// import firebase from 'firebase/app'
// import 'firebase/storage'
import { arrayUnion } from 'vuex-easy-firestore'

const reportModule = {
  firestorePath: 'officeData/{officeId}/report/',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'reportModule',
  statePropName: 'data',
  namespaced: true, // automatically added

  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    getActiveDangerSortedByDistOfMe: (state, getters) => {
      return getters.getActiveDangerWithDistOfMe.sort(function (a, b) {
        return a.distOfMe - b.distOfMe
      })
    },
    getActiveDangerWithDistOfMe: (state, getters, rootState, rootGetters) => {
      const list = getters.getActiveDanger
      list.forEach(danger => {
        danger.distOfMe = rootGetters.distanceOfMe(danger.dangergps)
      })
      return list
    },
    getProximityAlertSortedByDistOfMe: (state, getters) => {
      return getters.getProximityAlertWithDistOfMe.sort(function (a, b) {
        return a.distOfMe - b.distOfMe
      })
    },
    getProximityAlertWithDistOfMe: (state, getters, rootState, rootGetters) => {
      const list = getters.getProximityAlert
      list.forEach(danger => {
        danger.distOfMe = rootGetters.distanceOfMe(danger.dangergps)
      })
      return list
    },
    getProximityAlertDistanceMin: (state, getters) => {
      if (getters.getProximityAlertSortedByDistOfMe.length) {
        return getters.getProximityAlertSortedByDistOfMe[0].distOfMe
      } else {
        return 0
      }
    },
    getReportOfPocId: (state, getters) => (pocId) => {
      return Object.values(state.data).filter(x => x.pocList.map(x => { return x.pdrId }).indexOf(pocId) > -1)
    },
    getProximityAlert: (state, getters) => {
      return Object.values(state.data).filter(x => x.active && x.dangergps && x.proximityAlert)
    },
    getActiveDanger: (state, getters) => {
      return getters.getActiveReport.filter(x => x.dangergps)
    },
    getActiveReport: (state) => {
      return Object.values(state.data).filter(x => x.active)
    },
    getAllReport: (state) => {
      return Object.values(state.data)
    },
    isBlockReport: (state) => (reportId) => {
      let isBlock = false
      state.data[reportId].pocList.forEach(pocInfo => {
        if (pocInfo.isBlocked) {
          isBlock = true
        }
      })
      return isBlock
    },
    degreesToRadians: () => (degrees) => {
      const pi = Math.PI
      return degrees * (pi / 180)
    },
    getAlert: (state, getters) => (userPos) => {
      const dangerlist = []
      var minDist = 10000000
      const radius = 6371 // Earth's radius in kilometers
      if (userPos) {
        for (const alertId in state.data) {
          const alert = state.data[alertId]
          if (alert.active && alert.securedistance && alert.proximityAlert && alert.dangergps) {
            const latDelta = getters.degreesToRadians(userPos.lat - alert.dangergps.latitude)
            const lonDelta = getters.degreesToRadians(userPos.lng - alert.dangergps.longitude)

            const a = (Math.sin(latDelta / 2) * Math.sin(latDelta / 2)) +
                    (Math.cos(getters.degreesToRadians(alert.dangergps.latitude)) * Math.cos(getters.degreesToRadians(userPos.lat)) *
                    Math.sin(lonDelta / 2) * Math.sin(lonDelta / 2))

            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
            const dist = Math.round((radius * c) * 10000) / 10
            if (dist < minDist) { minDist = dist }
            // if (alert.securedistance > dist) {
            // if (alert.securedistance > dist) {
            // if (dist === 0) { // console.log('trace error') }
            if (alert.securedistance + 10 > dist) {
              dangerlist.push({ id: alert.id, dist: dist, isShow: false })
            }
          }
        }
      }
      // // console.log(dangerlist)
      // // console.log(minDist)
      return { list: dangerlist, minDist: minDist }
    },
    getAlertZoneMarkerData (state, getters, rootState, rootGetters) {
      const alertMarker = []
      const google = rootGetters.getGoogle
      getters.getActiveDanger.forEach(report => {
        if (report.type === 'dog' || report.type === 'ice' || report.type === 'brokenstep' || report.type === 'other') {
          // const marker = new google.maps.Marker({
          //   position: { lat: report.dangergps.latitude, lng: report.dangergps.longitude },
          //   map: null,
          //   icon: { // url:mdi-toilet,
          //     url: rootGetters.getAlertPngIcon(report.type),
          //     scaledSize: new google.maps.Size(30, 30), // scaled size
          //     origin: new google.maps.Point(0, 0), // origin
          //     anchor: new google.maps.Point(15, 15) // anchor
          //   }
          // })
          alertMarker.push({
            type: 'alertMarker',
            reportId: report.id,
            reportType: report.type,
            isOpenInfoWindow: true,
            position: { lat: report.dangergps.latitude, lng: report.dangergps.longitude },
            marker: rootGetters.getGoogleMarker({
              type: 'alertMarker',
              url: rootGetters.getAlertPngIcon(report.type),
              position: { lat: report.dangergps.latitude, lng: report.dangergps.longitude },
              label: ' '
            }),
            zone: !report.securedistance ? null : new google.maps.Circle({
              strokeColor: '#FF0000',
              strokeOpacity: 0.7,
              strokeWeight: 2,
              fillColor: '#FF0000',
              fillOpacity: 0.35,
              map: null,
              center: { lat: report.dangergps.latitude, lng: report.dangergps.longitude },
              radius: report.securedistance
            })
          })
        }
      })
      return alertMarker
    },
    getAlertZoneMarker (state, getters, rootState, rootGetters) {
      const alertMarker = []
      const google = rootGetters.getGoogle
      getters.getActiveDanger.forEach(report => {
        if (report.type === 'dog' || report.type === 'ice' || report.type === 'brokenstep' || report.type === 'other') {
          const marker = new google.maps.Marker({
            position: { lat: report.dangergps.latitude, lng: report.dangergps.longitude },
            map: null,
            icon: { // url:mdi-toilet,
              url: rootGetters.getAlertPngIcon(report.type),
              scaledSize: new google.maps.Size(30, 30), // scaled size
              origin: new google.maps.Point(0, 0), // origin
              anchor: new google.maps.Point(15, 15) // anchor
            }
          })
          let zone = null
          if (report.securedistance) {
            zone = new google.maps.Circle({
              strokeColor: '#FF0000',
              strokeOpacity: 0.7,
              strokeWeight: 2,
              fillColor: '#FF0000',
              fillOpacity: 0.35,
              map: null,
              center: { lat: report.dangergps.latitude, lng: report.dangergps.longitude },
              radius: report.securedistance
            })
          }
          alertMarker.push({ marker: marker, zone: zone, type: report.type })
        }
      })
      return alertMarker
    }
  },
  mutations: {},
  actions: {
    onCreateReport ({ commit, getters }, payload) {
      return this.dispatch('reportModule/insert', payload)
        .then((reportid) => {
          const prom = []
          if (payload.pocList.length) {
            for (const pocReport of payload.pocList) {
              const pdr = this.state.pocModule.data[pocReport.pdrId]
              if (pdr.report) {
                prom.push(this.dispatch('pocModule/set', {
                  id: pocReport.pdrId,
                  report: arrayUnion({ reportId: reportid, isBlocked: pocReport.isBlocked, isOwner: pocReport.isOwner })
                }))
              } else {
                prom.push(this.dispatch('pocModule/set', {
                  id: pocReport.pdrId,
                  report: [{ reportId: reportid, isBlocked: pocReport.isBlocked, isOwner: pocReport.isOwner }]
                }))
              }
            }
          }
          // // console.log('trace sendmessage')
          prom.push(this.dispatch('sendMessage', {
            type: 'createReport',
            reportId: reportid
          }))
          prom.push(this.dispatch('createTask', {
            type: 'createReport',
            reportId: reportid
          }))
          return Promise.all(prom)
          // this.dispatch('showMessage', 'Report Created')
        })
        // .catch(err => {
        //   this.dispatch('showAlert', err.message)
        // })
    }
  }
}

export default reportModule
