<template>
  <div class="home">
    <v-card>
      <v-card-title> Admin Test </v-card-title>
      <v-card-title>{{ testEnCour ? 'en cour...' : 'stop' }}</v-card-title>
      <v-card-text>
        <v-btn large rounded class="my-3" block @click="testOpenDBChannel()">Test OpenDBChannel all poc</v-btn>
        <v-btn large rounded class="my-3" block @click="testFetchAll()">Test fetch all poc</v-btn>
        <v-container v-if="testDone">
          <p class="subtitle-1"> Resultat: </p>
          <p>Dure : {{ result }} sec</p>
          <p>Poc count: {{ $store.getters['pocModule/numberOfPoc'] }}</p>
          <p>Poc count2: {{ countPoc }}</p>
        </v-container>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>Correction Data</v-card-title>
      <v-btn @click="correctBpcomDouble">Correct BpCom double</v-btn>
      <v-btn @click="patchofficemultiRoute">Correct multiItem route</v-btn>
      <v-btn @click="correctBpcomPatchTemp">Patch Temp {{ done }}/</v-btn>

      <v-card-text height="600" class="overflow-y-auto">
        <p v-for="(result, i) in correctResult" :key="i">{{ result }}</p>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>Correction Couleur Circulaire</v-card-title>
      <manage-calendar/>
    </v-card>
    <loading-popup :progressValue="progressValue" v-if="showLoading" :title="loadingTitle" :subTitle="loadingSubtitle"></loading-popup>
  </div>
</template>

<script>
import ManageCalendar from './ManageCalendar.vue'
export default {
  components: { ManageCalendar },
  data () {
    return {
      result: null,
      testEnCour: false,
      testDone: false,
      countPoc: 0,
      showLoading: false,
      loadingTitle: 'loading',
      loadingSubtitle: 'en cours',
      done: null,
      total: null,
      correctResult: []
    }
  },
  methods: {
    correctBpcomDouble () {
      this.correctResult = []
      const bpCompArray = this.$store.getters['officeItemMultiModule/getBpCom']
      const bpDouble = []
      const self = this
      bpCompArray.forEach(bp => {
        for (let i = 0; i < bpCompArray.length; i++) {
          if (bpCompArray[i].siteId === bp.siteId && bpCompArray[i].id !== bp.id) {
            const doubleIdx = bpDouble.map(x => { return x.siteId }).indexOf(bp.siteId)
            if (doubleIdx < 0) {
              bpDouble.push({ siteId: bp.siteId, idArray: [bp.id, bpCompArray[i].id], pocRef: bp.pocIdList[0] })
            } else {
              const idIdx = bpDouble[doubleIdx].idArray.indexOf(bpCompArray[i].id)
              if (idIdx < 0) {
                bpDouble[doubleIdx].idArray.push(bpCompArray[i].id)
              }
            }
          }
        }
      })
      const prom = []
      bpDouble.forEach(dd => {
        prom.push(self.$store.dispatch('pocModule/fetchById', dd.pocRef).then(() => {
          dd.goodId = self.$store.state.pocModule.data[dd.pocRef].mapItems[0].officeItemMultiId
        }))
      })
      const promCorrect = []
      Promise.all(prom).then(() => {
        this.correctResult = bpDouble.map(x => { return x.siteId + ' -> ' + x.idArray.toString() + '   --good = ' + x.goodId })
        bpDouble.forEach(dd => {
          dd.idArray.forEach(multiId => {
            if (multiId !== dd.goodId) {
              promCorrect.push(self.$store.dispatch('officeItemMultiModule/delete', multiId))
            }
          })
        })
      })
      Promise.all(promCorrect).then(() => {
        this.correctResult.push('delete Done')
      })
    },
    correctBpcomPatchTemp () {
      const batch = []
      const correctBatch = []
      const self = this

      const bpCompArray = this.$store.getters['officeItemMultiModule/getBpCom']
      // const bpCompIdArray = bpCompArray.map(x => { return x.id })
      this.done = 0
      this.loadingTitle = 'Recherche'
      this.showLoading = true
      bpCompArray.forEach(bp => {
        bp.pocIdList.forEach(pocId => {
          batch.push(pocId)
        })
      })
      function doCorrectBatch (doList) {
        var batchList
        const batchPromise = []
        var toDoList = null
        if (doList.length > 500) {
          batchList = doList.slice(0, 500)
          toDoList = doList.slice(500)
        } else {
          batchList = doList
        }
        // // console.log('doCorrectBatch batchList cpt = ' + batchList.length)
        batchList.forEach(c => {
          const mapItems = self.$store.state.pocModule.data[c.pocId].mapItems
          mapItems.splice(c.itemIdx, 1)
          batchPromise.push(new Promise((resolve, reject) => {
            self.$store.dispatch('pocModule/set', {
              id: c.pocId,
              mapItems: mapItems
            })
              .then(() => {
                self.done++
                resolve(c.pocId)
              })
              .catch((err) => {
                // store.dispatch('showAlert', err.message)
                self.done++
                reject(err.message)
              })
          }))
        })
        Promise.all(batchPromise).then(() => {
          // // console.log('trace promesse.all')
          if (toDoList) {
            // // console.log('batch reste ' + toDoList.length)
            doCorrectBatch(toDoList)
          } else {
            // EventBus.$emit('importpoclistupdatecomplete')
            // // console.log('correct complete')
            self.showLoading = false
            self.total = null
            self.done = null
          }
        })
          .catch((err) => {
            self.$store.dispatch('showAlert', err.message)
          })
      }
      function doSearchBatch (doList) {
        var batchList
        const batchPromise = []
        var toDoList = null
        if (doList.length > 500) {
          batchList = doList.slice(0, 500)
          toDoList = doList.slice(500)
        } else {
          batchList = doList
        }
        // // console.log('doSearchBatch batchList cpt = ' + batchList.length)
        batchList.forEach(pocId => {
          batchPromise.push(new Promise((resolve, reject) => {
            self.$store.dispatch('pocModule/fetchById', pocId)
              .then(() => {
                self.done++
                if (!self.$store.state.pocModule.data[pocId]) {
                  // // console.log('trace err A')
                }
                const poc = self.$store.state.pocModule.data[pocId]
                const idxToClean = []
                if (poc.mapItems.length > 1) {
                  for (let i = 0; i < poc.mapItems.length; i++) {
                    const item = poc.mapItems[i]
                    if (item.officeItemMultiId && !item.type) {
                      if (self.$store.state.officeItemModule.data[item.officeItemMultiId]) {
                        // // console.log('exist pocId => ' + pocId)
                      } else {
                        correctBatch.push({ pocId: pocId, itemIdx: i })
                        idxToClean.push(pocId)
                      }
                    }
                  }
                  if (idxToClean.length > 1) {
                    // // console.log('plusieur probleme pocId = ' + pocId)
                  }
                }
                resolve(pocId)
              })
              .catch((err) => {
                // store.dispatch('showAlert', err.message)
                self.done++
                reject(err.message)
              })
          }))
        })
        Promise.all(batchPromise).then(() => {
          // // console.log('trace promesse.all')
          if (toDoList) {
            // // console.log('batch reste ' + toDoList.length)
            doSearchBatch(toDoList)
          } else {
            // EventBus.$emit('importpoclistupdatecomplete')
            // // console.log('patch complete = ' + correctBatch.length)
            // // console.log(correctBatch)
            self.done = 0
            self.total = correctBatch.length
            self.loadingTitle = 'Correction'
            doCorrectBatch(correctBatch)
          }
        })
          .catch((err) => {
            self.$store.dispatch('showAlert', err.message)
          })
      }
      // // console.log('trace 2')
      this.total = batch.length
      doSearchBatch(batch)
    },
    patchPosToLocBpCom () {
      this.correctResult = []
      const bpCompArray = this.$store.getters['officeItemMultiModule/getBpCom']
      bpCompArray.forEach(bp => {
        if (bp.pos) {
          this.$store.dispatch('officeItemMultiModule/set', {
            id: bp.id,
            loc: bp.pos,
            pos: null
          })
        }
      })
    },
    patchofficemultiRoute () {
      this.prom = []
      // let ctpItem = 0
      const self = this
      const itemMultiArray = self.$store.getters['officeItemMultiModule/getItemMulti']
      // // console.log('total = ' + itemMultiArray.length)
      itemMultiArray.forEach(item => {
        if (item.pocIdList && !item.route) {
          // ctpItem++
          self.$store.dispatch('pocModule/fetchById', item.pocIdList[0])
            .then((poc) => {
              self.$store.dispatch('officeItemMultiModule/set', {
                id: item.id,
                route: poc.route
              })
            })
        }
      })
      // // console.log(ctpItem)
    },
    testOpenDBChannel () {
      const timeStart = new Date()
      this.testEnCour = true
      this.testDone = false
      this.showLoading = true
      this.$store.dispatch('pocModule/closeDBChannel', { clearModule: true })
        .then(() => {
          this.$store.dispatch('pocModule/openDBChannel', { officeId: this.$store.getters.getSelectedOfficeId })
            .then(() => {
              this.testEnCour = false
              this.testDone = true
              this.result = Math.abs(new Date() - timeStart) / 1000
              this.countPoc = this.$store.getters['pocModule/numberOfPoc']
              this.showLoading = false
            })
            .catch((err) => {
              this.$store.dispatch('showAlert', (err.message))
              this.showLoading = false
            })
        })
        .catch((err) => {
          this.$store.dispatch('showAlert', (err.message))
        })
    },
    testFetchAll () {
      const timeStart = new Date()
      this.testEnCour = true
      this.testDone = false
      this.countPoc = 0
      this.showLoading = true
      this.$store.dispatch('pocModule/fetchAndAdd', { clauses: { limit: 0 } })
        .then((fetchResult) => {
          this.testDone = true
          this.countPoc++
          this.showLoading = false
          if (fetchResult.done === true) {
            this.testEnCour = false
            this.result = Math.abs(new Date() - timeStart) / 1000
          }
        })
        .catch((err) => {
          this.$store.dispatch('showAlert', (err.message))
          this.showLoading = false
        })
    }
  },
  computed: {
    progressValue () {
      if (this.total) {
        if (this.done) {
          return Math.round((Number(this.done) * 100) / Number(this.total))
        } else {
          return 0
        }
      } else {
        return null
      }
    }
  },
  mounted () {
  }
}
</script>
