
import { arrayUnion } from 'vuex-easy-firestore'

const officeSelectedModule = {
  firestorePath: 'office/{officeId}',
  firestoreRefType: 'doc', // or 'doc'
  moduleName: 'officeSelectedModule',
  statePropName: 'data',
  namespaced: true, // automatically added

  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    getContactList: (state) => {
      return state.data.contactList ? state.data.contactList : []
    },
    getOfficeAddress: (state) => {
      const office = state.data

      const address = {
        city: null,
        province: null
      }
      for (let x = 0; x < office.gaddress.length; x++) {
        const item = office.gaddress[x]
        for (let i = 0; i < item.types.length; i++) {
          const type = item.types[i]
          if (type === 'administrative_area_level_1') {
            address.province = { lname: item.long_name, sname: item.short_name }
          }
          if (type === 'locality') {
            address.city = { lname: item.long_name, sname: item.short_name }
          }
        }
      }
      return address
    },
    getOfficePosition: (state) => {
      if (state.data.location) {
        const loc = state.data.location[0].loc
        return { lat: loc.latitude, lng: loc.longitude }
      } else {
        return null
      }
    },
    getOfficeToiletMarker: (state, getters, rootState, rootGetters) => {
      const toiletMarker = []
      const google = rootGetters.getGoogle
      for (const toilet of state.data.toilet) {
        const marker = new google.maps.Marker({
          position: { lat: toilet.location.latitude, lng: toilet.location.longitude },
          map: null,
          icon: { // url:mdi-toilet,
            url: require('@/assets/Icon/MapIcon/human-male-female.png'),
            scaledSize: new google.maps.Size(48, 48), // scaled size
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(24, 24) // anchor
          }
        })
        toiletMarker.push(marker)
      }
      return toiletMarker
    },
    getOfficeGreyBoxMarker: (state, getters) => {
      return getters.getGreyBoxActiveList.map(x => {
        x.type = 'greyBoxMarker'
        return x
      })
    },
    getOfficeGreyBoxMarker_back: (state, getters, rootState, rootGetters) => {
      const greyBoxMarker = []
      const google = rootGetters.getGoogle
      for (const greyBox of state.data.greybox) {
        if (greyBox.isActive) {
          const marker = new google.maps.Marker({
            position: greyBox.position,
            // label: greyBox.name,
            map: null,
            icon: { // url:mdi-toilet,
              url: require('@/assets/Icon/MapIcon/GreyBox.png'),
              scaledSize: new google.maps.Size(48, 48), // scaled size
              origin: new google.maps.Point(0, 0), // origin
              anchor: new google.maps.Point(24, 24) // anchor
            }
          })
          // const infowindow = new google.maps.InfoWindow({
          //   content: greyBox.name
          // })
          // marker.addListener('click', function () {
          //   infowindow.open(map, marker)
          // })
          greyBoxMarker.push({ marker: marker, name: greyBox.name })
        }
      }
      return greyBoxMarker
    },
    getGreyBoxActiveList: (state) => {
      return state.data.greybox.filter(x => x.isActive)
    },
    getGreyBoxActiveList_back: (state) => (officeId) => {
      const greyBoxList = []
      for (const greyBox of state.data[officeId].greybox) {
        if (greyBox.isActive) {
          greyBoxList.push(greyBox)
        }
      }
      return greyBoxList
    },
    getGreyBox: (state, getters) => (officeId) => {
      return []
    }
  },
  mutations: {
    setContact (state, payload) {
      if (!state.contactList) { state.contactList = [] }
      const index = state.data.contactList.map(x => { return x.id }).indexOf(payload.id)
      if (index < 0) {
        state.data.contactList.push(payload)
      } else {
        state.data.contactList[index] = payload
      }
      return this.dispatch('officeSelectedModule/set', { contactList: state.data.contactList })
    },
    deleteContact (state, payload) {
      const index = state.data.contactList.map(x => { return x.id }).indexOf(payload)
      if (index < 0) {
        return null
      } else {
        state.data.contactList.splice(index, 1)
        return this.dispatch('officeSelectedModule/set', { contactList: state.data.contactList })
      }
    }
  },
  actions: {
    importHarmonize ({ commit, getters }, payload) {
      const office = getters.getOffice(payload.officeId)
      var upDateParam
      if (office.harmonize) {
        upDateParam = {
          id: office.id,
          harmonize: arrayUnion(payload.harmonize)
        }
      } else {
        upDateParam = {
          id: office.id,
          harmonize: [payload.harmonize]
        }
      }
      this.dispatch('officeModule/set', upDateParam)
        .then((OfficeId) => {
          payload.EventBus.$emit('importharmonizeSuccess')
        })
        .catch((err) => {
          payload.EventBus.$emit('importharmonizeError', err.message)
        })
    },
    createGreyBox ({ commit, getters }, payload) {
      const office = getters.getOffice(payload.id)

      var newGBoxValue
      if (office.greybox) {
        newGBoxValue = {
          id: office.id,
          greybox: arrayUnion(payload.greybox)
        }
      } else {
        newGBoxValue = {
          id: office.id,
          greybox: [payload.greybox]
        }
      }
      this.dispatch('officeModule/set', newGBoxValue)
        .then((OfficeId) => {
          payload.EventBus.$emit('createGreyBoxSucces')
        })
        .catch((err) => {
          payload.EventBus.$emit('createGreyBoxError', err.message)
        })
    },
    updateGreyBox ({ commit, getters, store }, payload) {
      const greyBoxArray = getters.getOffice(payload.id).greybox
      const greyBoxIndex = greyBoxArray.findIndex(obj => obj.id === payload.greybox.id)
      greyBoxArray[greyBoxIndex] = payload.greybox

      this.dispatch('officeModule/patch', { id: payload.id, greybox: greyBoxArray })
        .then((OfficeId) => {
          payload.EventBus.$emit('updateGreyBoxSucces')
        })
        .catch((err) => {
          payload.EventBus.$emit('updateGreyBoxError', err.message)
        })
    },
    saveContact ({ commit, state, getters, rootState, rootGetters }, payload) {
      if (!payload.id) { payload.id = this.getUniqueKey() }
      return commit('setContact', payload)
    },
    deleteContact ({ commit }, payload) {
      return commit('deleteContact', payload)
    }
  }
}

export default officeSelectedModule
