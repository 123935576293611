<template>
  <div id="app">
    <v-card class="overflow-hidden" :style="$store.getters.contentStyle">
      <v-card height="90" class="grey lighten-2 pb-5">
        <v-card-title>
          <v-row no-gutters>
            <v-btn icon x-large @click="$store.getters.EventBus.$emit('selectLocalAdminComponent', ('pmt-menu-admin-local'))"><v-icon x-large>mdi-arrow-left-circle</v-icon></v-btn>
            <h3 class="mt-3">Menu</h3>
          </v-row>
        </v-card-title>
      </v-card>
      <v-sheet class="overflow-y-auto overflow-x-hidden" :height="$store.getters.contentHeight - 90" :max-height="$store.getters.contentHeight - 90">
        <v-card-title>{{ $t('import-poclist.import') }}</v-card-title>
        <v-card-subtitle>{{ $t('import-bpcom.title') }}</v-card-subtitle>
        <input id='input' v-show="false" type="file" ref="inputUpload" accept=".csv" @change="processSeqFiles()"/>
        <v-container v-if="!file" class="justify-center mt-12">
          <v-row><v-spacer></v-spacer><v-btn dark icon size="70" color="primary" @click="$refs.inputUpload.click()"><v-icon size="70">mdi-upload</v-icon></v-btn><v-spacer></v-spacer></v-row>
          <v-row centered class="mt-3"><h2 class="mx-auto">{{ $t('import-poclist.open') }}</h2></v-row>
        </v-container>
        <v-card v-if="file" id="filedetail" class="mx-auto mb-3 pt-4" centered max-width="444">
          <v-btn @click="file = null" class="float-right" x-large icon><v-icon x-large color="red">mdi-delete</v-icon></v-btn>
          <h4 class="ms-1">{{ $t('import-poclist.file') }} : </h4>
          <h5 class="ms-2">{{ fileName }}</h5>

          <h4 class="ms-1 mt-2">{{ $t('import-poclist.size') }} : </h4>
          <h5 class="ms-2">{{ Math.round(((file.size / 1024) / 1024) * 10) / 10 }} Mo</h5>
          <h4 class="ms-1 mt-2">{{ $t('import-poclist.modified') }} : </h4>
          <h5 class="ms-2">{{ new Date(file.lastModified).toLocaleString('fr-CA',) }}</h5>
          <h4 class="ms-1 mt-2">Detail : </h4>

          <v-container v-show="analyseComplete && validFile" id="fileStat">
            <v-card v-for="item in importData" :key="item.route" class="mb-3">
              <v-card-title>{{ item.route }} </v-card-title>
              <v-card-subtitle>Site : {{ item.site.length }}</v-card-subtitle>
              <v-list nav>
                <v-list-item @click="showImportBpComDetail = item">
                  <v-list-item-content>
                    <v-row justify="center" class="mt-3" ><v-icon x-large color="green">mdi-open-in-new</v-icon></v-row>
                    <v-row centered><h3 class="mx-auto mt-1 pb-10">{{ $t('common.open') }}</h3></v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-container>
          <v-container v-show="analyseComplete && !validFile">
            <v-card class="mb-3">
              <v-card-title>{{ $t('common.notvalidefile') }}</v-card-title>
              <v-card-text>{{ $t('import-bpcom.notvalidefiledesc') }}</v-card-text>
            </v-card>
          </v-container>
        </v-card>
      </v-sheet>
    </v-card>
    <pmt-import-bpcomdetail
      v-if="isShowImportBpComDetail"
      :bpComDetail="showImportBpComDetail"
      :fileName="file.name"
      @close="showImportBpComDetail = null"
    ></pmt-import-bpcomdetail>
  </div>
</template>

<script>
export default {
  name: 'App',
  props: ['title'],
  data () {
    return {
      file: null,
      fileName: '',
      pocListFiles: null,
      loadComplete: false,
      showImportBpComDetail: null,
      confirmTitle: null,
      routeType: 0,
      routeNameArray: [],
      createRouteParam: null,
      refreshRouteParam: null,
      routeSelected: null,
      pocLoadComplete: false,
      analyseComplete: false,
      validFile: false,
      showapp: false,
      showdoor: false,
      showProgress: false,
      progressTitle: '',
      importData: [],
      loadingTitle: '',
      loadingsubTitle: ''
    }
  },
  watch: {
    file (newValue) {
      if (!newValue) {
        this.loadComplete = false
        this.analyseComplete = false
        this.validFile = false
        this.importData = []
      }
    }
  },
  computed: {
    isShowImportBpComDetail () {
      if (this.showImportBpComDetail) {
        return true
      } else {
        return false
      }
    },
    bpInfo () {
      if (this.importData) {
        const info = { route: [] }
        return info
      } else {
        return null
      }
    }
  },
  methods: {
    confirmRefresh (routeName) {
      this.confirmTitle = this.$t('common.confirmrefresh')
      this.routeSelected = routeName
      this.refreshRouteParam = { mode: 'refreshRoute' }
      this.showConfirmRefreshPoc = true
    },
    confirmImport (routeName) {
      this.confirmTitle = this.$t('common.confirmimport')
      this.routeSelected = routeName
      this.createRouteParam = { mode: 'importRoute', routeType: null }
      this.showConfirmImportPoc = true
    },
    processSeqFiles () {
      this.file = this.$refs.inputUpload.files[0]
      this.fileName = this.file.name
      this.loadCommplete = true
      this.analyseComplete = false
      this.importData = []
      this.processBpComFile()
    },
    processBpComFile () {
      if (this.file) {
        this.$store.commit('setMasterLoadingStatus', {
          showLoading: true,
          title: this.$t('loading-mess.analyze-csv')
        })
        const EventBus = this.$store.getters.EventBus
        this.showProgress = true
        this.validFile = false
        EventBus.$on('filepdrlistcsvproccesscompleted', (result) => {
          this.importData = result.sort(function (a, b) { return a.route > b.route })
          if (this.importData.length) {
            this.validFile = true
            this.importData.forEach(route => {
              if (route.officePostalCode.replace(/ /g, '').toUpperCase() !== this.$store.getters.getSelectedOffice.cp.replace(/ /g, '').toUpperCase()) {
                this.validFile = false
              }
            })
          }
          this.analyseComplete = true
          this.showProgress = false
          this.$store.commit('setMasterLoadingStatus', { showLoading: false })
        })
        EventBus.$on('proccessbpcomfile', (fileInfo) => {
          switch (fileInfo.type) {
            case 'dos':
              this.processBpComFileDosCSV(fileInfo.csv)
              break
            case 'original':
              this.processBpComFileOriginalCSV(fileInfo.csv)
              break
            default:
              this.analyseComplete = true
              this.showProgress = false
              break
          }
        })
        if (window.FileReader) {
          var reader = new FileReader()
          reader.readAsText(this.file)
          reader.onload = function (event) {
            var csv = event.target.result.split('\n')
            if (csv[0].split(',').length > 6 && csv[0].split(',')[0].substring(0, 23) === '"COMMUNITY MAIL BOXES /') {
              EventBus.$emit('proccessbpcomfile', { type: 'original', csv: csv })
            } else if (csv[1].split(';').length > 9) {
              EventBus.$emit('proccessbpcomfile', { type: 'dos', csv: csv })
            } else {
              EventBus.$emit('filepdrlistcsvproccesscompleted', ([]))
            }
          }
        }
      }
    },
    processBpComFileDosCSV (csv) {
      const self = this
      const EventBus = this.$store.getters.EventBus
      const bpComArray = []
      let rtaDetail = {}
      let siteDetail = { udl: [] }
      let cptligne = 0
      const countligne = csv.length
      let progress; let oldprogress
      for (let i = 0; i < csv.length; i++) {
        const ligne = csv[i]
        cptligne++
        const item = ligne.replace(/"/g, '').split(';')
        if (item[0] && item[1] && !item[2] && !item[4] && item[26]) {
          rtaDetail = {}
          rtaDetail.rtaId = item[1].trim()
          rtaDetail.civic = item[3].trim()
          rtaDetail.street = item[5].trim().toUpperCase()
          rtaDetail.postaCode = item[12].toUpperCase() + item[15].toUpperCase()
          rtaDetail.desc = item[18].trim().toUpperCase()
          rtaDetail.status = item[26].trim()
          rtaDetail.pocType = item[28].trim()
          rtaDetail.is2015 = item[30].trim()
          rtaDetail.isMailSlot = rtaDetail.desc.substring(0, 11) === 'MAIL SLOT /'

          const udlIndex = siteDetail.udl.map(x => { return x.udlId }).indexOf(item[0].trim())
          if (udlIndex < 0) {
            siteDetail.udl.push({
              udlId: item[0].trim(),
              udlName: item[0].trim(),
              udlKey: self.$store.getUniqueKey(),
              rta: [rtaDetail]
            })
          } else {
            siteDetail.udl[udlIndex].rta.push(rtaDetail)
          }
          if (item.length > 37 && item[32] && !item[33] && item[34] && !item[35] && !item[37]) {
            rtaDetail = {}
            rtaDetail.rtaId = item[34].trim()
            rtaDetail.civic = item[36].trim()
            rtaDetail.street = item[38].trim().toUpperCase()
            rtaDetail.postaCode = item[45].toUpperCase() + item[48].toUpperCase()
            rtaDetail.desc = item[50].trim().toUpperCase()
            rtaDetail.status = item[61].trim()
            rtaDetail.pocType = item[62].trim()
            rtaDetail.is2015 = item[64].trim()
            rtaDetail.isMailSlot = rtaDetail.desc.substring(0, 11) === 'MAIL SLOT /'

            const udlIndex = siteDetail.udl.map(x => { return x.udlId }).indexOf(item[32].trim())
            if (udlIndex < 0) {
              siteDetail.udl.push({
                udlId: item[32].trim(),
                udlName: item[32].trim(),
                udlKey: self.$store.getUniqueKey(),
                rta: [rtaDetail]
              })
            } else {
              siteDetail.udl[udlIndex].rta.push(rtaDetail)
            }
          }
        }
        if (item[0].substring(0, 15) === 'Municipality / ') {
          const nextItem = csv[i + 1].replace(/"/g, '').split(';')
          const prevItem = csv[i - 1].replace(/"/g, '').split(';')
          siteDetail.siteConfiguration = prevItem[5]
          siteDetail.miniPark = prevItem[15]
          siteDetail.keyCode = prevItem[20].trim()

          siteDetail.route = nextItem[57].trim()
          siteDetail.city = nextItem[1].trim()
          siteDetail.bpComDesc = nextItem[20].trim()
          siteDetail.bpComDesc = siteDetail.bpComDesc.toUpperCase().replace(/� DROITE/g, 'À DROITE')
          siteDetail.bpComDesc = siteDetail.bpComDesc.toUpperCase().replace(/� GAUCHE/g, 'À GAUCHE')
          siteDetail.bpComDesc = siteDetail.bpComDesc.toUpperCase().replace(/LAT�RAL/g, 'LATÉRAL')
          siteDetail.bpComDesc = siteDetail.bpComDesc.toUpperCase().replace(/DERRI�RE/g, 'DERRIÈRE')
          siteDetail.bpComDesc = siteDetail.bpComDesc.toUpperCase().replace(/� C�T�/g, 'À côté').toUpperCase()
          siteDetail.bpComDesc = siteDetail.bpComDesc.toUpperCase().replace(/C�T�/g, 'Côté').toUpperCase()
        }
        if (item[0] === ' clients: ') {
          const dataItem = csv[i + 3].replace(/"/g, '').split(';')
          siteDetail.siteId = dataItem[56]
          siteDetail.officeName = dataItem[0].split('-')[0].trim()
          siteDetail.officePostalCode = dataItem[0].split('-')[1].trim()
          const routeIndex = bpComArray.map(x => { return x.route }).indexOf(siteDetail.route)
          if (routeIndex < 0) {
            bpComArray.push({
              route: siteDetail.route,
              officeName: siteDetail.officeName,
              officePostalCode: siteDetail.officePostalCode,
              site: [{
                siteId: siteDetail.siteId,
                siteKey: self.$store.getUniqueKey(),
                siteTempName: 1,
                siteConfiguration: siteDetail.siteConfiguration,
                miniPark: siteDetail.miniPark,
                bpComDesc: siteDetail.bpComDesc,
                udl: siteDetail.udl
              }]
            })
          } else {
            bpComArray[routeIndex].site.push({
              siteId: siteDetail.siteId,
              siteKey: self.$store.getUniqueKey(),
              siteTempName: bpComArray[routeIndex].site.length + 1,
              siteConfiguration: siteDetail.siteConfiguration,
              miniPark: siteDetail.miniPark,
              bpComDesc: siteDetail.bpComDesc,
              udl: siteDetail.udl
            })
          }
          siteDetail = { udl: [] }
        }

        progress = Math.round((cptligne / countligne) * 100)
        if (progress !== oldprogress) {
          oldprogress = progress
          EventBus.$emit('filepdrlistcsvprogressValueChange', (progress))
        }
      }
      EventBus.$emit('filepdrlistcsvproccesscompleted', (bpComArray))
    },
    processBpComFileOriginalCSV (csv) {
      const self = this
      const EventBus = this.$store.getters.EventBus
      const bpComArray = []
      let rtaDetail = {}
      let siteDetail = {}
      let udlDetail = {}
      let cptligne = 0
      let siteIndex = -1
      const countligne = csv.length
      let progress; let oldprogress; let fileType
      for (const ligne of csv) {
        cptligne++
        const item = ligne.replace(/"/g, '').split(',')

        if (item[0] === 'COMMUNITY MAIL BOXES /  BOÎTES POSTALES COMMUNAUTAIRES') {
          fileType = 'bpcom'
          rtaDetail = {}
        } else if (fileType === 'bpcom' && item.length > 2) {
          if (item[15] === 'Site type') {
            udlDetail.udlId = item[1]
            rtaDetail.rtaId = item[2]
            if (udlDetail.udlId === '1' && rtaDetail.rtaId === '1') {
              siteIndex++
            }
            rtaDetail.civic = item[4].trim()
            rtaDetail.street = item[5].trim()
            rtaDetail.postaCode = item[7].toUpperCase() + item[8].toUpperCase()
            rtaDetail.desc = item[10]
            rtaDetail.status = item[12]
            rtaDetail.pocType = item[13]
            rtaDetail.is2015 = item[14]
          } else if (item[0] === 'Livré par') {
            siteDetail.route = item[12]
            siteDetail.siteConfiguration = item[1]
            siteDetail.miniPark = item[3]
            udlDetail.keyCode = item[5]
            siteDetail.city = item[13]
            siteDetail.bpComDesc = item[15]
            siteDetail.officeName = item[17].split('-')[0].trim()
            siteDetail.officePostalCode = item[17].split('-')[1].trim()
          } else if (item[4] === 'Site no:') {
            siteDetail.siteId = item[5]
            if (udlDetail.udlId) {
              const routeIndex = bpComArray.map(x => { return x.route }).indexOf(siteDetail.route)
              if (routeIndex < 0) {
                bpComArray.push({
                  route: siteDetail.route,
                  officeName: siteDetail.officeName,
                  officePostalCode: siteDetail.officePostalCode,
                  site: [{
                    siteId: siteDetail.siteId,
                    siteKey: self.$store.getUniqueKey(),
                    siteTempName: 1,
                    siteConfiguration: siteDetail.siteConfiguration,
                    miniPark: siteDetail.miniPark,
                    bpComDesc: siteDetail.bpComDesc,
                    udl: [{
                      udlId: udlDetail.udlId,
                      udlName: udlDetail.udlId,
                      udlKey: self.$store.getUniqueKey(),
                      keyCode: udlDetail.keyCode,
                      rta: [rtaDetail]
                    }]
                  }]
                })
              } else {
                if (!bpComArray[routeIndex].site[siteIndex]) {
                  bpComArray[routeIndex].site.push({
                    siteId: siteDetail.siteId,
                    siteKey: self.$store.getUniqueKey(),
                    siteTempName: bpComArray[routeIndex].site.length + 1,
                    siteConfiguration: siteDetail.siteConfiguration,
                    miniPark: siteDetail.miniPark,
                    bpComDesc: siteDetail.bpComDesc,
                    udl: [{
                      udlId: udlDetail.udlId,
                      udlName: udlDetail.udlId,
                      udlKey: self.$store.getUniqueKey(),
                      keyCode: udlDetail.keyCode,
                      rta: [rtaDetail]
                    }]
                  })
                } else {
                  const udlIndex = bpComArray[routeIndex].site[siteIndex].udl.map(x => { return x.udlId }).indexOf(udlDetail.udlId)
                  if (udlIndex < 0) {
                    bpComArray[routeIndex].site[siteIndex].udl.push({
                      udlId: udlDetail.udlId,
                      udlName: udlDetail.udlId,
                      udlKey: self.$store.getUniqueKey(),
                      keyCode: udlDetail.keyCode,
                      rta: [rtaDetail]
                    })
                  } else {
                    const rtaIndex = bpComArray[routeIndex].site[siteIndex].udl[udlIndex].rta.map(x => { return x.rtaId }).indexOf(rtaDetail.rtaId)
                    if (rtaIndex < 0) {
                      bpComArray[routeIndex].site[siteIndex].udl[udlIndex].rta.push(rtaDetail)
                    }
                  }
                }
              }
            }
            rtaDetail = {}
            udlDetail = {}
            siteDetail = {}
          } else if (item[0] === 'Genre de Site') {
            siteDetail.siteType = item[1]
          }
        }
        progress = Math.round((cptligne / countligne) * 100)
        if (progress !== oldprogress) {
          oldprogress = progress
          EventBus.$emit('filepdrlistcsvprogressValueChange', (progress))
        }
      }
      EventBus.$emit('filepdrlistcsvproccesscompleted', (bpComArray))
    },
    verifDataFile () {
      if (this.importData) {
        for (let i = 0; i < this.importData.length; i++) {
          const bpInfo = this.importData[i]
          if (bpInfo.fileType !== 'bpcom') {
            this.validFile = false
            i = this.importData.length
          } else if (!bpInfo.bpComArray.length) {
            this.validFile = false
            i = this.importData.length
          } else {
            this.validFile = true
          }
        }
      } else {
        this.validFile = false
      }
    }
  },
  mounted () {
    this.pocLoadComplete = true
    this.$store.dispatch('pocModule/setPathVars', { officeId: this.$store.state.userLoggedModule.data.officeselected })
    this.loadingTitle = this.$t('common.loading')
    this.$refs.inputUpload.click()
  },
  destroyed () {
    this.file = null
  }
}
</script>
