<template>
    <div class="home">
        <pmt-la-nav-drawer
          :username= "username"
          :email= "email"
          :avatar_path= "avatar_path"
          :drawer="drawerState"
          @drawer-state-changed="drawerState = $event"
          @menu-item-selected="componentselectedKeepAlive = $event"
        >
        </pmt-la-nav-drawer>

        <div ref="toolbar">
        <appBar
            :drawer="drawerState"
            :logout-route="logoutroute"
            :contentstyles='contentstyles'
            :componentselectedKeepAlive= componentselectedKeepAlive
            @drawer-state-changed="drawerState = $event">
        </appBar>
        </div>
        <v-main ref="content">
            <keep-alive>
              <component
                v-if="componentselectedKeepAlive"
                v-bind:is='componentselectedKeepAlive'
                :contentstyles='contentstyles'
                @menu-item-selected="componentselectedKeepAlive = $event"
              ></component>
            </keep-alive>
            <component
              v-if="componentselectedTemp"
              v-bind:is='componentselectedTemp'
              :contentstyles='contentstyles'
            ></component>
        </v-main>

    </div>
</template>

<script>
import appBar from '../localAdmin/PMT_LA_App_Bar.vue'
export default {
  components: { appBar },
  props: {
    username: String,
    email: String,
    avatar_path: String,
    logoutroute: String
  },
  data () {
    return {
      drawerState: false,
      componentselectedKeepAlive: 'pmt-menu-admin-local',
      componentselectedTemp: null,
      aliveComponent: true,
      contentstyles: {
        styles: 'width:' + window.innerWidth + 'px; height:' + (window.innerHeight - 70) + 'px',
        witdh: window.innerWidth,
        height: window.innerHeight
      }
    }
  },
  async mounted () {
    this.$store.dispatch('openAdminLocalDataCollection')
    // scrolling off
    window.scrollTo(0, 0)
    // document.documentElement.style.overflow = 'hidden'
    this.$store.getters.EventBus.$on('sizeChange', () => {
      this.myEventHandler()
    })
    // this.$store.getters.EventBus.$on('showMap', () => {
    //   this.showMap()
    // })

    this.$store.getters.EventBus.$on('selectComponent', (choice) => {
      this.componentselectedKeepAlive = choice
    })
    this.$store.getters.EventBus.$on('selectLocalAdminComponent', (choice) => {
      this.componentselectedKeepAlive = choice
    })
    this.$store.getters.EventBus.$on('selectComponentTemp', (choice) => {
      this.componentselectedTemp = choice
    })
    this.$store.getters.EventBus.$on('selectLocalAdminComponentTemp', (choice) => {
      this.componentselectedTemp = choice
    })
    // this.$store.dispatch('refreshDayColor')
    // this.$store.dispatch('refreshCurrentPosition')
  },
  watch: {
    drawerState (newValue) {
    },
    componentselectedKeepAlive (newValue) {
      if (newValue) {
        this.componentselectedTemp = null
      }
    },
    componentselectedTemp (newValue) {
      if (newValue) {
        this.componentselectedKeepAlive = null
      }
    }
  },
  created () {
    window.addEventListener('resize', this.myEventHandler)
    // window.onresize = this.myEventHandler
    window.scrollTo(0, 0)
    // document.documentElement.style.overflow = 'hidden'
  },
  destroyed () {
    window.removeEventListener('resize', this.myEventHandler)
  },
  methods: {
    myEventHandler (e) {
      window.scrollTo(0, 0)
      // document.documentElement.style.overflow = 'hidden'
      this.$store.dispatch('resize')
      this.contentstyles = {
        styles: 'width:' + window.innerWidth + 'px; height:' + (window.innerHeight - 70) + 'px',
        witdh: window.innerWidth,
        height: window.innerHeight
      }
    }
  }
}
</script>
