// import { arrayUnion } from 'vuex-easy-firestore'

const officeMailNoticeModule = {
  firestorePath: 'officeData/{officeId}/params/officeMailAdvice',
  firestoreRefType: 'doc', // or 'doc'
  moduleName: 'officeMailNoticeModule',
  statePropName: 'data',
  namespaced: true, // automatically added

  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    getEmailAdvise: (state) => {
      return {
        pocVacantUpdate: state.data.pocVacantUpdate ? state.data.pocVacantUpdate : [],
        pocPubUpdate: state.data.pocPubUpdate ? state.data.pocPubUpdate : [],
        pocItemTypeUpdate: state.data.pocItemTypeUpdate ? state.data.pocItemTypeUpdate : [],
        reportCreate: state.data.reportCreate ? state.data.reportCreate : []
      }
    },
    getUserAdvise: (state, getters) => (userId) => {
      return {
        pocVacantUpdate: getters.getEmailAdvise.map(function (x) { return x.userId }).indexOf(userId) > -1,
        pocPubUpdate: getters.getEmailAdvise.map(function (x) { return x.userId }).indexOf(userId) > -1,
        pocItemTypeUpdate: getters.pocItemTypeUpdate.map(function (x) { return x.userId }).indexOf(userId) > -1,
        reportCreate: getters.reportCreate.map(function (x) { return x.userId }).indexOf(userId) > -1
      }
    }
  },
  mutations: {
    setUserAdvice (state, payload) {
      if (!state.data.pocVacantUpdate) { state.data.pocVacantUpdate = [] }
      if (!state.data.pocPubUpdate) { state.data.pocPubUpdate = [] }
      if (!state.data.pocItemTypeUpdate) { state.data.pocItemTypeUpdate = [] }
      if (!state.data.reportCreate) { state.data.reportCreate = [] }
      let toUpdate = false
      // const adv = getters.getEmailAdvise
      let index = state.data.pocVacantUpdate.map(function (x) { return x.userId }).indexOf(payload.userId)
      if (index > -1) {
        if (!payload.pocVacantUpdate) {
          state.data.pocVacantUpdate.splice(index, 1)
          toUpdate = true
        }
      } else {
        if (payload.pocVacantUpdate) {
          state.data.pocVacantUpdate.push({ userId: payload.userId, email: payload.userEmail })
          toUpdate = true
        }
      }
      index = state.data.pocPubUpdate.map(function (x) { return x.userId }).indexOf(payload.userId)
      if (index > -1) {
        if (!payload.pocPubUpdate) {
          state.data.pocPubUpdate.splice(index, 1)
          toUpdate = true
        }
      } else {
        if (payload.pocPubUpdate) {
          state.data.pocPubUpdate.push({ userId: payload.userId, email: payload.userEmail })
          toUpdate = true
        }
      }
      index = state.data.pocItemTypeUpdate.map(function (x) { return x.userId }).indexOf(payload.userId)
      if (index > -1) {
        if (!payload.pocItemTypeUpdate) {
          state.data.pocItemTypeUpdate.splice(index, 1)
          toUpdate = true
        }
      } else {
        if (payload.pocItemTypeUpdate) {
          state.data.pocItemTypeUpdate.push({ userId: payload.userId, email: payload.userEmail })
          toUpdate = true
        }
      }
      index = state.data.reportCreate.map(function (x) { return x.userId }).indexOf(payload.userId)
      if (index > -1) {
        if (!payload.reportCreate) {
          state.data.reportCreate.splice(index, 1)
          toUpdate = true
        }
      } else {
        if (payload.reportCreate) {
          state.data.reportCreate.push({ userId: payload.userId, email: payload.userEmail })
          toUpdate = true
        }
      }
      if (toUpdate) {
        this.dispatch('officeMailNoticeModule/set', {
          pocVacantUpdate: state.data.pocVacantUpdate,
          pocPubUpdate: state.data.pocPubUpdate,
          pocItemTypeUpdate: state.data.pocItemTypeUpdate,
          reportCreate: state.data.reportCreate
        })
      }
    }
  },
  actions: {
    // updateUserAdvice ({ commit, getters }, payload) {
    //   let toUpdate = false
    //   const adv = getters.getEmailAdvise
    //   let index = adv.pocVacantUpdate.map(function (x) { return x.userId }).indexOf(payload.userId)
    //   if (index > -1) {
    //     if (!payload.pocVacantUpdate) {
    //       adv.pocVacantUpdate.slice(index, 1)
    //       toUpdate = true
    //     }
    //   } else {
    //     if (payload.pocVacantUpdate) {
    //       adv.pocVacantUpdate.push({ userId: payload.userId, email: payload.userEmail })
    //       toUpdate = true
    //     }
    //   }
    //   index = adv.pocPubUpdate.map(function (x) { return x.userId }).indexOf(payload.userId)
    //   if (index > -1) {
    //     if (!payload.pocPubUpdate) {
    //       adv.pocPubUpdate.slice(index, 1)
    //       toUpdate = true
    //     }
    //   } else {
    //     if (payload.pocPubUpdate) {
    //       adv.pocPubUpdate.push({ userId: payload.userId, email: payload.userEmail })
    //       toUpdate = true
    //     }
    //   }
    //   index = adv.pocItemTypeUpdate.map(function (x) { return x.userId }).indexOf(payload.userId)
    //   if (index > -1) {
    //     if (!payload.pocItemTypeUpdate) {
    //       adv.pocItemTypeUpdate.slice(index, 1)
    //       toUpdate = true
    //     }
    //   } else {
    //     if (payload.pocItemTypeUpdate) {
    //       adv.pocItemTypeUpdate.push({ userId: payload.userId, email: payload.userEmail })
    //       toUpdate = true
    //     }
    //   }
    //   index = adv.reportCreate.map(function (x) { return x.userId }).indexOf(payload.userId)
    //   if (index > -1) {
    //     if (!payload.reportCreate) {
    //       adv.reportCreate.slice(index, 1)
    //       toUpdate = true
    //     }
    //   } else {
    //     if (payload.reportCreate) {
    //       adv.reportCreate.push({ userId: payload.userId, email: payload.userEmail })
    //       toUpdate = true
    //     }
    //   }
    //   if (toUpdate) {
    //     this.dispatch('officeMailNoticeModule/set', adv)
    //   }
    // },
    updateMyAdvice ({ commit, getters, rootState, rootGetters }, payload) {
      if (rootGetters.isSupervisor) {
        payload.userId = rootState.userId
        payload.userEmail = rootState.userEmail
        commit('setUserAdvice', payload)
      }
    },
    updateUserAdvice ({ commit, getters, rootState, rootGetters }, payload) {
      if (rootGetters.isAdminAcces) {
        commit('setUserAdvice', payload)
      // this.dispatch('officeMailNoticeModule/updateUserAdvice')
      }
    }
  }
}
export default officeMailNoticeModule
