<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="555" fixed persistent transition="dialog-bottom-transition">
      <v-card>
        <v-app-bar dark color="primary">
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ $t('common.parameters') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon x-large dark @click="dialog = false">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>
        <userParam/>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import userParam from './PMT_User_Param.vue'
export default {
  components: { userParam },
  props: [],
  data () {
    return {
      dialog: true
    }
  },
  watch: {
    dialog (newValue) {
      this.$store.dispatch('resize')
      this.dialog = false
      this.$emit('close')
    }
  }
}
</script>
