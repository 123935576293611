<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fixed  max-width="444" transition="dialog-bottom-transition">
      <v-card>
        <v-app-bar dark color="primary">
          <v-btn icon x-large dark @click="dialog = false">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ editedGreyBox ? $t('office-item-editor.modifytitle') : $t('office-item-editor.createtitle') }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-app-bar>
        <v-card-text>
          <v-text-field
            v-model="greyBoxName"
            class="mt-3 mx-1"
            :label="$t('office-item-editor.name')"
            outlined
            block
            @blur="$store.dispatch('resize')"
            >
          </v-text-field>
          <v-select
            class="mx-1"
            v-model="selectedGroup"
            :items="items"
            block
            outlined
            :label="$t('office-item-editor.group')"
          ></v-select>
          <!-- <v-list-item-content class="mx-auto" @click="showGetPosition = true" clearfix>
            <v-icon size="80" :color="position ? 'success' : 'error'">mdi-map-marker</v-icon>
            <v-list-item-title class="subtitle text-center">{{ $t('createreportdog.gps') }}</v-list-item-title>
          </v-list-item-content> -->
          <v-row no-gutters>
            <v-col cols="4" align="center">
              <v-btn icon x-large @click="showManagePicture = true">
                <v-badge
                  v-if="imageList.length"
                  :content="imageList.length"
                  color="green"
                  overlap
                  bottom
                >
                  <v-icon x-large color="success">mdi-folder-multiple-image</v-icon>
                </v-badge>
                <v-icon v-else x-large color="error">mdi-folder-multiple-image</v-icon>
              </v-btn>
              <p align="center">{{ $t('createreportdog.picture') }}</p>
            </v-col>
            <v-col cols="4" align="center">
              <v-btn icon x-large @click="showGetPosition = true"><v-icon x-large :color="position ? 'success' : 'error'">mdi-map-marker</v-icon></v-btn>
              <p align="center">{{ $t('createreportdog.gps') }}</p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-app-bar
          dark
          color="primary"
          bottom
          >
          <v-spacer></v-spacer>
          <v-btn
            v-if="readyToSave"
            @click="saveGreyBox"
            color="green"
            dark
          >
            save
            <v-icon class="ms-2">mdi-email-send</v-icon>
          </v-btn>
        </v-app-bar>
      </v-card>

    </v-dialog>
    <picture-manager
      v-if="showManagePicture"
      :subTitle="greyBoxName ? greyBoxName : $t('pmtparammapsinfo.greybox')"
      :param="{ type: 'officeItem', imageList: imageList }"
      @close="showManagePicture = false"
      @return-close="returnImage($event)"
    ></picture-manager>
    <pmt-position-map-set
      v-if="showGetPosition"
      :param="{ icon: require('@/assets/Icon/MapIcon/GreyBox.png'), getDistance: false }"
      @return-close="showGetPosition = false"
      @return-check="setPosition($event)"
    />
  </v-row>
</template>

<script>
export default {
  props: ['title', 'editedGreyBox'],
  data () {
    return {
      dialog: true,
      notifications: false,
      sound: true,
      widgets: false,
      selectedGroup: null,
      showGetPosition: false,
      position: null,
      showManagePicture: false,
      greyBoxName: null,
      imageList: [],
      imageListModified: false
    }
  },
  computed: {
    items () {
      const group = [
        { text: this.$t('office-item-editor.groupbrown'), value: 'brown' },
        { text: this.$t('office-item-editor.groupblue'), value: 'blue' },
        { text: this.$t('office-item-editor.groupyellow'), value: 'yellow' },
        { text: this.$t('office-item-editor.groupgreen'), value: 'green' },
        { text: this.$t('office-item-editor.groupred'), value: 'red' }
      ]
      return group
    },
    readyToSave () {
      if (this.position && this.selectedGroup && this.greyBoxName) {
        if (this.editedGreyBox) {
          if (this.position === this.editedGreyBox.position && !this.imageListModified && this.selectedGroup === this.editedGreyBox.group && this.greyBoxName === this.editedGreyBox.name) {
            return false
          } else {
            return true
          }
        } else {
          return true
        }
      } else {
        return false
      }
    }
  },
  watch: {
    dialog (newValue) {
      this.$store.dispatch('resize')
      this.dialog = false
      this.$emit('close')
    }
  },
  mounted () {
    if (this.editedGreyBox) {
      this.greyBoxName = this.editedGreyBox.name
      this.position = this.editedGreyBox.position
      this.selectedGroup = this.editedGreyBox.group
      this.imageList = this.editedGreyBox.imageList ? this.editedGreyBox.imageList : []
    }
  },
  methods: {
    setPosition (posDist) {
      // posDist.position {lat:lng} posDist.distance (num)
      // this.distance = posDist.distance
      this.position = posDist.position
      // this.pocOwner = posDist.pocOwner
      this.showGetPosition = false
    },
    returnImage (images) {
      this.showManagePicture = false
      if (images.length !== this.imageList.length) {
        this.imageListModified = true
      }
      this.imageList = images
    },
    saveGreyBox () {
      if (this.editedGreyBox) {
        this.saveModifiedGreyBox()
      } else {
        this.saveNewGreyBox()
      }
      this.imageListModified = false
    },
    saveModifiedGreyBox () {
      this.$store.dispatch('officeItemModule/set', {
        id: this.editedGreyBox.id,
        name: this.greyBoxName,
        position: this.position,
        group: this.selectedGroup,
        imageList: this.imageList,
        isActive: this.editedGreyBox.isActive
      })
        .then((officeItemId) => {
          this.$store.dispatch('showMessage', this.$t('office-item-editor.successModifyMessage'))
        })
        .catch((err) => {
          this.$store.dispatch('showAlert', err.message)
        })
      this.dialog = false
    },
    saveNewGreyBox () {
      this.$store.dispatch('officeItemModule/set', {
        type: 'greyBoxMarker',
        name: this.greyBoxName,
        position: this.position,
        group: this.selectedGroup,
        imageList: this.imageList,
        isActive: true
      })
        .then((officeItemId) => {
          this.$store.dispatch('showMessage', this.$t('office-item-editor.successModifyMessage'))
        })
        .catch((err) => {
          this.$store.dispatch('showAlert', err.message)
        })
      this.dialog = false
    }
  }
  // destroyed () {
  //   if (this.imageListTemp.length) {
  //     this.imageListTemp.forEach(picture => {
  //       var desertRef = firebase.storage().ref().child(picture.fullPath)
  //       desertRef.delete()
  //     })
  //   }
  // }
}
</script>
