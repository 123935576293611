import GrahamScan from '@lucio/graham-scan'
const mapPlugin = store => {
  store.getMarkerIconSpec = function (google, markerInfo) {
    switch (markerInfo.type) {
      case 'colisMarker':
        // return {
        //   path: 'M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z',
        //   fillColor: 'blue',
        //   fillOpacity: 0.6,
        //   strokeWeight: 0,
        //   rotation: 0,
        //   scale: 2,
        //   anchor: new google.maps.Point(15, 30)
        // }
        return { // url:mdi-toilet,
          url: store.getMapItemIconOfType('colis', markerInfo.isDone),
          scaledSize: new google.maps.Size(45, 45), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(12, 25), // anchor
          labelOrigin: new google.maps.Point(23, 55)
        }
      case 'postManMarker':
        return { // url:mdi-toilet,
          url: require('@/assets/Icon/MapIcon/postman.png'),
          scaledSize: new google.maps.Size(25, 50), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(12, 25) // anchor
        }
      case 'pocMarker':
        return {
          url: markerInfo.url,
          // size: new google.maps.Size(48, 48),
          scaledSize: new google.maps.Size(60, 60), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(24, 28), // anchor
          labelOrigin: new google.maps.Point(30, 43)
        }
      case 'placeMarker':
        return {
          url: markerInfo.url,
          // size: new google.maps.Size(48, 48),
          // scaledSize: new google.maps.Size(48, 48), // scaled size
          scaledSize: new google.maps.Size(60, 60), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(24, 28), // anchor
          // labelOrigin: new google.maps.Point(24, 32)
          labelOrigin: new google.maps.Point(30, 43)
        }
      case 'codeMarker':
        return {
          url: markerInfo.url,
          scaledSize: new google.maps.Size(60, 60), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(30, 60), // anchor
          labelOrigin: new google.maps.Point(30, 19)
        }
      case 'loopMarker':
        return {
          url: markerInfo.url,
          scaledSize: new google.maps.Size(60, 60), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(30, 60), // anchor
          labelOrigin: new google.maps.Point(30, 19)
        }
      case 'colorMarker':
        return {
          url: markerInfo.url,
          scaledSize: new google.maps.Size(60, 60), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(30, 60), // anchor
          labelOrigin: new google.maps.Point(30, 19)
        }
      case 'routeMarker':
        return {
          url: require('@/assets/Icon/MapIcon/route.png'),
          scaledSize: new google.maps.Size(50, 50), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(24, 28), // anchor
          labelOrigin: new google.maps.Point(25, 25)
        }
      case 'officeMarker':
        return {
          url: markerInfo.url,
          scaledSize: new google.maps.Size(60, 60), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(24, 28), // anchor
          labelOrigin: new google.maps.Point(30, -5)
        }
      case 'zoneMarker':
        return {
          url: markerInfo.url,
          scaledSize: new google.maps.Size(60, 60), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(24, 28), // anchor
          labelOrigin: new google.maps.Point(30, -5)
        }
      case 'multiItemMarker':
        return {
          url: markerInfo.url,
          scaledSize: new google.maps.Size(48, 48), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(24, 28), // anchor
          labelOrigin: new google.maps.Point(24, 54)
        }
      case 'pocMapItemMarker':
        return {
          url: markerInfo.url,
          scaledSize: new google.maps.Size(48, 48), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(24, 28), // anchor
          labelOrigin: new google.maps.Point(24, 54)
        }
      case 'cpMarker':
        return {
          url: require('@/assets/Icon/MapIcon/card.png'),
          scaledSize: new google.maps.Size(90, 48), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(0, 0), // anchor
          labelOrigin: new google.maps.Point(45, 24)
        }
      case 'streetMarker':
        return {
          url: require('@/assets/Icon/MapIcon/street.png'),
          scaledSize: new google.maps.Size(48, 48), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(24, 28), // anchor
          labelOrigin: new google.maps.Point(24, 54)
        }
      case 'toiletMarker':
        return {
          url: require('@/assets/Icon/MapIcon/human-male-female.png'),
          scaledSize: new google.maps.Size(48, 48), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(24, 24) // anchor
        }
      case 'greyBoxMarker':
        return {
          url: require('@/assets/Icon/MapIcon/GreyBox.png'),
          scaledSize: new google.maps.Size(40, 60), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(24, 24) // anchor
        }
      case 'cmb-direct':
      case 'cmb':
        return {
          url: store.getMapItemIconOfType(markerInfo.type, markerInfo.isDone, markerInfo.colisArray && markerInfo.colisArray.length),
          scaledSize: new google.maps.Size(48, 48), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(24, 28), // anchor
          labelOrigin: new google.maps.Point(24, 54)
        }
      case 'parking':
        return {
          url: require('@/assets/Icon/MapIcon/Item/parking.png'),
          scaledSize: new google.maps.Size(48, 48), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(24, 28), // anchor
          labelOrigin: new google.maps.Point(24, 54)
        }
      case 'park-loop':
        return {
          url: require('@/assets/Icon/MapIcon/Item/parking.png'),
          scaledSize: new google.maps.Size(48, 48), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(24, 28), // anchor
          labelOrigin: new google.maps.Point(24, 54)
        }
      case 'redBoxMarker':
        return {
          url: require('@/assets/Icon/MapIcon/redbox.png'),
          scaledSize: new google.maps.Size(30, 48), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(24, 24) // anchor
        }
      case 'parcelLockerMarker':
        return {
          url: require('@/assets/Icon/MapIcon/parcelLocker.png'),
          scaledSize: new google.maps.Size(60, 60), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(24, 24) // anchor
        }
      case 'wpMarker':
        return {
          url: markerInfo.url,
          scaledSize: new google.maps.Size(48, 48), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(24, 28), // anchor
          labelOrigin: new google.maps.Point(24, 54)
        }
      case 'alertMarker':
        return {
          url: markerInfo.url,
          scaledSize: new google.maps.Size(48, 48), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(24, 28), // anchor
          labelOrigin: new google.maps.Point(24, 54)
        }
      case 'taxi':
        return {
          url: require('@/assets/Icon/MapIcon/Item/taxi.png'),
          scaledSize: new google.maps.Size(64, 48), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(24, 28), // anchor
          labelOrigin: new google.maps.Point(34, 54)
        }
      case 'truck':
        return {
          url: require('@/assets/Icon/MapIcon/Item/truck.png'),
          scaledSize: new google.maps.Size(48, 48), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(24, 28), // anchor
          labelOrigin: new google.maps.Point(24, 54)
        }
      default:
        // // console.log('a faire type = ', markerInfo.type)
        // // console.log(markerInfo)
        return { url: markerInfo.url }
    }
  }
  store.getMapItemIconOfType = function (type, isDone, withColis) {
    switch (type.toLowerCase()) {
      case 'door':
        return require('@/assets/Icon/MapIcon/Item/door.png')
      case 'tempbox':
        return require('@/assets/Icon/MapIcon/Item/tempbox.png')
      case 'safeplace':
        return require('@/assets/Icon/MapIcon/Item/safeplace.png')
      case 'cntr':
        return require('@/assets/Icon/MapIcon/Item/comptoir.png')
      case 'ksk':
        return require('@/assets/Icon/MapIcon/Item/kiosque.png')
      case 'dflb':
        return require('@/assets/Icon/MapIcon/Item/boiteSolo.png')
      case 'cmb-direct':
      case 'cmb':
        if (withColis) {
          if (isDone) {
            return require('@/assets/Icon/MapIcon/Item/bpComColis-Done.png')
          } else {
            return require('@/assets/Icon/MapIcon/Item/bpComColis.png')
          }
        } else {
          if (isDone) {
            return require('@/assets/Icon/MapIcon/Item/bpCom-Done.png')
          } else {
            return require('@/assets/Icon/MapIcon/Item/bpCom.png')
          }
        }
      case 'lba':
        return require('@/assets/Icon/MapIcon/Item/boiteMulti.png')
      case 'aptlba':
        return require('@/assets/Icon/MapIcon/Item/boiteMulti.png')
      case 'dtd':
        return require('@/assets/Icon/MapIcon/Item/boiteMono.png')
      case 'rmb':
        return require('@/assets/Icon/MapIcon/Item/boiteRural.png')
      case 'park-loop':
        return require('@/assets/Icon/MapIcon/Item/parking.png')
      case 'loop':
        return require('@/assets/Icon/MapIcon/Item/loop.png')
      case 'park-direct':
        return require('@/assets/Icon/MapIcon/Item/parking.png')
      case 'colis-zone':
      case 'ship-box':
      case 'colis':
        if (isDone) {
          return require('@/assets/Icon/MapIcon/Item/ship-box-Done.png')
        } else {
          return require('@/assets/Icon/MapIcon/Item/ship-box.png')
        }
      case 'greybox':
        return require('@/assets/Icon/MapIcon/GreyBox.png')
      case 'parcellocker':
        return require('@/assets/Icon/MapIcon/parcelLocker.png')
      case 'taxi-start':
      case 'taxi-finish':
      case 'taxi-direct':
        return require('@/assets/Icon/MapIcon/Item/taxi.png')
      default:
        return require('@/assets/Icon/MapIcon/Item/boiteSolo.png')
    }
  }
  store.getMarkerLabelSize = function (m) {
    switch (m.type) {
      case 'colisMarker':
        return '20px'
      default:
        switch (m.label.length) {
          case 1:
            return '22px'
          case 2:
            return '20px'
          case 3:
            return '18px'
          case 4:
            return '16px'
          case 5:
            return '16px'
          case 6:
            return '18px'
          case 7:
            return '18px'
          default:
            return '18px'
        }
    }
  }
  store.getMarkerLabelFontWeight = function (m) {
    switch (m.type) {
      case 'colisMarker':
        return '900'
      default:
        return '500'
    }
  }
  store.getMapModeIcon = function (mode) {
    switch (mode) {
      case 'route-info':
        return 'mdi-information-variant'
      case 'track-me':
        return 'mdi-target-account'
      case 'track-me-on':
        return 'mdi-target-account'
      case 'track-me-off':
        return 'mdi-target-account'
      case 'direction':
        return 'mdi-directions'
      case 'road-book':
        return 'mdi-order-bool-ascending-variant'
      default:
        return ''
    }
  }
  store.getBoundsOfArray = function (posArray) {
    const bounds = new store.getters.getGoogle.maps.LatLngBounds()
    posArray.forEach(pos => {
      bounds.extend(pos)
    })
    return bounds
  }
  store.getGoogleMarker = function (google, markerInfo) {
    function labelColorText () {
      switch (markerInfo.type) {
        case 'streetMarker':
          return 'black'
        case 'park-loop':
          return 'black'
        case 'pocMapItemMarker':
          return 'red'
        case 'multiItemMarker':
          return 'red'
        case 'cmb':
        case 'cmb-direct':
          return 'black'
        case 'loopMarker':
          return 'black'
        case 'routeMarker':
          if (markerInfo.routeId === store.getters.getSelectedRouteId) {
            return 'green'
          } else {
            return 'black'
          }
        case 'colisMarker':
          return 'brown'
        case 'taxi':
          return 'black'
        default:
          return 'white'
      }
    }
    function collisionBehavior () {
      switch (markerInfo.type) {
        case 'streetMarker':
          return google.maps.CollisionBehavior.REQUIRED_AND_HIDES_OPTIONAL
        case 'toiletMarker':
          return google.maps.CollisionBehavior.OPTIONAL_AND_HIDES_LOWER_PRIORITY
        case 'placeMarker':
          return google.maps.CollisionBehavior.REQUIRED_AND_HIDES_OPTIONAL
        default:
          return google.maps.CollisionBehavior.REQUIRED_AND_HIDES_OPTIONAL
      }
    }
    function getZIndex () {
      switch (markerInfo.type) {
        case 'streetMarker':
          return 2000 - Number(markerInfo.pos)
        case 'cpMarker':
          return 2000 - Number(markerInfo.pos)
        case 'codeMarker':
          return 2000 - Number(markerInfo.pos)
        case 'colorMarker':
          return 2000 - Number(markerInfo.pos)
        case 'routeMarker':
          return 2000 - Number(markerInfo.pos)
        case 'officeMarker':
          return 2000
        case 'zoneMarker':
          return 2000
        case 'placeMarker':
          if (markerInfo.seqPosTot) {
            return 5000 - markerInfo.seqPosTot
          } else {
            return 5000
          }
        case 'pocMarker':
          if (markerInfo.seqPosTot) {
            return 5000 - markerInfo.seqPosTot
          } else {
            return 5000
          }
        case 'toiletMarker':
          return 500
        case 'colisMarker':
          return 6000
        default:
          return 700
      }
    }
    // if (markerInfo.type === 'colisMarker') {
    //   // console.log('-----trace colis--------')
    //   // console.log(markerInfo)
    // }
    // if (markerInfo.type === 'cmb-direct') {
    //   // console.log('-----trace cmb-direct--------')
    //   // console.log(markerInfo)
    // }
    return new google.maps.Marker({
      label: markerInfo.label ? { text: markerInfo.label, fontSize: store.getMarkerLabelSize(markerInfo), fontWeight: store.getMarkerLabelFontWeight(markerInfo), color: labelColorText() } : null,
      map: markerInfo.map ? markerInfo.map : null,
      visible: false,
      icon: store.getMarkerIconSpec(google, markerInfo),
      zIndex: getZIndex(),
      position: markerInfo.position ? markerInfo.position : null,
      // optimized: true,
      collisionBehavior: collisionBehavior()
    })
  }
  store.getGooglePolygon = function (google, posArray, colorId) {
    // Construct the polygon.
    var gsConvex = new GrahamScan()
    gsConvex.setPoints(posArray.map(x => { return [x.lat, x.lng] }))

    return new google.maps.Polygon({
      paths: gsConvex.getHull().map(x => { return { lat: x[0], lng: x[1] } }),
      strokeColor: store.getColorCode(colorId),
      strokeOpacity: 0.35,
      strokeWeight: 2,
      fillColor: store.getColorCode(colorId),
      fillOpacity: 0.2,
      zIndex: 1
    })
    // bermudaTriangle.setMap(map)
  }
  store.getLineMarker = function (posArray, colorId) {
    const google = store.getters.getGoogle
    const lineSymbol = {
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW
    }
    // Create the polyline and add the symbol via the 'icons' property.
    return {
      line: new google.maps.Polyline({
        path: posArray,
        geodesic: true,
        strokeColor: this.getColorName(colorId),
        strokeOpacity: 1.0,
        strokeWeight: 6
      }),
      lineArrow: new google.maps.Polyline({
        path: posArray,
        geodesic: true,
        strokeColor: this.getColorName(colorId),
        strokeOpacity: 1.0,
        strokeWeight: 6,
        icons: [
          {
            icon: lineSymbol,
            offset: '100%'
          }
        ]
      })
    }
  }
  store.getCodeLineMarker = function (google, lineInfo) {
    const lineSymbol = {
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW
    }

    // Create the polyline and add the symbol via the 'icons' property.
    const positionSorted = lineInfo.posArray.sort(function (a, b) { return a.seqPosTot - b.seqPosTot }).map(x => { return x.position })
    return {
      line: new google.maps.Polyline({
        path: positionSorted,
        geodesic: true,
        strokeColor: this.getColorName(lineInfo.colorId),
        strokeOpacity: 1.0,
        strokeWeight: 6
      }),
      lineArrow: new google.maps.Polyline({
        path: positionSorted,
        geodesic: true,
        strokeColor: this.getColorName(lineInfo.colorId),
        strokeOpacity: 1.0,
        strokeWeight: 6,
        icons: [
          {
            icon: lineSymbol,
            offset: '100%'
          }
        ]
      })
    }
  }
  store.getTravelItemMarker = function (item) {
    switch (item.type) {
      case 'sector':
        if (item.sectorId) {
          return (item.sectorId === 1) ? (this.$t('travel-group.sector') + ' 1 (' + this.$t('common.start') + ')') : (this.$t('travel-group.sector') + ' ' + item.sectorId) + ' ' + (item.name ? item.name : '')
        } else {
          return this.$t('travel-group.sector') + ' ' + item.name
        }
        // return (item.sectorId === 1) ? (this.$t('travel-group.sector') + ' 1 (' + this.$t('common.start') + ')') : (this.$t('travel-group.sector') + ' ' + item.sectorId) + ' ' + item.name
      case 'street':
        return item.street
      case 'cp':
        return item.cp
      case 'multi':
        // return this.getMultiName(item.multiId)
        return this.$t('pocitem.' + this.$store.state.officeItemMultiModule.data[item.multiId].type)
        // return this.$store.state.officeItemMultiModule.data[item.multiId].name ? this.$store.state.officeItemMultiModule.data[item.multiId].name : this.$store.state.officeItemMultiModule.data[item.multiId].desc
      case 'code':
        return 'Code: ' + item.codeName
        // stat = this.$store.getters.getCumulTotalStatFromPocIdArray(this.getPocOfGroup(item.items).map(x => { return x.id }))
        // return stat.pub + ' / ' + stat.pocActive + ' (' + stat.vacant + ')'
        // return 'Code: ' + item.codeName + ' , PDR: ' + stat.pub + ' / ' + stat.pocActive + ' (' + stat.vacant + ')'
      case 'color':
        return this.$t('travel.color-' + item.colorId)
      case 'poc':
        return store.state.pocModule.data[item.id].pdrListAddress
      case 'start-taxi':
        return this.$t('common.start')
      case 'greyBox':
        return item.name
      case 'redBox':
        return item.name
      case 'parcelLocker':
        return item.name
      case 'wp':
        if (item.wp) {
          if (item.wp.multiId) {
            return this.$t('pocitem.' + this.$store.state.officeItemMultiModule.data[item.wp.multiId].type)
          } else if (item.wp.type !== 'taxi-start' && item.wp.type !== 'park-loop' && item.wp.type !== 'park-direct') {
            return this.$t('pocitem.' + item.wp.type)
          } else {
            return this.$t('wp.' + item.wp.type + '-title')
          }
        } else {
          return 'WayPoint'
        }
      default:
        // // console.log('a faire item = ')
        // // console.log(item)
        return 'a faire'
    }
  }
  store.getStepMarker = function (tpType, step, travelItems, tWp) {
    const stepItems = travelItems.filter(x => x.stepId === step.stepId)

    let stepColorId = 0
    const colorArray = []
    var stepBoundsPosArray = []
    // const stepLineArray = []
    // let slInfo = {
    //   colorId: null
    // }
    // let steplineColor
    // let stepLineIdx = null

    if (colorArray.length === 1) {
      stepColorId = colorArray[0]
    }
    // // console.log(step)
    // // console.log(travelItems.filter(x => x.id === step.fromItemId))
    switch (tpType) {
      case 'taxi':
        switch (step.type) {
          case 'loop':
            // // console.log(store.getTravelStepItemsColorLine(stepItems).map(x => { return store.getLineMarker(x.posArray, x.colorId) }))
            stepBoundsPosArray = stepBoundsPosArray.concat(stepItems.filter(x => x.type === 'poc').map(x => {
              if (store.state.pocModule.data[x.id] && store.state.pocModule.data[x.id].homeLocation) {
                return store.state.pocModule.data[x.id].homeLocation
              } else {
                // // console.log('trace pas homelocation')
                // // console.log(x)
                // // console.log(store.state.pocModule.data[x.id])
                return null
              }
            }))
            stepBoundsPosArray = stepBoundsPosArray.concat(travelItems.filter(x => x.id === step.fromItemId || x.id === step.toItemId).map(x => { return x.markerInfo.position }))
            return {
              type: 'loopMarker',
              stepId: step.stepId,
              isOpenInfoWindow: false,
              marker: store.getGoogleMarker(store.getters.getGoogle, {
                type: 'loopMarker',
                label: String(step.pos),
                url: store.getters.getColorIconUrl(stepColorId),
                position: store.getPosCenterOfPocArray(stepItems.filter(x => x.type === 'poc').map(x => { return store.state.pocModule.data[x.id] }))
              }),
              // lineMarker: store.getTravelStepItemsCodeLine(stepItems).map(x => { return { marker: store.getLineMarker(x.posArray, x.colorId) } }),
              lineMarker: store.getTravelStepItemsColorLine(stepItems).map(x => { return { marker: store.getLineMarker(x.posArray, x.colorId) } }),
              // [{
              //   marker: store.getLineMarker(stepItems.filter(x => x.type === 'poc').map(x => { return store.state.pocModule.data[x.id].homeLocation }), stepColorId)
              // }],
              bounds: store.getBoundsOfArray(stepBoundsPosArray)
            }
          default:
            // // console.log('a faire step.type = ' + step.type)
            return null
        }
      case 'truck':
        switch (step.type) {
          case 'loop':
            // // console.log(store.getTravelStepItemsColorLine(stepItems).map(x => { return store.getLineMarker(x.posArray, x.colorId) }))
            stepBoundsPosArray = stepBoundsPosArray.concat(stepItems.filter(x => x.type === 'poc').map(x => {
              if (store.state.pocModule.data[x.id] && store.state.pocModule.data[x.id].homeLocation) {
                return store.state.pocModule.data[x.id].homeLocation
              } else {
                // // console.log('trace pas homelocation')
                // // console.log(x)
                // // console.log(store.state.pocModule.data[x.id])
                return null
              }
            }))
            stepBoundsPosArray = stepBoundsPosArray.concat(travelItems.filter(x => x.id === step.fromItemId || x.id === step.toItemId).map(x => { return x.markerInfo.position }))
            return {
              type: 'loopMarker',
              stepId: step.stepId,
              isOpenInfoWindow: false,
              marker: store.getGoogleMarker(store.getters.getGoogle, {
                type: 'loopMarker',
                label: String(step.pos),
                url: store.getters.getColorIconUrl(stepColorId),
                position: store.getPosCenterOfPocArray(stepItems.filter(x => x.type === 'poc').map(x => { return store.state.pocModule.data[x.id] }))
              }),
              // lineMarker: store.getTravelStepItemsCodeLine(stepItems).map(x => { return { marker: store.getLineMarker(x.posArray, x.colorId) } }),
              lineMarker: store.getTravelStepItemsColorLine(stepItems).map(x => { return { marker: store.getLineMarker(x.posArray, x.colorId) } }),
              // [{
              //   marker: store.getLineMarker(stepItems.filter(x => x.type === 'poc').map(x => { return store.state.pocModule.data[x.id].homeLocation }), stepColorId)
              // }],
              bounds: store.getBoundsOfArray(stepBoundsPosArray)
            }
          case 'cmb-direct':
            var wpIdx = tWp.map(x => { return x.wpId }).indexOf(step.fromWpId)
            if (wpIdx > -1) {
              step.position = tWp[wpIdx].loc
              step.label = 'test'
              return {
                type: 'cmb-directMarker',
                stepId: step.stepId,
                isOpenInfoWindow: false,
                // marker: tWp[wpIdx].marker,
                marker: store.getGoogleMarker(store.getters.getGoogle, step),
                // bounds: store.getBoundsOfArray([tWp[wpIdx].loc]),
                bounds: null,
                lineMarker: null
              }
            } else {
              // console.log('erreur wp pas existant')
              return null
            }
          case 'colis-zone':
            if (step.colisArray && step.colisArray.length) {
              // console.log(step)
              if (step.colisArray.length > 1) {
                return {
                  type: 'colis-zoneMarker',
                  stepId: step.stepId,
                  isOpenInfoWindow: false,
                  markerArray: step.colisArray,
                  bounds: store.getBoundsOfArray(step.colisArray.map(x => { return x.position })),
                  // bounds: null,
                  lineMarker: null
                }
              } else {
                return {
                  type: 'colis-zoneMarker',
                  stepId: step.stepId,
                  isOpenInfoWindow: false,
                  marker: step.colisArray[0].marker,
                  // bounds: store.getBoundsOfArray([tWp[wpIdx].loc]),
                  bounds: null,
                  lineMarker: null
                }
              }
            } else {
              return null
            }
          default:
            // console.log('a faire step.type = ' + step.type)
            return null
        }
      default:
        return null
    }
  }
  store.getPocMarker = function (poc) {
    // { id: '', pdrListAddress: '', pdrType: '', vacant: '', pub: '', colorId: '', codeId: '', codeName: '', pdrListPostalCode: '', homeLocation: '', seqPosTot: '' }
    var styleName
    var colorName
    var topName
    const doorTemp = poc.pdrListAddress.split(' ')[0]
    const door = doorTemp.split('-').length > 1 ? doorTemp.split('-')[1] : doorTemp
    const street = poc.pdrListAddress.replace(doorTemp, '').trim()
    switch (poc.pdrType) {
      case 'Hou/Dom':
        styleName = 'Hou'
        break
      case 'Apt/App':
        styleName = 'App'
        break
      case 'Bus/com':
        styleName = 'Bus'
        break
      default:
        styleName = 'Farm'
        break
    }
    if (poc.vacant) { topName = 'green' } else if (poc.pub) { topName = 'black' } else { topName = 'red' }
    if (poc.colorId === 1) { colorName = 'orange' } else if (poc.colorId === 2) { colorName = 'pink' } else { colorName = 'blue' }
    const marker = store.getGoogleMarker(store.getters.getGoogle, {
      type: 'pocMarker',
      label: door,
      seqPosTot: poc.seqPosTot,
      map: null,
      url: require('@/assets/Icon/MapIcon/POC/' + styleName + '-' + colorName + '-t' + topName + '.png')
    })
    marker.setPosition(poc.homeLocation)

    return {
      type: 'pocMarker',
      position: poc.homeLocation,
      pocId: poc.id,
      cp: poc.pdrListPostalCode,
      street: street,
      codeId: poc.codeId,
      codeName: poc.codeName,
      colorId: poc.colorId,
      seqPosTot: poc.seqPosTot,
      marker: marker
    }
  }
  store.getTaxiMarker = function (item) {
    return {
      type: 'taxiMarker',
      taxiType: item.type,
      position: item.loc,
      isOpenInfoWindow: false,
      marker: store.getGoogleMarker(store.getters.getGoogle, {
        type: 'taxi',
        url: store.getMapItemIconOfType(item.type),
        label: 'start',
        position: item.loc
      })
    }
  }
  store.getItemMarker = function (item) {
    switch (item.type) {
      case 'taxi-start':
      case 'taxi-finish':
      case 'taxi-direct':
        return store.getTaxiMarker(item)
      default:
        return null
    }
  }
  store.getTravelWayPointMarker = function (twp) {
    return {
      type: 'wpMarker',
      wpType: twp.type,
      position: twp.loc,
      wpId: twp.wpId,
      isOpenInfoWindow: false,
      marker: store.getGoogleMarker(store.getters.getGoogle, {
        type: twp.type,
        url: store.getMapItemIconOfType(twp.type),
        position: twp.loc
      })
    }
  }
}

export default mapPlugin
