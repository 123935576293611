<template>
  <div class="home">
    <v-card style="width: 100vw;height: 100vh" class="overflow-y-auto">
      <v-card-title>
        <v-spacer></v-spacer>
        {{ $t('common.udl') }} : {{ udl.udlName ? udl.udlName : udl.udlId }}
        <v-spacer></v-spacer>
      </v-card-title>
      <!-- <v-card-subtitle>{{ siteName }}</v-card-subtitle> -->
      <v-card-text>
        <v-list nav>
          <v-list-item
            v-for="rta in udl.rta"
            :key="rta.rtaId"
            elevation-5
            @click="rtaClick(rta)"
          >
            <v-list-item-avatar>
                {{ $t('common.rta') }} : {{ rta.rtaId }}
            </v-list-item-avatar>
            <v-list-item-content
              v-if="rta.pocId && $store.state.pocModule.data[rta.pocId]"
              two-line
              :class="$store.state.pocModule.data[rta.pocId].vacant ? 'green lighten-2' : !$store.state.pocModule.data[rta.pocId].pub ?  'red lighten-2' : null"
            >
              <p
                class="subtitle-2 mt-1"
              >
                {{ $store.state.pocModule.data[rta.pocId].pdrListAddress }}
              </p>
              <v-list-item-subtitle v-if="$store.state.pocModule.data[rta.pocId].vacant">{{ $t('common.vacant') }}</v-list-item-subtitle>
              <v-list-item-subtitle v-else-if="!$store.state.pocModule.data[rta.pocId].pub">{{ $t('common.nopub') }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content v-else>
              <p class="subtitle-2 mt-1">
                {{ rta.civic && rta.street ? rta.civic + ' ' + rta.street : $t('common.empty') }}
              </p>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-title class="mt-15 mb-15">
          <v-spacer></v-spacer>
          {{ $t('common.udl') }} : {{ udl.udlName ? udl.udlName : udl.udlId }}
          <v-spacer></v-spacer>
        </v-card-title>
      </v-card-text>
      <!-- <v-card-actions>{{ $t('common.udl') }} : {{ udl.udlName ? udl.udlName : udl.udlId }}</v-card-actions> -->
    </v-card>
  </div>
</template>

<script>
export default {
  props: ['udl', 'siteName', 'readOnly'],
  methods: {
    rtaClick (rta) {
      if (!this.readOnly) {
        this.$emit('selectRta', rta)
      }
    }
  },
  data () {
    return {
    }
  }
}
</script>
