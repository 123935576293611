<template fixed>
<v-container ref="mainreateusercard" fixed :style="$store.getters.contentStyle" class="overflow-y-auto overflow-x-hidden">
  <v-layout row v-if="authError">
    <v-flex xs12 sm6 offset-sm3>
      <alert-cmp @dismissed="onDismissed" :err="authError"></alert-cmp>
    </v-flex>
  </v-layout>
  <v-layout row>
    <v-flex xs12 sm6 offset-sm3>
      <v-card fixed>
        <v-toolbar
          color="primary"
          dark
          flat
          fixed
        >
          <v-btn icon x-large @click="$store.getters.EventBus.$emit('selectLocalAdminComponent', ('pmt-menu-admin-local'))"><v-icon x-large>mdi-arrow-left-circle</v-icon></v-btn>
          <h3>Menu</h3>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ $t('createuser.title') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-btn icon x-large @click="clear()"><v-icon x-large>mdi-clear</v-icon></v-btn> -->
          <!-- <v-btn @click="$i18n.locale = $t('common.otherlocale')">{{ $t('common.otherlocale') }}</v-btn> -->
        </v-toolbar>

        <v-card-text>
          <v-container>
            <form @submit.prevent="onSignup">
              <v-layout row class="mb-n4">
                <v-flex xs12>
                  <v-text-field
                    filled
                    name="empid"
                    @focus="autoScrollTo('#empid')"
                    :label="$t('createuser.empid')"
                    id="empid"
                    v-model="empid"
                    type="text"
                    :rules="[ validEmpId ]"
                    required
                    attach="mainreateusercard"
                  >
                  </v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs12>
                  <v-text-field
                    filled
                    name="email"
                    :label="$t('createuser.mail')"
                    id="email"
                    v-model="email"
                    type="email"
                    @focus="autoScrollTo('#email')"
                    required></v-text-field>
                </v-flex>
              </v-layout>
              <v-divider class="mb-5"/>
              <v-layout row class="mb-n4">
                <v-flex xs12>
                  <v-text-field
                    filled
                    name="fname"
                    :label="$t('createuser.fname')"
                    id="fname"
                    v-model="fname"
                    type="text"
                    @focus="autoScrollTo('#fname')"
                    required></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row class="mb-n4">
                <v-flex xs12>
                  <v-text-field
                    filled
                    name="lname"
                    :label="$t('createuser.lname')"
                    id="lname"
                    v-model="lname"
                    type="text"
                    @focus="autoScrollTo('#lname')"
                    required></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs12>
                  <v-text-field
                    filled
                    name="phone"
                    :label="$t('createuser.phone')"
                    id="phone"
                    :rules="[ validPhone ]"
                    v-model="phone"
                    @focus="autoScrollTo('#phone')"
                    type="tel"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-divider class="mb-5"/>
              <v-layout row class="mb-n4">
                <v-flex xs12>
                  <v-select
                    filled
                    :label="$t('createuser.emptype')"
                    v-model="userType"
                    :items="userTypeItems"
                    required
                    attach="attach"
                  >
                  </v-select>
                    <template v-slot:append-outer>
                      <div id="attach"></div>
                    </template>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs12>
                  <v-select
                    filled
                    :label="$t('common.userLocale')"
                    v-model="locale"
                    :items="localeItems"
                    required
                    attach="attach"
                  ></v-select>
                    <template v-slot:append-outer>
                      <div id="attach"></div>
                    </template>
                </v-flex>
              </v-layout>
              <v-divider class="mb-8 mt-5"/>
              <v-layout row>
                <v-flex xs12>
                  <v-btn type="submit" block x-large color="primary">{{ $t('createuser.create') }}</v-btn>
                </v-flex>
              </v-layout>
              <v-card class="mt-8" flat height="500"></v-card>
            </form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</v-container>
</template>

<script>
export default {
  methods: {
    autoScrollTo (idTarget) {
      this.$vuetify.goTo(idTarget, { container: this.$refs.mainreateusercard })
    },
    clear () {
      this.fname = null
      this.lname = null
      this.empid = null
      this.email = null
      this.phone = null
      this.userType = null
    },
    validEmpId (empId) {
      // if (this.$store.getters['userModule/getUserByEmpId'](('00000000000' + empId).substring(('00000000000' + empId).length - 9))) {
      //   return 'already exist user'
      // } else if (empId.length < 6) {
      if (empId) {
        if (empId.length < 7) {
          return 'invalid empId'
        } else {
          return true
        }
      } else {
        return true
      }
    },
    validPhone (phone) {
      if (phone) {
        if (this.$store.formatPhoneNumber(phone)) {
          return true
        } else {
          return this.$t('common.invalidPhone')
        }
      } else {
        return true
      }
    },
    onSignup () {
      const tempid = '00000000000' + this.empid
      this.empid = tempid.substring(tempid.length - 9)

      const taskdetail = {
        type: 'createUser',
        userData: {
          empid: this.empid,
          fname: this.fname,
          email: this.email,
          gid: null,
          lname: this.lname,
          officeselected: this.$store.getters.getSelectedOfficeId,
          zoneselected: this.$store.getters.getSelectedZoneId,
          routeselected: this.$store.getters.getSelectedRouteId,
          sequenceselected: this.$store.getters.getSelectedSequenceId,
          phone: this.phone,
          userType: this.userType,
          param: {
            guardianMode: {
              active: false,
              noteActive: false,
              dangerActive: true
            },
            locale: this.locale,
            mapInfo: {
              showColorLine: true,
              showDog: true,
              showGreyBox: true,
              showParking: true,
              showToilet: true,
              showPocMarker: true,
              showMe: true,
              trackMe: false
            }
          },
          selection: {
            code: null,
            color: null,
            office: this.$store.getters.getSelectedOfficeId,
            pdr: null,
            place: null,
            route: null,
            zone: this.$store.getters.getSelectedZoneId
          }
        }
      }
      this.$store.dispatch('userModule/fetchAndAdd', {
        where: [
          ['empid', '==', this.empid]
        ]
      })
        .then((status) => {
          if (this.$store.getters['userModule/getUserByEmpId'](this.empid)) {
            this.$store.dispatch('showAlert', this.$t('createuser.empidexist'))
          } else {
            this.$store.dispatch('userModule/fetchAndAdd', {
              where: [
                ['email', '==', this.email]
              ]
            })
              .then((status) => {
                if (this.$store.getters['userModule/getUserByEmpId'](this.empid)) {
                  this.$store.dispatch('showAlert', this.$t('createuser.emailexist'))
                } else {
                  this.$store.dispatch('adminModule/set', taskdetail)
                  this.$store.dispatch('showMessage', this.$t('createuser.usercreatesuccess'))
                  this.clear()
                }
              })
          }
        })
        .catch((err) => {
          this.$store.dispatch('showAlert', err.message)
        })
    },
    onDismissed () {
      this.$store.dispatch('clearAuthError')
    }
  },
  computed: {
    userTypeItems () {
      const items = []
      const userTypeArray = this.$store.getters['paramModule/getUserType']
      for (let i = 0; i < userTypeArray.length; i++) {
        const type = userTypeArray[i]
        items.push({ text: this.$t('usertype.' + type), value: type })
      }
      return items
    },
    user () {
      return this.$store.user
    },
    authError () {
      return this.$store.getters.authError
    }
  },
  watch: {
    user (value) {
      if (value !== null && value !== undefined) {
        this.$router.push('/')
      }
    }
  },
  data () {
    return {
      email: '',
      fname: '',
      lname: '',
      empid: '',
      phone: '',
      userType: null,
      locale: this.$store.getters.getLocale,
      localeItems: [{
        text: this.$t('common.fr'),
        value: 'fr'
      }, {
        text: this.$t('common.en'),
        value: 'en'
      }]
    }
  }
}
</script>
