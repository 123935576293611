<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="344" scrollable fixed transition="dialog-bottom-transition">
      <v-card>
        <v-app-bar dark color="primary">
          <v-btn icon x-large dark @click="dialog = false">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ $t('pmt-create-map-direction-popup.title') }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-app-bar>
        <v-card-text>
          <v-row class="mt-2">
            <v-spacer></v-spacer>
            <v-btn-toggle v-model="transportType" mandatory>
              <v-btn x-large>
                <v-icon x-large>mdi-car</v-icon>
              </v-btn>

              <v-btn x-large>
                <v-icon x-large>mdi-walk</v-icon>
              </v-btn>
            </v-btn-toggle>
            <v-spacer></v-spacer>
          </v-row>
          <v-divider class="mt-3"/>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="start"
                :items="items"
                :label="$t('pmt-create-map-direction-popup.start')"
                solo
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="end"
                :items="items"
                :label="$t('pmt-create-map-direction-popup.end')"
                solo
              ></v-select>
            </v-col>
          </v-row>
          <v-divider class="mt-n5"/>
          <v-row>
            <v-spacer></v-spacer>
            <v-card-subtitle>
              {{ $t('pmt-create-map-direction-popup.waypoint') }}
            </v-card-subtitle>
            <v-spacer></v-spacer>
          </v-row>
          <v-btn block @click="showSelectPdr = true" x-large>
            {{ selectPdrList.length ? selectPdrList.length + ' ' + $t('pmt-create-map-direction-popup.poc') : $t('pmt-create-map-direction-popup.poc')}}
          </v-btn>

          <v-btn block @click="showSelectGreyBox = true" x-large>
            {{ selectGreyBoxList.length ? selectGreyBoxList.length + ' ' + $t('pmt-create-map-direction-popup.greybox') : $t('pmt-create-map-direction-popup.greybox')}}
          </v-btn>
          <v-btn block @click="showSelectParking = true" x-large>
            {{ $t('pmt-create-map-direction-popup.parking') }}
          </v-btn>

          <v-btn block @click="showSelectReport = true" x-large>
            {{ $t('pmt-create-map-direction-popup.report') }}
          </v-btn>
          <v-divider class="mt-3 mb-3"/>
          <v-row>
            <v-spacer></v-spacer>
            <v-card-subtitle>
              {{ $t('pmt-create-map-direction-popup.trafictitle') }}
            </v-card-subtitle>
            <v-spacer></v-spacer>
          </v-row>
          <v-card class="mt-2">
            <v-card-text>
            <v-switch
              v-model="trafic"
              class="ms-1 mb-n3"
              :label="$t('pmt-create-map-direction-popup.trafic')"
            >
            </v-switch>
            </v-card-text>
            <v-divider v-show="trafic"/>
            <h4 v-show="trafic" class="text-center">{{ $t('pmt-create-map-direction-popup.departure') }}</h4>
            <v-row v-show="trafic" class="mx-1">
              <v-btn icon large @click="showGetStartDate = true"><v-icon large>mdi-calendar</v-icon></v-btn>
              <v-spacer></v-spacer>
              <v-col cols="6">
                <v-list-item-content class="mt-n3 text-center mx-n1">
                  <v-list-item-title>{{ travelStartDateDisplay }}</v-list-item-title>
                  <v-list-item-title>{{ travelStartTimeDisplay }}</v-list-item-title>
                </v-list-item-content>
              </v-col>
              <v-spacer></v-spacer>
              <v-btn icon large @click="showGetStartTime = true"><v-icon large>mdi-clock-outline</v-icon></v-btn>
            </v-row>
            <!-- <v-time-picker v-show="trafic" full-width v-model="timeStartPicker"></v-time-picker> -->
          </v-card>
          <v-divider class="mt-3"/>
        </v-card-text>
        <v-app-bar dark color="primary" bottom>
          <v-spacer></v-spacer>
          <v-btn x-large dark v-show="isValid" @click="createDirection">
            {{ $t('pmt-create-map-direction-popup.title') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-app-bar>
      </v-card>
    </v-dialog>
    <pmt-picker-date-popup v-if="showGetStartDate" :presetDate="travelStartDateTime" @close="showGetStartDate = false" @return-value="setNewDateTime($event)" :title="$t('pmt-create-map-direction-popup.date')"></pmt-picker-date-popup>
    <pmt-picker-time-popup v-if="showGetStartTime" :presetDate="travelStartDateTime" @close="showGetStartTime = false" @return-value="setNewDateTime($event)" :title="$t('pmt-create-map-direction-popup.time')"></pmt-picker-time-popup>

    <pmt-select-greybox
      v-if="showSelectGreyBox"
      :title="$t('createreportdog.selectgreyboxpopuptitle')"
      :selItem="selectGreyBoxList"
      @close="showSelectGreyBox = false"
      @select="selectGreyBoxList = $event"
    ></pmt-select-greybox>
    <pmt-select-pdr
      v-if="showSelectPdr"
      :title="$t('createreportdog.selectpdrpopuptitle')"
      :selItem="selectPdrList.length ? selectPdrList : null"
      @close="showSelectPdr = false"
      @select="selectPdrList = $event"
    ></pmt-select-pdr>
  </v-row>
</template>

<script>
export default {
  props: ['title'],
  data () {
    return {
      items: [
        { text: this.$t('pmt-create-map-direction-popup.myposition'), value: 1 },
        { text: this.$t('pmt-create-map-direction-popup.office'), value: 2 },
        { text: this.$t('pmt-create-map-direction-popup.route'), value: 3 }
      ],
      timeStartPicker: null,
      travelStartDateTime: null,
      showSelectPdr: false,
      showSelectGreyBox: false,
      showSelectParking: false,
      showSelectReport: false,
      showGetStartTime: false,
      showGetStartDate: false,
      selectPdrList: [],
      selectParkingList: [],
      selectGreyBoxList: [],
      selectReportList: [],
      dialog: true,
      trafic: false,
      notifications: false,
      transportType: 0,
      sound: true,
      start: null,
      end: null,
      widgets: false
    }
  },
  watch: {
    dialog (newValue) {
      this.dialog = false
      this.$store.dispatch('resize')
      this.$emit('close')
    }
  },
  computed: {
    travelStartDateDisplay () {
      if (this.travelStartDateTime) {
        const year = this.travelStartDateTime.getFullYear()
        const month = '00' + (this.travelStartDateTime.getMonth() + 1)
        const day = '00' + (this.travelStartDateTime.getDate())
        return year + '/' + month.substr(month.length - 2, 2) + '/' + day.substr(day.length - 2, 2)
      } else {
        return null
      }
    },
    travelStartTimeDisplay () {
      if (this.travelStartDateTime) {
        const hours = '00' + (this.travelStartDateTime.getHours())
        const minutes = '00' + (this.travelStartDateTime.getMinutes())
        return hours.substr(hours.length - 2, 2) + ':' + minutes.substr(minutes.length - 2, 2)
      } else {
        return null
      }
    },
    isValid () {
      if (!this.start) {
        return false
      } else if (!this.end) {
        return false
      } else if (!this.selectPdrList.length && !this.selectParkingList.length && !this.selectGreyBoxList.length && !this.selectReportList.length) {
        return false
      } else if (this.trafic && !this.travelStartDateTime) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    setNewDateTime (date) {
      this.travelStartDateTime = null
      if (date < Date.now()) {
        if ((Date.now() - date) > 500000) {
          this.$store.dispatch('showAlert', this.$t('pmt-create-map-direction-popup.alertolddate'))
        }
        this.travelStartDateTime = new Date(Date.now())
      } else {
        this.travelStartDateTime = date
      }

      this.showGetStartDate = false
      this.showGetStartTime = false
    },
    createDirection () {
      const google = this.$store.getters.getGoogle
      const EventBus = this.$store.getters.EventBus
      var directionsService = new google.maps.DirectionsService()
      var directionsRenderer = new google.maps.DirectionsRenderer()
      // var chicago = new google.maps.LatLng(41.850033, -87.6500523);
      // var mapOptions = {
      //   zoom:7,
      //   center: chicago
      // }
      // var map = new google.maps.Map(document.getElementById('map'), mapOptions);
      // directionsRenderer.setMap(map);
      const officeLocation = this.$store.getters['officeSelectedModule/getOfficePosition']

      var startPos
      switch (this.start) {
        case 1:
          // my position
          startPos = new google.maps.LatLng(this.$store.getters.getCurrentPosition.lat, this.$store.getters.getCurrentPosition.lng)
          // startPos = this.$store.getters.getCurrentPosition
          break
        case 2:
          // office
          startPos = new google.maps.LatLng(officeLocation.lat, officeLocation.lng)
          break
        case 3:
          // other direction
          startPos = new google.maps.LatLng(officeLocation.lat, officeLocation.lng)
          break
        default:
          startPos = new google.maps.LatLng(officeLocation.lat, officeLocation.lng)
          break
      }
      var endPos
      switch (this.end) {
        case 1:
          // my position
          // endPos = this.$store.getters.getCurrentPosition
          endPos = new google.maps.LatLng(this.$store.getters.getCurrentPosition.lat, this.$store.getters.getCurrentPosition.lng)
          break
        case 2:
          // office
          endPos = new google.maps.LatLng(officeLocation.lat, officeLocation.lng)
          // endPos = 'ChIJtXN-2IiVuEwRKrkSqUV3HYE'
          break
        case 3:
          // other direction
          endPos = new google.maps.LatLng(officeLocation.lat, officeLocation.lng)
          break
        default:
          endPos = new google.maps.LatLng(officeLocation.lat, officeLocation.lng)
          break
      }
      const waypts = []
      for (let i = 0; i < this.selectPdrList.length; i++) {
        const pdr = this.selectPdrList[i]
        const location = this.$store.state.pocModule.data[pdr.id].homeLocation
        const loc = new google.maps.LatLng(location)
        // waypts.push({ location: pdr.place_id, stopover: false })
        waypts.push({ location: loc, stopover: true })
      }
      for (let i = 0; i < this.selectGreyBoxList.length; i++) {
        const greyBox = this.selectGreyBoxList[i]
        const loc = new google.maps.LatLng(greyBox.position.lat, greyBox.position.lng)
        waypts.push({ location: loc, stopover: true })
      }

      var request
      if (this.trafic) {
        if (this.travelStartDateTime < Date.now()) {
          this.travelStartDateTime = new Date(Date.now())
        }
        request = {
          origin: startPos,
          destination: endPos,
          travelMode: this.transportType ? 'WALKING' : 'DRIVING',
          optimizeWaypoints: true,
          waypoints: waypts,
          drivingOptions: {
            departureTime: this.travelStartDateTime
          }
        }
      } else {
        request = {
          origin: startPos,
          destination: endPos,
          travelMode: this.transportType ? 'WALKING' : 'DRIVING',
          optimizeWaypoints: true,
          waypoints: waypts
        }
      }
      EventBus.$on('makeDirectionCompleteSuccess', () => {
        // this.$emit('return-renderer', (directionsRenderer))
        const marker = this.selectGreyBoxList
        this.selectPdrList.forEach(poc => {
          marker.push(this.$store.getters['pocModule/getPocMarker'](poc))
        })
        this.$emit('return-renderer', ({
          dr: directionsRenderer,
          marker: marker
        }))
        this.$emit('close')
      })
      directionsService.route(request, function (result, status) {
        if (status === 'OK') {
          directionsRenderer.setDirections(result)
          EventBus.$emit('makeDirectionCompleteSuccess')
        } else {
          this.$store.dispatch('showAlert', status)
        }
      })
    }
  },
  mounted () {
    this.travelStartDateTime = new Date()
    // this.timeStartPicker = dNow.getHours() + ':' + dNow.getMinutes()
  }
}
</script>
