<template fixed>
  <v-card
    :width="$store.getters.contentWidth - 160"
    max-width="600"
    rounded
    :style="infoControlActive === undefined ? 'opacity: 0.6;' : 'opacity: 0.9;'"
  >
  <v-expansion-panels v-model="infoControlActive">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-list-item-content two-line class="my-n3">
          <v-list-item-title v-text="infoControlTitle"/>
          <v-list-item-title v-text="infoControlTitle"/>
        </v-list-item-content>
        <template v-slot:actions>
          <v-icon class="pe-n2" color="primary">
            mdi-chevron-up
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>

      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
  </v-card>
</template>

<script>
export default {
  name: 'streetViewInfoControl',
  props: ['mapMode'],
  data () {
    return {
      infoControlActive: undefined,
      infoControlTitle: 'detail du StreetView'
    }
  },
  methods: {}
}
</script>
