<template>
  <div class="home">
    <v-card
      :width="$store.getters.contentWidth > 370 ? $store.getters.contentWidth - 160 : $store.getters.contentWidth - 130"
      max-width="600"
      rounded
      :style="infoControlActive === undefined ? 'opacity: 0.6;' : 'opacity: 0.9;'"
    >
    <v-expansion-panels v-model="infoControlActive">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-list-item-content two-line class="my-n3">
            <v-list-item-title :class="infoTitle1Class" v-text="infoTitle1"/>
            <v-list-item-title :class="infoTitle2Class" v-text="infoTitle2"/>
          </v-list-item-content>
          <template v-slot:actions>
            <v-icon class="pe-n2" color="primary">
              mdi-chevron-up
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <div v-if="$store.getters.postmanDataLoadCompleteReady">
          <v-expansion-panel-content v-if="mapMode === 'road-steps'" class="mx-n6 pb-6">
            <v-card flat>
              <v-card-title class="text-center">type: Boucle</v-card-title>
              <stats-popup class="mx-4 mb-4" block :title="infoTitle2" :pocItems="params.pocItems" showInfoButton></stats-popup>
              <v-list-item>
                <template v-slot:default="{ active }">
                  <v-list-item-content>Complete</v-list-item-content>
                  <v-list-item-action>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </v-card>
          </v-expansion-panel-content>
          <v-expansion-panel-content v-else-if="mapMode === 'road-book'" class="pb-3">
            <template row>
              <p class="title-control" v-text="getRoadBookTitle()"/>
              <stats-popup class="item-control mx-auto mb-4" block :title="infoTitle2" :pocItems="params.pocItems" showInfoButton></stats-popup>
              <v-btn
                :loading="isSetStepCompleteLoading"
                large
                class="item-control"
                dark
                block
                @click="setStepComplete()"
              >
                {{ $t('common.nextStep') }}
              </v-btn>
            </template>
          </v-expansion-panel-content>
          <v-expansion-panel-content v-else class="mx-n6">
            <v-tabs v-model="infoTab" dense grow>
              <!-- <v-tabs-slider></v-tabs-slider> -->
              <v-tab href="#info">
                <v-icon>mdi-information</v-icon>
              </v-tab>
              <v-tab href="#renderer">
                <v-icon>mdi-sign-direction</v-icon>
              </v-tab>
              <!-- <v-tabs-slider></v-tabs-slider> -->
            </v-tabs>
            <v-tabs-items v-model="infoTab">
              <v-tab-item value="info">
                <v-btn x-large>test</v-btn>
              </v-tab-item>
              <v-tab-item value="renderer">
                <div id="directionsPanel"/>
              </v-tab-item>
            </v-tabs-items>
          </v-expansion-panel-content>
        </div>
      </v-expansion-panel>
    </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'mapInfoControl',
  props: ['params', 'mapMode'],
  data () {
    return {
      infoControlActive: undefined,
      infoControlTitle: 'detail du StreetView',
      infoTab: 'info',
      isSetStepCompleteLoading: false
    }
  },
  methods: {
    setStepComplete () {
      this.isSetStepCompleteLoading = true
      this.$store.dispatch('routeDayDataModule/setMyTravelStepComplete', { stepId: this.params.stepId, steps: this.params.steps })
        .then(() => {
          this.isSetStepCompleteLoading = false
        })
    },
    getRoadBookTitle () {
      switch (this.params.stepType) {
        case 'loop':
          return this.$t('common.deliveryLoop')
        case 'parcelLocker':
          return 'parcelLocker'
        case 'greyBox':
          return 'greyBox'
        default:
          return ''
      }
    }
  },
  computed: {
    mode () {
      return this.params.mode
    },
    infoTitle1 () {
      switch (this.mapMode) {
        case 'track-me':
          return this.$t('mapMode.' + this.mapMode)
        case 'route-info':
          return this.$t('mapMode.' + this.mapMode)
        default:
          return this.$t('mapMode.' + this.mapMode)
      }
    },
    infoTitle1Class () {
      switch (this.mapMode) {
        case 'track-me':
          return ''
        case 'route-info':
          return ''
        case 'road-book':
          return 'red--text font-weight-black mt-n1'
        default:
          return 'red--text font-weight-black'
      }
    },
    infoTitle2 () {
      var temp1
      var temp2
      switch (this.mapMode) {
        case 'track-me':
          return 'ma position'
        case 'route-info':
          switch (this.infoShowLevel) {
            case 'placeOfCode':
              return 'placeofCode'
            default:
              return 'Level -> ' + this.infoShowLevel
          }
        case 'direction':
          return 'direction'
        case 'road-book':
          // console.log(this.$store.getters['officeTravelModule/getRoadBook'])
          // var stepIdx = this.$store.getters['officeTravelModule/getRoadBook'].map(x => { return x.pos }).indexOf(this.$store.getters['routeDayDataModule/getMyTravelSelectedNextStep'])
          temp1 = this.$store.getters['routeDayDataModule/getMyTravelSelectedNextStep']
          temp2 = this.$store.getters['officeTravelModule/getRoadBook'] ? this.$store.getters['officeTravelModule/getRoadBook'].length : '...'
          return this.$t('common.step') + ' ' + temp1 + ' ' + this.$t('common.from') + ' ' + temp2
        default:
          return null
      }
    },
    infoTitle2Class () {
      switch (this.mapMode) {
        case 'track-me':
          return ''
        case 'route-info':
          return ''
        case 'road-book':
          return 'font-weight-bold'
        default:
          return 'red--Text'
      }
    },
    infoShowLevel () {
      return this.$store.getters['userLoggedModule/getMapinfoShowLevel']
    }
  },
  mounted () {
    this.$store.getters.EventBus.$on('closeMapInfoControl', () => {
      this.infoControlActive = null
    })
    // console.log(this.params)
  }
}
</script>
<style>
  .title-control {
    text-align: center;
    font-size: x-large;
    font-weight: 700;
  }
  .item-control {
    width: 100% !important;
  }
  @media screen and (max-width: 600px) {
    .title-control {
      font-size: large;
      font-weight: 700;
    }
  }
  @media screen and (max-width: 400px) {
    .title-control {
      font-size: medium;
      font-weight: 700;
    }
  }
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
