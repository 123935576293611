<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen persistent transition="dialog-bottom-transition">
      <v-app-bar dark color="primary" fixed>
        <v-btn icon x-large dark @click="dialog = false">
          <v-icon x-large>mdi-close</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title>{{ route.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon x-large @click="saveRouteSequence()"><v-icon x-large color="success">mdi-content-save</v-icon></v-btn>
      </v-app-bar>
      <v-card >
        <v-divider class="mt-8"></v-divider>
        <v-list class="mt-12">
          <v-list-item
            v-for="(code, i) in dir"
            :key="i"
            :color="$store.getColorCode(code.tier)"
            three-line
            :class="$store.getColorText(code.tier)"
            >
            <v-list-item-content>
              <v-list-item-title :class="$store.getColorText(code.tier) + ' mt-n5 mb-n8'">
                <v-row>
                  <v-col cols="4"><h2 class="mt-1 text-right">Code : </h2></v-col>
                  <v-col cols="4" class=" center-justify"><v-text-field large class="centered-input text-h5 mt-n2" dark :background-color="$store.getColorCode(code.tier)" v-model="code.codeName" filled outlined @change="updateCodeName(code.codeId, $event)"></v-text-field></v-col>
                </v-row>
              </v-list-item-title>
              <!-- <v-card-subtitle class="mt-1">
                {{ code.first.value.name }}
              </v-card-subtitle> -->
                <v-select
                  class="mb-n4"
                  :label="$t('common.from')"
                  :items="[code.first]"
                  filled
                  :value="code.first"
                ></v-select>
                <v-select
                  class="mb-n4"
                  :items="sortSeq"
                  :label="$t('common.to')"
                  filled
                  menu-props="auto"
                  @change="updateCode(code.last.value, $event, code.first.value)"
                  :value="code.last"
                ></v-select>
                <v-divider></v-divider>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    <pmt-loading-progress-local :loadingShow="showLoading" :loadingTitle="loadingTitle" :progressValue="progressValue"></pmt-loading-progress-local>
    </v-dialog>
  </v-row>
</template>

<script>
// import { arrayUnion } from 'vuex-easy-firestore'
// import { arrayUnion } from 'vuex-easy-firestore'
export default {
  props: ['title', 'route'],
  data () {
    return {
      sortSeq: null,
      dialog: true,
      notifications: false,
      sound: true,
      widgets: false,
      dir: null,
      loadingComplete: 0,
      loadingCompleteTotal: 0,
      showLoading: false,
      progressValue: 0,
      loadingTitle: this.$t('common.analysing'),
      loadingsubTitle: null
    }
  },
  computed: {
    directory () {
      const dir = []
      var first
      if (this.sortSeq) {
        for (let i = 0; i < this.sortSeq.length; i++) {
          const seq = this.sortSeq[i]
          if (seq.value.firstOfCode) {
            first = i
          }
          if (seq.value.lastOfCode) {
            dir.push({ codeId: seq.value.codeId, codeName: seq.value.codeName, first: this.sortSeq[first], last: seq, tier: seq.value.tier })
          }
        }
      }
      return dir
    }
  },
  methods: {
    savePocSequence () {
      const self = this
      function doBatch (doList) {
        var batchList
        const batchPromise = []
        var toDoList = null
        if (doList.length > 500) {
          batchList = doList.slice(0, 500)
          toDoList = doList.slice(500)
        } else {
          batchList = doList
        }
        batchList.forEach(seq => {
          if (seq.value.pocId) {
            const routeSequences = self.$store.state.pocModule.data[seq.value.pocId].routeSequences ? self.$store.state.pocModule.data[seq.value.pocId].routeSequences : []
            const routeIndex = routeSequences.map(x => { return x.routeId }).indexOf(self.route.routeId)
            if (routeIndex < 0) {
              routeSequences.push({
                routeId: self.route.routeId,
                sequences: [{
                  sequenceId: self.route.sequenceItemSelect.id,
                  seqPosTot: seq.value.seqcodetotal,
                  seqcodeSys: seq.value.seqcodeSys,
                  seqcodeSysPos: seq.value.seqcodeSysPos,
                  tier: seq.value.tier,
                  codeId: seq.value.codeId,
                  codeName: seq.value.codeName
                }]
              })
            } else {
              const seqIndex = routeSequences[routeIndex].sequences.map(x => { return x.sequenceId }).indexOf(self.route.sequenceItemSelect.id)
              if (seqIndex < 0) {
                routeSequences[routeIndex].sequences.push({
                  sequenceId: self.route.sequenceItemSelect.id,
                  seqPosTot: seq.value.seqcodetotal,
                  seqcodeSys: seq.value.seqcodeSys,
                  seqcodeSysPos: seq.value.seqcodeSysPos,
                  tier: seq.value.tier,
                  codeId: seq.value.codeId,
                  codeName: seq.value.codeName
                })
              } else {
                routeSequences[routeIndex].sequences[seqIndex].seqPosTot = seq.value.seqcodetotal
                routeSequences[routeIndex].sequences[seqIndex].seqcodeSys = seq.value.seqcodeSys
                routeSequences[routeIndex].sequences[seqIndex].seqcodeSysPos = seq.value.seqcodeSysPos
                routeSequences[routeIndex].sequences[seqIndex].tier = seq.value.tier
                routeSequences[routeIndex].sequences[seqIndex].codeId = seq.value.codeId
                routeSequences[routeIndex].sequences[seqIndex].codeName = seq.value.codeName
              }
            }
            batchPromise.push(new Promise((resolve, reject) => {
              self.$store.dispatch('pocModule/set', {
                id: seq.value.pocId,
                pocInfo: seq.value.info7 ? seq.value.info7 : null,
                routeSequences: routeSequences
              })
                .then((id) => {
                  self.loadingComplete++
                  resolve(id)
                })
                .catch((err) => {
                  self.$store.dispatch('showAlert', err.message)
                  reject(err.message)
                })
            }))
          }
        })
        return Promise.all(batchPromise).then(() => {
          if (toDoList) {
            return doBatch(toDoList)
          } else {
            return null
          }
        })
      }
      return doBatch(this.sortSeq)
    },
    saveRouteSequence () {
      this.loadingTitle = this.$t('common.saveinprogress')
      this.showLoading = true
      this.loadingCompleteTotal = this.sortSeq.length
      this.loadingComplete = 0
      const store = this.$store
      const self = this
      // const prom = []
      // const seq = []
      // // console.log('trace 2')
      // const routeId = this.$store.getters['routeModule/getRouteIdByName'](this.route.name)
      const sequenceList = store.state.routeModule.data[self.route.routeId].sequenceList
      var routeUpdate = null
      if (sequenceList) {
        const seqRouteIndex = sequenceList.map(function (x) { return x.id }).indexOf(self.route.sequenceItemSelect.id)
        if (seqRouteIndex < 0) {
          self.route.sequenceItemSelect.id = sequenceList.length
          sequenceList.push(self.route.sequenceItemSelect)
          routeUpdate = {
            id: self.route.routeId,
            sequenceList: sequenceList
          }
        }
      } else {
        self.route.sequenceItemSelect.id = 0
        routeUpdate = {
          id: self.route.routeId,
          sequenceList: [self.route.sequenceItemSelect]
        }
      }
      if (routeUpdate) {
        this.$store.dispatch('routeModule/set', routeUpdate)
          .then(() => {
            self.savePocSequence().then(() => {
              self.$store.dispatch('showMessage', self.$t('common.succesSave'))
              self.showLoading = false
              self.dialog = false
            })
            // self.$store.dispatch('showMessage', routeUpdate.id + ' : ' + self.$t('common.succesSave'))
          })
          .catch((err) => {
            self.$store.dispatch('showAlert', (err.message))
          })
      } else {
        self.savePocSequence().then(() => {
          self.$store.dispatch('showMessage', self.$t('common.succesSave'))
          self.showLoading = false
          self.dialog = false
        })
      }
    },
    CreateDirectory () {
      const ldir = []
      this.showLoading = true
      var first
      if (this.sortSeq) {
        for (let i = 0; i < this.sortSeq.length; i++) {
          const seq = this.sortSeq[i]
          if (seq.value.firstOfCode) {
            first = i
          }
          if (seq.value.lastOfCode) {
            ldir.push({ codeId: seq.value.codeId, codeName: seq.value.codeName, first: this.sortSeq[first], last: seq, tier: seq.value.tier })
            first = i + 1
          }
        }
      }
      this.dir = ldir
      this.showLoading = false
    },
    updateCodeName (codeId, codeName) {
      for (let i = 0; i < this.sortSeq.length; i++) {
        if (this.sortSeq[i].value.codeId === codeId) {
          this.sortSeq[i].value.codeName = codeName
        }
      }
    },
    updateCode (lastCut, newCut, startCut) {
      if (newCut.tier !== startCut.tier) {
        this.$store.dispatch('showAlert', ('can create code over 2 diffirent tier'))
        return
      }
      this.showLoading = true
      const nbrSeq = this.sortSeq.length
      for (let i = 0; i < this.sortSeq.length; i++) {
        const sequence = this.sortSeq[i].value
        if (Number(sequence.seqcodetotal) > Number(startCut.seqcodetotal) && Number(sequence.seqcodetotal) < Number(newCut.seqcodetotal)) {
          this.sortSeq[i].value.firstOfCode = false
          this.sortSeq[i].value.lastOfCode = false
        }
        if (sequence.name === lastCut.name) {
          if (sequence.name !== this.route.firstPink.name && sequence.name !== this.route.firstBlue.name) {
            if (Number(lastCut.seqcodetotal) < (Number(newCut.seqcodetotal))) {
              this.sortSeq[i].value.lastOfCode = false
              if (i < nbrSeq - 1) { this.sortSeq[i + 1].value.firstOfCode = false }
            }
          }
        }
        if (sequence.name === newCut.name) {
          this.sortSeq[i].value.lastOfCode = true
          if (i < nbrSeq - 1) { this.sortSeq[i + 1].value.firstOfCode = true }
        }
      }
      // var lastcode = 0
      var codeId = 0
      var tier = 1
      var blueStartCode
      var lastOrFisrt = 'last'
      for (let i = 0; i < this.sortSeq.length; i++) {
        // for (let i = 0; i < 10; i++) {
        const sequence = this.sortSeq[i].value
        if (sequence.firstOfCode) {
          if (lastOrFisrt === 'last') {
            lastOrFisrt = 'first'
          } else {
            this.sortSeq[i].value.firstOfCode = false
          }
        }
        if (sequence.lastOfCode) {
          if (lastOrFisrt === 'first') {
            lastOrFisrt = 'last'
          } else {
            this.sortSeq[i].value.lastOfCode = false
          }
        }
        if (this.route.firstPink && sequence.name === this.route.firstPink.name) {
          tier = 2
          codeId++
        } else if (this.route.firstBlue && sequence.name === this.route.firstBlue.name) {
          tier = 3
          blueStartCode = codeId
          codeId++
        } else if (sequence.firstOfCode) {
          codeId++
        }
        if (tier === 3) {
          if (this.sortSeq[i].value.codeName === this.sortSeq[i].value.codeId) {
            this.sortSeq[i].value.codeId = this.blueCodeName(Number(codeId) - Number(blueStartCode))
            this.sortSeq[i].value.codeName = this.sortSeq[i].value.codeId
          } else {
            this.sortSeq[i].value.codeId = this.blueCodeName(Number(codeId) - Number(blueStartCode))
          }
        } else {
          if (this.sortSeq[i].value.codeName === this.sortSeq[i].value.codeId) {
            this.sortSeq[i].value.codeName = codeId
          }
          this.sortSeq[i].value.codeId = codeId
        }
        this.sortSeq[i].value.tier = tier
      }
      this.CreateDirectory()
    },
    generateInitCode () {
      this.showLoading = true
      var lastStreet
      var lastcode = 0
      var lastSysCode
      var codeId = 0
      var tier = 1
      var blueStartCode
      var isFirstBlueSet = false
      var isFirstPinkSet = false

      for (let i = 0; i < this.sortSeq.length; i++) {
        // for (let i = 0; i < 10; i++) {
        const sequence = this.sortSeq[i].value
        // if (i === 1) (// // console.log(sequence))
        if (!isFirstPinkSet && this.route.firstPink && sequence.name === this.route.firstPink.name) {
          tier = 2
          isFirstPinkSet = true
          lastStreet = sequence.street
          codeId++
        } else if (!isFirstBlueSet && this.route.firstBlue && sequence.name === this.route.firstBlue.name) {
          tier = 3
          blueStartCode = codeId
          lastStreet = sequence.street
          isFirstBlueSet = true
          codeId++
        } else if (lastStreet !== sequence.street) {
          lastStreet = sequence.street
          // lastSysCode = sequence.seqcodeSys
          codeId++
        } else if (lastSysCode !== sequence.seqcodeSys) {
          // lastSysCode = sequence.seqcodeSys
          codeId++
        } else if (i > 0) {
          if (this.sortSeq[i - 1].value.door.match(/\d+/g).map(Number) % 2 === 0 && sequence.door.match(/\d+/g).map(Number) % 2 === 1) {
            codeId++
          }
        }
        lastSysCode = sequence.seqcodeSys
        if (codeId !== lastcode) {
          this.sortSeq[i].value.firstOfCode = true
          if (i) {
            this.sortSeq[i - 1].value.lastOfCode = true
          }
          lastcode = codeId
        }
        if (tier === 3) {
          this.sortSeq[i].value.codeId = this.blueCodeName(Number(codeId) - Number(blueStartCode))
        } else {
          this.sortSeq[i].value.codeId = codeId
        }
        this.sortSeq[i].value.codeName = this.sortSeq[i].value.codeId
        this.sortSeq[i].value.tier = tier
      }
      this.sortSeq[this.sortSeq.length - 1].value.lastOfCode = true
      this.CreateDirectory()
    },
    blueCodeName (blueCode) {
      const charCode = blueCode + 64
      if (blueCode < 27) {
        return String.fromCharCode(charCode)
      } else if ((blueCode < 53)) {
        return String.fromCharCode(charCode - 26) + String.fromCharCode(charCode - 26)
      } else {
        return String.fromCharCode(charCode - 52) + String.fromCharCode(charCode - 52) + String.fromCharCode(charCode - 52)
      }
    }
  },
  watch: {
    dialog (newValue) {
      this.dialog = false
      this.$store.dispatch('resize')
      this.$emit('close')
    },
    loadingComplete (newValue) {
      if (newValue && this.loadingCompleteTotal) {
        this.progressValue = Math.round((newValue / this.loadingCompleteTotal) * 100)
      } else {
        this.progressValue = 0
      }
    }
  },
  mounted () {
    this.sortSeq = this.route.sequence.sort(function (a, b) {
      return a.value.seqcodetotal - b.value.seqcodetotal
    })
    this.generateInitCode()
  }
}
</script>
<style scoped>
    .centered-input >>> input {
      text-align: center
    }
</style>
