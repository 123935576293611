// import { getApiKey } from '@/utils/gmaps'
import firebase from 'firebase/app'
import 'firebase/storage'
import { arrayUnion } from 'vuex-easy-firestore'
// import { arrayUnion } from 'vuex-easy-firestore'

const pdrModule = {
  firestorePath: 'pdr',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'pdrModule',
  statePropName: 'data',
  namespaced: true, // automatically added

  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {
    imgUrl: [],
    apiKey: 'AIzaSyBCYzXrnUG2oYl9LaUoMURILgcFtag0TOI'
  },
  getters: {
    pdrExist: (state) => (adress) => {
      var existId = null
      for (const pdrId in state.data) {
        const pdr = state.data[pdrId]
        if (pdr.pdrListAddress === adress) {
          existId = pdrId
        }
      }
      return existId
    },
    sortedPdr: (state, getters) => {
      return Object.values(state.data).sort(function (a, b) {
        return a.csvSeqPosition - b.csvSeqPosition
      }) // your sort function
    },
    getPdr: (state) => (pdrId) => {
      return state.data[pdrId]
    },
    getPdrReplaceImgUrl: (state) => (pdrId) => {
      var img = null
      for (let i = 0; i < state.imgUrl.length; i++) {
        const pdrImg = state.imgUrl[i]
        if (pdrImg.id === pdrId) {
          img = pdrImg.img
          break
        }
      }
      return img
    },
    getPdrItemById: (state) => (pdrId) => {
      if (pdrId) {
        const pdr = state.data[pdrId]
        return { id: pdr.id, name: pdr.csvPdrListAddress, pub: pdr.pub, type: pdr.type, vacant: pdr.vacant, active: false, officeId: pdr.officeId, place_id: pdr.place_id, color: pdr.color, code: pdr.codeName }
      } else {
        return null
      }
    },
    getPdrItems: (state, getters) => {
      const pdrItemsList = []
      for (const pdr of getters.sortedPdr) {
        pdrItemsList.push({ text: pdr.csvPdrListAddress, value: { id: pdr.id, name: pdr.csvPdrListAddress, pub: pdr.pub, type: pdr.type, vacant: pdr.vacant, active: false, officeId: pdr.officeId, place_id: pdr.place_id, color: pdr.color, code: pdr.codeName } })
      }
      return pdrItemsList
    },
    getPdrDataIteratorItems: (state, getters) => {
      const pdrItemsList = []
      for (const pdr of getters.sortedPdr) {
        pdrItemsList.push({ id: pdr.id, name: pdr.csvPdrListAddress, pub: pdr.pub, type: pdr.type, vacant: pdr.vacant, active: false, officeId: pdr.officeId, place_id: pdr.place_id, color: pdr.color, code: pdr.codeName })
      }
      return pdrItemsList
    },
    getPdrLocation: (state) => (pdrId) => {
      return state.data[pdrId].location[0]
    },
    getNewPdrMarker: (state) => (pdrId, google) => {
      const pdr = state.data[pdrId]
      const pos = pdr.location[0]
      var styleName
      var colorName
      var topName
      var labelsize

      switch (pdr.placeIdDoor.length) {
        case 1:
          labelsize = '18px'
          break
        case 2:
          labelsize = '16px'
          break
        case 3:
          labelsize = '14px'
          break
        case 4:
          labelsize = '12px'
          break
        case 5:
          labelsize = '10px'
          break
        case 6:
          labelsize = '8px'
          break
        default:
          labelsize = '6px'
          break
      }

      if (pdr.type === 'Hou/Dom') {
        styleName = 'Hou'
      } else if (pdr.type === 'Apt/App') {
        styleName = 'App'
      } else if (pdr.type === 'Bus/com') {
        styleName = 'Bus'
      } else {
        styleName = 'Farm'
      }
      if (pdr.vacant) {
        topName = 'green'
      } else if (pdr.pub) {
        topName = 'red'
      } else {
        topName = 'yellow'
      }
      if (pdr.color === 1) {
        colorName = 'orange'
      } else if (pdr.color === 2) {
        colorName = 'pink'
      } else {
        colorName = 'blue'
      }

      const url = require('@/assets/Icon/MapIcon/POC/' + styleName + '-' + colorName + '-t' + topName + '.png')
      const marker = new google.maps.Marker({
        position: { lat: pos.loc.latitude, lng: pos.loc.longitude },
        label: { text: pdr.placeIdDoor, fontSize: labelsize, color: 'white' },
        map: null,
        icon: {
          url: url,
          size: new google.maps.Size(48, 48),
          //      scaledSize: new google.maps.Size(40, 40), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(24, 28), // anchor
          labelOrigin: new google.maps.Point(24, 32)
        }
      })
      return marker
    },
    getPlaceLocation: (state, getters) => (placeId) => {
      let pLaceLocation = null
      for (const pdrId in state.data) {
        const pdr = state.data[pdrId]
        if (pdr.place_id === placeId) {
          pLaceLocation = pdr.location[0]
          break
        }
      }
      return pLaceLocation
    },
    getPlacePictureHomeSrc: (state, getters) => (info) => {
      // info = { placeId: this.pdrId, width: '200', height: '400' }
      var pLaceLocation = null
      var placePdrId = null
      var countPdr = 0
      for (const pdrId in state.data) {
        const pdr = state.data[pdrId]
        if (pdr.place_id === info.placeId) {
          pLaceLocation = pdr.location[0]
          placePdrId = pdr.id
          if (countPdr) { break }
          countPdr++
        }
      }
      if (countPdr) {
        return getters.getPdrPictureHomeSrc({ pdrId: placePdrId, width: info.width, height: info.height })
      } else if (pLaceLocation) {
        return 'https://maps.googleapis.com/maps/api/streetview?size=' + info.width + 'x' + info.height + '&location=' + pLaceLocation.loc.latitude + ',' + pLaceLocation.loc.longitude + '&key=' + state.apiKey
      } else {
        return ''
      }
    },
    getPdrPictureHomeSrc: (state, getters) => (info) => {
      // info = { pdrId: this.pdrId, width: '200', height: '400' }
      const pdr = state.data[info.pdrId]
      const altPic = getters.getPdrReplaceImgUrl(info.pdrId)

      if (altPic) {
        return altPic
      } else {
        return 'https://maps.googleapis.com/maps/api/streetview?size=' + info.width + 'x' + info.height + '&location=' + pdr.location[0].loc.latitude + ',' + pdr.location[0].loc.longitude + '&key=' + state.apiKey
      }
    }
  },
  mutations: {
    addpictureUrlReady (state, payload) {
      state.imgUrl.push(payload)
    },
    deletePdrReplacedPictureReady (state, payload) {
      for (let i = 0; i < state.imgUrl.length; i++) {
        const pdrImg = state.imgUrl[i]
        if (pdrImg.id === payload) {
          state.imgUrl.splice(i, 1)
          state.importPictureInfo = null
          this.dispatch('pdrModule/set', {
            id: payload,
            importPictureInfo: null
          })
          break
        }
      }
    }
  },
  actions: {
    onUpdatePdr ({ commit, getters }, payload) {
      const pdr = getters.getPdr(payload.id)
      var newPdrValue
      if (payload.note) {
        if (pdr.note) {
          newPdrValue = {
            id: payload.id,
            pub: payload.pub,
            vacant: payload.vacant,
            note: arrayUnion(payload.note)
          }
        } else {
          newPdrValue = {
            id: payload.id,
            pub: payload.pub,
            vacant: payload.vacant,
            note: [payload.note]
          }
        }
      } else {
        newPdrValue = {
          id: payload.id,
          pub: payload.pub,
          vacant: payload.vacant
        }
      }
      if (payload.newPosition) {
        this.dispatch('updatePdrGeocoderFromPosition', { id: payload.id, pos: payload.newPosition })
      }
      if (payload.importPicture) {
        const storefilename = payload.id + '.jpg'
        const storepath = 'poc/' + pdr.officeId + '/'
        firebase.storage().ref(storepath).child(storefilename).putString(payload.importPicture.substring(23), 'base64', { contentType: 'image/jpg' })
          .then(fileData => {
            const imageUrl = fileData.metadata.fullPath
            this.dispatch('pdrModule/set', {
              id: payload.id,
              importPictureInfo: { fileName: storefilename, path: storepath }
            })
            firebase.storage()
              .ref(imageUrl)
              .getDownloadURL()
              .then(img => {
                commit('addpictureUrlReady', { id: pdr.id, img: img })
              })
          })
          .catch((err) => {
            this.dispatch('showAlert', err.message)
          })
      }

      this.dispatch('pdrModule/set', newPdrValue)
        .then((pdrId) => {
          this.dispatch('showMessage', payload.successMessage)
        })
        .catch((err) => {
          this.dispatch('showAlert', err.message)
        })
    },
    deletePdrReplacedPicture ({ commit, getters }, payload) {
      const pdr = getters.getPdr(payload)
      if (pdr.importPictureInfo) {
        firebase.storage().ref(pdr.importPictureInfo.path).child(pdr.importPictureInfo.fileName).delete()
        commit('deletePdrReplacedPictureReady', payload)
      }
    },
    setPdrImgUrl ({ commit, getters }) {
      for (const pdr of getters.sortedPdr) {
        if (pdr.importPictureInfo) {
          firebase.storage()
            .ref(pdr.importPictureInfo.path + pdr.importPictureInfo.fileName)
            .getDownloadURL()
            .then(img => {
              // use Vue.set for reactivity
              commit('addpictureUrlReady', { id: pdr.id, img: img })
            })
            .catch((err) => {
              this.dispatch('showAlert', err.message)
            })
        }
      }
    }
  }
}

export default pdrModule
