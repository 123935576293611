<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="444" fixed persistent transition="dialog-bottom-transition">
      <v-card>
        <v-app-bar dark color="primary">
          <v-btn icon x-large dark @click="dialog = false">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-app-bar>
        <v-card-text>
          <v-textarea
            v-model="desc"
            outlined
            label="Note"
            class="mt-5"
          ></v-textarea>

          <v-btn :disabled="!canBeSaved" block rounded color="primary" large @click="saveNote()">{{ this.note ? $t('common.save') : $t('common.add')}}</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ['title', 'note', 'type'],
  data () {
    return {
      dialog: true,
      desc: this.note ? this.note.desc : ''
    }
  },
  computed: {
    canBeSaved () {
      if (this.note) {
        if (this.desc && this.note.desc !== this.desc) {
          return true
        } else { return false }
      } else {
        if (this.desc) {
          return true
        } else {
          return false
        }
      }
    }
  },
  methods: {
    saveNote () {
      const firebase = require('firebase/app')
      const saveData = this.note ? this.note : {
        id: this.$store.getUniqueKey(),
        desc: this.desc,
        created_by: this.$store.getters.userId,
        createdByName: this.$store.state.userLoggedModule.data.lname + ', ' + this.$store.state.userLoggedModule.data.fname,
        created_at: firebase.firestore.Timestamp.fromDate(new Date())
      }
      if (this.note) {
        saveData.desc = this.desc
      }
      this.$emit('return-close', saveData)
    }
  },
  watch: {
    dialog (newValue) {
      this.$store.dispatch('resize')
      this.dialog = false
      this.$emit('close')
    }
  }
}
</script>
