<template>
  <v-card :style="$store.getters.contentStyle" class="overflow-y-auto">
    <v-card-actions>
      <v-row class="mx-1">
        <v-card-title>{{ $t('pmt-edit-greybox.title') }}</v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon x-large class="mt-2" @click="showCreateGreyBox = true"><v-icon x-large>mdi-plus</v-icon></v-btn>
      </v-row>
    </v-card-actions>
    <v-card-text>
      <v-list nav>
        <v-list-item
          v-for="(item) in $store.getters['officeItemModule/getAllGreyBoxSorted']"
          :key="item.id"
          :class="item.isActive ? item.group : 'grey'"
          @click="showReport = true"
        >
        <v-list-item-content>
          {{ item.name }}
        </v-list-item-content>
        <v-list-item-icon @click="editGreyBox(item)">
          <v-btn icon x-large>
          <v-icon x-large>mdi-pencil</v-icon>
          </v-btn>
          </v-list-item-icon>
            <v-list-item-icon>
          <v-btn icon x-large @click="switchActive(item)">
          <v-icon x-large>{{ item.isActive ? 'mdi-delete' : 'mdi-delete-off'}}</v-icon>
          </v-btn>
        </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-card-text>

    <pmt-edit-greybox v-if="showCreateGreyBox" @close="showCreateGreyBox = false"></pmt-edit-greybox>
    <pmt-edit-greybox :editedGreyBox="editedGreyBox" v-if="showEditGreyBox" @close="showEditGreyBox = false"></pmt-edit-greybox>
  </v-card>
</template>

<script>
export default {
  props: ['title'],
  methods: {
    editGreyBox (item) {
      this.editedGreyBox = item
      this.showEditGreyBox = true
    },
    switchActive (item) {
      item.isActive = !item.isActive
      this.$store.dispatch('officeItemModule/set', { id: item.id, isActive: item.isActive })
    }
  },
  data () {
    return {
      showCreateGreyBox: false,
      showEditGreyBox: false,
      editedGreyBox: null
    }
  }
}
</script>
