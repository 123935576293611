<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fixed persistent max-width="444" transition="dialog-bottom-transition">
      <v-card>
        <v-app-bar dark color="primary">
          <v-btn icon x-large dark @click="dialog = false">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ refItem ? $t('office-item-editor.modifytitle') : $t('office-item-editor.createtitle') }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-app-bar>
        <v-card-text class="overflow-x-hidden overflow-y-auto" :max-height="$store.getters.contentHeight * 0.65">
          <v-form @submit.prevent="saveItem()">
            <v-row no-gutters class="mt-8">
              <v-col cols="4">
                <v-img
                  :src="imgType"
                  height="60"
                  contain
                >
                </v-img>
              </v-col>
              <v-col cols="8">
                <v-select
                  v-model="itemType"
                  :items="itemTypeList"
                  block
                  outlined
                  :disabled="!isNewItem"
                  label="Type"
                  :rules="[ validType ]"
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-text-field
              v-if="showItem.showName"
              v-model="itemName"
              class="mt-3"
              :label="$t('office-item-editor.name')"
              outlined
              block
              @blur="$store.dispatch('resize')"
              >
            </v-text-field>
            <v-select
              v-if="showItem.showGroup"
              v-model="itemGroup"
              :items="groupList"
              block
              outlined
              :label="$t('office-item-editor.group')"
            ></v-select>
            <v-textarea
              v-if="showItem.showDesc"
              v-model="itemDesc"
              outlined
              block
              @blur="$store.dispatch('resize')"
            ></v-textarea>
            <v-text-field
              v-if="showItem.showRef"
              v-model="itemRefNumber"
              :label="$t('common.refnumber')"
              outlined
              block
              @blur="$store.dispatch('resize')"
              >
            </v-text-field>
            <v-select
              v-if="showItem.showRoutes"
              v-model="itemRoutes"
              outlined
              block
              :items="$store.getters['routeModule/getRouteIdItems']"
              :label="$t('office-item-editor.routes')"
              multiple
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 4">
                  <span>{{ item.text }}</span>
                </v-chip>
                <span
                  v-if="index === 4"
                  class="grey--text caption"
                >
                  (+{{ itemRoutes.length - 4 }} {{ $t('common.others') }})
                </span>
              </template>
            </v-select>
            <!-- <v-list-item-content class="mx-auto" @click="showGetPosition = true" clearfix>
              <v-icon size="80" :color="position ? 'success' : 'error'">mdi-map-marker</v-icon>
              <v-list-item-title class="subtitle text-center">{{ $t('createreportdog.gps') }}</v-list-item-title>
            </v-list-item-content> -->
            <v-row no-gutters v-if="itemType">
              <v-col cols="4" align="center">
                <v-btn icon x-large @click="showManagePicture = true">
                  <v-badge
                    v-if="imageList.length"
                    :content="imageList.length"
                    color="green"
                    overlap
                    bottom
                  >
                    <v-icon x-large color="success">mdi-folder-multiple-image</v-icon>
                  </v-badge>
                  <v-icon v-else x-large color="error">mdi-folder-multiple-image</v-icon>
                </v-btn>
                <p align="center">{{ $t('createreportdog.picture') }}</p>
              </v-col>
              <v-col cols="4" align="center">
                <v-btn icon x-large @click="showGetPosition = true"><v-icon x-large :color="position ? 'success' : 'error'">mdi-map-marker</v-icon></v-btn>
                <p align="center">{{ $t('createreportdog.gps') }}</p>
              </v-col>
              <v-col cols="4" align="center" v-if="$store.getters.isAdminAcces || $store.getters.isSupervisorAccess">
                <v-btn icon x-large @click="itemActive = !itemActive"><v-icon x-large :color="itemActive ? 'success' : 'error'" v-text="itemActive ? 'mdi-check-outline' : 'mdi-close-thick'"></v-icon></v-btn>
                <p align="center">{{ $t(itemActive ? 'common.active' : 'common.inactive') }}</p>
              </v-col>
            </v-row>
            <v-btn
              :disabled="!readyToSave"
              class="mt-2"
              color="green"
              dark
              large
              type="submit"
              block
            >
              {{ $t('common.save') }}
              <v-icon class="ms-2">mdi-email-send</v-icon>
            </v-btn>
          </v-form>
        </v-card-text>
        <!-- <v-app-bar
          dark
          color="primary"
          bottom
          >
          <v-spacer></v-spacer>
          <v-btn
            v-if="readyToSave"
            color="green"
            dark
            type="submit"
          >
            save
            <v-icon class="ms-2">mdi-email-send</v-icon>
          </v-btn>
        </v-app-bar> -->
      </v-card>

    </v-dialog>
    <picture-manager
      v-if="showManagePicture"
      :subTitle="itemName ? itemName : itemTypeList[itemTypeList.map(x => { return x.value }).indexOf(itemType)].text"
      :param="{ type: 'officeItem', imageList: imageList }"
      @close="showManagePicture = false"
      @return-close="returnImage($event)"
    ></picture-manager>
    <pmt-position-map-set
      v-if="showGetPosition"
      :param="{ markerStartPosition: position, centerPostition: position, icon: $store.getMarkerIconSpec($store.getters.getGoogle, { type: itemType }).url, getDistance: false }"
      @return-close="showGetPosition = false"
      @return-check="setPosition($event)"
    />
  </v-row>
</template>

<script>
export default {
  props: ['officeItemId'],
  data () {
    return {
      dialog: true,
      notifications: false,
      sound: true,
      widgets: false,
      showGetPosition: false,
      position: null,
      showManagePicture: false,
      itemName: null,
      imageList: [],
      imageListModified: false,
      itemType: null,
      itemDesc: null,
      itemGroup: null,
      itemRefNumber: null,
      itemRoutes: null,
      itemActive: true
    }
  },
  computed: {
    refItem () {
      if (this.officeItemId && this.officeItemId !== true) {
        return this.$store.state.officeItemModule.data[this.officeItemId]
      } else {
        return null
      }
    },
    isNewItem () {
      if (this.officeItemId === true) {
        return true
      } else {
        return false
      }
    },
    groupList () {
      const group = [
        { text: this.$t('office-item-editor.groupbrown'), value: 'brown' },
        { text: this.$t('office-item-editor.groupblue'), value: 'blue' },
        { text: this.$t('office-item-editor.groupyellow'), value: 'yellow' },
        { text: this.$t('office-item-editor.groupgreen'), value: 'green' },
        { text: this.$t('office-item-editor.groupred'), value: 'red' }
      ]
      return group
    },
    itemTypeList () {
      return [
        { text: this.$t('pmtparammapsinfo.parcellocker'), value: 'parcelLockerMarker' },
        { text: this.$t('pmtparammapsinfo.redbox'), value: 'redBoxMarker' },
        { text: this.$t('pmtparammapsinfo.greybox'), value: 'greyBoxMarker' },
        { text: this.$t('pmtparammapsinfo.toilet'), value: 'toiletMarker' }
      ]
    },
    showItem () {
      switch (this.itemType) {
        case 'parcelLockerMarker':
          return { showName: true, showGroup: false, showDesc: false, showRef: true, showRoutes: true }
        case 'redBoxMarker':
          return { showName: true, showGroup: false, showDesc: false, showRef: true, showRoutes: true }
        case 'greyBoxMarker':
          return { showName: true, showGroup: true, showDesc: false, showRef: true, showRoutes: true }
        case 'toiletMarker':
          return { showName: true, showGroup: false, showDesc: true, showRef: false, showRoutes: false }
        case 'parkingMarker':
          return { showName: true, showGroup: false, showDesc: false, showRef: false, showRoutes: true }
        default:
          return { showName: false, showGroup: false, showDesc: false, showRef: false, showRoutes: false }
      }
    },
    readyToSave () {
      if (this.isNewItem) {
        // new Item
        switch (this.itemType) {
          case 'greyBoxMarker':
            if (this.position && this.itemGroup && this.itemName) {
              return true
            } else {
              return false
            }
          case 'toiletMarker':
            if (this.position && this.itemName) {
              return true
            } else {
              return false
            }
          case 'redBoxMarker':
            if (this.position && this.itemName) {
              return true
            } else {
              return false
            }
          case 'parcelLockerMarker':
            if (this.position && this.itemName) {
              return true
            } else {
              return false
            }
          case null:
            return false
          default:
            return false
        }
      } else {
        // modified item
        if (this.position !== this.refItem.position) {
          // // console.log('trace diff position')
          return true
        } else if (this.imageListModified) {
          // // console.log('trace diff image')
          return true
        } else if (this.itemGroup !== this.refItem.group) {
          // // console.log('trace diff group')
          return true
        } else if (this.itemName !== this.refItem.name) {
          // // console.log('trace diff name')
          return true
        } else if (this.itemDesc !== this.refItem.description) {
          // // console.log('trace diff desc')
          return true
        } else if (this.itemRoutes !== this.refItem.route) {
          // // console.log('trace diff route')
          return true
        } else if (this.itemRefNumber !== this.refItem.refNumber) {
          // // console.log('trace diff refnumber')
          return true
        } else if (this.itemActive !== this.refItem.isActive) {
          // // console.log('trace diff refnumber')
          return true
        } else {
          return false
        }
      }
    },
    imgType () {
      if (this.itemType !== 'all') {
        return this.$store.getMarkerIconSpec(this.$store.getters.getGoogle, { type: this.itemType }).url
      } else {
        return require('@/assets/Icon/MenuIcon/logo_postes_canada.png')
      }
    }
  },
  watch: {
    dialog (newValue) {
      this.$store.dispatch('resize')
      this.dialog = false
      this.$emit('close')
    },
    refItem (newValue) {
      if (newValue) {
        this.itemType = newValue.type ? newValue.type : 'all'
        this.itemName = newValue.name ? newValue.name : ''
        this.itemDesc = newValue.description ? newValue.description : ''
        this.position = newValue.position
        this.itemGroup = newValue.group
        this.imageList = newValue.imageList ? newValue.imageList : []
        this.itemRoutes = newValue.route
        this.itemRefNumber = newValue.refNumber
        this.itemActive = newValue.isActive
      }
    }
  },
  mounted () {
    if (this.refItem) {
      this.itemType = this.refItem.type
      this.itemName = this.refItem.name
      this.itemDesc = this.refItem.description
      this.position = this.refItem.position
      this.itemGroup = this.refItem.group
      this.itemRoutes = this.refItem.route
      this.imageList = this.refItem.imageList ? this.refItem.imageList : []
      this.itemRefNumber = this.refItem.refNumber
      this.itemActive = this.refItem.isActive
    }
  },
  methods: {
    setPosition (posDist) {
      // posDist.position {lat:lng} posDist.distance (num)
      // this.distance = posDist.distance
      this.position = posDist.position
      // this.pocOwner = posDist.pocOwner
      this.showGetPosition = false
    },
    returnImage (images) {
      this.imageList = images
      if (!this.isNewItem) {
        this.$store.dispatch('officeItemModule/set', {
          id: this.officeItemId,
          imageList: images
        })
      }
      this.showManagePicture = false
      // if (images.length !== this.imageList.length) {
      //   this.imageListModified = true
      // }
      // this.imageList = images
    },
    saveItem () {
      // if (this.officeItemId && this.officeItemId !== true) {
      //   this.saveModifiedItem()
      // } else {
      //   this.saveNewItem()
      // }
      const saveData = {}
      if (!this.isNewItem) { saveData.id = this.officeItemId }
      saveData.type = this.itemType
      saveData.name = this.itemName !== undefined ? this.itemName : null
      saveData.position = this.position !== undefined ? this.position : null
      saveData.description = this.itemDesc !== undefined ? this.itemDesc : null
      saveData.refNumber = this.itemRefNumber !== undefined ? this.itemRefNumber : null
      saveData.group = this.itemGroup !== undefined ? this.itemGroup : null
      saveData.route = this.itemRoutes !== undefined ? this.itemRoutes : null
      saveData.imageList = this.imageList !== undefined ? this.imageList : null
      saveData.isActive = this.itemActive !== undefined ? this.itemActive : null
      this.$store.dispatch('officeItemModule/set', saveData)
        .then((officeItemId) => {
          this.$store.dispatch('showMessage', this.$t('office-item-editor.successModifyMessage'))
        })
        .catch((err) => {
          this.$store.dispatch('showAlert', err.message)
        })
      this.dialog = false
      this.imageListModified = false
    },
    // saveModifiedItem () {
    //   this.$store.dispatch('officeItemModule/set', {
    //     id: this.officeItemId,
    //     type: this.itemType,
    //     name: this.itemName,
    //     position: this.position,
    //     description: this.itemDesc,
    //     refNumber: this.itemRefNumber,
    //     group: this.itemGroup ? this.itemGroup : null,
    //     route: this.itemRoutes ? this.itemRoutes : null,
    //     imageList: this.imageList,
    //     isActive: this.itemActive
    //   })
    //     .then((officeItemId) => {
    //       this.$store.dispatch('showMessage', this.$t('office-item-editor.successModifyMessage'))
    //     })
    //     .catch((err) => {
    //       this.$store.dispatch('showAlert', err.message)
    //     })
    //   this.dialog = false
    // },
    // saveNewItem () {
    //   this.$store.dispatch('officeItemModule/set', {
    //     type: this.itemType,
    //     name: this.itemName,
    //     position: this.position,
    //     description: this.itemDesc,
    //     refNumber: this.itemRefNumber,
    //     group: this.itemGroup ? this.itemGroup : null,
    //     route: this.itemRoutes ? this.itemRoutes : null,
    //     imageList: this.imageList,
    //     isActive: this.itemActive
    //   })
    //     .then((officeItemId) => {
    //       this.$store.dispatch('showMessage', this.$t('office-item-editor.successModifyMessage'))
    //     })
    //     .catch((err) => {
    //       this.$store.dispatch('showAlert', err.message)
    //     })
    //   this.dialog = false
    // },
    validType (type) {
      if (type) {
        if (type.length < 7) {
          return 'invalid empId'
        } else {
          return true
        }
      } else {
        return true
      }
    }
  }
  // destroyed () {
  //   if (this.imageListTemp.length) {
  //     this.imageListTemp.forEach(picture => {
  //       var desertRef = firebase.storage().ref().child(picture.fullPath)
  //       desertRef.delete()
  //     })
  //   }
  // }
}
</script>
