<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fixed max-width="344" transition="dialog-bottom-transition">
      <v-card>
        <v-app-bar dark color="primary">
          <v-btn icon x-large dark @click="dialog = false">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon color="green darken-1" class="ms-1">
            <v-btn icon x-large @click="returnNewDate()" color="green darken-1">
              <v-icon x-large>mdi-check-circle</v-icon>
            </v-btn>
          </v-app-bar-nav-icon>
        </v-app-bar>

        <v-time-picker full-width v-model="pickerTime"></v-time-picker>

      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ['title', 'presetDate'],
  data () {
    return {
      dialog: true,
      pickerTime: null
    }
  },
  watch: {
    dialog (newValue) {
      this.$store.dispatch('resize')
      this.dialog = false
      this.$emit('close')
    }
  },
  methods: {
    returnNewDate () {
      var d

      if (this.presetDate) {
        d = this.presetDate
      } else {
        d = new Date()
      }
      d.setHours(this.pickerTime.split(':')[0])
      d.setMinutes(this.pickerTime.split(':')[1])
      this.$emit('return-value', d)
    }
  },
  mounted () {
    if (this.presetDate) {
      this.pickerTime = this.presetDate.getHours() + ':' + this.presetDate.getMinutes()
    } else {
      const d = new Date()
      this.pickerTime = d.getHours() + ':' + d.getMinutes()
    }
  }
}
</script>
