import store from '../store/index'
// import { auth } from 'firebase/app'

export default (to, from, next) => {
  if (store.getters.user !== null && store.getters.user !== undefined) {
    next()
  } else {
    next({ name: 'login' })
  }
  // const isAuth = auth().currentUser
  // if (auth) {
  //   store.dispatch('onRouterpathChanged', to)
  //   next()
  // } else {
  //   // store.dispatch('setAppPage')
  //   next({ name: 'login' })
  // }
  // if (auth) {
  //   if (store.getters.user !== null && store.getters.user !== undefined) {
  //     next()
  //   } else {
  //     next({ name: 'login' })
  //   }
  // } else {
  //   next('login')
  // }
  // var isAuthenticated= false
  // if (localStorage.getItem('LoggedUser')) {
  //   isAuthenticated = true
  // } else {
  //   isAuthenticated= false
  // }
  // if (isAuthenticated) {
  //   next() // allow to enter route
  // } else {
  //   next({ name: 'login' }) // go to '/login';
  // }
}
