<template>
  <v-sheet
    :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
    class="px-3 pt-3 pb-3"
    :style="$store.getters.screenStyle"
  >
    <v-row>
      <v-spacer></v-spacer>
      <h1 class="mt-12 mb-5 mx-auto">{{ $t('common.loading') }}...</h1>
      <v-spacer></v-spacer>
    </v-row>
    <v-skeleton-loader
      class="mx-auto"
      max-width="300"
      type="card"
    ></v-skeleton-loader>
  </v-sheet>
</template>
<script>
export default {
  // Vuetify components provide
  // a theme variable that is
  // used to determine dark
  inject: ['theme']
}
</script>
