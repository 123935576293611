// import { arrayUnion } from 'vuex-easy-firestore'

const officeRulesModule = {
  firestorePath: 'officeData/{officeId}/params/rules',
  firestoreRefType: 'doc', // or 'doc'
  moduleName: 'officeRulesModule',
  statePropName: 'data',
  namespaced: true, // automatically added

  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    getMyAccess: (state, getters, rootState, rootGetters) => {
      const access = { visitor: true, employe: false, admin: false, cSocial: false }
      state.data.empList.forEach(rule => {
        if (rule.id === rootState.userLoggedModule.data.empid) {
          access.employe = true
          access.admin = rule.isAdmin
          access.cSocial = rule.isSocialClub
          access.isMixComite = rule.isMixComite
        }
      })
      return access
    },
    getEmpAccess: (state) => (empId) => {
      const access = { visitor: true, employe: false, admin: false, cSocial: false }
      state.data.empList.forEach(rule => {
        if (rule.id === empId) {
          access.employe = true
          access.admin = rule.admin
          access.cSocial = rule.cSocial
        }
      })
      return access
    },
    getEmpList: (state) => {
      if (state.data.empList) {
        return state.data.empList
      } else {
        return []
      }
    },
    getEmpListSorted: (state) => {
      if (state.data.empList) {
        return state.data.empList.sort(function (a, b) { return a.id - b.id })
      } else {
        return []
      }
    },
    getAuthenticatedEmpList: (state, getters) => {
      return getters.getEmpList.filter(emp => emp.userId)
    },
    getEmpExist: (state, getters) => (empId) => {
      var exist = false
      for (let i = 0; i < getters.getEmpList.length; i++) {
        const emp = getters.getEmpList[i]
        if (emp.id === empId) {
          exist = true
        }
      }
      return exist
    }
  },
  mutations: {
    setNewEmp (state, payload) {
      if (!state.data.empList) { state.data.empList = [] }
      state.data.empList.push(payload)
      this.dispatch('officeRulesModule/patch', { empList: state.data.empList })
    },
    setUserId (state, payload) {
      const empIndex = state.data.empList.map(function (x) { return x.id }).indexOf(payload.empId)
      if (empIndex > -1) {
        state.data.empList[empIndex].userId = payload.userId
        this.dispatch('officeRulesModule/patch', { empList: state.data.empList })
      }
    },
    setUpdateEmp (state, payload) {
      const empIndex = state.data.empList.map(function (x) { return x.id }).indexOf(payload.id)
      // // console.log('trace updateEmp = ' + empIndex)
      if (empIndex > -1) {
        state.data.empList[empIndex] = payload
        this.dispatch('officeRulesModule/patch', { empList: state.data.empList })
      }
    },
    setDeleteEmp (state, payload) {
      const empIndex = state.data.empList.map(function (x) { return x.id }).indexOf(payload.id)
      state.data.empList.splice(empIndex, 1)
      this.dispatch('officeRulesModule/patch', { empList: state.data.empList })
    }
  },
  actions: {
    addEmp ({ commit, getters }, payload) {
      if (!getters.getEmpExist(payload.id)) {
        commit('setNewEmp', payload)
      }
    },
    updateEmp ({ commit }, payload) {
      commit('setUpdateEmp', payload)
    },
    deleteEmp ({ commit }, payload) {
      commit('setDeleteEmp', payload)
    },
    loadOfficeUser ({ commit, getters, rootState, rootGetters }) {
      const toDo = []
      getters.getEmpList.forEach(emp => {
        if (emp.userId) {
          if (!rootState.userModule.data[emp.userId]) {
            toDo.push(this.dispatch('userModule/fetchById', emp.userId))
          }
        } else {
          toDo.push(this.dispatch('userModule/fetchAndAdd', { where: [['empid', '==', emp.id]] }).then((res) => {
            const userIdFound = rootGetters['userModule/getUserByEmpId'](emp.id)
            if (userIdFound) {
              commit('setUserId', { empId: emp.id, userId: userIdFound })
            }
            return res
          }))
        }
      })
      return Promise.all(toDo)
      // this.dispatch('userModule/fetchById', )
    }
  }
}

export default officeRulesModule
