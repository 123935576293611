const routeDayDataModule = {
  firestorePath: 'officeData/{officeId}/routeDayData/{routeId}',
  firestoreRefType: 'doc', // or 'doc'
  moduleName: 'routeDayDataModule',
  statePropName: 'data',
  namespaced: true, // automatically added

  state: {},
  getters: {
    getCurrentDay: (state) => {
      return state.data.currentDay
    },
    getColisOfday: (state, getters, rootState, rootGetters) => {
      if (state.data.colisOfday) {
        return state.data.colisOfday.map(x => {
          x.position = rootState.pocModule.data[x.pocId].homeLocation
          return {
            type: 'colis',
            id: x.colisId,
            colisId: x.colisId,
            canShipMulti: x.canShipMulti,
            shipToMulti: x.shipToMulti,
            multiId: x.multiId,
            multiType: x.multiType,
            pocId: x.pocId,
            color: x.color,
            codeName: x.codeName,
            isDone: x.isDone,
            position: x.position,
            pdrListPostalCode: rootState.pocModule.data[x.pocId].pdrListPostalCode,
            pdrListAddress: rootState.pocModule.data[x.pocId].pdrListAddress,
            label: rootState.pocModule.data[x.pocId].pdrListPostalCode.substr(3),
            marker: rootGetters.getGoogleMarker(x),
            create_At: x.create_At
          }
        })
      } else {
        return []
      }
    },
    // getParcelLockerMarker: (state, getters, rootState, rootGetters) => {
    //   let pos = 0
    //   return Object.values(state.data).filter(x => x.type === 'parcelLockerMarker' && x.isActive).map(x => {
    //     x.pos = pos++
    //     x.marker = rootGetters.getGoogleMarker(x)
    //     x.isOpenInfoWindow = true
    //     return x
    //   })
    // },
    itemsStatus: (state) => {
      return state.data.itemDone
    },
    isItemDone: (state) => (itemId) => {
      return (state.data.itemDone && state.data.itemDone[itemId] === true)
    },
    getItemDone: (state) => {
      if (state.data.itemDone) {
        return state.data.itemDone
      } else {
        return {}
      }
    },
    getLastColis: (state, getters, rootState, rootGetters) => {
      if (getters.getColisOfday.length) {
        const sortByDateCreated = function (a, b) {
          if (b.create_At.seconds === a.create_At.seconds) {
            return b.create_At.nanoseconds - a.create_At.nanoseconds
          } else {
            return b.create_At.seconds - a.create_At.seconds
          }
        }
        return getters.getColisOfday.sort(sortByDateCreated)[0]
      } else {
        return null
      }
    },
    getMyStatus: (state, getters, rootState, rootGetters) => {
      if (state.data.myStatus && state.data.myStatus[rootGetters.userId]) {
        return state.data.myStatus[rootGetters.userId]
      } else {
        return {}
      }
    },
    getMyTravelStatus: (state, getters) => (travelId) => {
      if (getters.getMyStatus && getters.getMyStatus[travelId]) {
        return getters.getMyStatus[travelId]
      } else {
        return {
          isDone: {},
          nextStep: 1
        }
      }
    },
    getMyTravelSelectedStatus: (state, getters, rootState, rootGetters) => {
      if (rootGetters.getSelectedTravelId) {
        return getters.getMyTravelStatus(rootGetters.getSelectedTravelId)
      } else {
        return null
      }
    },
    getMyTravelSelectedNextStep: (state, getters) => {
      if (getters.getMyTravelSelectedStatus) {
        return getters.getMyTravelSelectedStatus.nextStep
      } else {
        return null
      }
    },
    getMyTravelSelectedStepDone: (state, getters) => {
      if (getters.getMyTravelSelectedStatus) {
        return getters.getMyTravelSelectedStatus.isDone
      } else {
        return null
      }
    }
  },
  mutations: {
    setMyTravelStatus (state, payload) {
      // payload = { userId: '', travelId: '', status: '' }
      // console.log('trace setMyTravelStatus')
      // console.log(payload)
      if (state.data.myStatus) {
        if (state.data.myStatus[payload.userId]) {
          state.data.myStatus[payload.userId][payload.travelId] = payload.status
        } else {
          state.data.myStatus[payload.userId] = {}
          state.data.myStatus[payload.userId][payload.travelId] = payload.status
        }
      } else {
        var nStatus = {}
        nStatus[payload.userId] = {}
        nStatus[payload.userId][payload.travelId] = payload.status
        state.data.myStatus = nStatus
      }
      this.dispatch('routeDayDataModule/set', { myStatus: state.data.myStatus })
    },
    setColisOfDay (state, payload) {
      state.data.colisOfday = payload
      this.dispatch('routeDayDataModule/set', { colisOfday: payload })
    },
    setColisDone (state, payload) {
      const colisIdx = state.data.colisOfday.map(x => { return x.colisId }).indexOf(payload.colisId)
      if (colisIdx > -1) {
        state.data.colisOfday[colisIdx].isDone = payload.isDone
        this.dispatch('routeDayDataModule/set', { colisOfday: state.data.colisOfday })
      }
    },
    resetColisArrayDone (state, payload) {
      for (let i = 0; i < state.data.colisOfday.length; i++) {
        if (payload.indexOf(state.data.colisOfday[i].colisId) > -1) {
          state.data.colisOfday[i].isDone = false
        }
        this.dispatch('routeDayDataModule/set', { colisOfday: state.data.colisOfday })
      }
    },
    addColis (state, payload) {
      const colisObj = {
        type: 'colisMarker',
        colisId: this.getUniqueKey(),
        canShipMulti: payload.canShipMulti,
        shipToMulti: payload.shipToMulti,
        multiId: payload.multiId,
        multiType: payload.multiType,
        color: payload.color,
        codeName: payload.codeName,
        pocId: payload.pocId,
        isDone: false,
        create_At: this.getTimeStamp(new Date())
      }
      state.data.colisOfday.push(colisObj)
      this.dispatch('routeDayDataModule/set', { colisOfday: state.data.colisOfday })
    },
    removeColis (state, payload) {
      // payload = colisId
      const colisIdx = state.data.colisOfday.map(x => { return x.colisId }).indexOf(payload)
      if (colisIdx > -1) {
        state.data.colisOfday.splice(colisIdx, 1)
        return this.dispatch('routeDayDataModule/set', { colisOfday: state.data.colisOfday })
      } else {
        return null
      }
    },
    updateColisShipTo (state, payload) {
      const colisIdx = state.data.colisOfday.map(x => { return x.colisId }).indexOf(payload.colisId)
      if (colisIdx > -1) {
        state.data.colisOfday[colisIdx].shipToMulti = payload.shipToMulti
        state.data.colisOfday[colisIdx].multiId = payload.multiId
        return this.dispatch('routeDayDataModule/set', { colisOfday: state.data.colisOfday })
      } else {
        return null
      }
    },
    setItemDone (state, payload) {
      if (!state.data.itemDone) { state.data.itemDone = {} }
      if (state.data.itemDone[payload] !== true) {
        state.data.itemDone[payload] = true
        this.dispatch('routeDayDataModule/set', { itemDone: state.data.itemDone })
      }
    },
    setItemStatus (state, payload) {
      state.data.itemDone[payload.id] = payload.status
      this.dispatch('routeDayDataModule/set', { itemDone: state.data.itemDone })
    }
  },
  actions: {
    removeLastColisOfPoc ({ commit, state }, payload) {
      // payload = pocId
      const sortByDateCreated = function (a, b) {
        if (b.create_At.seconds === a.create_At.seconds) {
          return b.create_At.nanoseconds - a.create_At.nanoseconds
        } else {
          return b.create_At.seconds - a.create_At.seconds
        }
      }
      const pocColis = state.data.colisOfday.filter(x => x.pocId === payload).sort(sortByDateCreated)
      if (pocColis.length) {
        return commit('removeColis', pocColis[0].colisId)
      } else {
        return null
      }
    },
    updateAllPocColisShipTo ({ commit, state }, payload) {
      // payload = pocId
      state.data.colisOfday.filter(x => x.pocId === payload.pocId).forEach(c => {
        commit('updateColisShipTo', {
          colisId: c.colisId,
          shipToMulti: payload.shipToMulti,
          multiId: payload.multiId,
          multiType: payload.multiType
        })
      })
    },
    addColis ({ commit }, payload) {
      return commit('addColis', payload)
    },
    updateMyTravelStatus ({ commit, getters, rootState, rootGetters }, payload) {
      return commit('setMyTravelStatus', { userId: rootGetters.userId, travelId: rootGetters.getSelectedTravelId, status: payload })
    },
    resetMyTravelStepsStatus ({ commit, getters, rootState, rootGetters }, payload) {
      commit('resetColisArrayDone', payload.colisIdArray)
      return this.dispatch('routeDayDataModule/updateMyTravelStatus', { isDone: {}, nextStep: 1 })
    },
    setMyTravelStepComplete ({ commit, getters, rootState, rootGetters }, payload) {
      // var tStatus = this.$store.getters['routeDayDataModule/getMyTravelSelectedStatus']
      const roadBook = rootGetters['officeTravelModule/getRoadBook']
      // tStatus.isDone[step.stepId] = true
      // tStatus.nextStep = this.getNextStepNotComplete(tStatus.isDone)
      // step.colisArray.forEach(colis => {
      //   // colis.isDone = true
      //   this.$store.commit('routeDayDataModule/setColisDone', { colisId: colis.colisId, isDone: true })
      // })
      // this.$store.dispatch('routeDayDataModule/updateMyTravelStatus', tStatus)
      // this.travelModel = tStatus.nextStep

      // { stepId: 'stepId a mettre complete', steps: 'array de step sort by pos'}
      function getNextStepNotComplete (isDone) {
        let pos
        roadBook.forEach(step => {
          if (!pos && !isDone[step.stepId]) {
            pos = step.roadBookPos
          }
        })
        if (pos) {
          return pos
        } else {
          return 1
        }
      }
      function isAllStepComplete (isDone) {
        let allDone = true
        roadBook.forEach(step => {
          if (!isDone[step.stepId]) {
            allDone = false
          }
        })
        return allDone
      }
      var tStatus = getters.getMyTravelSelectedStatus
      // var isDone = this.travelSelectedStepDone
      tStatus.isDone[payload.stepId] = true
      tStatus.nextStep = getNextStepNotComplete(tStatus.isDone)
      if (isAllStepComplete(tStatus.isDone)) {
        tStatus.isDone = {}
      }
      return commit('setMyTravelStatus', { userId: rootGetters.userId, travelId: rootGetters.getSelectedTravelId, status: tStatus })
    },
    initDay ({ commit, getters, rootState, rootGetters }, payload) {
      // console.log('trace initDay date = ' + payload)
      // console.log(payload)
      // console.log(getters.getCurrentDay)
      if (payload && payload !== getters.getCurrentDay) {
        // console.log('init routeDayData de ' + payload)
        var initStatus = {}
        initStatus[rootGetters.userId] = {}
        initStatus[rootGetters.userId][rootGetters.getSelectedTravelId] = {
          isDone: {},
          nextStep: 1
        }
        // const travelId = rootGetters['officeTravelModule/getOfficialTravelOfRoute'](state.data.id)
        this.dispatch('routeDayDataModule/set', {
          currentDay: payload,
          colisOfday: [],
          itemDone: {},
          myStatus: initStatus
          // travelId: travel
        })
      } else if (!getters.getMyStatus || !getters.getMyStatus[rootGetters.getSelectedTravelId]) {
        // console.log('init only MyTravelStatus status')
        commit('setMyTravelStatus', { userId: rootGetters.userId, travelId: rootGetters.getSelectedTravelId, status: { isDone: {}, nextStep: 1 } })
      }
    }
  }
}

export default routeDayDataModule
