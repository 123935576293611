<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="444" class="mx-auto" centered>
      <v-card class='mx-auto' centered>
          <v-card-title v-if="title" class="pt-10 pb-3">
            {{ title }}
          </v-card-title>
          <v-card-subtitle v-if="subTitle">
            {{ subTitle }}
          </v-card-subtitle>
          <v-card-subtitle v-if="progressValue">
            {{ progressValue }} %
          </v-card-subtitle>
          <v-card-text class="align-end">{{ $t('common.wait') }}</v-card-text>
          <v-card-text centered>
            <v-row>
              <v-progress-linear
                v-if="progressValue === 0 || progressValue"
                :value="progressValue"
                height="7"
                color="primary"
              ></v-progress-linear>
              <v-progress-linear
                v-else
                indeterminate
                color="primary"
              ></v-progress-linear>
            </v-row>
          </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
<script>
export default {
  props: ['title', 'subTitle', 'progressValue'],
  data () {
    return {
      dialog: true
    }
  },
  methods: {
  }
}
</script>
