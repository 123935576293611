<template>
  <div class="home">
    <v-dialog
      max-width="444"
      v-model="dialog"
      hide-overlay
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="pocLoaded"
          icon
          x-large
          v-bind="attrs"
          v-on="on"
        >
          <v-icon x-large>mdi-home-search</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
          height="60"
          loading="!searchResultArray.length"
        >
          <v-spacer></v-spacer>
          <v-toolbar-title>GOOGLE {{ $t('common.search') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            large
            icon
            dark
            @click="dialog = false"
          >
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card flat>
          <v-card
            v-for="(search, i) in searchResultArray"
            :key="i"
          >
          <!--
            type: 'harmonize',
            pocId: this.pocId,
            door: old.suit + '-' + old.civic + old.suffix,
            url: url,
            street: old.street,
            city: old.city,
            province: poc.province.sname,
            searchDone: false
            postalCode: old.postalCode  -->
            <v-card class="mx-2">
              <v-card-text>
                <p class="title">{{ $t('common.search') }} : {{ i + 1 }}</p>
                <!-- <p class="mb-n2 subtitle-1">{{ search.door }} {{ search.street }}, {{ search.city }}, {{ search.province }}</p> -->
                <!-- <p class="subtitle-2">{{ search.postalCode }}</p> -->
                <p class="subtitle-2">{{ search.url }} </p>
                <p class="subtitle-1">Type: {{ search.type }} </p>
                <!-- <p class="subtitle-2">{{ search.city }}, {{ search.province }}</p> -->
              </v-card-text>
            </v-card>
            <v-progress-circular
              v-if="!search.searchDone"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <p v-if="!search.searchDone" align="center" class="subtitle">{{ $t('common.search')}} </p>
            <v-list v-else nav>
              result:
              <v-list-item
                v-for="(result, x) in search.resultArray"
                :key="x"
                @click="$emit('googleSearchResult', result); dialog = false"
              >
                <v-list-item-avatar><v-icon large>mdi-google-maps</v-icon></v-list-item-avatar>
                <v-list-item-content three-line class="grey lighten-3">
                  <v-list-item-title>{{ result.formatted_address }}</v-list-item-title>
                  <v-list-item-subtitle>{{ result.door }} {{ result.street }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{ result.city }} {{ result.postalCode }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{ result.place_id }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['pocId'],
  data () {
    return {
      dialog: false,
      showLoading: false,
      searchResultArray: [],
      pocLoaded: false
    }
  },
  methods: {
    getAddress () {
    },
    getGeoCoderResult (schadress) {
      const google = this.$store.getters.getGoogle
      const geocoder = new google.maps.Geocoder()
      // const self = this
      // then we call the geocode function with the address we want to use as parameter
      // const searchResults = geocoder.geocode({ address: schadress })
      return geocoder.geocode({ address: schadress }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          return results
        } else {
          // there were no coordinates found for the address entered (or the address was not found)
          alert('Could not find your address :(')
          return null
        }
      })
    },
    getResultDetail (result) {
      const resultObj = { geometry: null, door: null, street: null, postalCode: null, city: null, place_id: result.place_id, formatted_address: result.formatted_address }
      if (result.geometry) {
        resultObj.geometry = result.geometry
        resultObj.homeLocation = { lat: result.geometry.location.lat(), lng: result.geometry.location.lng() }
        // // console.log('formatted_address = ' + result.formatted_address)
      }
      if (result.address_components) {
        let adminLvl = 0
        // // console.log(result.address_components)
        for (let i = 0; i < result.address_components.length; i++) {
          const item = result.address_components[i]

          for (let x = 0; x < item.types.length; x++) {
            const type = item.types[x]
            if (type === 'street_number') {
              // // console.log('trace result street = ' + item.long_name)
              resultObj.door = item.long_name
            }
            if (type === 'route') {
              resultObj.street = item.long_name
            }
            if (type === 'postal_code') {
              // // console.log('trace result postal code = ' + item.long_name)
              resultObj.postalCode = item.long_name
            }
            if (type === 'administrative_area_level_5' && adminLvl < 5) {
              resultObj.city = item.long_name
              adminLvl = 4
            }
            if (type === 'administrative_area_level_4' && adminLvl < 4) {
              resultObj.city = item.long_name
              adminLvl = 4
            }
            if (type === 'administrative_area_level_3' && adminLvl < 3) {
              resultObj.city = item.long_name
              adminLvl = 3
            }
            if (type === 'administrative_area_level_2' && adminLvl < 2) {
              resultObj.city = item.long_name
              adminLvl = 2
            }
            if (type === 'administrative_area_level_1' && adminLvl < 1) {
              resultObj.city = item.long_name
              adminLvl = 1
            }
          }
        }
      }
      return resultObj
    },
    search () {
      this.searchResultArray = this.createSearchArray()
      this.searchResultArray.forEach(search => {
        this.getGeoCoderResult(search.url).then((result) => {
          if (result) {
            search.resultArray = result.results.map(x => {
              return this.getResultDetail(x)
            })
            // // console.log(search.resultArray)
          }
          search.searchDone = true
        })
      })
    },
    createSearchArray () {
      const poc = this.$store.state.pocModule.data[this.pocId]
      const addressArray = poc.pdrListAddress.trim().split(' ')
      const doorOrigin = addressArray[0]
      var door
      const street = addressArray[addressArray.length - 1]
      const searchArray = []
      var searchObjWithUrl

      door = addressArray[0]

      searchObjWithUrl = this.setSearchUrl({ type: 'original', pocId: this.pocId, address: poc.pdrListAddress, door: door, url: null, street: street, city: poc.city.sname, province: poc.province.sname, searchDone: false, postalCode: poc.pdrListPostalCode })
      searchArray.push(searchObjWithUrl)
      if (door.split('-').length > 1) {
        // search address whitout app
        // .match(/\d+/g).map(Number)
        // .replace(/[^0-9]/g, '')
        door = doorOrigin.split('-')[1]
        searchObjWithUrl = this.setSearchUrl({ type: 'originalNoApp', pocId: this.pocId, address: poc.pdrListAddress.replace(doorOrigin, door), door: door, url: null, street: street, city: poc.city.sname, province: poc.province.sname, searchDone: false, postalCode: poc.pdrListPostalCode })
        searchArray.push(searchObjWithUrl)
        if (door !== String(door.match(/\d+/g).map(Number)[0])) {
          searchObjWithUrl = this.setSearchUrl({ type: 'originalNoAppNoSuffix1', pocId: this.pocId, address: poc.pdrListAddress.replace(doorOrigin, String(door.match(/\d+/g).map(Number)[0])), door: String(door.match(/\d+/g).map(Number)[0]), url: null, street: street, city: poc.city.sname, province: poc.province.sname, searchDone: false, postalCode: poc.pdrListPostalCode })
          searchArray.push(searchObjWithUrl)
        }
      } else {
        if (door !== String(door.match(/\d+/g).map(Number)[0])) {
          searchObjWithUrl = this.setSearchUrl({ type: 'originalNoAppNoSuffix', pocId: this.pocId, address: poc.pdrListAddress.replace(doorOrigin, String(door.match(/\d+/g).map(Number)[0])), door: String(door.match(/\d+/g).map(Number)[0]), url: null, street: street, city: poc.city.sname, province: poc.province.sname, searchDone: false, postalCode: poc.pdrListPostalCode })
          searchArray.push(searchObjWithUrl)
        }
      }

      if (poc.oldAddress) {
        // // console.log(poc.oldAddress)
        for (let i = 0; i < poc.oldAddress.length; i++) {
          const old = poc.oldAddress[i]
          var url
          // // console.log('old door = ' + old.civic)
          // // console.log('old street = ' + old.street)
          if (old.suit) {
            url = (old.suit + '-' + old.civic + old.suffix + ', ' + old.street + ', ' + old.city + ', ' + poc.province.sname + ', ' + old.postalCode).normalize('NFD').replace(/[\u0300-\u036f]/g, '')
            searchArray.push({ type: 'harmonize', pocId: this.pocId, door: old.suit + '-' + old.civic + old.suffix, url: url, street: old.street, city: old.city, province: poc.province.sname, searchDone: false, postalCode: old.postalCode })
          }
          url = (old.civic + old.suffix + ', ' + old.street + ', ' + old.city + ', ' + poc.province.sname + ', ' + old.postalCode).normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          searchArray.push({ type: 'harmonizeNoApp', pocId: this.pocId, door: old.civic + old.suffix, url: url, street: old.street, city: old.city, province: poc.province.sname, searchDone: false, postalCode: old.postalCode })
          if (old.suffix) {
            url = (old.civic + ', ' + old.street + ', ' + old.city + ', ' + poc.province.sname + ', ' + old.postalCode).normalize('NFD').replace(/[\u0300-\u036f]/g, '')
            searchArray.push({ type: 'harmonizeNoAppNoSuffix', pocId: this.pocId, door: old.civic, url: url, street: old.street, city: old.city, province: poc.province.sname, searchDone: false, postalCode: old.postalCode })
          }
        }
        // searchArray.push(setSearchUrl({ type: 'old', pocId: this.pocId, door: door, url: null, street: oldStreet, city: oldCity, province: poc.province.sname, searchDone: false, postalCode: poc.oldAddress.postalCode }))
      }
      return searchArray
    },
    setSearchUrl (obj) {
      // obj = { address: '', city: '', province: '', postalCode: ''}
      obj.url = (obj.address + ', ' + obj.city + ', ' + obj.province + ', CA, ' + obj.postalCode).normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      return obj
    }
  },
  watch: {
    dialog (newValue) {
      if (newValue && !this.searchResultArray.length) {
        this.search()
      }
    }
  },
  mounted () {
    if (this.pocId) {
      if (this.$store.state.pocModule.data[this.pocId]) {
        this.pocLoaded = true
      } else {
        this.$store.dispatch('pocModule/fetchById', this.pocId).then(() => {
          this.pocLoaded = true
        })
      }
    }
  }
}
</script>
