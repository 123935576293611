<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="444" persistent fixed transition="dialog-bottom-transition">
      <v-card>
        <v-app-bar dark color="primary">
          <v-btn icon x-large dark @click="dialog = false">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-app-bar>
        <v-expansion-panels>
          <v-expansion-panel
            v-for="item of itemList"
            :key="item.value"
          >
            <v-expansion-panel-header>
              <v-list-item-content two-line>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
                <v-list-item-subtitle class="mb-n2 red--text" v-if="listType == 'POC'">{{ $store.state.pocModule.data[item.value].vacant ? $t('common.novacant') : $t('common.vacant') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- <v-select :items="seqList"></v-select> -->
              <!-- <v-row no-gutters>
                <v-col cols="6">
                  <v-btn block @click="assignItem = { type: 'before', item: item }">{{ $t('common.insertbefore') }}</v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn block @click="assignItem = { type: 'after', item: item }">{{ $t('common.insertafter') }}</v-btn>
                </v-col>
              </v-row> -->
              <v-list-item-title>Insert</v-list-item-title>
              <v-btn block large @click="assignItem = { type: 'before', item: item }">{{ $t('common.insertbefore') }}...</v-btn>
              <v-btn block large @click="assignItem = { type: 'after', item: item }">{{ $t('common.insertafter') }}...</v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <select-item-popup
          v-if="assignItem"
          :items="seqList"
          :title="$t('common.insert' + assignItem.type) + '...'"
          :subTitle="assignItem.item.text"
          @select-item="selectItem($event)"
          @close="assignItem = null"
        >
        </select-item-popup>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ['title', 'itemList', 'listType', 'seqList'],
  data () {
    return {
      dialog: true,
      notifications: false,
      assignItem: null
    }
  },
  methods: {
    selectItem (choice) {
      // // console.log(choice)
      this.$emit('assign-item', {
        type: this.assignItem.type,
        item: this.assignItem.item,
        refItem: choice
      })
      this.assignItem = null
    }
  },
  watch: {
    dialog (newValue) {
      this.dialog = false
      this.$store.dispatch('resize')
      this.$emit('close')
    }
  }
}
</script>
