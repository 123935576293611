<template>

    <v-app-bar
      id="app-bar"
      app
      color="primary"
      dark
      fixed
      :extension-height="$store.getters.appBarExtendHeight"
      :src= "pictureSrc"
      :width="contentstyles.width"
      :height="$store.getters.appBarHeight"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          :gradient="$store.getters.getDayGradient"
        ></v-img>
      </template>
      <div class="d-flex align-center">
        <v-img
          @click="toggleDrawer"
          alt="CanadaPost Logo"
          class="shrink mr-2  ms-n2"
          contain
          :src="require('@/assets/Icon/MenuIcon/logo_postes_canada.png')"
          transition="scale-transition"
          :width="$store.getters.appBarIconSize"
        />
        <p class="display-1 hidden-sm-and-down">{{ $t('common.apptitle') }} </p>
      </div>
      <v-spacer></v-spacer>
      <p class="display-1 mt-2">Admin</p>
      <v-spacer></v-spacer>
      <div class="float-right me-2" v-if="false">
        <pmt-search-popup v-if="showSearch" @close="showSearch = false"/>
        <v-btn color="white" x-large icon class="float-right"
          :loading="!loadComplete"
          :disabled="!loadComplete"
          @click="showSearch = !showSearch"
          :size="$store.getters.appBarIconSize"
        >
          <v-icon :size="$store.getters.appBarIconSize">mdi-magnify</v-icon>
        </v-btn>
      </div>
      <template v-if="showExtend" v-slot:extension>
        <v-tabs v-if="componentSelected == 'pmt-pm-babillard' && !showSearch" show-arrows align-with-title v-model="dashBoardMenuSelect">
          <v-tab>{{ $t('pmtpmbabillard.all') }}</v-tab>
          <v-tab>{{ $t('pmtpmbabillard.office') }}</v-tab>
          <v-tab>{{ $t('pmtpmbabillard.route') }}</v-tab>
          <v-tab>{{ $t('pmtpmbabillard.perso') }}</v-tab>
          <v-tab>{{ $t('pmtpmbabillard.log') }}</v-tab>
        </v-tabs>
        <!-- <div v-if="showSearch">
          <v-row>
            <v-spacer></v-spacer>
            <pmt-select-pdr-input ></pmt-select-pdr-input>
            <v-spacer></v-spacer>
          </v-row>
        </div> -->
      </template>

      </v-app-bar>
</template>

<script>
export default {
  data () {
    return {
      title: 'Postman Tools',
      drawerState: false,
      item: 1,
      dashBoardMenuSelect: 0,
      items: [
        {
          title: 'Logout'
        }
      ],
      showSearch: false

    }
  },
  props: ['drawer', 'logoutRoute', 'contentstyles', 'componentSelected'],
  methods: {
    toggleDrawer () {
      this.drawerState = !this.drawer
      this.$emit('drawer-state-changed', this.drawerState)
    }
  },
  watch: {
    dashBoardMenuSelect (newValue) {
      this.$store.dispatch('dashBoardMenuSelect', newValue)
    }
  },
  computed: {
    loadComplete () {
      return this.$store.getters.user
    },
    pictureSrc () {
      return this.$store.getters.getUserSelectOfficePictureSrc ? this.$store.getters.getUserSelectOfficePictureSrc : 'https://picsum.photos/1920/1080?random'
    },
    showExtend () {
      if (this.componentSelected === 'pmt-pm-babillard') {
        this.$store.dispatch('showExtendAppBar', 50)
        return true
      } else {
        this.$store.dispatch('showExtendAppBar', 0)
        return false
      }
    }
  }
}
</script>
<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
