
<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent fullscreen transition="dialog-bottom-transition" class="overflow-hidden">
      <!-- <v-card id="main-card" fixed class="red overflow-hidden" flat style="width: 100vw; height: 100vh;"> -->
      <!-- <v-card id="main-card" fixed class="red overflow-hidden" flat> -->
      <v-app-bar
        fixed
        dark
        color="#DAA520"
        :height="topAppBarHeight"
        :src="$store.getMarkerIconSpec($store.getters.getGoogle, { type: travel.transportType }).url"
      >
        <template v-slot:img="{ props }">
          <v-img
            v-bind="props"
            :gradient="$store.getters.getDayGradient"
          ></v-img>
        </template>
        <template v-slot:default>
          <v-tooltip bottom><!-- save -->
            <template v-slot:activator="{ on, attrs }">
              <v-app-bar-nav-icon
                @click="saveTravel()"
                v-bind="attrs"
                v-on="on"
                :disabled="!isCanBeSave"
              >
                <v-icon :color="isCanBeSave ? 'green' : 'white'" x-large>mdi-content-save-outline</v-icon>
              </v-app-bar-nav-icon>
            </template>
            <span>{{ $t('common.save') }}</span>
          </v-tooltip>
          <v-tooltip bottom><!-- undo -->
            <template v-slot:activator="{ on, attrs }">
              <v-app-bar-nav-icon
                @click="resetTravel()"
                v-bind="attrs"
                v-on="on"
                :disabled="!travelIsModified"
              >
                <v-icon x-large>mdi-undo-variant</v-icon>
              </v-app-bar-nav-icon>
            </template>
            <span>{{ $t('common.resetChange') }}</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-col class="text-center">
            <v-toolbar-title class="mb-n2 font-weight-black">{{ $t('travel-editor.title') }}</v-toolbar-title>
            <v-toolbar-title class="mb-n2">{{ travel.name }}</v-toolbar-title>
            <v-toolbar-title class="mb-n2">{{ $store.state.routeModule.data[travel.routeId].name }}</v-toolbar-title>
          </v-col>
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click="quitEditor()"><v-icon x-large>mdi-close-circle</v-icon></v-app-bar-nav-icon>
        </template>
      </v-app-bar>
      <v-row row no-gutters>
        <!-- main editor Card -->
        <v-card id="editor-card" :style="editorCardStyle">
          <h3 class="card-title">{{ $t('travel.editor-card-title') }}</h3>
          <v-card flat :height="editorCardHeight - 38" class="grey lighten-2 overflow-y-auto overflow-x-hidden">
              <travel-editor-nested draggable @travelChange="travelChange($event)" @removeItem="removeItem($event)" :itemsPath="[]" :list="travelTree" :itemWidth="editorCardWidth"></travel-editor-nested>
          </v-card>
        </v-card>
        <!-- toAssignCard Mobile-->
        <!-- <v-card id="tool-card-mobile" v-if="isMobileMode" :style="'width: 100%; height: ' + toAssignCardHeight + 'px; overflow: hidden; position: absolute; top: ' + (appBarHeight + editorCardHeight) + 'px; '"> -->
        <v-card id="tool-card-mobile" :style="toAssignCardStyle">
          <v-card flat v-if="!isMobileMode" height="315">
            <h3 class="card-title">{{ $t('travel-editor.detail-title') }}</h3>
            <v-card flat class="accent overflow-y-auto overflow-x-hidden">
              <!-- <v-card class="px-3" color="secondary"> -->
              <v-card-text>
                <v-text-field filled class="text-h5 mb-n4" outlined :label="$t('common.name')" v-model="travelName"></v-text-field>
                <v-row no-gutters class="mb-n7 mt-n2">
                  <v-spacer></v-spacer>
                  <p class="headline font-weight-gold mt-4 me-4">Status :</p>
                  <v-switch
                    v-model="isActive"
                    :disabled="!isCanBeActive || isOfficial"
                    color="success"
                    class="align-center"
                  >
                    <template v-slot:label>
                      <v-toolbar-title class="font-weight-gold" v-text="isActive ? $t('common.active') : $t('common.inactive')"></v-toolbar-title>
                    </template>
                  </v-switch>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row v-if="isOfficial" no-gutters class="pb-3">
                  <v-spacer></v-spacer>
                  <v-icon>mdi-star</v-icon>
                  <v-card-title class="font-weight-black">{{ $t('travel.isOfficial') }}</v-card-title>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row v-else-if="isCanSetOfficial" no-gutters class="mt-3 mb-7">
                  <v-spacer></v-spacer>
                  <v-btn @click="setOfficialTravel()" large rounded dark>{{ $t('travel.setOfficial') }}</v-btn>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row v-else no-gutters class="pb-3">
                  <v-spacer></v-spacer>
                  <v-card-title class="font-weight-black">{{ $t('travel.isNotOfficial') }}</v-card-title>
                  <v-spacer></v-spacer>
                </v-row>
                <!-- <v-row no-gutters class="mt-n5">
                  <v-spacer></v-spacer>
                  <p class="headline font-weight-gold mt-4 me-4">{{ $t('travel.isOfficial') }} :</p>
                  <v-switch
                    v-model="isOfficial"
                    :disabled="isOfficialDisabled"
                    color="success"
                    class="align-center"
                  >
                    <template v-slot:label>
                      <v-toolbar-title class="font-weight-gold" v-text="isOfficial ? $t('common.yes') : $t('common.no')"></v-toolbar-title>
                    </template>
                  </v-switch>
                  <v-spacer></v-spacer>
                </v-row> -->
                <stats-popup class="mt-n3 mb-3" v-if="travelLoadComplete" :pocItems="pocItemsTravel" :showInfoButton="true"></stats-popup>
              </v-card-text>
            </v-card>
            <v-card flat height="150" class="grey lighten-3 overflow-y-auto overflow-x-hidden px-2">
            <!-- <v-card :height="toAssignCardHeight" class="overflow-y-auto"> -->
              <v-card-text>
                <draggable
                  class="dragArea grey lighten-3"
                  :list="tampon"
                  :group="{ name: 'g1' }"
                  ghost-class="ghost"
                  @change="log"
                  handle=".handle"
                  v-bind="dragOptions"
                  @start="drag = true"
                  @end="drag = false"
                >
                  <div v-if="!tampon.length">
                    <p class="mx-2 pt-4">{{ $t('travel.tamponDesc') }}...</p>
                  </div>
                  <div v-else class="list-group-item" v-for="item in tampon" :key="item.id">
                    <v-card class="list-group-item my-1" :color="$store.getTravelItemCardColor(item)">
                      <travel-editor-item
                        canBeRemove
                        @removeItem="setItemNotUse($event)"
                        :item="item"
                        notDense
                        draggable
                      ></travel-editor-item>
                    </v-card>
                  </div>
                </draggable>
              </v-card-text>
            </v-card>
          </v-card>
          <v-tabs
            v-model="toAssignTabModel"
            background-color="accent"
            color="primary"
            center-active
            show-arrows
          >
            <v-tabs-slider></v-tabs-slider>
            <!-- travel Detail -->
            <v-tab v-if="isMobileMode" href="#detail">
              <!-- {{ $t('travel-editor.detail-title') }} -->
              <v-icon>mdi-information</v-icon>
            </v-tab>
            <v-tab href="#addtravelitem">
              <!-- {{ $t('travel-editor.detail-title') }} -->
              <v-icon>mdi-map-marker-multiple</v-icon>
            </v-tab>
            <!-- <v-tab href="#tools">
              <v-icon>mdi-tools</v-icon>
            </v-tab> -->
            <v-tab href="#pocassign" v-if="pocItemsNotUse.length">
              <!-- {{ $t('travel-editor.unused-title') + ' (' + travelItemsUnAssigned.length + ')' }} -->
              <v-icon>mdi-home</v-icon>
            </v-tab>
            <v-tab href="#dtdNotUsed" v-if="dtdNotUsedItems.length" dark>
              <v-badge
                overlap
                bordered
                color="accent"
                :content="dtdNotUsedItems.length"
                offset-x="-3"
                offset-y="13"
              >
                <v-img
                  :src="$store.getMapItemIconOfType('dtd')"
                  alt="item"
                  contain
                  height="30"
                  width="20"
                />
              </v-badge>
            </v-tab>
            <v-tab href="#bpComNotUsed" v-if="bpComNotUsedItems.length">
              <v-badge
                overlap
                bordered
                color="accent"
                :content="bpComNotUsedItems.length"
                offset-x="-3"
                offset-y="13"
              >
                <v-img
                  :src="$store.getMapItemIconOfType('cmb')"
                  alt="item"
                  contain
                  height="30"
                  width="20"
                />
              </v-badge>
            </v-tab>
            <v-tab href="#multiNotUsed" v-if="multiNotUsedItems.length">
              <v-badge
                overlap
                bordered
                color="accent"
                :content="multiNotUsedItems.length"
                offset-x="-3"
                offset-y="13"
              >
                <v-img
                  :src="$store.getMapItemIconOfType('lba')"
                  alt="item"
                  contain
                  height="30"
                  width="20"
                />
              </v-badge>
            </v-tab>
            <v-tab href="#greybox" v-if="greyBoxItems.length">
              <v-badge
                overlap
                bordered
                color="accent"
                :content="greyBoxItems.length"
                offset-x="-3"
                offset-y="13"
              >
                <v-img
                  :src="$store.getMarkerIconSpec($store.getters.getGoogle, { type: 'greyBoxMarker' }).url"
                  alt="item"
                  contain
                  height="30"
                  width="20"
                />
              </v-badge>
            </v-tab>
            <v-tab href="#redbox" v-if="redBoxItems.length">
              <v-img
                :src="$store.getMarkerIconSpec($store.getters.getGoogle, { type: 'redBoxMarker' }).url"
                alt="item"
                contain
                height="30"
                width="20"
              />
            </v-tab>
            <v-tab href="#parcellocker" v-if="parcelLockerItems.length">
              <v-img
                :src="$store.getMarkerIconSpec($store.getters.getGoogle, { type: 'parcelLockerMarker' }).url"
                alt="item"
                contain
                height="30"
                width="20"
              />
            </v-tab>
            <v-tab href="#group">
              <v-icon>mdi-home-group</v-icon>
            </v-tab>
            <v-tab v-if="isMobileMode" href="#tampon">
              <!-- {{ $t('travel-editor.tampon-title') + ' (' + tampon.length + ')' }} -->
              <v-icon>mdi-selection</v-icon>
            </v-tab>
            <!-- <v-tab href="#param">
              <v-icon>mdi-cog</v-icon>
            </v-tab> -->
            <v-tabs-slider></v-tabs-slider>
          </v-tabs>
          <v-tabs-items v-model="toAssignTabModel" color="#DAA520" background-color="#DAA520">
            <v-tab-item value="detail">
              <h3 class="card-title">{{ $t('travel-editor.detail-title') }}</h3>
              <v-card flat :height="toAssignTabsHeight" class="grey lighten-2 overflow-y-auto overflow-x-hidden">
                <!-- <v-card class="px-3" color="secondary"> -->
                <v-card-text>
                  <v-text-field class="mb-n4" outlined :label="$t('common.name')" v-model="travelName"></v-text-field>
                  <v-row no-gutters>
                    <v-spacer></v-spacer>
                    <p class="headline font-weight-gold mt-4 me-4">Status :</p>
                    <v-switch
                      v-model="isActive"
                      :disabled="!isCanBeActive || isOfficial"
                      color="success"
                      class="align-center"
                    >
                      <template v-slot:label>
                        <v-toolbar-title class="font-weight-gold" v-text="isActive ? $t('common.active') : $t('common.inactive')"></v-toolbar-title>
                      </template>
                    </v-switch>
                    <v-spacer></v-spacer>
                  </v-row>
                    <v-row v-if="isOfficial" no-gutters class="pb-3">
                    <v-spacer></v-spacer>
                    <v-icon>mdi-star</v-icon>
                    <v-card-title class="font-weight-black">{{ $t('travel.isOfficial') }}</v-card-title>
                    <v-spacer></v-spacer>
                  </v-row>
                  <v-row v-else-if="isCanSetOfficial" no-gutters class="mt-3 mb-7">
                    <v-spacer></v-spacer>
                    <v-btn @click="setOfficialTravel()" large rounded dark>{{ $t('travel.setOfficial') }}</v-btn>
                    <v-spacer></v-spacer>
                  </v-row>
                  <v-row v-else no-gutters class="pb-3">
                    <v-spacer></v-spacer>
                    <v-card-title class="font-weight-black">{{ $t('travel.isNotOfficial') }}</v-card-title>
                    <v-spacer></v-spacer>
                  </v-row>
                  <stats-popup class="mt" v-if="travelLoadComplete" :pocItems="pocItemsTravel" :showInfoButton="true"></stats-popup>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="tools">
              <h3 class="card-title">{{ $t('travel-editor.tools-title') }}</h3>
              <v-card flat :height="toAssignTabsHeight" class="grey lighten-2 overflow-y-auto overflow-x-hidden">
                <!-- <v-card class="px-3" color="secondary"> -->
                <v-card-text>
                  <!-- <travelAddItemPopup @create-item="createItem($event)" mode="sector"></travelAddItemPopup> -->
                  <travelAddItemPopup @create-item="createItem($event)" mode="taxi"></travelAddItemPopup>
                  <!-- <v-btn large block rounded class="my-5">ajouter section</v-btn>
                  <v-btn large block rounded class="my-5">ajouter parking</v-btn> -->
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="addtravelitem">
              <h3 class="card-title">{{ $t('pmt-create-map-direction-popup.waypoint') }}</h3>
              <v-card flat :height="toAssignTabsHeight" class="grey lighten-2 overflow-y-auto overflow-x-hidden">
                <!-- <v-card class="px-3" color="secondary"> -->
                <v-card-text>
                  <!-- <travelAddItemPopup @create-item="createItem($event)" mode="sector"></travelAddItemPopup> -->
                  <travelAddItemPopup v-if="travel.transportType === 'truck'" @create-item="createItem($event)" mode="waypoint"></travelAddItemPopup>
                  <travelAddItemPopup v-else @create-item="createItem($event)" mode="taxi"></travelAddItemPopup>
                  <draggable
                    class="dragOnlyArea"
                    :list="newItemsArray"
                    :group="{ name: 'g1', put: false }"
                    ghost-class="ghost"
                    @change="log"
                    handle=".handle"
                    v-bind="dragOptions"
                    @start="drag = true"
                    @end="drag = false"
                  >
                    <div class="list-group-item" v-for="item in newItemsArray" :key="item.id">
                      <v-card class="list-group-item" :color="$store.getTravelItemCardColor(item)">
                        <travel-editor-item
                          canBeRemove
                          @removeItem="removeNewItem($event)"
                          :item="item"
                          notDense
                          draggable
                        ></travel-editor-item>
                      </v-card>
                    </div>
                  </draggable>
                  <!-- <v-btn large block rounded class="my-5">ajouter section</v-btn>
                  <v-btn large block rounded class="my-5">ajouter parking</v-btn> -->
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="group">
              <h3 class="card-title">{{ $t('travel-editor.group-title') }}</h3>
              <v-card flat :height="toAssignTabsHeight" class="grey lighten-2 overflow-y-auto overflow-x-hidden">
              <!-- <v-card flat :height="toAssignCardHeight" class="overflow-y-auto"> -->
                <v-list
                  flat
                  subheader
                  three-line
                  class="pb-16 mb-10"
                >
                  <v-list-item-group
                    v-model="groupSelected"
                    multiple
                    active-class=""
                  >
                    <v-list-item v-for="(group, i) in travelGroup" :key="i" :value="group">
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>{{ $t('travel-group.' + group) }}</v-list-item-title>
                          <v-list-item-subtitle>{{ $t('travel-group.' + group + '-desc') }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-tab-item>
            <v-tab-item value="pocassign">
              <h3 class="card-title">{{ $t('travel-editor.unused-title') + ' (' + pocItemsNotUse.length + ')' }}</h3>
              <v-card flat :height="toAssignTabsHeight" class="grey lighten-2 overflow-y-auto overflow-x-hidden">
              <!-- <v-card flat :height="toAssignCardHeight" class="overflow-y-auto"> -->
                <v-card-text>
                  <draggable
                    class="dragOnlyArea"
                    :list="pocItemsNotUse"
                    :group="{ name: 'g1', put: false }"
                    ghost-class="ghost"
                    @change="log"
                    handle=".handle"
                    v-bind="dragOptions"
                    @start="drag = true"
                    @end="drag = false"
                  >
                    <div class="list-group-item" v-for="item in pocItemsNotUse" :key="item.id">
                      <v-card class="list-group-item" :color="$store.getTravelItemCardColor(item)">
                        <travel-editor-item
                          :item="item"
                          draggable
                        ></travel-editor-item>
                      </v-card>
                    </div>
                  </draggable>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="dtdNotUsed">
              <h3 class="card-title">{{ $t('travel-editor.unused-title') + ' (' + dtdNotUsedItems.length + ')' }}</h3>
              <v-card flat :height="toAssignTabsHeight" class="grey lighten-2 overflow-y-auto overflow-x-hidden">
              <!-- <v-card flat :height="toAssignCardHeight" class="overflow-y-auto"> -->
                <v-card-text>
                  <draggable
                    class="dragOnlyArea"
                    :list="dtdNotUsedItems"
                    :group="{ name: 'g1', put: false }"
                    ghost-class="ghost"
                    @change="log"
                    handle=".handle"
                    v-bind="dragOptions"
                    @start="drag = true"
                    @end="drag = false"
                  >
                    <div class="list-group-item" v-for="item in dtdNotUsedItems" :key="item.id">
                      <v-card class="list-group-item" :color="$store.getTravelItemCardColor(item)">
                        <travel-editor-item
                          :item="item"
                          draggable
                        ></travel-editor-item>
                      </v-card>
                    </div>
                  </draggable>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="bpComNotUsed">
              <h3 class="card-title">{{ $t('travel-editor.unused-title') + ' (' + bpComNotUsedItems.length + ')' }}</h3>
              <v-card flat :height="toAssignTabsHeight" class="grey lighten-2 overflow-y-auto overflow-x-hidden">
              <!-- <v-card flat :height="toAssignCardHeight" class="overflow-y-auto"> -->
                <v-card-text>
                  <draggable
                    class="dragOnlyArea"
                    :list="bpComNotUsedItems"
                    :group="{ name: 'g1', put: false }"
                    ghost-class="ghost"
                    @change="log"
                    handle=".handle"
                    v-bind="dragOptions"
                    @start="drag = true"
                    @end="drag = false"
                  >
                    <div class="list-group-item" v-for="item in bpComNotUsedItems" :key="item.id">
                      <v-card class="list-group-item" :color="$store.getTravelItemCardColor(item)">
                        <travel-editor-item
                          :item="item"
                          draggable
                        ></travel-editor-item>
                      </v-card>
                    </div>
                  </draggable>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="multiNotUsed">
              <h3 class="card-title">{{ $t('travel-editor.unused-title') + ' (' + multiNotUsedItems.length + ')' }}</h3>
              <v-card flat :height="toAssignTabsHeight" class="grey lighten-2 overflow-y-auto overflow-x-hidden">
              <!-- <v-card flat :height="toAssignCardHeight" class="overflow-y-auto"> -->
                <v-card-text>
                  <draggable
                    class="dragOnlyArea"
                    :list="multiNotUsedItems"
                    :group="{ name: 'g1', put: false }"
                    ghost-class="ghost"
                    @change="log"
                    handle=".handle"
                    v-bind="dragOptions"
                    @start="drag = true"
                    @end="drag = false"
                  >
                    <div class="list-group-item" v-for="item in multiNotUsedItems" :key="item.id">
                      <v-card class="list-group-item" :color="$store.getTravelItemCardColor(item)">
                        <travel-editor-item
                          :item="item"
                          draggable
                        ></travel-editor-item>
                      </v-card>
                    </div>
                  </draggable>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="greybox">
              <h3 class="card-title">{{ $t('pmtparammapsinfo.greybox') + ' (' + greyBoxItems.length + ')' }}</h3>
              <v-card flat :height="toAssignTabsHeight" class="grey lighten-2 overflow-y-auto overflow-x-hidden">
                <v-card-text>
                  <draggable
                    class="dragOnlyArea"
                    :list="greyBoxItems"
                    :group="{ name: 'g1',pull: 'clone', put: false }"
                    ghost-class="ghost"
                    :clone="cloneGreyBox"
                    @change="log"
                    handle=".handle"
                    v-bind="dragOptions"
                    @start="drag = true"
                    @end="drag = false"
                  >
                    <div class="list-group-item" v-for="item in greyBoxItems" :key="item.id">
                      <v-card class="list-group-item" :color="$store.getTravelItemCardColor(item)">
                        <travel-editor-item
                          :item="item"
                          draggable
                        ></travel-editor-item>
                      </v-card>
                    </div>
                  </draggable>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="redbox">
              <h3 class="card-title">{{ $t('pmtparammapsinfo.redbox') + ' (' + redBoxItems.length + ')' }}</h3>
              <v-card flat :height="toAssignTabsHeight" class="grey lighten-2 overflow-y-auto overflow-x-hidden">
              <!-- <v-card flat :height="toAssignCardHeight" class="overflow-y-auto"> -->
                <v-card-text>
                  <draggable
                    class="dragOnlyArea"
                    :list="redBoxItems"
                    :group="{ name: 'g1',pull: 'clone', put: false }"
                    ghost-class="ghost"
                    :clone="cloneRedBox"
                    @change="log"
                    handle=".handle"
                    v-bind="dragOptions"
                    @start="drag = true"
                    @end="drag = false"
                  >
                    <div class="list-group-item" v-for="item in redBoxItems" :key="item.id">
                      <v-card class="list-group-item" :color="$store.getTravelItemCardColor(item)">
                        <travel-editor-item
                          :item="item"
                          draggable
                        ></travel-editor-item>
                      </v-card>
                    </div>
                  </draggable>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="parcellocker">
              <h3 class="card-title">{{ $t('pmtparammapsinfo.parcellocker') + ' (' + parcelLockerItems.length + ')' }}</h3>
              <v-card flat :height="toAssignTabsHeight" class="grey lighten-2 overflow-y-auto overflow-x-hidden">
              <!-- <v-card flat :height="toAssignCardHeight" class="overflow-y-auto"> -->
                <v-card-text>
                  <draggable
                    class="dragOnlyArea"
                    :list="parcelLockerItems"
                    :group="{ name: 'g1',pull: 'clone', put: false }"
                    ghost-class="ghost"
                    :clone="cloneParcelLocker"
                    @change="log"
                    handle=".handle"
                    v-bind="dragOptions"
                    @start="drag = true"
                    @end="drag = false"
                  >
                    <div class="list-group-item" v-for="item in parcelLockerItems" :key="item.id">
                      <v-card class="list-group-item" :color="$store.getTravelItemCardColor(item)">
                        <travel-editor-item
                          :item="item"
                          draggable
                        ></travel-editor-item>
                      </v-card>
                    </div>
                  </draggable>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="tampon">
              <h3 class="card-title">{{ $t('travel-editor.tampon-title') + ' (' + tampon.length + ')' }}</h3>
              <v-card flat :height="toAssignTabsHeight" class="grey lighten-2 overflow-y-auto overflow-x-hidden">
              <!-- <v-card :height="toAssignCardHeight" class="overflow-y-auto"> -->
                <v-card-text>
                  <draggable
                    class="dragArea grey lighten-3"
                    :list="tampon"
                    :group="{ name: 'g1' }"
                    ghost-class="ghost"
                    @change="log"
                    handle=".handle"
                    v-bind="dragOptions"
                    @start="drag = true"
                    @end="drag = false"
                  >
                    <div v-if="!tampon.length">
                      <p class="mx-2 pt-4">{{ $t('travel.tamponDesc') }}...</p>
                    </div>
                    <div v-else class="list-group-item" v-for="item in tampon" :key="item.id">
                      <v-card class="list-group-item" :color="$store.getTravelItemCardColor(item)">
                        <travel-editor-item
                          :item="item"
                          draggable
                        ></travel-editor-item>
                      </v-card>
                    </div>
                  </draggable>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="param">
              <h3 class="card-title">{{ $t('travel-editor.param-title') }}</h3>
              <v-card flat :height="toAssignTabsHeight" class="grey lighten-2 overflow-y-auto overflow-x-hidden">
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
        <!-- <v-card id="tool-card" class="overflow-y-auto" :style="toolCardStyle" v-if="showToolCard">
        </v-card> -->
      </v-row>
      <!-- </v-card> -->
    </v-dialog>
    <confirm-popup
      v-if="showConfirm"
      @cancel="cancelConfirm()"
      @confirm="areConfirm()"
      :title="confirmTitle"
      :param="{ mode: 'standard', text: confirmMessText }"
    ></confirm-popup>
    <loading-popup v-if="showLoading" :title="loadingTitle" :subTitle="loadingsubTitle"></loading-popup>
  </v-row>
</template>

<script>
// import { nextTick } from 'vue/types/umd'
// import { VueNestable, VueNestableHandle } from 'vue-nestable'
import draggable from 'vuedraggable'
import travelAddItemPopup from '../common/PMT_TravelAddItem_popup.vue'
import travelEditorNested from './PMT_TravelEditorNested.vue'
export default {
  name: 'travel-editor',
  props: ['travelId'],
  components: {
    draggable,
    travelEditorNested,
    travelAddItemPopup
  },
  data () {
    return {
      dialog: true,
      toAssignTabModel: null,
      showGroupColor: false,
      showGroupCode: false,
      showGroupSector: false,
      showGroupCp: false,
      showGroupStreet: false,
      travelGroup: [],
      travelSteps: [],
      travelIsModified: false,
      drag: false,
      toolsSelected: null,
      toAssignCardPanelModel: [0],
      newItemsArray: [],
      tampon: [],
      showLoading: false,
      loadingTitle: this.$t('common.loading'),
      loadingsubTitle: this.$t('edit-route.loadingDesc'),
      showConfirm: false,
      confirmTitle: '',
      confirmMess: null,
      confirmMessText: '',
      groupSelected: this.$store.state.officeTravelModule.data[this.travelId].transportType === 'taxi' ? ['sector', 'code', 'multi'] : ['code', 'multi'],
      showToolCard: false,
      btnCreatePlaceLoading: false,
      btnCorrectPlaceLoading: false,
      btnCorrectPocLocationLoading: false,
      isActive: this.$store.state.officeTravelModule.data[this.travelId].isActive,
      isOfficial: this.$store.state.officeTravelModule.data[this.travelId].isOfficial === true,
      customGroup: this.$store.state.officeTravelModule.data[this.travelId].customGroup ? this.$store.state.officeTravelModule.data[this.travelId].customGroup : [],
      travelName: this.$store.state.officeTravelModule.data[this.travelId].name,
      travelItems: [],
      travelInfo: null,
      travelTree: [],
      travelWayPoint: [],
      TravelWpUsedArray: [],
      // travelGroupItems: [],
      // travelItemsUnAssigned: [],
      travelLoadComplete: false
      // travelItems: this.$store.getters['officeTravelModule/getTravelDetail'](this.travelId).items,
      // travelItemsUnAssigned: this.$store.getters['officeTravelModule/getTravelDetail'](this.travelId).itemsUnAssigned
    }
  },
  computed: {
    travel () {
      if (this.travelId) {
        // return this.$store.state.officeTravelModule.data[this.travelId]
        return this.$store.getters['officeTravelModule/getTravel'](this.travelId)
      } else {
        return null
      }
    },
    refTravelItems () {
      return this.travel.travelInfo.items
    },
    refTravelItemsUse () {
      return this.refTravelItems.filter(x => x.isUse)
    },
    refTravelItemsNotUse () {
      return this.refTravelItems.filter(x => !x.isUse)
    },
    pocItemsNotUse () {
      return this.travelItems.filter(x => !x.isUse && x.type === 'poc')
    },
    dtdNotUsedItems () {
      return this.travelItems.filter(x => !x.isUse && x.type === 'poc' && this.$store.state.pocModule.data[x.id].tpType === 'dtd')
    },
    bpComNotUsedItems () {
      return this.travelItems.filter(x => !x.isUse && x.type === 'poc' && this.$store.state.pocModule.data[x.id].tpType === 'cmb')
    },
    multiNotUsedItems () {
      return this.travelItems.filter(x => !x.isUse && x.type === 'poc' && (this.$store.state.pocModule.data[x.id].tpType === 'lba' || this.$store.state.pocModule.data[x.id].tpType === 'aptlba'))
    },
    travelwpNotusedArray () {
      return this.travelWayPoint.filter(x => this.TravelWpUsedArray.indexOf(x.wpId) < 0).map(item => {
        return {
          type: 'wp',
          id: this.$store.getUniqueKey(),
          wpId: item.wpId,
          wp: item,
          items: []
        }
      })
    },
    isCanBeActive () {
      if (this.pocItemsNotUse.length) {
        return false
      } else if (this.tampon.length) {
        return false
      } else {
        return true
      }
    },
    isCanSetOfficial () {
      if (this.$store.getters.isSupervisorAccess || this.$store.getters.isLocalAdmin) {
        if (this.travel.isActive && this.isActive) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    pocItemsTravel () {
      // return []
      return this.travelItems.filter(x => x.type === 'poc')
    },
    dragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    },
    appBarHeight () {
      return this.topAppBarHeight + this.extAppBarHeight
    },
    topAppBarHeight () {
      return 70
    },
    extAppBarHeight () {
      return 0
    },
    editorStyle () {
      return {
        height: this.$store.getters.screenSize.height - this.appBarHeight,
        width: this.$store.getters.screenSize.width
      }
    },
    isMobileMode () {
      if (this.$store.getters.screenSize.width < 600) {
        return true
      } else {
        return false
      }
    },
    editorCardStyle () {
      if (!this.isMobileMode) {
        let style = 'width: ' + this.editorCardWidth + 'px; '
        style += 'height: ' + this.editorCardHeight + 'px; '
        style += 'overflow-y: auto; position: absolute; left: 0px; top: ' + this.appBarHeight + 'px; '
        return style
      } else {
        let style = 'width: 100%; height: ' + this.editorCardHeight + 'px; '
        style += 'overflow-y: auto; position: absolute; left: 0px; top: ' + this.appBarHeight + 'px; '
        return style
        // return 'width: 100%; height: ' + (this.editorStyle.height - this.toolCardHeight - 200) + 'px; overflow-y: auto;'
      }
    },
    editorCardWidth () {
      if (this.isMobileMode) {
        return this.editorStyle.width
      } else {
        return Math.round(this.editorStyle.width * 0.7)
      }
    },
    editorCardHeight () {
      if (!this.isMobileMode) {
        return this.editorStyle.height - this.toolCardHeight
      } else {
        return Math.round(this.editorStyle.height * 0.5)
      }
    },
    toAssignCardStyle () {
      if (!this.isMobileMode) {
        let style = 'width: ' + (this.editorStyle.width - this.editorCardWidth) + 'px; '
        style += 'height: ' + this.toAssignCardHeight + 'px; '
        style += 'overflow-y: auto; position: absolute; right: 0px; top: ' + this.appBarHeight + 'px; '
        return style
        // return 'width: ' + (this.editorStyle.width - this.editorCardWidth) + 'px; height: ' + (this.editorStyle.height - this.toolCardHeight) + 'px; overflow-y: auto;'
      } else {
        return 'width: 100%; height: ' + this.toAssignCardHeight + 'px; overflow-y: auto; position: absolute; top: ' + (this.appBarHeight + this.editorCardHeight) + 'px; '
      }
    },
    toAssignCardStyleMobile () {
      if (!this.isMobileMode) {
        let style = 'width: ' + (this.editorStyle.width - this.editorCardWidth) + 'px; '
        style += 'height: ' + this.toAssignCardHeight + 'px; '
        style += 'overflow-y: auto; position: absolute; right: 0px; top: ' + this.appBarHeight + 'px; '
        return style
        // return 'width: ' + (this.editorStyle.width - this.editorCardWidth) + 'px; height: ' + (this.editorStyle.height - this.toolCardHeight) + 'px; overflow-y: auto;'
      } else {
        return 'width: 100%; height: ' + this.toAssignCardHeight + 'px; overflow: hidden; position: absolute; top: ' + (this.appBarHeight + this.editorCardHeight) + 'px; '
      }
    },
    toAssignCardHeight () {
      if (!this.isMobileMode) {
        return this.editorStyle.height - this.toolCardHeight
      } else {
        return this.editorStyle.height - this.editorCardHeight
      }
    },
    toAssignTabsHeight () {
      if (this.isMobileMode) {
        return this.toAssignCardHeight - 85
      } else {
        return this.toAssignCardHeight - 400
      }
    },
    toolCardStyle () {
      if (!this.isMobileMode) {
        return 'width: ' + this.editorStyle.width + 'px; height: ' + this.toolCardHeight + 'px; overflow-y: auto;'
      } else {
        return 'width: 100%'
      }
    },
    toolCardHeight () {
      if (this.showToolCard) {
        return Math.floor(this.editorStyle.height * 0.2)
      } else {
        return 0
      }
    },
    redBoxItems () {
      return this.$store.getters['officeItemModule/getRedBoxOfRoute'](this.travel.routeId).map(redBox => {
        return {
          type: 'redBox',
          id: redBox.id,
          redBoxId: redBox.id,
          name: redBox.name
        }
      })
    },
    greyBoxItems () {
      return this.$store.getters['officeItemModule/getGreyBoxOfRoute'](this.travel.routeId).map(greyBox => {
        return {
          type: 'greyBox',
          id: this.$store.getUniqueKey(),
          greyBoxId: greyBox.id,
          name: greyBox.name
        }
      })
    },
    parcelLockerItems () {
      return this.$store.getters['officeItemModule/getParcelLockerOfRoute'](this.travel.routeId).map(parcelLocker => {
        return {
          type: 'parcelLocker',
          id: parcelLocker.id,
          parcelLockerId: parcelLocker.id,
          name: parcelLocker.name
        }
      })
    },
    isCanBeSave () {
      if (this.travelIsModified || this.isActive !== this.travel.isActive) {
        return true
      } else if (this.isTravelWayPointModified) {
        return true
      } else if (this.travelName !== this.travel.name) {
        return true
      } else {
        return false
      }
    },
    isTravelWayPointModified () {
      const curr = this.travelWayPoint
      const ref = this.travel.travelInfo.travelWayPoint
      if (curr.length !== ref.length) {
        return true
      } else {
        let diff = false
        curr.forEach(trav => {
          if (ref.map(x => { return x.wpId }).indexOf(trav.wpId) < 0) {
            diff = true
          }
        })
        return diff
      }
    }
  },
  methods: {
    setOfficialTravel () {
      this.confirmTitle = this.$t('confirm-mess.setTravelOfficial')
      this.confirmMessText = this.$t('confirm-mess.setTravelOfficialDesc')
      this.confirmMode = 'setOfficial'
      this.showConfirm = true
    },
    createItem (item) {
      switch (item.type) {
        case 'park-loop':
          this.travelWayPoint.push(item)
          // this.tampon.push({
          //   type: 'wp',
          //   id: this.$store.getUniqueKey(),
          //   wpId: item.wpId,
          //   wp: item,
          //   items: []
          // })
          break
        case 'park-direct':
          this.travelWayPoint.push(item)
          break
        default:
          this.newItemsArray.push(item)
          break
      }
      // if (this.isMobileMode) {
      //   this.toAssignTabModel = 'tampon'
      // }
      // if (this.toAssignCardPanelModel.indexOf(0) < 0) {
      //   this.toAssignCardPanelModel.push(0)
      // }
    },
    updatetravelItemsFromTree () {
      let newPos = 1
      // let currentWpId = this.travelWayPoint.length ? this.travelWayPoint[0].id : null
      const self = this
      self.travelItems = []
      function setPosToItem (item, pos) {
        item.pos = pos
        // if (item.type === 'poc') {
        //   self.travelItems.push(item)
        // }
        const itemIdx = self.travelItems.map(x => { return x.id }).indexOf(item.id)
        if (itemIdx < 0) {
          if (item.type === 'poc') {
            self.travelItems.push(item)
          } else if (item.type === 'taxi-start' || item.type === 'taxi-finish' || item.type === 'taxi-direct') {
            // console.log('trace ajout')
            // console.log(item)
            self.travelItems.push(item)
          }
        } else {
          self.travelItems[itemIdx] = item
        }
      }
      for (let i = 0; i < self.travelTree.length; i++) {
        const item = self.travelTree[i]
        if (item.items && item.items.length) {
          for (let x = 0; x < item.items.length; x++) {
            const xitem = item.items[x]
            if (xitem.items && xitem.items.length) {
              for (let y = 0; y < xitem.items.length; y++) {
                const yitem = xitem.items[y]
                if (yitem.items && yitem.items.length) {
                  for (let z = 0; z < yitem.items.length; z++) {
                    const zitem = yitem.items[z]
                    if (zitem.items && zitem.items.length) {
                      for (let z1 = 0; z1 < zitem.items.length; z1++) {
                        const z1item = zitem.items[z1]
                        if (z1item.items && z1item.items.length) {
                        } else {
                          setPosToItem(z1item, newPos)
                          newPos++
                        }
                      }
                    } else {
                      setPosToItem(zitem, newPos)
                      newPos++
                    }
                  }
                } else {
                  setPosToItem(yitem, newPos)
                  newPos++
                }
              }
            } else {
              setPosToItem(xitem, newPos)
              newPos++
            }
          }
        } else {
          setPosToItem(item, newPos)
          newPos++
        }
      }
      this.travelTree = this.generateDetailTree(this.groupSelected)
    },
    getPossibleGroup (items) {
      const group = []
      const colorGroup = []
      const codeGroup = []
      let sectorGroup = false
      for (let i = 0; i < items.length; i++) {
        const item = items[i]
        if (item.type === 'poc') {
          if (item.colorId !== null && item.colorId !== undefined) {
            colorGroup.push(item.colorId)
          }
          if (item.codeId !== null && item.codeId !== undefined) {
            codeGroup.push(item.codeId)
          }
        } else {
          if (item.type === 'greyBox' || item.type === 'bpcom') {
            sectorGroup = true
          }
        }
      }
      if (sectorGroup) { group.push('sector') }
      if (colorGroup.length > 1) { group.push('color') }
      if (codeGroup.length > 1) { group.push('code') }
      const groupDefault = ['cp', 'street', 'multi']
      return [].concat(group, groupDefault)
    },
    generateDetailTree (group) {
      const dt = this.$store.generateTravelTree(this.travelInfo, group)
      // this.wpUsedArray = dt.wpUsedArray
      return dt

      // if (this.travel.transportType === 'taxi') {
      //   return this.generateDetailTreeTaxi(group)
      // } else {
      //   return this.generateDetailTreeTruck(group)
      // }
    },
    generateDetailTreeTruck_back (group) {
      const tree = []
      const sectorId = 1
      let stepId
      this.travelSteps = []
      const showSectorGroup = group.indexOf('sector') > -1
      const showColorGroup = group.indexOf('color') > -1
      const showCodeGroup = group.indexOf('code') > -1
      const showCpGroup = group.indexOf('cp') > -1
      const showStreetGroup = group.indexOf('street') > -1
      const showMultiGroup = group.indexOf('multi') > -1
      let currentSectorId = null
      let currentColorId = null
      let currentCodeId = null
      let currentCustomGroup = null
      let currentCp = null
      let currentWpId = this.travelWayPoint.length ? this.travelWayPoint[0].wpId : null
      // let currentWpId = null
      const wpUsedArray = []
      let currentStreet = null
      let currentMulti = null
      let sectorIdx = null
      let wpIdx = null
      let colorIdx = null
      let codeIdx = null
      let cpIdx = null
      let streetIdx = null
      let multiIdx = null
      const tpTypeArray = []
      const travelItemSorted = this.travelItems.filter(x => x.isUse).sort(function (a, b) {
        return a.pos - b.pos
      })
      for (let itemIdx = 0; itemIdx < travelItemSorted.length; itemIdx++) {
        const item = travelItemSorted[itemIdx]
        if (item.type === 'poc') {
          const poc = this.$store.state.pocModule.data[item.id]
          item.stepId = stepId
          if (tpTypeArray.indexOf(poc.tpType) < 0) { tpTypeArray.push(poc.tpType) }
          let pocPath = tree
          if (item.group) {
            if (currentCustomGroup !== item.group) {
              pocPath.push({
                type: 'customGroup',
                id: this.$store.getUniqueKey(),
                groupId: item.group,
                colorId: item.colorId,
                items: []
              })
              currentCustomGroup = item.group
              currentSectorId = null
              currentColorId = null
              currentCodeId = null
              currentCp = null
              currentWpId = null
              currentStreet = null
              currentMulti = null
              sectorIdx = pocPath.length - 1
            }
            pocPath = pocPath[sectorIdx].items
          }
          if (showSectorGroup) {
            if (currentSectorId !== sectorId) {
              pocPath.push({
                type: 'sector',
                id: this.$store.getUniqueKey(),
                sectorId: sectorId,
                items: []
              })
              currentSectorId = sectorId
              currentColorId = null
              currentWpId = null
              currentCodeId = null
              currentCp = null
              currentStreet = null
              currentMulti = null
              sectorIdx = pocPath.length - 1
            }
            pocPath = pocPath[sectorIdx].items
          }
          if (showColorGroup) {
            if (currentColorId !== item.colorId) {
              pocPath.push({
                type: 'color',
                id: this.$store.getUniqueKey(),
                colorId: item.colorId,
                items: []
              })
              currentColorId = item.colorId
              currentCodeId = null
              currentCp = null
              currentStreet = null
              currentWpId = null
              currentMulti = null
              colorIdx = pocPath.length - 1
            }
            pocPath = pocPath[colorIdx].items
          }
          if (!item.wpId) { item.wpId = currentWpId }
          if (String(currentWpId) !== String(item.wpId) || wpIdx === null) {
            const wId = item.wpId ? item.wpId : currentWpId
            wpUsedArray.push(wId)
            pocPath.push({
              type: 'wp',
              id: this.$store.getUniqueKey(),
              wpId: wId,
              wp: this.travelWayPoint[this.travelWayPoint.map(x => { return x.wpId }).indexOf(wId)],
              items: []
            })
            currentWpId = wId
            currentCodeId = null
            currentCp = null
            currentStreet = null
            currentMulti = null
            wpIdx = pocPath.length - 1
          }
          pocPath = pocPath[wpIdx].items
          if (showCodeGroup && item.codeId) {
            if (currentCodeId !== item.codeId) {
              pocPath.push({
                type: 'code',
                id: this.$store.getUniqueKey(),
                codeId: item.codeId,
                codeName: item.codeName,
                colorId: item.colorId,
                items: []
              })
              currentCodeId = item.codeId
              currentCp = null
              currentStreet = null
              currentMulti = null
              codeIdx = pocPath.length - 1
            }
            pocPath = pocPath[codeIdx].items
          }
          if (showStreetGroup) {
            const street = poc.pdrListAddress.replace(poc.pdrListAddress.split(' ')[0], '').trim()
            if (currentStreet !== street) {
              pocPath.push({
                type: 'street',
                id: this.$store.getUniqueKey(),
                street: street,
                items: []
              })
              currentStreet = street
              currentCp = null
              currentMulti = null
              streetIdx = pocPath.length - 1
            }
            pocPath = pocPath[streetIdx].items
          }
          if (showCpGroup) {
            if (currentCp !== poc.pdrListPostalCode) {
              pocPath.push({
                type: 'cp',
                id: this.$store.getUniqueKey(),
                cp: poc.pdrListPostalCode,
                colorId: item.colorId,
                items: []
              })
              currentCp = poc.pdrListPostalCode
              currentMulti = null
              cpIdx = pocPath.length - 1
            }
            pocPath = pocPath[cpIdx].items
          }
          if (showMultiGroup) {
            const multiId = poc.mapItems[0].officeItemMultiId ? poc.mapItems[0].officeItemMultiId : null
            if (multiId) {
              if (currentMulti !== multiId) {
                pocPath.push({
                  type: 'multi',
                  multiType: poc.mapItems[0].type,
                  id: this.$store.getUniqueKey(),
                  multiId: multiId,
                  colorId: item.colorId,
                  items: []
                })
                currentMulti = multiId
                // currentSectorId = null
                // currentWpId = null
                // currentColorId = null
                // currentCodeId = null
                // currentCp = null
                // currentStreet = null
                multiIdx = pocPath.length - 1
              }
              pocPath = pocPath[multiIdx].items
            } else {
              if (currentMulti) {
                // currentSectorId = null
                // currentColorId = null
                // currentCodeId = null
                // currentCp = null
                // currentStreet = null
              }
              currentMulti = null
            }
          }
          pocPath.push(item)
        } else {
          if (item.type === 'greyBox') {
            // sectorId++
            tree.push(item)
            currentColorId = null
            currentCodeId = null
          // } else if (item.type === 'wp' && !item.items.length) {
          //   sectorId++
          //   tree.push(item)
          //   currentWpId = item.wpId
          //   currentCodeId = null
          } else if (item.type === 'parcelLocker') {
            tree.push(item)
            currentColorId = null
            currentCodeId = null
          } else {
            stepId++
            // // console.log('trace a traiter type = ' + item.type)
          }
        }
      }
      this.TravelWpUsedArray = wpUsedArray
      return tree
    },
    generateDetailTreeTruck (group) {
      const tree = []
      const sectorId = 1
      let stepId
      this.travelSteps = []
      const showSectorGroup = group.indexOf('sector') > -1
      const showColorGroup = group.indexOf('color') > -1
      const showCodeGroup = group.indexOf('code') > -1
      const showCpGroup = group.indexOf('cp') > -1
      const showStreetGroup = group.indexOf('street') > -1
      const showMultiGroup = group.indexOf('multi') > -1
      let currentSectorId = null
      let currentColorId = null
      let currentCodeId = null
      let currentCustomGroup = null
      let currentCp = null
      let currentWpId = this.travelWayPoint.length ? this.travelWayPoint[0].wpId : null
      // let currentWpId = null
      const wpUsedArray = []
      let currentStreet = null
      let currentMulti = null
      let sectorIdx = null
      let wpIdx = null
      let colorIdx = null
      let codeIdx = null
      let cpIdx = null
      let streetIdx = null
      let multiIdx = null
      const tpTypeArray = []
      const travelItemSorted = this.travelItems.filter(x => x.isUse).sort(function (a, b) {
        return a.pos - b.pos
      })
      for (let itemIdx = 0; itemIdx < travelItemSorted.length; itemIdx++) {
        const item = travelItemSorted[itemIdx]
        if (item.type === 'poc') {
          const poc = this.$store.state.pocModule.data[item.id]
          item.stepId = stepId
          if (tpTypeArray.indexOf(poc.tpType) < 0) { tpTypeArray.push(poc.tpType) }
          let pocPath = tree
          if (item.group) {
            if (currentCustomGroup !== item.group) {
              pocPath.push({
                type: 'customGroup',
                id: this.$store.getUniqueKey(),
                groupId: item.group,
                colorId: item.colorId,
                items: []
              })
              currentCustomGroup = item.group
              currentSectorId = null
              currentColorId = null
              currentCodeId = null
              currentCp = null
              currentWpId = null
              currentStreet = null
              currentMulti = null
              sectorIdx = pocPath.length - 1
            }
            pocPath = pocPath[sectorIdx].items
          }
          if (showSectorGroup) {
            if (currentSectorId !== sectorId) {
              pocPath.push({
                type: 'sector',
                id: this.$store.getUniqueKey(),
                sectorId: sectorId,
                items: []
              })
              currentSectorId = sectorId
              currentColorId = null
              currentWpId = null
              currentCodeId = null
              currentCp = null
              currentStreet = null
              currentMulti = null
              sectorIdx = pocPath.length - 1
            }
            pocPath = pocPath[sectorIdx].items
          }
          if (showColorGroup) {
            if (currentColorId !== item.colorId) {
              pocPath.push({
                type: 'color',
                id: this.$store.getUniqueKey(),
                colorId: item.colorId,
                items: []
              })
              currentColorId = item.colorId
              currentCodeId = null
              currentCp = null
              currentStreet = null
              currentWpId = null
              currentMulti = null
              colorIdx = pocPath.length - 1
            }
            pocPath = pocPath[colorIdx].items
          }
          if (!item.wpId) { item.wpId = currentWpId }
          if (String(currentWpId) !== String(item.wpId) || wpIdx === null) {
            const wId = item.wpId ? item.wpId : currentWpId
            wpUsedArray.push(wId)
            pocPath.push({
              type: 'wp',
              id: this.$store.getUniqueKey(),
              wpId: wId,
              wp: this.travelWayPoint[this.travelWayPoint.map(x => { return x.wpId }).indexOf(wId)],
              items: []
            })
            currentWpId = wId
            currentCodeId = null
            currentCp = null
            currentStreet = null
            currentMulti = null
            wpIdx = pocPath.length - 1
          }
          pocPath = pocPath[wpIdx].items
          if (showCodeGroup && item.codeId) {
            if (currentCodeId !== item.codeId) {
              pocPath.push({
                type: 'code',
                id: this.$store.getUniqueKey(),
                codeId: item.codeId,
                codeName: item.codeName,
                colorId: item.colorId,
                items: []
              })
              currentCodeId = item.codeId
              currentCp = null
              currentStreet = null
              currentMulti = null
              codeIdx = pocPath.length - 1
            }
            pocPath = pocPath[codeIdx].items
          }
          if (showStreetGroup) {
            const street = poc.pdrListAddress.replace(poc.pdrListAddress.split(' ')[0], '').trim()
            if (currentStreet !== street) {
              pocPath.push({
                type: 'street',
                id: this.$store.getUniqueKey(),
                street: street,
                items: []
              })
              currentStreet = street
              currentCp = null
              currentMulti = null
              streetIdx = pocPath.length - 1
            }
            pocPath = pocPath[streetIdx].items
          }
          if (showCpGroup) {
            if (currentCp !== poc.pdrListPostalCode) {
              pocPath.push({
                type: 'cp',
                id: this.$store.getUniqueKey(),
                cp: poc.pdrListPostalCode,
                colorId: item.colorId,
                items: []
              })
              currentCp = poc.pdrListPostalCode
              currentMulti = null
              cpIdx = pocPath.length - 1
            }
            pocPath = pocPath[cpIdx].items
          }
          if (showMultiGroup) {
            const multiId = poc.mapItems[0].officeItemMultiId ? poc.mapItems[0].officeItemMultiId : null
            if (multiId) {
              if (currentMulti !== multiId) {
                pocPath.push({
                  type: 'multi',
                  multiType: poc.mapItems[0].type,
                  id: this.$store.getUniqueKey(),
                  multiId: multiId,
                  colorId: item.colorId,
                  items: []
                })
                currentMulti = multiId
                // currentSectorId = null
                // currentWpId = null
                // currentColorId = null
                // currentCodeId = null
                // currentCp = null
                // currentStreet = null
                multiIdx = pocPath.length - 1
              }
              pocPath = pocPath[multiIdx].items
            } else {
              if (currentMulti) {
                // currentSectorId = null
                // currentColorId = null
                // currentCodeId = null
                // currentCp = null
                // currentStreet = null
              }
              currentMulti = null
            }
          }
          pocPath.push(item)
        } else {
          if (item.type === 'greyBox') {
            // sectorId++
            tree.push(item)
            currentColorId = null
            currentCodeId = null
          // } else if (item.type === 'wp' && !item.items.length) {
          //   sectorId++
          //   tree.push(item)
          //   currentWpId = item.wpId
          //   currentCodeId = null
          } else if (item.type === 'parcelLocker') {
            tree.push(item)
            currentColorId = null
            currentCodeId = null
          } else {
            stepId++
            // // console.log('trace a traiter type = ' + item.type)
          }
        }
      }
      this.TravelWpUsedArray = wpUsedArray
      return tree
    },
    generateDetailTreeTaxi (group) {
      const tree = []
      // let lastPath
      let sectorId = 1
      let stepIdx = 0
      let stepId = this.$store.getUniqueKey()
      const showSectorGroup = group.indexOf('sector') > -1
      const showColorGroup = group.indexOf('color') > -1
      const showCodeGroup = group.indexOf('code') > -1
      const showCpGroup = group.indexOf('cp') > -1
      const showStreetGroup = group.indexOf('street') > -1
      const showMultiGroup = group.indexOf('multi') > -1
      let currentSectorId = null
      let currentColorId = null
      let currentCodeId = null
      let currentCp = null
      let currentStreet = null
      let currentMulti = null
      let sectorIdx = null
      let colorIdx = null
      let codeIdx = null
      let cpIdx = null
      let streetIdx = null
      let multiIdx = null
      const travelItemSorted = this.travelItems.filter(x => x.isUse).sort(function (a, b) {
        return a.pos - b.pos
      })
      this.travelSteps = [{
        type: 'loop',
        stepId: stepId,
        pos: stepIdx + 1,
        fromItemId: travelItemSorted[0].id,
        toItemId: null
      }]
      for (let itemIdx = 0; itemIdx < travelItemSorted.length; itemIdx++) {
        const item = travelItemSorted[itemIdx]
        if (item.type === 'poc') {
          const poc = this.$store.state.pocModule.data[item.id]
          let pocPath = tree
          item.stepId = stepId
          if (showSectorGroup) {
            if (currentSectorId !== sectorId) {
              pocPath.push({
                type: 'sector',
                id: this.$store.getUniqueKey(),
                sectorId: sectorId,
                colorId: item.colorId,
                items: []
              })
              currentSectorId = sectorId
              currentColorId = null
              currentCodeId = null
              currentCp = null
              currentStreet = null
              currentMulti = null
              sectorIdx = pocPath.length - 1
            }
            pocPath = pocPath[sectorIdx].items
          }
          if (showColorGroup) {
            if (currentColorId !== item.colorId) {
              pocPath.push({
                type: 'color',
                id: this.$store.getUniqueKey(),
                colorId: item.colorId,
                items: []
              })
              currentColorId = item.colorId
              currentCodeId = null
              currentCp = null
              currentStreet = null
              currentMulti = null
              colorIdx = pocPath.length - 1
            }
            pocPath = pocPath[colorIdx].items
          }
          if (showCodeGroup) {
            if (currentCodeId !== item.codeId) {
              pocPath.push({
                type: 'code',
                id: this.$store.getUniqueKey(),
                codeId: item.codeId,
                codeName: item.codeName,
                colorId: item.colorId,
                items: []
              })
              currentCodeId = item.codeId
              currentCp = null
              currentStreet = null
              currentMulti = null
              codeIdx = pocPath.length - 1
            }
            pocPath = pocPath[codeIdx].items
          }
          if (showStreetGroup) {
            const street = poc.pdrListAddress.replace(poc.pdrListAddress.split(' ')[0], '').trim()
            if (currentStreet !== street) {
              pocPath.push({
                type: 'street',
                id: this.$store.getUniqueKey(),
                street: street,
                items: []
              })
              currentStreet = street
              currentCp = null
              currentMulti = null
              streetIdx = pocPath.length - 1
            }
            pocPath = pocPath[streetIdx].items
          }
          if (showCpGroup) {
            if (currentCp !== poc.pdrListPostalCode) {
              pocPath.push({
                type: 'cp',
                id: this.$store.getUniqueKey(),
                cp: poc.pdrListPostalCode,
                colorId: item.colorId,
                items: []
              })
              currentCp = poc.pdrListPostalCode
              currentMulti = null
              cpIdx = pocPath.length - 1
            }
            pocPath = pocPath[cpIdx].items
          }
          if (showMultiGroup) {
            const multiId = poc.mapItems[0].officeItemMultiId ? poc.mapItems[0].officeItemMultiId : null
            if (multiId) {
              if (currentMulti !== multiId) {
                pocPath.push({
                  type: 'multi',
                  multiType: poc.mapItems[0].type,
                  id: this.$store.getUniqueKey(),
                  multiId: multiId,
                  colorId: item.colorId,
                  items: []
                })
                currentMulti = multiId
                multiIdx = pocPath.length - 1
              }
              pocPath = pocPath[multiIdx].items
            } else {
              currentMulti = null
            }
          }
          pocPath.push(item)
          // lastPath = pocPath
        } else {
          if (item.type === 'greyBox') {
            sectorId++
            this.travelSteps[stepIdx].toItemId = item.id
            stepIdx++
            stepId = this.$store.getUniqueKey()
            this.travelSteps.push({
              type: 'loop',
              stepId: stepId,
              pos: stepIdx + 1,
              fromItemId: item.id,
              toItemId: null
            })
            tree.push(item)
            currentColorId = null
            currentCodeId = null
          } else if (item.type === 'parcelLocker') {
            tree.push(item)
            item.stepId = stepId
            currentSectorId = null
            currentColorId = null
            currentCodeId = null
          } else if (item.type === 'redBox') {
            // lastPath.push(item)
            tree.push(item)
            item.stepId = stepId
            currentSectorId = null
            currentColorId = null
            currentCodeId = null
          } else if (item.type === 'wp') {
            tree.push(item)
            currentSectorId = null
            currentColorId = null
            currentCodeId = null
          } else {
            tree.push(item)
          }
        }
      }
      return tree
    },
    updateStepsFromToItem () {
      const travelItemSorted = this.travelItems.filter(x => x.isUse).sort(function (a, b) {
        return a.pos - b.pos
      })
      // console.log('nbr travelItem = ' + travelItemSorted.length)
      this.travelSteps.sort(this.$store.sortByPos).forEach(step => {
        const stepItems = travelItemSorted.filter(x => x.stepId === step.stepId).sort(this.$store.sortByPos)
        if (stepItems.length) {
          const fItem = stepItems[0]
          const lItem = stepItems[stepItems.length - 1]
          const fItemIdx = travelItemSorted.map(x => { return x.id }).indexOf(fItem.id)
          const lItemIdx = travelItemSorted.map(x => { return x.id }).indexOf(lItem.id)
          // console.log(step.pos + '- nbr item: ' + stepItems.length + ' fIdx: ' + fItemIdx + ' lIdx: ' + lItemIdx)
          var fItemId, lItemId
          for (let i = 1; i < 10; i++) {
            // // console.log('loop ' + i)
            const fIdx = fItemIdx - i
            const lIdx = lItemIdx + i
            if (!fItemId && fIdx > -1) {
              switch (travelItemSorted[fIdx].type) {
                case 'greyBox':
                case 'taxi-start':
                case 'taxi-direct':
                case 'taxi-finish':
                  // console.log('trace step start idx = ' + fIdx + ' = ' + travelItemSorted[fIdx].id)
                  fItemId = travelItemSorted[fIdx].id
                  break
                default:
                  break
              }
            }
            if (!lItemId && lIdx < travelItemSorted.length) {
              // // console.log('tracehr step idx = ' + lIdx + ' pos = ' + step.pos + ' type = ' + travelItemSorted[lIdx].type)
              switch (travelItemSorted[lIdx].type) {
                case 'greyBox':
                case 'taxi-start':
                case 'taxi-direct':
                case 'taxi-finish':
                  // console.log('trace step end = ' + travelItemSorted[lIdx].id)
                  lItemId = travelItemSorted[lIdx].id
                  break
                default:
                  break
              }
            }
          }
          if (fItemId) { step.fromItemId = fItemId }
          if (lItemId) { step.toItemId = lItemId }
        }
      })
    },
    setItemPosOftravelTree () {
      let newPos = 1
      let needRebuild = false
      const self = this
      function setItem (item, pos) {
        item.pos = pos
        item.isUse = true
        if (!item.loc && self.isTravelItemNeedLocation(item)) {
          item.loc = self.generateTravelItemLocation(item)
        }
        const itemIdx = self.travelItems.map(x => { return x.id }).indexOf(item.id)
        if (itemIdx < 0) {
          if (self.isTravelItem(item)) {
            if (item.type === 'greyBox') {
              needRebuild = true
            }
            self.travelItems.push(item)
          }
        } else {
          self.travelItems[itemIdx] = item
        }
      }
      for (let i = 0; i < self.travelTree.length; i++) {
        const item = self.travelTree[i]
        if (item.items && item.items.length) {
          for (let x = 0; x < item.items.length; x++) {
            const xitem = item.items[x]
            if (xitem.items && xitem.items.length) {
              for (let y = 0; y < xitem.items.length; y++) {
                const yitem = xitem.items[y]
                if (yitem.items && yitem.items.length) {
                  for (let z = 0; z < yitem.items.length; z++) {
                    const zitem = yitem.items[z]
                    if (zitem.items && zitem.items.length) {
                      for (let z1 = 0; z1 < zitem.items.length; z1++) {
                        const z1item = zitem.items[z1]
                        if (z1item.items && z1item.items.length) {
                          // // console.log('trace erreur too much children')
                        } else {
                          if (this.isTravelItem(z1item)) { setItem(z1item, newPos); newPos++ }
                        }
                      }
                    } else {
                      if (this.isTravelItem(zitem)) { setItem(zitem, newPos); newPos++ }
                    }
                  }
                } else {
                  if (this.isTravelItem(yitem)) { setItem(yitem, newPos); newPos++ }
                }
              }
            } else {
              if (this.isTravelItem(xitem)) { setItem(xitem, newPos); newPos++ }
            }
          }
        } else {
          if (this.isTravelItem(item)) { setItem(item, newPos); newPos++ }
        }
      }
      return needRebuild
    },
    isTravelItem (item) {
      switch (item.type) {
        case 'poc':
          return true
        case 'greyBox':
          return true
        case 'redBox':
          return true
        case 'parcelLocker':
          return true
        case 'taxi-start':
        case 'taxi-finish':
        case 'taxi-direct':
          return true
        default:
          return false
      }
    },
    isTravelItemNeedLocation (item) {
      switch (item.type) {
        case 'taxi-start':
        case 'taxi-finish':
        case 'taxi-direct':
          return true
        default:
          return false
      }
    },
    generateTravelItemLocation (item) {
      var travelItemSorted
      var refItem
      switch (item.type) {
        case 'taxi-start':
          travelItemSorted = this.travelItems.filter(x => x.pos).sort(function (a, b) {
            return a.pos - b.pos
          })
          refItem = travelItemSorted[0]
          switch (refItem.type) {
            case 'poc':
              return this.$store.state.pocModule.data[refItem.id].homeLocation
            default:
              return this.$store.getters.getCurrentPosition
          }
        case 'taxi-finish':
          travelItemSorted = this.travelItems.filter(x => x.pos).sort(function (a, b) {
            return b.pos - a.pos
          })
          refItem = travelItemSorted[0]
          switch (refItem.type) {
            case 'poc':
              return this.$store.state.pocModule.data[refItem.id].homeLocation
            default:
              return this.$store.getters.getCurrentPosition
          }
        case 'taxi-direct':
          return this.$store.getters.getCurrentPosition
        default:
          return this.$store.getters.getCurrentPosition
      }
    },
    resetTravelTree () {
      this.travelTree = this.generateDetailTree(this.groupSelected)
    },
    correctTravelTree () {
      let needRebuild = this.setItemPosOftravelTree()
      let lastWpId = null
      const trace = {
        wpId: null,
        sectorId: null
      }
      function correctItem (item) {
        switch (item.type) {
          case 'poc':
            if (trace.wpId) {
              if (trace.wpId !== item.wpId) {
                item.wpId = trace.wpId
                needRebuild = true
              }
            } else {
              if (!item.wpId) {
                item.wpId = lastWpId
                needRebuild = true
              }
            }
            if (trace.sectorId) {
              if (trace.sectorId !== item.sectorId) {
                item.sectorId = trace.sectorId
                needRebuild = true
              }
            }
            break
          case 'wp':
            trace.wpId = item.wpId
            lastWpId = item.wpId
            break
          case 'sector':
            trace.sectorId = item.sectorId
            break
          default:
            break
        }
        if (item.items && item.items.length) {
          for (let i = 0; i < item.items.length; i++) {
            correctItem(item.items[i])
          }
        }
      }
      for (let i1 = 0; i1 < this.travelTree.length; i1++) {
        correctItem(this.travelTree[i1])
      }
      if (needRebuild) {
        this.travelTree = this.generateDetailTree(this.groupSelected)
      }
    },
    correctTravelTree_back () {
      this.setItemPosOftravelTree()
      let needRebuildTree = false
      function verifItem (item, trace) {
        if (item.type === 'wp') {
          if (trace.wpId) {
            needRebuildTree = true
          }
          trace.wpId = item.wpId
        }
        if (item.items && item.items.length) {
          // verif waypoint imbriquer
          if (item.wpId !== undefined && trace.wpId !== item.wpId) {
            if (trace.wpId) {
              needRebuildTree = true
            }
            trace.wpId = item.wpId
          }
          // verif sector imbriquer
          if (item.sectorId !== undefined && trace.sectorId !== item.sectorId) {
            if (trace.sectorId) {
              needRebuildTree = true
            }
            trace.sectorId = item.sectorId
          }
          item.items.map(x => { verifItem(x, trace) })
          // verifItem(item, pos, trace)
        } else {
          // if (item.type === 'poc') {
          if (trace.wpId !== undefined) {
            if (item.wpId !== trace.wpId) {
              item.wpId = trace.wpId
            }
          } else {
            needRebuildTree = true
          }
          // }
          // setPosToItem(item, newPos)
          // newPos++
        }
      }
      this.travelTree.map(x => { verifItem(x, {}) })
      // verifItem(this.travelTree, newPos, {})
      if (needRebuildTree) {
        this.travelTree = this.generateDetailTree(this.groupSelected)
      }
    },
    doTask (task) {
      switch (task.taskType) {
        case 'save':
          // this.mapIsLoaded = true
          break
        default:
          // this.$emit('doTask', task)
          break
      }
    },
    cancelConfirm () {
      this.showConfirm = false
      this.confirmMessText = ''
      this.confirmTitle = ''
      switch (this.confirmMode) {
        case 'add':
          this.deleteTempAddPicture()
          break
        default:
          break
      }
    },
    areConfirm () {
      this.showConfirm = false
      switch (this.confirmMode) {
        case 'delete':
          this.deletePicture()
          break
        case 'add':
          this.setPicture(this.addPictureList)
          break
        case 'quitNoSave':
          this.dialog = false
          break
        case 'setOfficial':
          this.$store.commit('officeTravelModule/setOfficialTravel', this.travelId)
          this.isOfficial = true
          break
        case 'save':
          // console.log(this.travelSteps)
          this.setWplatlngFromTree()
          this.updateStepsFromToItem()
          this.loadingTitle = this.$t('common.saveinprogress')
          this.loadingsubTitle = null
          this.showLoading = true
          // // console.log(this.travelItems)
          // console.log(this.travelSteps)
          this.$store.dispatch('officeTravelModule/set', {
            id: this.travel.id,
            name: this.travelName,
            travelInfo: {
              items: this.travelItems,
              // itemsUnAssigned: this.travelItemsUnAssigned,
              travelWayPoint: this.travelWayPoint,
              steps: this.travelSteps
            },
            customGroup: this.customGroup,
            isActive: this.isActive === true
          }).then(() => {
            this.showLoading = false
          })
          this.travelIsModified = false
          break
        default:
          break
      }
    },
    travelChange (detail) {
      this.travelIsModified = true
      if (detail.added) {
        this.correctTravelTree()
      }
      if (detail.moved) {
        this.correctTravelTree()
      }
      if (detail.removed) {
        this.correctTravelTree()
      }
    },
    setWplatlngFromTree () {
      const travelItemSorted = this.travelItems.filter(x => x.isUse).sort(function (a, b) {
        return a.pos - b.pos
      })
      this.travelWayPoint.forEach(wp => {
        if (!wp.loc) {
          travelItemSorted.filter(x => x.wpId === wp.wpId).forEach(item => {
            if (!wp.loc && item.type === 'poc') {
              wp.loc = this.$store.state.pocModule.data[item.id].homeLocation
            }
          })
        }
      })
    },
    saveTravel () {
      if (this.tampon.length) {
        this.$store.dispatch('showAlert', this.$t('travel-editor.tampon-save-message'))
      } else {
        this.confirmTitle = this.$t('confirm-mess.saveModif')
        this.confirmMessText = ''
        this.confirmMode = 'save'
        this.showConfirm = true
      }
    },
    quitEditor () {
      if (this.travelIsModified) {
        this.confirmTitle = this.$t('confirm-mess.quitNoSave')
        this.confirmMessText = this.$t('confirm-mess.quitNoSaveDesc')
        this.confirmMode = 'quitNoSave'
        this.showConfirm = true
      } else {
        this.dialog = false
      }
    },
    resetTravel () {
      this.travelInfo = this.$store.cloneObj(this.travel.travelInfo)
      this.travelItems = this.$store.cloneObj(this.refTravelItems)
      this.travelWayPoint = this.$store.cloneObj(this.travel.travelInfo.travelWayPoint)
      this.travelSteps = this.$store.cloneObj(this.travel.travelInfo.steps)
      // this.travelItems = JSON.parse(JSON.stringify(this.refTravelItems))
      // this.travelWayPoint = JSON.parse(JSON.stringify(this.travel.travelInfo.travelWayPoint))
      // this.travelGroupItems = JSON.parse(JSON.stringify(this.travel.travelInfo.travelGroupItems))
      this.travelGroup = this.getPossibleGroup(this.travelItems)
      // this.travelItemsUnAssigned = JSON.parse(JSON.stringify(this.travel.travelInfo.itemsUnAssigned))
      this.travelTree = this.generateDetailTree(this.groupSelected)
      this.travelIsModified = false
    },
    removeWaypointItem (item) {
      const travelIdx = this.travelWayPoint.map(x => { return x.wpId }).indexOf(item.wp.wpId)
      if (travelIdx > -1) {
        this.travelWayPoint.splice(travelIdx, 1)
      }
    },
    removeNewItem (item) {
      const travelIdx = this.newItemsArray.map(x => { return x.id }).indexOf(item.id)
      if (travelIdx > -1) {
        this.newItemsArray.splice(travelIdx, 1)
      }
    },
    setItemNotUse (item) {
      const self = this
      function setNotUseFromArrayOfObj (i) {
        let idx
        switch (i.type) {
          case 'poc':
            idx = self.travelItems.map(x => { return x.id }).indexOf(i.id)
            if (idx > -1) {
              self.travelItems[idx].isUse = false
            }
            break
          case 'wp':
            idx = self.travelWayPoint.map(x => { return x.wpId }).indexOf(i.wp.wpId)
            if (idx > -1) {
              self.travelWayPoint[idx].isUse = false
            }
            if (i.items.length) {
              i.items.forEach(wpItem => {
                setNotUseFromArrayOfObj(wpItem)
              })
            }
            break
          default:
            idx = self.travelItems.map(x => { return x.id }).indexOf(i.id)
            if (idx > -1) {
              self.travelItems[idx].isUse = false
            }
            if (i.items.length) {
              i.items.forEach(j => {
                setNotUseFromArrayOfObj(j)
              })
            }
            break
        }
      }
      setNotUseFromArrayOfObj(item)
      const tamponIdx = this.tampon.map(x => { return x.id }).indexOf(item.id)
      if (tamponIdx > -1) {
        this.tampon.splice(tamponIdx, 1)
      }
      this.travelGroup = this.getPossibleGroup(this.travelItems)
      this.travelTree = this.generateDetailTree(this.groupSelected)
      this.travelIsModified = true
    },
    removeItem (item) {
      function removeFromArrayOfObj (array, idToRemove) {
        for (const [i, e] of array.entries()) {
          if (e.id === idToRemove) {
            array.splice(i, 1)
            continue
          }
          if (e.items) {
            removeFromArrayOfObj(e.items, idToRemove)
          }
        }
        return array
      }
      this.travelItems = removeFromArrayOfObj(this.travelItems, item.id)
      this.travelGroup = this.getPossibleGroup(this.travelItems)
      this.travelTree = this.generateDetailTree(this.groupSelected)
      this.travelIsModified = true
    },
    cloneGreyBox (gB) {
      return {
        type: 'greyBox',
        id: this.$store.getUniqueKey(),
        greyBoxId: gB.greyBoxId,
        items: [],
        isUse: true,
        name: gB.name
      }
    },
    cloneRedBox (rB) {
      return {
        type: 'redBox',
        id: this.$store.getUniqueKey(),
        redBoxId: rB.redBoxId,
        isUse: true,
        name: rB.name
      }
    },
    cloneParcelLocker (pL) {
      return {
        type: 'parcelLocker',
        id: this.$store.getUniqueKey(),
        parcelLockerId: pL.parcelLockerId,
        isUse: true,
        name: pL.name
      }
    },
    getItemTitleClass (item) {
      switch (item.type) {
        case 'code':
          return 'text-black black--text'
        case 'start-taxi':
          return 'text-black'
        default:
          return null
      }
    },
    getItemTitle (item) {
      // let stat
      switch (item.type) {
        case 'sector':
          // return (item.sectorId === 1) ? (this.$t('travel-group.sector') + ' 1 (' + this.$t('common.start') + ')') : (this.$t('travel-group.sector') + ' ' + item.sectorId)
          // return (item.sectorId === 1) ? (this.$t('travel-group.sector') + ' 1 (' + this.$t('common.start') + ')') : (this.$t('travel-group.sector') + ' ' + item.sectorId)
          return this.$t('travel-group.sector') + (item.sectorId !== undefined ? item.sectorId === 1 ? ' 1 (' + this.$t('common.start') + ')' : item.sectorId : '')
        case 'street':
          return item.street
        case 'cp':
          return item.cp
        case 'multi':
          // return this.getMultiName(item.multiId)
          return this.$t('pocitem.' + this.$store.state.officeItemMultiModule.data[item.multiId].type)
          // return this.$store.state.officeItemMultiModule.data[item.multiId].name ? this.$store.state.officeItemMultiModule.data[item.multiId].name : this.$store.state.officeItemMultiModule.data[item.multiId].desc
        case 'code':
          return 'Code: ' + item.codeName
          // stat = this.$store.getters.getCumulTotalStatFromPocIdArray(this.getPocOfGroup(item.items).map(x => { return x.id }))
          // return stat.pub + ' / ' + stat.pocActive + ' (' + stat.vacant + ')'
          // return 'Code: ' + item.codeName + ' , PDR: ' + stat.pub + ' / ' + stat.pocActive + ' (' + stat.vacant + ')'
        case 'color':
          return this.$t('travel.color-' + item.colorId)
        case 'poc':
          return this.$store.state.pocModule.data[item.id].pdrListAddress
        case 'start-taxi':
          return this.$t('common.start')
        case 'greyBox':
          return item.name
        case 'redBox':
          return item.name
        case 'parcelLocker':
          return item.name
        case 'wp':
          if (item.wp.multiId) {
            return this.$t('pocitem.' + this.$store.state.officeItemMultiModule.data[item.wp.multiId].type)
          } else if (item.wp.type !== 'taxi-start' && item.wp.type !== 'park-loop') {
            return this.$t('pocitem.' + item.wp.type)
          } else {
            return this.$t('wp.' + item.wp.type + '-title')
          }
        default:
          return 'a faire'
      }
    },
    getStepColor (step) {
      switch (step.type) {
        case 'code':
          return this.$store.getColorName(step.colorId)
        case 'start-taxi':
          return 'primary'
        default:
          return 'primary'
      }
    },
    getStepTitleClass (step) {
      switch (step.type) {
        case 'code':
          return 'text-black black--text'
        case 'start-taxi':
          return 'text-black'
        default:
          return null
      }
    },
    getStepTitle (step) {
      switch (step.type) {
        case 'code':
          return 'Code: ' + step.codeName
        case 'start-taxi':
          return this.$t('common.start')
        default:
          return 'a faire'
      }
    },
    // getLastAddressOfStep (step) {
    //   const pocOfStep = this.travel.base.filter(x => x.stepId === step.stepId).sort(function (a, b) {
    //     return b.posInStep - a.posInStep
    //   })
    //   if (pocOfStep.length) {
    //     return this.$store.state.pocModule.data[pocOfStep[0].pocId].pdrListAddress
    //   } else {
    //     return null
    //   }
    //   // const pocId = this.travel.base.filter(x => x.stepId === step.stepId).sort(function (a, b) {
    //   //   return b.posInStep - a.posInStep
    //   // })[0].pocId
    //   // return this.$store.state.pocModule.data[pocId].pdrListAddress
    // },
    // getFirstAddressOfStep (step) {
    //   const pocOfStep = this.travel.base.filter(x => x.stepId === step.stepId).sort(function (a, b) {
    //     return a.posInStep - b.posInStep
    //   })
    //   if (pocOfStep.length) {
    //     return this.$store.state.pocModule.data[pocOfStep[0].pocId].pdrListAddress
    //   } else {
    //     return null
    //   }
    //   // const pocId = this.travel.base.filter(x => x.stepId === step.stepId).sort(function (a, b) {
    //   //   return a.posInStep - b.posInStep
    //   // })[0].pocId
    //   // return this.$store.state.pocModule.data[pocId].pdrListAddress
    // },
    log (info) {
    }
  },
  watch: {
    // isOfficial (newValue) {
    //   if (newValue && !this.travel.isOfficial) {
    //     this.confirmTitle = this.$t('confirm-mess.setTravelOfficial')
    //     this.confirmMessText = this.$t('confirm-mess.setTravelOfficialDesc')
    //     this.confirmMode = 'setOfficial'
    //     this.showConfirm = true
    //   }
    // },
    dialog (newValue) {
      this.dialog = false
      this.$store.dispatch('resize')
      this.$emit('close')
    },
    isCanBeActive (newValue) {
      if (!newValue) {
        this.isActive = false
      }
    },
    travelId (newValue) {
      const tr = this.$store.getters['officeTravelModule/getTravel'](newValue)
      // this.travelItems = JSON.parse(JSON.stringify(tr.travelInfo.items.filter(x => x.isUse)))
      // this.travelWayPoint = JSON.parse(JSON.stringify(tr.travelInfo.travelWayPoint))
      this.travelInfo = this.$store.cloneObj(this.travel.travelInfo)
      this.travelItems = this.$store.cloneObj(tr.travelInfo.items.filter(x => x.isUse))
      this.travelWayPoint = this.$store.cloneObj(tr.travelInfo.travelWayPoint)
      this.travelSteps = this.$store.cloneObj(tr.travelInfo.steps)
    },
    travelItems (newValue) {
      this.travelGroup = this.getPossibleGroup(newValue)
    },
    groupSelected (newValue) {
      this.travelTree = this.generateDetailTree(newValue)
    }
  },
  mounted () {
    this.loadingTitle = this.$t('common.loading')
    this.loadingsubTitle = this.travel.name
    const self = this
    this.showLoading = true
    this.$store.dispatch('officeTravelModule/loadTravelItems', this.travelId).then(() => {
      self.$nextTick(() => {
        // console.log(self.refTravelItems)
        self.travelInfo = self.$store.cloneObj(self.travel.travelInfo)
        self.travelItems = self.$store.cloneObj(self.refTravelItems)
        self.travelWayPoint = self.$store.cloneObj(self.travel.travelInfo.travelWayPoint)
        self.travelSteps = self.$store.cloneObj(self.travel.travelInfo.steps)
        // self.travelItems = JSON.parse(JSON.stringify(self.refTravelItems))
        // // console.log(self.travelItems.filter(x => x.type === 'taxi'))
        // self.travelWayPoint = JSON.parse(JSON.stringify(self.travel.travelInfo.travelWayPoint))

        self.travelGroup = self.getPossibleGroup(self.travelItems)
        self.travelTree = self.generateDetailTree(self.groupSelected)
        self.showLoading = false
        self.travelLoadComplete = true
      })
    })
  },
  unmounted () {
    this.travelItems = null
    this.travelGroup = null
    this.travelIsModified = false
  },
  destroyed () {
    this.travelItems = null
    this.travelTree = null
    this.travelGroup = null
  },
  created () {}
}
</script>
<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  .dragArea {
    min-height: 100px;
    /* outline: 1px dashed; */
  }
  #editor-card {
    overflow: hidden !important;
  }
  /*
  #to-assign-card {
    position: relative !important;
  } */
  .card-title {
    font-size: 1.6em;
    text-align: center;
    color: #DAA520;
  }
  #main-card {
    position: absolute !important;
    top: 0px;
  }
  #tool-card-mobile {
    position: absolute !important;
    overflow: hidden !important;
    bottom: 0px;
  }
  @media screen and (max-width: 600px) {
    /* #editor-card {
      position: relative !important;
      top: 300px;
    }
    #to-assign-card {
      position: relative !important;
    } */
  }
</style>
