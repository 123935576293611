<template id="template" fixed>
  <v-row justify="center" id="row">
    <v-dialog max-width="444" v-model="dialog" persistent fixed id="dialog" transition="dialog-bottom-transition">
      <v-toolbar
        dark
        color="primary"
        class="mb-1"
        :height="$store.getters.appBarHeight"
        :prominent="!solo"
        >
        <v-app-bar-nav-icon class="me-1">
          <v-btn icon x-large @click="close">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
        </v-app-bar-nav-icon>
        <v-text-field
          v-model="search"
          clearable
          flat
          solo-inverted
          hide-details
          prepend-inner-icon="mdi-magnify"
          :label="$t('dataIterator.searchLabel')"
          @blur="$store.dispatch('resize')"
        ></v-text-field>
        <p class="title ms-2 mt-2">{{ pdrSelectedList.length }}</p>
        <v-app-bar-nav-icon color="green darken-1" class="ms-1">
          <v-btn icon x-large @click="returnSelectedPdr()" color="green darken-1">
            <v-icon x-large>mdi-check-circle</v-icon>
          </v-btn>
        </v-app-bar-nav-icon>
        <!-- <template v-slot:extension two-line v-if="(pdrSelectedList.length || pocIdToRangeList) && !solo">
          <v-row>
            <v-card-subtitle class="white--text"> {{ pdrSelectedList.length }} {{ $t('common.selected') }}</v-card-subtitle>
          </v-row>
        </template> -->
        <template v-slot:extension v-if="pocIdToRangeList || !solo">
          <v-row class="mx-n4 mt-1">
            <!-- <v-col align="start">
              <h3 class="mt-3">{{ pdrSelectedList.length }}</h3>
              <p>{{ $t('common.selected') }}</p>
            </v-col> -->
            <v-col align="center" class="mx-n3">
              <v-btn icon x-large @click="switchMode"><v-icon large>mdi-eye</v-icon></v-btn>
              <p class="mt-n3">{{ $t('selectpdr.mode'+ showMode)}}</p>
            </v-col>
            <v-spacer></v-spacer>

            <v-col v-if="canDoAutoSelect" align="center" class="mx-n3">
              <v-btn icon x-large @click="setPreSelect()"><v-icon large>mdi-auto-fix</v-icon></v-btn>
              <p class="mt-n3">Auto</p>
            </v-col>
            <v-col v-if="canDoBetweenSelect" align="center" class="mx-n3">
              <v-btn icon x-large @click="doBetweenSelect()"><v-icon large>mdi-transit-connection-horizontal</v-icon></v-btn>
              <p class="mt-n3">Bettween</p>
            </v-col>
            <v-col v-if="pdrSelectedList.length > 1" align="center" class="mx-n3">
              <v-btn icon x-large @click="cleanSelectedPdr"><v-icon large>mdi-delete</v-icon></v-btn>
              <p class="mt-n3">clear</p>
            </v-col>

            <!-- <v-list-item align="center" @click="pdrSelectedList = []">
              <v-col>
                <v-icon>mdi-auto-fix</v-icon>
                <p>clear All </p>
              </v-col>
            </v-list-item> -->
          </v-row>
        </template>
      </v-toolbar>
      <v-card :max-height="$store.getters.contentHeight * 0.75" flat class="overflow-y-auto">
        <v-data-iterator
          ref="dataIterator"
          :expanded.sync="pdrSelectedList"
          :single-expand="solo ? true : false"
          :custom-filter="customFilter"
          :search="search"
          :items="pocSearchList"
          :items-per-page="30"
          :footer-props="{ 'items-per-page-options' : [15, 30, 50, 100, -1] }"
          @update:items-per-page="$store.dispatch('resize')"
          >
          <template v-slot:default="{ items, isExpanded, expand }">
            <div overflow-y-auto>
              <v-container v-show="showSearchParam" transition="dialog-top-transition">
                <v-card-title> Option de recherche</v-card-title>
                <v-btn large block>
                  type de recherche
                </v-btn>
                <v-btn large block>
                  type de recherche
                </v-btn>
                <v-btn large block>
                  type de recherche
                </v-btn>
              </v-container>
              <v-list nav v-if="!showSearchParam">
                <!-- <v-row v-if="!solo">
                  <v-card-subtitle> {{ pdrSelectedList.length }} {{ $t('common.selected') }}</v-card-subtitle>
                </v-row> -->
                <v-list-item
                  v-for="item in items"
                  :key="item.id"
                  :class="isExpanded(item) ? 'elevation-2' : ''"
                  @click="(v) => expand(item, !isExpanded(item))"
                >
                  <v-list-item-content>
                    <v-list-item-title :class="$store.getColorText(item.color)" v-text="item.pdrListAddress"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action><v-checkbox :color="$store.getColorCode(item.color)" :input-value="isExpanded(item)"></v-checkbox></v-list-item-action>
                </v-list-item>
              </v-list>
            </div>
          </template>
          <!-- <template v-slot:footer>
            <v-container background-color="primary" class="primary darken-1">
            <v-row align="center" justify="center">
              <span class="white--text">{{ $t('dataIterator.rowsPerPageText') }}</span>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    dark
                    x-large
                    class="primary ml-1"
                    v-on="on"
                  >
                    {{ itemsPerPage.text }}
                    <v-icon large>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(number, index) in itemsPerPageArray"
                    :key="index"
                    @click="updateItemsPerPage(number)"
                  >
                    <v-list-item-title>{{ number.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-row>
            <v-row class="mt-3">
              <v-btn
                fab
                dark
                x-large
                class="ms-1"
                color="primary lighten-1"
                @click="formerPage"
              >
                <v-icon x-large>mdi-chevron-left</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <span
                two-line
                class="white--text"
              >
                <v-list dark two-line class="mt-n2 text-center primary darken-1">
                    <v-list-item-title>
                      Page {{ page }} {{ $t('dataIterator.of') }} {{ numberOfPages }}
                    </v-list-item-title>
                    <v-list-item-title class="mx-auto mt-1">
                      {{ nbrItemFiltered }} {{ $t('common.pdr') }}
                    </v-list-item-title>
                </v-list>
              </span>
              <v-spacer></v-spacer>
              <v-btn
                class="me-1"
                fab
                dark
                x-large
                color="primary  lighten-1"
                @click="nextPage"
              >
                <v-icon x-large>mdi-chevron-right</v-icon>
              </v-btn>
            </v-row>
            </v-container>
          </template> -->

        </v-data-iterator>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ['selItem', 'solo', 'pocIdToRangeList'],
  data () {
    return {
      itemsPerPageArray: [{ text: '5', value: 5 }, { text: '10', value: 10 }, { text: '25', value: 25 }, { text: '50', value: 50 }, { text: this.$t('dataIterator.all'), value: -1 }],
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: this.pocIdToRangeList ? { text: this.$t('dataIterator.all'), value: -1 } : { text: '5', value: 5 },
      searchMode: 0,
      nbrItemFiltered: 0,
      pdrSelectedList: this.selItem ? this.selItem.length ? this.selItem : [this.selItem] : [],
      dialog: true,
      showSearchParam: false,
      notifications: false,
      search: '',
      showMode: this.selItem ? 'range' : 'all'
    }
  },
  mounted () {
    this.nbrItemFiltered = this.pocSearchList.length
  },
  watch: {
    dialog (newValue) {
      if (!newValue) {
        this.$store.dispatch('resize')
        this.$emit('close')
      }
    },
    showMode (newValue) {
      if (newValue === 'all') {
        this.itemsPerPage = { text: '5', value: 5 }
      } else {
        this.itemsPerPage = { text: this.$t('dataIterator.all'), value: -1 }
      }
    }
  },
  computed: {
    numberOfPages () {
      if (this.itemsPerPage.value > 0) {
        return Math.ceil(this.nbrItemFiltered / this.itemsPerPage.value)
      } else {
        return 1
      }
    },
    filteredKeys () {
      return this.keys.filter(key => key !== 'Name')
    },
    pictureSrc () {
      return this.$store.getters.getUserSelectOfficePictureSrc ? this.$store.getters.getUserSelectOfficePictureSrc : 'https://picsum.photos/1920/1080?random'
    },
    rangeList () {
      if (this.pdrSelectedList.length) {
        return this.$store.getters['pocModule/getPocDataIteratorItemsRangeList'](this.pdrSelectedList)
      } else {
        return []
      }
    },
    pocSearchList () {
      // if (this.pocIdToRangeList) {
      if (this.showMode === 'range') {
        return this.rangeList.list
      } else if (this.showMode === 'select') {
        return this.pdrSelectedList
      } else {
        return this.$store.getters['pocModule/getPocOfSequenceSortedSelected']
      }
    },
    canDoAutoSelect () {
      if (!this.solo && this.pdrSelectedList.length === 1) {
        return true
      } else {
        return false
      }
    },
    canDoBetweenSelect () {
      if (!this.solo && this.pdrSelectedList.length === 2) {
        const idArray = this.pocSearchList.map(function (x) { return x.id })
        // const i1 = idArray.indexOf(this.pdrSelectedList[0].id)
        // const i2 = idArray.indexOf(this.pdrSelectedList[0].id)
        if (Math.abs(idArray.indexOf(this.pdrSelectedList[0].id) - idArray.indexOf(this.pdrSelectedList[1].id)) > 1) {
          return true
        } else { return false }
      } else { return false }
    }
  },
  methods: {
    switchMode () {
      switch (this.showMode) {
        case 'select':
          if (this.pdrSelectedList.length) {
            this.showMode = 'range'
          } else {
            this.showMode = 'all'
          }
          break
        case 'range':
          this.showMode = 'all'
          break
        case 'all':
          if (this.pdrSelectedList.length) {
            this.showMode = 'select'
          } else {
            this.showMode = 'all'
          }
          break
        default:
          this.showMode = 'all'
          break
      }
    },
    doBetweenSelect () {
      const idArray = this.pocSearchList.map(function (x) { return x.id })
      const i0 = idArray.indexOf(this.pdrSelectedList[0].id)
      const i1 = idArray.indexOf(this.pdrSelectedList[1].id)
      if (i0 > i1) {
        for (let i = i1 + 1; i < i0; i++) {
          this.pdrSelectedList.push(this.pocSearchList[i])
        }
      } else {
        for (let i = i0 + 1; i < i1; i++) {
          this.pdrSelectedList.push(this.pocSearchList[i])
        }
      }
    },
    setPreSelect () {
      this.pdrSelectedList = this.rangeList.preSelectList
    },
    cleanSelectedPdr () {
      if (this.pocIdToRangeList) {
        const index = this.pocSearchList.map(function (x) { return x.id }).indexOf(this.pocIdToRangeList)
        this.pdrSelectedList = [this.pocSearchList[index]]
      } else {
        this.pdrSelectedList = []
      }
    },
    returnSelectedPdr () {
      this.$emit('select', this.pdrSelectedList)
      this.close()
    },
    createReportFor (owner) {
      this.owner = owner
      this.showCreateReport = true
    },
    nextPage () {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage () {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage (number) {
      this.page = 1
      this.itemsPerPage = number
    },
    customFilter (items, search, filter) {
      // this custom filter will do a multi-match separated by a space.
      // e.g
      this.page = 1

      if (!search) {
        this.nbrItemFiltered = items.length
        return items
      } // if the search is empty just return all the items

      function newFilter (val, search) {
        return val !== null &&
          ['undefined', 'boolean'].indexOf(typeof val) === -1 &&
          val.toString().toLowerCase().replace(/[^0-9a-zA-Z-]+/g, '').indexOf(search) !== -1
      }

      const needleAry = search.toString().toLowerCase().split(' ').filter(x => x)
      // whenever we encounter a space in our search we will filter for both the first and second word (or third word)

      const result = items.filter(row => needleAry.every(needle => Object.keys(row).some(key => newFilter(row.pdrListAddress, needle))))
      if (result.length) {
        this.nbrItemFiltered = result.length
      } else {
        this.nbrItemFiltered = 1
      }

      return result
      // return items.filter(row => needleAry.every(needle => Object.keys(row).some(key => newFilter(row[key], needle))))
      // foreach needle in our array cycle through the data (row[key]) in the current row looking for a match.
      //  .some will return true and exit the loop if it finds one it will return false if it doesnt
      //  .every will exit the loop if we dont find a match but will return true if all needles match
      //  .filter the rows on each match
    },
    close () {
      this.$store.getters.EventBus.$emit('sizeChange')
      this.dialog = false
      this.$emit('close')
    },
    getTypeDesc (type) {
      let desc = ''
      switch (type) {
        case 'Hou/Dom':
          desc = this.$t('common.dom')
          break
        case 'Apt/App':
          desc = this.$t('common.app')
          break
        case 'Bus/com':
          desc = this.$t('common.bus')
          break
        default:
          desc = this.$t('common.frm')
      }
      return desc
    }
  }
}
</script>
<style>
  #attach {
    width: 50%
  }
</style>
