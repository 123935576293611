<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="344" fixed transition="dialog-bottom-transition">
      <v-card>
        <v-app-bar dark color="primary">
          <v-btn icon x-large dark @click="dialog = false">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon color="green darken-1" class="ms-1">
            <v-btn icon x-large @click="returnNewDate()" color="green darken-1">
              <v-icon x-large>mdi-check-circle</v-icon>
            </v-btn>
          </v-app-bar-nav-icon>
        </v-app-bar>

        <v-date-picker :locale="$store.getters.getLocale" v-model="pickerDate" full-width></v-date-picker>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ['title', 'presetDate'],
  data () {
    return {
      dialog: true,
      pickerDate: null
    }
  },
  watch: {
    dialog (newValue) {
      this.$store.dispatch('resize')
      this.dialog = false
      this.$emit('close')
    }
  },
  methods: {
    returnNewDate () {
      const d = new Date()
      d.setFullYear(this.pickerDate.split('-')[0])
      d.setMonth(this.pickerDate.split('-')[1] - 1)
      d.setDate(this.pickerDate.split('-')[2])
      if (this.presetDate) {
        d.setMinutes(this.presetDate.getMinutes())
        d.setHours(this.presetDate.getHours())
      }
      this.$emit('return-value', d)
    }
  },
  mounted () {
    if (this.presetDate) {
      this.pickerDate = this.presetDate.toISOString().substr(0, 10)
    } else {
      this.pickerDate = new Date().toISOString().substr(0, 10)
    }
  }
}
</script>
