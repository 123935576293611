<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" :height="dialogHeight" fixed transition="dialog-bottom-transition" class="overflow-hidden" max-width="444">
      <!-- <v-app-bar dark :color="placeColor" height="60">
        <v-toolbar-title>{{ placeId ? $t('common.edit') : $t('common.create') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon x-large dark @click="onClose">
          <v-icon x-large>mdi-close</v-icon>
        </v-btn>
      </v-app-bar> -->
      <v-card :max-height="dialogHeight" class="overflow-hidden">
        <v-app-bar dark :color="placeColor" height="60">
          <v-toolbar-title>{{ placeId ? $t('common.edit') : $t('common.create') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon x-large dark @click="onClose">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>
        <!-- <v-card-text> -->
        <v-card flat :max-height="dialogHeight - 60" class="overflow-x-hidden overflow-y-auto">
          <v-card-text>
            <form @submit.prevent="onSave">
              <v-text-field
                :label="$t('common.abbreviation')"
                filled
                block
                outlined
                v-model="place.sName"
                class="mt-4"
                :rules="[ ruleMax5 ]"
                @blur="modifySName"
              ></v-text-field>
              <v-text-field
                :label="$t('common.name')"
                filled
                block
                outlined
                v-model="place.lName"
                @blur="modifyLName"
              ></v-text-field>

              <v-btn  class="mb-3" block large @click="showSelectPocOfPlace = true">{{ place.pocList ? place.pocList.length : '' }} {{ $t('common.pdr') }}</v-btn>
              <v-img
                v-if="place.homeLocation"
                :src="$store.getters['placeModule/getPlacePictureHomeSrc']({ placeId: placeId, width: '450', height: '300' })"
                contain
                alt="pas d'image"
              >
              </v-img>
              <h3>{{ $t('pmtpdreditpopup.soucePicture') }} :</h3>
              <v-switch class="ms-4 mt-n1" :disabled="!place.imageList || !place.imageList.length" @change="modifyHomeImageListSelect" v-model="place.homeImageListSelect" :color="placeColor" :label="place.homeImageListSelect ? $t('pmtpdreditpopup.nogooglePicture') : $t('pmtpdreditpopup.googlePicture')"></v-switch>

              <v-divider></v-divider>
              <v-row no-gutters class="mx-2 mt-5">
                <v-btn icon x-large @click="showManagePicture = true">
                  <v-badge
                    v-if="place.imageList && place.imageList.length"
                    :content="place.imageList.length"
                    color="green"
                    overlap
                    bottom
                  >
                  <v-icon x-large color="green">mdi-folder-multiple-image</v-icon>
                  </v-badge>
                  <v-icon v-else x-large color="red">mdi-folder-multiple-image</v-icon>
                </v-btn>
                <p class="mt-3">{{ $t('createreportdog.picture') }}</p>
                <v-spacer></v-spacer>
                <p class="mt-3">{{ $t('createreportdog.gps') }}</p>
                <v-icon @click="showGetPosition = true" x-large :color="place.homeLocation ? 'green': 'red'" > mdi-map-marker </v-icon>
              </v-row>
              <!-- <v-layout row>
                <v-flex xs12>
                  <v-btn type="submit" color="primary">{{ $t('common.save') }}</v-btn>
                </v-flex>
              </v-layout> -->
            </form>
          </v-card-text>
        </v-card>
      </v-card>
      <confirm-popup
        v-if="showConfirmQuit"
        @cancel="justQuit()"
        @confirm="saveAndQuit()"
        :title="$t('common.confirmKeepChange')"
        :param="{ mode: 'yesno' }"
      ></confirm-popup>
      <confirm-popup
        v-if="showConfirmNewPocList"
        :item="showConfirmNewPocList.item"
        @cancel="showConfirmNewPocList = null"
        @confirm="changePocList($event)"
        :title="$t('common.confirmNewPocList')"
        :param="{ mode: 'modifyPocIdArray' }"
      ></confirm-popup>
      <confirm-popup
        v-if="showConfirmModify"
        @cancel="cancelmodifyConfirm()"
        @confirm="modifyConfirmed()"
        :title="confirmTitle"
        :from="confirmModifyFrom"
        :to="confirmModifyTo"
        :param="{ mode: 'standard', text: null }"
      ></confirm-popup>
      <pmt-loading-local :loadingTitle="showLoadingTitle" :loadingShow="showLoading"></pmt-loading-local>
      <picture-manager
        v-if="showManagePicture"
        :subTitle="place.lName"
        :param="{ type: 'place-' + mode, placeId: this.placeId ? this.placeId : null, imageList: place.imageList }"
        @close="closeManagePicture()"
        @return-close="setPlaceImage($event)"
      ></picture-manager>
      <pmt-position-map-set
        v-if="showGetPosition"
        :param="{ title: place.lName, markerStartPosition: place.homeLocation, centerPostition: place.homeLocation, icon: $store.getAlertPngIcon('house'), getDistance: false, isCreateMode: false, searchPocId: place.pocList[0] }"
        @return-close="showGetPosition = false"
        @return-check="setNewPosition($event)"
      />
      <pmt-select-pdr
        v-if="showSelectPocOfPlace"
        @close="showSelectPocOfPlace = false"
        @select="setPocIdList($event)"
        :selItem="$store.getters['pocModule/getPocDataIteratorItemsOfPocIdArray'](place.pocList)"
        :pocIdToRangeList="place.pocList ? place.pocList[0] : null"
      ></pmt-select-pdr>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ['title', 'placeId', 'colorId', 'createPlaceInfo'],
  data () {
    return {
      dialog: true,
      showManagePicture: false,
      showGetPosition: false,
      showSelectPocOfPlace: false,
      showLoading: false,
      showLoadingTitle: '',
      showConfirm: false,
      showConfirmModify: false,
      showConfirmQuit: false,
      confirmTitle: '',
      confirmModifyFrom: '',
      confirmModifyTo: '',
      showConfirmNewPocList: null,
      mode: null,
      place: {
        lName: '',
        sName: '',
        homeLocation: null,
        ggPlaceId: '',
        pocList: [],
        imageList: [],
        route: [],
        homeImageListSelect: false
      }
      // place: {
      //   lName: this.refPlace ? this.refPlace.lName : this.createPlaceInfo && this.createPlaceInfo.lName ? this.createPlaceInfo.lName : '',
      //   sName: this.refPlace ? this.refPlace.sName : this.createPlaceInfo && this.createPlaceInfo.sName ? this.createPlaceInfo.sName : '',
      //   homeLocation: this.refPlace ? this.refPlace.homeLocation : this.createPlaceInfo && this.createPlaceInfo.homeLocation ? this.createPlaceInfo.homeLocation : '',
      //   ggPlaceId: this.refPlace ? this.refPlace.ggPlaceId : this.createPlaceInfo && this.createPlaceInfo.ggPlaceId ? this.createPlaceInfo.ggPlaceId : '',
      //   pocList: this.refPlace ? this.refPlace.pocList : this.createPlaceInfo && this.createPlaceInfo.pocList ? this.createPlaceInfo.pocList : [],
      //   imageList: this.refPlace ? this.refPlace.imageList : this.createPlaceInfo && this.createPlaceInfo.imageList ? this.createPlaceInfo.imageList : [],
      //   route: this.refPlace ? this.refPlace.route : this.createPlaceInfo && this.createPlaceInfo.route ? this.createPlaceInfo.route : []
      // }
    }
  },
  methods: {
    changePocList (item) {
      this.showLoadingTitle = this.$t('common.saveinprogress')
      this.showLoading = true
      const prom = []
      if (item.addList.length) {
        prom.push(this.$store.dispatch('placeModule/addPocToPlace', { placeId: this.placeId, pocIdArray: item.addList }))
      }
      if (item.remList.length) {
        prom.push(this.$store.dispatch('placeModule/removePocFromPlace', { placeId: this.placeId, pocIdArray: item.remList }))
      }
      this.showConfirmNewPocList = null
      Promise.all(prom).then(() => {
        this.showLoading = false
        this.place.pocList = item.newList
      })
    },
    setPocIdList (items) {
      if (this.mode === 'edit') {
        const addList = []
        const remList = []
        const newList = items.map(function (x) { return x.id })
        // // console.log(newList)
        newList.forEach(pocId => {
          if (this.place.pocList.indexOf(pocId) < 0) {
            addList.push(pocId)
          }
        })
        this.place.pocList.forEach(pocId => {
          if (newList.indexOf(pocId) < 0) {
            remList.push(pocId)
          }
        })
        if (addList.length || remList.length) {
          this.showConfirmNewPocList = { item: { addList: addList, remList: remList, newList: newList } }
        }
      } else {
        this.place.pocList = items.map(function (x) { return x.id })
      }
    },
    setNewPosition (pos) {
      this.showGetPosition = false
      if (this.mode === 'edit') {
        this.showLoadingTitle = this.$t('common.saveinprogress')
        this.showLoading = true
        this.$store.dispatch('placeModule/changePlaceHomeLocation', { placeId: this.placeId, homeLocation: pos.position })
          .then(() => {
            this.place.homeLocation = pos.position
            this.showLoading = false
          })
      } else {
        this.place.homeLocation = pos.position
      }
    },
    closeManagePicture () {
      this.showManagePicture = false
      if (this.refPlace.imageList) {
        this.place.imageList = this.refPlace.imageList
      }
    },
    cancelmodifyConfirm () {
      this.place = {
        lName: this.refPlace.lName,
        sName: this.refPlace.sName,
        homeLocation: this.refPlace.homeLocation,
        ggPlaceId: this.refPlace.ggPlaceId,
        pocList: this.refPlace.pocList,
        imageList: this.refPlace.imageList,
        route: this.refPlace.route,
        homeImageListSelect: this.refPlace.homeImageListSelect ? this.refPlace.homeImageListSelect : false
      }
      this.showConfirmModify = false
    },
    modifyConfirmed () {
      this.showConfirmModify = false
      const change = { id: this.refPlace.id }
      if (this.place.sName !== this.refPlace.sName) {
        change.sName = this.place.sName
      } else if (this.place.lName !== this.refPlace.lName) {
        change.lName = this.place.lName
      } else if (this.place.homeImageListSelect !== this.refPlace.homeImageListSelect) {
        change.homeImageListSelect = this.place.homeImageListSelect
      }
      this.showLoading = true
      this.showLoadingTitle = this.$t('common.saveinprogress')
      this.$store.dispatch('placeModule/set', change).then(() => {
        this.showLoading = false
      })
    },
    modifyHomeImageListSelect () {
      // // console.log('trace 1')
      // // console.log(this.mode)
      // // console.log(this.refPlace.homeImageListSelect)
      // // console.log(this.place.homeImageListSelect)
      if (this.mode === 'edit' && (this.refPlace.homeImageListSelect !== this.place.homeImageListSelect)) {
        // // console.log('trace 2')
        this.confirmTitle = this.$t('common.changepicsourceconfirm')
        this.confirmModifyFrom = null
        this.confirmModifyTo = null
        this.showConfirmModify = true
      }
    },
    modifySName () {
      this.$store.dispatch('resize')
      if (this.mode === 'edit' && (this.refPlace.sName !== this.place.sName)) {
        this.confirmTitle = this.$t('place-edit.confirm-modify-sname')
        this.confirmModifyFrom = this.refPlace.sName
        this.confirmModifyTo = this.place.sName
        this.showConfirmModify = true
      }
    },
    modifyLName () {
      this.$store.dispatch('resize')
      if (this.mode === 'edit' && (this.refPlace.lName !== this.place.lName)) {
        this.confirmTitle = this.$t('place-edit.confirm-modify-lname')
        this.confirmModifyFrom = this.refPlace.lName
        this.confirmModifyTo = this.place.lName
        this.showConfirmModify = true
      }
    },
    onSave () {
      // // console.log('trace onSave')
    },
    onClose () {
      // // console.log('isModify = ' + this.isModify + ' isToSave = ' + this.isToSave)
      if (!this.isModify && !this.isToSave) {
        this.dialog = false
        this.$emit('close')
      }
    },
    ruleMax5 (text) {
      // if (this.$store.getters['userModule/getUserByEmpId'](('00000000000' + empId).substring(('00000000000' + empId).length - 9))) {
      //   return 'already exist user'
      // } else if (empId.length < 6) {
      if (text.length > 5) {
        return 'max 5'
      } else {
        return true
      }
    }
  },
  computed: {
    isToSave () {
      if (this.mode === 'create') {
        if (this.createPlaceInfo) {
          if (this.place.lName !== this.createPlaceInfo.lName) {
            return true
          } else if (this.place.sName !== this.createPlaceInfo.sName) {
            return true
          } else if (this.place.imageList !== this.createPlaceInfo.imageList) {
            return true
          } else if (this.place.pocList !== this.createPlaceInfo.pocList) {
            return true
          } else if (this.place.homeLocation.lat !== this.createPlaceInfo.homeLocation.lat || this.place.homeLocation.lng !== this.createPlaceInfo.homeLocation.lng) {
            return true
          } else {
            return false
          }
        } else {
          if (this.place.lName !== '') {
            return true
          } else if (this.place.sName !== '') {
            return true
          } else if (this.place.imageList !== []) {
            return true
          } else if (this.place.pocList !== []) {
            return true
          } else if (this.place.homeLocation) {
            return true
          } else {
            return false
          }
        }
      } else {
        return false
      }
    },
    isModify () {
      if (this.mode === 'edit') {
        if (this.place.sName !== this.refPlace.sName) {
          return true
        } else if (this.place.lName !== this.refPlace.lName) {
          return true
        } else if (this.place.homeImageListSelect !== this.refPlace.homeImageListSelect) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    refPlace () {
      if (this.placeId) {
        return this.$store.state.placeModule.data[this.placeId]
      } else {
        return null
      }
    },
    lName () {
      return this.place.lName
    },
    sName () {
      return this.place.sName
    },
    placeColor () {
      if (this.colorId) {
        return this.$store.getColorCode(this.colorId)
      } else {
        return 'primary'
      }
    },
    dialogHeight () {
      // // console.log('trace dialogHeigth = ' + Math.abs(this.$store.getters.getContentHeigth * 0.5))
      return Math.abs(this.$store.getters.contentHeight * 0.9)
    }
  },
  watch: {
    dialog (newValue) {
      this.$store.dispatch('resize')
      if (!this.isModify) {
        this.dialog = false
        this.$emit('close')
      }
    },
    place (newValue) {
      if (this.mode) {
        // // console.log('trace watch place')
      }
    }
  },
  mounted () {
    if (this.placeId) {
      // const p = this.$store.state.placeModule.data[this.placeId]
      this.place = {
        lName: this.refPlace.lName,
        sName: this.refPlace.sName,
        homeLocation: this.refPlace.homeLocation,
        ggPlaceId: this.refPlace.ggPlaceId,
        pocList: this.refPlace.pocList,
        imageList: this.refPlace.imageList,
        route: this.refPlace.route,
        homeImageListSelect: this.refPlace.homeImageListSelect
      }
      this.mode = 'edit'
    } else if (this.createPlaceInfo) {
      if (this.createPlaceInfo.lName) { this.place.lName = this.createPlaceInfo.lName }
      if (this.createPlaceInfo.sName) { this.place.sName = this.createPlaceInfo.sName }
      if (this.createPlaceInfo.homeLocation) { this.place.homeLocation = this.createPlaceInfo.homeLocation }
      if (this.createPlaceInfo.ggPlaceId) { this.place.ggPlaceId = this.createPlaceInfo.ggPlaceId }
      if (this.createPlaceInfo.pocList) { this.place.pocList = this.createPlaceInfo.pocList }
      if (this.createPlaceInfo.imageList) { this.place.imageList = this.createPlaceInfo.imageList }
      if (this.createPlaceInfo.route) { this.place.route = this.createPlaceInfo.route }
      if (this.createPlaceInfo.homeImageListSelect) { this.place.homeImageListSelect = this.createPlaceInfo.homeImageListSelect }

      this.mode = 'create'
    } else {
      this.mode = 'create'
    }
  },
  destroyed () {
    this.place = null
  }
}
</script>
