<template>
  <div class="text-center">
    <v-bottom-sheet v-model="isOpen">
      <v-card :max-height="Math.round($store.getters.screenSize.height / 3)" class="overflow-y-auto overflow-x-hidden">
        <v-list>
          <v-subheader><p class="title">{{ $t('common.dangerProxim') }} :</p></v-subheader>
          <v-list-item
            v-for="(danger, i) in dangerList"
            :key="i"
            @click="dangerToDisplay = danger"
          >
            <v-list-item-avatar>
              <v-avatar
                size="32px"
                tile
              >
                <img
                  :src="$store.getAlertPngIcon(danger.type)"
                  :alt="$store.getAlertPngIcon()"
                >
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-title>
              <v-row no-gutters>
              {{ getReportName(danger) }}
              <v-spacer></v-spacer>
              {{ getDisplayDist(danger.distOfMe) }}
              </v-row>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-bottom-sheet>
    <dangerInfo-popup
      v-if="dangerToDisplay"
      :danger="dangerToDisplay"
      @close="dangerToDisplay = null"
    ></dangerInfo-popup>
  </div>
</template>

<script>
export default {
  props: [],
  data () {
    return {
      isOpen: false,
      dangerToDisplay: null
    }
  },
  methods: {
    getDisplayDist (dist) {
      if (dist > 1000) {
        return (Math.round(dist / 100) / 10) + ' Km'
      } else {
        return (Math.round(dist * 10) / 10) + ' M '
      }
    },
    getReportName (report) {
      const ownerIdx = report.pocList.map(x => { return x.isOwner }).indexOf(true)
      if (ownerIdx < 0) {
        return this.$t('pmtcreatereportpopup.title' + report.type)
      } else {
        if (report.pocList[ownerIdx].name) {
          return report.pocList[ownerIdx].name
        } else if (report.pocList[ownerIdx].address) {
          return report.pocList[ownerIdx].address
        } else {
          return this.$t('pmtcreatereportpopup.title' + report.type)
        }
      }
    }
  },
  computed: {
    dangerList () {
      return this.$store.getters['reportModule/getProximityAlertSortedByDistOfMe']
    }
  },
  created () {
    const self = this
    this.$store.getters.EventBus.$on('openBottomSheetGuardianDanger', () => {
      self.isOpen = true
    })
  }
}
</script>
