<template>
  <div class="home overflow-hidden" :style="$store.getters.contentStyle">
    <v-card>
      <v-sheet class="overflow-y-auto overflow-x-hidden" :height="$store.getters.contentHeight" :max-height="$store.getters.contentHeight">
        <v-data-iterator
          fixed
          :hide-default-footer="showSearchParam"
          single-expand
          :custom-filter="customFilter"
          :sort-by="sortBy"
          :sort-desc="isAscending"
          :custom-sort="customSort"
          :search="routeSelected"
          :items="items"
          :items-per-page="30"
          :footer-props="{ 'items-per-page-options' : [15, 30, 50, 100, -1] }"
          @update:items-per-page="$store.dispatch('resize')"
        >
          <template v-slot:header>
            <v-app-bar
              dark
              color="primary"
              class="mb-1"
              :height="$store.getters.appBarHeight"
              extended
              :src="imgBar"
              >
              <template v-slot:img="{ props }">
                <v-img
                  v-bind="props"
                  contain
                  :gradient="$store.getters.getDayGradient"
                ></v-img>
              </template>
              <!-- <v-app-bar-nav-icon>
                <v-btn icon x-large @click="close">
                  <v-icon x-large>mdi-close</v-icon>
                </v-btn>
              </v-app-bar-nav-icon> -->
              <v-row no-gutters>
                <v-btn icon x-large @click="$emit('menu-item-selected', 'pmt-pm-tool')"><v-icon x-large>mdi-arrow-left-circle</v-icon></v-btn>
                <h3 class="mt-3">Menu</h3>
              </v-row>
              <v-spacer></v-spacer>
              <v-app-bar-nav-icon>
              <v-btn icon x-large @click="isAscending = !isAscending"><v-icon x-large>{{ isAscending ? 'mdi-sort-alphabetical-ascending' : 'mdi-sort-alphabetical-descending' }}</v-icon></v-btn>
              </v-app-bar-nav-icon>
              <!-- <v-app-bar-nav-icon>
                <v-btn icon @click="showSearchParam=!showSearchParam">
                  <v-icon x-large :color="showSearchParam ? 'green' : 'white'" >mdi-cogs</v-icon>
                </v-btn>
              </v-app-bar-nav-icon> -->
              <v-app-bar-nav-icon>
                <v-btn icon @click="editOfficeItemId = true">
                  <v-icon x-large :color="showSearchParam ? 'green' : 'white'" >mdi-plus</v-icon>
                </v-btn>
              </v-app-bar-nav-icon>
              <template v-slot:extension>
                <v-spacer></v-spacer>
                <v-col cols="6">
                  <v-select
                    v-model="itemTypeSelected"
                    :items="itemList"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="routeSelected"
                    :items="routeList"
                  ></v-select>
                </v-col>
                <!-- <v-text-field
                  v-show="!showSearchParam"
                  v-model="search"
                  clearable
                  flat
                  solo-inverted
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  @blur="$store.dispatch('resize')"
                  :label="$t('dataIterator.searchLabel')"
                ></v-text-field> -->
                <p v-show="showSearchParam" class="title">{{ $t('common.searchOption') }}</p>
                <v-spacer></v-spacer>
              </template>
            </v-app-bar>
          </template>
          <template v-slot:default="{ items }">
            <v-card :max-height="iteratorHeight" :height="iteratorHeight" flat class="overflow-y-auto">
              <v-list>
                <v-list-item
                  v-for="item in items"
                  :key="item.id"
                  @click="editOfficeItemId = item.id"
                >
                  <v-list-item-avatar>
                    <v-avatar
                      size="30px"
                      tile
                    >
                      <img
                        :src="$store.getMarkerIconSpec($store.getters.getGoogle, item).url"
                        alt="item"
                        contain
                      >
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content two-line v-if="item.type === 'greyBoxMarker'" :class="item.group" align="center">
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ getTypeName(item.type) }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content two-line v-if="item.type === 'toiletMarker'" align="center">
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ getTypeName(item.type) }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content two-line v-if="item.type === 'redBoxMarker'" align="center">
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ getTypeName(item.type) }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content two-line v-if="item.type === 'parcelLockerMarker'" align="center">
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ getTypeName(item.type) }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <!-- <v-list-item-content>
                    <div v-if="item.type === 'greyBoxMarker'">
                        {{ item.name }}
                      <v-container v-if="isExpanded(item)" transition="dialog-top-transition">
                        <v-row class="mt-3" no-gutters>
                          <v-btn @click="editItem = item.id" icon large><v-icon x-large>mdi-cog-outline</v-icon></v-btn>
                          <v-spacer/>
                          <v-btn @click="selectPdr(item)" icon large><v-icon x-large>mdi-google-maps</v-icon></v-btn>
                        </v-row>
                      </v-container>
                    </div>
                    <div v-if="searchMode === 'greybox'" :color="item.group">
                      <p :class="item.group + ' subtitle-1 py-2 mb-n1'" v-text="item.name"></p>
                      <v-row no-gutters class="mb-n4">
                        <v-spacer></v-spacer>
                        <p v-if="sortBy === 'dist'" class="subtitle-2">{{ $store.getters.distanceOfMeText(item.position) }}</p>
                      </v-row>
                      <v-container v-if="isExpanded(item)" transition="dialog-top-transition">
                        <p class="subtitle-1">{{ item.name }}</p>
                      </v-container>
                    </div>
                    <div v-if="searchMode === 'toilet'">
                      <p v-text="item.description"></p>
                      <v-row no-gutters class="mb-n4">
                        <v-spacer></v-spacer>
                        <p v-if="sortBy === 'dist'" class="subtitle-2">{{ $store.getters.distanceOfMeText(item.position) }}</p>
                      </v-row>
                      <v-container v-if="isExpanded(item)" transition="dialog-top-transition">
                        <p class="subtitle-1">{{ item.description }}</p>
                      </v-container>
                    </div>
                  </v-list-item-content> -->
                </v-list-item>
              </v-list>
            </v-card>
          </template>
        </v-data-iterator>
      </v-sheet>
    </v-card>
    <loading-popup v-if="showLoading" :title="loadingTitle" :subTitle="loadingsubTitle"></loading-popup>
    <pmt-position-map-set
      v-if="showGetPosition"
      :param="{ title: $store.state.pocModule.data[selectPocId].pdrListAddress, icon: $store.getAlertPngIcon('house'), getDistance: false, isCreateMode: true}"
      @return-close="showGetPosition = false"
      @return-check="setPosition($event)"
    />
    <pmt-officeitem-editor
      v-if="editOfficeItemId"
      :officeItemId="editOfficeItemId"
      @close="editOfficeItemId = null"
    ></pmt-officeitem-editor>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isDataLoaded: true,
      showLoading: false,
      showGetPosition: false,
      loadingTitle: this.$t('common.loading'),
      loadingsubTitle: '',
      selectPocId: null,
      showSearchParam: false,
      isAscending: true,
      type: null,
      searchMode: 'pdr',
      search: '',
      searchItems: this.$store.getters['pocModule/getPocOfSequenceSortedByDistanceSelected'],
      sortBy: this.$store.getters.getCurrentPosition ? 'dist' : 'name',
      editOfficeItemId: null,
      itemTypeSelected: 'all',
      routeSelected: 'all'
    }
  },
  methods: {
    customFilter (items, search) {
      if (search !== 'all') {
        return items.filter(val => {
          if (val.route && val.route.length) {
            return val.route.indexOf(search) > -1
          } else {
            return true
          }
        })
      } else {
        return items
      }
    },
    customSort (items, index, isDescending) {
      // const mode = this.searchMode
      // const distMe = this.$store.getters.distanceOfMe
      // const sortBy = index[0]
      const isDesc = isDescending[0]

      switch (this.itemTypeSelected) {
        case 'all':
          return items.sort(function (a, b) {
            if (a.type === b.type) {
              if (a.group === b.group) {
                if (a.name === b.name) {
                  if (isDesc) { return b.created_at - a.created_at } else { return a.created_at - b.created_at }
                } else {
                  if (isDesc) { return b.name < a.name } else { return a.name < b.name }
                }
              } else {
                if (isDesc) { return b.group < a.group } else { return a.group < b.group }
              }
            } else {
              if (isDesc) { return b.type < a.type } else { return a.type < b.type }
              // return a.type < b.type
            }
          })
        case 'redBoxMarker':
          return items.sort(function (a, b) {
            if (isDesc) { return b.created_at - a.created_at } else { return a.created_at - b.created_at }
          })
        case 'parcelLockerMarker':
          return items.sort(function (a, b) {
            if (isDesc) { return b.created_at - a.created_at } else { return a.created_at - b.created_at }
          })
        case 'greyBoxMarker':
          return items.sort(function (a, b) {
            if (a.group === b.group) {
              if (isDesc) { return b.name < a.name } else { return a.name < b.name }
              // return a.name < b.name
            } else {
              if (isDesc) { return b.group < a.group } else { return a.group < b.group }
              // return a.group < b.group
            }
          })
        case 'toiletMarker':
          return items.sort(function (a, b) {
            if (isDesc) { return b.created_at - a.created_at } else { return a.created_at - b.created_at }
          })
        case 'parkingMarker':
          return items.sort(function (a, b) {
            if (isDesc) { return b.created_at - a.created_at } else { return a.created_at - b.created_at }
            // return a.created_at - b.created_at
          })
        default:
          return items.sort(function (a, b) {
            if (a.type === b.type) {
              if (isDesc) { return b.created_at - a.created_at } else { return a.created_at - b.created_at }
            } else {
              if (isDesc) { return b.type < a.type } else { return a.type < b.type }
            }
          })
      }
    },
    customGroup (items, groupBy, groupDesc) {
      // // console.log('trace test')
      // // console.log(groupBy)
      // // console.log(groupDesc)
    },
    deleteRoute (data) {
      this.editOfficeItemId = null
      if (data.pocArray.length) {
        this.$store.dispatch('pocModule/deleteBatch', data.pocArray.map(x => { return x.id }))
      }
      if (data.pocArray.length) {
        this.$store.dispatch('placeModule/deleteBatch', data.placeArray.map(x => { return x.id }))
      }
      if (data.routeId) {
        this.$store.dispatch('routeModule/delete', data.routeId)
      }
    },
    searchPosPlaceId (pos) {
      const google = this.$store.getters.getGoogle
      const geocoder = new google.maps.Geocoder()
      const store = this.$store
      const pocId = this.selectPocId
      geocoder.geocode({ location: pos.position }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          const pocUpdate = {
            id: pocId,
            place_id: results[0].place_id,
            formatted_address: results[0].formatted_address,
            homeLocation: pos.position,
            googleFound: true,
            isActive: true
          }
          store.dispatch('pocModule/set', pocUpdate)
        } else {
          // there were no coordinates found for the address entered (or the address was not found)
          alert('Could not find your address ')
        }
      })
    },
    selected (item) {
      this.selectPocId = item.value.id
      this.showGetPosition = true
    },
    setPosition (pos) {
      this.showGetPosition = false
      this.searchPosPlaceId(pos)
    },
    getTypeName (type) {
      switch (type) {
        case 'parcelLockerMarker':
          return this.$t('pmtparammapsinfo.parcellocker')
        case 'redBoxMarker':
          return this.$t('pmtparammapsinfo.redbox')
        case 'greyBoxMarker':
          return this.$t('pmtparammapsinfo.greybox')
        case 'toiletMarker':
          return this.$t('pmtparammapsinfo.toilet')
        default:
          return ''
      }
    }
  },
  computed: {
    iteratorHeight () {
      return Math.round(this.$store.getters.contentHeight - (149 + this.$store.getters.appBarHeight))
    },
    items () {
      const officeItem = this.$store.getters['officeItemModule/getAllOfficeItem']
      switch (this.itemTypeSelected) {
        case 'all':
          return officeItem
        case 'redBoxMarker':
          return officeItem.filter(x => x.type === 'redBoxMarker')
        case 'parcelLockerMarker':
          return officeItem.filter(x => x.type === 'parcelLockerMarker')
        case 'greyBoxMarker':
          return officeItem.filter(x => x.type === 'greyBoxMarker')
        case 'toiletMarker':
          return officeItem.filter(x => x.type === 'toiletMarker')
        case 'parkingMarker':
          return officeItem.filter(x => x.type === 'parkingMarker')
        default:
          return []
      }
    },
    itemList () {
      return [
        { text: this.$t('common.all'), value: 'all' },
        { text: this.$t('pmtparammapsinfo.parcellocker'), value: 'parcelLockerMarker' },
        { text: this.$t('pmtparammapsinfo.redbox'), value: 'redBoxMarker' },
        { text: this.$t('pmtparammapsinfo.greybox'), value: 'greyBoxMarker' },
        { text: this.$t('pmtparammapsinfo.toilet'), value: 'toiletMarker' }
      ]
    },
    routeList () {
      return [].concat(
        [{ text: this.$t('common.all'), value: 'all' }],
        this.$store.getters['routeModule/getRouteIdItems']
      )
    },
    imgBar () {
      if (this.itemTypeSelected !== 'all') {
        return this.$store.getMarkerIconSpec(this.$store.getters.getGoogle, { type: this.itemTypeSelected }).url
      } else {
        return require('@/assets/Icon/MenuIcon/logo_postes_canada.png')
      }
    }
  },
  mounted () {
    this.isDataLoaded = true
  },
  destroyed () {
    // this.$store.dispatch('routeModule/closeDBChannel', { clearModule: true })
    // this.$store.dispatch('routeModule/setPathVars', { officeId: this.$store.state.userLoggedModule.data.officeselected })
  }
}
</script>
