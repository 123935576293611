<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fixed max-width="444" transition="dialog-bottom-transition">
      <v-card class="overflow-x-hidden">
        <v-app-bar dark color="primary">
          <v-btn icon x-large dark @click="dialog = false">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ $t('createmapitem.title') }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-app-bar>
        <v-list nav>
          <v-row justify="start">
            <!-- BP COM -->
            <v-col cols="6" v-for="type in mapItemArray" :key="type" align="center">
              <v-list-item link class='mt-n2'>
                <v-list-item-content class="mx-auto" @click="selectItem(type)" clearfix>
                  <v-img
                    :src="$store.getMapItemIconOfType(type)"
                    alt="BP COM"
                    :max-height="$store.getters.iconSize"
                    contain
                    centered
                  ></v-img>
                  <p class="subtitle text-center">{{ $t('pocitem.' + type) }}</p>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-list>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ['title', 'param', 'mode'],
  data () {
    return {
      dialog: true,
      notifications: false,
      sound: true,
      widgets: false
    }
  },
  watch: {
    dialog (newValue) {
      this.$store.dispatch('resize')
      this.dialog = false
      this.$emit('close')
    }
  },
  computed: {
    mapItemArray () {
      switch (this.param.itemType) {
        case 'pocItem-primary':
          return this.$store.getters.getPrimaryPocMapItemTypeArray
        case 'pocItem-secondary':
          return this.$store.getters.getOptionalPocMapItemTypeArray
        default:
          return null
      }
    }
  },
  methods: {
    selectItem (type) {
      if (this.param.mode === 'select-only') {
        this.$emit('return-select-type', type)
      }
    }
  }
}
</script>
