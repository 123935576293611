<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="444" transition="dialog-bottom-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="secondary"
          block
          rounded
          large
          class="mb-5"
          v-bind="attrs"
          v-on="on"
        >
          <p class="mt-4 me-2">{{ title }}</p>
          <v-icon large>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-app-bar dark color="primary">
          <v-icon x-large>{{ mode === 'waypoint' ? 'mdi-map-marker' : 'mdi-selection-marker' }}</v-icon>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon x-large dark @click="dialog = false">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>
        <v-card-text>
          <v-select class="mt-5" outlined :label="$t('travel-add-item.' + this.mode + '-select-label')" v-model="modeSelected" :items="modeItems">
          </v-select>
          <v-text-field outlined v-model="name" :label="$t('common.name')"></v-text-field>
          <v-btn large block rounded @click="create()" :disabled="!canBeCreate" v-text="$t('common.add')"></v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ['mode'],
  data () {
    return {
      dialog: false,
      pos: null,
      title: this.$t('travel-add-item.' + this.mode + '-btn-title'),
      name: null,
      modeSelected: null
    }
  },
  computed: {
    modeItems () {
      switch (this.mode) {
        case 'sector':
          return [
            { text: this.$t(''), value: 'sector', icon: null }
          ]
        case 'waypoint':
          return [
            { text: this.$t('wp.park-loop-title'), value: 'park-loop', icon: this.$store.getMapItemIconOfType('park-loop') },
            { text: this.$t('wp.park-direct-title'), value: 'park-direct', icon: this.$store.getMapItemIconOfType('park-direct') }
          ]
        case 'taxi':
          return [
            { text: this.$t('travelItem.taxi-start'), value: 'taxi-start', icon: this.$store.getMapItemIconOfType('taxi-start') },
            { text: this.$t('travelItem.taxi-finish'), value: 'taxi-finish', icon: this.$store.getMapItemIconOfType('taxi-finish') },
            { text: this.$t('travelItem.taxi-direct'), value: 'taxi-direct', icon: this.$store.getMapItemIconOfType('taxi-direct') }
          ]
        default:
          return []
      }
    },
    canBeCreate () {
      switch (this.mode) {
        case 'sector':
          if (this.name) {
            return true
          } else {
            return false
          }
        case 'taxi':
          if (this.modeSelected) {
            return true
          } else {
            return false
          }
        case 'waypoint':
          if (this.modeSelected) {
            return true
          } else {
            return false
          }
        default:
          return false
      }
    }
  },
  methods: {
    create () {
      const newId = this.$store.getUniqueKey()
      switch (this.mode) {
        case 'taxi':
          this.$emit('create-item', {
            type: this.modeSelected,
            name: this.name,
            items: this.isGroup() ? [] : null,
            pos: this.pos,
            loc: null,
            id: newId,
            isActive: true
          })
          break
        case 'sector':
          this.$emit('create-item', {
            type: 'sector',
            items: [],
            name: this.name,
            pos: this.pos,
            sectorId: null,
            id: newId,
            isActive: true
          })
          break
        case 'waypoint':
          this.$emit('create-item', {
            type: this.modeSelected,
            name: this.name,
            loc: null,
            id: newId,
            wpId: newId
          })
          this.name = null
          this.modeSelected = null
          break
        default:
          break
      }
      this.dialog = false
    },
    isGroup () {
      switch (this.modeSelected) {
        case 'taxi-start':
        case 'taxi-finish':
          return false
        case 'taxi-direct':
          return true
        default:
          return false
      }
    }
  },
  watch: {
    // dialog (newValue) {
    //   this.$store.dispatch('resize')
    //   this.dialog = false
    //   this.$emit('close')
    // }
  }
}
</script>
