const routeSelectedModule = {
  firestorePath: 'officeData/{officeId}/route/{routeId}',
  firestoreRefType: 'doc', // or 'doc'
  moduleName: 'routeSelectedModule',
  statePropName: 'data',
  namespaced: true, // automatically added

  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {},
  mutations: {},
  actions: {}
}

export default routeSelectedModule
