<template>
  <div class="home" :style="$store.getters.contentStyle">
    <v-container justify-center align-center>
      <h1 class="text-center">{{ $t('tool.title')}}</h1>
    </v-container>
    <v-card :height="$store.getters.contentSize.height - 74" :width="$store.getters.contentSize.width" class="overflow-y-auto overflow-x-hidden">
      <v-list nav>
        <v-row>
        <v-col cols="4" v-if="this.$store.getters.isEmploye">
          <v-list-item link class='mt-n2'>
            <v-list-item-content class="mx-auto" @click="showCreateReport = true" clearfix>
                <v-icon :size="$store.getters.iconSize" color="red">mdi-alert-plus</v-icon>
                <p class="subtitle text-center">{{ $t('tool.createreport') }}</p>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="4" v-if="this.$store.getters.isEmploye">
          <v-list-item link class='mt-n2 mx-n3'>
            <v-list-item-content class="mx-auto" @click="selectItem('pmt-report-manager')" clearfix>
                <v-icon :size="$store.getters.iconSize" color="red">mdi-alert</v-icon>
                <p class="subtitle text-center">{{ $t('tool.managereport') }}</p>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="4" v-if="this.$store.getters.isEmploye">
          <v-list-item link class='mt-n2'>
            <v-list-item-content class="mx-auto" @click="selectItem('pmt-travel-manager')" clearfix>
                <v-icon :size="$store.getters.iconSize" color="primary">mdi-map-marker-path</v-icon>
                <p class="subtitle text-center">{{ $t('tool.travel') }}</p>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="4" v-if="this.$store.getters.isEmploye">
          <v-list-item link class='mt-n2'>
            <v-list-item-content class="mx-auto" @click="selectItem('pmt-officeitem-manager')" clearfix>
                <v-icon :size="$store.getters.iconSize" color="primary">mdi-database-marker</v-icon>
                <p class="subtitle text-center">{{ $t('tool.officeitem') }}</p>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <!-- <v-col cols="4">
          <v-list-item link class='mt-n2'>
            <v-list-item-content class="mx-auto" @click="showCreateMapItem = true" clearfix>
                <v-icon :size="$store.getters.iconSize" color="primary">mdi-map-marker-plus</v-icon>
                <p class="subtitle text-center">{{ $t('tool.addmapitem') }}</p>
            </v-list-item-content>
          </v-list-item>
        </v-col> -->
        <v-col cols="4">
          <v-list-item link class='mt-n2'>
          <v-list-item-content class="mx-auto" clearfix @click="selectItem('menu-formation')">
              <v-icon :size="$store.getters.iconSize" color="primary">mdi-human-male-board-poll</v-icon>
              <p class="subtitle text-center">{{ $t('tool.training') }}</p>
          </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="4" v-if="this.$store.getters.isEmploye">
          <v-list-item link class='mt-n2'>
          <v-list-item-content class="mx-auto" clearfix @click="showContact = true">
              <v-icon :size="$store.getters.iconSize" color="primary"> mdi-card-account-phone</v-icon>
              <p class="subtitle text-center">{{ $t('tool.contact') }}</p>
          </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="4" v-if="$store.getters.isMixComite">
          <v-list-item link class='mt-n2'>
          <v-list-item-content class="mx-auto" clearfix @click="selectItem('menu-mixcomite')">
              <v-icon :size="$store.getters.iconSize" color="primary">mdi-shield-link-variant</v-icon>
              <p class="subtitle text-center">{{ $t('tool.mixcomite') }}</p>
          </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="4" v-if="$store.getters.isCSocial">
          <v-list-item link class='mt-n2'>
          <v-list-item-content class="mx-auto" clearfix @click="selectItem('menu-csocial')">
              <v-icon :size="$store.getters.iconSize" color="primary">mdi-account-group</v-icon>
              <p class="subtitle text-center">{{ $t('tool.clubsocial') }}</p>
          </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="4" v-if="this.$store.getters.isAdminAcces">
          <v-list-item link class='mt-n2'>
            <!-- <v-list-item-content class="mx-auto" @click="showAdminMenu = true" clearfix> -->
            <v-list-item-content class="mx-auto" @click="$router.push('/admin')" clearfix>
              <v-icon :size="$store.getters.iconSize" color="grey">mdi-account-cog</v-icon>
              <p class="subtitle text-center">{{ $t('tool.setting') }}</p>
          </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="4" v-if="this.$store.getters.isEmploye">
          <v-list-item link class='mt-n2'>
          <v-list-item-content class="mx-auto" @click="selectItem('pmt-correct-pdr')" clearfix>
              <v-icon :size="$store.getters.iconSize" color="primary"> mdi-home-edit
              </v-icon>
              <p two-line class="subtitle text-center">{{ $t('pmtmenuadminlocal.correctpdr') }}</p>
          </v-list-item-content>
          </v-list-item>
        </v-col>
        <!-- <v-col cols="4">
          <v-list-item link class='mt-n2'>
            <v-list-item-content class="mx-auto" @click="selectItem('pmt-admin-greybox')" clearfix>
              <v-img
                :src="require('@/assets/Icon/MapIcon/GreyBox.png')"
                alt="Grey Box"
                :max-height="$store.getters.iconSize"
                contain
                centered
              ></v-img>
              <p two-line class="subtitle text-center">{{ $t('pmt-edit-greybox.title') }}</p>
          </v-list-item-content>
          </v-list-item>
        </v-col> -->
        <!-- <v-col cols="4">
          <v-list-item link class='mt-n2'>
            <v-list-item-content class="mx-auto" @click="selectItem('pmt-maps-info')" clearfix>
              <v-icon :size="$store.getters.iconSize" color="grey">mdi-map-marker</v-icon>
              <p class="subtitle text-center">Map temp</p>
          </v-list-item-content>
          </v-list-item>
        </v-col> -->
        </v-row>
      </v-list>
    </v-card>
    <pmt-menu-admin-local @close="closeAdminMenu" v-if="showAdminMenu && $store.getters.isAdminAcces"></pmt-menu-admin-local>
    <pmt-create-report-popup @close="closeCreateReport" v-if="showCreateReport"></pmt-create-report-popup>
    <pmt-menu-create-mapItem @close="closeCreateMapItem" v-if="showCreateMapItem"></pmt-menu-create-mapItem>
    <contact-popup v-if="showContact" @close="showContact = false"></contact-popup>
  </div>
</template>

<script>
export default {
  props: ['contentstyles'],
  methods: {
    selectItem (choice) {
      this.$emit('menu-item-selected', choice)
      this.ldrawer = false
    },
    closeCreateReport () {
      this.showCreateReport = false
      this.$store.getters.EventBus.$emit('sizeChange')
    },
    closeAdminMenu () {
      this.showAdminMenu = false
      this.$store.getters.EventBus.$emit('sizeChange')
    },
    closeCreateMapItem () {
      this.showCreateMapItem = false
      this.$store.getters.EventBus.$emit('sizeChange')
    }
  },
  data () {
    return {
      showCreateReport: false,
      showAdminMenu: false,
      showCreateMapItem: false,
      showContact: false
    }
  }
}
</script>
