<template fixed>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="444" fixed scrollable transition="dialog-bottom-transition">
      <v-card tile id="masterCardPdrEdit">
        <v-app-bar dark :src="pictureSrc" :color="$store.getColorCode(pdr.color)">
          <v-btn x-large icon dark @click="dialog = false">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ pdr.pdrListAddress }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <template v-slot:extension>
            <v-tabs align-with-title grow show-arrows v-model="showMenu">
              <v-tab>{{ $t('pmtpdreditpopup.menuinfo') }}</v-tab>
              <v-tab>{{ $t('pmtpdreditpopup.menuparam') }}</v-tab>
              <v-tab>{{ $t('pmtpdreditpopup.menuitem') }}</v-tab>
              <v-tab>{{ $t('pmtpdreditpopup.menunote') }}</v-tab>
              <v-tab>{{ $t('pmtpdreditpopup.menureport') }}</v-tab>
            </v-tabs>
          </template>
          <template v-slot:img="{ props }">
            <v-img
              v-bind="props"
              :gradient="$store.getGradientByColorId(pdr.color, $store.getters['userLoggedModule/isDarkThemes'])"
            ></v-img>
          </template>
        </v-app-bar>
        <!--  Info -->
        <v-card-text v-show="showMenu == 0"
          style="height: 340px"
          class="mx-auto"
          >
          <v-banner
              v-if="isPdrBlocked"
              two-line
              transition="slide-y-transition"
              dark
              sticky
              tile
              color="red"
              dense
            >
              <v-icon
                slot="icon"
                color="warning"
                size="25"
              >
                mdi-alert
              </v-icon>
              {{ $t('pmtpdreditpopup.pdrblocked') }}
            </v-banner>
          <v-card class="mt-3">
            <v-card-text class="pb-n2">
              <p class="subtitle-2 mt-n3">{{ pdr.pdrListAddress }}</p>
              <p class="subtitle-2 mt-n4">{{ pdr.pdrListPostalCode }} ,{{ pdr.city.sname }}</p>
              <p class="mt-n2">{{ getPdrTypeName(pdr.pdrType) }} ({{ $t('common.' + pdr.tpType.toLowerCase()) }})</p>
              <v-row no-gutters>
                <p class="subtitle-2 mt-n1 mb-n1">Place :</p>
                <v-spacer></v-spacer>
                <v-btn class="mt-n3 mb-n1" @click="editPlace(pdr.placeId)" icon><v-icon :color="$store.getColorCode(pdr.color)">mdi-cog-outline</v-icon></v-btn>
              </v-row>
              <p v-if="!showNewPlaceSelect" class="mb-n2 mt-n1">{{ pdr.placeId ? $store.state.placeModule.data[pdr.placeId].lName : $t('pmtpdreditpopup.noplace') }}</p>
              <v-expand-x-transition class="mb-n2 mt-2">
                <v-card
                  v-show="showNewPlaceSelect"
                  class="px-2 mx-auto mt-3 pt-2 pb-2 grey lighten-5"
                >
                  <p class="title ms-3 mt-3">{{ $t('common.allocate')}}</p>
                  <v-btn class="mt-2" @click="confirmChange('createNewPlace')" block large>{{ $t('pmtpdreditpopup.newplace') }}</v-btn>
                  <!-- <v-btn class="mt-2" block large>{{ $t('pmtpdreditpopup.existingplace') }}</v-btn> -->
                </v-card>
              </v-expand-x-transition>
            </v-card-text>
          </v-card>
          <v-divider></v-divider>
          <v-container>
            <v-row xs12>
              <v-img contain max-width="50" :src="pub ? $store.getters.getAppIcon.pub : $store.getters.getAppIcon.noPub"></v-img>
              <v-switch class="ms-2" v-model="pub" @change="confirmChange('pub')" :color="$store.getColorCode(pdr.color)" :label="pub ? $t('common.pub') : $t('common.nopub')"></v-switch>
            </v-row>
            <v-row xs12>
              <v-img contain max-width="45" :src="occuped ? $store.getters.getAppIcon.noVacant : $store.getters.getAppIcon.vacant"></v-img>
              <v-switch class="ms-2" v-model="occuped" @change="confirmChange('vacant')" :color="$store.getColorCode(pdr.color)" :label="occuped ? $t('common.novacant') : $t('common.vacant')"></v-switch>
            </v-row>
          </v-container>

        </v-card-text>
        <!--  param -->
        <v-sheet v-show="showMenu == 1"
          style="height: 340px"
          class="overflow-y-auto overflow-x-hidden"
          justify="center"
          align="center"
          >
            <div
              justify="center"
              align="center"
              height="200"
            >
              <v-img
                :src="pictureSrc"
                :height="imageStyle.height"
                :width="imageStyle.width"
                alt="pas d'image"
              >
              </v-img>
            </div>
            <!-- <v-row>
              <v-btn icon x-large @click="clickPictureIcon"><v-icon x-large :color="$store.getColorCode(pdr.color)"> {{ isReplacedPicture ? 'mdi-image-remove' : 'mdi-image-plus'}} </v-icon></v-btn>
              <p class="mt-5">{{ $t('createreportdog.picture') }}</p>
              <v-spacer></v-spacer>
              <p class="mt-5">{{ $t('createreportdog.gps') }}</p>
              <v-icon @click="showGetPosition = true" x-large :color="$store.getColorCode(pdr.color)" > mdi-map-marker </v-icon>
            </v-row> -->

            <h3>{{ $t('pmtpdreditpopup.soucePicture') }} :</h3>
            <v-switch class="ms-4 mt-n1" :disabled="!imageList.length" @change="confirmChange('picsource')" v-model="homeImageListSelect" :color="$store.getColorCode(pdr.color)" :label="homeImageListSelect ? $t('pmtpdreditpopup.nogooglePicture') : $t('pmtpdreditpopup.googlePicture')"></v-switch>
            <v-divider></v-divider>
            <v-row no-gutters class="mx-2">
              <v-btn icon x-large @click="showManagePicture = true">
                <v-badge
                  v-if="imageList.length"
                  :content="imageList.length"
                  color="green"
                  overlap
                  bottom
                >
                <v-icon x-large color="green">mdi-folder-multiple-image</v-icon>
                </v-badge>
                <v-icon v-else x-large color="red">mdi-folder-multiple-image</v-icon>
              </v-btn>
              <p class="mt-3">{{ $t('createreportdog.picture') }}</p>
              <v-spacer></v-spacer>
              <p class="mt-3">{{ $t('createreportdog.gps') }}</p>
              <v-icon @click="showGetPosition = true" x-large :color="$store.getColorCode(pdr.color)" > mdi-map-marker </v-icon>
            </v-row>
        </v-sheet>
        <!--  Item -->
        <v-sheet v-show="showMenu == 2"
          style="height: 340px;"
          class="overflow-y-auto"
          >
          <v-row class="my-3 mx-5">
              <p>{{ $t('pocitem.title') }}</p>
              <v-spacer></v-spacer>
              <v-btn
                v-show="!note"
                :color="$store.getColorCode(pdr.color)"
                fab
                dark
                small
                right
                @click="showCreateMapItem = true"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-row>
            <v-expansion-panels>
              <v-expansion-panel
                v-for="(item) in pocItemArray"
                :key="item.id"
              >
                <v-expansion-panel-header class="my-n5 mx-n4">
                  <v-col cols="3" align="center">
                    <v-img
                      :src="$store.getMapItemIconOfType(item.type)"
                      alt="Item"
                      contain
                      max-height="50"
                    ></v-img>
                  </v-col>
                  <v-col cols="8" align="start">
                  <v-list-item-content two-line>
                    <v-list-item-subtitle v-text="item.id ? $t('common.pocItem') + ' :' : $t('common.deliveryreceptacle') + ' :'"></v-list-item-subtitle>
                    <v-list-item-subtitle v-text="$t('pocitem.' + item.type)"></v-list-item-subtitle>
                  </v-list-item-content>
                  </v-col>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mb-n4">
                  <v-divider/>
                  <!-- <v-row><p class="mx-auto title">{{ $t('common.parameters') }}</p></v-row> -->
                  <v-row  class="px-n4 mx-n4">
                    <v-col :cols="$store.getters.isUpdateAccess ? '8' : '12'" align="start">
                      <display-pocitem :pocItem="item" :showDetail="false"></display-pocitem>
                    </v-col>
                    <!-- <v-col cols="4" align="center">
                      <v-btn icon x-large><v-icon x-large :color="item.imageList ? 'success' : 'error'">mdi-folder-multiple-image</v-icon></v-btn>
                      <p align="center">{{ $t('createreportdog.picture') }}</p>
                    </v-col>

                    <v-col cols="4" align="center">
                      <v-btn icon x-large @click="changeItemMapPosition(item)"><v-icon x-large :color="item.loc ? 'success' : 'error'">mdi-map-marker</v-icon></v-btn>
                      <p align="center">{{ $t('createreportdog.gps') }}</p>
                    </v-col> -->

                    <v-col cols="4" v-if="$store.getters.isUpdateAccess" align="center">
                        <v-btn icon x-large @click="editPocItem(item)"><v-icon x-large :color="$store.getColorCode(pdr.color)">mdi-file-document-edit</v-icon></v-btn>
                        <p align="center">{{ $t('common.edit') }}</p>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          <!-- </v-sheet> -->
        </v-sheet>
        <!--  Note -->
        <v-card-text v-show="showMenu == 3"
          style="height: 340px;"
          >
          <v-container>
            <v-row>
              <v-spacer></v-spacer>
              <v-btn
                v-show="!note"
                :color="$store.getColorCode(pdr.color)"
                fab
                dark
                small
                absolute
                right
                @click="showNewNote = !showNewNote"
              >
                <v-icon>{{ showNewNote ? 'mdi-pencil-off':'mdi-pencil' }}</v-icon>
              </v-btn>
            </v-row>
            <v-textarea
              class="mb-2"
              :color="$store.getColorCode(pdr.color)"
              v-show="showNewNote"
              v-model="note"
              autofocus
              auto-grow
              filled
              outlined
              clearable
              rows=3
              :label="$t('pmtpdreditpopup.createnote')"
            >
            </v-textarea>
            <div v-if="pdr.note">
              <v-textarea
                v-for="(item,i) in pdr.note"
                :key="i"
                readonly
                :value="item.note"
                solo
                :rows="item.note.split('\n').length"
                auto-grow
              >
              </v-textarea>
            </div>
          </v-container>
        </v-card-text>
        <!--  Report -->
        <v-sheet no-gutters v-show="showMenu == 4"
           style="height: 340px;"
           class="overflow-y-auto"
          >
          <v-list two-line subheader nav>
            <v-card-title>{{ $t('tool.report') }}</v-card-title>
            <v-list-item
              v-for="item in pdrReport"
              :key="item.id"
              :class="item.active ? 'grey lighten-4' : 'red lighten-4'"
              :disabled="!item.active"
              @click="editReportId = item.id"
            >
              <v-list-item-avatar>
                <v-icon large :color="item.active ? 'red' : 'grey'">{{ $store.getAlertMdiIcon(item.type) }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content three-line class="mx-n2">
                <v-list-item-title>
                  {{ $t('pmtcreatereportpopup.title' + item.type) }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Status : {{ $t('report-Status.' + item.status) }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ $t('common.create') }} : {{ $store.getters.getDisplayDate(item.created_at) }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon :color="item.proximityAlert && item.active ? 'success' : 'error'" v-text="item.active ? item.proximityAlert ? 'mdi-leak' : 'mdi-leak-off' : 'mdi-close-thick'"></v-icon>
              </v-list-item-action>
            </v-list-item>
            <!-- <v-list-item
              v-for="(item) in pdrReport"
              :key="item.reportId"
              @click="showReport = true"
              link
            >
              <v-list-item-avatar>
                <v-icon large
                  :color="item.isBlocked ? 'red': 'warning'"
                  v-text="$store.getAlertMdiIcon($store.state.reportModule.data[item.reportId].type)"
                ></v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="'status : ' + $t('pmtpdreditpopup.reportstatus' + $store.state.reportModule.data[item.reportId].status)"></v-list-item-title>
                <v-list-item-subtitle v-text="$store.getters.getDisplayDate($store.state.reportModule.data[item.reportId].created_at)"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon large color="grey lighten-1">mdi-information</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item> -->
          </v-list>
        </v-sheet>
        <!-- App bar bottom -->
        <v-app-bar dark :color="isPdrBlocked ? 'red' : $store.getColorCode(pdr.color)" bottom>
          <!-- <v-btn x-large icon dark @click="dialog = false">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn> -->
          <v-icon
            v-show="isPdrBlocked"
          >
            mdi-alert
          </v-icon>
          <v-toolbar-title class="red--" v-show="isPdrBlocked">
              {{ $t('pmtpdreditpopup.pdrblocked') }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon
            v-if="pdrIsModify"
            x-large
            size="50"
            color="green"
            icon
            @click="saveChange"
          >
            <v-icon size="50">mdi-content-save</v-icon>
          </v-app-bar-nav-icon>
        </v-app-bar>
      </v-card>
    </v-dialog>
      <!-- <pmt-import-picture
        v-if="showImportPicture"
        :title="$t('createreportdog.addpicture')"
        @selectpicture="addpicture($event)"
        @close="showImportPicture = false"
      ></pmt-import-picture> -->
      <pmt-position-map-set
        v-if="showGetPosition"
        :startCenterPdrId="pdr.id"
        :param="{ icon: null, getDistance: false, searchPocId: pdr.id }"
        @return-close="showGetPosition = false"
        @return-check="setPosition($event)"
      />
      <pmt-edit-pocItem
        v-if="showCreateEditPocItem"
        :pocId="pdr.id"
        :itemId="selectedItem.id"
        :color="$store.getColorCode(pdr.color)"
        @return-close="showCreateEditPocItem = false"
      ></pmt-edit-pocItem>
      <pmt-menu-create-mapItem
        @close="showCreateMapItem = false"
        @return-select-type="createNewPocItem($event)"
        :param="{ mode: 'select-only', itemType: 'pocItem-secondary' }"
        v-if="showCreateMapItem"
      ></pmt-menu-create-mapItem>
      <picture-manager
        v-if="showManagePicture"
        :subTitle="pdr.pdrListAddress"
        :param="{ type: 'pocHome', pocId: pdr.id }"
        @close="closeManagePicture()"
      ></picture-manager>
      <confirm-popup
        v-if="showConfirm"
        @cancel="cancelConfirm()"
        @confirm="areConfirm()"
        :from="confirmFrom"
        :to="confirmTo"
        :title="confirmTitle"
        :param="{ mode: 'standard', text: null }"
      ></confirm-popup>
      <pmt-report-editor
        v-if="editReportId"
        :reportId="editReportId"
        @close="editReportId = null"
      ></pmt-report-editor>
      <pmt-edit-place-popup
        v-if="showEditPlaceId"
        :placeId="showEditPlaceId"
        :colorId="pdr.color"
        @close="showEditPlaceId = null"
      ></pmt-edit-place-popup>
      <pmt-loading-local :loadingTitle="showLoadingTitle" :loadingShow="showLoading"></pmt-loading-local>
  </v-row>
</template>

<script>
export default {
  props: ['title', 'pdrId'],
  data () {
    return {
      newPdr: null,
      pub: false,
      occuped: false,
      homeImageListSelect: false,
      showNewPlaceSelect: false,
      showCreateMapItem: false,
      editReportId: null,
      dialog: true,
      showMenu: 0,
      // pocItemArray: this.$store.getters['pocModule/getActiveMapItemsOfPoc'](this.pdrId),
      showReport: false,
      showNewNote: false,
      notifications: false,
      homeImageUrl: null,
      sound: true,
      widgets: false,
      note: null,
      importPicture: null,
      newPosition: null,
      showImportPicture: false,
      showManagePicture: false,
      showGetPosition: false,
      showGetItemPosition: false,
      showCreateEditPocItem: false,
      showEditPlaceId: null,
      showLoading: false,
      showLoadingTitle: '',
      showEditPlace: false,
      selectedItem: null,
      showConfirm: false,
      confirmMode: null,
      confirmTitle: null,
      confirmFrom: null,
      confirmTo: null
    }
  },
  computed: {
    pocItemArray () {
      return this.$store.getters['pocModule/getActiveMapItemsOfPoc'](this.pdrId)
      // // console.log('trace pocItem')
      // // console.log(test)
      // return test
      // return this.$store.getters['pocModule/getActiveMapItemsOfPoc'](this.pdrId)
    },
    isPdrBlocked () {
      let result = false
      if (this.pdrReport) {
        this.pdrReport.forEach(report => {
          if (report.active && report.pocList[report.pocList.map(x => { return x.pdrId }).indexOf(this.pdrId)].isBlocked) {
            result = true
          }
        })
      }
      return result
    },
    pdrReport () {
      return this.$store.getters['reportModule/getReportOfPocId'](this.pdrId)
      // if (this.pdr.report) {
      //   const activeReportList = []
      //   for (let i = 0; i < this.pdr.report.length; i++) {
      //     const report = this.pdr.report[i]
      //     if (this.$store.state.reportModule.data[report.reportId].active) {
      //       activeReportList.push(report)
      //     }
      //   }
      //   return activeReportList
      // } else {
      //   return []
      // }
    },
    isReplacedPicture () {
      if (this.importPicture) {
        return true
      } else if (this.pdr.importPictureInfo) {
        return true
      } else {
        return false
      }
    },
    pdrIsModify () {
      if (this.importPicture) {
        return true
      } else if (this.newPosition) {
        return true
      } else if (this.note) {
        return true
      } else {
        return false
      }
    },
    pdr () {
      if (this.pdrId) {
        return this.$store.state.pocModule.data[this.pdrId]
      } else {
        return null
      }
    },
    imageList () {
      if (this.pdr.homeImageList) {
        return this.pdr.homeImageList
      } else { return [] }
    },
    pictureSrc () {
      return this.$store.getters['pocModule/getPdrPictureHomeSrc']({ pdrId: this.pdrId, width: this.imageStyle.width, height: this.imageStyle.height })
    },
    imageStyle () {
      const imgWidth = this.$store.getters.contentWidth - 50
      if (imgWidth > 300) {
        return { width: 300, height: 200 }
      } else {
        return { width: Math.round(imgWidth), height: Math.round((imgWidth / 3) * 2) }
      }
    }
  },
  watch: {
    dialog (newValue) {
      // // console.log('trace A')
      this.dialog = false
      this.$store.dispatch('resize')
      this.$emit('close')
    },
    pictureSrc (newValue) {
      // // console.log('trace B')
      this.homeImageUrl = newValue
      this.homeImageListSelect = this.pdr.homeImageListSelect
    }
  },
  methods: {
    getRouteNameString (routeIdArray) {
      return routeIdArray.map(x => { return this.$store.state.routeModule.data[x].name }).sort(function (a, b) { return a - b }).toString()
    },
    getReportName (report) {
      const ownerIdx = report.pocList.map(x => { return x.isOwner }).indexOf(true)
      if (ownerIdx < 0) {
        return this.$t('pmtcreatereportpopup.title' + report.type)
      } else {
        if (report.pocList[ownerIdx].name) {
          return report.pocList[ownerIdx].name
        } else if (report.pocList[ownerIdx].address) {
          return report.pocList[ownerIdx].address
        } else {
          return this.$t('pmtcreatereportpopup.title' + report.type)
        }
      }
    },
    editPlace (placeId) {
      // // console.log('editplace -> ' + placeId)
      if (placeId) {
        this.showEditPlaceId = placeId
      } else {
        this.showNewPlaceSelect = !this.showNewPlaceSelect
      }
      // this.showEditPlace = true
    },
    getPdrTypeName (type) {
      switch (type) {
        case 'Hou/Dom':
          return this.$t('common.dom')
        case 'Apt/App':
          return this.$t('common.app')
        case 'Bus/com':
          return this.$t('common.bus')
        default:
          return this.$t('common.frm')
      }
    },
    closeManagePicture () {
      this.showManagePicture = false
      this.homeImageUrl = this.pictureSrc
    },
    confirmChange (mode) {
      this.confirmMode = mode
      switch (mode) {
        case 'pub':
          this.confirmTitle = this.$t('common.changepubconfirm')
          this.confirmFrom = this.pdr.pub ? this.$t('common.pub') : this.$t('common.nopub')
          this.confirmTo = this.pub ? this.$t('common.pub') : this.$t('common.nopub')
          this.showConfirm = true
          break
        case 'vacant':
          this.confirmFrom = this.pdr.vacant ? this.$t('common.vacant') : this.$t('common.novacant')
          this.confirmTo = this.occuped ? this.$t('common.novacant') : this.$t('common.vacant')
          this.confirmTitle = this.$t('common.changevacantconfirm')
          this.showConfirm = true
          break
        case 'picsource':
          this.confirmTitle = this.$t('common.changepicsourceconfirm')
          this.confirmFrom = null
          this.confirmTo = null
          this.showConfirm = true
          break
        case 'homePosition':
          this.confirmTitle = this.$t('common.changehomepositionconfirm')
          this.confirmFrom = null
          this.confirmTo = null
          this.showConfirm = true
          break
        case 'createNewPlace':
          this.confirmTitle = this.$t('common.createnewplaceconfirm')
          this.confirmFrom = null
          this.confirmTo = null
          this.showConfirm = true
          this.showNewPlaceSelect = false
          break
        case 'allocatePlace':
          this.confirmTitle = this.$t('common.allocateplaceconfirm')
          this.confirmFrom = null
          this.confirmTo = null
          this.showConfirm = true
          this.showNewPlaceSelect = false
          break
        default:
          break
      }
    },
    areConfirm () {
      switch (this.confirmMode) {
        case 'pub':
          this.$store.dispatch('pocModule/updatePub', { pocId: this.pdr.id, pub: this.pub })
          this.showConfirm = false
          break
        case 'vacant':
          this.$store.dispatch('pocModule/updateVacant', { pocId: this.pdr.id, vacant: !this.occuped })
          this.showConfirm = false
          break
        case 'picsource':
          this.$store.dispatch('pocModule/updateHomeImageListSelect', { pocId: this.pdr.id, homeImageListSelect: this.homeImageListSelect })
          this.showConfirm = false
          break
        case 'homePosition':
          this.$store.dispatch('pocModule/updateHomeLocation', { pocId: this.pdr.id, homeLocation: this.newPosition.position })
          this.newPosition = null
          this.showConfirm = false
          break
        case 'allocatePlace':
          // this.$store.dispatch('pocModule/updateHomeLocation', { pocId: this.pdr.id, homeLocation: this.newPosition.position })
          this.newPosition = null
          this.showConfirm = false
          break
        case 'createNewPlace':
          // this.$store.dispatch('pocModule/updateHomeLocation', { pocId: this.pdr.id, homeLocation: this.newPosition.position })
          this.newPosition = null

          this.showConfirm = false
          this.showLoadingTitle = this.$t('common.saveinprogress')
          this.showLoading = true
          this.$store.dispatch('placeModule/createPlace', {
            sName: this.pdr.pdrListAddress.split(' ')[0],
            lName: this.pdr.pdrListAddress,
            homeLocation: this.pdr.homeLocation,
            ggPlaceId: this.pdr.place_id,
            pocList: [this.pdrId],
            imageList: this.pdr.imageList ? this.pdr.imageList : []
          }).then((placeId) => {
            // // console.log('new Place Id =' + placeId)
            // this.$store.state.pocModule.data[this.pdrId].placeId = placeId
            this.$store.dispatch('pocModule/setPlaceId', { placeId: placeId, pocId: this.pdrId })
              .then(() => {
                this.$store.dispatch('userSelectionPdrOfOffice', {
                  codeId: this.pdr.codeId,
                  color: this.pdr.color,
                  placeId: placeId,
                  id: null
                }).then(() => {
                  this.showLoading = false
                })
              })
          })
          break
        default:
          break
      }
    },
    cancelConfirm () {
      this.occuped = !this.pdr.vacant
      this.pub = this.pdr.pub
      if (this.pdr.homeImageListSelect) {
        this.homeImageListSelect = this.pdr.homeImageListSelect
      } else {
        this.homeImageListSelect = false
      }
      this.newPosition = null
      this.showConfirm = false
    },
    createNewPocItem (type) {
      this.showCreateMapItem = false
      this.showLoadingTitle = this.$t('common.saveinprogress')
      this.showLoading = true
      this.$store.dispatch('pocModule/createPocItem', {
        type: type,
        pocId: this.pdrId
      }).then((itemId) => {
        this.showLoading = false
      })
      // this.selectedItem = null
      // this.showCreateEditPocItem = true
    },
    editPocItem (item) {
      this.selectedItem = item
      this.showCreateEditPocItem = true
    },
    changeItemMapPosition (item) {
      this.selectedItem = item
      this.showGetItemPosition = true
    },
    setItemPosition (pos) {
      this.showGetItemPosition = false
      this.$store.dispatch('pocModule/updateMapItemPos', { itemId: this.selectedItem.id, pocId: this.pdr.id, pos: pos.position })
    },
    addNote () {
      if (this.note) {
        if (this.pdr.note) {
          this.pdr.note.push(this.note)
        } else {
          this.pdr.note = [this.note]
        }
        this.note = null
      }
    },
    // addpicture (pic) {
    //   this.showImportPicture = false
    //   this.importPicture = pic
    // },
    // clickPictureIcon () {
    //   if (this.importPicture) {
    //     this.importPicture = null
    //   } else if (this.pdr.importPictureInfo) {
    //     this.$store.dispatch('pdrModule/deletePdrReplacedPicture', this.pdr.id)
    //   } else {
    //     this.showImportPicture = true
    //   }
    // },
    setPosition (pos) {
      this.showGetPosition = false
      this.newPosition = pos
      this.confirmChange('homePosition')
    },
    saveChange () {
      this.dialog = false
      let data
      if (this.note) {
        data = {
          id: this.pdr.id,
          pub: this.pub,
          vacant: !this.occuped,
          importPicture: this.importPicture,
          newPosition: this.newPosition,
          note: { note: this.note, user: this.$store.getters.userinfokey, timestampFields: new Date() },
          successMessage: this.$t('common.succesUpdate'),
          errorMessage: this.$t('common.errorUpdate')
        }
      } else {
        data = {
          id: this.pdr.id,
          pub: this.pub,
          vacant: !this.occuped,
          importPicture: this.importPicture,
          newPosition: this.newPosition,
          successMessage: this.$t('common.succesUpdate'),
          errorMessage: this.$t('common.errorUpdate')
        }
      }

      this.$store.dispatch('pdrModule/onUpdatePdr', (data))
      this.note = null
    }

  },
  mounted () {
    this.pub = this.pdr.pub
    // // console.log('trace mounth pdrId = ' + this.pdrId)
    // // console.log(this.pdr)
    // // console.log(this.pocItemArray)
    // this.pocItemArray = this.$store.getters['pocModule/getActiveMapItemsOfPoc'](this.pdrId)
    this.occuped = !this.pdr.vacant
    if (this.pdr.homeImageListSelect) {
      this.homeImageListSelect = this.pdr.homeImageListSelect
    }
    this.homeImageUrl = this.pictureSrc
    // for (let i = 0; i < this.pdr.note.length; i++) {
    //   const note = this.pdr.note[i]
    //   const test = note.note.split('\n')
    // }
    // this.note = this.pdr.note
    // if (this.pdr.) {
    //   this.importPicture = this.$store.getters['pdrModule/getPdrReplaceImgUrl'](this.pdr.id)
    // }
    // var pdrSrc = this.pdrPictureSrc
    // this.$store.getters['pdrModule/getPdrPictureHomeSrc']({ pdrId: this.pdrId, width: '200', height: '400' })
    //   .then((src) => {
    //     this.pdrPictureSrc = src
    //   })
  }
}
</script>
