const messUserModule = {
  firestorePath: 'userData/{userId}/messUser',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'messUserModule',
  statePropName: 'data',
  namespaced: true, // automatically added

  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    getActiveMessageSorted: (state, getters) => {
      return Object.values(state.data).filter(mess => mess.isActive && !mess.isDelete)
        .sort(function (a, b) {
          return b.created_at - a.created_at
        }) // your sort function
    },
    getItems: (state, getters) => {
      const items = getters.getActiveMessageSorted
      items.forEach(mess => {
        mess.module = 'messUserModule'
      })
      return items
    }
  },
  mutations: {
    setReadByMe (state, payload) {
      this.dispatch('messUserModule/set', {
        id: payload,
        isRead: true
      })
    },
    setDeleteByMe (state, payload) {
      this.dispatch('messUserModule/set', {
        id: payload,
        isDelete: true
      })
    },
    setUndeleteByMe (state, payload) {
      this.dispatch('messUserModule/set', {
        id: payload,
        isDelete: false
      })
    }
  },
  actions: {
    asReadMessage ({ commit, getters }, payload) {
      commit('setReadByMe', payload)
    },
    asDeleteMessage ({ commit, getters }, payload) {
      commit('setDeleteByMe', payload)
    },
    asUndeleteMessage ({ commit, getters }, payload) {
      commit('setUndeleteByMe', payload)
    }
  }
}

export default messUserModule
