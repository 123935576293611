<template>
  <div class="home">
    <v-card :style="$store.getters.contentStyle">
       <v-card height="150" class="grey lighten-2">
        <v-card-title>
          <v-row>
            <v-btn icon x-large @click="closeBack()"><v-icon x-large>mdi-arrow-left-circle</v-icon></v-btn>
            <h3 class="mt-3">Menu</h3>
            <v-spacer></v-spacer>
            <p class="mt-3 red--text">{{ items.length }} error</p>
            <v-spacer></v-spacer>
            <!-- <v-btn x-large icon @click="autoSearchAll()"><v-icon x-large>mdi-auto-fix</v-icon></v-btn> -->
          </v-row>
          <v-select class="pb-n1 grey lighten-2" v-model="routeFilter" :items="routeItems"></v-select>
        </v-card-title>
      </v-card>
      <v-sheet class="overflow-y-auto overflow-x-hidden" :height="$store.getters.contentHeight - 150" :max-height="$store.getters.contentHeight - 150">
        <!-- <v-card-title>
        <v-row>
          <v-btn icon x-large @click="$store.getters.EventBus.$emit('selectLocalAdminComponent', ('pmt-menu-admin-local'))"><v-icon x-large>mdi-arrow-left-circle</v-icon></v-btn>
          <h3 class="mt-3">Menu</h3>
          <v-spacer></v-spacer>
          {{ items.length }} error
          <v-spacer></v-spacer>
          <v-btn x-large icon @click="autoSearchAll()"><v-icon x-large>mdi-auto-fix</v-icon></v-btn>

        </v-row>
      </v-card-title> -->
        <v-list v-if="isDataLoaded" nav>
          <v-list-item
            v-for="item in items"
            :key="item.id"
          >
            <v-list-item-content>
              <v-row class="mx-auto">
                <v-card-subtitle class="mt-n2">
                  {{ item.pdrListAddress }}
                </v-card-subtitle>
                <v-spacer></v-spacer>
                <!-- <v-btn x-large icon @click="autoSearch(item)"><v-icon x-large>mdi-auto-fix</v-icon></v-btn> -->
                <v-btn x-large icon @click="getAddressgeocode(item)"><v-icon x-large>mdi-map-marker-plus</v-icon></v-btn>
              </v-row>
              <v-card-subtitle class="mt-n8" v-for="routeId in item.route" :key="routeId">
                {{ $store.state.routeModule.data[routeId].name }}
              </v-card-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-sheet>
    </v-card>
    <loading-popup v-if="showLoading" :title="loadingTitle" :subTitle="loadingsubTitle"></loading-popup>
    <pmt-position-map-set
      v-if="showGetPosition"
      :param="{ title: showGetPositionTitle, bounds: showGetPositionBounds, icon: $store.getAlertPngIcon('house'), getDistance: false, isCreateMode: true, searchPocId: showGetPosition  }"
      @return-close="showGetPosition = null"
      @return-check="setPosition($event)"
    />
  </div>
</template>

<script>
export default {
  props: ['title'],
  data () {
    return {
      isDataLoaded: true,
      showLoading: false,
      showGetPosition: null,
      showGetPositionTitle: '',
      arroudPoc: [],
      loadingTitle: this.$t('common.loading'),
      loadingsubTitle: '',
      selectPocId: null,
      showGetPositionBounds: null,
      selectedPoc: null,
      processAllonOff: false,
      itemPosition: null,
      routeFilter: 'all'
    }
  },
  methods: {
    closeBack () {
      if (this.$route.name === 'app') {
        this.$emit('menu-item-selected', 'pmt-pm-tool')
      } else {
        this.$store.getters.EventBus.$emit('selectLocalAdminComponent', ('pmt-menu-admin-local'))
      }
    },
    searchPosPlaceId (pos) {
      const google = this.$store.getters.getGoogle
      const geocoder = new google.maps.Geocoder()
      const store = this.$store
      // const pocId = this.selectPocId
      const poc = this.selectedPoc
      const pocToUpdateArray = []
      if (poc.pdrListAddress.split(' ')[0].split('-').length > 1) {
        const originalDoor = poc.pdrListAddress.split(' ')[0]
        const doorNoSuit = originalDoor.split('-')[1]
        const addressNoSuit = poc.pdrListAddress.replace(originalDoor, doorNoSuit)
        this.items.forEach(item => {
          if (item.pdrListAddress.indexOf(addressNoSuit) > 0) {
            // // console.log(item.pdrListAddress)
            pocToUpdateArray.push(item.id)
          }
        })
        // pocToUpdateArray.push()
        // // console.log('addressNoSuit')
        // // console.log(addressNoSuit)
      } else {
        // // console.log('pas de suit')
        pocToUpdateArray.push(poc.id)
      }
      geocoder.geocode({ location: pos.position }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          // // console.log(results)
          pocToUpdateArray.forEach(id => {
            const pocUpdate = {
              id: id,
              place_id: results[0].place_id,
              formatted_address: results[0].formatted_address,
              homeLocation: pos.position,
              googleFound: true,
              isActive: true
            }
            // // console.log('update = ')
            // // console.log(pocUpdate)
            store.dispatch('pocModule/set', pocUpdate)
            //   .then(() => {
            //     // // console.log('poc update id = ' + pocId)
            //   })
          })
        } else {
          // there were no coordinates found for the address entered (or the address was not found)
          alert('Could not find your address ')
        }
      })
    },
    getPocPosition (poc) {
      // const prom = []
      // // console.log('trace 1')
      this.showLoading = true
      const clauses = []
      this.selectedPoc = poc
      poc.route.forEach(routeId => {
        clauses.push(['route', 'array-contains', routeId])
        // prom.push(this.$store.dispatch('pocModule/openDBChannel', { pathVariables: { officeId: this.$store.getters.getSelectedOfficeId }, clauses: { where: [['route', 'array-contains', this.routeId]] } }))
      })
      this.$store.dispatch('pocModule/openDBChannel', { pathVariables: { officeId: this.$store.getters.getSelectedOfficeId }, clauses: { where: clauses } })
        .then(() => {
          this.arroundPoc = this.getArroundPoc(poc)
          this.showGetPositionTitle = poc.pdrListAddress
          this.showLoading = false
          // // console.log(poc.id)
          this.showGetPosition = poc.id
        })
    },
    getAddressgeocode (poc) {
      this.showLoading = true
      const google = this.$store.getters.getGoogle
      this.selectedPoc = poc
      const self = this
      // let schadress = "20 River Terrace, New York, NY, United States'";
      const schadress = poc.pdrListAddress + ', ' + poc.city.sname + ', QC, Canada'
      var geocoder = new google.maps.Geocoder()
      // then we call the geocode function with the address we want to use as parameter
      geocoder.geocode({ address: schadress }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            self.showGetPositionBounds = results[0].geometry.bounds
          }
          // this.searchgeocoderresult = [];
          // this.searchgeocoderresult.push(results[0]) ;
        } else {
          // there were no coordinates found for the address entered (or the address was not found)
          alert('Could not find your address :(')
          // // console.log('Address not found by google :' + schadress)
        }
        self.showGetPositionTitle = poc.pdrListAddress
        self.showLoading = false
        self.showGetPosition = poc.id
      })
    },
    getArroundPoc (poc) {
      const pocArray = this.$store.getters['pocModule/getPocOfRoute'](poc.route[0]).sort(function (a, b) {
        return Number(a.pdrseqtotal) - Number(b.pdrseqtotal)
      })
      const sameCodePostalPoc = pocArray.filter(x => x.pdrListPostalCode === poc.pdrListPostalCode && poc.homeLocation)
      const pocIndex = pocArray.map(x => { return x.id }).indexOf(poc.id)
      const afterPoc = []
      const beforePoc = []
      for (let i = pocIndex + 1; i < pocArray.length; i++) {
        const pdr = pocArray[i]
        if (pdr.homeLocation) { afterPoc.push(pdr) }
        if (afterPoc.length > 2) { break }
      }
      for (let i = pocIndex - 1; i >= 0; i--) {
        const pdr = pocArray[i]
        if (pdr.homeLocation) { beforePoc.push(pdr) }
        if (beforePoc.length > 2) { break }
      }
      return [].concat(beforePoc, afterPoc, sameCodePostalPoc)
    },
    // async getItemPosition_back (poc) {
    //   // // console.log('select item = ' + item.text)
    //   const google = this.$store.getters.getGoogle
    //   const geocoder = new google.maps.Geocoder()
    //   // const poc = this.$store.state.pocModule.data[item.value.id]
    //   const searchArray = await this.createSearchArray(poc)
    //   var search = searchArray[0]
    //   if (searchArray.length) {
    //     for (let i = 0; i < searchArray.length; i++) {
    //       if (searchArray[i].type === 'originalNoApp') {
    //         search = searchArray[i]
    //       }
    //     }
    //   }
    //   this.selectPocId = item.value.id
    //   // // console.log('search url = ' + search.url)
    //   const gSearch = new Promise((resolve, reject) => {
    //     geocoder.geocode({ address: search.url }, async function (results, status) {
    //       if (status === google.maps.GeocoderStatus.OK) {
    //         // // console.log('trace1')
    //         // // console.log(results)
    //         resolve(results[0])
    //       } else {
    //         resolve(null)
    //       }
    //     })
    //   })
    //   // const googleResult = await gSearch()
    //   gSearch.then((result) => {
    //     // // console.log('trace promise')
    //     // // console.log(result)
    //     this.itemPosition = { lat: result.geometry.location.lat(), lng: result.geometry.location.lng() }
    //     this.showGetPosition = true
    //   }).catch((err) => {
    //     this.$store.dispatch('showAlert', (err.message))
    //     // // console.log(err)
    //   })
    //   // // console.log(googleResult)
    //   // if (googleResult) {
    //   // }
    //   // this.itemPosition =
    //   // this.showGetPosition = true
    // },
    autoSearchAll () {
      const data = this.$store.state.pocModule.data
      const google = this.$store.getters.getGoogle
      const geocoder = new google.maps.Geocoder()
      const dispatch = this.$store.dispatch
      const createSearchArray = this.createSearchArray
      var operate
      const items = this.items
      var itemToSearch = -1
      // const result = await this.getGoogleLatLng(poc)
      function searchNextITem () {
        itemToSearch++
        if (itemToSearch < items.length && operate) {
          searchOneItem(items[itemToSearch])
        }
      }
      async function searchOneItem (item) {
        const poc = data[item.value.id]
        const toSearchArray = await createSearchArray(poc)
        // // console.log(toSearchArray)
        async function trySearch (i) {
          const search = toSearchArray[i]
          geocoder.geocode({ address: toSearchArray[i].url }, async function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
              const resultObj = await createObjetWithGoogleResult(results[0])
              // // console.log('trace i = ' + i)
              // // console.log(resultObj)
              if (!resultObj.door) {
                // // console.log('no result with door found')
                if (i < toSearchArray.length - 1) {
                  trySearch(i + 1)
                } else {
                  searchNextITem()
                }
              } else if (search.door.toString().toUpperCase().replace(/ /g, '') !== resultObj.door.toString().toUpperCase().replace(/ /g, '')) {
                // // console.log('trace door = {' + search.door + '} !== {' + resultObj.door + '}')
                if (i < toSearchArray.length - 1) {
                  trySearch(i + 1)
                } else {
                  searchNextITem()
                }
              } else if (search.postalCode.substr(0, 3) !== resultObj.postalCode.substr(0, 3)) {
                // // console.log('trace postalCode = {' + search.postalCode.substr(0, 3) + '} !== {' + resultObj.postalCode.substr(0, 3) + '}')
                if (i < toSearchArray.length - 1) {
                  trySearch(i + 1)
                } else {
                  searchNextITem()
                }
              } else {
                // // console.log('found match')
                const update = {
                  id: poc.id,
                  place_id: resultObj.place_id,
                  formatted_address: resultObj.formatted_address,
                  homeLocation: resultObj.homeLocation,
                  googleFound: true,
                  googleSearch: true,
                  isActive: true
                }
                dispatch('pocModule/set', update)
                  .then(() => {
                    searchNextITem()
                  })
                // // console.log(toSearchArray[i])
              }
              // this.$store.dispatch('officeModule/set', officedetail)
            } else if (status === 'OVER_QUERY_LIMIT') {
              // onsole.log('over limits cpt = ' + cpt)
              setTimeout(function () {
                trySearch(i)
              }, 2000)
            } else {
              if (i < toSearchArray.length - 1) {
                trySearch(i + 1)
              } else {
                searchNextITem()
              }
              // there were no coordinates found for the address entered (or the address was not found)
              alert('Could not find your address ( ' + poc.pdrListAddress + ' )')
              // onsole.log(status)
            }
          })
        }
        if (toSearchArray.length) {
          trySearch(0)
        }
      }
      async function createObjetWithGoogleResult (result) {
        var resultObj = { door: null, street: null, postalCode: null, city: null, place_id: result.place_id, formatted_address: result.formatted_address, homeLocation: { lat: result.geometry.location.lat(), lng: result.geometry.location.lng() } }
        // // console.log('formatted_address = ' + result.formatted_address)

        for (let i = 0; i < result.address_components.length; i++) {
          const item = result.address_components[i]

          for (let x = 0; x < item.types.length; x++) {
            const type = item.types[x]
            if (type === 'street_number') {
              // // console.log('trace result street = ' + item.long_name)
              resultObj.door = item.long_name
            }
            if (type === 'route') {
              resultObj.street = item.long_name
            }
            if (type === 'postal_code') {
              // // console.log('trace result postal code = ' + item.long_name)
              resultObj.postalCode = item.long_name
            }
            if (type === 'administrative_area_level_2') {
              resultObj.city = item.long_name
            }
          }
        }
        return resultObj
      }
      if (this.processAllonOff) {
        this.processAllonOff = false
        operate = false
      } else {
        operate = true
        searchNextITem()
      }
    },
    async autoSearch (item) {
      this.selectPocId = item.value.id
      const poc = this.$store.state.pocModule.data[item.value.id]
      const google = this.$store.getters.getGoogle
      const geocoder = new google.maps.Geocoder()
      const dispatch = this.$store.dispatch
      // const result = await this.getGoogleLatLng(poc)
      const toSearchArray = await this.createSearchArray(poc)
      // // console.log(toSearchArray)
      async function trySearch (i) {
        const search = toSearchArray[i]
        geocoder.geocode({ address: toSearchArray[i].url }, async function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            const resultObj = await createObjetWithGoogleResult(results[0])
            // // console.log('trace i = ' + i)
            // // console.log(resultObj)
            if (!resultObj.door) {
              // // console.log('no result with door found')
              if (i < toSearchArray.length - 1) {
                trySearch(i + 1)
              }
            } else if (search.door.toString().toUpperCase().replace(/ /g, '') !== resultObj.door.toString().toUpperCase().replace(/ /g, '')) {
              // // console.log('trace door = {' + search.door + '} !== {' + resultObj.door + '}')
              if (i < toSearchArray.length - 1) {
                trySearch(i + 1)
              }
            } else if (search.postalCode.substr(0, 3) !== resultObj.postalCode.substr(0, 3)) {
              // // console.log('trace postalCode = {' + search.postalCode.substr(0, 3) + '} !== {' + resultObj.postalCode.substr(0, 3) + '}')
              if (i < toSearchArray.length - 1) {
                trySearch(i + 1)
              }
            } else {
              // // console.log('found match')
              const update = {
                id: poc.id,
                place_id: resultObj.place_id,
                formatted_address: resultObj.formatted_address,
                homeLocation: resultObj.homeLocation,
                googleFound: true,
                googleSearch: true,
                isActive: true
              }
              dispatch('pocModule/set', update)
              // // console.log(toSearchArray[i])
            }
          } else if (status === 'OVER_QUERY_LIMIT') {
            // onsole.log('over limits cpt = ' + cpt)
            setTimeout(function () {
              trySearch(i)
            }, 2000)
          } else {
            if (i < toSearchArray.length - 1) {
              trySearch(i + 1)
            }
            // there were no coordinates found for the address entered (or the address was not found)
            alert('Could not find your address ( ' + poc.pdrListAddress + ' )')
            // onsole.log(status)
          }
        })
      }
      async function createObjetWithGoogleResult (result) {
        var resultObj = { door: null, street: null, postalCode: null, city: null, place_id: result.place_id, formatted_address: result.formatted_address, homeLocation: { lat: result.geometry.location.lat(), lng: result.geometry.location.lng() } }
        // // console.log('formatted_address = ' + result.formatted_address)

        for (let i = 0; i < result.address_components.length; i++) {
          const item = result.address_components[i]

          for (let x = 0; x < item.types.length; x++) {
            const type = item.types[x]
            if (type === 'street_number') {
              // // console.log('trace result street = ' + item.long_name)
              resultObj.door = item.long_name
            }
            if (type === 'route') {
              resultObj.street = item.long_name
            }
            if (type === 'postal_code') {
              // // console.log('trace result postal code = ' + item.long_name)
              resultObj.postalCode = item.long_name
            }
            if (type === 'administrative_area_level_2') {
              resultObj.city = item.long_name
            }
          }
        }
        return resultObj
      }
      if (toSearchArray.length) {
        trySearch(0)
      }
    },
    setPosition (pos) {
      this.showGetPosition = false
      // // console.log(pos)
      this.searchPosPlaceId(pos)
    },
    async createObjetWithGoogleResult (result) {
      var resultObj = { door: null, street: null, postalCode: null, city: null, place_id: result.place_id, formatted_address: result.formatted_address, homeLocation: { lat: result.geometry.location.lat, lng: result.geometry.location.lng } }
      // // console.log('formatted_address = ' + result.formatted_address)

      for (let i = 0; i < result.address_components.length; i++) {
        const item = result.address_components[i]

        for (let x = 0; x < item.types.length; x++) {
          const type = item.types[x]
          if (type === 'street_number') {
            // // console.log('trace result street = ' + item.long_name)
            resultObj.door = item.long_name
          }
          if (type === 'route') {
            resultObj.street = item.long_name
          }
          if (type === 'postal_code') {
            // // console.log('trace result postal code = ' + item.long_name)
            resultObj.postalCode = item.long_name
          }
          if (type === 'administrative_area_level_2') {
            resultObj.city = item.long_name
          }
        }
      }
      return resultObj
    },
    async createSearchArray (poc) {
      const addressArray = poc.pdrListAddress.trim().split(' ')
      const doorOrigin = addressArray[0]
      var door = addressArray[0]
      const street = addressArray[addressArray.length - 1]
      const searchArray = []

      // var searchObj
      var searchObjWithUrl
      // searchArray.push(poc.pdrListAddress + ', ' + poc.city.sname + ', ' + poc.province.sname + ' ' + poc.pdrListPostalCode + ', Canada')
      // search same address with app-door
      // door = addressArray[0]
      // // console.log('address = ' + poc.pdrListAddress)
      // // console.log('door = ' + door)
      // // console.log('street = ' + street)
      searchObjWithUrl = await setSearchUrl({ type: 'original', pocId: poc.id, address: poc.pdrListAddress, door: door, url: null, street: street, city: poc.city.sname, province: poc.province.sname, postalCode: poc.pdrListPostalCode })
      searchArray.push(searchObjWithUrl)
      if (door.split('-').length > 1) {
        // search address whitout app
        door = doorOrigin.split('-')[1]
        searchObjWithUrl = await setSearchUrl({ type: 'originalNoApp', pocId: poc.id, address: poc.pdrListAddress.replace(doorOrigin, door), door: door, url: null, street: street, city: poc.city.sname, province: poc.province.sname, postalCode: poc.pdrListPostalCode })
        searchArray.push(searchObjWithUrl)
        if (door !== door.replace(/[^0-9]/g, '')) {
          // // console.log('trace 1 door = ' + door.replace(/[^0-9]/g, ''))
          searchObjWithUrl = await setSearchUrl({ type: 'originalNoAppNoSuffix', pocId: poc.id, address: poc.pdrListAddress.replace(doorOrigin, door.replace(/[^0-9]/g, '')), door: door.replace(/[^0-9]/g, ''), url: null, street: street, city: poc.city.sname, province: poc.province.sname, postalCode: poc.pdrListPostalCode })
          searchArray.push(searchObjWithUrl)
        }
      } else {
        if (door !== door.replace(/[^0-9]/g, '')) {
          // // console.log('trace 2')
          searchObjWithUrl = await setSearchUrl({ type: 'originalNoAppNoSuffix', pocId: poc.id, address: poc.pdrListAddress.replace(doorOrigin, door.replace(/[^0-9]/g, '')), door: door.replace(/[^0-9]/g, ''), url: null, street: street, city: poc.city.sname, province: poc.province.sname, postalCode: poc.pdrListPostalCode })
          searchArray.push(searchObjWithUrl)
        }
      }
      if (poc.oldAddress) {
        // // console.log(poc.oldAddress)
        for (let i = 0; i < poc.oldAddress.length; i++) {
          const old = poc.oldAddress[i]
          var url
          var address
          // // console.log('old door = ' + old.civic)
          // // console.log('old street = ' + old.street)
          if (old.suit) {
            address = old.suit + '-' + old.civic + old.suffix + ' ' + old.street
            searchObjWithUrl = await setSearchUrl({ type: 'harmonize', pocId: poc.id, address: address, door: old.suit + '-' + old.civic + old.suffix, url: url, street: old.street, city: old.city, province: poc.province.sname, postalCode: old.postalCode })
            searchArray.push(searchObjWithUrl)
          }
          address = old.civic + old.suffix + ' ' + old.street
          searchObjWithUrl = await setSearchUrl({ type: 'harmonizeNoApp', pocId: poc.id, address: address, door: old.civic + old.suffix, url: url, street: old.street, city: old.city, province: poc.province.sname, postalCode: old.postalCode })
          searchArray.push(searchObjWithUrl)
          if (old.suffix) {
            address = old.civic + ' ' + old.street
            searchObjWithUrl = await setSearchUrl({ type: 'harmonizeNoAppNoSuffix', pocId: poc.id, address: address, door: old.civic, url: url, street: old.street, city: old.city, province: poc.province.sname, postalCode: old.postalCode })
            searchArray.push(searchObjWithUrl)
          }
        }
        // searchArray.push(await setSearchUrl({ type: 'old', pocId: poc.id, door: door, url: null, street: oldStreet, city: oldCity, province: poc.province.sname, postalCode: poc.oldAddress.postalCode }))
      }
      async function setSearchUrl (obj) {
        obj.url = obj.address + ', ' + obj.city + ', ' + obj.province + ' ' + obj.postalCode + ', Canada'
        return obj
      }
      return searchArray
    }
  },
  computed: {
    pocToCorrect () {
      return this.$store.getters['pocModule/getAllPoc'].filter(x => !x.googleFound).sort(function (a, b) {
        return a.pdrListPostalCode < b.pdrListPostalCode
      })
    },
    items () {
      if (this.routeFilter !== 'all') {
        return this.pocToCorrect.filter(x => (x.route.indexOf(this.routeFilter) > -1))
      } else {
        return this.pocToCorrect
      }
    },
    routeItems () {
      const route = [{ value: 'all', text: this.$t('common.all') }]
      this.pocToCorrect.forEach(poc => {
        poc.route.forEach(routeId => {
          if (route.map(x => { return x.value }).indexOf(routeId) < 0) {
            route.push({ value: routeId, text: this.$store.state.routeModule.data[routeId].name })
          }
        })
      })
      return [{ value: 'all', text: this.$t('common.all') }].concat(route.sort(function (a, b) {
        return a.text > b.text
      }))
    }
  },
  mounted () {
    // this.loadingTitle = this.$t('common.loading')
    // this.showLoading = true
    // // this.$store.dispatch('pocModule//fetchAndAdd', { clauses: { limit: 0 } })
    // dispatch('pocModule/setPathVars', { officeId: this.$store.state.userLoggedModule.data.officeselected })
    // const where = [['googleFound', '==', false]]
    this.showLoading = true
    this.$store.dispatch('pocModule/setPathVars', { officeId: this.$store.state.userLoggedModule.data.officeselected })
    // this.$store.dispatch('pocModule/fetchAndAdd', { where: [['googleFound', '==', false]] })
    this.$store.dispatch('pocModule/openDBChannel', { where: [['googleFound', '!=', true]] })
      .then(() => {
        this.showLoading = false
        // // console.log('nbr poc loaded = ' + this.$store.getters['pocModule/numberOfPoc'])
        // // console.log(this.items)
        this.isDataLoaded = true
        // this.$refs.inputUpload.click()
      })
  },
  destroyed () {
    this.$store.dispatch('pocModule/closeDBChannel', { clearModule: true })
    this.$store.dispatch('pocModule/setPathVars', { officeId: this.$store.state.userLoggedModule.data.officeselected })
  }
}
</script>
<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
