<template>
    <v-card>
        <v-navigation-drawer app v-model='ldrawer'  temporary>
            <template v-slot:prepend>
                <v-list>
                    <v-list-item>
                        <v-row no-gutters>

                          <!-- <v-img
                            @click="ldrawer = false"
                            alt="CanadaPost Logo"
                            contain
                            :src="require('@/assets/Icon/MenuIcon/logo_postes_canada.png')"
                            transition="scale-transition"
                            height="70"
                            width="70"
                          /> -->
                          <v-btn color="red" @click="ldrawer = false" icon x-large>
                            <v-icon color="primary" :size="50">mdi-backburger</v-icon>
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-menu>
                            <template v-slot:activator="{ on: menu }">
                              <v-list width="70%">
                                <v-list-item
                                  link
                                  v-on="{ ...menu }"
                                >
                                  <v-list-item-content>
                                      <v-list-item-subtitle class="subtitle">{{ $store.state.userLoggedModule.data.empid }}</v-list-item-subtitle>
                                      <v-list-item-subtitle class="subtitle">{{ $store.state.userLoggedModule.data.lname }}, {{ $store.state.userLoggedModule.data.fname }}</v-list-item-subtitle>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                      <v-icon>mdi-menu-down</v-icon>
                                  </v-list-item-action>
                                </v-list-item>
                              </v-list>
                            </template>
                            <v-list>
                              <v-list-item
                                class="pt-2"
                                @click="logout()"
                              >
                                <v-list-item-title>{{ $t('nav_drawer.useritemlogout') }}</v-list-item-title>
                              </v-list-item>
                              <v-list-item
                                @click="showUserParam = true"
                              >
                                <v-list-item-title>{{ $t('common.parameters') }}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-row>
                    </v-list-item>

                    <v-list-item>
                      <!-- <v-list-item-title class="title">{{ $store.state.officeSelectedModule.data.name }}</v-list-item-title> -->
                      <v-list-item-title class="title">
                        <v-select
                          v-model="officeSeletedItem"
                          :items="$store.getters['officeModule/getOfficeItems']"
                          :label="$t('common.office')"
                          @change="changeOfficeSelect($event)"
                          filled
                        ></v-select>
                      </v-list-item-title>
                        <!-- <v-list-item-action>
                            <v-icon>mdi-menu-down</v-icon>
                        </v-list-item-action> -->
                    </v-list-item>
                    <v-list-item v-if="officeSelectedId">
                      <v-select
                        :value="routeSelectedId"
                        :items="$store.getters['routeModule/getRouteIdItems']"
                        :label="$t('common.route')"
                        @change="changeRouteSelect($event)"
                        filled
                      ></v-select>
                    </v-list-item>
                    <v-list-item v-if="routeSelectedId && $store.getters['routeModule/getSequenceItemsOfRouteId'](routeSelectedId).length > 1">
                      <v-select
                        v-model="sequenceSeletedItem"
                        :items="$store.getters['routeModule/getSequenceItemsOfRouteId'](routeSelectedId)"
                        :label="$t('common.sequence')"
                        @change="changeSequenceSelect($event)"
                        filled
                      ></v-select>
                    </v-list-item>
                </v-list>
            </template>

            <v-divider></v-divider>

            <v-list nav shaped>
              <v-list-item
                class="text-center"
                v-for="item in items"
                :key="item.title"
                link
                @click="selectitem(item.link)"
              >
                <v-list-item-icon><v-icon x-large color="primary"> {{ item.icon }}</v-icon></v-list-item-icon>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              <!-- <v-list-item-content class="mx-auto" @click="selectitem('pmt-road-import')" clearfix>
                <v-container v-if="$store.getters.contentHeight >= 660">
                  <v-icon :size="$store.getters.menuIconSize" color="primary"> {{ item.icon }}</v-icon>
                  <v-list-item-title class="subtitle text-center">{{ item.title }}</v-list-item-title>
                </v-container>
                <v-container v-if="$store.getters.contentHeight < 660 ">
                  <v-row>
                  <v-flex xs6>
                    <v-list-item-title class="subtitle text-center mt-3">{{ item.title }}</v-list-item-title>
                  </v-flex>
                  <v-flex xs6>
                    <v-icon :size="$store.getters.menuIconSize" color="primary"> {{ item.icon }}</v-icon>
                  </v-flex>
                  </v-row>
                </v-container>
              </v-list-item-content> -->
                <!--
                <v-list-item-content  class="mx-auto" clearfix>
                  <v-row>
                  <div height="100px" width="100px" class="mx-auto">
                    <v-img
                      :src="item.icon"
                      alt="contact"
                      height="100"
                      width="100"
                    ></v-img>
                  </div>
                  <v-list-item-title class="title">{{ item.title }}</v-list-item-title>
                  </v-row>
                </v-list-item-content>
                -->
              </v-list-item>
           </v-list>
        </v-navigation-drawer>
        <user-param-popup
          v-if="showUserParam"
          @close="showUserParam = false"
        ></user-param-popup>
        <confirm-popup
          v-if="showConfirmOfficeChange"
          @cancel="changeOfficeCancel()"
          @confirm="changeOffice($event)"
          :title="confirmTitle"
          :from="confirmFrom"
          :to="confirmTo"
          :item="confirmNewId"
        ></confirm-popup>
        <confirm-popup
          v-if="showConfirmRouteChange"
          @cancel="changeRouteCancel()"
          @confirm="changeRoute($event)"
          :title="confirmTitle"
          :from="confirmFrom"
          :to="confirmTo"
          :item="confirmNewId"
        ></confirm-popup>
        <!-- <pmt-loading-local :loadingTitle="showLoadingTitle" :loadingShow="showLoading"></pmt-loading-local> -->
    </v-card>
</template>
<script>
export default {
  name: 'NavDrawer',
  props: {
    drawer: Boolean,
    username: String,
    email: String,
    avatar_path: String
  },
  data () {
    return {
      ldrawer: false,
      right: null,
      // routeSeletedItem: null,
      sequenceSeletedItem: null,
      officeSeletedItem: null,
      showConfirmOfficeChange: false,
      showConfirmRouteChange: false,
      confirmTitle: null,
      confirmFrom: null,
      confirmTo: null,
      confirmNewId: null,
      showLoading: false,
      showUserParam: false,
      showLoadingTitle: null
    }
  },
  methods: {
    selectitem (choice) {
      this.$emit('menu-item-selected', choice)
      this.ldrawer = false
    },
    changeOfficeSelect (item) {
      if (item.id !== this.officeSelectedId) {
        this.confirmTitle = this.$t('common.changeofficeconfirm')
        this.confirmFrom = this.$store.state.officeSelectedModule.data.name
        this.confirmTo = item.name
        this.confirmNewId = item.id
        this.showConfirmOfficeChange = true
      }
    },
    changeRouteSelect (item) {
      if (item !== this.routeSelectedId) {
        this.confirmTitle = this.$t('common.changerouteconfirm')
        this.confirmFrom = this.routeSelectedId ? this.$store.state.routeModule.data[this.routeSelectedId].name : null
        this.confirmTo = this.$store.state.routeModule.data[item].name
        this.confirmNewId = item
        this.showConfirmRouteChange = true
      }
    },
    changeSequenceSelect (item) {
      if (item.id !== this.this.sequenceSelectedId) {
        this.$store.dispatch('changeSequence', item.id)
      }
    },
    changeRoute_back (id) {
      this.showConfirmRouteChange = false
      this.showLoadingTitle = this.$t('common.loading')
      this.showLoading = true
      this.$store.commit('userLoggedModule/setTravelSelected', null)
      this.$store.dispatch('postmanChangeRoute', id).then(() => {
        const userSelection = {
          zone: this.$store.getters.getSelectedZoneId,
          office: this.officeSelectedId,
          route: id,
          color: null,
          code: null,
          street: null,
          cp: null,
          place: null,
          pdr: null,
          travelselected: null
        }
        this.$store.dispatch('userLoggedModule/updateSelectionField', userSelection).then(() => {
          this.showLoading = false
        })
      })
    },
    changeRoute (id) {
      this.showConfirmRouteChange = false
      this.showLoadingTitle = this.$t('common.loading')
      this.showLoading = true
      this.$store.dispatch('userLoggedModule/updateMapMode', 'route-info')
      this.$store.dispatch('userLoggedModule/updateSelectionField', {
        color: null,
        code: null,
        street: null,
        cp: null,
        place: null,
        pdr: null,
        travelselected: null
      })
      this.$store.dispatch('postmanChangeRoute', id).then(() => {
        const userSelection = {
          zone: this.$store.getters.getSelectedZoneId,
          office: this.officeSelectedId,
          route: id,
          color: null,
          code: null,
          street: null,
          cp: null,
          place: null,
          pdr: null,
          travelselected: null
        }
        this.$store.dispatch('userLoggedModule/updateSelectionField', userSelection).then(() => {
          this.showLoading = false
        })
      })
    },
    changeOffice (id) {
      this.showConfirmOfficeChange = false
      this.$store.dispatch('changeOffice', id)
    },
    changeOfficeCancel () {
      this.showConfirmOfficeChange = false
      this.$store.getters['officeModule/getOfficeItems'].forEach(item => {
        if (item.value.id === this.officeSelectedId) { this.officeSeletedItem = item }
      })
    },
    changeRouteCancel () {
      this.showConfirmRouteChange = false
      // this.$store.getters['routeModule/getRouteItems'].forEach(item => {
      //   if (item.value.id === this.routeSelectedId) { this.routeSeletedItem = item }
      // })
    },
    logout () {
      // this.$router.push('/login')
      this.$store.dispatch('logout')
    }
  },
  computed: {
    items () {
      return [
        { title: this.$t('nav_drawer.messageboard'), icon: 'mdi-forum', link: 'pmt-pm-babillard' },
        { title: this.$t('nav_drawer.map'), icon: 'mdi-google-maps', link: 'maps-user' },
        { title: this.$t('nav_drawer.tools'), icon: 'mdi-tools', link: 'pmt-pm-tool' }
      ]
    },
    routeSelectedId () {
      return this.$store.getters.getSelectedRouteId
    },
    officeSelectedId () {
      return this.$store.getters.getSelectedOfficeId
    },
    sequenceSelectedId () {
      return this.$store.getters.getSelectedSequenceId
    }
  },
  created () {
    // if (this.routeSelectedId) { this.routeSeletedItem = this.$store.getters['routeModule/getRouteItemOfRouteId'](this.routeSelectedId) }
    this.$store.getters['officeModule/getOfficeItems'].forEach(item => {
      if (item.value.id === this.officeSelectedId) { this.officeSeletedItem = item }
    })
  },
  watch: {
    officeSelectedId (newValue) {
      this.officeSeletedItem = null
      this.$store.getters['officeModule/getOfficeItems'].forEach(item => {
        if (item.value.id === newValue) { this.officeSeletedItem = item }
      })
    },
    // routeSelectedId (newValue) {
    //   this.routeSeletedItem = null
    //   this.$store.getters['routeModule/getRouteItems'].forEach(item => {
    //     if (item.value.id === newValue) { this.routeSeletedItem = item }
    //   })
    // },
    sequenceSeletedId (newValue) {
      this.sequenceSeletedItem = null
      if (newValue) {
        const seqArray = this.$store.getters['routeModule/getSequenceItemsOfRouteId'](this.routeSelectedId)
        seqArray.forEach(seq => {
          if (seq.id === newValue) { this.sequenceSeletedItem = seq }
        })
      }
    },
    ldrawer (newValue) {
      if (newValue !== this.drawer) {
        this.$emit('drawer-state-changed', newValue)
      }
    },
    drawer (newValue) {
      this.ldrawer = newValue
    }
  }
}
</script>
