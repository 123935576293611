<template>
  <!-- <div class="home"> -->
    <v-list-item :dense="!notDense" justify="center" id="test" style="overflow-hidden" max-width="100%" width="100%">
      <v-list-item-icon id="iconZone" v-if="isItemPngIcon(item) || isItemIcon(item)" class="ms-n3">
        <v-icon v-if="draggable && !item.moveLock" class="handle">mdi-drag-vertical</v-icon>
        <!-- <v-icon color="red" v-if="item.type === 'wp'">mdi-map-marker</v-icon> -->
        <v-icon
          class="justify-center"
          v-if="isItemIcon(item)"
          :color="$store.getTravelItemColor(item)"
        >{{ getItemIcon(item) }}</v-icon>
        <img
          v-show="isItemPngIcon(item)"
          :src="getItemPngIcon(item)"
          alt="item"
          class="me-n3"
          contain
          height="24"
          width="20"
        >
      </v-list-item-icon>
      <v-list-item-content three-line>
        <v-list-item-title :color="$store.getTravelItemColor(item)" id="item-title" v-text="getItemTitle(item)" :class="getItemTitleClass(item) + ' text-wrap'"></v-list-item-title>
        <v-list-item-subtitle v-if="isItemSubTitle1(item)" id="item-subtitle1" v-text="getItemSubTitle1(item)" :class="getItemSubTitle1Class(item)"></v-list-item-subtitle>
        <v-list-item-subtitle v-if="isItemSubTitle2(item)" id="item-subtitle2" v-text="getItemSubTitle2(item)" :class="getItemSubTitle2Class(item)"></v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-if="showAction" class="me-n2">
        <stats-popup v-if="isShowItemStats(item)" :pocItems="getPocOfGroup(item.items)" :title="getItemTitle(item)"></stats-popup>
        <v-btn v-if="item.type === 'poc'" @click="showEditPdr = item.id" icon><v-icon>mdi-cogs</v-icon></v-btn>
        <v-btn v-if="item.type === 'colis'" @click="showEditPdr = item.pocId" icon><v-icon>mdi-cogs</v-icon></v-btn>
        <v-btn v-if="isCanCloseItem(item) || canBeRemove" @click="removeItem(item)" icon><v-icon color="red">mdi-close-circle</v-icon></v-btn>
      </v-list-item-action>
      <v-list-item-action v-else-if="canBeRemove" class="me-n2">
        <v-btn @click="removeItem(item)" icon><v-icon color="red">mdi-close-circle</v-icon></v-btn>
      </v-list-item-action>
      <!-- <v-list-item-icon v-if="item.type === 'poc'"><v-btn @click="showEditPdr = item.id" icon><v-icon>mdi-cogs</v-icon></v-btn></v-list-item-icon> -->
      <pmt-edit-pdr-popup
        v-if="showEditPdr"
        :pdrId="showEditPdr"
        @close="showEditPdr = null"
      ></pmt-edit-pdr-popup>
    </v-list-item>
  <!-- </div> -->
</template>

<script>
export default {
  name: 'TravelEditorItem',
  // props: ['item', 'params', 'showAction', 'canBeRemove', 'notDense'],
  props: {
    item: {
      type: Object,
      required: true
    },
    params: {
      type: Object
    },
    showAction: {
      type: Boolean,
      default: false
    },
    canBeRemove: {
      type: Boolean,
      default: false
    },
    notDense: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showEditPdr: null
    }
  },
  methods: {
    isShowItemStats (item) {
      if (item.items && item.items.length) {
        switch (item.type) {
          case 'sector':
          case 'color':
          case 'code':
          case 'multi':
          case 'wp':
          case 'step':
          case 'street':
          case 'cmb-direct':
          case 'colis-zone':
            return true
          default:
            return false
        }
      } else {
        return false
      }
    },
    getiItemsPath (idx) {
      const path = this.itemsPath
      path.push(idx)
      return path
    },
    removeItem (item) {
      this.$emit('removeItem', item)
    },
    getItemGroupActiveClass (item) {
      switch (item.type) {
        case 'color':
          return this.$store.getColorName(item.colorId) + ' lighten-2'
        case 'sector':
          return (Number(this.$store.getTravelItemColorId(item)) ? this.$store.getTravelItemColor(item) : 'grey') + ' lighten-4'
        case 'code':
          return this.$store.getColorName(item.colorId) + ' lighten-4'
        case 'multi':
          return 'grey lighten-2'
        case 'step':
          return 'grey lighten-2'
        default:
          return null
      }
    },
    isCanCloseItem (item) {
      switch (item.type) {
        case 'greyBox':
          return true
        case 'redBox':
          return true
        case 'parcelLocker':
          return true
        case 'wp':
          if (this.canBeRemove) {
            return true
          } else {
            return false
          }
        case 'taxi-start':
        case 'taxi-finish':
        case 'taxi-direct':
          return true
        default:
          return false
      }
    },
    isItemIcon (item) {
      switch (item.type) {
        case 'poc':
        case 'wp':
        case 'colis-zone':
        case 'cmb-direct':
          return true
        default:
          return false
      }
    },
    getItemIcon (item) {
      switch (item.type) {
        case 'poc':
          return 'mdi-home'
        case 'colis-zone':
          return 'mdi-vector-triangle'
        case 'wp':
        case 'cmb-direct':
          return 'mdi-map-marker'
        default:
          return 'mdi-information'
      }
    },
    isItemPngIcon (item) {
      switch (item.type) {
        case 'colis-zone':
        case 'colis':
        case 'colis-list':
        case 'cmb-direct':
        case 'taxi-start':
        case 'taxi-finish':
        case 'taxi-direct':
        case 'greyBox':
        case 'redBox':
        case 'parcelLocker':
        case 'multi':
          return true
        case 'wp':
          if (item.wp && item.wp.type) {
            return true
          } else {
            return false
          }
        default:
          return false
      }
    },
    getItemPngIcon (item) {
      switch (item.type) {
        case 'poc':
          return this.$store.getMapItemIconOfType(this.$store.state.pocModule.data[item.id].tpType)
        case 'greyBox':
          return this.$store.getMarkerIconSpec(this.$store.getters.getGoogle, { type: 'greyBoxMarker' }).url
        case 'multi':
          return this.$store.getMapItemIconOfType(item.multiType)
        case 'redBox':
          return this.$store.getMarkerIconSpec(this.$store.getters.getGoogle, { type: 'redBoxMarker' }).url
        case 'parcelLocker':
          return this.$store.getMarkerIconSpec(this.$store.getters.getGoogle, { type: 'parcelLockerMarker' }).url
        case 'cmb-direct':
        case 'wp':
          if (item.wp && item.wp.type) {
            return this.$store.getMapItemIconOfType(item.wp.type)
          } else {
            return null
          }
        case 'colis-list':
          return this.$store.getMapItemIconOfType('colis')
        case 'colis-zone':
        case 'colis':
        case 'taxi-start':
        case 'taxi-finish':
        case 'taxi-direct':
          return this.$store.getMapItemIconOfType(item.type)
        default:
          return null
      }
    },
    getItemTitleClass (item) {
      switch (item.type) {
        case 'code':
          return 'font-weight-black black--text'
        case 'greyBox':
          return 'text-black black--text'
        case 'redBox':
          return 'text-black black--text'
        case 'parcelLocker':
          return 'text-black black--text'
        case 'poc':
          return this.$store.getColorText(item.colorId)
        case 'start-taxi':
          return 'text-black'
        case 'multi':
          return 'font-weight-black'
        case 'color':
          return 'font-weight-black'
        case 'sector':
          return this.$store.getColorText(this.$store.getTravelItemColorId(item)) + ' font-weight-black'
        case 'wp':
          return this.$store.getColorText(this.$store.getTravelItemColorId(item)) + ' font-weight-black'
          // return 'font-weight-black'
        case 'cmb-direct':
        case 'colis-zone':
        case 'step':
          return 'font-weight-black'
        default:
          return null
      }
    },
    getItemTitle (item) {
      // let stat
      switch (item.type) {
        case 'sector':
          if (item.sectorId) {
            return (item.sectorId === 1) ? (this.$t('travel-group.sector') + ' 1 (' + this.$t('common.start') + ')') : (this.$t('travel-group.sector') + ' ' + item.sectorId) + ' ' + (item.name ? item.name : '')
          } else {
            return this.$t('travel-group.sector') + ' ' + item.name
          }
          // return (item.sectorId === 1) ? (this.$t('travel-group.sector') + ' 1 (' + this.$t('common.start') + ')') : (this.$t('travel-group.sector') + ' ' + item.sectorId) + ' ' + item.name
        case 'cmb-direct':
        case 'colis-zone':
        case 'step':
          return this.$t('common.step') + ' : ' + item.pos
        case 'street':
          return item.street
        case 'cp':
          return item.cp
        case 'multi':
          // return this.getMultiName(item.multiId)
          return this.$t('pocitem.' + this.$store.state.officeItemMultiModule.data[item.multiId].type)
          // return this.$store.state.officeItemMultiModule.data[item.multiId].name ? this.$store.state.officeItemMultiModule.data[item.multiId].name : this.$store.state.officeItemMultiModule.data[item.multiId].desc
        case 'code':
          return 'Code: ' + item.codeName
          // stat = this.$store.getters.getCumulTotalStatFromPocIdArray(this.getPocOfGroup(item.items).map(x => { return x.id }))
          // return stat.pub + ' / ' + stat.pocActive + ' (' + stat.vacant + ')'
          // return 'Code: ' + item.codeName + ' , PDR: ' + stat.pub + ' / ' + stat.pocActive + ' (' + stat.vacant + ')'
        case 'color':
          return this.$t('travel.color-' + item.colorId)
        case 'poc':

          return this.$store.state.pocModule.data[item.id].pdrListAddress
        case 'start-taxi':
          return this.$t('common.start')
        case 'greyBox':
          return item.name
        case 'redBox':
          return item.name
        case 'parcelLocker':
          return item.name
        case 'wp':
          if (item.wp) {
            if (item.wp.multiId) {
              return this.$t('pocitem.' + this.$store.state.officeItemMultiModule.data[item.wp.multiId].type)
            } else if (item.wp.type !== 'taxi-start' && item.wp.type !== 'park-loop' && item.wp.type !== 'park-direct') {
              return this.$t('pocitem.' + item.wp.type)
            } else {
              return this.$t('wp.' + item.wp.type + '-title')
            }
          } else {
            return 'WayPoint'
          }
        case 'colis-list':
          return this.$t('common.colis') + ' (' + item.items.length + ')'
        case 'colis':
          return item.pdrListAddress
        case 'poc-list':
          return this.$t('common.poc-long')
        case 'taxi-start':
        case 'taxi-finish':
        case 'taxi-direct':
          return this.$t('travelItem.' + item.type)
        default:
          // console.log('a faire item = ' + item.type)
          // // console.log(item)
          return 'a faire type:' + item.type
      }
    },
    getItemSubTitle1Class (item) {
      switch (item.type) {
        case 'code':
          return this.$store.getColorText(item.colorId)
        case 'start-taxi':
          return 'text-black'
        case 'colis-zone':
        case 'cmb-direct':
          return 'font-weight-black'
        default:
          return null
      }
    },
    getItemSubTitle1 (item) {
      let stat
      switch (item.type) {
        case 'code':
          return this.$t('common.from') + ': ' + this.getFirstAddressOfStep(item)
        case 'start-taxi':
          return item.address
        case 'sector':
          stat = this.$store.getters.getCumulTotalStatFromPocIdArray(this.getPocOfGroup(item.items).map(x => { return x.id }))
          return stat.pub + ' / ' + stat.pocActive + ' (' + stat.vacant + ')'
        case 'color':
          stat = this.$store.getters.getCumulTotalStatFromPocIdArray(this.getPocOfGroup(item.items).map(x => { return x.id }))
          return stat.pub + ' / ' + stat.pocActive + ' (' + stat.vacant + ')'
        case 'greyBox':
          return this.$t('pmtparammapsinfo.greybox')
        case 'redBox':
          return this.$t('pmtparammapsinfo.redbox')
        case 'parcelLocker':
          return this.$t('pmtparammapsinfo.parcellocker')
        case 'multi':
          return this.getMultiName(item.multiId)
        case 'wp':
          if (item.wp.multiId) {
            return this.getMultiName(item.wp.multiId)
          } else if (item.wp.name) {
            return item.wp.name
          } else {
            return null
          }
        case 'cmb-direct':
          if (item.wp.multiId) {
            return this.$t('pocitem.' + this.$store.state.officeItemMultiModule.data[item.wp.multiId].type)
          } else if (item.wp.type !== 'taxi-start' && item.wp.type !== 'park-loop' && item.wp.type !== 'park-direct') {
            return this.$t('pocitem.' + item.wp.type)
          } else {
            return this.$t('wp.' + item.wp.type + '-title')
          }
        case 'colis-zone':
          return this.$t('travelItem.colis-zone')
        case 'poc-list':
          return this.$t('common.poc-long')
        case 'taxi-start':
        case 'taxi-finish':
        case 'taxi-direct':
          return item.name
        default:
          // // console.log(item)
          return 'a faire'
      }
    },
    isItemSubTitle1 (item) {
      switch (item.type) {
        case 'code':
        case 'start-taxi':
        case 'greyBox':
        case 'redBox':
        case 'sector':
        case 'color':
        case 'parcelLocker':
        case 'multi':
        case 'cmb-direct':
        case 'colis-zone':
          return true
        case 'wp':
          if (item.wp && (item.wp.name || item.wp.multiId)) {
            return true
          } else {
            return false
          }
        case 'taxi-start':
        case 'taxi-finish':
        case 'taxi-direct':
          if (item.name) {
            return true
          } else {
            return false
          }
        default:
          return false
      }
    },
    getItemSubTitle2Class (item) {
      switch (item.type) {
        case 'code':
          return this.$store.getColorText(item.colorId)
        case 'start-taxi':
          return 'text-black'
        default:
          return null
      }
    },
    getItemSubTitle2 (item) {
      switch (item.type) {
        case 'code':
          return this.$t('common.to') + ': ' + this.getLastAddressOfStep(item)
        case 'start-taxi':
          return null
        case 'cmb-direct':
          if (item.wp.multiId) {
            return this.getMultiName(item.wp.multiId)
          } else if (item.wp.name) {
            return item.wp.name
          } else {
            return null
          }
        case 'colis-zone':
          return this.getPocOfGroup(item.items).length + ' ' + this.$t('common.poc-long')
        default:
          return null
      }
    },
    isItemSubTitle2 (item) {
      switch (item.type) {
        case 'cmb-direct':
        case 'colis-zone':
        case 'code':
          return true
        case 'start-taxi':
          return false
        default:
          return false
      }
    },
    getLastAddressOfStep (step) {
      // const pocOfStep = step.items.filter(x => x.type === 'poc' )
      const pocOfStep = this.getPocOfGroup(step.items).sort(function (a, b) {
        return a.pos - b.pos
      })
      if (pocOfStep.length) {
        return this.$store.state.pocModule.data[pocOfStep[pocOfStep.length - 1].id].pdrListAddress
      } else {
        return null
      }
    },
    getFirstAddressOfStep (step) {
      // const pocOfStep = step.items.filter(x => x.type === 'poc')
      const pocOfStep = this.getPocOfGroup(step.items).sort(function (a, b) {
        return a.pos - b.pos
      })
      if (pocOfStep.length) {
        return this.$store.state.pocModule.data[pocOfStep[0].id].pdrListAddress
      } else {
        return null
      }
    },
    getPocOfGroup (group) {
      const pocItemsArray = []
      for (let i = 0; i < group.length; i++) {
        const item = group[i]
        if (item.items && item.items.length) {
          for (let x = 0; x < item.items.length; x++) {
            const xitem = item.items[x]
            if (xitem.items && xitem.items.length) {
              for (let y = 0; y < xitem.items.length; y++) {
                const yitem = xitem.items[y]
                if (yitem.items && yitem.items.length) {
                  for (let z = 0; z < yitem.items.length; z++) {
                    const zitem = yitem.items[z]
                    if (zitem.items && zitem.items.length) {
                      for (let z1 = 0; z1 < zitem.items.length; z1++) {
                        const z1item = zitem.items[z1]
                        if (z1item.items && z1item.items.length) {
                          // // console.log('trace erreur too much children')
                        } else {
                          if (z1item.type === 'poc') { pocItemsArray.push(z1item) }
                        }
                      }
                    } else {
                      if (zitem.type === 'poc') { pocItemsArray.push(zitem) }
                    }
                  }
                } else {
                  if (yitem.type === 'poc') { pocItemsArray.push(yitem) }
                }
              }
            } else {
              if (xitem.type === 'poc') { pocItemsArray.push(xitem) }
            }
          }
        } else {
          if (item.type === 'poc') { pocItemsArray.push(item) }
        }
      }
      return pocItemsArray
    },
    getMultiName (multiId) {
      const multi = this.$store.state.officeItemMultiModule.data[multiId]
      switch (multi.type) {
        case 'cmb':
          return multi.name ? multi.name + ' : ' + multi.desc : multi.siteId + ' : ' + multi.desc
        default:
          return multi.name ? multi.name : multi.desc
      }
    }
  },
  mounted () {
    if (this.item === 'colis') {
      // console.log(this.item)
    }
  }
}
</script>
<style>
  #iconZone {
    align-self: center !important;
  }
  #item-title {
    font-size: 1.1em;
    text-align: start;
  }
  #item-subtitle1 {
    font-size: 0.9em;
    text-align: start;
  }
  #item-subtitle2 {
    font-size: 0.9em;
    text-align: start;
  }
  @media screen and (max-width: 600px) {
    #item-title {
      font-size: 0.9em;
      text-align: start;
    }
    #item-subtitle1 {
      font-size: 0.8em;
      text-align: start;
    }
    #item-subtitle2 {
      font-size: 0.8em;
      text-align: start;
    }
  }
</style>
