<template>
  <v-row justify-center no-gutters>
    <v-card :style="$store.getters.contentStyle" class="overflow-y-auto overflow-x-hidden">
      <v-container
        fixedclass="overflow-y-auto overflow-x-hidden"
      >
        <v-list nav>
          <v-row no-gutters>
            <v-col cols="6">
              <v-list-item link>
              <v-list-item-content class="mx-auto" @click="selectitemTemp('create-user')" clearfix>
                  <v-icon :size="$store.getters.iconSize" color="primary">mdi-account-plus</v-icon>
                  <p two-line class="subtitle text-center">{{ $t('pmtmenuadminlocal.createuser') }}</p>
              </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="6" v-if="$store.getters.isSupportAdmin">
              <v-list-item link>
              <v-list-item-content class="mx-auto" @click="selectitem('create-office')" clearfix>
                  <v-icon :size="$store.getters.iconSize" color="primary">mdi-table-large-plus</v-icon>
                  <p two-line class="subtitle text-center">{{ $t('pmtmenuadminlocal.createoffice') }}</p>
              </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="6">
              <v-list-item link>
              <v-list-item-content class="mx-auto" @click="selectitemTemp('pmt-edit-rule')" clearfix>
                  <v-icon :size="$store.getters.iconSize" color="primary">mdi-shield-account</v-icon>
                  <p two-line class="subtitle text-center">{{ $t('pmtmenuadminlocal.userrule') }}</p>
              </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="6" v-if="$store.getters.isSupportAdmin">
              <v-list-item link>
              <v-list-item-content class="mx-auto" @click="selectitem('create-zone')" clearfix>
                  <v-icon :size="$store.getters.iconSize" color="primary">mdi-map-clock</v-icon>
                  <p two-line class="subtitle text-center">{{ $t('pmtmenuadminlocal.createzone') }}</p>
              </v-list-item-content>
              </v-list-item>
            </v-col>
            <!-- <v-col cols="6">
              <v-list-item link>
              <v-list-item-content class="mx-auto" @click="selectitem('pmt-road-import')" clearfix>
                  <v-icon :size="$store.getters.iconSize" color="primary"> mdi-database-import
                  </v-icon>
                  <p two-line class="subtitle text-center">{{ $t('pmtmenuadminlocal.importroute') }}</p>
              </v-list-item-content>
              </v-list-item>
            </v-col> -->
            <v-col cols="6">
              <v-list-item link>
              <v-list-item-content class="mx-auto" @click="showSubMenuImport = true" clearfix>
                  <v-icon :size="$store.getters.iconSize" color="primary"> mdi-database-import
                  </v-icon>
                  <p two-line class="subtitle text-center">{{ $t('pmtmenuadminlocal.importcsv') }}</p>
              </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="6">
              <v-list-item link>
              <v-list-item-content class="mx-auto" @click="selectitemTemp('pmt-correct-pdr')" clearfix>
                  <v-icon :size="$store.getters.iconSize" color="primary"> mdi-home-edit
                  </v-icon>
                  <p two-line class="subtitle text-center">{{ $t('pmtmenuadminlocal.correctpdr') }}</p>
              </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="6">
              <v-list-item link>
              <v-list-item-content class="mx-auto" @click="selectitemTemp('manage-contact-office')" clearfix>
                  <v-icon :size="$store.getters.iconSize" color="primary"> mdi-card-account-phone
                  </v-icon>
                  <p two-line class="subtitle text-center">{{ $t('pmtmenuadminlocal.managecontactoffice') }}</p>
              </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="6" v-if="$store.getters.isSupportAdmin">
              <v-list-item link>
              <v-list-item-content class="mx-auto" @click="selectitemTemp('manage-contact-zone')" clearfix>
                  <v-icon :size="$store.getters.iconSize" color="primary"> mdi-card-account-phone
                  </v-icon>
                  <p two-line class="subtitle text-center">{{ $t('pmtmenuadminlocal.managecontactzone') }}</p>
              </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="6">
              <v-list-item link>
              <v-list-item-content class="mx-auto" @click="selectitemTemp('pmt-route-manager')" clearfix>
                  <v-icon :size="$store.getters.iconSize" color="primary"> mdi-routes
                  </v-icon>
                  <p two-line class="subtitle text-center">{{ $t('pmtmenuadminlocal.routemanager') }}</p>
              </v-list-item-content>
              </v-list-item>
            </v-col>
            <!-- <v-col cols="6">
              <v-list-item link>
              <v-list-item-content class="mx-auto" @click="selectitemTemp('pmt-edit-route')" clearfix>
                  <v-icon :size="$store.getters.iconSize" color="primary"> mdi-file-edit
                  </v-icon>
                  <p two-line class="subtitle text-center">{{ $t('pmtmenuadminlocal.editroute') }}</p>
              </v-list-item-content>
              </v-list-item>
            </v-col> -->
            <!-- <v-col cols="6">
              <v-list-item link>
              <v-list-item-content class="mx-auto" @click="selectitemTemp('pmt-edit-rule')" clearfix>
                  <v-icon :size="$store.getters.iconSize" color="primary"> mdi-human-edit
                  </v-icon>
                  <p two-line class="subtitle text-center">{{ $t('pmtmenuadminlocal.editrule') }}</p>
              </v-list-item-content>
              </v-list-item>
            </v-col> -->
            <!-- <v-col cols="6">
              <v-list-item link>
              <v-list-item-content class="mx-auto" @click="selectitem('pmt-edit-route')" clearfix>
                  <v-icon :size="$store.getters.iconSize" color="primary"> mdi-database-edit
                  </v-icon>
                  <p two-line class="subtitle text-center">{{ $t('pmtmenuadminlocal.editroute') }}</p>
              </v-list-item-content>
              </v-list-item>
            </v-col> -->
            <!-- <v-col cols="6">
              <v-list-item link>
              <v-list-item-content class="mx-auto" @click="selectitem('pmt-admin-greybox')" clearfix>
                  <v-img
                    :src="require('@/assets/Icon/MapIcon/GreyBox.png')"
                    alt="Grey Box"
                    :max-height="$store.getters.iconSize"
                    contain
                    centered
                  ></v-img>
                  <p two-line class="subtitle text-center">{{ $t('pmt-edit-greybox.title') }}</p>
              </v-list-item-content>
              </v-list-item>
            </v-col> -->

          </v-row>
        </v-list>
        <pmt-menu-import-csv v-if="showSubMenuImport" @close="showSubMenuImport = false"></pmt-menu-import-csv>
      </v-container>

    </v-card>
  </v-row>
</template>

<script>
export default {
  props: ['contentstyles'],
  methods: {
    selectitem (choice) {
      this.$store.getters.EventBus.$emit('selectLocalAdminComponent', (choice))
    },
    selectitemTemp (choice) {
      this.$store.getters.EventBus.$emit('selectLocalAdminComponentTemp', (choice))
    }
  },
  data () {
    return {
      showSubMenuImport: false
    }
  }
}
</script>
