<template>
  <v-overlay :value="loadingShow" opacity="0.8" dark>
    <v-card-title>
      {{ loadingTitle }}
    </v-card-title>
    <v-row no-gutters>
      <v-spacer></v-spacer>
      <v-progress-circular
        :rotate="-90"
        :size="100"
        :value="Math.abs(progressValue)"
        :width="10"
      >{{ progressValue }}</v-progress-circular>
      <v-spacer></v-spacer>
    </v-row>
    <v-progress-linear
      indeterminate
    >
  </v-progress-linear>
</v-overlay>
  <!-- <div>
    <v-dialog v-model="loadingShow" persistent max-width="310" class="mx-auto" centered>
      <v-card class='mx-auto' centered>
          <v-card-title>
            {{ loadingTitle }}
          </v-card-title>
          <v-card-text centered>
            <v-row centered>
              <v-progress-circular
                :rotate="-90"
                :size="100"
                :value="progressValue"
                :width="15"
                color="primary"
              >{{ progressValue }}</v-progress-circular>
            </v-row>
            <v-progress-linear
              indeterminate
              color="primary"
            >
            </v-progress-linear>
          </v-card-text>
      </v-card>
    </v-dialog>
  </div> -->
</template>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
<script>
export default {
  props: ['loadingShow', 'progressValue', 'loadingTitle'],
  data () {
    return {
    }
  }
}
</script>
