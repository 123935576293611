<template>
  <div class="home">
      <pmt-pm-nav-drawer
        v-if="$store.getters.userLoadPartialReady"
        :username= "username"
        :email= "email"
        :avatar_path= "avatar_path"
        :drawer="drawerState"
        @drawer-state-changed="drawerState = $event"
        @menu-item-selected="$store.dispatch('userLoggedModule/updateCurrentNav', { comp: $event, kAlive: true })"
      >
      </pmt-pm-nav-drawer>
      <appBar
        :drawer="drawerState"
        :logout-route="logoutroute"
        :componentSelected="currentNav.comp"
        @drawer-state-changed="drawerState = $event">
      </appBar>
      <v-main id="content-zone">
        <keep-alive>
          <component
            v-if="currentNav.kAlive && $store.getters.postmanDataLoadCompleteReady"
            v-bind:is='currentNav.comp'
            :contentstyles="contentStyles"
            @menu-item-selected="$store.dispatch('userLoggedModule/updateCurrentNav', { comp: $event, kAlive: true })"
          ></component>
        </keep-alive>
        <component
          v-if="!currentNav.kAlive && $store.getters.postmanDataLoadCompleteReady"
          v-bind:is='currentNav.comp'
          :contentstyles="contentStyles"
          @menu-item-selected="$store.dispatch('userLoggedModule/updateCurrentNav', { comp: $event, kAlive: false })"
        ></component>
        <!-- <pmt-loading-local :loadingTitle="$t('common.loading')" :loadingShow="!$store.getters.postmanDataLoadCompleteReady"></pmt-loading-local> -->
        <guardian-danger-bottomsheet></guardian-danger-bottomsheet>
      </v-main>
    </div>
</template>

<script>
import vuetify from 'vuetify/lib'
import vue from 'vue'
import appBar from '../postman/PMT_PM_App_Bar.vue'
vue.use(vuetify)
export default {
  components: { appBar },
  props: {
    username: String,
    email: String,
    avatar_path: String,
    logoutroute: String
  },
  data () {
    return {
      drawerState: false,
      aliveComponent: true
    }
  },
  async mounted () {
    // scrolling off
    this.$store.dispatch('openPostmanDataCollection')
    window.scrollTo(0, 0)
    document.documentElement.style.overflow = 'hidden'
  },
  computed: {
    userIsAuthenticated () {
      if (this.$store.getters.user !== null && this.$store.getters.user !== undefined) {
        return true
      } else {
        return false
      }
    },
    contentStyles () {
      return this.$store.getters.contentStyle
    },
    toolbarHeight () {
      return this.$store.getters.appBarHeight + this.$store.getters.appBarExtendHeight
    },
    currentNav () {
      return this.$store.getters['userLoggedModule/getCurrentNav']
    },
    loadComplete () {
      return this.$store.getters.postmanDataLoadCompleteReady
    },
    loadPartial () {
      return this.$store.getters.postmanDataLoadPartialReady
    },
    loadUserComplete () {
      return this.$store.getters.isUserLoggedModuleLoadComplete
    }
  },
  watch: {
    loadUserComplete (newValue) {
      if (newValue && !this.loadPartial) {
        this.$store.commit('setMasterLoadingStatus', {
          showLoading: true,
          title: this.$t('loading-mess.load-sys'),
          subTitle: this.$t('loading-mess.load-route-data')
        })
      }
    },
    loadPartial (newValue) {
      if (newValue) {
        this.$store.commit('setMasterLoadingStatus', {
          showLoading: true,
          title: this.$t('loading-mess.load-sys'),
          subTitle: this.$t('loading-mess.load-route-data')
        })
      }
    },
    loadComplete (newValue) {
      if (newValue) {
        this.$store.commit('setShowMasterLoading', false)
      } else {
        this.$store.commit('setMasterLoadingStatus', {
          showLoading: true,
          title: this.$t('loading-mess.load-sys'),
          subTitle: this.$t('loading-mess.load-route-data')
        })
      }
    }
  },
  created () {
    // window.addEventListener('resize', this.myEventHandler())
    // window.onresize = this.myEventHandler
    window.scrollTo(0, 0)
    // document.documentElement.style.overflow = 'hidden'
  },
  destroyed () {
    // window.removeEventListener('resize')
  },
  methods: {
    myEventHandler () {
      this.$store.dispatch('resize')
    }
  }
}
</script>
<style scoped>
#content-zone {
  position: relative !important;
  top: 0px !important;
  overflow: hidden;
}
</style>>
