<template id="template" fixed>
  <v-row justify="center" id="row">
    <v-dialog v-model="dialog" id="dialog" fixed persistent fullscreen transition="dialog-bottom-transition">
        <v-card id="dialogCard" ref="dialogCard" overflow-hidden>
          <v-app-bar
            color="primary"
            dark
            height="60"
          >
            <v-app-bar-nav-icon v-if="$store.getters.isSupportAdmin && param.searchPocId">
              <!-- <v-btn icon x-large @click="getCurrentPosition">
                <v-icon x-large>mdi-mapbox</v-icon>
              </v-btn> -->
              <geocoder-search :pocId="param.searchPocId" @googleSearchResult="googleSearchResult($event)"></geocoder-search>
            </v-app-bar-nav-icon>
            <v-spacer v-if="$store.getters.isSupportAdmin"></v-spacer>
            <!-- <v-toolbar-title>{{ getMode === 'position' ? $t('pmtpositionmapset.titleposition') : $t('pmtpositionmapset.titledistance') }}</v-toolbar-title> -->

            <v-toolbar-title>{{ param.title ? param.title : (getMode === 'position' ? $t('pmtpositionmapset.titleposition') : $t('pmtpositionmapset.titledistance')) }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-app-bar-nav-icon>
              <v-btn icon x-large @click="getCurrentPosition">
                <v-icon x-large>mdi-crosshairs-gps</v-icon>
              </v-btn>
            </v-app-bar-nav-icon>

          </v-app-bar>
          <v-sheet
            id="sheet"
            ref="sheet"
            class="overflow-hidden"
          >
            <!-- <v-container class="mt-7"></v-container> -->
            <v-container v-show="!showSearch" ref="container" style="margin: 0px; padding: 0px; width: 100%">
              <div id="positionMaps" class="App" :style="mapStyles" overflow-hidden></div>
            </v-container>
            <v-container v-if="showSearch" >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('pmtplacepopupinfo.search')"
                single-line
                hide-details
                clearable
                autofocus
                @blur="$store.getters.EventBus.$emit('sizeChange')"
              ></v-text-field>
              <v-sheet class="overflow-y-auto">
                <v-list>
                  <v-list-item
                    v-for="item in pdrSearchList"
                    :key="item.id"
                    v-model="item.active"
                    @click="searchResult(item.id)"
                  >
                  <v-list-item-content>
                      <v-list-item-title :class="$store.getColorText(item.color)" v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                  <!--
                  <v-card>
                    <v-list-item-content>
                      <v-row class="mx-5">
                        <p display-4 >{{ getTypeDesc(item.type) }}</p>
                        <v-spacer/>
                        <p>{{ $t('common.pub') }} : </p>
                        <v-icon class="mt-n4 ms-1" color='green' v-if="item.pub">mdi-check-bold</v-icon>
                        <v-icon class="mt-n4 ms-1" color='red' v-if="!item.pub">mdi-cancel</v-icon>
                        <v-spacer/>
                        <p class="me-9">{{ item.vacant ? $t('pmtplacepopupinfo.vacant') : $t('pmtplacepopupinfo.occuped')  }}</p>
                      </v-row>
                      <v-row class="mx-5 my-3">
                        <v-btn class="me-7" icon x-large><v-icon x-large :color="$store.getColorCode(item.color)">mdi-cog-outline</v-icon></v-btn>
                        <v-spacer/>
                        <v-btn class="me-7" icon x-large><v-icon x-large color="red">mdi-alert</v-icon></v-btn>
                        <v-spacer/>
                        <v-btn class="me-7" @click="selectPdr(item)" icon x-large><v-icon x-large :color="$store.getColorCode(item.color)">mdi-google-maps</v-icon></v-btn>

                      </v-row>
                    </v-list-item-content>
                  </v-card>
                  -->
                  </v-list-item>
                </v-list>
              </v-sheet>
            </v-container>
          </v-sheet>
          <v-app-bar
            bottom
            color="primary"
            dark
            height="60"
          >
            <v-app-bar-nav-icon>
              <v-btn x-large icon @click="back">
                <v-icon x-large>mdi-arrow-left-circle</v-icon>
              </v-btn>
            </v-app-bar-nav-icon>
            <v-spacer></v-spacer>
            <v-btn v-if="getMode === 'position' && showPdrSelect" @click="showSearch = !showSearch" class="primary darken-4">
                {{ $t('common.pdr') }}
            </v-btn>
            <v-container v-if="getMode === 'distance'">
              <!-- <v-text-field
                ref="textDistance"
                id="textDistance"
                class="mx-auto subtitle-1"
                solo
                flat
                prefix="Distance (M) : "
                v-model.number="distance"
                hide-details
                single-line
                background-color="primary"
                @blur="validateDistance"
                type="number"
              /> -->
              <p class="subtitle-1 mt-3" align="center">Distance (M) : {{ distance }}</p>
            </v-container>

            <v-spacer></v-spacer>
            <v-app-bar-nav-icon>
              <v-btn x-large v-if="nextButton" :disabled="!markerPos" icon @click="next">
                <v-icon x-large>mdi-arrow-right-circle</v-icon>
              </v-btn>
              <v-btn x-large v-if="checkButton" :disabled="!markerPos" icon @click="check">
                <v-icon x-large>mdi-check</v-icon>
              </v-btn>
            </v-app-bar-nav-icon>
          </v-app-bar>
        </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ['startCenterPdrId', 'param'],
  data () {
    return {
      map: null,
      showSearch: false,
      distance: Number,
      pdrMarker: null,
      pocOwner: null,
      getMode: 'position',
      getPdrPosMode: false,
      showPdrSelect: false,
      soloPdr: false,
      markerPos: null,
      marker: null,
      zoneMarker: null,
      dialog: true,
      notifications: false,
      search: ''
    }
  },
  mounted () {
    this.distance = 20
    // if (this.$refs.container) {
    //   this.mapHeight = this.$refs.container.clientHeight
    //   this.mapWidth = this.$refs.container.clientWidth
    // }
    if (!this.param.icon) {
      if (!this.param.isCreateMode) {
        this.showPdrSelect = true
      }
      this.getPdrPosMode = true
    }
    this.setMap()
    if (this.startCenterPdrId) { this.searchResult(this.startCenterPdrId) }
  },
  created () {
    // this.$store.dispatch('refreshCurrentPosition')
    window.addEventListener('resize', () => {
      this.resize()
    })
  },
  watch: {
    getMode (newValue) {
      if (newValue === 'position') {
        this.zoneMarker.setMap(null)
      } else {
        if (this.map) {
          this.zoneMarker.setMap(this.map)
          this.map.setCenter(this.markerPos)
        }
      }
    },
    markerPos (newValue) {
      if (newValue) {
        if (this.getPdrPosMode) {
          this.pdrMarker.setPosition(newValue)
        } else {
          this.marker.setPosition(newValue)
          this.marker.setMap(this.map)
          this.zoneMarker.setCenter(newValue)
        }
      } else {
        this.marker.setMap(null)
      }
    },
    distance (newValue) {
      this.validateDistance()
    },
    dialog (newValue) {
      if (!newValue) { this.$store.getters.EventBus.$emit('sizeChange') }
    }
  },
  computed: {

    mapHeight () { return this.$store.getters.screenSize.height - 120 },
    mapWidth () { return this.$store.getters.screenSize.width },
    nextButton () {
      if (this.showDistance && this.getMode === 'position') {
        return true
      } else {
        return false
      }
    },
    checkButton () {
      if (!this.showDistance || this.getMode === 'distance') {
        return true
      } else {
        return false
      }
    },
    showDistance () {
      if (this.param.getDistance && this.markerPos) {
        return true
      } else {
        return false
      }
    },
    position () {
      if (this.param.centerPostition) {
        return this.param.centerPostition
      } else {
        if (this.$store.getters.getCurrentPosition) {
          return this.$store.getters.getCurrentPosition
        } else {
          const officeLoc = this.$store.state.officeSelectedModule.data.location[0].loc
          return { lat: officeLoc.latitude, lng: officeLoc.longitude }
        }
      }
    },
    mapStyles () {
      return 'width:' + this.mapWidth + 'px; height:' + this.mapHeight + 'px'
      // return 'width: 100vw;height: 100vh'
    },
    google () {
      return this.$store.getters.getGoogle
    },
    pictureSrc () {
      return this.$store.getters.getUserSelectOfficePictureSrc ? this.$store.getters.getUserSelectOfficePictureSrc : 'https://picsum.photos/1920/1080?random'
    },
    pdrList () {
      const pList = []
      const pdrListSorted = this.$store.getters['pocModule/getPocOfSequence'](this.$store.getters.getSelectedRouteId, this.$store.getters.getSelectedSequenceId)
      for (const pdr of pdrListSorted) {
        pList.push({ id: pdr.id, name: pdr.pdrListAddress, pub: pdr.pub, type: pdr.type, vacant: pdr.vacant, active: false, officeId: pdr.officeId, place_id: pdr.place_id, color: pdr.color, code: pdr.codeName })
      }
      return pList
    },
    codeSearchList () {
      return null
    },
    pdrSearchList () {
      const sList = []
      if (this.search) {
        let i = 0
        let cptPdr = 0
        while (cptPdr < 50 && i < this.pdrList.length) {
          const pdr = this.pdrList[i]
          if (pdr.name.search(this.search) >= 0) {
            sList.push(pdr)
            cptPdr++
          } else {
            pdr.active = false
          }
          i++
        }
      } else {
        let i = 0
        while (i < 50) {
          sList.push(this.pdrList[i])
          i++
        }
      }
      return sList
    }
  },
  methods: {
    googleSearchResult (result) {
      // // console.log(result)
      this.markerPos = result.homeLocation
      this.map.setCenter(result.homeLocation)
    },
    setMapBoundFromPositionArray (markerInfoArray) {
      const bounds = new this.$store.getters.getGoogle.maps.LatLngBounds()
      let cptPos = 0
      let lastPos = null
      markerInfoArray.forEach(marker => {
        if (marker.position) {
          if (marker.position.lat && marker.position.lng) {
            bounds.extend(marker.position)
            cptPos += 1
            lastPos = marker.position
          }
        } else {
          // // console.log('trace no position')
          // // console.log(marker)
        }
      })
      if (cptPos > 1) {
        this.map.fitBounds(bounds)
      } else {
        this.map.setCenter(lastPos)
        this.map.setZoom(20)
      }
    },
    searchResult (pdrId) {
      this.showSearch = false
      this.pocOwner = pdrId
      this.search = ''
      // const pos = this.$store.getters['pdrModule/getPdrLocation'](pdrId)
      // const position = this.$store.state.pocModule.data[pdrId].homeLocation
      if (this.pdrMarker) { this.pdrMarker.setMap(null) }
      this.pdrMarker = this.$store.getters['pocModule/getNewPocMarker'](pdrId, this.$store.getters.getGoogle)
      this.pdrMarker.setMap(this.map)
      this.map.setCenter(this.pdrMarker.getPosition())
    },
    getCurrentPosition () {
      const EventBus = this.$store.getters.EventBus
      EventBus.$on('getcurrentpositionerr', (message) => {
        this.$store.dispatch('showAlert', (message))
      })
      navigator.geolocation.getCurrentPosition(pos => {
        if (pos.coords) {
          EventBus.$emit('setCurrentPosition', { lat: pos.coords.latitude, lng: pos.coords.longitude })
        }
      }, err => {
        EventBus.$emit('getcurrentpositionerr', (err.message))
      })
    },
    validateDistance () {
      this.$store.dispatch('resize')
      if (this.distance) {
        if (typeof this.distance !== 'number') {
          this.distance = 20
        } else if (this.distance < 1) {
          this.distance = 1
        } else if (this.distance > 150) {
          this.distance = 150
        }
        if (!this.getPdrPosMode) { this.zoneMarker.setRadius(this.distance) }
      }
    },
    next () {
      if (this.showDistance) {
        this.getMode = 'distance'
      }
    },
    check () {
      this.$emit('return-check', { position: this.markerPos, distance: this.distance, pocOwner: this.pocOwner })
    },
    back () {
      if (this.getMode === 'position') {
        this.dialog = false
        this.$emit('return-close')
      } else {
        this.distance = 20
        this.getMode = 'position'
      }
    },
    resize () {
      window.scrollTo(0, 0)
      // if (this.map) {
      //   if (this.$refs.container) {
      //     this.mapHeight = this.$refs.container.clientHeight
      //     this.mapWidth = this.$refs.container.clientWidth
      //   }
      //   this.google.maps.event.trigger(this.map, 'resize')
      //   if (this.markerPos) {
      //     this.map.setCenter(this.markerPos)
      //   } else {
      //     this.map.setCenter(this.position)
      //   }
      // }
    },
    setMap () {
      // if (this.$refs.container) {
      //   this.mapHeight = this.$refs.container.clientHeight
      //   this.mapWidth = this.$refs.container.clientWidth
      // }
      if (this.google) {
        const EventBus = this.$store.getters.EventBus
        this.map = new this.google.maps.Map(document.getElementById('positionMaps'), {
          center: this.position,
          zoom: this.param.isCreateMode ? 17 : 19,
          styles: [
            {
              featureType: 'administrative',
              elementType: 'geometry',
              stylers: [
                {
                  visibility: 'off'
                }
              ]
            },
            {
              featureType: 'poi',
              stylers: [
                {
                  visibility: 'off'
                }
              ]
            },
            {
              featureType: 'road',
              elementType: 'labels.icon',
              stylers: [
                {
                  visibility: 'off'
                }
              ]
            },
            {
              featureType: 'transit',
              stylers: [
                {
                  visibility: 'off'
                }
              ]
            }
          ]
        })
        if (!this.getPdrPosMode) {
          this.marker = new this.google.maps.Marker({
            position: this.position,
            map: null,
            icon: { // url:mdi-toilet,
              url: this.param.icon,
              scaledSize: new this.google.maps.Size(48, 48), // scaled size
              origin: new this.google.maps.Point(0, 0), // origin
              anchor: new this.google.maps.Point(24, 24) // anchor
            }
          })
          this.zoneMarker = new this.google.maps.Circle({
            strokeColor: '#FF0000',
            strokeOpacity: 0.4,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0.35,
            map: null,
            center: this.markerPos,
            radius: this.distance // km -> M
          })
          this.zoneMarker.addListener('click', function (pos) {
            EventBus.$emit('position-map-click', pos)
          })
          if (this.param.markerStartPosition) {
            this.marker.setPosition(this.param.markerStartPosition)
            this.marker.setMap(this.map)
          }
        }
        this.map.addListener('click', function (pos) {
          EventBus.$emit('position-map-click', pos)
        })
        if (this.param.bounds) {
          this.map.fitBounds(this.param.bounds)
        }

        EventBus.$on('position-map-click', (pos) => {
          if (this.getMode === 'position') {
            this.markerPos = { lat: pos.latLng.lat(), lng: pos.latLng.lng() }
          } else {
            this.distance = this.$store.distance(this.markerPos, { lat: pos.latLng.lat(), lng: pos.latLng.lng() })
          }
        })
        EventBus.$on('setCurrentPosition', (pos) => {
          if (this.getMode === 'position') {
            this.markerPos = pos
            this.map.setCenter(pos)
          } else {
            this.distance = this.$store.distance(this.markerPos, pos)
          }
        })
        // this.map.addListener('zoom_changed', function () { EventBus.$emit('map_zoom_changed') })
      }
    },
    getTypeDesc (type) {
      let desc = ''
      switch (type) {
        case 'Hou/Dom':
          desc = this.$t('common.dom')
          break
        case 'Apt/App':
          desc = this.$t('common.app')
          break
        case 'Bus/com':
          desc = this.$t('common.bus')
          break
        default:
          desc = this.$t('common.frm')
      }
      return desc
    }
  }
}
</script>
<style>
  #textDistance {
    font-size: 1.4em;
  }
</style>
