<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="444" fixed transition="dialog-bottom-transition">
      <v-card>
        <v-app-bar dark color="primary">
          <v-btn icon x-large dark @click="dialog = false">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ routeName }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-app-bar>
        <v-list nav>
          <v-card-title>{{ title }}</v-card-title>
          <v-card-subtitle class="mb-n4"> {{ $t('matchpocsequence.sequencenotmatched') }} : {{ resultMatchArray.length }}</v-card-subtitle>
          <v-card-subtitle class="mt-n8"> {{ $t('matchpocsequence.pocnotused') }} : {{ toMatchPocItems.length }} <v-btn icon @click="showPocList = true" v-if="toMatchPocItems.length"><v-icon>mdi-eye</v-icon></v-btn></v-card-subtitle>
          <v-divider></v-divider>
          <v-list-item
            v-for="toMatch in resultMatchArray"
            :key="toMatch.address"
            >
            <v-list-item-content>
              <v-card>
                <v-card-title>
                {{ toMatch.address }}
                </v-card-title>
                <v-radio-group v-model="toMatch.choice">
                  <v-radio
                    :key="0"
                    :label="$t('matchpocsequence.notinclude')"
                  ></v-radio>
                  <v-radio
                    :key="1"
                    :disabled="toMatchPocItems.length ? false : true"
                    :label="$t('matchpocsequence.manualselectpoc')"
                  ></v-radio>
                </v-radio-group>
                <v-select
                  v-if="toMatch.choice"
                  outlined
                  filled
                  :label="$t('matchpocsequence.matching')"
                  v-model="toMatch.pocId"
                  :items="toMatchPocItems"
                ></v-select>
                <v-checkbox
                  class="mt-n3"
                  v-model="toMatch.replace"
                  v-if="toMatch.choice && toMatch.pocId"
                  :label="$t('matchpocsequence.replace')"
                ></v-checkbox>
                <!-- <v-btn v-if="toMatch.choice" block @click="confirmUpdatePoc(toMatch)" >correct poc</v-btn> -->
              </v-card>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <confirm-popup
          v-if="showConfirmUpdatePoc"
          @cancel="showConfirmUpdatePoc = false"
          @confirm="updatePoc($event)"
          :title="confirmTitle"
          :from="$store.state.pocModule.data[updatePocItem.pocId].pdrListAddress"
          :to="updatePocItem.address"
          :item="updatePocItem"
        ></confirm-popup>
      </v-card>
      <v-app-bar
        bottom
        color="primary"
        dark
      >
        <v-app-bar-nav-icon>
          <v-btn x-large icon @click="$emit('close')">
            <v-icon x-large>mdi-arrow-left-circle</v-icon>
          </v-btn>
        </v-app-bar-nav-icon>
        {{ $t('common.cancel') }}
        <v-spacer></v-spacer>
        {{ validMatch ? $t('common.next') : '' }}
        <v-app-bar-nav-icon v-if="validMatch">
          <v-btn x-large icon @click="$emit('complete', resultMatchArray)">
            <v-icon x-large>mdi-arrow-right-circle</v-icon>
          </v-btn>
        </v-app-bar-nav-icon>
      </v-app-bar>
      <pmt-showList-popup
        v-if="showPocList"
        @close="showPocList = false"
        @assign-item="$emit('assign-item', $event)"
        :itemList="toMatchPocItems"
        :seqList="seqList"
        listType="POC"
        :title="$t('matchpocsequence.pocnotused')"
        ></pmt-showList-popup>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ['title', 'routeName', 'toMatchAddress', 'toMatchPocId', 'seqList'],
  data () {
    return {
      showPocList: false,
      dialog: true,
      notifications: false,
      showConfirmUpdatePoc: false,
      updatePocItem: null,
      sound: true,
      confirmTitle: '',
      widgets: false,
      resultMatchArray: this.toMatchAddress,
      toMatchPocItems: []
    }
  },
  watch: {
    dialog (newValue) {
      this.dialog = false
      this.$store.dispatch('resize')
      this.$emit('close')
    }
  },
  mounted () {
    this.toMatchPocItems = []
    this.toMatchPocId.forEach(pocId => {
      const poc = this.$store.state.pocModule.data[pocId]
      this.toMatchPocItems.push({ text: poc.pdrListAddress, value: pocId })
    })
  },
  computed: {
    validMatch () {
      var valid = true
      this.resultMatchArray.forEach(item => {
        if (item.choice && !item.pocId) {
          valid = false
        }
      })
      return valid
    }
  },
  methods: {
    confirmUpdatePoc (item) {
      this.confirmTitle = this.$t('common.confirmupdatepoc')
      this.updatePocItem = item
      this.showConfirmUpdatePoc = true
    },
    updatePoc () {
      this.$store.dispatch('pocModule/set', { id: this.updatePocItem.pocId, pdrListAddress: this.updatePocItem.address })
        .then(() => {
          this.$store.dispatch('showMessage', this.$t('common.successUpdate'))
        })
    }
  }
}
</script>
