<template>
  <div class="home overflow-hidden" :style="$store.getters.contentStyle">
    <v-card>
      <v-card height="90" class="grey lighten-2">
        <v-card-title>
          <v-row no-gutters>
            <v-btn icon x-large @click="$store.getters.EventBus.$emit('selectLocalAdminComponent', ('pmt-menu-admin-local'))"><v-icon x-large>mdi-arrow-left-circle</v-icon></v-btn>
            <h3 class="mt-3">Menu</h3>
          </v-row>
        </v-card-title>
      </v-card>
      <v-sheet class="overflow-y-auto overflow-x-hidden" :height="$store.getters.contentHeight - 90" :max-height="$store.getters.contentHeight - 90">
        <v-list>
          <v-list-item
            v-for="(route) in items"
            :key="route.id"
          >
            <v-list-item-avatar>
              <v-icon :color="route.isActive ? 'success' : 'error'" large>{{ route.isActive ? 'mdi-check-bold' : 'mdi-alert-circle' }}</v-icon>
            </v-list-item-avatar>
              <v-list-item-title large>{{ route.name }}</v-list-item-title>
            <v-list-item-icon @click="showEditRoute = route.id"><v-icon color="primary" large>mdi-file-edit</v-icon></v-list-item-icon>
          </v-list-item>
        </v-list>
        <!-- <v-expansion-panels class="overflow-y-auto">
          <v-expansion-panel
            v-for="(route) in items"
            :key="route.id"
            dense
          >
            <v-expansion-panel-header>
              <v-card-title>
                {{ route.name }}
              </v-card-title>
              <v-spacer></v-spacer>
              <v-icon v-if="route.isActive" color="success" x-large>mdi-check-bold</v-icon>
              <v-icon v-else color="error" x-large>mdi-alert-circle</v-icon>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-row no-gutters>
                  <v-spacer></v-spacer>
                  <v-btn icon large><v-icon large>mdi-file-edit</v-icon></v-btn>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels> -->
      </v-sheet>
    </v-card>
    <loading-popup v-if="showLoading" :title="loadingTitle" :subTitle="loadingsubTitle"></loading-popup>
    <pmt-position-map-set
      v-if="showGetPosition"
      :param="{ title: $store.state.pocModule.data[selectPocId].pdrListAddress, icon: $store.getAlertPngIcon('house'), getDistance: false, isCreateMode: true}"
      @return-close="showGetPosition = false"
      @return-check="setPosition($event)"
    />
    <pmt-route-editor
      v-if="showEditRoute"
      :routeId="showEditRoute"
      @close="showEditRoute = null"
      @close-delete="deleteRoute($event)"
    ></pmt-route-editor>
  </div>
</template>

<script>
export default {
  props: ['title'],
  data () {
    return {
      isDataLoaded: true,
      showLoading: false,
      showGetPosition: false,
      showEditRoute: null,
      loadingTitle: this.$t('common.loading'),
      loadingsubTitle: '',
      selectPocId: null
    }
  },
  methods: {
    autoMatchPocOfRoute (route) {
      // for (let i = 0; i < route.sequence.length; i++) {
      //   const poc = route.sequence[i]
      // }
    },
    deleteRoute (data) {
      this.showEditRoute = null
      if (data.pocArray.length) {
        this.$store.dispatch('pocModule/deleteBatch', data.pocArray.map(x => { return x.id }))
      }
      if (data.pocArray.length) {
        this.$store.dispatch('placeModule/deleteBatch', data.placeArray.map(x => { return x.id }))
      }
      if (data.routeId) {
        this.$store.dispatch('routeModule/delete', data.routeId)
      }
      // // console.log(data)
    },
    searchPosPlaceId (pos) {
      const google = this.$store.getters.getGoogle
      const geocoder = new google.maps.Geocoder()
      const store = this.$store
      const pocId = this.selectPocId
      geocoder.geocode({ location: pos.position }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          const pocUpdate = {
            id: pocId,
            place_id: results[0].place_id,
            formatted_address: results[0].formatted_address,
            homeLocation: pos.position,
            googleFound: true,
            isActive: true
          }
          store.dispatch('pocModule/set', pocUpdate)
        } else {
          // there were no coordinates found for the address entered (or the address was not found)
          alert('Could not find your address ')
        }
      })
    },
    selected (item) {
      this.selectPocId = item.value.id
      this.showGetPosition = true
    },
    setPosition (pos) {
      this.showGetPosition = false
      this.searchPosPlaceId(pos)
    }
  },
  computed: {
    items () {
      return this.$store.getters['routeModule/getAllRoute']
    }
  },
  mounted () {
    this.isDataLoaded = true
  },
  destroyed () {
    // this.$store.dispatch('routeModule/closeDBChannel', { clearModule: true })
    // this.$store.dispatch('routeModule/setPathVars', { officeId: this.$store.state.userLoggedModule.data.officeselected })
  }
}
</script>
