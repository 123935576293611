<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fixed max-width="444" transition="dialog-bottom-transition">
      <v-card>
        <v-app-bar dark :color="color ? color : 'primary'">
          <v-btn icon x-large dark @click="onClose()">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-btn icon x-large v-if="canBeSave" dark @click="saveItem()" color="success">
            <v-icon x-large>mdi-content-save</v-icon>
          </v-btn> -->
        </v-app-bar>
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="4" align="center">
              <v-img
                v-if="type"
                :src="$store.getMapItemIconOfType(type)"
                alt="Item"
                max-height="50"
                contain
              ></v-img>
            </v-col>
            <v-col cols="8" align="start">
              <v-select outlined :items="itemType" :color="color" :item-color="color" v-model="type" label="Type"></v-select>
            </v-col>
          </v-row>
          <v-btn @click="showSelectPocOfItem = true" class="mb-2" block v-if="!$store.getters.isSoloPocMapItemType(type)">{{ pocIdList.length }} select poc</v-btn>
          <v-text-field v-if="!isPrimaryItem" @blur="$store.dispatch('resize')" outlined block :label="$t('pocitem.name')" v-model="name" :color="color"></v-text-field>
          <v-textarea outlined @blur="modifyDesc()" :label="$t('pocitem.desc')" v-model="desc" name="test" :color="color"></v-textarea>
          <v-row class="mt-n3">
            <v-col cols="4" align="center">
              <v-btn icon x-large @click="showManagePicture = true">
                <v-badge
                  v-if="imageList.length"
                  :content="imageList.length"
                  color="green"
                  overlap
                  bottom
                >
                  <v-icon x-large color="success">mdi-folder-multiple-image</v-icon>
                </v-badge>
                <v-icon v-else x-large color="error">mdi-folder-multiple-image</v-icon>
              </v-btn>
              <p align="center">{{ $t('createreportdog.picture') }}</p>
            </v-col>

            <v-col cols="4" align="center">
              <v-btn icon x-large @click="showGetItemPosition = true"><v-icon x-large :color="pos ? 'success' : 'error'">mdi-map-marker</v-icon></v-btn>
              <p align="center">{{ $t('createreportdog.gps') }}</p>
            </v-col>

            <v-col cols="4" align="center" v-if="!isPrimaryItem">
                <v-btn icon x-large @click="deleteItem"><v-icon x-large color="primary">mdi-delete</v-icon></v-btn>
                <p align="center">{{ $t('common.delete') }}</p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <pmt-position-map-set
        v-if="showGetItemPosition"
        :startCenterPdrId="pocId"
        :param="{ icon: $store.getMapItemIconOfType(type), markerStartPosition: pos, getDistance: false }"
        @return-close="showGetItemPosition = false"
        @return-check="modifyPos($event)"
      />
      <pmt-select-pdr
        v-if="showSelectPocOfItem"
        @close="showSelectPocOfItem = false"
        @select="setPocIdList($event)"
        :selItem="$store.getters['pocModule/getPocDataIteratorItemsOfPocIdArray'](pocIdList)"
        :pocIdToRangeList="pocId"
      ></pmt-select-pdr>
      <picture-manager
        v-if="showManagePicture"
        :subTitle="name ? name : $t('pocitem.' + type)"
        :param="pictureManagerParam()"
        @close="showManagePicture = false"
      ></picture-manager>
      <confirm-popup
        v-if="showConfirmModify"
        @cancel="cancelmodifyConfirm()"
        @confirm="modifyConfirmed($event)"
        :title="confirmTitle"
        :from="confirmModifyFrom"
        :to="confirmModifyTo"
        :item="confirmItem"
        :param="confirmParam"
      ></confirm-popup>
      <pmt-loading-local :loadingTitle="showLoadingTitle" :loadingShow="showLoading"></pmt-loading-local>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ['pocId', 'itemId', 'color', 'multiItemId', 'pocItem'],
  data () {
    return {
      dialog: true,
      notifications: false,
      type: null,
      name: null,
      desc: null,
      imageList: [],
      pocIdList: [],
      showGetItemPosition: false,
      pos: null,
      title: this.itemId !== undefined ? this.$t('pocitem.edittitle') : this.$t('pocitem.createtitle'),
      isActive: true,
      showSelectPocOfItem: false,
      showManagePicture: false,
      itemType: [],
      showLoading: false,
      showLoadingTitle: '',
      showConfirm: false,
      showConfirmModify: null,
      showConfirmQuit: false,
      confirmItem: null,
      confirmParam: { mode: 'standard', text: null },
      confirmTitle: '',
      confirmModifyFrom: '',
      confirmModifyTo: ''
    }
  },
  computed: {
    refItem () {
      if (this.multiItemId) {
        return this.$store.state.officeItemMultiModule.data[this.multiItemId]
      } else if (this.itemId !== undefined) {
        return this.$store.getters['pocModule/getActiveMapItemsOfPoc'](this.pocId).filter(x => x.itemId === this.itemId)[0]
      } else {
        return null
      }
    },
    isMultiITem () {
      if (this.multiItemId) {
        return this.multiItemId
      } else if (this.refItem.officeItemMultiId) {
        return this.refItem.officeItemMultiId
      } else {
        return false
      }
    },
    isPocITem () {
      if (!this.multiItemId && this.itemId !== undefined) {
        return true
      } else {
        return false
      }
    },
    isPrimaryItem () {
      return this.$store.getters.isPrimaryPocItem(this.type)
      // if (this.itemId === 0) {
      //   return true
      // } else if (this.isMultiITem) {
      //   let primary = false
      //   this.pocIdList.forEach(pocId => {
      //     if (this.$store.getters['pocModule/getActiveMapItemsOfPoc'](this.pocId).filter(x => x.id === 0)[0].officeItemMultiId === this.isMultiITem) {
      //       primary = true
      //     }
      //   })
      //   return primary
      // } else { return false }
    },
    isModify () {
      if (this.refItem.name !== this.name) {
        // // console.log('trace name diff')
        return true
      } else if (this.refItem.desc !== this.desc) {
        // // console.log('trace desc diff')
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    onClose () {
      if (!this.isModify) {
        this.dialog = false
      } else {
        // // console.log('trace isModify')
        if (this.refItem.name !== this.name) {
          // // console.log('trace desc diff' + this.refItem.name + '!==' + this.name)
        } else if (this.refItem.desc !== this.desc) {
          // // console.log('trace desc diff' + this.refItem.desc + '!==' + this.desc)
        }
      }
    },
    resetItem () {
      // // console.log('trace resetItem')
      // // console.log(this.refItem)
      this.type = this.refItem ? this.refItem.type : null
      this.name = this.refItem ? this.refItem.name : null
      this.desc = this.refItem ? this.refItem.desc : null
      this.imageList = this.refItem ? this.refItem.imageList : []
      this.pocIdList = this.refItem ? this.refItem.pocIdList : []
      this.pos = this.refItem ? this.refItem.loc : null
      return null
    },
    modifyDesc () {
      if (this.refItem.desc !== this.desc) {
        this.confirmTitle = this.$t('pocItem-edit.confirm-modify-desc')
        this.confirmModifyFrom = this.refItem.desc
        this.confirmModifyTo = this.desc
        this.confirmItem = { desc: this.desc }
        this.confirmParam = { mode: 'standard', text: null }
        this.showConfirmModify = 'save'
      }
    },
    modifyPos (newPos) {
      this.pos = newPos.position
      if (this.refItem.loc !== this.pos) {
        this.showGetItemPosition = false
        this.confirmTitle = this.$t('pocItem-edit.confirm-modify-position')
        this.confirmModifyFrom = null
        this.confirmModifyTo = null
        this.confirmItem = { pos: newPos.position }
        this.confirmParam = { mode: 'standard', text: null }
        this.showConfirmModify = 'save'
      }
    },
    cancelmodifyConfirm () {
      this.resetItem()
      this.showConfirmModify = null
    },
    modifyConfirmed (change) {
      // // console.log('trace change =')
      // // console.log(change)
      switch (this.showConfirmModify) {
        case 'save':
          this.showLoading = true
          if (this.isMultiITem) {
            change.multiId = this.isMultiITem
            change.isPrimaryItem = this.$store.getters.isPrimaryPocItem(this.type)
            this.$store.dispatch('officeItemMultiModule/updateMultiItem', change).then(() => {
              this.showLoading = false
            })
          // } else if (!this.$store.getters.isSoloPocMapItemType(this.type)) {
          //   // // console.log('trace to create multi')
          } else {
            change.pocId = this.pocId
            change.itemId = this.itemId
            this.$store.dispatch('pocModule/updatePocItem', change).then(() => {
              this.showLoading = false
            })
          }
          break
        case 'savePocIdList':
          this.showConfirmModify = null
          this.showLoading = true
          this.$store.dispatch('officeItemMultiModule/updateMultiItem', {
            multiId: this.isMultiITem,
            pocIdList: change.newList,
            isPrimaryItem: this.$store.getters.isPrimaryPocItem(this.type)
          }).then(() => {
            this.showLoading = false
          })
          break
        case 'switchToMulti':
          this.showLoading = true
          this.$store.dispatch('officeItemMultiModule/set', {
            type: change.type ? change.type : this.type,
            name: this.name,
            desc: this.desc,
            imageList: this.imageList,
            pocIdList: this.pocIdList,
            loc: this.pos,
            isActive: this.isActive,
            route: this.getRouteArrayOfPoc(this.pocIdList)
          }).then((multiId) => {
            this.$store.dispatch('pocModule/updatePocItem', {
              pocId: this.pocId,
              itemId: this.itemId,
              officeItemMultiId: multiId
            }).then(() => {
              this.showLoading = false
            })
          })
          break
        case 'switchToSolo':
          this.showLoading = true
          this.$store.dispatch('pocModule/updatePocItem', {
            pocId: this.pocId,
            itemId: this.itemId,
            officeItemMultiId: null,
            type: change.type
          }).then(() => {
            this.$store.dispatch('officeItemMultiModule/set', {
              id: this.isMultiITem,
              isActive: false
            })
          })
          break
        case 'deleteItem':
          this.$store.dispatch('pocModule/deletePocItem', {
            pocId: this.pocId,
            itemId: this.itemId
          })
          break
        default:
          break
      }

      if (this.showConfirmModify === 'deleteItem') {
        this.showConfirmModify = null
        this.dialog = false
      } else if (!this.isMultiITem) {
        this.showConfirmModify = null
        this.showLoading = true
        this.showLoadingTitle = this.$t('common.saveinprogress')
        change.pocId = this.pocId
        change.itemId = this.itemId
        this.$store.dispatch('pocModule/updatePocItem', change).then(() => {
          this.showLoading = false
        })
      } else {
        this.showConfirmModify = null
        // // console.log('trace not solo')
      }
    },
    getRouteArrayOfPoc (pocIdArray) {
      const route = []
      pocIdArray.forEach(pocId => {
        if (this.$store.state.pocModule.data[pocId] && this.$store.state.pocModule.data[pocId].route) {
          this.$store.state.pocModule.data[pocId].route.forEach(routeId => {
            route.push(routeId)
          })
        }
      })
    },
    pictureManagerParam () {
      if (this.isMultiITem) {
        return {
          type: 'multiItem',
          multiId: this.isMultiITem
        }
      } else {
        return {
          type: 'pocItem',
          itemId: this.itemId,
          pocId: this.pocId
        }
      }
    },
    setPicture (pictureArray) {
      this.imageList = pictureArray
      this.showManagePicture = false
    },
    setPocIdList (items) {
      this.showGetItemPosition = false
      const newPocIdList = items.map(function (x) { return x.id })
      const addList = []
      const remList = []
      newPocIdList.forEach(pocId => {
        if (this.pocIdList.indexOf(pocId) < 0) {
          addList.push(pocId)
        }
      })
      this.pocIdList.forEach(pocId => {
        if (newPocIdList.indexOf(pocId) < 0) {
          remList.push(pocId)
        }
      })
      this.pocIdList = newPocIdList
      if (addList.length || remList.length) {
        // // console.log('trace change to save pocIdList')
        this.confirmTitle = this.$t('common.confirmNewPocList')
        this.confirmItem = { addList: addList, remList: remList, newList: newPocIdList }
        this.confirmParam = { mode: 'modifyPocIdArray' }
        this.showConfirmModify = this.isMultiITem ? 'savePocIdList' : 'switchToMulti'
      }
    },
    deleteItem () {
      this.confirmTitle = this.$t('pocItem-edit.confirm-delete')
      // this.confirmModifyFrom = this.refItem.desc
      // this.confirmModifyTo = this.desc
      // this.confirmItem = { desc: this.desc }
      this.confirmParam = { mode: 'standard', text: null }
      this.showConfirmModify = 'deleteItem'
    },
    saveItem () {
      const item = {
        type: this.type,
        name: this.name,
        desc: this.desc,
        imageList: this.imageList,
        pocIdList: this.pocIdList,
        pos: this.pos,
        pocId: this.pocId,
        isActive: this.isActive
      }
      if (this.pocItem) {
        if (this.pocItem.officeItemMultiId !== undefined) { item.officeItemMultiId = this.pocItem.officeItemMultiId }
        item.id = this.pocItem.id
      }
      this.$store.dispatch('pocModule/savePocItem', item)
      this.$emit('return-close')
    }
  },
  mounted () {
    // // console.log('trace itemId = ' + this.itemId)
    // // console.log('trace pocId = ' + this.pocId)
    // // console.log('trace multiItemId = ' + this.multiItemId)

    this.resetItem()
    if (this.$store.getters.isPrimaryPocItem(this.type)) {
      this.$store.getters.getPrimaryPocMapItemTypeArray.forEach(type => {
        this.itemType.push({ text: this.$t('pocitem.' + type), value: type })
      })
    } else {
      this.$store.getters.getOptionalPocMapItemTypeArray.forEach(type => {
        this.itemType.push({ text: this.$t('pocitem.' + type), value: type })
      })
    }
    // this.resetItem().then(() => {
    //   if (this.$store.getters.isPrimaryPocItem(this.type)) {
    //     this.$store.getters.getPrimaryPocMapItemTypeArray.forEach(type => {
    //       this.itemType.push({ text: this.$t('pocitem.' + type), value: type })
    //     })
    //   } else {
    //     this.$store.getters.getOptionalPocMapItemTypeArray.forEach(type => {
    //       this.itemType.push({ text: this.$t('pocitem.' + type), value: type })
    //     })
    //   }
    // })
    // this.$store.getters['pocModule/get' + (this.isPrimaryItem ? 'Primary' : 'Optional') + 'PocMapItemTypeArray'].forEach(type => {
    //   this.itemType.push({ text: this.$t('pocitem.' + type), value: type })
    // })
  },
  watch: {
    type (newValue, oldValue) {
      // // console.log('trace type watch ' + oldValue + ' -> ' + newValue)
      if (oldValue && newValue && newValue !== oldValue) {
        this.confirmTitle = this.$t('pocItem-edit.confirm-modify-type')
        this.confirmModifyFrom = this.$t('pocitem.' + oldValue)
        this.confirmModifyTo = this.$t('pocitem.' + newValue)
        this.confirmParam = { mode: 'standard', text: null }
        if (this.$store.getters.isSoloPocMapItemType(oldValue)) {
          if (this.$store.getters.isSoloPocMapItemType(newValue)) {
            // // console.log('trace solo to Solo')
            this.confirmItem = { type: newValue }
            this.showConfirmModify = 'save'
          } else {
            // // console.log('trace solo to multi')
            // this.modifySoloToMultiItemType(oldValue, newValue)
            this.pocIdList.push(this.pocId)
            this.confirmItem = {
              type: newValue
            }
            this.showConfirmModify = 'switchToMulti'
          }
        } else {
          if (this.$store.getters.isSoloPocMapItemType(newValue)) {
            // // console.log('trace multi to Solo')
            // this.modifyMultiToSoloItemType(oldValue, newValue)
            if (this.pocIdList.length > 1) {
              this.$store.dispatch('showInfo', this.$t('pocItem-edit.cantswitchtosolotype'))
            } else {
              this.confirmItem = { type: newValue }
              this.showConfirmModify = 'switchToSolo'
            }
          } else {
            // // console.log('trace multi to multi')
            this.confirmItem = { type: newValue }
            this.showConfirmModify = 'save'
          }
        }
      }
      // if (!this.$store.getters.isSoloPocMapItemType(newValue)) {
      //   if (!this.pocIdList.length) {
      //     this.pocIdList.push(this.pocId)
      //   }
      // }
    },
    dialog (newValue) {
      this.$store.dispatch('resize')
      this.dialog = false
      this.$emit('return-close')
    }
  }
}
</script>
