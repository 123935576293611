<template>
  <div class="overflow-y-hidden" :style="$store.getters.contentStyle">
    <v-app-bar dark height="50" color="primary" class="lighten-1">
      <v-app-bar-nav-icon large @click="showSearch = !showSearch">
        <v-icon large>mdi-magnify</v-icon>
      </v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title>{{ $t('pmtpmbabillard.title-' + $store.getters.dashBoardMenuSelect) }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon large @click="showFilter = !showFilter">
        <v-icon large>{{ filterMessType !== 'all' ? 'mdi-filter-menu' : 'mdi-filter-menu-outline'}}</v-icon>
      </v-app-bar-nav-icon>
    </v-app-bar>
    <v-card v-if="showFilter" max-height="150" height="150" flat color="grey lighten-4">
      <v-card-title class="pt-n2">{{ $t('common.filter') }} :</v-card-title>
      <v-select
        class="mx-2"
        label="Type"
        outlined
        :items="typeItems"
        v-model="filterMessType"
      ></v-select>
    </v-card>
    <v-card v-if="showSearch" max-height="150" height="150" flat color="grey lighten-4">
      <v-card-title class="pt-n2">{{ $t('common.search') }} :</v-card-title>
      <v-text-field
        class="mx-2"
        :label="$t('common.search')"
        outlined
        v-model="search"
        @blur="$store.dispatch('resize')"
      ></v-text-field>
    </v-card>
    <v-card v-if="$store.getters.dashBoardMenuSelect === 'log'" :height="contentHeight" :width="$store.getters.contentSize.width" class="overflow-y-auto overflow-x-hidden">
      <v-card-title>{{ $t('pmtpmbabillard.title') }}</v-card-title>
      <v-card-title v-if="$store.state.pocModule">{{ pocLoadedCount }} poc loaded</v-card-title>
      <v-select
        :items="$store.getters['pocModule/getPocItems']"
        >
      </v-select>
      <v-btn @click="doPatch()"> patcher</v-btn>
      <v-btn @click="doPatch2()">correct pdr</v-btn>
      <admin-test class="mt-5 mx-2"></admin-test>
      <v-container
        fixedclass="overflow-y-auto overflow-x-hidden"
        max-height="200"
      >
        <p>contentHeight = {{ $store.getters.contentSize.height }}</p>
        <p>screenHeight = {{ $store.getters.screenSize.height }}</p>
        <!-- <p v-for="item in $store.getters['pocModule/getTpTypeArray']" :key="item">
          {{ item + ' = ' + $t('common.' + item.toLowerCase()) }}
        </p> -->
        <p>officeModuleLoadComplete = {{ $store.state.officeModuleLoadComplete }}</p>
        <p>officeRulesModuleLoadComplete = {{ $store.state.officeRulesModuleLoadComplete }}</p>
        <p>officeItemModuleLoadComplete = {{ $store.state.officeItemModuleLoadComplete }}</p>
        <p>officeSelectedLoadComplete = {{ $store.state.officeSelectedLoadComplete }}</p>
        <p>userModuleLoadComplete = {{ $store.state.userModuleLoadComplete }}</p>
        <p>userLoggedModuleLoadComplete = {{ $store.state.userLoggedModuleLoadComplete }}</p>
        <p>pocModuleLoadComplete = {{ $store.state.pocModuleLoadComplete }}</p>
        <p>zoneModuleLoadComplete = {{ $store.state.zoneModuleLoadComplete }}</p>
        <p>routeModuleLoadComplete = {{ $store.state.routeModuleLoadComplete }}</p>
        <p>reportModuleLoadComplete = {{ $store.state.reportModuleLoadComplete }}</p>
        <p>paramModuleLoadComplete = {{ $store.state.paramModuleLoadComplete }}</p>
        <p>postmanDataLoadPartialReady = {{ $store.getters.postmanDataLoadPartialReady }}</p>
        <p>postmanDataLoadCompleteReady = {{ $store.getters.postmanDataLoadCompleteReady }}</p>
        <v-card-text v-if="$store.getters.dashBoardMenuSelect == 4">
          <p class="mt-n2">height = {{ $store.getters.contentSize.height }}</p>
          <p class="mt-n2">width = {{ $store.getters.contentSize.width }}</p>
          <p class="mt-n2">style = {{ $store.getters.contentStyle }}</p>
          <p class="mt-n2">screen height = {{ $store.getters.screenSize.height }}</p>
          <p class="mt-n2">screen width = {{ $store.getters.screenSize.width }}</p>
          <p class="mt-n2">screen style = {{ $store.getters.screenStyle }}</p>
          <p>log :</p>
          <v-textarea
          readonly
          solo
          :value="$store.getters.getMessageLog"
          >
          </v-textarea>
        </v-card-text>
      </v-container>
    </v-card>
    <v-card fixed v-else-if="$store.getters.dashBoardMenuSelect === 'task'" :height="contentHeight" :width="$store.getters.contentSize.width" class="overflow-y-auto overflow-x-hidden">
    </v-card>
    <v-sheet id="sheet-iterator" fixed v-else :height="contentHeight" :width="$store.getters.contentSize.width" class="overflow-y-auto overflow-x-hidden">
      <v-data-iterator
        ref="dataIterator"
        :expanded.sync="notReadItems"
        :items="messItems"
        :custom-filter="customFilter"
        :search="search"
        fixed
        :items-per-page="15"
        :footer-props="{ 'items-per-page-options' : [15, 30, 50, 100] }"
        @update:items-per-page="$store.dispatch('resize')"
      >
        <template v-slot:default="{ items, isExpanded, expand }">
          <v-list>
            <!-- <v-row v-if="!solo">
              <v-card-subtitle> {{ pdrnotReadItems.length }} {{ $t('common.selected') }}</v-card-subtitle>
            </v-row> -->
            <v-list-item
              v-for="item in items"
              :key="item.id"
              :class="isExpanded(item) ? 'elevation-3 primary lighten-3 my-2' : 'white elevation-1'"
            >
            <!-- @click="(v) => expand(item, !isExpanded(item))" -->
              <v-list-item-content>
                <v-row no-gutters justify="center" align="center" @click="(v) => expand(item, !isExpanded(item))">
                  <v-col  v-if="item.from" width="35" max-width="35" class="me-1 mt-n1 mb-n1" justify="center" align="center">
                    <v-icon v-text="getFromIcon(item.from)" large color="primary"></v-icon>
                    <v-icon color="primary" v-text="isExpanded(item) ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-icon>
                  </v-col>
                  <v-col v-else width="35" max-width="35" class="ps-n2 mt-n1 mb-n1 me-1" justify="center" align="center">
                    <v-img :src="getMessageImg(item)" contain max-width="35"></v-img>
                    <v-icon color="primary" v-text="isExpanded(item) ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-icon>
                  </v-col>
                  <v-col align="start" justify="center" cols="8">
                    <v-list-item-content two-line>
                      <p class="subtitle-1" v-if="item.from" v-text="item.title"></p>
                      <v-list-item-title v-else v-text="$t('pmtpmbabillard.titleMessType-' + item.type)"></v-list-item-title>
                      <v-list-item-subtitle v-text="getSubTitleImg(item)"></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col justify="center">
                    <!-- <v-list-item-content two-line>
                      <v-list-item-subtitle v-text="$store.getters.getDisplayDate(item.created_at)"></v-list-item-subtitle>
                    </v-list-item-content> -->
                    <h5 class="mb-n1 me-1" align="center" v-text="$store.getters.getDisplayDate(item.byDate)"></h5>
                  </v-col>
                  <!-- <v-col justify="end">
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-col> -->
                  <!-- <v-list-item-action><v-icon>mdi-chevron-down</v-icon></v-list-item-action> -->
                </v-row>
                <v-card v-if="isExpanded(item)" transition="dialog-top-transition">
                  <div v-if="item.type === 'pubUpdate'">
                    <v-card-title class="primary--text justify-center mt-n3">{{ $t('pmtpmbabillard.titleMessType-' + item.type) }} :</v-card-title>
                    <v-row justify="center"><v-card-title class="mt-n6">{{ item.address }}</v-card-title></v-row>
                    <v-row justify="center" class="mt-1" no-gutters><p>{{ $t('common.newvalue') }} : </p><h3 class="red--text ms-2 mt-n1">{{ item.pub ? $t('common.pub') : $t('common.nopub') }}</h3></v-row>
                    <v-card-text v-if="item.byName">{{ $t('common.updatedBy') }} : {{ item.byName }} ({{ item.byEmpId }})</v-card-text>
                    <v-card-text class="mt-n9">{{ $t('common.updatedAt') }} : {{ getDisplayDate(item.byDate) }}</v-card-text>
                  </div>
                  <div v-else-if="item.type === 'vacantUpdate'">
                    <v-card-title class="primary--text justify-center mt-n3">{{ $t('pmtpmbabillard.titleMessType-' + item.type) }} :</v-card-title>
                    <v-row justify="center"><v-card-title class="mt-n6">{{ item.address }}</v-card-title></v-row>
                    <v-row justify="center" class="mt-1" no-gutters><p>{{ $t('common.newvalue') }} : </p><h3 class="red--text ms-2 mt-n1">{{ item.vacant ? $t('common.vacant') : $t('common.novacant') }}</h3></v-row>
                    <v-card-text v-if="item.byName">{{ $t('common.updatedBy') }} : {{ item.byName }} ({{ item.byEmpId }})</v-card-text>
                    <v-card-text class="mt-n9">{{ $t('common.updatedAt') }} : {{ getDisplayDate(item.byDate) }}</v-card-text>
                  </div>
                  <div v-else-if="item.type === 'message'">
                    <v-card-title class="primary--text mt-n3">{{ $t('pmtpmbabillard.titleMessType-' + item.type) + ' : ' + $t('from.' + item.from) }}</v-card-title>
                    <v-card-title class="mt-n6">{{ item.title }}</v-card-title>
                    <v-card-text class="mt-n3">{{ item.message }}</v-card-text>
                    <v-row no-gutters justify="center" align="center">
                      <v-carousel
                        v-if="item.imageList.length"
                        hide-delimiters
                        :show-arrows="item.imageList.length > 1"
                        :height="Math.abs((($store.getters.contentWidth * 0.9) / 3) * 2)"
                      >
                        <v-carousel-item
                          v-for="(image, i) of item.imageList"
                          :key="i"
                          :src="image.url"
                          contain
                          @click="$store.getters.EventBus.$emit('ShowImageListFullScreen', item.imageList)"
                        ></v-carousel-item>
                      </v-carousel>
                    </v-row>
                    <v-row>
                      <v-spacer></v-spacer>
                      <v-btn x-large icon><v-icon color="primary" x-large @click="(v) => expand(item, !isExpanded(item))">mdi-chevron-up</v-icon></v-btn>
                      <v-spacer></v-spacer>
                    </v-row>
                  </div>
                  <div v-else-if="item.type === 'createReport' && $store.getters.isReportModuleLoadComplete">
                    <v-card-title class="primary--text mt-n3">{{ $t('pmtcreatereportpopup.title' + $store.state.reportModule.data[item.reportId].type) + ' : ' }}</v-card-title>
                    <v-card-title class="mt-n6 mb-n6">Route :</v-card-title>
                    <p
                      class="subtitle-1 my-n1"
                      v-for="(routeId, i) of $store.state.reportModule.data[item.reportId].routeIdArray"
                      :key="i"
                      align="center"
                      color="red"
                    >
                      {{ $store.state.routeModule.data[routeId].name }}
                    </p>
                    <v-card-subtitle v-if="$store.getters['reportModule/isBlockReport'](item.reportId)" class="red--text">* {{ $t('createreportdog.blockpdrtitle')}}</v-card-subtitle>
                    <v-row no-gutters justify="center" align="center">
                      <v-carousel
                        v-if="$store.state.reportModule.data[item.reportId].imageList.length"
                        hide-delimiters
                        :show-arrows="$store.state.reportModule.data[item.reportId].imageList.length > 1"
                        :height="Math.abs((($store.getters.contentWidth * 0.9) / 3) * 2)"
                      >
                        <v-carousel-item
                          v-for="(image, i) of $store.state.reportModule.data[item.reportId].imageList"
                          :key="i"
                          :src="image.url"
                          contain
                          @click="$store.getters.EventBus.$emit('ShowImageListFullScreen', $store.state.reportModule.data[item.reportId].imageList)"
                        ></v-carousel-item>
                      </v-carousel>
                    </v-row>
                    <v-card-text v-if="item.byName">{{ $t('common.createdBy') }} : {{ item.byName }} ({{ item.byEmpId }})</v-card-text>
                    <v-card-text class="mt-n9">{{ $t('common.createdAt') }} : {{ getDisplayDate(item.byDate) }}</v-card-text>
                    <v-row>
                      <v-spacer></v-spacer>
                      <v-btn x-large icon><v-icon color="primary" x-large @click="(v) => expand(item, !isExpanded(item))">mdi-chevron-up</v-icon></v-btn>
                      <v-spacer></v-spacer>
                    </v-row>
                  </div>
                  <div v-else-if="item.type === 'newRouteReport' && $store.getters.isReportModuleLoadComplete && $store.getters.isPocModuleLoadComplete">
                    <v-card-title class="primary--text mt-n3">{{ $t('pmtcreatereportpopup.title' + $store.state.reportModule.data[item.reportId].type) + ' : ' }}</v-card-title>
                    <v-card-title v-if="$store.state.reportModule.data[item.reportId].pocList.length" class="mt-n6 mb-n6">{{ $t('common.pdr') }} :</v-card-title>
                    <p
                      class="subtitle-1 my-n1"
                      v-for="(pocDetail, i) of $store.state.reportModule.data[item.reportId].pocList"
                      :key="i"
                      align="center"
                      color="red"
                    >
                      {{ $store.state.pocModule.data[pocDetail.pdrId].pdrListAddress }}
                    </p>
                    <v-card-subtitle v-if="$store.getters['reportModule/isBlockReport'](item.reportId)" class="red--text">* {{ $t('createreportdog.blockpdrtitle')}}</v-card-subtitle>
                    <v-row no-gutters justify="center" align="center">
                      <v-carousel
                        v-if="$store.state.reportModule.data[item.reportId].imageList.length"
                        hide-delimiters
                        class="mb-n2"
                        :show-arrows="$store.state.reportModule.data[item.reportId].imageList.length > 1"
                        :height="Math.abs((($store.getters.contentWidth * 0.9) / 3) * 2)"
                      >
                        <v-carousel-item
                          v-for="(image, i) of $store.state.reportModule.data[item.reportId].imageList"
                          :key="i"
                          :src="image.url"
                          contain
                          @click="$store.getters.EventBus.$emit('ShowImageListFullScreen', $store.state.reportModule.data[item.reportId].imageList)"
                        ></v-carousel-item>
                      </v-carousel>
                    </v-row>
                    <v-card-text class="mt-1">{{ $t('common.createdAt') }} : {{ getDisplayDate(item.byDate) }}</v-card-text>
                    <v-row>
                      <v-spacer></v-spacer>
                      <v-btn x-large icon><v-icon color="primary" x-large @click="(v) => expand(item, !isExpanded(item))">mdi-chevron-up</v-icon></v-btn>
                      <v-spacer></v-spacer>
                    </v-row>
                  </div>
                  <div v-else>
                    <v-card-title class="primary--text justify-center mt-n3">{{ $t('common.loading') }} :</v-card-title>
                    <v-row no-gutters justify="center">
                      <v-spacer></v-spacer>
                      <v-progress-circular
                        :size="100"
                        indeterminate
                        :width="10"
                      ></v-progress-circular>
                      <v-spacer></v-spacer>
                    </v-row>
                  </div>
                </v-card>
              </v-list-item-content>
              <!-- <v-list-item-action><v-checkbox :input-value="isExpanded(item)"></v-checkbox></v-list-item-action> -->
            </v-list-item>
          </v-list>
        </template>
        <!-- <template v-slot:footer>
          <v-app-bar bottom dark color="primary">
            <v-spacer></v-spacer>
            <v-btn icon x-large dark @click="close()">
              <v-icon color="green" x-large>mdi-check</v-icon>
            </v-btn>
          </v-app-bar>
        </template> -->
      </v-data-iterator>
    </v-sheet>
    <pmt-loading-local :loadingTitle="showLoadingTitle" :loadingShow="showLoading"></pmt-loading-local>
  </div>
</template>

<script>
import AdminTest from '../admin/AdminTest.vue'

export default {
  components: { AdminTest },
  props: ['contentstyles'],
  data () {
    return {
      dashboard: 0,
      messOpenIndexArray: [],
      notReadItems: [],
      filterMessType: 'all',
      showFilter: false,
      showSearch: false,
      search: null,
      showLoading: false,
      showLoadingTitle: ''
    }
  },
  methods: {
    doPatch () {
      this.showLoadingTitle = 'Patcher'
      this.showLoading = true
      this.$store.dispatch('pocModule/patchTemp').then(() => {
        this.showLoading = false
      })
    },
    doPatch2 () {
      this.$store.dispatch('adminModule/set', {
        type: 'searchGooglePosition',
        officeId: this.$store.getters.getSelectedOfficeId
      })
    },
    getMessageImg (mess) {
      switch (mess.type) {
        case 'pubUpdate':
          if (mess.pub) {
            return this.$store.getters.getAppIcon.pub
          } else {
            return this.$store.getters.getAppIcon.noPub
          }
        case 'vacantUpdate':
          if (mess.vacant) {
            return this.$store.getters.getAppIcon.vacant
          } else {
            return this.$store.getters.getAppIcon.noVacant
          }
        case 'createReport':
          if (this.$store.getters.isReportModuleLoadComplete) {
            return this.$store.getAlertPngIcon(this.$store.state.reportModule.data[mess.reportId].type)
          } else {
            return this.$store.getAlertPngIcon()
          }
        case 'newRouteReport':
          if (this.$store.getters.isReportModuleLoadComplete) {
            return this.$store.getAlertPngIcon(this.$store.state.reportModule.data[mess.reportId].type)
          } else {
            return this.$store.getAlertPngIcon()
          }
        default:
          return this.$store.getters.getAppIcon.pub
      }
    },
    getSubTitleImg (mess) {
      switch (mess.type) {
        case 'pubUpdate':
          return mess.address
        case 'vacantUpdate':
          return mess.address
        case 'createReport':
          if (this.$store.getters.isReportModuleLoadComplete) {
            return this.$t('pmtcreatereportpopup.title' + this.$store.state.reportModule.data[mess.reportId].type)
          } else {
            return this.$t('pmtcreatereportpopup.title')
          }
        case 'newRouteReport':
          if (this.$store.getters.isReportModuleLoadComplete) {
            return this.$t('pmtcreatereportpopup.title' + this.$store.state.reportModule.data[mess.reportId].type)
          } else {
            return this.$t('pmtcreatereportpopup.title')
          }
        default:
          return mess.address
      }
    },
    getFromIcon (from) {
      switch (from) {
        case 'mixComite':
          return 'mdi-shield-link-variant'
        case 'csocial':
          return 'mdi-account-group'
        case 'supervisor':
          return 'mdi-account-tie'
        default:
          return 'mdi-message'
      }
    },
    getDisplayDate (date) {
      return date.toDate().toLocaleString(this.$store.getters.getLocale + '-CA')
    },
    customFilter (items, search, filter) {
      // this custom filter will do a multi-match separated by a space.
      // e.g
      this.page = 1

      if (!search) {
        this.nbrItemFiltered = items.length
        return items
      } // if the search is empty just return all the items

      function newFilter (val, search) {
        return val !== null &&
          ['undefined', 'boolean'].indexOf(typeof val) === -1 &&
          val.toString().toLowerCase().replace(/[^0-9a-zA-Z-]+/g, '').indexOf(search) !== -1
      }

      const needleAry = search.toString().toLowerCase().split(' ').filter(x => x)
      // whenever we encounter a space in our search we will filter for both the first and second word (or third word)

      const result = items.filter(row => needleAry.every(needle => Object.keys(row).some(key => newFilter(row[key], needle))))
      if (result.length) {
        this.nbrItemFiltered = result.length
      } else {
        this.nbrItemFiltered = 1
      }

      return result
      // return items.filter(row => needleAry.every(needle => Object.keys(row).some(key => newFilter(row[key], needle))))
      // foreach needle in our array cycle through the data (row[key]) in the current row looking for a match.
      //  .some will return true and exit the loop if it finds one it will return false if it doesnt
      //  .every will exit the loop if we dont find a match but will return true if all needles match
      //  .filter the rows on each match
    }
  },
  watch: {
    showFilter (newValue) {
      if (newValue) { this.showSearch = false }
    },
    showSearch (newValue) {
      if (newValue) { this.showFilter = false }
    },
    messItems (newValue) {
      this.notReadItems = newValue.filter(mess => !mess.isRead)
    },
    notReadItems (newValue) {
      const noReadIdArray = newValue.map(function (x) { return x.id })
      this.messItems.forEach(mess => {
        if (!mess.isRead) {
          if (noReadIdArray.indexOf(mess.id) < 0) {
            this.$store.dispatch(mess.module + '/asReadMessage', mess.id)
          }
        }
      })
    }
  },
  computed: {
    typeItems () {
      const types = [{
        text: this.$t('usertypeShort.' + this.$store.getters.userType),
        value: 'userType'
      }, {
        text: this.$t('pmtpmbabillard.perso'),
        value: 'perso'
      }]
      if (this.$store.getters.isPostman) {
        types.push({
          text: this.$t('pmtpmbabillard.route'),
          value: 'route'
        })
      }
      if (this.$store.getters.isCSocial) {
        types.push({
          text: this.$t('pmtpmbabillard.csocial'),
          value: 'csocial'
        })
      }
      if (this.$store.getters.isMixComite) {
        types.push({
          text: this.$t('pmtpmbabillard.mixcomite'),
          value: 'mixcomite'
        })
      }
      return [{
        text: this.$t('pmtpmbabillard.all'),
        value: 'all'
      }].concat(types.sort((a, b) => {
        return a.text.toUpperCase() > b.text.toUpperCase()
      }))
    },
    // messItems () {
    //   let result
    //   function indexNotReadList (list) {
    //     const noReadList = []
    //     for (let i = 0; i < list.length; i++) {
    //       if (!list[i].isRead) {
    //         noReadList.push(i)
    //       }
    //     }
    //     return noReadList
    //   }
    //   switch (this.$store.getters.dashBoardMenuSelect) {
    //     case 'all':
    //       result = [].concat(this.messUserItems, this.messRouteItems, this.messUserTypeItems, this.messCSocialItems, this.messMixComiteItems).sort(function (a, b) { return b.created_at - a.created_at })
    //       return { items: result, openList: indexNotReadList(result) }
    //     case 'office':
    //       return { items: this.messOfficeItems, openList: indexNotReadList(this.messOfficeItems) }
    //     case 'perso':
    //       return { items: this.messUserItems, openList: indexNotReadList(this.messUserItems) }
    //     case 'route':
    //       return { items: this.messRouteItems, openList: indexNotReadList(this.messRouteItems) }
    //     case 'userType':
    //       return { items: this.messUserTypeItems, openList: indexNotReadList(this.messUserTypeItems) }
    //     case 'csocial':
    //       return { items: this.messCSocialItems, openList: indexNotReadList(this.messCSocialItems) }
    //     case 'mixcomite':
    //       return { items: this.messMixComiteItems, openList: indexNotReadList(this.messMixComiteItems) }
    //     default:
    //       return { items: [], openList: [] }
    //   }
    // },
    messItems () {
      switch (this.filterMessType) {
        case 'all':
          return [].concat(this.messUserItems, this.messRouteItems, this.messUserTypeItems, this.messCSocialItems, this.messMixComiteItems).sort(function (a, b) { return b.created_at - a.created_at })
        case 'mixcomite':
          return this.messMixComiteItems
        case 'csocial':
          return this.messCSocialItems
        case 'route':
          return this.messRouteItems
        case 'perso':
          return this.messUserItems
        case 'userType':
          return this.messUserTypeItems
        default:
          return []
      }
    },
    // messOfficeItems () {
    //   if (this.$store.getters.isMessOfficeModuleLoadComplete) {
    //     return this.$store.getters['messOfficeModule/getItems']
    //   } else {
    //     return []
    //   }
    // },
    messUserItems () {
      if (this.$store.getters.isMessUserModuleLoadComplete) {
        return this.$store.getters['messUserModule/getItems']
      } else {
        return []
      }
    },
    messUserTypeItems () {
      if (this.$store.getters.isMessUserTypeModuleLoadComplete) {
        return this.$store.getters['messUserTypeModule/getItems']
      } else {
        return []
      }
    },
    messRouteItems () {
      if (this.$store.getters.isMessRouteModuleLoadComplete) {
        return this.$store.getters['messRouteModule/getItems']
      } else {
        return []
      }
    },
    messCSocialItems () {
      if (this.$store.getters.isMessCSocialModuleLoadComplete) {
        return this.$store.getters['messCSocialModule/getItems']
      } else {
        return []
      }
    },
    messMixComiteItems () {
      if (this.$store.getters.isMessMixComiteModuleLoadComplete) {
        return this.$store.getters['messMixComiteModule/getItems']
      } else {
        return []
      }
    },
    pocLoadedCount () {
      if (this.$store.state.pocModule) {
        return this.$store.getters['pocModule/numberOfPoc']
      } else {
        return 0
      }
    },
    contentHeight () {
      if (this.showFilter || this.showSearch) {
        return this.$store.getters.contentSize.height - 200
      } else {
        return this.$store.getters.contentSize.height - 50
      }
    },
    carouselHeight () {
      return Math.abs(((this.$store.getters.contentWidth * 0.9) / 3) * 2)
      // if (calcWeidth > 300) {
      //   return 300
      // } else {
      //   return calcWeidth
      // }
    }
  }
}
</script>
<style scoped>
  #sheet-iterator {
    position: fixed;
  }
</style>
