<template>

    <v-app-bar
      color="primary"
      dark
      :extension-height="$store.getters.appBarExtendHeight"
      :src= "pictureSrc"
      :width="$store.getters.screenSize.width"
      :height="$store.getters.appBarHeight"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          :gradient="$store.getters.getDayGradient"
        ></v-img>
      </template>
      <div class="d-flex align-center">
        <!-- <v-img
          @click="toggleDrawer"
          alt="CanadaPost Logo"
          class="shrink mr-2  ms-n2"
          contain
          :src="require('@/assets/Icon/MenuIcon/logo_postes_canada.png')"
          transition="scale-transition"
          :width="$store.getters.appBarIconSize"
        /> -->
        <v-btn color="red" @click="toggleDrawer" icon x-large>
          <v-icon color="white" :size="50">mdi-microsoft-xbox-controller-menu</v-icon>
        </v-btn>
        <p class="ms-2 mt-3 display-1 hidden-sm-and-down">{{ $t('common.apptitle') }} </p>
      </div>

      <v-spacer></v-spacer>

      <v-toolbar-title
        two-line
        align="center"
        v-show="$store.getters.getGuardianMode.active && $store.getters.getGuardianMode.dangerActive"
        @click="$store.getters.EventBus.$emit('openBottomSheetGuardianDanger')"
      >
        <v-icon :color="Number($store.getters['reportModule/getProximityAlertDistanceMin']) < 500 ? Number($store.getters['reportModule/getProximityAlertDistanceMin']) < 100 ? 'red' : 'yellow' : 'green'" large class="mt-2 mb-n1">mdi-shield-alert</v-icon>
        <p class="mb-n1">{{ getDisplayDist(Number($store.getters['reportModule/getProximityAlertDistanceMin'])) }}</p>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <div class="float-right me-2">
        <pmt-search-popup v-if="showSearch" @close="showSearch = false"/>
        <v-btn color="red" x-large icon class="float-right"
          :loading="!loadComplete"
          :disabled="!loadComplete"
          @click="showSearch = !showSearch"
        >
          <v-icon color="white" :size="60">mdi-magnify</v-icon>
        </v-btn>
      </div>
      <template v-if="showExtend" v-slot:extension>
        <v-tabs v-if="componentSelected == 'pmt-pm-babillard' && !showSearch"
          show-arrows
          align-with-title
          v-model="dashBoardMenuSelect"
        >
          <v-tab href="#messageboard">{{ $t('commonmenu.messageboard') }}</v-tab>
          <v-tab href="#task">{{ $t('commonmenu.task') }}</v-tab>
          <v-tab href="#log" v-if="$store.getters.isAdminAcces">{{ $t('pmtpmbabillard.log') }}</v-tab>
          <!-- <v-tab href="#route" v-if="$store.getters.isPostman">{{ $t('pmtpmbabillard.route') }}</v-tab>
          <v-tab href="#userType">{{ $t('usertypeShort.' + $store.getters.userType) }}</v-tab>
          <v-tab href="#csocial" v-if="$store.getters.isCSocial">{{ $t('pmtpmbabillard.csocial') }}</v-tab>
          <v-tab href="#mixcomite" v-if="$store.getters.isMixComite">{{ $t('pmtpmbabillard.mixcomite') }}</v-tab>
          <v-tab href="#log" v-if="$store.getters.isAdminAcces">{{ $t('pmtpmbabillard.log') }}</v-tab> -->
        </v-tabs>
        <!-- <div v-if="showSearch">
          <v-row>
            <v-spacer></v-spacer>
            <pmt-select-pdr-input ></pmt-select-pdr-input>
            <v-spacer></v-spacer>
          </v-row>
        </div> -->
      </template>

      </v-app-bar>
</template>

<script>
export default {
  data () {
    return {
      drawerState: false,
      // minDist: 0,
      item: 1,
      dashBoardMenuSelect: 'messageboard',
      showSearch: false
    }
  },
  props: ['drawer', 'logoutRoute', 'componentSelected'],
  methods: {
    getDisplayDist (dist) {
      if (dist > 1000) {
        return (Math.round(dist / 100) / 10) + ' Km'
      } else {
        return (Math.round(dist * 10) / 10) + ' M '
      }
    },
    toggleDrawer () {
      this.drawerState = !this.drawer
      this.$emit('drawer-state-changed', this.drawerState)
    }
  },
  watch: {
    dashBoardMenuSelect (newValue) {
      this.$store.dispatch('dashBoardMenuSelect', newValue)
    },
    showExtend (newValue) {
      if (newValue) {
        this.$store.dispatch('showExtendAppBar', 50)
      } else {
        this.$store.dispatch('showExtendAppBar', 0)
      }
    }
  },
  computed: {
    loadComplete () {
      return this.$store.getters.dataLoadComplete
    },
    pictureSrc () {
      return this.$store.getters.getUserSelectOfficePictureSrc ? this.$store.getters.getUserSelectOfficePictureSrc : require('@/assets/postes-logo.jpg')
    },
    showExtend () {
      if (this.componentSelected === 'pmt-pm-babillard') {
        // this.$store.dispatch('showExtendAppBar', 50)
        return true
      } else {
        // this.$store.dispatch('showExtendAppBar', 0)
        return false
      }
    }
  },
  created () {
    // this.$store.getters.EventBus.$on('minDist', (newValue) => {
    //   this.minDist = newValue
    // })
    if (this.componentSelected === 'pmt-pm-babillard') {
      this.$store.dispatch('showExtendAppBar', 50)
      // return true
    } else {
      this.$store.dispatch('showExtendAppBar', 0)
      // return false
    }
  }
}
</script>
<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
