<template fixed>
  <div class="home">
    <v-card :style="$store.getters.contentStyle" class="overflow-y-auto overflow-x-hidden">
      <v-card-title>
        <v-row>
          <v-btn icon x-large @click="$store.getters.EventBus.$emit('selectLocalAdminComponent', ('pmt-menu-admin-local'))"><v-icon x-large>mdi-arrow-left-circle</v-icon></v-btn>
          <h3 class="mt-3">Menu</h3>
          <v-spacer></v-spacer>
          <!-- <v-btn class="me-3" icon x-large @click="showFilter = !showFilter"><v-icon x-large>{{ showFilter ? 'mdi-filter-outline' : 'mdi-filter' }}</v-icon></v-btn> -->
          <v-btn class="me-3" icon x-large @click="showCreateEditContact = true"><v-icon x-large>mdi-plus</v-icon></v-btn>
        </v-row>
      </v-card-title>
      <v-row><v-spacer></v-spacer><h2>Contact</h2><v-spacer></v-spacer></v-row>
      <v-list class="mt-3">
        <v-list-item
          v-for="contact in contactList"
          :key="contact.id"
        >
          <v-list-item-content two-line>
            <!-- <v-list-item-icon><v-icon color="primary" x-large>mdi-phone</v-icon></v-list-item-icon> -->
            <v-list-item-title>{{ contact.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ contact.detail }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-row no-gutters>
              <v-btn icon @click="showCreateEditContact = contact">
                <v-icon color="primary">mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon class="ms-2" @click="deleteContact(contact.id)">
                <v-icon color="red">mdi-delete</v-icon>
              </v-btn>
            </v-row>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
    <create-edit-contact
      v-if="showCreateEditContact"
      :contact="showCreateEditContact"
      @return-close="setContact($event)"
      @close="showCreateEditContact = null"
    >
    </create-edit-contact>
    <pmt-loading-local :loadingTitle="showLoadingTitle" :loadingShow="showLoading"></pmt-loading-local>
  </div>
</template>

<script>
export default {
  props: [],
  data () {
    return {
      showCreateEditContact: null,
      showLoading: false,
      showLoadingTitle: this.$t('common.saveinprogress')
    }
  },
  computed: {
    contactList () {
      return this.$store.getters['officeSelectedModule/getContactList']
    }
  },
  methods: {
    setContact (contact) {
      this.showLoading = true
      this.showCreateEditContact = null
      this.$store.dispatch('officeSelectedModule/saveContact', contact)
        .then(() => {
          this.showLoading = false
        })
    },
    deleteContact (contactId) {
      this.showLoading = true
      this.$store.dispatch('officeSelectedModule/deleteContact', contactId)
        .then(() => {
          this.showLoading = false
        })
    }
  }
}
</script>
