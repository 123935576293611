<template>
  <div class="home">
    <div v-if="m.type === 'routeMarker'" align="center" width="250" max-width="250" class="overflow-x-hidden mx-2">
      <p class="title pt-3" align="center">{{ m.title }}</p>
      <v-card color="yellow lighten-4" max-width="220" class="overflow-x-hidden pt-3">
        <p align="center" class="subtitle-2" v-if="m.stat.hou || m.stat.houV">{{ $t('common.dom') }} : {{ m.stat.houP + '/' + m.stat.hou + '   (' + m.stat.houV + ')' }}</p>
        <p align="center" class="subtitle-2" v-if="m.stat.app || m.stat.appV">{{ $t('common.app') }} : {{ m.stat.appP + '/' + m.stat.app + '   (' + m.stat.appV + ')' }}</p>
        <p align="center" class="subtitle-2" v-if="m.stat.bus || m.stat.busV">{{ $t('common.bus') }} : {{ m.stat.busP + '/' + m.stat.bus + '   (' + m.stat.busV + ')' }}</p>
        <p align="center" class="subtitle-2" v-if="m.stat.farm || m.stat.farmV">{{ $t('common.frm') }} : {{ m.stat.farmP + '/' + m.stat.farm + '   (' + m.stat.farmV + ')' }}</p>
        <p align="center" class="subtitle-2 pb-3">Total : {{ statSum.p + '/' + statSum.a + '   (' + statSum.v + ')' }}</p>
      </v-card>
      <v-row no-gutters class="mb-3 mt-4">
        <v-spacer></v-spacer>
        <v-btn x-large @click="loadRouteClicked(m)" >{{ $t('common.load') }}<v-icon class="ms-4">mdi-cloud-download</v-icon></v-btn>
        <v-spacer></v-spacer>
      </v-row>
    </div>
    <div v-if="m.type === 'pocMarker_back'" class="overflow-x-hidden pe-3">
      <v-app-bar dense :color="$store.getColorCode(m.colorId)" dark>
      <v-app-bar-nav-icon v-if="$store.getters.myAccess.employe" @click="showEditPdrId = m.pocId"><v-icon large>mdi-cog</v-icon></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click="$emit('close')"><v-icon large>mdi-close</v-icon></v-app-bar-nav-icon>
      </v-app-bar>
      <p dense class="subtitle-1 pt-2 mb-n1 text-center">{{ $store.state.pocModule.data[m.pocId].pdrListAddress }}</p>
      <p class="subtitle-2 text-center">{{ $store.state.pocModule.data[m.pocId].pdrListPostalCode }}</p>
      <v-card color="yellow lighten-4 pt-2">
        <p class="text-decoration-underline font-weight-bold title ms-3 mb-n1">{{ getPocTypeName($store.state.pocModule.data[m.pocId].pdrType) }}</p>
        <v-row xs12 no-gutters class="mb-n4 ms-2">
          <v-img contain max-width="30" :src="$store.state.pocModule.data[m.pocId].pub ? $store.getters.getAppIcon.pub : $store.getters.getAppIcon.noPub"></v-img>
          <p class="mt-3 ms-3 subtitle-2">{{ $store.state.pocModule.data[m.pocId].pub ? $t('common.pub') : $t('common.nopub') }}</p>
        </v-row>
        <v-row no-gutters class="ms-2">
          <v-img contain max-width="30" :src="$store.state.pocModule.data[m.pocId].vacant ? $store.getters.getAppIcon.vacant : $store.getters.getAppIcon.noVacant"></v-img>
          <p class="mt-3 ms-3 subtitle-2">{{ $store.state.pocModule.data[m.pocId].vacant ? $t('common.vacant') : $t('common.novacant') }}</p>
        </v-row>
      </v-card>
      <v-row no-gutters class="mb-3 mt-2">
        <v-spacer></v-spacer>
        <v-img
          contain
          width="100%"
          height="160"
          align="center"
          @click="$store.getters.EventBus.$emit('ShowPictureFullScreen', ($store.getters['pocModule/getPdrPictureHomeSrc']({ pdrId: m.pocId, width: 450, height: 300 })))"
          :src="$store.getters['pocModule/getPdrPictureHomeSrc']({ pdrId: m.pocId, width: 210, height: 140 })"
        ></v-img>
        <v-spacer></v-spacer>
      </v-row>
    </div>
    <div v-if="m.type === 'pocMarker' && mapMode === 'route-info'" class="overflow-x-hidden pe-3">
      <v-btn class="ms-n4 mt-n4" large absolute icon v-if="$store.getters.myAccess.employe" @click="showEditPdrId = m.pocId"><v-icon>mdi-cog</v-icon></v-btn>
      <p dense class="subtitle-1 pt-6 mb-n1 text-center">{{ $store.state.pocModule.data[m.pocId].pdrListAddress }}</p>
      <p class="subtitle-2 text-center">{{ $store.state.pocModule.data[m.pocId].pdrListPostalCode }}</p>
      <v-card color="yellow lighten-4 pt-2">
        <p class="text-decoration-underline font-weight-bold title ms-3 mb-n1">{{ getPocTypeName($store.state.pocModule.data[m.pocId].pdrType) }}</p>
        <v-row xs12 no-gutters class="mb-n4 ms-2">
          <v-img contain max-width="30" :src="$store.state.pocModule.data[m.pocId].pub ? $store.getters.getAppIcon.pub : $store.getters.getAppIcon.noPub"></v-img>
          <p class="mt-3 ms-3 subtitle-2">{{ $store.state.pocModule.data[m.pocId].pub ? $t('common.pub') : $t('common.nopub') }}</p>
        </v-row>
        <v-row no-gutters class="ms-2">
          <v-img contain max-width="30" :src="$store.state.pocModule.data[m.pocId].vacant ? $store.getters.getAppIcon.vacant : $store.getters.getAppIcon.noVacant"></v-img>
          <p class="mt-3 ms-3 subtitle-2">{{ $store.state.pocModule.data[m.pocId].vacant ? $t('common.vacant') : $t('common.novacant') }}</p>
        </v-row>
      </v-card>
      <v-row no-gutters class="mb-3 mt-2">
        <v-spacer></v-spacer>
        <v-img
          contain
          width="100%"
          height="160"
          align="center"
          @click="$store.getters.EventBus.$emit('ShowPictureFullScreen', ($store.getters['pocModule/getPdrPictureHomeSrc']({ pdrId: m.pocId, width: 450, height: 300 })))"
          :src="$store.getters['pocModule/getPdrPictureHomeSrc']({ pdrId: m.pocId, width: 210, height: 140 })"
        ></v-img>
        <v-spacer></v-spacer>
      </v-row>
    </div>
    <div v-if="m.type === 'placeMarker' && mapMode === 'route-info'" class="overflow-x-hidden pe-3">
      <v-btn class="ms-n4 mt-n4" large absolute icon v-if="$store.getters.myAccess.employe" @click="showEditPlaceId = m.placeId"><v-icon>mdi-cog</v-icon></v-btn>
      <p dense class="subtitle-1 pt-6 text-center">{{ $store.state.placeModule.data[m.placeId].lName }}</p>
      <!-- <p class="subtitle-1 mb-n1 text-right yellow lighten-4">{{ $store.state.pocModule.data[m.pocId].pdrListPostalCode }}</p> -->
      <v-btn @click="showPocOfPlace = m" class="mb-1" block large>{{  'Detail ' + $t('common.pdr')}}</v-btn>
      <v-card color="yellow lighten-4 pt-3">
        <p align="center" class="subtitle-2" v-if="m.stat.hou || m.stat.houV">{{ $t('common.dom') }} : {{ m.stat.houP + '/' + m.stat.hou + '   (' + m.stat.houV + ')' }}</p>
        <p align="center" class="subtitle-2" v-if="m.stat.app || m.stat.appV">{{ $t('common.app') }} : {{ m.stat.appP + '/' + m.stat.app + '   (' + m.stat.appV + ')' }}</p>
        <p align="center" class="subtitle-2" v-if="m.stat.bus || m.stat.busV">{{ $t('common.bus') }} : {{ m.stat.busP + '/' + m.stat.bus + '   (' + m.stat.busV + ')' }}</p>
        <p align="center" class="subtitle-2" v-if="m.stat.farm || m.stat.farmV">{{ $t('common.frm') }} : {{ m.stat.farmP + '/' + m.stat.farm + '   (' + m.stat.farmV + ')' }}</p>
        <p align="center" class="subtitle-2 pb-3">Total : {{ statSum.p + '/' + statSum.a + '   (' + statSum.v + ')' }}</p>
      </v-card>
      <v-row no-gutters class="mb-3">
        <v-spacer></v-spacer>
        <v-img
          contain
          width="100%"
          height="160"
          align="center"
          @click="$store.getters.EventBus.$emit('ShowPictureFullScreen', ($store.getters['placeModule/getPlacePictureHomeSrc']({ placeId: m.placeId, width: 450, height: 300 })))"
          :src="$store.getters['placeModule/getPlacePictureHomeSrc']({ placeId: m.placeId, width: 210, height: 140 })"
        ></v-img>
        <v-spacer></v-spacer>
      </v-row>
    </div>
    <div v-if="m.type === 'pocMarker' && mapMode === 'road-book'" class="overflow-x-hidden pe-3">
      <v-btn class="ms-n4 mt-n4" large absolute icon v-if="$store.getters.myAccess.employe" @click="showEditPdrId = m.pocId"><v-icon>mdi-cog</v-icon></v-btn>
      <p dense class="subtitle-1 pt-6 mb-n1 text-center">{{ $store.state.pocModule.data[m.pocId].pdrListAddress }}</p>
      <p class="subtitle-2 text-center">{{ $store.state.pocModule.data[m.pocId].pdrListPostalCode }}</p>
      <v-card color="yellow lighten-4 pt-2">
        <p class="text-decoration-underline font-weight-bold title ms-3 mb-n1">{{ getPocTypeName($store.state.pocModule.data[m.pocId].pdrType) }}</p>
        <v-row xs12 no-gutters class="mb-n4 ms-2">
          <v-img contain max-width="30" :src="$store.state.pocModule.data[m.pocId].pub ? $store.getters.getAppIcon.pub : $store.getters.getAppIcon.noPub"></v-img>
          <p class="mt-3 ms-3 subtitle-2">{{ $store.state.pocModule.data[m.pocId].pub ? $t('common.pub') : $t('common.nopub') }}</p>
        </v-row>
        <v-row no-gutters class="ms-2">
          <v-img contain max-width="30" :src="$store.state.pocModule.data[m.pocId].vacant ? $store.getters.getAppIcon.vacant : $store.getters.getAppIcon.noVacant"></v-img>
          <p class="mt-3 ms-3 subtitle-2">{{ $store.state.pocModule.data[m.pocId].vacant ? $t('common.vacant') : $t('common.novacant') }}</p>
        </v-row>
      </v-card>
      <v-row no-gutters class="mb-3 mt-2">
        <v-spacer></v-spacer>
        <v-img
          contain
          width="100%"
          height="160"
          align="center"
          @click="$store.getters.EventBus.$emit('ShowPictureFullScreen', ($store.getters['pocModule/getPdrPictureHomeSrc']({ pdrId: m.pocId, width: 450, height: 300 })))"
          :src="$store.getters['pocModule/getPdrPictureHomeSrc']({ pdrId: m.pocId, width: 210, height: 140 })"
        ></v-img>
        <v-spacer></v-spacer>
      </v-row>
    </div>
    <div v-if="m.type === 'placeMarker' && mapMode === 'road-book'" class="overflow-x-hidden pe-3">
      <v-btn class="ms-n4 mt-n4" large absolute icon v-if="$store.getters.myAccess.employe" @click="showEditPlaceId = m.placeId"><v-icon>mdi-cog</v-icon></v-btn>
      <p dense class="subtitle-1 pt-6 text-center">{{ $store.state.placeModule.data[m.placeId].lName }}</p>
      <!-- <p class="subtitle-1 mb-n1 text-right yellow lighten-4">{{ $store.state.pocModule.data[m.pocId].pdrListPostalCode }}</p> -->
      <v-btn @click="showPocOfPlace = m" class="mb-1" block large>{{  'Detail ' + $t('common.pdr')}}</v-btn>
      <v-card color="yellow lighten-4 pt-3">
        <p align="center" class="subtitle-2" v-if="m.stat.hou || m.stat.houV">{{ $t('common.dom') }} : {{ m.stat.houP + '/' + m.stat.hou + '   (' + m.stat.houV + ')' }}</p>
        <p align="center" class="subtitle-2" v-if="m.stat.app || m.stat.appV">{{ $t('common.app') }} : {{ m.stat.appP + '/' + m.stat.app + '   (' + m.stat.appV + ')' }}</p>
        <p align="center" class="subtitle-2" v-if="m.stat.bus || m.stat.busV">{{ $t('common.bus') }} : {{ m.stat.busP + '/' + m.stat.bus + '   (' + m.stat.busV + ')' }}</p>
        <p align="center" class="subtitle-2" v-if="m.stat.farm || m.stat.farmV">{{ $t('common.frm') }} : {{ m.stat.farmP + '/' + m.stat.farm + '   (' + m.stat.farmV + ')' }}</p>
        <p align="center" class="subtitle-2 pb-3">Total : {{ statSum.p + '/' + statSum.a + '   (' + statSum.v + ')' }}</p>
      </v-card>
      <v-row no-gutters class="mb-3">
        <v-spacer></v-spacer>
        <v-img
          contain
          width="100%"
          height="160"
          align="center"
          @click="$store.getters.EventBus.$emit('ShowPictureFullScreen', ($store.getters['placeModule/getPlacePictureHomeSrc']({ placeId: m.placeId, width: 450, height: 300 })))"
          :src="$store.getters['placeModule/getPlacePictureHomeSrc']({ placeId: m.placeId, width: 210, height: 140 })"
        ></v-img>
        <v-spacer></v-spacer>
      </v-row>
    </div>
    <div v-if="m.type === 'pocMarker' && mapMode === 'direction'" class="overflow-x-hidden pe-3">
      <v-btn class="ms-n4 mt-n4" large absolute icon v-if="$store.getters.myAccess.employe" @click="showEditPdrId = m.pocId"><v-icon>mdi-cog</v-icon></v-btn>
      <p dense class="subtitle-1 pt-6 mb-n1 text-center">{{ $store.state.pocModule.data[m.pocId].pdrListAddress }}</p>
      <p class="subtitle-2 text-center">{{ $store.state.pocModule.data[m.pocId].pdrListPostalCode }}</p>
      <v-card color="yellow lighten-4 pt-2">
        <p class="text-decoration-underline font-weight-bold title ms-3 mb-n1">{{ getPocTypeName($store.state.pocModule.data[m.pocId].pdrType) }}</p>
        <v-row xs12 no-gutters class="mb-n4 ms-2">
          <v-img contain max-width="30" :src="$store.state.pocModule.data[m.pocId].pub ? $store.getters.getAppIcon.pub : $store.getters.getAppIcon.noPub"></v-img>
          <p class="mt-3 ms-3 subtitle-2">{{ $store.state.pocModule.data[m.pocId].pub ? $t('common.pub') : $t('common.nopub') }}</p>
        </v-row>
        <v-row no-gutters class="ms-2">
          <v-img contain max-width="30" :src="$store.state.pocModule.data[m.pocId].vacant ? $store.getters.getAppIcon.vacant : $store.getters.getAppIcon.noVacant"></v-img>
          <p class="mt-3 ms-3 subtitle-2">{{ $store.state.pocModule.data[m.pocId].vacant ? $t('common.vacant') : $t('common.novacant') }}</p>
        </v-row>
      </v-card>
      <v-row no-gutters class="mb-3 mt-2">
        <v-spacer></v-spacer>
        <v-img
          contain
          width="100%"
          height="160"
          align="center"
          @click="$store.getters.EventBus.$emit('ShowPictureFullScreen', ($store.getters['pocModule/getPdrPictureHomeSrc']({ pdrId: m.pocId, width: 450, height: 300 })))"
          :src="$store.getters['pocModule/getPdrPictureHomeSrc']({ pdrId: m.pocId, width: 210, height: 140 })"
        ></v-img>
        <v-spacer></v-spacer>
      </v-row>
    </div>
    <div v-if="m.type === 'placeMarker' && mapMode === 'direction'" class="overflow-x-hidden pe-3">
      <v-btn class="ms-n4 mt-n4" large absolute icon v-if="$store.getters.myAccess.employe" @click="showEditPlaceId = m.placeId"><v-icon>mdi-cog</v-icon></v-btn>
      <p dense class="subtitle-1 pt-6 text-center">{{ $store.state.placeModule.data[m.placeId].lName }}</p>
      <!-- <p class="subtitle-1 mb-n1 text-right yellow lighten-4">{{ $store.state.pocModule.data[m.pocId].pdrListPostalCode }}</p> -->
      <v-btn @click="showPocOfPlace = m" class="mb-1" block large>{{  'Detail ' + $t('common.pdr')}}</v-btn>
      <v-card color="yellow lighten-4 pt-3">
        <p align="center" class="subtitle-2" v-if="m.stat.hou || m.stat.houV">{{ $t('common.dom') }} : {{ m.stat.houP + '/' + m.stat.hou + '   (' + m.stat.houV + ')' }}</p>
        <p align="center" class="subtitle-2" v-if="m.stat.app || m.stat.appV">{{ $t('common.app') }} : {{ m.stat.appP + '/' + m.stat.app + '   (' + m.stat.appV + ')' }}</p>
        <p align="center" class="subtitle-2" v-if="m.stat.bus || m.stat.busV">{{ $t('common.bus') }} : {{ m.stat.busP + '/' + m.stat.bus + '   (' + m.stat.busV + ')' }}</p>
        <p align="center" class="subtitle-2" v-if="m.stat.farm || m.stat.farmV">{{ $t('common.frm') }} : {{ m.stat.farmP + '/' + m.stat.farm + '   (' + m.stat.farmV + ')' }}</p>
        <p align="center" class="subtitle-2 pb-3">Total : {{ statSum.p + '/' + statSum.a + '   (' + statSum.v + ')' }}</p>
      </v-card>
      <v-row no-gutters class="mb-3">
        <v-spacer></v-spacer>
        <v-img
          contain
          width="100%"
          height="160"
          align="center"
          @click="$store.getters.EventBus.$emit('ShowPictureFullScreen', ($store.getters['placeModule/getPlacePictureHomeSrc']({ placeId: m.placeId, width: 450, height: 300 })))"
          :src="$store.getters['placeModule/getPlacePictureHomeSrc']({ placeId: m.placeId, width: 210, height: 140 })"
        ></v-img>
        <v-spacer></v-spacer>
      </v-row>
    </div>
    <div v-if="m.type === 'pocMarker' && mapMode === 'track-me'" class="overflow-x-hidden pe-3">
      <v-btn class="ms-n4 mt-n4" large absolute icon v-if="$store.getters.myAccess.employe" @click="showEditPdrId = m.pocId"><v-icon>mdi-cog</v-icon></v-btn>
      <p dense class="subtitle-1 pt-6 mb-n1 text-center">{{ $store.state.pocModule.data[m.pocId].pdrListAddress }}</p>
      <p class="subtitle-2 text-center">{{ $store.state.pocModule.data[m.pocId].pdrListPostalCode }}</p>
      <v-card color="yellow lighten-4 pt-2">
        <p class="text-decoration-underline font-weight-bold title ms-3 mb-n1">{{ getPocTypeName($store.state.pocModule.data[m.pocId].pdrType) }}</p>
        <v-row xs12 no-gutters class="mb-n4 ms-2">
          <v-img contain max-width="30" :src="$store.state.pocModule.data[m.pocId].pub ? $store.getters.getAppIcon.pub : $store.getters.getAppIcon.noPub"></v-img>
          <p class="mt-3 ms-3 subtitle-2">{{ $store.state.pocModule.data[m.pocId].pub ? $t('common.pub') : $t('common.nopub') }}</p>
        </v-row>
        <v-row no-gutters class="ms-2">
          <v-img contain max-width="30" :src="$store.state.pocModule.data[m.pocId].vacant ? $store.getters.getAppIcon.vacant : $store.getters.getAppIcon.noVacant"></v-img>
          <p class="mt-3 ms-3 subtitle-2">{{ $store.state.pocModule.data[m.pocId].vacant ? $t('common.vacant') : $t('common.novacant') }}</p>
        </v-row>
      </v-card>
      <v-row no-gutters class="mb-3 mt-2">
        <v-spacer></v-spacer>
        <v-img
          contain
          width="100%"
          height="160"
          align="center"
          @click="$store.getters.EventBus.$emit('ShowPictureFullScreen', ($store.getters['pocModule/getPdrPictureHomeSrc']({ pdrId: m.pocId, width: 450, height: 300 })))"
          :src="$store.getters['pocModule/getPdrPictureHomeSrc']({ pdrId: m.pocId, width: 210, height: 140 })"
        ></v-img>
        <v-spacer></v-spacer>
      </v-row>
    </div>
    <div v-if="m.type === 'placeMarker' && mapMode === 'track-me'" class="overflow-x-hidden pe-3">
      <v-btn class="ms-n4 mt-n4" large absolute icon v-if="$store.getters.myAccess.employe" @click="showEditPlaceId = m.placeId"><v-icon>mdi-cog</v-icon></v-btn>
      <p dense class="subtitle-1 pt-6 text-center">{{ $store.state.placeModule.data[m.placeId].lName }}</p>
      <!-- <p class="subtitle-1 mb-n1 text-right yellow lighten-4">{{ $store.state.pocModule.data[m.pocId].pdrListPostalCode }}</p> -->
      <v-btn @click="showPocOfPlace = m" class="mb-1" block large>{{  'Detail ' + $t('common.pdr')}}</v-btn>
      <v-card color="yellow lighten-4 pt-3" v-if="m.stat">
        <p align="center" class="subtitle-2" v-if="m.stat.hou || m.stat.houV">{{ $t('common.dom') }} : {{ m.stat.houP + '/' + m.stat.hou + '   (' + m.stat.houV + ')' }}</p>
        <p align="center" class="subtitle-2" v-if="m.stat.app || m.stat.appV">{{ $t('common.app') }} : {{ m.stat.appP + '/' + m.stat.app + '   (' + m.stat.appV + ')' }}</p>
        <p align="center" class="subtitle-2" v-if="m.stat.bus || m.stat.busV">{{ $t('common.bus') }} : {{ m.stat.busP + '/' + m.stat.bus + '   (' + m.stat.busV + ')' }}</p>
        <p align="center" class="subtitle-2" v-if="m.stat.farm || m.stat.farmV">{{ $t('common.frm') }} : {{ m.stat.farmP + '/' + m.stat.farm + '   (' + m.stat.farmV + ')' }}</p>
        <p align="center" class="subtitle-2 pb-3">Total : {{ statSum.p + '/' + statSum.a + '   (' + statSum.v + ')' }}</p>
      </v-card>
      <v-row no-gutters class="mb-3">
        <v-spacer></v-spacer>
        <v-img
          contain
          width="100%"
          height="160"
          align="center"
          @click="$store.getters.EventBus.$emit('ShowPictureFullScreen', ($store.getters['placeModule/getPlacePictureHomeSrc']({ placeId: m.placeId, width: 450, height: 300 })))"
          :src="$store.getters['placeModule/getPlacePictureHomeSrc']({ placeId: m.placeId, width: 210, height: 140 })"
        ></v-img>
        <v-spacer></v-spacer>
      </v-row>
    </div>
    <div v-if="m.type === 'pocMapItemMarker'" class="overflow-x-hidden pe-3">
      <v-btn class="ms-n4 mt-n4" large absolute icon v-if="$store.getters.myAccess.employe" @click="showEditPocItem = m"><v-icon>mdi-cog</v-icon></v-btn>
      <p dense class="subtitle-1 pt-6 mb-n1 text-center">{{ m.address }}</p>
      <p dense class="subtitle-1 text-center">{{ m.name ? m.name : $t('pocitem.' + m.itemType) }}</p>
      <p v-if="m.desc" class="subtitle-1 py-2 yellow lighten-4" align="center">{{ m.desc }}</p>
      <v-row no-gutters class="mb-3" v-if="m.imageList && m.imageList.length">
        <v-spacer></v-spacer>
        <v-img
          contain
          width="100%"
          height="160"
          align="center"
          @click="$store.getters.EventBus.$emit('ShowImageListFullScreen', m.imageList)"
          :src="(m.imageList.filter(x => x.isPrimary))[0].url"
        ></v-img>
        <v-spacer></v-spacer>
      </v-row>
    </div>
    <div v-if="m.type === 'multiItemMarker'" class="overflow-x-hidden pe-3">
      <v-btn class="ms-n4 mt-n4" large absolute icon v-if="$store.getters.myAccess.employe" @click="showEditPocItem = m"><v-icon>mdi-cog</v-icon></v-btn>
      <p dense class="subtitle-1 pt-6 text-center">{{ m.name ? m.name : $t('pocitem.' + m.itemType) }}</p>
      <p dense v-if="m.siteId" class="subtitle-2 text-center">{{ m.siteId }}</p>
      <p v-if="m.desc" class="subtitle-1 py-2 yellow lighten-4" align="center">{{ m.desc }}</p>
      <v-row no-gutters class="mb-3" v-if="m.imageList && m.imageList.length">
        <v-spacer></v-spacer>
        <v-img
          contain
          width="100%"
          align="center"
          @click="$store.getters.EventBus.$emit('ShowImageListFullScreen', m.imageList)"
          :src="(m.imageList.filter(x => x.isPrimary))[0].url"
        ></v-img>
        <v-spacer></v-spacer>
      </v-row>
      <div v-if="m.itemType === 'cmb'" class="mb-8">
        <p class="subtitle-2 ms-3">{{ $t('common.udl') }} : {{ $store.state.officeItemMultiModule.data[m.multiId].udl.length }}</p>
        <v-dialog
          v-model="bpComDialog"
          fullscreen
          hide-overlay
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              block
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              {{ $t('common.udl') }} detail
            </v-btn>
          </template>
          <v-card>
            <v-toolbar
              dark
              color="primary"
              height="60"
            >
              <v-spacer></v-spacer>
              <v-toolbar-title>{{ $store.state.officeItemMultiModule.data[m.multiId].siteId }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                x-large
                icon
                dark
                @click="bpComDialog = false"
              >
                <v-icon x-large>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <display-bpcom
              :bpCom="$store.state.officeItemMultiModule.data[m.multiId]"
              :height="$store.getters.screenSize.height - 60"
              @selectRta="selectRta($event)"
            ></display-bpcom>
          </v-card>
        </v-dialog>
      </div>
      <div v-else>
        <p class="subtitle-2 ms-3">{{ $t('common.pdr') }} :</p>
        <v-card class="mb-3 mt-n1 px-n2 overflow-y-auto yellow lighten-4" max-height="150">
          <v-list>
            <v-list-item class="my-n2 yellow lighten-4" v-for="pocId in $store.state.officeItemMultiModule.data[m.multiId].pocIdList" :key="pocId">
              <p align="start">{{ $store.state.pocModule.data[pocId].pdrListAddress }}</p>
            </v-list-item>
          </v-list>
        </v-card>
      </div>
    </div>
    <div v-if="m.type === 'cmb'" class="overflow-x-hidden pe-3">
      <!-- <v-btn class="ms-n4 mt-n4" large absolute icon v-if="$store.getters.myAccess.employe" @click="showEditPocItem = m"><v-icon>mdi-cog</v-icon></v-btn> -->
      <p dense class="subtitle-2 pt-6 text-center">{{ m.siteId }}</p>
      <p dense class="subtitle-1 text-center">{{ m.name ? m.name : $t('pocitem.' + m.type) }}</p>
      <p v-if="m.desc" class="subtitle-1 py-2 yellow lighten-4" align="center">{{ m.desc }}</p>
      <v-row no-gutters class="mb-3" v-if="m.imageList && m.imageList.length">
        <v-spacer></v-spacer>
        <v-img
          contain
          width="100%"
          align="center"
          @click="$store.getters.EventBus.$emit('ShowImageListFullScreen', m.imageList)"
          :src="(m.imageList.filter(x => x.isPrimary))[0].url"
        ></v-img>
        <v-spacer></v-spacer>
      </v-row>
      <p class="subtitle-2 ms-3">{{ $t('common.udl') }} : {{ m.udl.length }}</p>
      <v-dialog
        v-model="bpComDialog"
        fullscreen
        hide-overlay
        class="mb-6"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            block
            color="primary"
            dark
            class="mb-6"
            v-bind="attrs"
            v-on="on"
          >
            {{ $t('common.udl') }} Detail
          </v-btn>
        </template>
        <v-card>
          <v-toolbar
            dark
            color="primary"
            height="60"
          >
            <v-spacer></v-spacer>
            <v-toolbar-title>{{ m.siteId }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              x-large
              icon
              dark
              @click="bpComDialog = false"
            >
              <v-icon x-large>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <display-bpcom
            :bpCom="m"
            :height="$store.getters.screenSize.height - 60"
            @selectRta="selectRta($event)"
          ></display-bpcom>
        </v-card>
      </v-dialog>
    </div>
    <div v-if="m.type === 'greyBoxMarker'" class="overflow-x-hidden pe-3" :background-color="m.group">
      <v-btn class="ms-n4 mt-n4" large absolute icon v-if="$store.getters.myAccess.employe" @click="showEditOfficeItemId = m.id"><v-icon>mdi-cog</v-icon></v-btn>
      <p dense :class="'subtitle-1 mt-6 text-center ' + m.group">{{ m.name }}</p>
      <p v-if="m.desc" class="subtitle-1 py-2 yellow lighten-4" align="center">{{ m.desc }}</p>
      <v-row no-gutters class="mb-3" v-if="m.imageList && m.imageList.length">
        <v-spacer></v-spacer>
        <v-img
          contain
          width="100%"
          height="160"
          align="center"
          @click="$store.getters.EventBus.$emit('ShowImageListFullScreen', m.imageList)"
          :src="(m.imageList.filter(x => x.isPrimary))[0].url"
        ></v-img>
        <v-spacer></v-spacer>
      </v-row>
    </div>
    <div v-if="m.type === 'toiletMarker'" class="overflow-x-hidden pe-3">
      <v-btn class="ms-n4 mt-n4" large absolute icon v-if="$store.getters.myAccess.employe" @click="showEditOfficeItemId = m.id"><v-icon>mdi-cog</v-icon></v-btn>
      <p dense class="subtitle-1 pt-6 text-center">{{ m.name }}</p>
      <p v-if="m.description" class="subtitle-1 py-2 yellow lighten-4" align="center">{{ m.description }}</p>
      <v-row no-gutters class="mb-3" v-if="m.imageList && m.imageList.length">
        <v-spacer></v-spacer>
        <v-img
          contain
          width="100%"
          height="160"
          align="center"
          @click="$store.getters.EventBus.$emit('ShowImageListFullScreen', m.imageList)"
          :src="(m.imageList.filter(x => x.isPrimary))[0].url"
        ></v-img>
        <v-spacer></v-spacer>
      </v-row>
    </div>
    <div v-if="m.type === 'redBoxMarker'" class="overflow-x-hidden pe-3">
      <v-btn class="ms-n4 mt-n4" large absolute icon v-if="$store.getters.myAccess.employe" @click="showEditOfficeItemId = m.id"><v-icon>mdi-cog</v-icon></v-btn>
      <p dense class="subtitle-1 pt-6 text-center">{{ m.name }}</p>
      <v-divider class="mt-2 mb-2"></v-divider>
      <v-row no-gutters class="mb-3" v-if="m.imageList && m.imageList.length">
        <v-spacer></v-spacer>
        <v-img
          contain
          width="100%"
          height="160"
          align="center"
          @click="$store.getters.EventBus.$emit('ShowImageListFullScreen', m.imageList)"
          :src="(m.imageList.filter(x => x.isPrimary))[0].url"
        ></v-img>
        <v-spacer></v-spacer>
      </v-row>
      <v-card v-if="m.route && m.route.length" color="yellow lighten-4" class="mb-5" >
        <v-card-title>{{ $t('common.useby') }} :</v-card-title>
        <v-card-text>
          {{ m.route.map(x => { return $store.state.routeModule.data[x].name }).toString() }}
        </v-card-text>
      </v-card>
      <p dense class="subtitle-2 text-center" v-if="m.refNumber">ref: {{ m.refNumber }}</p>
    </div>
    <div v-if="m.type === 'parcelLockerMarker'" class="overflow-x-hidden pe-3">
      <v-btn class="ms-n4 mt-n4" large absolute icon v-if="$store.getters.myAccess.employe" @click="showEditOfficeItemId = m.id"><v-icon>mdi-cog</v-icon></v-btn>
      <p dense class="subtitle-1 pt-6 text-center">{{ m.name }}</p>
      <v-divider class="mt-2 mb-2"></v-divider>
      <v-row no-gutters class="mb-3" v-if="m.imageList && m.imageList.length">
        <v-spacer></v-spacer>
        <v-img
          contain
          width="100%"
          height="160"
          align="center"
          @click="$store.getters.EventBus.$emit('ShowImageListFullScreen', m.imageList)"
          :src="(m.imageList.filter(x => x.isPrimary))[0].url"
        ></v-img>
        <v-spacer></v-spacer>
      </v-row>
      <v-card v-if="m.route && m.route.length" color="yellow lighten-4" class="mb-5" >
        <v-card-title>{{ $t('common.useby') }} :</v-card-title>
        <v-card-text>
          {{ m.route.map(x => { return $store.state.routeModule.data[x].name }).toString() }}
        </v-card-text>
      </v-card>
      <p dense class="subtitle-2 text-center" v-if="m.refNumber">ref: {{ m.refNumber }}</p>
    </div>
    <div v-if="m.type === 'postManMarker'" class="overflow-x-hidden pe-3">
      <!-- <v-btn class="ms-n4 mt-n4" large absolute icon v-if="$store.getters.myAccess.employe" @click="showEditOfficeItemId = m.id"><v-icon>mdi-cog</v-icon></v-btn> -->
      <p dense class="title pt-6">{{ user.lname }}, {{ user.fname }}</p>
      <p dense class="subtitle-1">{{ user.empid }}</p>
      <p dense class="subtitle-1">{{ $t('usertype.' + user.userType) }}</p>
    </div>
    <div v-if="m.type === 'alertMarker'" class="overflow-x-hidden pe-3">
      <v-btn class="ms-n4 mt-n4" large absolute icon v-if="$store.getters.myAccess.employe" @click="showReportEditor = m.reportId"><v-icon>mdi-cog</v-icon></v-btn>
      <p dense class="title pt-6 text-center">{{ $t('createreport.' + m.reportType) }}</p>
      <v-row no-gutters class="mb-3" v-if="$store.state.reportModule.data[m.reportId].imageList && $store.state.reportModule.data[m.reportId].imageList.length">
        <v-spacer></v-spacer>
        <v-img
          contain
          width="100%"
          height="160"
          align="center"
          @click="$store.getters.EventBus.$emit('ShowImageListFullScreen', $store.state.reportModule.data[m.reportId].imageList)"
          :src="($store.state.reportModule.data[m.reportId].imageList.filter(x => x.isPrimary))[0].url"
        ></v-img>
        <v-spacer></v-spacer>
      </v-row>
      <p align="center" class="red--text title">Status : {{ $t('report-Status.' + $store.state.reportModule.data[m.reportId].status) }}</p>
      <p v-if="$store.state.reportModule.data[m.reportId].desc" class="subtitle-1 py-2 yellow lighten-4" align="center">{{ $store.state.reportModule.data[m.reportId].desc }}</p>
      <v-divider></v-divider>
      <div v-if="$store.state.reportModule.data[m.reportId].note && $store.state.reportModule.data[m.reportId].note.length">
        <p v-for="(note, i) in $store.state.reportModule.data[m.reportId].note" :key="i" class="subtitle-1 py-2 yellow lighten-4" align="center">{{ note.desc }}</p>
      </div>
    </div>
    <pmt-edit-pdr-popup
      v-if="showEditPdrId"
      :pdrId="showEditPdrId"
      @close="showEditPdrId = null"
    ></pmt-edit-pdr-popup>
    <pmt-officeitem-editor
      v-if="showEditOfficeItemId"
      :officeItemId="showEditOfficeItemId"
      @close="showEditOfficeItemId = null"
    ></pmt-officeitem-editor>
    <pmt-edit-place-popup
      v-if="showEditPlaceId"
      :placeId="showEditPlaceId"
      :colorId="m.colorId"
      @close="showEditPlaceId = null"
    ></pmt-edit-place-popup>
    <!-- <pmt-pocofplace-popup
      v-if="showPocOfPlace"
      :placeId="showPocOfPlace.placeId ? showPocOfPlace.placeId : null"
      :routeId="showPocOfPlace.routeId ? showPocOfPlace.routeId : null"
      :sequenceId="showPocOfPlace.sequenceId !== undefined ? showPocOfPlace.sequenceId : null"
      :codeId="showPocOfPlace.codeId ? showPocOfPlace.codeId : null"
      :colorId="showPocOfPlace.colorId ? showPocOfPlace.colorId : null"
      @close="showPocOfPlace = null"
    ></pmt-pocofplace-popup> -->
    <pmt-pocofplace-popup
      v-if="showPocOfPlace"
      :placeId="showPocOfPlace.placeId ? showPocOfPlace.placeId : null"
      :routeId="showPocOfPlace.routeId ? showPocOfPlace.routeId : null"
      @close="showPocOfPlace = null"
    ></pmt-pocofplace-popup>
    <pmt-report-editor
      v-if="showReportEditor"
      :reportId="showReportEditor"
      @close="showReportEditor = null"
    ></pmt-report-editor>
    <pmt-edit-pocItem
      v-if="showEditPocItem"
      :pocId="showEditPocItem.pocId !== undefined ? showEditPocItem.pocId : null"
      :itemId="showEditPocItem.itemId !== undefined ? showEditPocItem.itemId : null"
      :multiItemId="showEditPocItem.multiId !== undefined ? showEditPocItem.multiId : null"
      :color="$store.getColorCode(showEditPocItem.color)"
      @return-close="showEditPocItem = null"
    ></pmt-edit-pocItem>
  </div>
</template>

<script>
export default {
  props: ['m', 'mapMode'],
  data () {
    return {
      showEditPdrId: null,
      showEditPlaceId: null,
      showPocOfPlace: null,
      showEditPocItem: null,
      showEditOfficeItemId: null,
      showReportEditor: null,
      bpComDialog: false
    }
  },
  methods: {
    loadRouteClicked (m) {
      // // console.log('load route clicked = ' + m.title)
      this.$emit('doTask', { taskType: 'loadRoute', routeId: m.routeId, routeName: m.title })
    },
    selectRta (rta) {
      if (rta.pocId) {
        this.showEditPdrId = rta.pocId
      }
    },
    getPocTypeName (type) {
      switch (type) {
        case 'Hou/Dom':
          return this.$t('common.dom')
        case 'Apt/App':
          return this.$t('common.app')
        case 'Bus/com':
          return this.$t('common.bus')
        default:
          return this.$t('common.frm')
      }
    }
  },
  computed: {
    statSum () {
      const m = this.m
      switch (m.type) {
        case 'routeMarker':
          return {
            v: (m.stat.houV + m.stat.appV + m.stat.busV + m.stat.farmV),
            p: (m.stat.houP + m.stat.appP + m.stat.busP + m.stat.farmP),
            a: (m.stat.hou + m.stat.app + m.stat.bus + m.stat.farm)
          }
        case 'placeMarker':
          return {
            v: (m.stat.houV + m.stat.appV + m.stat.busV + m.stat.farmV),
            p: (m.stat.houP + m.stat.appP + m.stat.busP + m.stat.farmP),
            a: (m.stat.hou + m.stat.app + m.stat.bus + m.stat.farm)
          }
        default:
          return null
      }
    },
    user () {
      return this.$store.state.userLoggedModule.data
    }
  }
}
</script>
