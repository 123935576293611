const mailModule = {
  firestorePath: 'mail',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'mailModule',
  statePropName: 'data',
  namespaced: true, // automatically added

  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {},
  mutations: {},
  actions: {
    sendNoticeMail ({ commit, rootGetters, rootState }, payload) {
      const message = {
        to: [],
        message: {
          subject: 'Hello from Firebase!',
          text: 'This is the plaintext section of the email body.',
          html: 'This is the <code>HTML</code> section of the email body.'
        }
      }
      let poc
      const me = rootState.userLoggedModule.data.empid + ' - ' + rootState.userLoggedModule.data.fname + ' ' + rootState.userLoggedModule.data.lname
      const adv = rootGetters['officeMailNoticeModule/getEmailAdvise']
      switch (payload.type) {
        case 'pocVacantUpdate':
          poc = rootState.pocModule.data[payload.pocId]
          if (adv.pocVacantUpdate.length) {
            message.to = adv.pocVacantUpdate.map(function (x) { return x.email })
            message.message.subject = '(VACANT) Poc update notice / Avis de modification de Pdr'
            message.message.html = '<hr /><table><tbody><tr><td><h3><strong>'
            message.message.html += poc.pdrListAddress
            message.message.html += '</strong></h3></td></tr><tr><td>'
            message.message.html += poc.pdrListPostalCode
            message.message.html += '</td></tr></tbody></table><hr /><table><tbody><tr><td>Value / Valeur :</td><td><h2><span style="color: #ff0000;"><strong>'
            message.message.html += payload.vacant ? 'Vacant' : 'Occuped / occupé'
            message.message.html += '</strong></span></h2></td></tr></tbody></table><hr /><table><tbody><tr><td><table><tbody><tr><td><p>Route :</p></td><td>'
            for (let i = 0; i < poc.route.length; i++) {
              message.message.html += '<p>' + rootState.routeModule.data[poc.route[i]].name + '</p>'
            }
            message.message.html += '</td></tr></tbody></table></td></tr></tbody></table><hr /><p>Updated By / Mis à jour par :</p><table><tbody><tr><td>'
            message.message.html += me
            message.message.html += '</td></tr></tbody></table><p><a href="https://www.ptool.ca" target="_blank" rel="noopener">Open / Ouvrir</a></p>'
            this.dispatch('mailModule/set', message)
          }
          break
        case 'pocPubUpdate':
          poc = rootState.pocModule.data[payload.pocId]
          if (adv.pocPubUpdate.length) {
            message.to = adv.pocPubUpdate.map(function (x) { return x.email })
            message.message.subject = '(PUB) Poc update notice / Avis de modification de Pdr'
            message.message.html = '<hr /><table><tbody><tr><td><h3><strong>'
            message.message.html += poc.pdrListAddress
            message.message.html += '</strong></h3></td></tr><tr><td>'
            message.message.html += poc.pdrListPostalCode
            message.message.html += '</td></tr></tbody></table><hr /><table><tbody><tr><td>Value / Valeur :</td><td><h2><span style="color: #ff0000;"><strong>'
            message.message.html += payload.pub ? 'Circular accepted / Circulaire accepté' : 'Circulaire refusé / Circular refused'
            message.message.html += '</strong></span></h2></td></tr></tbody></table><hr /><table><tbody><tr><td><table><tbody><tr><td><p>Route :</p></td><td>'
            for (let i = 0; i < poc.route.length; i++) {
              message.message.html += '<p>' + rootState.routeModule.data[poc.route[i]].name + '</p>'
            }
            message.message.html += '</td></tr></tbody></table></td></tr></tbody></table><hr /><p>Updated By / Mis à jour par :</p><table><tbody><tr><td>'
            message.message.html += me
            message.message.html += '</td></tr></tbody></table><p><a href="https://www.ptool.ca" target="_blank" rel="noopener">Open / Ouvrir</a></p>'
            this.dispatch('mailModule/set', message)
          }
          break
        case 'pocItemTypeUpdate':
          poc = rootState.pocModule.data[payload.pocId]
          if (adv.pocItemTypeUpdate.length) {
            message.to = adv.pocItemTypeUpdate.map(function (x) { return x.email })
            message.message.subject = 'pocItemTypeUpdate Notice'
            message.message.subject = '(Type) Poc update notice / Avis de modification de Pdr'
            message.message.html = '<hr /><table><tbody><tr><td><h3><strong>'
            message.message.html += poc.pdrListAddress
            message.message.html += '</strong></h3></td></tr><tr><td>'
            message.message.html += poc.pdrListPostalCode
            message.message.html += '</td></tr></tbody></table><hr /><table><tbody><tr><td>Value / Valeur :</td><td><h2><span style="color: #ff0000;"><strong>'
            message.message.html += payload.oldType + ' -> ' + payload.newType
            message.message.html += '</strong></span></h2></td></tr></tbody></table><hr /><table><tbody><tr><td><table><tbody><tr><td><p>Route :</p></td><td>'
            for (let i = 0; i < poc.route.length; i++) {
              message.message.html += '<p>' + rootState.routeModule.data[poc.route[i]].name + '</p>'
            }
            message.message.html += '</td></tr></tbody></table></td></tr></tbody></table><hr /><p>Updated By / Mis à jour par :</p><table><tbody><tr><td>'
            message.message.html += me
            message.message.html += '</td></tr></tbody></table><p><a href="https://www.ptool.ca" target="_blank" rel="noopener">Open / Ouvrir</a></p>'
            this.dispatch('mailModule/set', message)
            this.dispatch('mailModule/set', message)
          }
          break
        case 'reportCreate':
          if (adv.reportCreate.length) {
            message.to = adv.reportCreate.map(function (x) { return x.email })
            message.message.subject = 'reportCreate Notice'
            this.dispatch('mailModule/set', message)
          }
          break
        default:
          break
      }
    }
  }
}

export default mailModule
