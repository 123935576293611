
import { arrayUnion } from 'vuex-easy-firestore'

const officeModule = {
  firestorePath: 'office',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'officeModule',
  statePropName: 'data',
  namespaced: true, // automatically added

  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    sortedOfficeByName: (state) => {
      return Object.values(state.data).sort(function (a, b) {
        return a.name - b.name
      }) // your sort function
    },
    getOfficeItems: (state, getters) => {
      const items = []
      getters.sortedOfficeByName.forEach(office => {
        items.push({ text: office.name, value: { id: office.id, name: office.name, position: { lat: office.location[0].loc.latitude, lng: office.location[0].loc.longitude } } })
      })
      return items
    },
    getOffice: (state) => (officeId) => {
      return state.data[officeId]
    },
    getOfficeIdByCp: (state) => (cp) => {
      let officeId = null
      const tCp = cp.replace(/ /g, '')
      for (const id in state.data) {
        if (state.data[id].cp === tCp) {
          officeId = id
        }
      }
      return officeId
    },
    getOfficeAddress: (state) => (officeId) => {
      const office = state.data[officeId]

      const address = {
        city: null,
        province: null
      }
      for (let x = 0; x < office.gaddress.length; x++) {
        const item = office.gaddress[x]
        for (let i = 0; i < item.types.length; i++) {
          const type = item.types[i]
          if (type === 'administrative_area_level_1') {
            address.province = { lname: item.long_name, sname: item.short_name }
          }
          if (type === 'locality') {
            address.city = { lname: item.long_name, sname: item.short_name }
          }
        }
      }
      return address
    },
    getOfficeToiletMarker: (state, getters) => (officeId, google) => {
      const toiletMarker = []
      // const google = this.$store.getters.getGoogle
      for (const toilet of state.data[officeId].toilet) {
        const marker = new google.maps.Marker({
          position: { lat: toilet.location.latitude, lng: toilet.location.longitude },
          map: null,
          icon: { // url:mdi-toilet,
            url: require('@/assets/Icon/MapIcon/human-male-female.png'),
            scaledSize: new google.maps.Size(48, 48), // scaled size
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(24, 24) // anchor
          }
        })
        toiletMarker.push(marker)
      }
      return toiletMarker
    },
    getOfficeGreyBoxMarker: (state) => (officeId, google, map) => {
      const greyBoxMarker = []
      // const google = this.$store.getters.getGoogle
      for (const greyBox of state.data[officeId].greybox) {
        if (greyBox.isActive) {
          const marker = new google.maps.Marker({
            position: greyBox.position,
            // label: greyBox.name,
            map: null,
            icon: { // url:mdi-toilet,
              url: require('@/assets/Icon/MapIcon/GreyBox.png'),
              scaledSize: new google.maps.Size(48, 48), // scaled size
              origin: new google.maps.Point(0, 0), // origin
              anchor: new google.maps.Point(24, 24) // anchor
            }
          })
          const infowindow = new google.maps.InfoWindow({
            content: greyBox.name
          })
          marker.addListener('click', function () {
            infowindow.open(map, marker)
          })
          greyBoxMarker.push(marker)
        }
      }
      return greyBoxMarker
    },
    getGreyBoxActiveList: (state) => (officeId) => {
      const greyBoxList = []
      for (const greyBox of state.data[officeId].greybox) {
        if (greyBox.isActive) {
          greyBoxList.push(greyBox)
        }
      }
      return greyBoxList
    },
    getGreyBox: (state, getters) => (officeId) => {
      return []
    },
    getOfficeMarker: (state, getters, rootState, rootGetters) => {
      const google = rootGetters.getGoogle
      // const EventBus = rootGetters.EventBus
      const bounds = new google.maps.LatLngBounds(rootGetters['zoneModule/getSelectedZonePosition'])
      const officeMarker = []
      for (const officeId in state.data) {
        const office = state.data[officeId]
        let loc
        for (let x = 0; x < office.location.length; x++) {
          if (office.location[x].desc === 'home') {
            loc = office.location[x].loc
            break
          }
        }
        bounds.extend({ lat: loc.latitude, lng: loc.longitude })
        // const offMarker = new google.maps.Marker({
        //   position: { lat: loc.latitude, lng: loc.longitude },
        //   label: { text: office.name, color: 'blue', fontSize: '16px' },
        //   title: office.name,
        //   icon: {
        //     url: require('@/assets/logo.png'),
        //     // size: new this.google.maps.Size(48, 48),
        //     scaledSize: new google.maps.Size(60, 60), // scaled size
        //     origin: new google.maps.Point(0, 0), // origin
        //     anchor: new google.maps.Point(24, 28), // anchor
        //     // labelOrigin: new this.google.maps.Point(24, 32)
        //     labelOrigin: new google.maps.Point(30, -5)
        //   }
        // })
        // if (officeId === this.$store.state.userModule.data[this.$store.state.userinfokey].officeselected) {
        //   offMarker.setMap(this.map)
        // }
        // const officeInfo = { id: officeId, name: office.name, zone: office.zone }
        // if (this.officeSelectId === officeInfo.id) { this.select.office = officeInfo }

        // offMarker.addListener('click', function () {
        //   EventBus.$emit('officeClick', (officeId))
        // })
        // officeMarker.push({ id: officeId, name: office.name, zone: office.zone, marker: offMarker })
        const isNotLoadedOffice = rootGetters.getSelectedOfficeId !== officeId
        officeMarker.push({
          type: 'officeMarker',
          label: { text: office.name, fontSize: '18px', color: isNotLoadedOffice ? 'red' : 'green' },
          title: office.name,
          url: require('@/assets/logo.png'),
          position: { lat: loc.latitude, lng: loc.longitude },
          officeId: officeId,
          isOpenInfoWindow: isNotLoadedOffice
        })
        // officeMarker.push(offMarker)
      }
      return { marker: officeMarker, bounds: bounds.toJSON() }
    },
    getOfficeMarkerData: (state, getters, rootState, rootGetters) => {
      const google = rootGetters.getGoogle
      // const EventBus = rootGetters.EventBus
      const bounds = new google.maps.LatLngBounds(rootGetters['zoneModule/getSelectedZonePosition'])
      const officeMarker = []
      for (const officeId in state.data) {
        const office = state.data[officeId]
        let loc
        for (let x = 0; x < office.location.length; x++) {
          if (office.location[x].desc === 'home') {
            loc = office.location[x].loc
            break
          }
        }
        bounds.extend({ lat: loc.latitude, lng: loc.longitude })
        const isNotLoadedOffice = rootGetters.getSelectedOfficeId !== officeId
        const offMarker = new google.maps.Marker({
          position: { lat: loc.latitude, lng: loc.longitude },
          label: { text: office.name, color: isNotLoadedOffice ? 'red' : 'green', fontSize: '16px' },
          title: office.name,
          zIndex: 2000,
          icon: {
            url: require('@/assets/logo.png'),
            // size: new this.google.maps.Size(48, 48),
            scaledSize: new google.maps.Size(60, 60), // scaled size
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(24, 28), // anchor
            // labelOrigin: new this.google.maps.Point(24, 32)
            labelOrigin: new google.maps.Point(30, -5)
          }
        })
        // if (officeId === this.$store.state.userModule.data[this.$store.state.userinfokey].officeselected) {
        //   offMarker.setMap(this.map)
        // }
        // const officeInfo = { id: officeId, name: office.name, zone: office.zone }
        // if (this.officeSelectId === officeInfo.id) { this.select.office = officeInfo }

        // offMarker.addListener('click', function () {
        //   EventBus.$emit('officeClick', (officeId))
        // })
        // officeMarker.push({ id: officeId, name: office.name, zone: office.zone, marker: offMarker })

        officeMarker.push({
          type: 'officeMarker',
          officeId: officeId,
          isOpenInfoWindow: isNotLoadedOffice,
          marker: offMarker
        })
        // officeMarker.push(offMarker)
      }
      return { markerList: officeMarker, bounds: bounds.toJSON() }
    }
  },
  mutations: {},
  actions: {
    importHarmonize ({ commit, getters }, payload) {
      const office = getters.getOffice(payload.officeId)
      var upDateParam
      if (office.harmonize) {
        upDateParam = {
          id: office.id,
          harmonize: arrayUnion(payload.harmonize)
        }
      } else {
        upDateParam = {
          id: office.id,
          harmonize: [payload.harmonize]
        }
      }
      this.dispatch('officeModule/set', upDateParam)
        .then((OfficeId) => {
          payload.EventBus.$emit('importharmonizeSuccess')
        })
        .catch((err) => {
          payload.EventBus.$emit('importharmonizeError', err.message)
        })
    },
    createGreyBox ({ commit, getters }, payload) {
      const office = getters.getOffice(payload.id)

      var newGBoxValue
      if (office.greybox) {
        newGBoxValue = {
          id: office.id,
          greybox: arrayUnion(payload.greybox)
        }
      } else {
        newGBoxValue = {
          id: office.id,
          greybox: [payload.greybox]
        }
      }
      this.dispatch('officeModule/set', newGBoxValue)
        .then((OfficeId) => {
          payload.EventBus.$emit('createGreyBoxSucces')
        })
        .catch((err) => {
          payload.EventBus.$emit('createGreyBoxError', err.message)
        })
    },
    updateGreyBox ({ commit, getters, store }, payload) {
      const greyBoxArray = getters.getOffice(payload.id).greybox
      const greyBoxIndex = greyBoxArray.findIndex(obj => obj.id === payload.greybox.id)
      greyBoxArray[greyBoxIndex] = payload.greybox

      this.dispatch('officeModule/patch', { id: payload.id, greybox: greyBoxArray })
        .then((OfficeId) => {
          payload.EventBus.$emit('updateGreyBoxSucces')
        })
        .catch((err) => {
          payload.EventBus.$emit('updateGreyBoxError', err.message)
        })
    }
  }
}

export default officeModule
