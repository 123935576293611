<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="444" fixed transition="dialog-bottom-transition">
      <v-card>
        <v-app-bar dark color="primary">
          <v-btn icon x-large dark @click="dialog = false">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>Contact</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-app-bar>
        <v-card class="pb-5">
          <form @submit.prevent="saveContactAndExit">
            <v-layout row class="mx-2 mt-4">
              <v-flex xs12>
                <v-text-field
                  filled
                  :label="$t('common.name')"
                  v-model="c.name"
                  type="text"
                  @blur="$store.dispatch('resize')"
                  required>
                </v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row class="mx-2 mt-n4">
              <v-flex xs12>
                <v-text-field
                  filled
                  label="Detail"
                  v-model="c.detail"
                  type="text"
                  @blur="$store.dispatch('resize')"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row class="mx-2">
              <v-flex xs8>
                <v-text-field
                  class="me-2"
                  filled
                  :label="$t('createuser.phone')"
                  v-model="c.phone"
                  type="tel"
                  @blur="$store.dispatch('resize')"
                ></v-text-field>
              </v-flex>
              <v-flex xs4>
                <v-text-field
                  filled
                  label="Extension"
                  v-model="c.phoneExt"
                  type="tel"
                  @blur="$store.dispatch('resize')"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row class="mt-n4 mx-2">
              <v-flex xs12>
                <v-text-field
                  filled
                  :label="$t('createuser.mail')"
                  v-model="c.mail"
                  type="email"
                  @blur="$store.dispatch('resize')"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row class="mx-2">
              <v-flex xs12>
                <v-btn block x-large type="submit">{{ contact.id ? $t('common.edit') : $t('common.create') }}</v-btn>
              </v-flex>
            </v-layout>
          </form>
        </v-card>

      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ['contact'],
  data () {
    return {
      dialog: true,
      c: {
        id: this.contact.id ? this.contact.id : null,
        name: this.contact.name ? this.contact.name : '',
        detail: this.contact.detail ? this.contact.detail : '',
        phone: this.contact.phone ? this.contact.phone : '',
        phoneExt: this.contact.phoneExt ? this.contact.phoneExt : '',
        mail: this.contact.mail ? this.contact.mail : ''
      }
    }
  },
  watch: {
    dialog (newValue) {
      this.$store.dispatch('resize')
      this.dialog = false
      this.$emit('close')
    }
  },
  methods: {
    saveContactAndExit () {
      this.dialog = false
      this.$store.dispatch('resize')
      this.$emit('return-close', this.c)
    }
  },
  destroyed () {
    this.c = null
  }
}
</script>
