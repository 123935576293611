const userModule = {
  firestorePath: 'user',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'userModule',
  statePropName: 'data',
  namespaced: true, // automatically added

  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    getUserByEmpId: (state) => (empId) => {
      var existId = null
      for (const userId in state.data) {
        const user = state.data[userId]
        if (user.empid === empId) {
          existId = userId
        }
      }
      return existId
    }
  },
  mutations: {},
  actions: {}
}

export default userModule
