<template>
  <div class="home mx-n2">
    <v-expansion-panels>
      <v-expansion-panel
        v-for="(group, i) in stepGroup"
        :key="i"
        popout
      >
        <v-expansion-panel-header class="text-subtitle-2">
          <div v-if="group.type === 'start'">
            {{ $t('common.start') }}
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content :class="panelClass">
        <!-- <v-expansion-panel-content> -->
          <v-timeline
            align-top
            :dense="$vuetify.breakpoint.smAndDown"
          >
            <v-timeline-item
              v-for="(step, i) in getStepOfGroupSorted(group.groupId)"
              :key="i"
              :color="getStepColor(step)"
              :icon="getStepIcon(step)"
              :small="$vuetify.breakpoint.smAndDown"
              fill-dot
            >
              <template v-slot:icon>
                <v-icon dark :small="$vuetify.breakpoint.smAndDown" v-if="step.type === 'code'">mdi-subdirectory-arrow-right</v-icon>
                <v-img contain v-else-if="step.type === 'start-taxi'" :src="$store.getMarkerIconSpec($store.getters.getGoogle, { type: 'taxi'}).url"/>
              </template>
              <!-- <template v-slot:opposite>
                <span>{{ step.stepPosInGroup }}</span>
              </template> -->
              <v-card
                :color="getStepColor(step)"
                dark
              >
                <v-card-title
                  class="text-black text-body-1 text-xl-h5 text-lg-h5 text-md-h6 text-sm-h6"
                  v-text="getStepTitle(step)"
                ></v-card-title>
                <v-card-subtitle v-if="isStepSubTitle1(step)" v-text="getStepSubTitle1(step)"></v-card-subtitle>
                <v-card-subtitle v-if="isStepSubTitle2(step)" v-text="getStepSubTitle2(step)" class="mt-n9"></v-card-subtitle>
                <v-card-text v-if="step.type === 'code'" class="white text--primary">
                  <v-btn
                    :color="getStepColor(step)"
                    class="mx-0"
                    outlined
                  >
                    Button
                    <!-- <v-icon v-html="'$vuetify.icons.customIconSvg'"></v-icon> -->
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  props: ['travel'],
  data () {
    return {
      stepGroup: this.travel.stepGroup.sort(function (a, b) {
        return a.gsPosition - b.gsPosition
      })
    }
  },
  methods: {
    // getStepOfGroupSorted (groupId) {
    //   return this.travel.step.filter(x => x.groupId === groupId).sort(function (a, b) { return a.stepPosInGroup - b.stepPosInGroup })
    // },
    getStepColor (step) {
      switch (step.type) {
        case 'code':
          // if (step.codeName === null) { // console.log(step) }
          return this.$store.getColorName(step.colorId)
        case 'start-taxi':
          return 'primary'
        default:
          return 'primary'
      }
    },
    getStepTitle (step) {
      switch (step.type) {
        case 'code':
          // if (step.codeName === null) { // console.log(step) }
          return 'Code: ' + step.codeName
        case 'start-taxi':
          return this.$t('common.start')
        default:
          return 'a faire'
      }
    },
    getStepSubTitle1 (step) {
      switch (step.type) {
        case 'code':
          return this.$t('common.from') + ': ' + this.getFirstAddressOfStep(step)
        case 'start-taxi':
          return step.address
        default:
          return 'a faire'
      }
    },
    isStepSubTitle1 (step) {
      switch (step.type) {
        case 'code':
          return true
        case 'start-taxi':
          return true
        default:
          return false
      }
    },
    getStepSubTitle2 (step) {
      switch (step.type) {
        case 'code':
          return this.$t('common.to') + ': ' + this.getLastAddressOfStep(step)
        case 'start-taxi':
          return null
        default:
          return null
      }
    },
    isStepSubTitle2 (step) {
      switch (step.type) {
        case 'code':
          return true
        case 'start-taxi':
          return false
        default:
          return false
      }
    },
    getStepIcon (step) {
      return 'mdi-star'
    },
    getStepOfGroupSorted (groupId) {
      return this.travel.step.filter(x => x.groupId === groupId).sort(function (a, b) { return a.stepPosInGroup - b.stepPosInGroup })
    },
    getLastAddressOfStep (step) {
      const pocId = this.travel.base.filter(x => x.stepId === step.stepId).sort(function (a, b) {
        return b.posInStep - a.posInStep
      })[0].pocId
      return this.$store.state.pocModule.data[pocId].pdrListAddress
    },
    getFirstAddressOfStep (step) {
      const pocId = this.travel.base.filter(x => x.stepId === step.stepId).sort(function (a, b) {
        return a.posInStep - b.posInStep
      })[0].pocId
      return this.$store.state.pocModule.data[pocId].pdrListAddress
    }
  },
  computed: {
    panelClass () {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 'me-n5 ms-n8'
      } else {
        return 'mx-n1'
      }
    },
    breakPointName () {
      return this.$vuetify.breakpoint.name
    }
  },
  watch: {
    breakPointName (newValue) {
      // // console.log('trace breakpoint = ' + newValue)
    },
    travel (newValue) {
      this.stepGroup = newValue.stepGroup.sort(function (a, b) {
        return a.gsPosition - b.gsPosition
      })
    }
  }
}
</script>
