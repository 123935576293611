<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="444" fixed transition="expand-transition">
      <v-card>
        <v-app-bar dark color="primary">
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ $t('common.publish') }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-app-bar>

        <form @submit.prevent="publish()">
          <v-card-text>
          <v-select
            v-model="messageType"
            outlined
            class="mt-2"
            :label="$t('createmessage.messagetype')"
            :items="messageTypeItems"
            @change="selectedItems = []"
          ></v-select>
          <v-textarea :value="toDisplayDetail" required :rules="isValidDest()" label="Destinataire" readonly outlined @click="showSelect = true"></v-textarea>
          <v-text-field v-model="title" @blur="$store.dispatch('resize')" :label="$t('createmessage.title')" outlined required></v-text-field>
          <v-textarea v-model="message" label="Message" outlined required></v-textarea>
          <v-row class="mb-4" no-gutters><v-spacer></v-spacer>
            <v-btn icon x-large @click="showManagePicture = true">
              <v-badge
                v-if="imageList.length"
                :content="imageList.length"
                color="green"
                overlap
                bottom
              >
                <v-icon x-large color="success">mdi-folder-multiple-image</v-icon>
              </v-badge>
              <v-icon v-else x-large color="error">mdi-folder-multiple-image</v-icon>
            </v-btn>
          </v-row>
          <v-btn v-show="selectedItems.length" block type="submit" :disabled="isLoading" :loading="isLoading">
            {{ $t('common.publish') }}
            <span slot="loader" class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </v-btn>
          <v-btn class="mt-5 mb-7" block @click="dialog = false">{{ $t('common.cancel') }}</v-btn>
          </v-card-text>
        </form>
      </v-card>
    </v-dialog>
    <pmt-select-popup
      v-if="showSelect"
      :list="toItems"
      :preselect="selectedItems"
      :solo="false"
      :mode="messageType"
      @return-select="returnFromSelect($event)"
    ></pmt-select-popup>
    <picture-manager
        v-if="showManagePicture"
        subTitle="Publication"
        :param="{ type: 'message', imageList: imageList }"
        @close="showManagePicture = false"
        @return-close="returnImage($event)"
      ></picture-manager>
  </v-row>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/storage'

export default {
  props: ['from'],
  data () {
    return {
      dialog: true,
      title: '',
      message: '',
      showManagePicture: false,
      imageList: [],
      isLoading: false,
      selectedItems: [],
      showSelect: false,
      showLoading: false,
      messageType: 'group',
      messageTypeItems: [{
        text: this.$t('createmessage.typegroup'),
        value: 'group'
      }, {
        text: this.$t('createmessage.typeperso'),
        value: 'perso'
      }, {
        text: this.$t('createmessage.typeroute'),
        value: 'route'
      }]
    }
  },
  mounted () {
    this.showLoading = true
    this.$store.dispatch('officeRulesModule/loadOfficeUser')
      .then((result) => {
        this.showLoading = false
      })
  },
  destroyed () {
    if (this.imageList.length) {
      this.imageList.forEach(picture => {
        var desertRef = firebase.storage().ref().child(picture.fullPath)
        desertRef.delete()
      })
    }
  },
  methods: {
    isValidDest () {
      if (this.selectedItems.length) {
        return [true]
      } else {
        return [this.$t('common.nodest')]
      }
    },
    returnFromSelect (itemsList) {
      this.showSelect = false
      this.selectedItems = itemsList
    },
    returnImage (images) {
      this.showManagePicture = false
      this.imageList = images
    },
    clearField () {
      this.title = ''
      this.message = ''
      this.imageList = []
      this.selectedItems = []
    },
    publish () {
      this.isLoading = true
      const message = {
        imageList: this.imageList,
        title: this.title,
        message: this.message,
        from: this.from
      }
      switch (this.messageType) {
        case 'perso':
          message.dest = this.selectedItems.map(function (x) { return x.userId })
          message.type = 'userMess'
          this.$store.dispatch('sendMessage', message)
            .then(() => {
              this.isLoading = false
              this.$store.dispatch('showMessage', this.$t('createmessage.messagesended'))
              this.clearField()
              this.dialog = false
            })
          break
        default:
          message.dest = this.selectedItems.map(function (x) { return x.id })
          message.type = this.messageType
          this.$store.dispatch('sendMessage', message)
            .then(() => {
              this.isLoading = false
              this.$store.dispatch('showMessage', this.$t('createmessage.messagesended'))
              this.clearField()
              this.dialog = false
            })
          break
      }
      // this.$store.dispatch('sendMessage', message)
    }
  },
  watch: {
    dialog (newValue) {
      this.$store.dispatch('resize')
      this.dialog = false
      this.$emit('close')
    }
  },
  computed: {
    toItems () {
      if (this.messageType === 'group') {
        return [{
          id: 'supervisor',
          name: this.$t('jobtype.supervisor'),
          expanded: false
        }, {
          id: 'postmansttp',
          name: this.$t('jobtype.postman'),
          expanded: false
        }, {
          id: 'postmanffrs',
          name: this.$t('jobtype.ffrs'),
          expanded: false
        }, {
          id: 'csocial',
          name: this.$t('tool.clubsocial'),
          expanded: false
        }, {
          id: 'mixcomite',
          name: this.$t('tool.mixcomite'),
          expanded: false
        }]
      } else if (this.messageType === 'perso') {
        return this.$store.getters['officeRulesModule/getAuthenticatedEmpList']
      } else if (this.messageType === 'route') {
        return this.$store.getters['routeModule/sortedRouteAllType']
      } else {
        return []
      }
    },
    /*
    toItems () {
      if (this.messageType === 'group') {
        return [{
          id: 'supervisor',
          name: this.$t('jobtype.supervisor'),
          expanded: false
        }, {
          id: 'postmansttp',
          name: this.$t('jobtype.postman'),
          expanded: false
        }, {
          id: 'postmanffrs',
          name: this.$t('jobtype.ffrs'),
          expanded: false
        }, {
          id: 'csocial',
          name: this.$t('tool.clubsocial'),
          expanded: false
        }, {
          id: 'mixcomite',
          name: this.$t('tool.mixcomite'),
          expanded: false
        }]
      } else if (this.messageType === 'perso') {
        const perso = []
        this.$store.getters['officeRulesModule/getEmpList'].forEach(emp => {
          const user = this.$store.state.userModule.data[emp.userId]
          if (user) {
            perso.push({
              id: user.id,
              name: '(' + user.empid + ') - ' + user.lname + ', ' + user.fname,
              lname: user.lname,
              fname: user.fname
            })
          }
        })
        return [{
          id: 0,
          name: this.$t('common.all'),
          children: perso.sort(function (a, b) {
            const alname = a.lname.toUpperCase()
            const blname = b.lname.toUpperCase()
            const afname = a.fname.toUpperCase()
            const bfname = b.fname.toUpperCase()
            let comparison = 0
            if (alname > blname) {
              comparison = 1
            } else if (alname < blname) {
              comparison = -1
            } else if (afname > bfname) {
              comparison = 1
            } else if (afname < bfname) {
              comparison = -1
            }
            return comparison
          })
        }]
      } else if (this.messageType === 'route') {
        return [{
          id: 0,
          name: this.$t('common.all'),
          children: this.$store.getters['routeModule/sortedRouteAllType'].map(function (x) { return { id: x.id, name: x.name } })
        }]
      } else {
        return []
      }
    },
    */
    toDisplay () {
      // let toMsg = ''
      if (this.messageType === 'group') {
        return this.$t('createmessage.typegroup')
      } else if (this.messageType === 'perso') {
        return this.$t('createmessage.typeperso')
      } else if (this.messageType === 'route') {
        return this.$t('createmessage.typeroute')
      } else {
        return ''
      }
    },
    toDisplayDetail () {
      let display = ''
      if (this.selectedItems.length) {
        if (this.messageType === 'group') {
          this.selectedItems.forEach(item => {
            switch (item.id) {
              case 'supervisor':
                display += this.$t('jobtype.supervisor') + ', '
                break
              case 'postmansttp':
                display += this.$t('jobtype.postman') + ', '
                break
              case 'postmanffrs':
                display += this.$t('jobtype.ffrs') + ', '
                break
              case 'csocial':
                display += this.$t('tool.clubsocial') + ', '
                break
              case 'mixcomite':
                display += this.$t('tool.mixcomite') + ', '
                break
              default:
                break
            }
          })
          return display
        } else if (this.messageType === 'perso') {
          this.selectedItems.forEach(item => {
            const user = this.$store.state.userModule.data[item.userId]
            display += user.fname + ' ' + user.lname + ', '
          })
          return display
        } else {
          this.selectedItems.forEach(item => {
            display += item.name + ', '
          })
          return display
        }
      } else {
        return ''
      }
    }
  }
}
</script>
<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
