<template>
  <div class="home">
    <v-card :style="$store.getters.contentStyle" class="overflow-y-auto overflow-x-hidden">
      <v-card-title>
        <v-row>
          <v-btn icon x-large @click="$store.getters.EventBus.$emit('selectLocalAdminComponent', ('pmt-menu-admin-local'))"><v-icon x-large>mdi-arrow-left-circle</v-icon></v-btn>
          <h3 class="mt-3">Menu</h3>
          <v-spacer></v-spacer>
          <v-btn class="me-3" icon x-large @click="showFilter = !showFilter"><v-icon x-large>{{ showFilter ? 'mdi-filter-outline' : 'mdi-filter' }}</v-icon></v-btn>
          <v-btn class="me-3" icon x-large @click="showInputNewUser = !showInputNewUser"><v-icon x-large>{{ showInputNewUser ? 'mdi-account-plus-outline' : 'mdi-account-plus' }}</v-icon></v-btn>
        </v-row>
      </v-card-title>
      <v-row><v-spacer></v-spacer><h2>{{$t('pmtmenuadminlocal.userrule') }}</h2><v-spacer></v-spacer></v-row>
      <v-container align-center max-width="444">
        <v-card v-if="showInputNewUser" class="mx-3 mb-5">
          <v-card-title>{{$t('common.adduser')}}</v-card-title>
          <v-text-field v-model="newEmpId" class="mx-3" solo block autofocus @blur="$store.dispatch('resize')" :label="$t('login.empid')"></v-text-field>
          <v-row class="mx-3 pb-3"><v-spacer></v-spacer><v-btn :disabled="!newEmpIdIsValide" icon x-large @click="addmEmpToOffice()"><v-icon x-large>mdi-plus</v-icon></v-btn></v-row>
        </v-card>
        <v-card v-if="showFilter" class="mx-3 mb-5">
          <v-card-title>{{$t('common.filter')}}</v-card-title>
          <v-select class="mx-3" v-model="filterUserType" outlined :label="$t('editrule.jobtype')" :items="jobTypeItem"></v-select>
          <v-select class="mx-3" v-model="filterAdmin" outlined :label="$t('editrule.admin')" :items="yesNoAllItems"></v-select>
          <v-select class="mx-3" v-model="filterCSocial" outlined :label="$t('editrule.csocial')" :items="yesNoAllItems"></v-select>
          <v-select class="mx-3" v-model="filterMixComite" outlined :label="$t('editrule.mixcomite')" :items="yesNoAllItems"></v-select>
        </v-card>
        <v-expansion-panels class="px-1">
          <v-expansion-panel
            v-for="emp in empArray"
            :key="emp.id"
          >
            <v-expansion-panel-header>
              {{ emp.id + ($store.state.userModule.data[emp.userId] ? ' : ' + $store.state.userModule.data[emp.userId].lname + ', ' + $store.state.userModule.data[emp.userId].fname  + '  ( ' + $t('usertype.' + $store.state.userModule.data[emp.userId].userType) + ' )': '  ' + $t('common.usernotexist'))}}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-checkbox v-model="emp.isMixComite" @change="$store.dispatch('officeRulesModule/updateEmp', emp)" :label="$t('editrule.mixcomite')"></v-checkbox>
              <v-checkbox v-model="emp.isSocialClub" @change="$store.dispatch('officeRulesModule/updateEmp', emp)" :label="$t('editrule.csocial')"></v-checkbox>
              <v-checkbox v-model="emp.isAdmin" @change="$store.dispatch('officeRulesModule/updateEmp', emp)" :label="$t('editrule.admin')"></v-checkbox>
              <v-row class="mx-3"><v-spacer></v-spacer><v-btn x-large icon @click="confirmDeleteEmp(emp)"><v-icon x-large>mdi-delete</v-icon></v-btn></v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-card>
    <loading-popup v-if="showLoading" :title="loadingTitle" :subTitle="loadingsubTitle"></loading-popup>
    <confirm-popup
      v-if="showConfirmDelete"
      @cancel="showConfirmDelete = false"
      @confirm="deleteEmp($event)"
      :title="confirmDeleteTitle"
      :item="selEmp"
    ></confirm-popup>
  </div>
</template>

<script>
export default {
  props: ['title'],
  data () {
    return {
      showLoading: false,
      showConfirmDelete: false,
      showInputNewUser: false,
      showFilter: false,
      loadingTitle: '',
      loadingsubTitle: '',
      selEmp: null,
      newEmpId: '',
      confirmDeleteTitle: '',
      filterUserType: 0,
      filterAdmin: 0,
      filterCSocial: 0,
      filterMixComite: 0
    }
  },
  methods: {
    confirmDeleteEmp (emp) {
      this.selEmp = emp
      this.confirmDeleteTitle = this.$t('common.confirmdelete') + ' : ' + emp.id
      this.showConfirmDelete = true
    },
    deleteEmp (emp) {
      this.showConfirmDelete = false
      this.$store.dispatch('officeRulesModule/deleteEmp', emp)
    },
    addmEmpToOffice () {
      const tempId = ('00000000000' + this.newEmpId)
      const empId = tempId.substring(tempId.length - 9)
      this.showLoading = true
      const emp = {
        id: empId,
        userId: null,
        isAdmin: false,
        isSocialClub: false,
        isMixComite: false
      }
      this.$store.dispatch('userModule/fetchAndAdd', { where: [['empid', '==', empId]] })
        .then(() => {
          const userId = this.$store.getters['userModule/getUserByEmpId'](empId)
          if (userId) {
            emp.userId = userId
          }
          // this.empArray.push(emp)
          this.$store.dispatch('officeRulesModule/addEmp', emp)
          this.showLoading = false
          this.newEmpId = ''
        })
        .catch((err) => {
          this.showLoading = false
          this.$store.dispatch('showAlert', (err.message))
          this.newEmpId = ''
        })
    },
    refreshEmpDetail () {

    }
  },
  computed: {
    empArray () {
      const returnArray = []
      this.$store.getters['officeRulesModule/getEmpList'].forEach(emp => {
        var valid = true
        if (this.filterUserType) {
          if (this.$store.state.userModule.data[emp.userId]) {
            if (this.filterUserType !== this.$store.state.userModule.data[emp.userId].userType) {
              valid = false
            }
          } else {
            valid = false
          }
        }
        if (valid) {
          if (this.filterAdmin === 1 && !emp.isAdmin) {
            valid = false
          } else if (this.filterAdmin === 2 && emp.isAdmin) {
            valid = false
          } else if (this.filterCSocial === 1 && !emp.isSocialClub) {
            valid = false
          } else if (this.filterCSocial === 2 && emp.isSocialClub) {
            valid = false
          } else if (this.filterMixComite === 1 && !emp.isMixComite) {
            valid = false
          } else if (this.filterMixComite === 2 && emp.isMixComite) {
            valid = false
          }
        }
        if (valid) {
          returnArray.push(emp)
        }
      })
      return returnArray
    },
    jobTypeItem () {
      const item = [{ text: this.$t('common.all'), value: 0 }]
      const userTypeId = 'userType'
      this.$store.state.paramModule.data[userTypeId].typeId.forEach(typeId => {
        item.push({ text: this.$t('usertype.' + typeId), value: typeId })
      })
      return item
    },
    yesNoAllItems () {
      return [{
        text: this.$t('common.whatever'),
        value: 0
      }, {
        text: this.$t('common.yes'),
        value: 1
      }, {
        text: this.$t('common.no'),
        value: 2
      }]
    },
    newEmpIdIsValide () {
      var valid = false
      if (this.newEmpId) {
        if (this.newEmpId.length < 10) {
          const onlyNumber = String(this.newEmpId.match(/\d+/g).map(Number))
          const tempId1 = ('00000000000' + this.newEmpId)
          const var1 = tempId1.substring(tempId1.length - 9)
          const tempId2 = ('00000000000' + onlyNumber)
          const var2 = tempId2.substring(tempId2.length - 9)
          if (var1 === var2) {
            valid = true
          }
        }
      }
      return valid
    }
  },
  mounted () {
    this.$store.dispatch('officeRulesModule/loadOfficeUser')
  },
  destroyed () {},
  watch: {
    showInputNewUser (newValue) {
      if (newValue) {
        this.showFilter = false
      }
    },
    showFilter (newValue) {
      if (newValue) {
        this.showInputNewUser = false
      }
    }
  }
}
</script>
