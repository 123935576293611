<template>
  <v-overlay :value="loadingShow" opacity="0.8" dark>
    <v-card-title class="my-5">
      {{ loadingTitle }}
    </v-card-title>
    <v-row no-gutters>
      <v-spacer></v-spacer>
      <v-progress-circular
        :size="120"
        indeterminate
        :width="10"
      ></v-progress-circular>
      <v-spacer></v-spacer>
    </v-row>
    <v-progress-linear
      indeterminate
      class="mt-3"
    >
    </v-progress-linear>
  </v-overlay>
</template>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
<script>
export default {
  props: ['loadingShow', 'loadingTitle'],
  data () {
    return {
    }
  }
}
</script>
