
<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent fixed max-width="444" transition="dialog-bottom-transition">
      <v-card class="overflow-hidden">
        <v-app-bar dark color="primary">
          <v-btn icon x-large dark @click="isModify ? null : dialog = false">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ $t('report-editor.title') }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-app-bar>
        <v-card class="overflow-y-auto overflow-x-hidden" :max-height="Math.round($store.getters.contentHeight * 0.80)">
          <v-card>
            <v-card-title v-if="$store.getters.userId === report.created_by">
              <v-row no-gutters>
                <v-spacer></v-spacer>
                <v-switch
                  v-model="active"
                  :label="$t(active ? 'common.active' : 'common.inactive')"
                ></v-switch>
                <v-icon large class="ms-3" :color="active ? 'success' : 'error'" v-text="active ? 'mdi-check-outline' : 'mdi-close-thick'"></v-icon>
                <v-spacer></v-spacer>
              </v-row>
            </v-card-title>
            <v-card-title v-else>
              <v-row no-gutters>
                <v-spacer></v-spacer>
                <p>{{ $t(active ? 'common.active' : 'common.inactive') }}</p>
                <v-icon class="mt-n4 ms-1" :color="active ? 'success' : 'error'" v-text="active ? 'mdi-check-outline' : 'mdi-close-thick'"></v-icon>
                <v-spacer></v-spacer>
              </v-row>
            </v-card-title>
            <p align="center" class="title mt-n4">{{ $t('pmtcreatereportpopup.title' + report.type) }}</p>
            <v-card-title>Detail :</v-card-title>
            <v-card-text>
              <p v-if="report.routeIdArray" class="subtitle-1">{{ report.routeIdArray.map(x => { return $store.state.routeModule.data[x].name }).sort(function (a, b) { return a - b }).toString() }}</p>
              <v-textarea
                label="Description"
                :readonly="isReadOnly"
                outlined
                v-model="desc"
                @blur="validDesc()"
              ></v-textarea>
            </v-card-text>
            <v-expansion-panels class="mt-2 pb-5" focusable popout>
              <!-- report.status -->
              <v-expansion-panel :readonly="isReadOnly">
                <v-expansion-panel-header>
                  <p align="center" class="red--text title">Status : {{ $t('report-Status.' + report.status) }}</p>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list nav>
                    <v-list-item
                      v-for="(hStatus, i) in hist"
                      :key="i"
                    >
                      <v-list-item-content three-line dense class="mx-n4">
                        <v-list-item-title align="center">{{ $t('report-Status.' + hStatus.status) }} :</v-list-item-title>
                        <v-list-item-subtitle>{{ $t('common.by') }} : {{ hStatus.name }} </v-list-item-subtitle>
                        <v-list-item-subtitle>{{ $t('common.at') }} : {{ getDisplayDate(hStatus.at) }}</v-list-item-subtitle>
                        <!-- <pre v-if="hStatus.explain && $store.getters.isMixComite" class="body2 grey lighten-3 px-3 mt-2 py-2">{{ hStatus.explain }}</pre> -->
                        <v-textarea readonly v-model="hStatus.explain" v-if="hStatus.explain && $store.getters.isMixComite" class="mt-2" filled>{{ hStatus.explain }}</v-textarea>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!-- PDR -->
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <p align="center" class="title mb-n1">{{ $t('common.pdr') }} ({{ report.pocList.length }})</p>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list>
                    <v-list-item
                      v-for="(poc, i) in report.pocList"
                      :key="i"
                    >
                      <v-list-item-content three-line>
                        <v-list-item-title>{{ poc.name ? poc.name : poc.address}}</v-list-item-title>
                        <v-list-item-subtitle>{{ poc.name ? poc.address : ''}}</v-list-item-subtitle>
                        <v-list-item-subtitle>{{ poc.isBlocked ? $t('pmtpdreditpopup.pdrblocked') + ', ' : '' }}{{ poc.isOwner ? $t('common.owner') : '' }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!-- Note -->
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <p align="center" class="title mb-n1">Note ({{ note.length }})</p>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row no-gutters class="mt-2">
                    <v-spacer></v-spacer>
                    <v-btn @click="showNoteEditor = true" x-large icon><v-icon color="green" x-large>mdi-plus-circle</v-icon></v-btn>
                  </v-row>
                  <v-list v-if="note.length" class="mt-7">
                    <v-list-item
                      v-for="n in note"
                      :key="n.id"
                    >
                      <v-list-item-content class="mx-n2">
                        <v-textarea
                          :label="n.createdByName + ' - ' + getDisplayDate(n.created_at)"
                          outlined
                          filled
                          v-model="n.desc"
                          readonly
                        >
                        </v-textarea>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          v-if="$store.getters.userId === n.created_by"
                          color="primary"
                          fab
                          class="me-n1"
                          dark
                          top
                          small
                          absolute
                          right
                          @click="showNoteEditor = n"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  <p v-else>{{ $t('report-editor.noNote') }}</p>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!-- Mix Comite -->
              <v-expansion-panel v-if="$store.getters.isMixComite">
                <v-expansion-panel-header>
                  <p align="center" class="title mb-n1">{{ $t('tool.mixcomite') }}</p>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-btn class="mb-4 mt-7 primary" @click="validSupported()" rounded large block :disabled="report.supportedBy === $store.getters.userId || report.status === 5">{{ $t('common.supported') }}</v-btn>
                  <v-btn class="my-4 primary" @click="validToWait()" rounded large block :disabled="report.status === 5" v-show="report.supportedBy === $store.getters.userId">{{ $t('common.toWait') }}</v-btn>
                  <v-btn class="my-4 primary" @click="validToMonitor()" rounded large block :disabled="report.status === 5" v-show="report.supportedBy === $store.getters.userId">{{ $t('common.toMonitor') }}</v-btn>
                  <v-btn class="my-4 primary" @click="validResolved()" rounded large block :disabled="report.status === 5" v-show="report.supportedBy === $store.getters.userId">{{ $t('common.resolved') }}</v-btn>
                  <v-btn class="my-4 primary" @click="validActive()" rounded large block v-show="report.status === 5">{{ $t('common.reactive') }}</v-btn>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-card-text v-if="showProximityAlert">
              <p class="title mt-5">{{ $t('common.proximityAlert') }} :</p>
              <v-row no-gutters class="mt-n1">
                <v-spacer></v-spacer>
                <v-switch
                  v-model="proximityAlert"
                  :disabled="isReadOnly"
                  :label="$t(proximityAlert ? 'common.active' : 'common.inactive')"
                ></v-switch>
                <v-icon large class="ms-3" :color="proximityAlert ? 'success' : 'error'" v-text="proximityAlert ? 'mdi-leak' : 'mdi-leak-off'"></v-icon>
                <v-spacer></v-spacer>
              </v-row>
            </v-card-text>
            <v-row no-gutters>
              <v-col cols="4" align="center">
                <v-btn icon x-large @click="isReadOnly ? $store.getters.EventBus.$emit('ShowImageListFullScreen', report.imageList) : showManagePicture = true">
                  <v-badge
                    v-if="report.imageList.length"
                    :content="report.imageList.length"
                    color="green"
                    overlap
                    bottom
                  >
                    <v-icon x-large color="success">mdi-folder-multiple-image</v-icon>
                  </v-badge>
                  <v-icon v-else x-large color="error">mdi-folder-multiple-image</v-icon>
                </v-btn>
                <p align="center">{{ $t('createreportdog.picture') }}</p>
              </v-col>
              <v-col cols="4" align="center" v-if="!isReadOnly && !isFullBox">
                <v-btn icon x-large @click="showGetPosition = true"><v-icon x-large :color="report.dangergps ? 'success' : 'error'">mdi-map-marker</v-icon></v-btn>
                <p align="center">{{ $t('createreportdog.gps') }}</p>
              </v-col>
            </v-row>
          </v-card>
        </v-card>
      </v-card>
    </v-dialog>
    <loading-popup v-if="showLoading" :title="loadingTitle" :subTitle="loadingsubTitle"></loading-popup>
    <picture-manager
      v-if="showManagePicture"
      :subTitle="$t('pmtcreatereportpopup.title' + report.type)"
      :param="{ type: 'report', reportId: reportId }"
      @close="showManagePicture = false"
      @return-close="showManagePicture = false"
    ></picture-manager>
    <pmt-position-map-set
      v-if="showGetPosition"
      :startCenterPdrId="report.owner"
      :param="{ icon: $store.getAlertPngIcon(report.type), markerStartPosition: { lat: report.dangergps.latitude, lng: report.dangergps.longitude }, getDistance: report.securedistance ? true : false }"
      @return-close="showGetPosition = false"
      @return-check="setPosition($event)"
    />
    <pmt-note-editor
      v-if="showNoteEditor"
      :title="$t('common.editor')"
      :note="showNoteEditor !== true ? showNoteEditor : null"
      type="reportNote"
      @close="showNoteEditor = null"
      @return-close="validSaveNote($event)"
    ></pmt-note-editor>
    <confirm-popup
      v-if="showConfirm"
      @cancel="cancelConfirm($event)"
      @confirm="areConfirm($event)"
      :title="showConfirm.title"
      :from="showConfirm.from"
      :to="showConfirm.to"
      :item="showConfirm.item"
      :param="{ mode: showConfirm.mode, text: showConfirm.subtitle }"
    ></confirm-popup>
  </v-row>
</template>

<script>
const firebase = require('firebase/app')
export default {
  props: ['reportId'],
  data () {
    return {
      dialog: true,
      myName: this.$store.state.userLoggedModule.data.lname + ', ' + this.$store.state.userLoggedModule.data.fname,
      myEmpId: this.$store.state.userLoggedModule.data.empid,
      note: this.$store.state.reportModule.data[this.reportId].note,
      desc: this.$store.state.reportModule.data[this.reportId].desc,
      proximityAlert: this.$store.state.reportModule.data[this.reportId].proximityAlert,
      loadingTitle: this.$t('common.loading'),
      loadingsubTitle: this.$t('edit-route.loadingDesc'),
      showLoading: false,
      btnCreatePlaceLoading: false,
      btnCorrectPlaceLoading: false,
      btnCorrectPocLocationLoading: false,
      active: this.$store.state.reportModule.data[this.reportId].active,
      showManagePicture: false,
      showGetPosition: false,
      showNoteEditor: null,
      showConfirm: null
    }
  },
  computed: {
    isModify () {
      if (this.desc !== this.report.desc) {
        return true
      } else {
        return false
      }
    },
    hist () {
      if (this.reportId) {
        const h = this.$store.state.reportModule.data[this.reportId].hist
        return h ? h.sort(function (a, b) { return b.at - a.at }) : []
      } else {
        return []
      }
    },
    report () {
      if (this.reportId) {
        return this.$store.state.reportModule.data[this.reportId]
      } else {
        return null
      }
    },
    isReadOnly () {
      if (this.report && (this.report.created_by === this.$store.getters.userId || this.$store.getters.isMixComite)) {
        return false
      } else {
        return true
      }
    },
    isFullBox () {
      if (this.report && this.report.type === 'fullbox') {
        return true
      } else {
        return false
      }
    },
    showProximityAlert () {
      if (this.isFullBox) {
        return false
      } else {
        return true
      }
    },
    iSupported () {
      if (this.report && this.report.supportedBy === this.$store.getters.userId) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    // @cancel="cancelConfirm($event)"
    //   @confirm="areConfirm($event)"
    //   :title="showConfirm.title"
    //   :from="showConfirm.from"
    //   :to="showConfirm.to"
    //   :item="showConfirm.item"
    //   :param="{ mode: 'standard', text: showConfirm.subtitle }"
    cancelConfirm (item) {
      switch (item.confirmType) {
        case 'saveNote':
          this.showConfirm = null
          break
        case 'proximityAlert':
          this.showConfirm = null
          this.proximityAlert = this.$store.state.reportModule.data[this.reportId].proximityAlert
          break
        case 'active':
          this.showConfirm = null
          this.active = this.$store.state.reportModule.data[this.reportId].active
          break
        case 'resolved':
          this.showConfirm = null
          this.active = this.$store.state.reportModule.data[this.reportId].active
          break
        case 'desc':
          this.showConfirm = null
          this.desc = this.$store.state.reportModule.data[this.reportId].desc
          break
        default:
          this.showConfirm = null
          break
      }
    },
    areConfirm (item) {
      const hist = this.report.hist
      this.showConfirm = null
      switch (item.confirmType) {
        case 'saveNote':
          this.saveNote(item.note)
          this.showNoteEditor = null
          break
        case 'proximityAlert':
          this.saveReport({
            id: this.reportId,
            proximityAlert: item.proximityAlert
          })
          break
        case 'active':
          hist.push({ status: 1, userId: this.$store.getters.userId, name: this.myName, empId: this.myEmpId, explain: item.explain ? item.explain : null, at: firebase.firestore.Timestamp.fromDate(new Date()) })
          this.saveReport({
            id: this.reportId,
            status: 1,
            active: true,
            hist: hist
          })
          this.active = true
          break
        case 'desc':
          this.saveReport({
            id: this.reportId,
            desc: this.desc
          })
          break
        case 'supported':
          hist.push({ status: 2, userId: this.$store.getters.userId, name: this.myName, empId: this.myEmpId, explain: item.explain ? item.explain : null, at: firebase.firestore.Timestamp.fromDate(new Date()) })
          this.saveReport({
            id: this.reportId,
            status: 2,
            supportedBy: this.$store.getters.userId,
            supportedByName: this.myName,
            supportedAt: firebase.firestore.Timestamp.fromDate(new Date()),
            hist: hist
          })
          break
        case 'toWait':
          hist.push({ status: 3, userId: this.$store.getters.userId, name: this.myName, empId: this.myEmpId, explain: item.explain ? item.explain : null, at: firebase.firestore.Timestamp.fromDate(new Date()) })
          this.saveReport({
            id: this.reportId,
            status: 3,
            toWaitBy: this.$store.getters.userId,
            toWaitByName: this.myName,
            toWaitAt: firebase.firestore.Timestamp.fromDate(new Date()),
            hist: hist
          })
          break
        case 'toMonitor':
          hist.push({ status: 4, userId: this.$store.getters.userId, name: this.myName, empId: this.myEmpId, explain: item.explain ? item.explain : null, at: firebase.firestore.Timestamp.fromDate(new Date()) })
          this.saveReport({
            id: this.reportId,
            status: 4,
            toMonitorBy: this.$store.getters.userId,
            toMonitorName: this.myName,
            toMonitorAt: firebase.firestore.Timestamp.fromDate(new Date()),
            hist: hist
          })
          break
        case 'resolved':
          hist.push({ status: 5, userId: this.$store.getters.userId, name: this.myName, empId: this.myEmpId, explain: item.explain ? item.explain : null, at: firebase.firestore.Timestamp.fromDate(new Date()) })
          this.saveReport({
            id: this.reportId,
            status: 5,
            active: false,
            resolvedBy: this.$store.getters.userId,
            resolvedName: this.myName,
            resolvedAt: firebase.firestore.Timestamp.fromDate(new Date()),
            hist: hist
          })
          this.active = false
          break
        case 'position':
          this.saveReport({
            id: this.reportId,
            dangergps: new firebase.firestore.GeoPoint(item.position.lat, item.position.lng),
            securedistance: item.distance ? item.distance : null
          })
          break
        default:
          break
      }
    },
    validSaveNote (noteToSave) {
      const noteIdx = this.note.map(x => { return x.id }).indexOf(noteToSave.id)
      const isNewNote = noteIdx < 0
      if (this.reportId) {
        this.showConfirm = {
          title: isNewNote ? this.$t('confirm-mess.createNote') : this.$t('confirm-mess.updateNote'),
          subtitle: null,
          from: isNewNote ? null : this.note[noteIdx].desc,
          to: noteToSave.desc,
          item: {
            note: noteToSave,
            confirmType: 'saveNote'
          },
          mode: 'standard'
        }
      } else {
        this.saveNote(noteToSave)
      }
    },
    saveNote (noteToSave) {
      this.showNoteEditor = null
      const noteIdx = this.note.map(x => { return x.id }).indexOf(noteToSave.id)
      if (noteIdx < 0) {
        this.note.push(noteToSave)
      } else {
        this.note[noteIdx] = noteToSave
      }
      if (this.reportId) {
        this.saveReport({
          id: this.reportId,
          note: this.note
        })
      }
    },
    validSupported () {
      this.showConfirm = {
        title: this.$t('confirm-mess.supported'),
        subtitle: null,
        from: null,
        to: null,
        item: {
          confirmType: 'supported'
        },
        mode: 'standard'
      }
    },
    validToWait () {
      this.showConfirm = {
        title: this.$t('confirm-mess.toWait'),
        subtitle: null,
        from: null,
        to: null,
        item: {
          confirmType: 'toWait'
        },
        mode: 'standard-explain'
      }
    },
    validToMonitor () {
      this.showConfirm = {
        title: this.$t('confirm-mess.toMonitor'),
        subtitle: null,
        from: null,
        to: null,
        item: {
          confirmType: 'toMonitor'
        },
        mode: 'standard-explain'
      }
    },
    validResolved () {
      this.showConfirm = {
        title: this.$t('confirm-mess.resolved'),
        subtitle: this.$t('confirm-mess.resolvedSubTitle'),
        from: null,
        to: null,
        item: {
          confirmType: 'resolved'
        },
        mode: 'standard-explain'
      }
    },
    validActive () {
      this.showConfirm = {
        title: this.$t('confirm-mess.active'),
        subtitle: null,
        from: null,
        to: null,
        item: {
          confirmType: 'active'
        },
        mode: 'standard-explain'
      }
    },
    reportDelete () {
      this.$emit('close-delete', { reportId: this.reportId })
    },
    saveReport (saveData) {
      this.loadingTitle = this.$t('common.saveinprogress')
      this.loadingsubTitle = null
      this.showLoading = true
      this.$store.dispatch('reportModule/set', saveData)
        .then(() => {
          this.showLoading = false
          this.$store.dispatch('showMessage', this.$t('common.succesSave'))
        })
        .catch((err) => {
          this.showLoading = false
          this.$store.dispatch('showAlert', (err.message))
        })
    },
    setPosition (posDist) {
      this.showGetPosition = false
      this.showConfirm = {
        title: this.$t('confirm-mess.position'),
        subtitle: null,
        from: null,
        to: null,
        item: {
          confirmType: 'position',
          position: posDist.position,
          distance: posDist.distance
        },
        mode: 'standard'
      }
    },
    validDesc () {
      if (this.desc !== this.report.desc) {
        this.showConfirm = {
          title: this.$t('confirm-mess.desc'),
          subtitle: null,
          from: this.report.desc,
          to: this.desc,
          item: {
            confirmType: 'desc'
          },
          mode: 'standard'
        }
      }
    },
    getDisplayDate (date) {
      if (date && date.toDate) {
        return date.toDate().toLocaleString(this.$store.getters.getLocale + '-CA')
      } else {
        return null
      }
    }
  },
  watch: {
    active (newValue) {
      if (newValue) {
        this.validActive(newValue)
      } else {
        this.validResolved()
      }
    },
    proximityAlert (newValue) {
      if (newValue !== this.$store.state.reportModule.data[this.reportId].proximityAlert) {
        this.showConfirm = {
          title: this.$t('confirm-mess.proximityAlert'),
          subtitle: this.$t('confirm-mess.proximityAlertSubTitle'),
          from: null,
          to: this.$t(newValue ? 'common.active' : 'common.inactive'),
          item: {
            confirmType: 'proximityAlert',
            proximityAlert: newValue
          },
          mode: 'standard'
        }
      }
    },
    dialog (newValue) {
      this.dialog = false
      this.$store.dispatch('resize')
      this.$emit('close')
    }
  },
  created () {
    const self = this
    // self.showLoading = true
    const prom = []
    self.$store.state.reportModule.data[self.reportId].pocList.forEach(pocInfo => {
      if (pocInfo.pdrId && !self.$store.state.pocModule.data[pocInfo.pdrId]) {
        prom.push(self.$store.dispatch('pocModule/fetchById', pocInfo.pdrId))
      }
    })
    Promise.all(prom).then(() => {
      // // console.log('trace promise finish')
      // self.showLoading = false
    })
  }
}
</script>
<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
