
const officeItemModule = {
  firestorePath: 'officeData/{officeId}/officeItem',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'officeItemModule',
  statePropName: 'data',
  namespaced: true, // automatically added

  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    getAllOfficeItem: (state) => {
      return Object.values(state.data)
    },
    getGreyBoxOfRoute: (state, getters) => (routeId) => {
      return getters.getGreyBox.filter(x => x.route.indexOf(routeId) > -1)
    },
    getGreyBox: (state) => {
      return Object.values(state.data).filter(x => x.type === 'greyBoxMarker' && x.isActive)
    },
    getAllGreyBox: (state) => {
      return Object.values(state.data).filter(x => x.type === 'greyBoxMarker')
    },
    getRedBoxOfRoute: (state, getters) => (routeId) => {
      return getters.getRedBox.filter(x => x.route.indexOf(routeId) > -1)
    },
    getRedBox: (state) => {
      return Object.values(state.data).filter(x => x.type === 'redBoxMarker' && x.isActive)
    },
    getParcelLockerOfRoute: (state, getters) => (routeId) => {
      return getters.getParcelLocker.filter(x => x.route.indexOf(routeId) > -1)
    },
    getParcelLocker: (state) => {
      return Object.values(state.data).filter(x => x.type === 'parcelLockerMarker' && x.isActive)
    },
    getGreyBoxMarker: (state, getters, rootState, rootGetters) => {
      let pos = 0
      return getters.getGreyBox.map(x => {
        x.isOpenInfoWindow = true
        x.pos = pos++
        x.marker = rootGetters.getGoogleMarker(x)
        // x.circle = rootGetters.getGoogleCircle(x.position, x.group, 20)
        return x
      })
    },
    getGreyBoxMarkerById: (state, getters) => (id) => {
      return getters.getGreyBoxMarker.filter(x => x.id === id)[0]
    },
    getGreyBoxSorted: (state, getters) => {
      return getters.getGreyBox.sort(function (a, b) {
        return a.group < b.group
      })
    },
    getAllGreyBoxSorted: (state, getters) => {
      return getters.getAllGreyBox.sort(function (a, b) {
        return a.name < b.name
      }).sort(function (a, b) {
        return a.group < b.group
      })
    },
    getToiletMarker: (state, getters, rootState, rootGetters) => {
      let pos = 0
      return Object.values(state.data).filter(x => x.type === 'toiletMarker' && x.isActive).map(x => {
        x.pos = pos++
        x.marker = rootGetters.getGoogleMarker(x)
        x.isOpenInfoWindow = true
        return x
      })
    },
    getRedBoxMarker: (state, getters, rootState, rootGetters) => {
      let pos = 0
      return Object.values(state.data).filter(x => x.type === 'redBoxMarker' && x.isActive).map(x => {
        x.pos = pos++
        x.marker = rootGetters.getGoogleMarker(x)
        x.isOpenInfoWindow = true
        return x
      })
    },
    getRedBoxMarkerById: (state, getters) => (id) => {
      return getters.getRedBoxMarker.filter(x => x.id === id)[0]
    },
    getParcelLockerMarker: (state, getters, rootState, rootGetters) => {
      let pos = 0
      return Object.values(state.data).filter(x => x.type === 'parcelLockerMarker' && x.isActive).map(x => {
        x.pos = pos++
        x.marker = rootGetters.getGoogleMarker(x)
        x.isOpenInfoWindow = true
        return x
      })
    },
    getParcelLockerMarkerById: (state, getters) => (id) => {
      return getters.getParcelLockerMarker.filter(x => x.id === id)[0]
    }
  },
  mutations: {},
  actions: {
  }
}

export default officeItemModule
