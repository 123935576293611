// src/utils/gmaps.js

// Your personal API key.
// Get it here: https://console.cloud.google.com/google/maps-apis

// const API_KEY = 'AIzaSyD8d5tQwZZYHr2vmkEI-FlOcz9QlN5vvBo'
const API_KEY = 'AIzaSyBCYzXrnUG2oYl9LaUoMURILgcFtag0TOI'
// AIzaSyBCYzXrnUG2oYl9LaUoMURILgcFtag0TOI
// AIzaSyBi43l3ScYGJFPNrh2_qcUAfsq6E-I9ma4
const CALLBACK_NAME = 'neodev6gmapsCallback'

let initialized = !!window.google
let resolveInitPromise
let rejectInitPromise
// This promise handles the initialization
// status of the google maps script.
const initPromise = new Promise((resolve, reject) => {
  resolveInitPromise = resolve
  rejectInitPromise = reject
})

export default function init () {
  // If Google Maps already is initialized
  // the `initPromise` should get resolved
  // eventually.
  if (initialized) return initPromise

  initialized = true
  // The callback function is called by
  // the Google Maps script if it is
  // successfully loaded.
  window[CALLBACK_NAME] = () => resolveInitPromise(window.google)

  // We inject a new script tag into
  // the `<head>` of our HTML to load
  // the Google Maps script.
  const script = document.createElement('script')
  script.async = true
  script.defer = true
  script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&v=beta&callback=${CALLBACK_NAME}&language=`
  // js?key=KEY&v=beta&callback=yourInitMapMethod"
  script.onerror = rejectInitPromise
  document.querySelector('head').appendChild(script)

  return initPromise
}
export async function getPictureSrc (lat, lng, width, height) {
  return 'https://maps.googleapis.com/maps/api/streetview?size=' + width + 'x' + height + '&location=' + lat + ',' + lng + '&key=' + API_KEY
}
export async function getApiKey () {
  return API_KEY
}
