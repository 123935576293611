<template>
  <div id="app">
    <v-card class="overflow-hidden" :style="$store.getters.contentStyle">
      <v-card height="90" class="grey lighten-2 pb-5">
        <v-card-title>
          <v-row no-gutters>
            <v-btn icon x-large @click="$store.getters.EventBus.$emit('selectLocalAdminComponent', ('pmt-menu-admin-local'))"><v-icon x-large>mdi-arrow-left-circle</v-icon></v-btn>
            <h3 class="mt-3">Menu</h3>
          </v-row>
        </v-card-title>
      </v-card>
      <v-sheet class="overflow-y-auto overflow-x-hidden" :height="$store.getters.contentHeight - 90" :max-height="$store.getters.contentHeight - 90">
        <v-card-title>{{ $t('import-poclist.import') }}</v-card-title>
        <v-card-subtitle>{{ $t('import-poclist.title') }}</v-card-subtitle>
        <input id='input' v-show="false" type="file" ref="inputUpload" accept=".csv" @change="processSeqFiles()"/>
        <v-container v-if="!file" class="justify-center mt-12">
          <v-row><v-spacer></v-spacer><v-btn dark icon size="70" color="primary" @click="$refs.inputUpload.click()"><v-icon size="70">mdi-upload</v-icon></v-btn><v-spacer></v-spacer></v-row>
          <v-row centered class="mt-3"><h2 class="mx-auto">{{ $t('import-poclist.open') }}</h2></v-row>
        </v-container>
        <v-card v-if="file" id="filedetail" class="mx-auto mb-3 pt-4" centered max-width="444">
          <v-btn @click="file = null" class="float-right" x-large icon><v-icon x-large color="red">mdi-delete</v-icon></v-btn>
          <h4 class="ms-1">{{ $t('import-poclist.file') }} : </h4>
          <h5 class="ms-2">{{ fileName }}</h5>

          <h4 class="ms-1 mt-2">{{ $t('import-poclist.size') }} : </h4>
          <h5 class="ms-2">{{ Math.round(((file.size / 1024) / 1024) * 10) / 10 }} Mo</h5>
          <h4 class="ms-1 mt-2">{{ $t('import-poclist.modified') }} : </h4>
          <h5 class="ms-2">{{ new Date(file.lastModified).toLocaleString('fr-CA',) }}</h5>
          <h4 class="ms-1 mt-2">Detail : </h4>

          <v-container v-show="analyseComplete && validFile" id="fileStat">
            <v-card v-for="item in importData" :key="item.route" class="mb-3">
              <v-card-title>{{ item.route }} </v-card-title>
              <!-- <v-card-subtitle v-if="$store.getters['officeModule/getOfficeIdByCp'](item.officeCp)">{{ $store.state.officeModule.data[$store.getters['officeModule/getOfficeIdByCp'](item.officeCp)].name }},  {{ item.officeCp.substr(0,3) + ' ' + item.officeCp.substr(3,3) }}</v-card-subtitle> -->
              <v-card-subtitle>{{  $t('common.pdrlong') }} :</v-card-subtitle>
              <v-card-text class="mt-n3">Total :  {{ item.stats.cptDomPub + item.stats.cptAppPub + item.stats.cptBusPub + item.stats.cptFrmPub }}  /  {{ item.stats.cptDom + item.stats.cptApp + item.stats.cptBus + item.stats.cptFrm }}  ( {{ item.stats.cptDomVacant + item.stats.cptAppVacant + item.stats.cptBusVacant + item.stats.cptFrmVacant }} )</v-card-text>
              <v-card-text class="mt-n8">{{ $t('common.dom') }}    :  {{ item.stats.cptDomPub }}  /  {{ item.stats.cptDom }}  ( {{ item.stats.cptDomVacant }} )</v-card-text>
              <v-card-text class="mt-n8">{{ $t('common.app') }}    :  {{ item.stats.cptAppPub }}  /  {{ item.stats.cptApp }}  ( {{ item.stats.cptAppVacant }} )</v-card-text>
              <v-card-text class="mt-n8">{{ $t('common.bus') }}    :  {{ item.stats.cptBusPub }}  /  {{ item.stats.cptBus }}  ( {{ item.stats.cptBusVacant }} )</v-card-text>
              <v-card-text class="mt-n8">{{ $t('common.frm') }}    : {{ item.stats.cptFrmPub }}  /  {{ item.stats.cptFrm }}  ( {{ item.stats.cptFrmVacant }} )</v-card-text>
              <!-- <p> nbr de bpcom = {{ item.bpComArray.length }}</p> -->
              <v-list nav>
                <v-list-item v-if="$store.getters['routeModule/getRouteIdByName'](item.route)" @click="confirmRefresh(item.route)">
                  <v-list-item-content>
                    <v-row justify="center" class="mt-3" ><v-icon x-large color="green">mdi-database-refresh</v-icon></v-row>
                    <v-row centered><h3 class="mx-auto mt-1 pb-10">{{ $t('import-poclist.refreshdata') }}</h3></v-row>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-else @click="confirmImport(item.route)">
                  <v-list-item-content>
                    <v-row justify="center" class="mt-3" ><v-icon x-large color="green">mdi-database-import</v-icon></v-row>
                    <v-row centered><h3 class="mx-auto mt-1 pb-10">{{ $t('import-poclist.importdata') }}</h3></v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
            <!-- <th v-for="item in codeMarkerStat" :key="item.name" class="text-center white--text subtitle-2">{{ item.name }}</th> -->
          </v-container>
          <v-container v-show="analyseComplete && !validFile">
            <v-card class="mb-3">
              <v-card-title>{{ $t('common.notvalidefile') }}</v-card-title>
              <v-card-text>{{ $t('import-poclist.notvalidefiledesc') }}</v-card-text>
            </v-card>
          </v-container>
          <!-- <div v-if="validFile" class="mt-5">
            <v-row justify="center" ><v-btn icon size="70" @click="importToDatabase()" color="green"><v-icon size="70">mdi-database-import</v-icon></v-btn></v-row>
            <v-row centered><h2 class="mx-auto mt-3 pb-10">{{ $t('import-poclist.importdata') }}</h2></v-row>
          </div> -->
        </v-card>
      </v-sheet>
    </v-card>
    <loading-popup v-if="showLoading" :title="loadingTitle" :subTitle="loadingsubTitle"></loading-popup>
    <confirm-popup
      v-if="showConfirmRefreshPoc"
      @cancel="showConfirmRefreshPoc = false"
      @confirm="refreshSelectedRoute($event)"
      :title="confirmTitle"
      :item="routeSelected"
      :param="refreshRouteParam"
    ></confirm-popup>
    <confirm-popup
      v-if="showConfirmImportPoc"
      @cancel="showConfirmImportPoc = false"
      @confirm="createRoute($event)"
      :title="confirmTitle"
      :item="routeSelected"
      :param="createRouteParam"
    ></confirm-popup>
  </div>
</template>

<script>
import { arrayUnion } from 'vuex-easy-firestore'
export default {
  name: 'App',
  props: ['title'],
  data () {
    return {
      file: null,
      fileName: '',
      pocListFiles: null,
      loadComplete: false,
      showConfirmRefreshPoc: false,
      showConfirmImportPoc: false,
      // harmonizeLoadComplete: false,
      confirmTitle: null,
      routeType: 0,
      createRouteParam: null,
      refreshRouteParam: null,
      routeSelected: null,
      pocLoadComplete: false,
      analyseComplete: false,
      validFile: false,
      showapp: false,
      showdoor: false,
      showProgress: false,
      progressTitle: '',
      importData: [],
      showLoading: false,
      loadingTitle: '',
      loadingsubTitle: ''
    }
  },
  watch: {
    file (newValue) {
      if (!newValue) {
        this.loadComplete = false
        this.analyseComplete = false
        this.validFile = false
        this.importData = []
      }
    }
  },
  computed: {
    dataloadComplete () {
      // if (this.pocLoadComplete) {
      //   return true
      // } else {
      //   return false
      // }
      return true
    }
  },
  methods: {
    /*
    importPoc (data) {
      const db = this.$store.getters.pocRef
      // const EventBus = this.$store.getters.EventBus

      db.add(this.importData[data.x].poc[data.i])
        .then(() => {
          this.loadingsubTitle = data.i + 1 + ' / ' + data.iMax
          if (data.i < data.iMax) {
            data.i++
            // EventBus.$emit('proccessNextImportPoc', (data))
            this.importPoc(data)
          } else if (data.x < data.xMax) {
            data.i = 0
            data.x++
            data.iMax = this.importData[data.x].poc.length - 1
            // EventBus.$emit('proccessNextImportPoc', (data))
            this.importPoc(data)
          } else {
            this.showLoading = false
          }
        })
        .catch((err) => {
         // // console.log('erreur = ' + err.message)
        })
    },
    */
    isRouteNameExist (routeName) {
      const routeId = this.$store.getters['routeModule/getRouteIdByName'](routeName)
      if (routeId) {
        return true
      } else {
        return false
      }
      // this.dispatch('pocModule/openDBChannel', { where: [['route', 'array-contains', this.$store.getters.getSelectedRouteId]] })
      //       .then(() => {
      //        // // console.log(' all poc loaded')
      //         commit('setpocModuleLoadComplete', true)
      //       })
    },
    createRoute (param) {
      this.showLoading = true
      this.loadingTitle = this.$t('common.import')
      this.loadingsubTitle = param.routeName
      this.$store.dispatch('routeModule/set', { name: param.routeName, type: param.routeType })
        .then((routeId) => {
          this.importSelectedRoute(param.routeName, routeId)
        })
        .catch((err) => {
          this.$store.dispatch('showAlert', err.message)
          this.showLoading = false
        })
    },
    confirmRefresh (routeName) {
      this.confirmTitle = this.$t('common.confirmrefresh')
      this.routeSelected = routeName
      this.refreshRouteParam = { mode: 'refreshRoute' }
      this.showConfirmRefreshPoc = true
    },
    confirmImport (routeName) {
      this.confirmTitle = this.$t('common.confirmimport')
      this.routeSelected = routeName
      this.createRouteParam = { mode: 'importRoute', routeType: null }
      this.showConfirmImportPoc = true
    },
    refreshSelectedRoute (param) {
      this.showConfirmRefreshPoc = false
      this.showLoading = true
      this.loadingTitle = this.$t('common.loading')
      this.loadingsubTitle = param.routeName
      this.$store.dispatch('pocModule/openDBChannel', { where: [['route', 'array-contains', param.routeId]] })
        .then(() => {
          this.loadingTitle = this.$t('common.update')
          for (let i = 0; i < this.importData.length; i++) {
            if (this.importData[i].route === param.routeName) {
              for (let j = 0; j < this.importData[i].poc.length; j++) {
                this.importData[i].poc[j].route = [param.routeId]
              }
              this.updatePocBatch(this.importData[i].poc, param)
            }
          }
        })
        .catch((err) => {
          this.$store.dispatch('showAlert', err.message)
          this.showLoading = false
        })
    },
    updatePocBatch (pocList, param) {
      const updatePdr = []
      const store = this.$store
      const EventBus = store.getters.EventBus
      for (let i = 0; i < pocList.length; i++) {
        const poc = pocList[i]
        const pocId = store.getters['pocModule/pocExist'](poc.pdrListAddress)
        if (pocId) {
          if (poc.route) {
            if (store.state.pocModule.data[pocId].route.indexOf(poc.route[0]) < 0) {
              const update = {
                id: pocId,
                route: arrayUnion(poc.route[0])
              }
              if (param.pub) { update.pub = poc.pub }
              if (param.vacant) {
                update.vacant = poc.vacant
                update.occuparyStatus = poc.occuparyStatus
              }
              updatePdr.push(update)
            } else {
              if ((param.pub && poc.pub !== store.state.pocModule.data[pocId].pub) || (param.vacant && poc.vacant !== store.state.pocModule.data[pocId].vacant)) {
                const update = {
                  id: pocId
                }
                if (param.pub) { update.pub = poc.pub }
                if (param.vacant) {
                  update.vacant = poc.vacant
                  update.occuparyStatus = poc.occuparyStatus
                }
                updatePdr.push(update)
              }
            }
          } else {
            const update = {
              id: pocId,
              route: poc.route
            }
            if (param.pub) { update.pub = poc.pub }
            if (param.vacant) {
              update.vacant = poc.vacant
              update.occuparyStatus = poc.occuparyStatus
            }
            updatePdr.push(update)
          }
        } else {
          updatePdr.push(poc)
        }
      }
      function doBatch (doList) {
        var batchList
        const batchPromise = []
        var toDoList = null
        if (doList.length > 500) {
          batchList = doList.slice(0, 500)
          toDoList = doList.slice(500)
        } else {
          batchList = doList
        }
        batchList.forEach(poc => {
          batchPromise.push(new Promise((resolve, reject) => {
            store.dispatch('pocModule/set', poc)
              .then((id) => {
                resolve(id)
              })
              .catch((err) => {
                store.dispatch('showAlert', err.message)
                reject(err.message)
              })
          }))
        })
        Promise.all(batchPromise).then(() => {
          if (toDoList) {
            doBatch(toDoList)
          } else {
            EventBus.$emit('importpoclistupdatecomplete')
          }
        })
          .catch((err) => {
            store.dispatch('showAlert', err.message)
          })
      }
      doBatch(updatePdr)
      EventBus.$on('importpoclistupdatecomplete', () => {
        this.showLoading = false
        store.dispatch('showMessage', this.$t('common.succesUpdate'))
      })
    },
    importSelectedRoute (routeName, routeId) {
      this.showConfirmImportPoc = false
      for (let i = 0; i < this.importData.length; i++) {
        if (this.importData[i].route === routeName) {
          for (let j = 0; j < this.importData[i].poc.length; j++) {
            this.importData[i].poc[j].route = [routeId]
          }
          this.importPocBatch(this.importData[i].poc, routeName, this.importData[i].poc.length)
        }
      }
    },
    importPocBatch (pocList, routeName, pocToImport) {
      var batchList
      var toDoList = null
      this.showLoading = true
      this.loadingTitle = this.$t('common.import')
      this.loadingsubTitle = routeName + ' :  ' + pocList.length
      if (pocList.length > 500) {
        batchList = pocList.slice(0, 500)
        toDoList = pocList.slice(500)
      } else {
        batchList = pocList
      }
      this.$store.dispatch('pocModule/insertBatch', batchList)
        .then((result) => {
          if (toDoList) {
            this.importPocBatch(toDoList, routeName, pocToImport)
          } else {
            this.showLoading = false
            this.$store.dispatch('showMessage', ('Route :' + routeName + '   ' + pocToImport + ' ' + this.$t('common.importpoc')))
          }
        })
        .catch((err) => {
          this.showLoading = false
          this.$store.dispatch('showAlert', (err.message))
        })
    },
    async importToDatabase () {
      this.showLoading = true
      this.$store.getters.EventBus.$on('proccessNextImportPoc', (x) => {
        this.importRoutePoc(x)
      })
      this.importRoutePoc(0)
    },
    processSeqFiles () {
      this.file = this.$refs.inputUpload.files[0]
      this.fileName = this.file.name
      this.loadCommplete = true
      this.analyseComplete = false
      this.importData = []
      this.processPdrListFile()
    },
    processPdrListFile () {
      if (this.file) {
        const EventBus = this.$store.getters.EventBus
        const store = this.$store
        this.showProgress = true
        EventBus.$on('filepdrlistcsvproccesscompleted', (result) => {
          this.importData = result
          this.analyseComplete = true
          this.showProgress = false
          this.verifDataFile()
        })
        if (window.FileReader) {
          var reader = new FileReader()
          reader.readAsText(this.file)
          reader.onload = function (event) {
            let lrname; let progress; let oldprogress
            let pdrs = []
            const ldata = []

            var csv = event.target.result.split('\n')
            let cptligne = 0
            let seqcode = ''
            let oldcode = ''
            let tempcode = ''
            let officeCp, officeAddress
            let stats = {
              cptBus: 0,
              cptBusPub: 0,
              cptBusVacant: 0,
              cptApp: 0,
              cptAppPub: 0,
              cptAppVacant: 0,
              cptFrm: 0,
              cptFrmPub: 0,
              cptFrmVacant: 0,
              cptDom: 0,
              cptDomPub: 0,
              cptDomVacant: 0
            }

            const countligne = csv.length
            // // console.log('trace nbr ligne du csv = ' + csv.length)
            for (const ligne of csv) {
              cptligne++
              const item = ligne.replace(/"/g, '').split(',')
              if (item[1] === 'POINT OF CALLS LIST PER INSTALLATION' && item[6]) {
                tempcode = item[6]
                const rname = item[4].split(':')[1].replace(/ /g, '')
                // if (item[6] === '10540') {
                //   // // console.log('trace cptligne = ' + cptligne)
                //   item.forEach((field, i) => {
                //     // // console.log('item[' + i + '] = ' + item[i])
                //   })
                // }
                if (lrname !== rname) {
                  if (lrname) {
                    ldata.push({ route: lrname, poc: pdrs, stats: stats, officeCp: officeCp })
                  }
                  officeCp = item[3].split(':')[1].replace(/ /g, '').substr(0, 6)
                  if (officeCp.toUpperCase() !== store.state.officeSelectedModule.data.cp.replace(/ /g, '').toUpperCase()) {
                    store.dispatch('showAlert', 'poc of another office')
                    break
                  }
                  // officeId = store.getters['officeModule/getOfficeIdByCp'](officeCp)
                  officeAddress = store.getters['officeSelectedModule/getOfficeAddress']
                  lrname = rname
                  pdrs = []
                  stats = {
                    cptBus: 0,
                    cptBusPub: 0,
                    cptBusVacant: 0,
                    cptApp: 0,
                    cptAppPub: 0,
                    cptAppVacant: 0,
                    cptFrm: 0,
                    cptFrmPub: 0,
                    cptFrmVacant: 0,
                    cptDom: 0,
                    cptDomPub: 0,
                    cptDomVacant: 0
                  }
                }
                if (tempcode !== oldcode) {
                  oldcode = tempcode
                  seqcode = tempcode
                }
              }
              if (item[1] === 'Address / Adresse') {
                let vacant
                if (item[5] === '0') {
                  vacant = true
                } else {
                  vacant = false
                }
                let pub
                if (item[6] === '0') {
                  pub = false
                } else {
                  pub = true
                }

                const totseqpdr = item[11].split(':')
                const pdr = {
                  pdrListAddress: item[2].toUpperCase().trim(),
                  pdrListPostalCode: item[3].toUpperCase().trim(),
                  seqcode: seqcode,
                  codeBF: item[4],
                  vacant: vacant,
                  pub: pub,
                  route: [],
                  occuparyStatus: item[7],
                  tpType: item[8].toLowerCase(),
                  mapItems: [{ id: 0, type: item[8].toLowerCase(), loc: null, pocIdList: [], isActive: true }],
                  pdrType: item[9],
                  pdrZone: item[10],
                  pdrseqtotal: Number(totseqpdr[1].replace(/ /g, '')),
                  isActive: false,
                  city: officeAddress.city,
                  province: officeAddress.province,
                  officeId: store.state.userLoggedModule.data.officeselected
                }
                if (pdr.pdrListAddress.substr(0, 4) === 'APP-') {
                  pdr.pdrListAddress = pdr.pdrListAddress.substr(4)
                }
                if (pdr.pdrType === 'Hou/Dom') {
                  stats.cptDom += 1
                  if (pdr.vacant) {
                    stats.cptDomVacant += 1
                  }
                  if (pdr.pub) {
                    stats.cptDomPub += 1
                  }
                } else if (pdr.pdrType === 'Apt/App') {
                  stats.cptApp += 1
                  if (pdr.vacant) {
                    stats.cptAppVacant += 1
                  }
                  if (pdr.pub) {
                    stats.cptAppPub += 1
                  }
                } else if (pdr.pdrType === 'Bus/com') {
                  stats.cptBus += 1
                  if (pdr.vacant) {
                    stats.cptBusVacant += 1
                  }
                  if (pdr.pub) {
                    stats.cptBusPub += 1
                  }
                } else {
                  stats.cptFrm += 1
                  if (pdr.vacant) {
                    stats.cptFrmVacant += 1
                  }
                  if (pdr.pub) {
                    stats.cptFrmPub += 1
                  }
                }
                // pdrs.push(pdr)
                // const poc = store.getters['harmonizeModule/searchHarmonize'](pdr)
                pdrs.push(pdr)
              }
              progress = Math.round((cptligne / countligne) * 100)
              if (progress !== oldprogress) {
                oldprogress = progress
                EventBus.$emit('filepdrlistcsvprogressValueChange', (progress))
              }
            }
            ldata.push({ route: lrname, poc: pdrs, stats: stats, officeCp: officeCp })
            // const ldata = {
            //   roadname: lrname,
            //   officename: loffice,
            //   pdrlist: pdrs
            // }
            EventBus.$emit('filepdrlistcsvproccesscompleted', (ldata))
          }
        }
      }
    },
    csvJSON (csvText) {
      const lines = []
      const linesArray = csvText.split('\n')
      // for trimming and deleting extra space
      linesArray.forEach(e => {
        const row = e.replace(/[\s]+[,]+|[,]+[\s]+/g, ',').trim()
        lines.push(row)
      })
      // for removing empty record
      lines.splice(lines.length - 1, 1)
      const result = []
      const headers = lines[0].split(',')

      for (let i = 1; i < lines.length; i++) {
        const obj = {}
        const currentline = lines[i].split(',')

        for (let j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentline[j]
        }
        result.push(obj)
      }
      // return result; //JavaScript object
      // return JSON.stringify(result);
      // JSON
      return result
    },
    verifDataFile () {
      if (this.importData) {
        // // console.log(this.importData)
        for (let i = 0; i < this.importData.length; i++) {
          const route = this.importData[i]
          // // console.log('nbr poc = ' + route.poc.length)
          // // console.log('nbr bpCom = ' + route.bpComArray.length)
          // // console.log('route = ' + route.route)
          // // console.log('officeCp = ' + route.officeCp)
          if (!route.poc.length) {
            this.validFile = false
            i = this.importData.length
          } else {
            this.validFile = true
          }
        }
      } else {
        this.validFile = false
      }
      // // console.log('validFile = ' + this.validFile)
    }
  },
  mounted () {
    // this.$store.dispatch('harmonizeModule/openDBChannel', { pathVariables: { officeId: this.$store.state.userLoggedModule.data.officeselected } })
    //   .then(() => {
    //     this.harmonizeLoadComplete = true
    //    // // console.log('harmonize loaded')
    //   })
    this.pocLoadComplete = true
    this.$store.dispatch('pocModule/setPathVars', { officeId: this.$store.state.userLoggedModule.data.officeselected })
    this.loadingTitle = this.$t('common.loading')
    this.$refs.inputUpload.click()
    // this.showLoading = true
    // // this.$store.dispatch('pocModule//fetchAndAdd', { clauses: { limit: 0 } })
    // dispatch('pocModule/setPathVars', { officeId: this.$store.state.userLoggedModule.data.officeselected })
    // this.$store.dispatch('pocModule/fetchAndAdd', { pathVariables: { officeId: this.$store.state.userLoggedModule.data.officeselected }, clauses: { limit: 1 } })
    //   .then(() => {
    //     this.pocLoadComplete = true
    //     this.showLoading = false
    //    // // console.log('nbr poc loaded = ' + this.$store.getters['pocModule/numberOfPoc'])
    //     // this.$refs.inputUpload.click()
    //   })
  },
  destroyed () {
    // // console.log('trace destroy harmonizeModule')
    // // console.log('destroy close pocModule')
    // this.$store.dispatch('pocModule/closeDBChannel', { clearModule: true })
  }
}
</script>
