<template>
  <div class="home" style="width: 100vw; height: 100vh;">
    <g-maps
      :mapMode="mapMode"
      :mapStyle="mapStyle"
      :mapOption="mapOption"
      :showControl="showControl"
      :markerData1="markerData"
      :markerData2="officeItemMarker"
      :markerClustererData="markerClustererData"
      :lineMarkerData="lineMarkerData"
      :zoneMarkerData="zoneMarkerData"
      :params="params"
      @doTask="doTask($event)"
      @showParam="showParam = true"
    ></g-maps>
    <div v-show="showInfobox" class="mx-auto overflow-y-auto overflow-x-hidden" :max-height="infoboxHeight"  max-width="744" :style="infoboxStyle">
      <div id="directionsPanel" :style="infoboxStyle"></div>
    </div>
    <pmt-param-maps-info @close="showParam = false" v-if="showParam"/>
  </div>
</template>

<script>
import GMaps from './GMaps.vue'
export default {
  components: { GMaps },
  props: ['mapMode', 'markerData', 'officeItemMarker', 'lineMarkerData', 'zoneMarkerData', 'markerClustererData', 'params'],
  data () {
    return {
      showParam: false,
      showLevel: 'route',
      showInfobox: false,
      mapIsLoaded: false
    }
  },
  methods: {
    doTask (task) {
      switch (task.taskType) {
        case 'mapLoaded':
          this.mapIsLoaded = true
          break
        default:
          this.$emit('doTask', task)
          break
      }
    }
  },
  computed: {
    mapStyle () {
      if (this.showInfobox) {
        return 'width:' + this.$store.getters.contentWidth + 'px; height:' + (this.$store.getters.contentHeight - this.infoboxHeight) + 'px'
      } else {
        return this.$store.getters.contentStyle
      }
    },
    showControl () {
      if (this.mapMode === 'route-info') {
        return {
          showLevelUp: true,
          trackMe: true
        }
      } else if (this.mapMode === 'direction') {
        return {
          showLevelUp: this.$store.getters['userLoggedModule/getTravelSelectedNavInfoType'] !== null,
          trackMe: true
        }
      } else if (this.mapMode === 'road-book') {
        return {
          showLevelUp: this.$store.getters['userLoggedModule/getTravelSelectedNavInfoType'] !== null,
          trackMe: true
        }
      } else {
        return {
          showLevelUp: false,
          trackMe: true
        }
      }
    },
    mapOption () {
      switch (this.mapMode) {
        case 'track-me':
          return {
            adjustMode: 'current',
            startzoom: 19,
            startCenter: this.$store.getters.getCurrentPosition
          }
        default:
          if (this.markerData.bounds) {
            return {
              adjustMode: 'bounds',
              bounds: this.markerData.bounds
            }
          } else if (this.markerData.markerList.length > 1) {
            const bounds = new this.$store.getters.getGoogle.maps.LatLngBounds()
            this.markerData.markerList.forEach(m => {
              bounds.extend(m.marker.getPosition())
            })
            return {
              adjustMode: 'bounds',
              bounds: bounds
            }
          } else if (this.markerData.markerList.length && this.markerData.markerList[0].marker) {
            // console.log('trace temp error')
            // console.log(this.markerData.markerList[0] && this.markerData.markerList[0].marker)
            const pos = this.markerData.markerList[0].marker.getPosition()
            return {
              adjustMode: 'center',
              startzoom: 17,
              startCenter: pos
            }
          } else if (this.mapIsLoaded && this.$store.getters['officeSelectedModule/getOfficePosition']) {
            return {
              adjustMode: 'center',
              startzoom: 17,
              startCenter: this.$store.getters['officeSelectedModule/getOfficePosition']
            }
          } else {
            return {
              adjustMode: 'center',
              startzoom: 16,
              startCenter: { lat: 46.825713, lng: -71.166643 }
            }
          }
      }
    },
    infoboxStyle () {
      return 'width:' + this.$store.getters.contentWidth + 'px; height:' + this.infoboxHeight + 'px'
    },
    infoboxHeight () {
      return Math.round(this.$store.getters.contentHeight * 0.35)
    }
  }
}
</script>
