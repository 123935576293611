<template>
  <div class="home">
    <v-carousel :height="carouselHeight" light :show-arrows="udlSorted.length > 1">
      <v-carousel-item
        v-for="(udl, i) in udlSorted"
        :key="i"
      >
        <display-udl
          :udl="udl"
          :siteName="bpCom.siteId"
          :readOnly="readOnly"
          @selectRta="$emit('selectRta', $event)"
        ></display-udl>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
export default {
  props: ['bpCom', 'readOnly', 'height'],
  methods: {},
  computed: {
    carouselHeight () {
      if (this.height) {
        return this.height
      } else {
        return 900
      }
    }
  },
  data () {
    return {
      udlSorted: this.bpCom.udl.sort(function (a, b) { return Number(a.udlId) - Number(b.udlId) })
    }
  }
}
</script>
