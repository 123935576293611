
const placeModule = {
  firestorePath: 'officeData/{officeId}/place',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'placeModule',
  statePropName: 'data',
  namespaced: true, // automatically added

  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    getPrimaryPictureUrl: (state) => (placeId) => {
      const place = state.data[placeId]
      if (place.imageList && place.imageList.length) {
        return place.imageList.filter(img => img.isPrimary).map(function (x) { return x.url })[0]
      } else {
        return null
      }
    },
    getPlacePictureHomeSrc: (state, getters, rootState, rootGetters) => (info) => {
      const place = state.data[info.placeId]
      if (place.imageList && place.imageList.length && place.homeImageListSelect) {
        return place.imageList.filter(img => img.isPrimary).map(function (x) { return x.url })[0]
      } else {
        // return getPictureSrc(place.homeLocation.lat, place.homeLocation.lng, info.width, info.height)
        return 'https://maps.googleapis.com/maps/api/streetview?size=' + info.width + 'x' + info.height + '&location=' + place.homeLocation.lat + ',' + place.homeLocation.lng + '&key=' + rootState.API_KEY
      }
    },
    getPlaceOfRoute: (state) => (routeId) => {
      return Object.values(state.data).filter(place => place.route.indexOf(routeId) > -1)
    }
    // getPlaceMarkerOfRoute: (state, getters, rootState, rootGetters) => (routeId) => {
    //   return getters.getPlaceOfRoute(routeId).map(function (place) {
    //     return getters.getPlaceMarker(routeId, place)
    //   })
    // },
    // getPlaceMarker: (state, getters, rootState, rootGetters) => (routeId, place) => {
    //   let colorName
    //   let topName
    //   let codeId
    //   let colorId = 1
    //   let allVacant = true
    //   let allNoPub = true
    //   const codeArray = []
    //   const colorArray = []
    //   const cpArray = []
    //   const streetArray = []
    //   const pocInfoArray = []
    //   const seqId = rootGetters['routeModule/getOfficialSeqIdOfRouteId'](routeId)
    //   const pocArray = rootGetters['pocModule/getPocOfPlaceOfRoute'](routeId, place.id)
    //   pocArray.forEach(poc => {
    //     if (poc.id === '0gC0xiqgsnqZn4tfZ9NY') {
    //       // // console.log('trace Start')
    //     }
    //   })
    //   place.pocList.forEach(pocId => {
    //     const poc = rootState.pocModule.data[pocId]
    //     if (poc.route.indexOf(routeId) > -1) {
    //       if (poc.pub) { allNoPub = false }
    //       if (!poc.vacant) { allVacant = false }
    //       const seqInfo = rootGetters['pocModule/getSeqInfoOfPoc'](routeId, seqId, poc)
    //       const doorTemp = poc.pdrListAddress.split(' ')[0]
    //       const street = poc.pdrListAddress.replace(doorTemp, '').trim()
    //       pocInfoArray.push({
    //         pocId: pocId,
    //         colorId: seqInfo ? seqInfo.tier : null,
    //         codeId: seqInfo ? seqInfo.codeId : null,
    //         codeName: seqInfo ? seqInfo.codeName : null,
    //         cp: poc.pdrListPostalCode,
    //         street: street
    //       })
    //       if (streetArray.indexOf(street) < 0) {
    //         streetArray.push(street)
    //       }
    //       if (cpArray.indexOf(poc.pdrListPostalCode) < 0) {
    //         cpArray.push(poc.pdrListPostalCode)
    //       }
    //       if (seqInfo) {
    //         if (!codeId) { codeId = seqInfo.codeId }
    //         if (!colorId) { colorId = seqInfo.tier }
    //         if (seqInfo.codeId && codeArray.map(x => { return x.codeId }).indexOf(seqInfo.codeId) < 0) {
    //           codeArray.push({ codeId: seqInfo.codeId, codeName: seqInfo.codeName })
    //         }
    //         if (seqInfo.tier && colorArray.indexOf(seqInfo.tier) < 0) {
    //           colorArray.push(seqInfo.tier)
    //         }
    //       }
    //     }
    //   })
    //   if (colorId === 3) { colorName = 'blue' } else if (colorId === 2) { colorName = 'pink' } else { colorName = 'orange' }
    //   if (allVacant) {
    //     topName = 'red'
    //   } else if (allNoPub) {
    //     topName = 'green'
    //   } else {
    //     topName = 'black'
    //   }
    //   const marker = rootGetters.getGoogleMarker({
    //     type: 'placeMarker',
    //     label: String(place.sName),
    //     url: require('@/assets/Icon/MapIcon/POC/App-' + colorName + '-t' + topName + '.png')
    //   })
    //   marker.setPosition(place.homeLocation)
    //   return {
    //     type: 'placeMarker',
    //     position: place.homeLocation,
    //     label: { text: String(place.sName), fontSize: store.getMarkerLabelSize(String(place.sName)), color: 'white' },
    //     url: require('@/assets/Icon/MapIcon/POC/App-' + colorName + '-t' + topName + '.png'),
    //     placeId: place.id,
    //     routeId: routeId,
    //     codeId: codeId,
    //     colorId: colorId,
    //     codeArray: codeArray,
    //     colorArray: colorArray,
    //     cpArray: cpArray,
    //     streetArray: streetArray,
    //     pocInfoArray: pocInfoArray,
    //     marker: marker
    //   }
    // }
  },
  mutations: {
    setPlaceHomeLocation  (state, payload) {
      state.data[payload.placeId].homeLocation = payload.homeLocation
    },
    setPlacePocList (state, payload) {
      state.data[payload.placeId].pocList = payload.pocList
    }
  },
  actions: {
    createPlace ({ commit, state, rootState }, payload) {
      let isValidPocList = true
      const route = []
      payload.pocList.forEach(pocId => {
        rootState.pocModule.data[pocId].route.forEach(routeId => {
          if (route.indexOf(routeId) < 0) {
            route.push(routeId)
          }
        })
        if (Object.values(state.data).filter(place => place.pocList.indexOf(pocId) > -1).length) {
          // // console.log('trace pocId = ' + pocId)
          // // console.log((Object.values(state.data).filter(place => place.pocList.indexOf(pocId) > -1)))
          isValidPocList = false
        }
      })
      if (isValidPocList) {
        const place = {
          lName: payload.lName,
          sName: payload.sName,
          homeLocation: payload.homeLocation,
          ggPlaceId: payload.ggPlaceId,
          pocList: payload.pocList,
          imageList: payload.imageList ? payload.imageList : [],
          route: route
        }
        return this.dispatch('placeModule/set', place).then((placeId) => {
          const prom = []
          payload.pocList.forEach(pocId => {
            if (rootState.pocModule.data[pocId].placeId) {
              // remove previous poc from previous place
              prom.push(this.dispatch('placeModule/removePocFromPlace', {
                placeId: rootState.pocModule.data[pocId].placeId,
                pocId: pocId
              }))
            }
            prom.push(this.dispatch('pocModule/set', {
              id: pocId,
              placeId: placeId
            }))
          })
          return Promise.all(prom).then(() => { return placeId })
        })
      } else {
        // // console.log('trace lName =>' + payload.lName + '<=')
        return this.dispatch('showAlert', 'Place ' + payload.lName + ' Already exist')
      }
    },
    changePlaceHomeLocation ({ commit, state, rootState, rootGetters }, payload) {
      // payload = { placeId: '', homeLocation: '' }
      const prom = []
      const place = state.data[payload.placeId]
      place.pocList.forEach(pocId => {
        if (rootState.pocModule.data[pocId]) {
          const pocLoc = rootState.pocModule.data[pocId].homeLocation
          if (this.distance(pocLoc, place.homeLocation < 0.5)) {
            prom.push(this.dispatch('pocModule/set', {
              id: pocId,
              homeLocation: payload.homeLocation
            }))
          }
        }
      })
      prom.push(this.dispatch('placeModule/set', {
        id: payload.placeId,
        homeLocation: payload.homeLocation
      }).then(() => {
        return commit('setPlaceHomeLocation', payload)
      }))
      return Promise.all(prom)
    },
    addPocToPlace ({ commit, state }, payload) {
      // { placeId: '', pocIdArray: [] }
      // // console.log('trace placeModule/addPocToPlace')
      const prom = []
      const pocIdToAddArray = []
      const place = state.data[payload.placeId]
      payload.pocIdArray.forEach(pocId => {
        if (place.pocList.indexOf(pocId) < 0) {
          pocIdToAddArray.push(pocId)
          prom.push(this.dispatch('pocModule/set', { id: pocId, placeId: payload.placeId }))
        }
      })

      prom.push(this.dispatch('placeModule/set', {
        id: payload.placeId,
        pocList: [].concat(pocIdToAddArray, place.pocList)
      }))
      // }).then(() => { return commit('setPlacePocList', { placeId: payload.placeId, pocList: [].concat(pocIdToAddArray, place.pocList) }) }))
      return Promise.all(prom)
    },
    removePocFromPlace ({ commit, state }, payload) {
      // { placeId: '', pocIdArray: [] }
      // // console.log('trace placeModule/removePocFromPlace')
      const prom = []
      // const pocIdToRemoveArray = []
      // const place = state.data[payload.placeId]
      const newPocList = state.data[payload.placeId].pocList
      payload.pocIdArray.forEach(pocId => {
        const index = newPocList.indexOf(pocId)
        if (index > -1) {
          newPocList.splice(index, 1)
          prom.push(this.dispatch('pocModule/set', { id: pocId, placeId: null }))
        }
      })

      prom.push(this.dispatch('placeModule/set', {
        id: payload.placeId,
        pocList: newPocList
      }).then(() => { return commit('setPlacePocList', { placeId: payload.placeId, pocList: newPocList }) }))
      return Promise.all(prom)
    }
  }
}

export default placeModule
