<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="544" fixed transition="fab-transition">
      <v-card>
        <!-- <v-container
          max-height="500"
          class="overflow-x-hidden overflow-y-auto"
        > -->
          <v-data-iterator
            fixed
            ref="dataIterator"
            :custom-filter="customFilter"
            :sort-by="sortBy"
            :sort-desc="isAscending"
            :custom-sort="customSort"
            :search="search"
            :items-per-page="15"
            :no-results-text="$t('common.noSearchResult')"
            :footer-props="{ 'items-per-page-options' : [15, 30, 50, 100, -1] }"
            :items="items"
          >
            <template v-slot:header>
              <v-card v-if="lastColisPoc && searchMode" outlined :class="$store.getColorName(lastColisPoc.color) + ' lighten-5'">
                <p class="mb-n1 red--text">{{ $t('common.lastcolisadd') }} :</p>
                <v-list-item dense>
                  <v-list-item-content three-line>
                    <v-list-item-title v-text="lastColisPoc.pdrListAddress"></v-list-item-title>
                    <v-list-item-subtitle v-text="lastColisPoc.pdrListPostalCode"/>
                    <v-list-item-subtitle v-text="'Code: ' + lastColisPoc.codeName"/>
                    <v-list-item-subtitle v-if="lastColisPoc.canShipMulti" v-text="lastColisPoc.shipToMulti ? $t('shipTo.' + lastColisPoc.multiType) : $t('shipTo.atDoor')"/>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
              <v-app-bar dark color="primary" extended>
                <v-spacer></v-spacer>
                <v-app-bar-title>Colis du jour</v-app-bar-title>
                <v-spacer></v-spacer>
                <v-app-bar-nav-icon @click="close()"><v-icon large>mdi-close-circle</v-icon></v-app-bar-nav-icon>
                <template v-slot:extension>
                  <v-app-bar-nav-icon v-show="dayColisArray.length" @click="searchMode = !searchMode">
                    <v-badge
                      color="green"
                      :content="dayColisArray.length"
                    >
                      <v-img contain max-width="30" max-height="30" :src="$store.getMapItemIconOfType('colis')"/>
                    </v-badge>
                  </v-app-bar-nav-icon>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-show="searchMode"
                    v-model="search"
                    clearable
                    dark
                    extension-height="60"
                    class="mb-1"
                    flat
                    solo-inverted
                    hide-details
                    autofocus
                    prepend-inner-icon="mdi-magnify"
                    @blur="$store.dispatch('resize')"
                    :label="$t('dataIterator.searchLabel')"
                  ></v-text-field>
                  <v-spacer></v-spacer>
                </template>
              </v-app-bar>
            </template>
            <template v-slot:default="{ items }">
              <v-card :max-height="iteratorHeight" :height="iteratorHeight" flat class="overflow-y-auto">
                <v-list>
                  <v-list-item
                    v-for="item in items"
                    :key="item.id"
                  >
                    <v-list-item-content three-line>
                      <v-list-item-title :class="$store.getColorText(item.color)" v-text="item.pdrListAddress"></v-list-item-title>
                      <v-list-item-subtitle v-text="item.pdrListPostalCode"/>
                      <v-list-item-subtitle v-text="'Code: ' + item.codeName"/>
                      <v-radio-group
                        column
                        v-if="item.canShipMulti && searchMode"
                        class="ms-2 mb-n1"
                        :value="true"
                        v-model="item.shipToMulti"
                      >
                        <v-radio
                          class=""
                          :label="$t('shipTo.' + item.tpType)"
                          :value="true"
                        ></v-radio>
                        <v-radio
                          :label="$t('shipTo.atDoor')"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                      <v-divider class="mt-3"></v-divider>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn-toggle dense v-if="dayPocIdArray.indexOf(item.id) > -1">
                        <v-btn small :loading="item.isRemoveLoading" @click="removeColis(item)"><v-icon small>mdi-minus</v-icon></v-btn>
                        <p class="mx-1">{{ dayPocIdArray.filter(x => x === item.id).length }}</p>
                        <v-btn small :loading="item.isAddLoading" @click="addColis(item)"><v-icon small>mdi-plus</v-icon></v-btn>
                      </v-btn-toggle>
                      <v-btn small v-else :loading="item.isAddLoading" @click="addColis(item)"><v-icon>mdi-plus</v-icon></v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card>
          </template>
            <!-- <template v-slot:footer>
              <v-app-bar bottom dark color="primary">
                <v-spacer></v-spacer>
                <v-btn icon x-large dark @click="close()">
                  <v-icon color="green" x-large>mdi-check</v-icon>
                </v-btn>
              </v-app-bar>
            </template> -->
          </v-data-iterator>
        <!-- </v-container> -->
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ['list', 'preselect', 'solo', 'mode'],
  data () {
    return {
      dialog: true,
      selectedList: this.preselect ? this.preselect : [],
      search: null,
      searchMode: true,
      isAscending: false,
      sortBy: 'name'
    }
  },
  methods: {
    isCanShipMulti (poc) {
      if (poc.tpType === 'cmb') {
        return true
      } else {
        return false
      }
    },
    updateAllPocColisShipTo (i) {
      if (!this.searchMode) {
        var multiId = null
        var multiType = null
        if (i.shipToMulti) {
          multiId = i.item.mapItems[0].officeItemMultiId ? i.item.mapItems[0].officeItemMultiId : null
          multiType = i.item.mapItems[0].type
        }
        this.$store.dispatch('routeDayDataModule/updateAllPocColisShipTo', {
          pocId: i.item.id,
          shipToMulti: i.shipToMulti,
          multiType: multiType,
          multiId: multiId
        })
      }
    },
    addColis (item) {
      var multiId = null
      var multiType = item.tpType
      if (item.shipToMulti) {
        multiId = item.mapItems[0].officeItemMultiId ? item.mapItems[0].officeItemMultiId : null
        multiType = item.mapItems[0].type
      }
      item.isAddLoading = true
      this.$store.dispatch('routeDayDataModule/addColis', {
        pocId: item.id,
        color: item.color,
        codeName: item.codeName,
        canShipMulti: item.canShipMulti,
        shipToMulti: item.shipToMulti,
        multiType: multiType,
        multiId: multiId
      }).then(() => {
        item.isAddLoading = false
      })
    },
    removeColis (item) {
      item.isRemoveLoading = true
      this.$store.dispatch('routeDayDataModule/removeLastColisOfPoc', item.id).then(() => {
        item.isRemoveLoading = false
        if (!this.dayColisArray.length) {
          this.searchMode = true
        }
      })
    },
    customFilter (items, search, filter) {
      // const mode = this.searchMode
      // const fieldSearchArray = ['pdrListAddress', 'pdrListPostalCode']
      function newFilter (val, wSearch) {
        return val.pdrListAddress !== null &&
          (val.pdrListAddress.toString().toLowerCase().replace(/[^0-9a-zA-Z-]+/g, '').indexOf(wSearch) !== -1 ||
          val.pdrListPostalCode.toString().toLowerCase().replace(/[^0-9a-zA-Z]+/g, '').indexOf(wSearch) !== -1)
      }
      // // console.log('search mode = ' + this.searchMode)
      // // console.log(items)
      if (!this.searchMode) {
        return items
      } else if (!search) {
        return []
      } else {
        const needleAry = search.toString().trim().toLowerCase().split(' ').filter(x => x.length)
        const results = items.filter(row => needleAry.every(needle => newFilter(row, needle)))
        return results
      }
    },
    customSort (items, index, isDescending) {
      // const mode = this.searchMode
      const distMe = this.$store.getters.distanceOfMe
      const sortBy = index[0]
      const isDesc = isDescending[0]
      // The following is informations as far as I researched.
      // items: 'food' items
      // index: Enabled sort headers value. (black arrow status).
      // isDescending: Whether enabled sort headers is desc

      switch (sortBy) {
        case 'dist':
          if (isDesc) {
            return items.sort((a, b) => {
              return distMe(a.homeLocation) - distMe(b.homeLocation)
            })
          } else {
            return items.sort((a, b) => {
              return distMe(b.homeLocation) - distMe(a.homeLocation)
            })
          }
        case 'seq':
          if (isDesc) {
            return items.sort((a, b) => {
              return a.seqPosTot - b.seqPosTot
            })
          } else {
            return items.sort((a, b) => {
              return b.seqPosTot - a.seqPosTot
            })
          }
        case 'name':
          if (isDesc) {
            return items.sort((a, b) => {
              return a.pdrListAddress < b.pdrListAddress
            })
          } else {
            return items.sort((a, b) => {
              return b.pdrListAddress < a.pdrListAddress
            })
          }
        default:
          return items
      }
    },
    close () {
      this.dialog = false
      this.$emit('close')
    }
  },
  computed: {
    items () {
      if (this.searchMode) {
        return this.$store.getters['pocModule/getPocOfSequenceSelected'].map(x => {
          x.canShipMulti = this.isCanShipMulti(x)
          if (x.canShipMulti) {
            x.shipToMulti = true
          } else {
            x.shipToMulti = false
          }
          x.isRemoveLoading = false
          x.isAddLoading = false
          return x
        })
      } else {
        // // console.log(this.dayPocIdArray)
        // const use = this.$store.getters['routeDayDataModule/getColisOfday'].map(x => { return x.pocId })
        // // console.log(use)
        // // console.log(this.$store.getters['pocModule/getPocOfSequenceSelected'].filter(x => use.indexOf(x.id) > -1))
        return this.$store.getters['pocModule/getPocOfSequenceSelected'].filter(x => this.dayPocIdArray.indexOf(x.id) > -1)
      }
    },
    lastColisPoc () {
      return this.$store.getters['routeDayDataModule/getLastColis']
    },
    iteratorHeight () {
      if (this.searchMode) {
        return Math.round(this.$store.getters.contentHeight * 0.70) - 120
      } else {
        return Math.round(this.$store.getters.contentHeight * 0.70)
      }
    },
    dayColisArray () {
      return this.$store.getters['routeDayDataModule/getColisOfday']
    },
    dayPocIdArray () {
      return this.$store.getters['routeDayDataModule/getColisOfday'].map(x => { return x.pocId })
    }
  },
  mounted () {
    // // console.log(this.items)
  }
}
</script>
<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
