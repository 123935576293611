const paramModule = {
  firestorePath: 'system_param',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'paramModule',
  statePropName: 'data',
  namespaced: true, // automatically added

  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {
    calId: 'calendar',
    userType: 'userType',
    itemMultiId: 'itemMultiId'
  },
  getters: {
    getCalReference: (state, getters) => {
      return state.data[state.calId].reference
    },
    getCalId: (state, getters) => {
      return state.calId
    },
    getCalCalendar: (state, getters) => {
      return state.data[state.calId].calendar
    },
    getCalNotWorkingDayArray: (state, getters) => {
      return state.data[state.calId].notworkingDay
    },
    getUserType: (state) => {
      return state.data[state.userType].typeId
    },
    getColorOfDay: (state, getters) => (date) => {
      let colorCode = 0
      if (state.data[state.calId]) {
        for (let i = 0; i < state.data[state.calId].calendar.length; i++) {
          const calDay = state.data[state.calId].calendar[i]
          if (calDay.year === date.getFullYear() && calDay.month === date.getMonth() && calDay.day === date.getDate()) {
            colorCode = calDay.color
          }
        }
      }
      if (colorCode === 1) {
        return { id: colorCode, color: '#FF9800' }
      } else if (colorCode === 2) {
        return { id: colorCode, color: '#E91E63' }
      } else if (colorCode === 3) {
        return { id: colorCode, color: '#1976D2' }
      } else {
        return { id: colorCode, color: '#424242' }
      }
    },
    dayColor: (state, getters) => {
      const today = new Date()
      let colorCode = 0
      if (state.data[state.calId]) {
        for (let i = 0; i < state.data[state.calId].calendar.length; i++) {
          const calDay = state.data[state.calId].calendar[i]
          if (calDay.year === today.getFullYear() && calDay.month === today.getMonth() && calDay.day === today.getDate()) {
            colorCode = calDay.color
          }
        }
      }
      if (colorCode === 1) {
        return { id: colorCode, color: '#FF9800' }
      } else if (colorCode === 2) {
        return { id: colorCode, color: '#E91E63' }
      } else if (colorCode === 3) {
        return { id: colorCode, color: '#1976D2' }
      } else {
        return { id: colorCode, color: '#424242' }
      }
    },
    sortedPdr: (state, getters) => {
      return Object.values(state.data).sort(function (a, b) {
        return a.csvSeqPosition - b.csvSeqPosition
      }) // your sort function
    }
  },
  mutations: {},
  actions: {
    generateCalendar  ({ commit }) {
      const calendar = this.getters['paramModule/getCalCalendar']
      const ref = this.getters['paramModule/getCalReference']
      const refDate = new Date(ref.year, ref.month, ref.day)

      let color = ref.color
      const notWDayArray = this.getters['paramModule/getCalNotWorkingDayArray']

      for (let cpt = 1; cpt < 500; cpt++) {
        refDate.setDate(refDate.getDate() + 1)
        if (refDate.getDay() && refDate.getDay() !== 6) {
          let workDay = true

          for (let i = 0; i < notWDayArray.length; i++) {
            const notWDay = notWDayArray[i]
            if (notWDay.year === refDate.getFullYear() && notWDay.month === refDate.getMonth() && notWDay.day === refDate.getDate()) {
              workDay = false
            }
          }
          if (workDay) {
            if (color === 3) {
              color = 1
            } else { color++ }

            let dayExist = false
            for (let i = 0; i < calendar.length; i++) {
              const calDay = calendar[i]
              if (calDay.year === refDate.getFullYear() && calDay.month === refDate.getMonth() && calDay.day === refDate.getDate()) {
                dayExist = true
                calDay.color = color
              }
            }
            if (!dayExist) {
              calendar.push({ year: refDate.getFullYear(), month: refDate.getMonth(), day: refDate.getDate(), color: color })
            }
          }
        }
      }
      const id = this.getters['paramModule/getCalId']
      this.dispatch('paramModule/set', { id, calendar: calendar })
    }
  }
}

export default paramModule
