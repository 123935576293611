
const officeTravelModule = {
  firestorePath: 'officeData/{officeId}/officeTravel',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'officeTravelModule',
  statePropName: 'data',
  namespaced: true, // automatically added

  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    getAllTravel: (state, getters, rootState, rootGetters) => {
      function rFilter (travel) {
        if (travel.isActive) {
          return true
        } else if (travel.created_by === rootGetters.userId) {
          return true
        } else {
          return false
        }
      }
      if (rootGetters.isLocalAdmin) {
        return Object.values(state.data)
      } else {
        return Object.values(state.data).filter(rFilter)
      }
    },
    getTravel: (state) => (travelId) => {
      return state.data[travelId]
    },
    getTravelDetail: (state) => (travelId) => {
      return Object.values(state.data[travelId].travelDetail)
      // return state.data[travelId].travelDetail
    },
    getActiveTravelsOfRoute: (state) => (routeId) => {
      return Object.values(state.data).filter(x => { return x.isActive && x.routeId === routeId })
    },
    getActiveTravels: (state, getters, rootState, rootGetters) => {
      return getters.getActiveTravelsOfRoute(rootGetters.getSelectedRouteId)
    },
    getOfficialTravelOfRoute: (state, getters) => (routeId) => {
      const officialArray = getters.getActiveTravelsOfRoute(routeId).filter(x => x.isOfficial)
      if (officialArray.length) {
        return officialArray[0]
      } else {
        return null
      }
    },
    getOfficalTravel: (state, getters, rootState, rootGetters) => {
      return getters.getOfficialTravelOfRoute(rootGetters.getSelectedRouteId)
    },
    getSeletedTravel: (state, getters, rootState, rootGetters) => {
      return getters.getTravel(rootGetters.getSelectedTravelId)
    },
    getTravelMapDetail: (state, getters, rootState, rootGetters) => (travelId) => {
      // // console.log('travelId = ' + travelId)
      // // console.log(travelId)
      if (travelId && state.data[travelId]) {
        const travel = state.data[travelId]
        // const isItemDone = rootGetters['routeDayDataModule/getItemDone']
        var x
        const tItems = travel.travelInfo.items.map(item => {
          switch (item.type) {
            case 'poc':
              if (rootState.pocModule.data[item.id]) {
                x = rootState.pocModule.data[item.id]
                item.markerInfo = rootGetters.getPocMarker({
                  id: x.id,
                  pdrListAddress: x.pdrListAddress,
                  pdrType: x.pdrType,
                  vacant: x.vacant,
                  pub: x.pub,
                  colorId: item.colorId,
                  codeId: item.codeId,
                  codeName: item.codeName,
                  pdrListPostalCode: x.pdrListPostalCode,
                  homeLocation: x.homeLocation,
                  seqPosTot: item.pos
                })
                item.position = x.homeLocation
                item.placeId = x.placeId ? x.placeId : null
              }
              return item
            case 'greyBox':
              item.markerInfo = rootGetters['officeItemModule/getGreyBoxMarkerById'](item.greyBoxId)
              return item
            case 'redBox':
              item.markerInfo = rootGetters['officeItemModule/getRedBoxMarkerById'](item.redBoxId)
              return item
            case 'parcelLocker':
              // // console.log(item)
              item.markerInfo = rootGetters['officeItemModule/getParcelLockerMarkerById'](item.parcelLockerId)
              return item
            case 'taxi-start':
              item.markerInfo = rootGetters.getItemMarker(item)
              return item
            case 'taxi-finish':
              item.markerInfo = rootGetters.getItemMarker(item)
              return item
            case 'taxi-direct':
              item.markerInfo = rootGetters.getItemMarker(item)
              return item
            default:
              // console.log('erreur type item non defini: ' + item.type)
              return {}
          }
        })
        const tWp = travel.travelInfo.travelWayPoint.map(item => {
          item.marker = rootGetters.getTravelWayPointMarker(item)
          return item
        })
        // const placeArray = tItems.filter(x => x.placeId).map(item => { return item.placeId }).filter(rootGetters.getFilterOnlyUnique)
        return {
          type: travel.type,
          items: tItems,
          wp: tWp,
          steps: travel.travelInfo.steps.map(step => {
            step.stepMarker = rootGetters.getStepMarker(travel.transportType, step, tItems, tWp)
            return step
          }).sort(rootGetters.sortByPos),
          group: travel.travelInfo.group,
          transportType: travel.transportType
        }
      } else {
        return null
      }
    },
    getTravelStepsSelected: (state, getters) => {
      if (getters.getTravelMapDetailSelected) {
        return getters.getTravelMapDetailSelected.steps
      } else {
        return null
      }
    },
    getTravelMapDetailSelected: (state, getters, rootState, rootGetters) => {
      if (rootGetters.getSelectedTravelId && rootGetters.postmanDataLoadCompleteReady) {
        return getters.getTravelMapDetail(rootGetters.getSelectedTravelId)
      } else {
        return null
      }
    },
    getTravelSteps: (state, getters, rootState, rootGetters) => {
      function compare (a, b) {
        if (a.pos < b.pos) {
          return -1
        }
        if (a.pos > b.pos) {
          return 1
        }
        return 0
      }
      if (getters.getTravelMapDetailSelected) {
        // return getters.getTravelMapDetailSelected.items.sort(compare).map(item => {
        //   item.todayDone = rootGetters['routeDayDataModule/isItemDone'](item.id)
        //   return item
        // })
        return getters.getTravelMapDetailSelected.items.sort(compare)
      } else {
        return null
      }
    },
    getRoadBook: (state, getters, rootState, rootGetters) => {
      const rBook = []
      var rBookStepCpt = 1
      var wpIdx
      if (getters.getTravelMapDetailSelected) {
        const td = getters.getTravelMapDetailSelected
        const colisArray = rootGetters['routeDayDataModule/getColisOfday']
        const getPocIdArrayOfStepId = function (stepId) {
          return td.items.filter(x => x.stepId === stepId).map(x => { return x.id })
        }
        const getColisOfMulti = function (multiId) {
          return colisArray.filter(x => x.multiId === multiId && x.shipToMulti)
        }
        const getColisOfStep = function (stepId) {
          const pocIdArray = getPocIdArrayOfStepId(stepId)
          // // console.log(pocIdArray)
          return colisArray.filter(x => pocIdArray.indexOf(x.pocId) > -1 && !x.shipToMulti)
        }
        // // console.log(colisDay)

        td.steps.forEach(step => {
          switch (step.type) {
            case 'cmb-direct':
              // // console.log(td)
              wpIdx = td.wp.map(x => { return x.wpId }).indexOf(step.fromWpId)
              step.colisArray = getColisOfMulti(td.wp[wpIdx].multiId)
              step.roadBookPos = rBookStepCpt
              step.stepMarker = rootGetters.getStepMarker(td.transportType, step, td.items, td.wp)
              rBookStepCpt++
              rBook.push(step)
              break
            case 'colis-zone':
              // // console.log('trace colis-zone ' + step.pos)
              // console.log(step)
              step.colisArray = getColisOfStep(step.stepId)
              // console.log(step.colisArray)
              if (step.colisArray.length) {
                step.roadBookPos = rBookStepCpt
                step.stepMarker = rootGetters.getStepMarker(td.transportType, step, td.items, td.wp)
                rBookStepCpt++
                rBook.push(step)
              }
              break
            case 'park-loop':
              step.colisArray = getColisOfStep(step.stepId)
              if (step.colisArray.length) {
                step.roadBookPos = rBookStepCpt
                step.stepMarker = rootGetters.getStepMarker(td.transportType, step, td.items, td.wp)
                rBookStepCpt++
                rBook.push(step)
              }
              break
            case 'loop':
              step.colisArray = getColisOfStep(step.stepId)
              // if (step.colisArray.length) {
              step.roadBookPos = rBookStepCpt
              rBookStepCpt++
              step.stepMarker = rootGetters.getStepMarker(td.transportType, step, td.items, td.wp)
              rBook.push(step)
              // } else {
              //   // console.log('trace test')
              // }
              break
            default:
              // console.log('a faire => ' + step.type)
              break
          }
        })
      }
      return rBook
    }
  },
  mutations: {
    setTravelDetail (state, payload) {
      state.data[payload.id].travelDetail = payload.travelDetail
    },
    setOfficialTravel (state, payload) {
      const prom = []
      const routeId = state.data[payload].routeId
      Object.values(state.data).filter(x => x.routeId === routeId).forEach(travel => {
        if (travel.isOfficial && travel.id !== payload) {
          travel.isOfficial = false
          prom.push(this.dispatch('officeTravelModule/set', {
            id: travel.id,
            isOfficial: false
          }))
        }
      })
      if (state.data[payload] && !state.data[payload].isOfficial) {
        state.data[payload].isOfficial = true
        prom.push(this.dispatch('officeTravelModule/set', {
          id: payload,
          isOfficial: true
        }))
      }
      return Promise.all(prom)
    }
  },
  actions: {
    loadTravelItems ({ commit, state, rootState }, payload) {
      const self = this
      const travel = state.data[payload]
      const prom = []
      travel.travelInfo.items.forEach(item => {
        switch (item.type) {
          case 'poc':
            if (!rootState.pocModule.data[item.id]) {
              // prom.push(this.dispatch('pocModule/fetchById', item.id))
              prom.push(new Promise((resolve, reject) => {
                const pocProm = []
                self.dispatch('pocModule/fetchById', item.id).then(() => {
                  const poc = rootState.pocModule.data[item.id]
                  if (poc.placeId && !rootState.placeModule.data[poc.placeId]) {
                    pocProm.push(self.dispatch('placeModule/fetchById', poc.placeId))
                  }
                  if (poc.mapItems[0].officeItemMultiId && !rootState.officeItemMultiModule.data[poc.mapItems[0].officeItemMultiId]) {
                    pocProm.push(self.dispatch('officeItemMultiModule/fetchById', poc.mapItems[0].officeItemMultiId))
                  }
                  Promise.all(pocProm)
                    .then(() => {
                      resolve(true)
                    })
                    .catch((err) => {
                      reject(err)
                    })
                })
              }))
            }
            break
          case 'multi':
            if (!rootState.officeItemMultiModule.data[item.id]) {
              prom.push(this.dispatch('officeItemMultiModule/fetchById', item.id))
            }
            break
          default:
            break
        }
      })
      return this.dispatch('doBatch', prom)
    },
    updateTravelDetail ({ commit }, payload) {
      const prom = []
      commit('setTravelDetail', payload)
      function closeAll (items) {
        items.forEach(item => {
          if (item.isOpen) { item.isOpen = false }
          if (item.items && item.items.length) {
            return closeAll(item.items)
          } else {
            return true
          }
        })
      }
      const cleanItems = JSON.parse(JSON.stringify(payload.travelDetail.items))
      const cleanItemsUnAssigned = JSON.parse(JSON.stringify(payload.travelDetail.itemsUnAssigned))
      prom.push(closeAll(cleanItems))
      prom.push(closeAll(cleanItemsUnAssigned))
      return Promise.all(prom).then(() => {
        return this.dispatch('officeTravelModule/set', {
          id: payload.id,
          travelDetail: {
            items: cleanItems,
            itemsUnAssigned: cleanItemsUnAssigned
          }
        })
      })
      // return this.dispatch('officeTravelModule/set', {
      //   id: payload.id,
      //   travelDetail: {
      //     items: cleanItems,
      //     itemsUnAssigned: cleanItemsUnAssigned
      //   }
      // })
    },
    setOfficialTravel ({ commit }, payload) {
      return commit('setOfficialTravel')
    }
  }
}

export default officeTravelModule
