<template>
  <div>
    <v-card>
      <v-list-item width="100%">
        <v-list-item-avatar>
          <v-img :src="$store.state.userLoggedModule.data.avatar ? $store.state.userLoggedModule.data.avatar : require('@/assets/logo.png')"></v-img>
        </v-list-item-avatar>
        <v-list-item-content three-line>
          <v-list-item-title class="title">{{ $store.state.userLoggedModule.data.lname }}, {{ $store.state.userLoggedModule.data.fname }}</v-list-item-title>
          <v-list-item-title class="subtitle">{{ $store.state.userLoggedModule.data.empid }}</v-list-item-title>
          <v-list-item-title class="subtitle">{{ this.$t('usertype.' + $store.state.userLoggedModule.data.userType) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-expansion-panels v-model="epModel" focusable multiple popout class="pb-6">
        <v-expansion-panel>
          <v-expansion-panel-header>{{ $t('common.infoperso') }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-text-field
              class="mt-4 mb-n5"
              disabled
              filled
              :label="$t('createuser.mail')"
              :value="$store.state.userLoggedModule.data.email"
              type="email"
            ></v-text-field>
            <v-switch class="mt-n3" v-model="shareEmail" :label="shareEmail ? $t('common.areShareEmail') : $t('common.notShareEmail')"></v-switch>
            <v-divider></v-divider>
            <v-text-field
              class="mt-4 mb-n2"
              filled
              :label="$t('createuser.phone')"
              v-model="phone"
              :rules="[ validPhone ]"
              type="tel"
            ></v-text-field>
            <v-switch v-model="sharePhone" :label="sharePhone ? $t('common.areSharePhone') : $t('common.notSharePhone')"></v-switch>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>{{ $t('common.theme') }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-radio-group v-model="themesMode">
            <v-radio
              :label="$t('common.autoThemes')"
              value="auto"
            ></v-radio>
            <v-radio
              :label="$t('common.orange')"
              value="orange"
            ></v-radio>
            <v-radio
              :label="$t('common.pink')"
              value="pink"
            ></v-radio>
            <v-radio
              :label="$t('common.blue')"
              value="blue"
            ></v-radio>
            <v-radio
              :label="$t('common.none')"
              value="none"
            ></v-radio>
          </v-radio-group>
          <v-switch disabled v-model="isDarkThemes" :label="isDarkThemes ? $t('common.dark') : $t('common.light')"></v-switch>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>{{ $t('common.language') }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-switch v-model="locale" :label="locale ? $t('common.fr') : $t('common.en')"></v-switch>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>{{ $t('common.guardian') }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-switch v-model="guardianActive" :label="guardianActive ? $t('common.active') : $t('common.inactive')"></v-switch>
            <v-card v-show="guardianActive">
              <v-card-text>
                <p class="subtitle-2">{{ $t('common.guardiannotif') }}</p>
                <p class="mb-n3 mt-1">Note :</p>
                <v-switch v-model="guardianNote" :label="guardianNote ? $t('common.active') : $t('common.inactive')"></v-switch>
                <p class="mb-n3 mt-1">Danger :</p>
                <v-switch v-model="guardianDanger" :label="guardianDanger ? $t('common.active') : $t('common.inactive')"></v-switch>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
export default {
  props: [],
  data () {
    return {
      locale: this.$store.getters.getLocale === 'fr',
      guardianActive: this.$store.getters.getGuardianMode.active,
      guardianDanger: this.$store.getters.getGuardianMode.dangerActive,
      guardianNote: this.$store.getters.getGuardianMode.noteActive,
      isDarkThemes: this.$store.getters['userLoggedModule/isDarkThemes'],
      phone: this.$store.formatPhoneNumber(this.$store.state.userLoggedModule.data.phone),
      sharePhone: this.$store.state.userLoggedModule.data.sharePhone === true,
      shareEmail: this.$store.state.userLoggedModule.data.shareEmail === true,
      themesMode: this.$store.getters['userLoggedModule/getThemesMode'],
      epModel: []
    }
  },
  computed: {
    gardianMode () {
      return this.$store.getters.getGuardianMode
    },
    clocale () { return this.$store.getters.getLocale === 'fr' },
    cguardianActive () { return this.$store.getters.getGuardianMode.active },
    cguardianDanger () { return this.$store.getters.getGuardianMode.dangerActive },
    cguardianNote () { return this.$store.getters.getGuardianMode.noteActive },
    cisDarkThemes () { return this.$store.getters['userLoggedModule/isDarkThemes'] },
    cthemesMode () { return this.$store.getters['userLoggedModule/getThemesMode'] }
  },
  watch: {
    locale (newValue) {
      this.$store.dispatch('updateLocale', newValue ? 'fr' : 'en')
    },
    guardianActive (newValue) {
      this.$store.dispatch('updateGuardianMode', { active: newValue })
    },
    guardianDanger (newValue) {
      this.$store.dispatch('updateGuardianMode', { dangerActive: newValue })
    },
    guardianNote (newValue) {
      this.$store.dispatch('updateGuardianMode', { noteActive: newValue })
    },
    isDarkThemes (newValue) {
      // this.$store.commit('setIsDarkThemes', newValue)
      this.$store.dispatch('userLoggedModule/updateIsDarkThemes', newValue)
    },
    sharePhone (newValue) {
      this.$store.dispatch('userLoggedModule/updateSharePhone', newValue)
    },
    shareEmail (newValue) {
      this.$store.dispatch('userLoggedModule/updateShareEmail', newValue)
    },
    phone (newValue) {
      if (this.validPhone(newValue) === true) {
        this.$store.dispatch('userLoggedModule/updatePhone', this.$store.formatPhoneNumber(newValue))
      }
    },
    themesMode (newValue) {
      this.$store.dispatch('userLoggedModule/updateThemesMode', newValue)
    },
    clocale (newValue) { this.locale = newValue },
    cguardianActive (newValue) { this.guardianActive = newValue },
    cguardianDanger (newValue) { this.guardianDanger = newValue },
    cguardianNote (newValue) { this.guardianNote = newValue },
    cisDarkThemes (newValue) { this.isDarkThemes = newValue },
    cthemesMode (newValue) { this.themesMode = newValue }
  },
  methods: {
    validPhone (phone) {
      if (phone) {
        if (this.$store.formatPhoneNumber(phone)) {
          return true
        } else {
          return this.$t('common.invalidPhone')
        }
      } else {
        return true
      }
    }
  }
}
</script>
