import firebase from 'firebase/app'
const commonFunctionPlugin = store => {
  store.truncate = function (str) {
    return str.replace(/-/g, '') + ' ...was truncaaaated!'
  }
  store.cloneObj = function (obj) {
    var clonedObjectsArray = []
    var originalObjectsArray = []
    var nextObjId = 0

    function objectId (obj) {
      if (obj === null) return null
      if (obj.__obj_id === undefined) {
        obj.__obj_id = nextObjId++
        originalObjectsArray[obj.__obj_id] = obj
      }
      return obj.__obj_id
    }

    function cloneRecursive (obj) {
      if (obj === null || typeof obj === 'string' || typeof obj === 'number' || typeof obj === 'boolean') return obj
      var copy
      // Handle Date
      if (obj instanceof Date) {
        copy = new Date()
        copy.setTime(obj.getTime())
        return copy
      }

      // Handle Array
      if (obj instanceof Array) {
        copy = []
        for (var i = 0; i < obj.length; ++i) {
          copy[i] = cloneRecursive(obj[i])
        }
        return copy
      }

      // Handle Object
      if (obj instanceof Object) {
        if (clonedObjectsArray[objectId(obj)] !== undefined) {
          return clonedObjectsArray[objectId(obj)]
        }
        // Handle Function
        if (obj instanceof Function) {
          copy = function () { return obj.apply(this, arguments) }
        } else {
          copy = {}
        }

        clonedObjectsArray[objectId(obj)] = copy

        for (var attr in obj) {
          // if (attr !== '__obj_id' && obj.hasOwnProperty(attr)) {
          if (attr !== '__obj_id' && obj[attr] !== null && obj[attr] !== undefined) {
            copy[attr] = cloneRecursive(obj[attr])
          }
        }
        return copy
      }

      throw new Error("Unable to copy obj! Its type isn't supported.")
    }
    var cloneObj = cloneRecursive(obj)

    // remove the unique ids
    for (var i = 0; i < originalObjectsArray.length; i++) {
      delete originalObjectsArray[i].__obj_id
    }

    return cloneObj
  }
  store.getTimeStamp = function (date) {
    return firebase.firestore.Timestamp.fromDate(date)
  }
  store.degreesToRadians = function (degrees) {
    const pi = Math.PI
    return degrees * (pi / 180)
  }
  store.distance = function (pos1, pos2) {
    const location1 = { latitude: 0, longitude: 0 }
    const location2 = { latitude: 0, longitude: 0 }
    if (!pos1 || !pos2) { return 10000 }
    if (pos1.lat) { location1.latitude = pos1.lat }
    if (pos1.lng) { location1.longitude = pos1.lng }
    if (pos1.latitude) { location1.latitude = pos1.latitude }
    if (pos1.longitude) { location1.longitude = pos1.longitude }
    if (pos2) {
      if (pos2.lat) { location2.latitude = pos2.lat }
      if (pos2.lng) { location2.longitude = pos2.lng }
      if (pos2.latitude) { location2.latitude = pos2.latitude }
      if (pos2.longitude) { location2.longitude = pos2.longitude }
    }

    const radius = 6371 // Earth's radius in kilometers
    const latDelta = store.degreesToRadians(location2.latitude - location1.latitude)
    const lonDelta = store.degreesToRadians(location2.longitude - location1.longitude)

    const a = (Math.sin(latDelta / 2) * Math.sin(latDelta / 2)) +
            (Math.cos(store.degreesToRadians(location1.latitude)) * Math.cos(store.degreesToRadians(location2.latitude)) *
            Math.sin(lonDelta / 2) * Math.sin(lonDelta / 2))

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    const dist = Math.round((radius * c) * 10000)
    return dist / 10
  }
  store.getColorName = function (colorId) {
    switch (String(colorId)) {
      case '1':
        return 'orange'
      case '2':
        return 'pink'
      case '3':
        return 'blue'
      default:
        return 'black'
    }
  }
  store.formatPhoneNumber = function (str) {
    // Filter only numbers from the input
    const cleaned = ('' + str).replace(/\D/g, '')

    // Check if the input is of correct length
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }

    return null
  }
  store.isValidDate = function (dateString) {
    var regEx = /^\d{4}-\d{2}-\d{2}$/
    if (!dateString.match(regEx)) { return false } // Invalid format
    var d = new Date(dateString)
    var dNum = d.getTime()
    if (!dNum && dNum !== 0) { return false } // NaN value, Invalid date
    return d.toISOString().slice(0, 10) === dateString
  }
  store.getUniqueKey = function () {
    var d = new Date().getTime()
    var d2 = (performance && performance.now && (performance.now() * 1000)) || 0
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16
      if (d > 0) {
        r = (d + r) % 16 | 0
        d = Math.floor(d / 16)
      } else {
        r = (d2 + r) % 16 | 0
        d2 = Math.floor(d2 / 16)
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
    })
  }
  store.getCumulTotalStatFromPocArray = function (pocArray) {
    let pocActive = 0
    let vacant = 0
    let pub = 0
    pocArray.forEach(poc => {
      if (poc.vacant) { vacant++ } else {
        pocActive++
        if (poc.pub) { pub++ }
      }
    })
    return { pocActive: pocActive, pub: pub, vacant: vacant }
  }
  store.getCumulStatFromPocArray = function (pocArray) {
    const stat = { app: 0, appP: 0, appV: 0, hou: 0, houP: 0, houV: 0, bus: 0, busP: 0, busV: 0, farm: 0, farmP: 0, farmV: 0, tot: 0, totP: 0, totV: 0 }
    pocArray.forEach(poc => {
      switch (poc.pdrType) {
        case 'Hou/Dom':
          if (poc.vacant) { stat.houV++ } else {
            stat.hou++
            if (poc.pub) { stat.houP++ }
          }
          break
        case 'Apt/App':
          if (poc.vacant) { stat.appV++ } else {
            stat.app++
            if (poc.pub) { stat.appP++ }
          }
          break
        case 'Bus/com':
          if (poc.vacant) { stat.busV++ } else {
            stat.bus++
            if (poc.pub) { stat.busP++ }
          }
          break
        case 'Frm':
          if (poc.vacant) { stat.farmV++ } else {
            stat.farm++
            if (poc.pub) { stat.farmP++ }
          }
          break
        default:
          if (poc.vacant) { stat.farmV++ } else {
            stat.farm++
            if (poc.pub) { stat.farmP++ }
          }
          break
      }
    })
    stat.tot = stat.app + stat.hou + stat.bus + stat.farm
    stat.totP = stat.appP + stat.houP + stat.busP + stat.farmP
    stat.totV = stat.appV + stat.houV + stat.busV + stat.farmV
    return stat
  }
  store.getPosCenterOfPocArray = function (pocArray) {
    const validPos = []
    const usedPlaceId = []
    const pocArraySorted = pocArray.sort(function (a, b) {
      return a.seqPosTot - b.seqPosTot
    })
    pocArraySorted.forEach(poc => {
      if (poc && poc.placeId) {
        if (usedPlaceId.indexOf(poc.placeId) < 0) {
          usedPlaceId.push(poc.placeId)
          validPos.push(store.state.placeModule.data[poc.placeId].homeLocation)
        }
      } else if (poc && poc.homeLocation) {
        validPos.push(poc.homeLocation)
      }
    })
    if (validPos.length) {
      return validPos[Math.floor(validPos.length / 2)]
    } else {
      return null
    }
  }
  store.getPosCenterOfPosArray = function (posArray) {
    const posArraySorted = posArray.sort(function (a, b) {
      return a.seqPosTot - b.seqPosTot
    })
    return posArraySorted[Math.floor(posArraySorted.length / 2)].position
  }
  store.getPosAverageOfPocArray = function (pocArray) {
    const cum = { lat: 0, lng: 0, cpt: 0 }
    const usedPlaceId = []
    pocArray.forEach(poc => {
      if (poc && poc.placeId) {
        if (usedPlaceId.indexOf(poc.placeId) < 0) {
          usedPlaceId.push(poc.placeId)
          cum.lat += store.state.placeModule.data[poc.placeId].homeLocation.lat
          cum.lng += store.state.placeModule.data[poc.placeId].homeLocation.lng
          cum.cpt++
        }
      } else {
        if (poc && poc.homeLocation) {
          cum.lat += poc.homeLocation.lat
          cum.lng += poc.homeLocation.lng
          cum.cpt++
        }
      }
    })
    return { lat: cum.lat / cum.cpt, lng: cum.lng / cum.cpt }
  }
  store.getPosAverageOfPosArray = function (posArray) {
    const cum = { lat: 0, lng: 0, cpt: 0 }
    posArray.forEach(pos => {
      cum.lat += pos.lat
      cum.lng += pos.lng
      cum.cpt++
    })
    return { lat: cum.lat / cum.cpt, lng: cum.lng / cum.cpt }
  }
  store.getPosAverageOnFirtsPosOfPosArray = function (posArray) {
    const cum = { lat: 0, lng: 0, cpt: 0 }
    posArray.forEach(pos => {
      cum.lat += pos.lat
      cum.lng += pos.lng
      cum.cpt++
    })
    const center = { lat: cum.lat / cum.cpt, lng: cum.lng / cum.cpt }
    return posArray.sort(function (a, b) {
      return store.distance(center, a.x) - store.distance(center, b.x)
    })[0]
  }
  store.getAlertMdiIcon = function (type) {
    if (type === 'dog') {
      return 'mdi-dog-side'
    } else if (type === 'ice') {
      return 'mdi-snowflake-alert'
    } else if (type === 'brokenstep') {
      return 'mdi-stairs-up'
    } else if (type === 'fullbox') {
      return 'mdi-mailbox-up'
    } else if (type === 'house') {
      return 'mdi-home-map-marker'
    } else {
      return 'mdi-alert'
    }
  }
  store.getAlertPngIcon = function (type) {
    if (type === 'dog') {
      return require('@/assets/Icon/MapIcon/dog-side.png')
    } else if (type === 'ice') {
      return require('@/assets/Icon/MapIcon/snowflake-alert.png')
    } else if (type === 'brokenstep') {
      return require('@/assets/Icon/MapIcon/stairs-up.png')
    } else if (type === 'house') {
      return require('@/assets/Icon/MapIcon/home-map-marker.png')
    } else {
      return require('@/assets/Icon/MapIcon/alert.png')
    }
  }
  store.sortByPos = function (a, b) {
    if (a.pos < b.pos) {
      return -1
    }
    if (a.pos > b.pos) {
      return 1
    }
    return 0
  }
  store.sortByRoadBookPos = function (a, b) {
    if (a.roadBookPos < b.roadBookPos) {
      return -1
    }
    if (a.roadBookPos > b.roadBookPos) {
      return 1
    }
    return 0
  }
  store.sortByPosDesc = function (a, b) {
    if (b.pos < a.pos) {
      return -1
    }
    if (b.pos > a.pos) {
      return 1
    }
    return 0
  }
  store.sortByText = function (a, b) {
    if (a.text < b.text) {
      return -1
    }
    if (a.text > b.text) {
      return 1
    }
    return 0
  }
  store.sortByDateCreated = function (a, b) {
    return a.create_At - b.create_At
  }
  store.filterOnlyUnique = function (value, index, self) {
    return self.indexOf(value) === index
  }
}

export default commonFunctionPlugin
