<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" width="290" fixed persistent transition="fab-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="accent"
          v-bind="attrs"
          v-on="on"
          icon
        >
          <v-icon>mdi-calendar</v-icon>
        </v-btn>
      </template>
      <v-app-bar dense dark color="primary">
        <v-spacer></v-spacer>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon large dark @click="dialog = false">
          <v-icon large>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card>
        <v-date-picker
          :value="presetDate"
          @change="$emit('newDate', $event); dialog = false"
        ></v-date-picker>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ['title', 'presetDate'],
  data () {
    return {
      dialog: false
    }
  },
  watch: {
    dialog (newValue) {
      this.$store.dispatch('resize')
    }
  }
}
</script>
