const userLoggedModule = {
  firestorePath: 'user/{userId}',
  firestoreRefType: 'doc', // or 'doc'
  moduleName: 'userLoggedModule',
  statePropName: 'data',
  namespaced: true, // automatically added
  state: {
  },
  getters: {
    getTravelSelectedNavInfo: (state) => {
      if (state.data.travelselected && state.data.travelselected.navInfo) {
        return state.data.travelselected.navInfo
      } else {
        return null
      }
    },
    getTravelSelectedNavInfoType: (state) => {
      if (state.data.travelselected && state.data.travelselected.navInfo && state.data.travelselected.navInfo.type) {
        return state.data.travelselected.navInfo.type
      } else {
        return null
      }
    },
    getTravelSelectedId: (state) => {
      if (state.data.travelselected) {
        return state.data.travelselected.id
      } else {
        return null
      }
    },
    getData: (state) => {
      return state.data
    },
    getCurrentNav: (state) => {
      if (state.data.currentNav) {
        return state.data.currentNav
      } else {
        return { comp: 'pmt-pm-babillard', kAlive: true }
      }
    },
    getThemesMode: (state) => {
      if (state.data.param && state.data.param.themes && state.data.param.themes.themesMode) {
        return state.data.param.themes.themesMode
      } else {
        return 'none'
      }
    },
    isDarkThemes: (state) => {
      if (state.data.param && state.data.param.themes && state.data.param.themes.isDarkThemes) {
        return true
      } else {
        return false
      }
    },
    getMapInfoParam: (state) => {
      return state.data.param.mapInfo
    },
    getMapinfoShowLevel: (state, getters, rootState) => {
      if (getters.getSelectionPdr) {
        return 'pocDetail'
      } else if (getters.getSelectionPlace) {
        return 'placeDetail'
      } else if (getters.getSelectionRoute) {
        if (rootState.routeModule.data[getters.getSelectionRoute].type === 1) {
          switch (getters.getRouteInfoSttpGroupBy) {
            case 'color':
              if (getters.getSelectionColor) {
                return 'placeOfColor'
              } else {
                return 'colorOfRoute'
              }
            case 'code':
              if (getters.getSelectionCode) {
                return 'placeOfCode'
              } else {
                return 'codeOfRoute'
              }
            case 'street':
              if (getters.getSelectionStreet) {
                return 'placeOfStreet'
              } else {
                return 'streetOfRoute'
              }
            case 'cp':
              if (getters.getSelectionCp) {
                return 'placeOfCp'
              } else {
                return 'cpOfRoute'
              }
            case 'color-code':
              if (getters.getSelectionColor) {
                if (getters.getSelectionCode) {
                  return 'placeOfCodeOfColor'
                } else {
                  return 'codeOfColor'
                }
              } else {
                return 'colorOfRoute'
              }
            case 'color-street':
              if (getters.getSelectionColor) {
                if (getters.getSelectionStreet) {
                  return 'placeOfStreetOfColor'
                } else {
                  return 'streetOfColor'
                }
              } else {
                return 'colorOfRoute'
              }
            case 'color-cp':
              if (getters.getSelectionColor) {
                if (getters.getSelectionCp) {
                  return 'placeOfCpOfColor'
                } else {
                  return 'cpOfColor'
                }
              } else {
                return 'colorOfRoute'
              }
            case 'street-cp':
              if (getters.getSelectionStreet) {
                if (getters.getSelectionCp) {
                  return 'placeOfCpOfStreet'
                } else {
                  return 'cpOfStreet'
                }
              } else {
                return 'streetOfRoute'
              }
            default:
              return 'placeOfRoute'
          }
        } else {
          switch (getters.getRouteInfoFfrsGroupBy) {
            case 'street':
              if (getters.getSelectionStreet) {
                return 'placeOfStreet'
              } else {
                return 'streetOfRoute'
              }
            case 'cp':
              if (getters.getSelectionCode) {
                return 'placeOfCp'
              } else {
                return 'cpOfRoute'
              }
            case 'code':
              if (getters.getSelectionCode) {
                return 'placeOfCode'
              } else {
                return 'codeOfRoute'
              }
            case 'street-cp':
              if (getters.getSelectionStreet) {
                if (getters.getSelectionCp) {
                  return 'placeOfCpOfStreet'
                } else {
                  return 'cpOfStreet'
                }
              } else {
                return 'streetOfRoute'
              }
            default:
              return 'placeOfRoute'
          }
        }
      } else if (getters.getSelectionOffice) {
        return 'routeOfOffice'
      } else if (getters.getSelectionZone) {
        return 'officeOfZone'
      } else {
        return 'zone'
      }
    },
    getSelection: (state) => {
      return state.data.selection
    },
    getSelectionPdr: (state, getters) => {
      if (getters.getSelection.pdr) {
        return getters.getSelection.pdr
      } else { return null }
    },
    getSelectionPlace: (state, getters) => {
      if (getters.getSelection.place) {
        return getters.getSelection.place
      } else { return null }
    },
    getSelectionCp: (state, getters) => {
      if (getters.getSelection.cp) {
        return getters.getSelection.cp
      } else { return null }
    },
    getSelectionStreet: (state, getters) => {
      if (getters.getSelection.street) {
        return getters.getSelection.street
      } else { return null }
    },
    getSelectionCode: (state, getters) => {
      if (getters.getSelection.code) {
        return getters.getSelection.code
      } else { return null }
    },
    getSelectionColor: (state, getters) => {
      if (getters.getSelection.color) {
        return getters.getSelection.color
      } else { return null }
    },
    getSelectionRoute: (state, getters) => {
      if (getters.getSelection.route) {
        return getters.getSelection.route
      } else { return null }
    },
    getSelectionOffice: (state, getters) => {
      if (getters.getSelection.office) {
        return getters.getSelection.office
      } else { return null }
    },
    getSelectionZone: (state, getters) => {
      if (getters.getSelection.zone) {
        return getters.getSelection.zone
      } else { return null }
    },
    getMapMode: (state, getters) => {
      if (state.data.mapMode) {
        return state.data.mapMode
      } else {
        return 'route-info'
      }
    },
    getMarkerShow: (state) => {
      if (state.data.param.markerShow) {
        return state.data.param.markerShow
      } else { return null }
    },
    getRouteInfoFfrsGroupBy: (state) => {
      if (state.data.routeInfoFfrsGroupBy) {
        return state.data.routeInfoFfrsGroupBy
      } else {
        return 'street'
      }
    },
    getRouteInfoSttpGroupBy: (state) => {
      if (state.data.routeInfoSttpGroupBy) {
        return state.data.routeInfoSttpGroupBy
      } else {
        return 'color-code'
      }
    },
    getUserType: (state) => {
      if (state.data.userType) {
        return state.data.userType
      } else {
        return 0
      }
    }
  },
  mutations: {
    // this.dispatch('userLoggedModule/set', { routeselected: payload, sequenceselected: 0 })
    setRouteSelected (state, payload) {
      // console.log('trace set new Route')
      // console.log(payload)
      state.data.routeselected = payload.routeId
      state.data.sequenceselected = 0
      if (!payload.travelId && state.data.mapMode === 'road-book') {
        state.data.mapMode = 'route-info'
      }
      state.data.travelselected = {
        id: payload.travelId,
        navInfo: null
      }
      return this.dispatch('userLoggedModule/set', {
        routeselected: state.data.routeselected,
        sequenceselected: state.data.sequenceselected,
        travelselected: state.data.travelselected,
        mapMode: state.data.mapMode
      })
    },
    setTravelSelected (state, payload) {
      state.data.travelselected = payload
      return this.dispatch('userLoggedModule/set', { travelselected: state.data.travelselected })
    },
    setTravelSelectedId (state, payload) {
      state.data.travelselected = { id: payload, navInfo: null }
      return this.dispatch('userLoggedModule/set', { travelselected: state.data.travelselected })
    },
    setTravelSelectedNavInfo (state, payload) {
      state.data.travelselected.navInfo = payload
      return this.dispatch('userLoggedModule/set', { travelselected: state.data.travelselected })
    },
    setCurrentNav (state, payload) {
      state.data.currentNav = payload
    },
    setThemesMode (state, payload) {
      if (state.data.param.themes) {
        state.data.param.themes.themesMode = payload
      } else {
        state.data.param.themes = { themesMode: payload }
      }
      this.dispatch('assignCurrentThemes')
      return this.dispatch('userLoggedModule/set', { param: state.data.param })
    },
    setIsDarkThemes (state, payload) {
      if (state.data.param.themes) {
        state.data.param.themes.isDarkThemes = payload
      } else {
        state.data.param.themes = { isDarkThemes: payload }
      }
      return this.dispatch('userLoggedModule/set', { param: state.data.param })
    },
    setPhone (state, payload) {
      state.data.phone = payload
      return this.dispatch('userLoggedModule/set', { phone: payload })
    },
    setSharePhone (state, payload) {
      state.data.sharePhone = payload
      return this.dispatch('userLoggedModule/set', { sharePhone: payload })
    },
    setShareEmail (state, payload) {
      state.data.shareEmail = payload
      return this.dispatch('userLoggedModule/set', { shareEmail: payload })
    },
    setRouteInfoSttpGroupBy (state, payload) {
      state.data.routeInfoSttpGroupBy = payload
      return this.dispatch('userLoggedModule/set', { routeInfoSttpGroupBy: payload })
    },
    setRouteInfoFfrsGroupBy (state, payload) {
      state.data.routeInfoFfrsGroupBy = payload
      return this.dispatch('userLoggedModule/set', { routeInfoFfrsGroupBy: payload })
    },
    setSelection (state, payload) {
      state.data.selection = payload
      return this.dispatch('userLoggedModule/set', { selection: payload })
    },
    setSelectionPdr (state, payload) {
      state.data.selection.pdr = payload
      return this.dispatch('userLoggedModule/set', { selection: state.data.selection })
    },
    setSelectionPlace (state, payload) {
      state.data.selection.place = payload
      return this.dispatch('userLoggedModule/set', { selection: state.data.selection })
    },
    setSelectionCp (state, payload) {
      state.data.selection.cp = payload
      return this.dispatch('userLoggedModule/set', { selection: state.data.selection })
    },
    setSelectionStreet (state, payload) {
      state.data.selection.street = payload
      return this.dispatch('userLoggedModule/set', { selection: state.data.selection })
    },
    setSelectionCode (state, payload) {
      state.data.selection.code = payload
      return this.dispatch('userLoggedModule/set', { selection: state.data.selection })
    },
    setSelectionColor (state, payload) {
      state.data.selection.color = payload
      return this.dispatch('userLoggedModule/set', { selection: state.data.selection })
    },
    setSelectionRoute (state, payload) {
      state.data.selection.route = payload
      return this.dispatch('userLoggedModule/set', { selection: state.data.selection })
    },
    setSelectionOffice (state, payload) {
      state.data.selection.office = payload
      return this.dispatch('userLoggedModule/set', { selection: state.data.selection })
    },
    setSelectionZone (state, payload) {
      state.data.selection.zone = payload
      return this.dispatch('userLoggedModule/set', { selection: state.data.selection })
    },
    setMapMode (state, payload) {
      state.data.mapMode = payload
      state.data.travelselected.navInfo = null
      return this.dispatch('userLoggedModule/set', { mapMode: payload, travelselected: state.data.travelselected })
    },
    setMarkerShow (state, payload) {
      state.data.param.markerShow = payload
      return this.dispatch('userLoggedModule/set', { param: state.data.param })
    },
    setParamMapInfo (state, payload) {
      state.data.param.mapInfo = payload
      return this.dispatch('userLoggedModule/set', { param: state.data.param })
    },
    setParamMapInfoShowParking (state, payload) {
      state.data.param.mapInfo.showParking = payload
      return this.dispatch('userLoggedModule/set', { param: state.data.param })
    },
    setParamMapInfoShowCodeLine (state, payload) {
      state.data.param.mapInfo.showCodeLine = payload
      return this.dispatch('userLoggedModule/set', { param: state.data.param })
    },
    setParamMapInfoShowBpCom (state, payload) {
      state.data.param.mapInfo.showBpCom = payload
      return this.dispatch('userLoggedModule/set', { param: state.data.param })
    },
    setParamMapInfoShowColorZone (state, payload) {
      state.data.param.mapInfo.showColorZone = payload
      return this.dispatch('userLoggedModule/set', { param: state.data.param })
    },
    setParamMapInfoShowMe (state, payload) {
      state.data.param.mapInfo.showMe = payload
      return this.dispatch('userLoggedModule/set', { param: state.data.param })
    },
    setParamMapInfoTrackMe (state, payload) {
      state.data.param.mapInfo.trackMe = payload
      return this.dispatch('userLoggedModule/set', { param: state.data.param })
    },
    setParamMapInfoShowPocMarker (state, payload) {
      state.data.param.mapInfo.showPocMarker = payload
      return this.dispatch('userLoggedModule/set', { param: state.data.param })
    },
    setParamMapInfoShowDog (state, payload) {
      state.data.param.mapInfo.showDog = payload
      return this.dispatch('userLoggedModule/set', { param: state.data.param })
    },
    setParamMapInfoShowToilet (state, payload) {
      state.data.param.mapInfo.showToilet = payload
      return this.dispatch('userLoggedModule/set', { param: state.data.param })
    },
    setParamMapInfoShowGreyBox (state, payload) {
      state.data.param.mapInfo.showGreyBox = payload
      return this.dispatch('userLoggedModule/set', { param: state.data.param })
    },
    setParamMapInfoShowRedBox (state, payload) {
      state.data.param.mapInfo.showRedBox = payload
      return this.dispatch('userLoggedModule/set', { param: state.data.param })
    },
    setParamMapInfoShowParcelLocker (state, payload) {
      state.data.param.mapInfo.showParcelLocker = payload
      return this.dispatch('userLoggedModule/set', { param: state.data.param })
    }
  },
  actions: {
    updateCurrentNav ({ commit }, payload) {
      commit('setCurrentNav', payload)
      this.dispatch('userLoggedModule/set', { currentNav: payload })
    },
    updateSelection ({ commit }, payload) {
      return commit('setSelection', payload)
    },
    updateMarkerShow ({ commit }, payload) {
      commit('setMapMode', 'marker-show')
      return commit('setMarkerShow', { type: payload.type, id: payload.id })
    },
    updateSelectionField ({ commit, state }, payload) {
      const selection = state.data.selection ? state.data.selection : {}
      if (payload.pdr !== undefined) { selection.pdr = payload.pdr }
      if (payload.place !== undefined) { selection.place = payload.place }
      if (payload.cp !== undefined) { selection.cp = payload.cp }
      if (payload.street !== undefined) { selection.street = payload.street }
      if (payload.code !== undefined) { selection.code = payload.code }
      if (payload.color !== undefined) { selection.color = payload.color }
      if (payload.route !== undefined) { selection.route = payload.route }
      if (payload.office !== undefined) { selection.office = payload.office }
      if (payload.zone !== undefined) { selection.zone = payload.zone }
      return this.dispatch('userLoggedModule/updateSelection', selection)
    },
    updateSelectionPdr ({ commit, state }, payload) {
      if (state.data.selection.pdr !== payload) {
        return commit('setSelectionPdr', payload)
      } else {
        return null
      }
    },
    updateSelectionPlace ({ commit, state }, payload) {
      if (state.data.selection.place !== payload) {
        return commit('setSelectionPlace', payload)
      } else {
        return null
      }
    },
    updateSelectionCp ({ commit, state }, payload) {
      if (state.data.selection.cp !== payload) {
        return commit('setSelectionCp', payload)
      } else {
        return null
      }
    },
    updateSelectionStreet ({ commit, state }, payload) {
      if (state.data.selection.street !== payload) {
        return commit('setSelectionStreet', payload)
      } else {
        return null
      }
    },
    updateSelectionCode ({ commit, state }, payload) {
      if (state.data.selection.code !== payload) {
        return commit('setSelectionCode', payload)
      } else {
        return null
      }
    },
    updateSelectionColor ({ commit, state }, payload) {
      if (state.data.selection.color !== payload) {
        return commit('setSelectionColor', payload)
      } else {
        return null
      }
    },
    updateSelectionRoute ({ commit, state }, payload) {
      if (state.data.selection.route !== payload) {
        return commit('setSelectionRoute', payload)
      } else {
        return null
      }
    },
    updateSelectionOffice ({ commit, state }, payload) {
      if (state.data.selection.office !== payload) {
        return commit('setSelectionOffice', payload)
      } else {
        return null
      }
    },
    updateSelectionZone ({ commit, state }, payload) {
      if (state.data.selection.zone !== payload) {
        return commit('setSelectionZone', payload)
      } else {
        return null
      }
    },
    updateMapMode ({ commit, state }, payload) {
      if (state.data.mapMode !== payload) {
        return commit('setMapMode', payload)
      } else {
        return null
      }
    },
    updateThemesMode ({ commit }, payload) {
      return commit('setThemesMode', payload)
    },
    updateIsDarkThemes ({ commit }, payload) {
      return commit('setIsDarkThemes', payload)
    },
    updateSharePhone ({ commit }, payload) {
      return commit('setSharePhone', payload)
    },
    updateShareEmail ({ commit }, payload) {
      return commit('setShareEmail', payload)
    },
    updatePhone ({ commit }, payload) {
      return commit('setPhone', payload)
    },
    updateParamMapInfoShowParking ({ commit }, payload) {
      return commit('setParamMapInfoShowParking', payload)
    },
    updateParamMapInfoShowCodeLine ({ commit }, payload) {
      return commit('setParamMapInfoShowCodeLine', payload)
    },
    updateParamMapInfoShowColorZone ({ commit }, payload) {
      return commit('setParamMapInfoShowColorZone', payload)
    },
    updateParamMapInfoShowBpCom ({ commit }, payload) {
      return commit('setParamMapInfoShowBpCom', payload)
    },
    updateParamMapInfoShowMe ({ commit }, payload) {
      return commit('setParamMapInfoShowMe', payload)
    },
    updateParamMapInfoTrackMe ({ commit }, payload) {
      return commit('setParamMapInfoTrackMe', payload)
    },
    updateParamMapInfoShowPocMarker ({ commit }, payload) {
      return commit('setParamMapInfoShowPocMarker', payload)
    },
    updateParamMapInfoShowDog ({ commit }, payload) {
      return commit('setParamMapInfoShowDog', payload)
    },
    updateParamMapInfoShowToilet ({ commit }, payload) {
      return commit('setParamMapInfoShowToilet', payload)
    },
    updateParamMapInfoShowGreyBox ({ commit }, payload) {
      return commit('setParamMapInfoShowGreyBox', payload)
    },
    updateParamMapInfoShowRedBox ({ commit }, payload) {
      return commit('setParamMapInfoShowRedBox', payload)
    },
    updateParamMapInfoShowParcelLocker ({ commit }, payload) {
      return commit('setParamMapInfoShowParcelLocker', payload)
    },
    updateRouteInfoSttpGroupBy ({ commit }, payload) {
      const prom = []
      prom.push(this.dispatch('userLoggedModule/updateSelectionField', {
        cp: null,
        street: null,
        code: null,
        color: null
      }))
      prom.push(commit('setRouteInfoSttpGroupBy', payload))
      return Promise.all(prom)
    },
    updateRouteInfoFfrsGroupBy ({ commit }, payload) {
      const prom = []
      prom.push(this.dispatch('userLoggedModule/updateSelectionField', {
        cp: null,
        street: null
      }))
      prom.push(commit('setRouteInfoFfrsGroupBy', payload))
      return Promise.all(prom)
    }
  }
}

export default userLoggedModule
