const officeItemMultiModule = {
  firestorePath: 'officeData/{officeId}/officeItemMulti',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'officeItemMultiModule',
  statePropName: 'data',
  namespaced: true, // automatically added

  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    getItemMulti: (state) => {
      return Object.values(state.data)
    },
    getBpCom: (state) => {
      return Object.values(state.data).filter(x => x.type === 'cmb')
    },
    getParking: (state) => {
      return Object.values(state.data).filter(x => x.type === 'parking')
    },
    getBpComMarker: (state, getters, rootState, rootGetters) => {
      let pos = 0
      return getters.getBpCom.map(x => {
        x.isOpenInfoWindow = true
        x.pos = pos++
        x.position = x.loc
        x.label = x.siteId
        x.marker = rootGetters.getGoogleMarker(x)
        // x.circle = rootGetters.getGoogleCircle(x.position, x.group, 20)
        return x
      })
    },
    getBpComMarkerById: (state, getters) => (id) => {
      return getters.getBpComMarker.filter(x => x.id === id)[0]
    },
    getParkingMarker: (state, getters, rootState, rootGetters) => {
      let pos = 0
      return getters.getParking.map(x => {
        x.isOpenInfoWindow = true
        x.pos = pos++
        x.marker = rootGetters.getGoogleMarker(x)
        // x.circle = rootGetters.getGoogleCircle(x.position, x.group, 20)
        return x
      })
    }
  },
  mutations: {
    // setMultiPocItemImageList (state, payload) {
    //   // payload = { itemId: , multiId: , imageList: , successMess: }
    //   const mapItems = state.data[payload.multiId].mapItems
    //   var self = this
    //   const index = mapItems.map(function (x) { return x.id }).indexOf(payload.itemId)
    //   if (index > -1) {
    //     mapItems[index].imageList = payload.imageList
    //     this.dispatch('officeItemMultiModule/set', { id: payload.multiId, mapItems: mapItems })
    //       .then(function () {
    //         self.dispatch('showMessage', payload.successMess)
    //       }).catch(function (error) {
    //         self.dispatch('showAlert', error.message)
    //       })
    //   }
    // },
    setMultiPocItem (state, payload) {
      const item = {
        loc: payload.pos,
        name: payload.name,
        desc: payload.desc,
        type: payload.type,
        imageList: payload.imageList,
        pocIdList: payload.pocIdList,
        isActive: payload.isActive,
        route: payload.route
      }
      if (payload.udl) { item.udl = payload.udl }
      if (payload.siteId) { item.siteId = payload.siteId }
      if (payload.siteConfiguration) { item.siteConfiguration = payload.siteConfiguration }
      if (payload.miniPark) { item.miniPark = payload.miniPark }

      if (payload.officeItemMultiId) {
        item.id = payload.officeItemMultiId
        const oldItem = state.data[payload.officeItemMultiId]
        if (item.pocIdList !== oldItem.pocIdList) {
          oldItem.pocIdList.forEach(pocId => {
            if (item.pocIdList.indexOf(pocId) < 0) {
              this.dispatch('pocModule/removeMultiItemRef', { type: item.type, pocId: pocId, multiId: payload.officeItemMultiId })
            }
          })
        }
        this.dispatch('logModule/set', { type: 'updateMultiPocItem', multiId: payload.officeItemMultiId })
      }
      return this.dispatch('officeItemMultiModule/set', item)
        .then((multiId) => {
          item.pocIdList.forEach(pocId => {
            this.dispatch('pocModule/addMultiItemRef', { type: item.type, isPrimary: !payload.id, pocId: pocId, multiId: multiId })
          })
          return multiId
        })
    },
    updateMultiItem (state, payload) {
      const prom = []
      const item = state.data[payload.multiId]
      // // console.log('trace multi update')
      // // console.log(payload)
      // // console.log(item)
      const trace = {}
      if (payload.pos !== undefined) { item.loc = payload.pos; trace.loc = payload.pos }
      if (payload.name !== undefined) { item.name = payload.name; trace.name = payload.name }
      if (payload.type !== undefined) { item.type = payload.type; trace.type = payload.type }
      if (payload.desc !== undefined) { item.desc = payload.desc; trace.desc = payload.desc }
      if (payload.isActive !== undefined) { item.isActive = payload.isActive; trace.isActive = payload.isActive }

      if (payload.udl !== undefined) { item.udl = payload.udl; trace.udl = payload.udl }
      if (payload.siteId !== undefined) { item.siteId = payload.siteId; trace.siteId = payload.siteId }
      if (payload.siteConfiguration !== undefined) { item.siteConfiguration = payload.siteConfiguration; trace.siteConfiguration = payload.siteConfiguration }
      if (payload.miniPark !== undefined) { item.miniPark = payload.miniPark; trace.miniPark = payload.miniPark }
      if (payload.proximPocId !== undefined) { item.proximPocId = payload.proximPocId }
      if (payload.fileName !== undefined) { item.fileName = payload.fileName }

      if (payload.pocIdList !== undefined) {
        payload.pocIdList.forEach(pocId => {
          // pocId to add
          if (item.pocIdList.indexOf(pocId) < 0) {
            if (payload.isPrimaryItem) {
              prom.push(this.dispatch('pocModule/updatePocItem', {
                pocId: pocId,
                itemId: 0,
                officeItemMultiId: payload.multiId
              }))
            } else {
              prom.push(this.dispatch('pocModule/createPocItem', {
                pocId: pocId,
                type: item.type,
                officeItemMultiId: payload.multiId
              }))
            }
          }
        })
        item.pocIdList.forEach(pocId => {
          // pocId to remove
          if (item.pocIdList.indexOf(pocId) < 0) {
            if (payload.isPrimaryItem) {
              prom.push(this.dispatch('pocModule/updatePocItem', {
                pocId: pocId,
                itemId: 0,
                officeItemMultiId: null
              }))
            } else {
              prom.push(this.dispatch('pocModule/removePocItemMultiRef', {
                pocId: pocId,
                officeItemMultiId: payload.multiId
              }))
            }
          }
        })
        item.pocIdList = payload.pocIdList
        trace.pocIdList = payload.pocIdList
      }
      state.data[payload.multiId] = item
      if (item.id === undefined) { item.id = payload.multiId }
      prom.push(this.dispatch('officeItemMultiModule/set', item))
      prom.push(this.dispatch('logModule/insert', { type: 'updateMultiItem', multiId: payload.multiId, trace: trace }))
      return Promise.all(prom)
    }
  },
  actions: {
    createNewMultiItem ({ commit }, payload) {
      return commit('createNewMultiItem', payload)
    },
    updateMultiItem ({ commit }, payload) {
      return commit('updateMultiItem', payload)
    },
    savePocItem ({ commit, state, getters, rootState, rootGetters }, payload) {
      payload.userId = rootGetters.getCurrentUserId
      if (rootGetters.isSoloPocMapItemType(payload.type)) {
        return this.dispatch('pocModule/savePocItem', payload)
      } else {
        payload.route = []
        payload.pocIdList.forEach(pocId => {
          const routeArray = rootState.pocModule.data[pocId].route
          routeArray.forEach(routeId => {
            if (payload.route.indexOf(routeId) < 0) {
              payload.route.push(routeId)
            }
          })
        })
        return commit('setMultiPocItem', payload)
      }
    }
  }
}
export default officeItemMultiModule
