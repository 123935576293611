<template>
  <div ref="home" class="home" :style="contentstyles.styles">
    <div ref="userMaps" class="App" id="userMaps" :style="mapstyles"/>

  </div>
</template>

<script>
import PMTmapsInit, { getusergoogle, showtoiletmarker } from '../../utils/PMTmaps'
import Vue from 'vue'
const EventBus = new Vue()

export default {
  props: ['contentstyles'],
  computed: {
    mapstyles () {
      if (this.showinfobox) {
        return 'width:' + this.contentstyles.width + 'px; height:' + (this.contentstyles.height - 460) + 'px'
      } else {
        return 'width:' + this.contentstyles.width + 'px; height:' + (this.contentstyles.height - 160) + 'px'
      }
    }
  },
  methods: {
    changetabinfobox (newValue) {
      if (newValue === 'view') {
        if (!this.streetviewobj) {
          this.streetviewobj = new this.usergoogle.maps.StreetViewPanorama(
            document.getElementById('view'), {
              position: this.selectedpdr.homeposition,
              // position:this.selectedpdr.sv.home.postion,
              // pov:this.selectedpdr.sv.home.pov,
              pov: { heading: 34, pitch: 10 },
              addressControl: false,
              // addressControlOptions: {
              //    position: google.maps.ControlPosition.BOTTOM_CENTER
              // },
              linksControl: false,
              panControl: false,
              enableCloseButton: false
            })
        } else {
          this.streetviewobj.setPosition(this.selectedpdr.homeposition)
        }
      }
      if (newValue === 'option') {
        // this.searchgeocoderresult.push(this.getAddressgeocode())

      }
      if (newValue === 'info') {

      }
    },
    switchguardianmode () {
      this.guardianmode = !this.guardianmode
    },
    dogsetup () {

      // showtoiletmarker(this.showtoilet);
    },

    setlistener: function () {
      EventBus.$on('mapclick', () => {
        if (this.showinfobox) {
          this.showinfobox = false
          //         if(this.usermap){this.usermap.setStreetView(null)}
        } else {

        }
      })
      EventBus.$on('markerclick', (pdrclick) => {
        if (this.selectedpdr !== pdrclick) {
          this.selectedpdr = pdrclick
          if (this.streetviewobj) {
            this.streetviewobj.setPosition(this.selectedpdr.homeposition)
          }
          if (!this.showinfobox) { this.showinfobox = true };
        } else {
          this.showinfobox = !this.showinfobox
        }
      })
      this.usermap.addListener('click', function () { EventBus.$emit('mapclick') })

      document.addEventListener('selectpdr', function (e) {
        // this.selectedpdr = e.detail;
        EventBus.$emit('markerclick', (e.detail))
      })
    }

  },
  watch: {
    selectedpdr (newValue) {
      if (newValue) {
        this.disabledpdrdetail = false
      } else {
        this.disabledpdrdetail = true
      }
    },
    showtoilet (newValue) {
      showtoiletmarker(newValue)
    }
  },
  data () {
    return {
      guardianmode: false,
      dialog: false,
      searchgeocoderresult: [],
      disabledpdrdetail: true,
      showinfobox: false,
      selectinfobox: 1,
      dispoheight: 0,
      mapheigth: '84%',
      showtoilet: false,
      showdog: true,
      grouppdrby: '1/3',
      shipmode: false,
      alertmode: false,
      selectedpdr: '',
      streetviewobj: '',
      usermap: null,
      usergoogle: null,
      streetviewchange: false,
      mapData:
          [
            {
              regionName: 'Quebec Metro',
              zone: [
                {
                  zoneName: 'Sud',
                  office: [
                    {
                      officeName: 'Levis',
                      road: [
                        {
                          roadName: '49',
                          id: 1,
                          pdr: [
                            { door: '39', dog: 0, street: 'Mgr Bourget', homeposition: { lat: 46.823193, lng: -71.162751 }, tier: 'pink', type: 'App', app: [{ name: '1' }, { name: '2' }, { name: '3' }, { name: '4' }], pair: false },
                            { door: '35', dog: 0, street: 'Mgr Bourget', homeposition: { lat: 46.823638, lng: -71.162917 }, tier: 'pink', type: 'App', app: [{ name: '1' }, { name: '2' }, { name: '3' }, { name: '4' }, { name: '5' }, { name: '6' }, { name: '7' }, { name: '8' }, { name: '9' }, { name: '10' }], pair: false },
                            { door: '33', dog: 0, street: 'Mgr Bourget', homeposition: { lat: 46.823505, lng: -71.163222 }, tier: 'pink', type: 'Dom', pair: false },
                            { door: '12', dog: 0, street: 'Mercier', homeposition: { lat: 46.825012, lng: -71.170245 }, tier: 'pink', type: 'Dom', pair: true },
                            { door: '7790', dog: 0, street: 'Francois-Bissot', homeposition: { lat: 46.826571, lng: -71.158279 }, tier: 'orange', type: 'Dom', pair: true },
                            { door: '7788', dog: 0, street: 'Francois-Bissot', homeposition: { lat: 46.826544, lng: -71.158274 }, tier: 'orange', type: 'Dom', pair: true },
                            { door: '7784', dog: 0, street: 'Francois-Bissot', homeposition: { lat: 46.826403, lng: -71.158534 }, tier: 'orange', type: 'Dom', pair: true },
                            { door: '35', dog: 0, street: 'Joliet', homeposition: { lat: 46.826977, lng: -71.168935 }, tier: 'orange', type: 'Dom', pair: false },
                            { door: '34A', dog: 0, street: 'Bourassa', homeposition: { lat: 46.828372, lng: -71.161988 }, tier: 'orange', type: 'Dom', pair: true },
                            { door: '43', dog: 0, street: 'St-Gilbert', homeposition: { lat: 46.824409, lng: -71.174724 }, tier: 'blue', type: 'Dom', pair: false },
                            { door: '37', dog: 2, street: 'St-Gilbert', homeposition: { lat: 46.823992, lng: -71.175043 }, tier: 'blue', type: 'Dom', pair: false },
                            { door: '33', dog: 0, street: 'St-Gilbert', homeposition: { lat: 46.823581, lng: -71.175515 }, tier: 'blue', type: 'Dom', pair: false },
                            { door: '2A', dog: 0, street: 'Couillard', homeposition: { lat: 46.825073, lng: -71.167065 }, tier: 'blue', type: 'Dom', pair: false },
                            { door: '245', picture: '../assets/IMG_1376.jpg', dog: 0, street: 'St-Joseph', homeposition: { lat: 46.824868, lng: -71.167091 }, tier: 'pink', type: 'Dom', pair: false }
                          ]
                        }

                      ],
                      toilet: [
                        { toiletposition: { lat: 46.826485, lng: -71.170103 }, desc: 'Toilet du bloc accessible avec la clef du buzzer' },
                        { toiletposition: { lat: 46.824583, lng: -71.167702 }, desc: 'Toilet du bloc accessible avec la clef du buzzer' }

                      ]
                    }
                  ]
                }
              ]
            }
          ],
      mapOption: { startzoom: 14, startCenter: { lat: 46.825713, lng: -71.166643 } }

    }
  },
  async mounted () {
    try {
      this.usermap = await PMTmapsInit(this.mapData, this.mapOption, this.$el)
      this.usergoogle = getusergoogle()
      this.setlistener()
      showtoiletmarker(this.showtoilet)
    } catch (error) {
    }
  }
}
</script>
