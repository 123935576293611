<template>
  <!-- <v-dialog
    v-model="showPicture"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    :style="$store.getters.screenSize"
  > -->
  <v-overlay z-index="3000" :value="showPicture" opacity="0.93" dark @click="showPicture = false">
      <v-btn
        color="primary"
        dark
        fixed
        top
        right
        fab
        @click="showPicture = false"
      >
        <v-icon x-large>mdi-close</v-icon>
      </v-btn>
    <v-zoomer-gallery
      style="width: 100vw; height: 100vh;"
      :list="imageList"
      v-model="imageSelectedIndex"
    >
    </v-zoomer-gallery>
    <v-btn
      v-show="imageList.length > 1 && imageSelectedIndex > 0"
      color="primary"
      dark
      fixed
      bottom
      left
      fab
      @click="imageSelectedIndex--"
    >
      <v-icon x-large>mdi-chevron-left</v-icon>
    </v-btn>
    <v-btn
      v-show="imageList.length > 1 && imageSelectedIndex < (imageList.length - 1)"
      color="primary"
      dark
      fixed
      bottom
      right
      fab
      @click="imageSelectedIndex++"
    >
      <v-icon x-large>mdi-chevron-right</v-icon>
    </v-btn>
    <!-- <v-img contain v-if="picUrl" :width="$store.getters.contentWidth" @click="showPicture = false" max-width="100%" :src="picUrl"></v-img>

    <v-card
      justify-center
      align-center
      v-if="imageList"
      :width='$store.getters.getContentWidth'
      :max-width="$store.getters.getContentWidth"
      transparent
    >
      <v-row no-gutters><v-spacer></v-spacer><v-btn icon dark x-large @click="showPicture = false"><v-icon x-large>mdi-close</v-icon></v-btn></v-row>
      <v-carousel
        v-if="imageList"
        v-model="imageSelectedIndex"
        :height="carouselHeight"
        :max-height="carouselHeight"
      >
        <v-carousel-item
          v-for="(image, i) of imageList"
          :key="i"

        >
          <v-img contain :width="$store.getters.contentWidth" max-width="100%" :src="image.url"></v-img>
        </v-carousel-item>
      </v-carousel>
    </v-card> -->
  </v-overlay>
  <!-- </v-dialog> -->
</template>

<script>
export default {
  data () {
    return {
      picUrl: null,
      imageList: [],
      imageSelectedIndex: 0,
      showPicture: false
    }
  },
  methods: {},
  mounted () {
    this.$store.getters.EventBus.$on('ShowPictureFullScreen', (url) => {
      // this.picUrl = url
      this.imageList = [url]
      this.showPicture = true
    })
    this.$store.getters.EventBus.$on('ShowImageListFullScreen', (imageList) => {
      // this.picUrl = null
      for (let i = 0; i < imageList.length; i++) {
        if (imageList[i].isPrimary) { this.imageSelectedIndex = i }
      }
      this.imageList = imageList.map(x => { return x.url })
      this.showPicture = true
    })
  },
  computed: {
    carouselHeight () {
      const calcHeidth = Math.abs((this.$store.getters.contentWidth / 3) * 2)
      if (calcHeidth > 500) {
        return 500
      } else {
        return calcHeidth
      }
    }
  }
}
</script>
