import colors from 'vuetify/lib/util/colors'

const themesPlugin = store => {
  const themes = {
    blue: {
      name: 'blue',
      light: {
        primary: colors.blue.darken2,
        secondary: colors.shades.black,
        accent: colors.amber.accent3,
        error: colors.red.accent2,
        info: colors.blue.base,
        success: colors.green.base,
        warning: colors.amber.base,
        gradient: 'to top right, rgba(25,118,210,.5), rgba(2,30,99,.8)'
      },
      dark: {
        primary: colors.blue.darken2,
        secondary: colors.shades.black,
        accent: colors.amber.accent3,
        error: colors.red.accent2,
        info: colors.blue.base,
        success: colors.green.base,
        warning: colors.amber.base,
        gradient: 'to top right, rgba(25,118,210,.5), rgba(2,30,99,.8)'
      }
    },
    orange: {
      name: 'orange',
      light: {
        primary: colors.orange.base,
        secondary: colors.shades.black,
        accent: colors.amber.accent3,
        error: colors.red.accent2,
        info: colors.blue.base,
        success: colors.green.base,
        warning: colors.amber.base,
        gradient: 'to top right, rgba(255,152,0,.5), rgba(204,119,0,.8)'
      },
      dark: {
        primary: colors.orange.base,
        secondary: colors.shades.black,
        accent: colors.amber.accent3,
        error: colors.red.accent2,
        info: colors.blue.base,
        success: colors.green.base,
        warning: colors.amber.base,
        gradient: 'to top right, rgba(255,152,0,.5), rgba(204,119,0,.8)'
      }
    },
    pink: {
      name: 'pink',
      light: {
        primary: colors.pink.base,
        secondary: colors.shades.black,
        accent: colors.amber.accent3,
        error: colors.red.accent2,
        info: colors.blue.base,
        success: colors.green.base,
        warning: colors.amber.base,
        gradient: 'to top right, rgba(233,30,99,.5), rgba(241,64,123,.8)'
      },
      dark: {
        primary: colors.pink.base,
        secondary: colors.shades.black,
        accent: colors.amber.accent3,
        error: colors.red.accent2,
        info: colors.blue.base,
        success: colors.green.base,
        warning: colors.amber.base,
        gradient: 'to top right, rgba(233,30,99,.5), rgba(241,64,123,.8)'
      }
    },
    noColor: {
      name: 'noColor',
      light: {
        primary: colors.grey.darken3,
        secondary: colors.shades.black,
        accent: colors.amber.accent3,
        error: colors.red.accent2,
        info: colors.blue.base,
        success: colors.green.base,
        warning: colors.amber.base,
        gradient: 'to top right, rgba(66,66,66,.5), rgba(25,25,25,.8)'
      },
      dark: {
        primary: colors.grey.darken3,
        secondary: colors.shades.black,
        accent: colors.amber.accent3,
        error: colors.red.accent2,
        info: colors.blue.base,
        success: colors.green.base,
        warning: colors.amber.base,
        gradient: 'to top right, rgba(66,66,66,.5), rgba(25,25,25,.8)'
      }
    }
  }
  store.getThemesByName = function (name) {
    if (Object.keys(themes).indexOf(name) > -1) {
      return themes[name]
    } else {
      return themes.noColor
    }
  }
  store.getThemesByColorId = function (colorId) {
    switch (String(colorId)) {
      case '0':
        return themes.noColor
      case '1':
        return themes.orange
      case '2':
        return themes.pink
      case '3':
        return themes.blue
      default:
        return themes.noColor
    }
  }
  store.getGradientByColorId = function (colorId, isDarkThemes) {
    switch (String(colorId)) {
      case '0':
        if (isDarkThemes) {
          return themes.noColor.dark.gradient
        } else {
          return themes.noColor.light.gradient
        }
      case '1':
        if (isDarkThemes) {
          return themes.orange.dark.gradient
        } else {
          return themes.orange.light.gradient
        }
      case '2':
        if (isDarkThemes) {
          return themes.pink.dark.gradient
        } else {
          return themes.pink.light.gradient
        }
      case '3':
        if (isDarkThemes) {
          return themes.blue.dark.gradient
        } else {
          return themes.blue.light.gradient
        }
      default:
        if (isDarkThemes) {
          return themes.noColor.dark.gradient
        } else {
          return themes.noColor.light.gradient
        }
    }
  }
  store.getColorText = function (code) {
    switch (code) {
      case 1:
        return 'orange--text'
      case '1':
        return 'orange--text'
      case 2:
        return 'pink--text'
      case '2':
        return 'pink--text'
      case 3:
        return 'blue--text text--darken-2'
      case '3':
        return 'blue--text text--darken-2'
      default:
        return 'black--text'
    }
  }
  store.getColorName = function (code) {
    switch (code) {
      case 1:
        return 'orange'
      case '1':
        return 'orange'
      case 2:
        return 'pink'
      case '2':
        return 'pink'
      case 3:
        return 'blue'
      case '3':
        return 'blue'
      default:
        return 'grey'
    }
  }
  store.getColorCode = function (code) {
    switch (code) {
      case 0:
        return themes.noColor.light.primary
      case 1:
        return themes.orange.light.primary
      case '1':
        return themes.orange.light.primary
      case 2:
        return themes.pink.light.primary
      case '2':
        return themes.pink.light.primary
      case 3:
        return themes.blue.light.primary
      case '3':
        return themes.blue.light.primary
      default:
        return themes.noColor.light.primary
    }
  }
}

export default themesPlugin
