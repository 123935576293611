<template>
  <div id="app">
    <v-card class="overflow-hidden overflow-x-hidden" :style="$store.getters.contentStyle">
      <v-card height="90" class="grey lighten-2 pb-5">
        <v-card-title>
          <v-row no-gutters>
            <v-btn icon x-large @click="$store.getters.EventBus.$emit('selectLocalAdminComponent', ('pmt-menu-admin-local'))"><v-icon x-large>mdi-arrow-left-circle</v-icon></v-btn>
            <h3 class="mt-3">Menu</h3>
          </v-row>
        </v-card-title>
      </v-card>
      <v-sheet class="overflow-y-auto overflow-x-hidden" :height="$store.getters.contentHeight - 90" :max-height="$store.getters.contentHeight - 90">
        <input id='input' v-show="false" type="file" ref="inputUpload" accept=".csv" @change="processCsvFiles()"/>
        <v-card-title>{{ $t('import-poclist.import') }}</v-card-title>
        <v-card-subtitle>{{ $t('import-harmonize.title') }}</v-card-subtitle>

        <!-- <v-progress-circular indeterminate :width="7" color="primary" :size="50"></v-progress-circular> -->
        <v-container v-if="!file" class="justify-center mt-12">
          <v-row><v-spacer></v-spacer><v-btn dark icon size="70" color="primary" @click="$refs.inputUpload.click()"><v-icon size="70">mdi-upload</v-icon></v-btn><v-spacer></v-spacer></v-row>
          <v-row centered class="mt-3"><h2 class="mx-auto">{{ $t('import-poclist.open') }}</h2></v-row>
        </v-container>
        <v-card v-if="file" id="filedetail" class="mx-auto mb-3 pt-4" centered max-width="444">
          <v-btn @click="file = null" class="float-right" x-large icon><v-icon x-large color="red">mdi-delete</v-icon></v-btn>
          <h4 class="ms-1">{{ $t('import-poclist.file') }} : </h4>
          <h5 class="ms-2">{{ fileName }}</h5>

          <h4 class="ms-1 mt-2">{{ $t('import-poclist.size') }} : </h4>
          <h5 class="ms-2">{{ Math.round(((file.size / 1024) / 1024) * 10) / 10 }} Mo</h5>
          <h4 class="ms-1 mt-2">{{ $t('import-poclist.modified') }} : </h4>
          <h5 class="ms-2">{{ new Date(file.lastModified).toLocaleString('fr-CA',) }}</h5>
          <h4 class="ms-1 mt-2">Detail : </h4>

          <v-container v-if="analyseComplete" id="fileStat">
            <v-card class="mb-3">
                <v-card-title>{{ importData.length + ' ' + $t('common.pdrlong') }} </v-card-title>
            </v-card>
            <!-- <th v-for="item in codeMarkerStat" :key="item.name" class="text-center white--text subtitle-2">{{ item.name }}</th> -->
          </v-container>
          <div v-if="validFile" class="mt-5">
            <v-row justify="center" ><v-btn icon size="70" @click="importToDatabase" color="green"><v-icon size="70">mdi-database-import</v-icon></v-btn></v-row>
            <v-row centered><h2 class="mx-auto mt-3 pb-10">{{ $t('import-poclist.importdata') }}</h2></v-row>
          </div>
        </v-card>
      </v-sheet>
    </v-card>
    <!-- <pmt-loading-progress-local :loadingShow="showProgress" :progressValue="progressValue" :loadingTitle="progressTitle"></pmt-loading-progress-local> -->
  </div>
</template>

<script>
export default {
  name: 'App',
  props: ['title'],
  data () {
    return {
      file: null,
      fileName: '',
      pocListFiles: null,
      loadComplete: false,
      analyseComplete: false,
      validFile: false,
      showapp: false,
      showdoor: false,
      importData: [],
      showProgress: false,
      progressValue: 0,
      progressTitle: ''
    }
  },
  watch: {
    file (newValue) {
      if (!newValue) {
        this.loadComplete = false
        this.analyseComplete = false
        this.validFile = false
        this.importData = []
      }
    }
  },
  methods: {
    async importToDatabase () {
      var importArray = []
      var cpt = 0
      // var cptTotal = 0
      for (let i = 0; i < this.importData.length; i++) {
        importArray.push(this.importData[i])
        cpt++
        if (cpt === 500) {
          // cptTotal++
          await this.$store.dispatch('harmonizeModule/insertBatch', (importArray))
            .then(() => {
              return null
            })
          importArray = []
          cpt = 0
        }
      }
      if (importArray.length) {
        // cptTotal++
        await this.$store.dispatch('harmonizeModule/insertBatch', (importArray))
          .then(() => {
            return null
          })
      }
    },
    // async importToDatabase_back () {
    //   // var cptNewPoc = 0
    //   // var cptUpdatePoc = 0
    //   const insertBatch = []
    //   for (let x = 0; x < this.importData.length; x++) {
    //     const routeImport = this.importData[x]
    //     for (let i = 0; i < routeImport.poc.length; i++) {
    //       const poc = routeImport.poc[i]
    //       const existId = this.$store.getters['pdrModule/pdrExist'](poc.pdrListAddress)
    //       if (existId) {
    //         poc.id = existId
    //         // cptUpdatePoc++
    //         this.$store.dispatch('pdrModule/set', poc)
    //       } else {
    //         // cptNewPoc++
    //         insertBatch.push(poc)
    //       }
    //     }
    //   }
    //   if (insertBatch.length) {
    //     const resultId = await this.$store.dispatch('pdrModule/insertBatch', insertBatch)
    //   }
    // },
    processCsvFiles () {
      this.file = this.$refs.inputUpload.files[0]
      this.fileName = this.file.name
      this.loadCommplete = true
      this.analyseComplete = false
      this.importData = []
      this.analyseCsvFile()
    },
    analyseCsvFile () {
      if (this.file) {
        this.progressValue = 0
        this.progressTitle = this.$t('common.analysing')
        this.showProgress = true
        const EventBus = this.$store.getters.EventBus
        EventBus.$on('harmonyzeproccessprogress', (value) => {
          this.progressValue = value
        })
        EventBus.$on('harmonyzecsvproccesscompleted', (result) => {
          this.showProgress = false
          this.importData = result
          // this.importData = {
          //   EventBus: EventBus,
          //   officeId: this.$store.getters.getSelectedOfficeId,
          //   harmonize: {
          //     data: result,
          //     import_by: this.$store.getters.userinfokey,
          //     import_at: new Date(),
          //     fileMoficication_at: new Date(this.file.lastModified)
          //   }
          // }
          this.analyseComplete = true
          this.verifDataFile()
        })
        if (window.FileReader) {
          var reader = new FileReader()
          reader.readAsText(this.file)
          reader.onload = function (event) {
            // let progress; let oldprogress
            // let street; let lastcomplex = ''
            const ldata = []

            var csv = event.target.result.split('\n')
            // // onsole.log('csv = ' + csv.length)
            // // onsole.log('ligne = ' + csv[2])

            // // onsole.log('rue = ->'+test[0].substring(0,4)+'<-')
            // let cptligne = 0
            // const countligne = csv.length
            for (const ligne of csv) {
              // cptligne++
              const item = ligne.replace(/ {2}/g, ' ').replace(/\r/g, '').replace(/\n/g, '').split(';')
              if (item[1]) {
                if (String(Number(item[1])) === item[1]) {
                  var oldAddressString
                  var newAddressString

                  const oldAddress = {
                    civic: item[1].toUpperCase().replace(/ /g, ''),
                    suffix: item[2].toUpperCase().replace(/ /g, ''),
                    suit: item[3].toUpperCase().replace(/ /g, ''),
                    streetType: item[4].toUpperCase().trim(),
                    streetArt: item[5].toUpperCase().trim(),
                    streetShortName: item[6].toUpperCase().trim(),
                    streetDirection: item[7].toUpperCase().trim(),
                    street: item[8].toUpperCase().trim(),
                    city: item[9].toUpperCase().trim(),
                    postalCode: item[10].toUpperCase().trim()
                  }
                  if (oldAddress.suit && oldAddress.suffix) {
                    oldAddressString = oldAddress.suit + '-' + oldAddress.civic + oldAddress.suffix + ' ' + oldAddress.street
                  } else if (oldAddress.suit) {
                    oldAddressString = oldAddress.suit + '-' + oldAddress.civic + ' ' + oldAddress.street
                  } else if (oldAddress.suffix) {
                    oldAddressString = oldAddress.civic + oldAddress.suffix + ' ' + oldAddress.street
                  } else {
                    oldAddressString = oldAddress.civic + ' ' + oldAddress.street
                  }
                  const newAddress = {
                    civic: item[11].toUpperCase().replace(/ /g, ''),
                    suffix: item[12].toUpperCase().replace(/ /g, ''),
                    suit: item[13].toUpperCase().replace(/ /g, ''),
                    streetType: item[14].toUpperCase().trim(),
                    streetArt: item[15].toUpperCase().trim(),
                    streetShortName: item[16].toUpperCase().trim(),
                    streetDirection: item[17].toUpperCase().trim(),
                    street: item[18].toUpperCase().trim(),
                    city: item[19].toUpperCase().trim(),
                    postalCode: item[20].toUpperCase().trim()
                  }
                  if (newAddress.suit && newAddress.suffix) {
                    newAddressString = newAddress.suit + '-' + newAddress.civic + newAddress.suffix + ' ' + newAddress.street
                  } else if (newAddress.suit) {
                    newAddressString = newAddress.suit + '-' + newAddress.civic + ' ' + newAddress.street
                  } else if (newAddress.suffix) {
                    newAddressString = newAddress.civic + newAddress.suffix + ' ' + newAddress.street
                  } else {
                    newAddressString = newAddress.civic + ' ' + newAddress.street
                  }
                  ldata.push({ oldAddress: oldAddress, oldAddressString: oldAddressString.trim(), newAddress: newAddress, newAddressString: newAddressString.trim(), pocId: null })
                }
              }
              // const item = itembrut.replace(/"/g, '')

              // progress = Math.round((cptligne / countligne) * 100)
              // if (progress !== oldprogress) {
              //   oldprogress = progress
              //   EventBus.$emit('harmonyzeproccessprogress', (progress))
              // }
            }
            EventBus.$emit('harmonyzecsvproccesscompleted', (ldata))
          }
        }
      }
    },
    verifDataFile () {
      if (this.importData) {
        if (this.importData.length) {
          this.validFile = true
        } else {
          this.validFile = false
        }
      } else {
        this.validFile = false
      }
    }
  },
  // created () {
  //   this.$store.dispatch('harmonizeModule/openDBChannel', { pathVariables: { officeId: this.$store.state.userLoggedModule.data.officeselected }, where: [['id', '==', '12']] })
  //     .then(() => {
  //       // // console.log('harmonize loaded')
  //     })
  // },
  // destroyed () {
  //   this.dispatch('harmonizeModule/closeDBChannel', { clearModule: true })
  // },
  mounted () {
    this.$refs.inputUpload.click()
  }
}
</script>
