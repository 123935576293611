<template>
  <div class="home">
      <h4>{{ pocItem.name ? pocItem.name : $t('pocitem.' + pocItem.type )}}</h4>
      <p v-if="pocItem.name && showDetail">{{ $t('pocitem.' + pocItem.type) }}</p>
      <v-textarea v-if="pocItem.desc" class="mt-2" outlined filled rows="2" :label="$t('pocitem.desc')" readonly :value="pocItem.desc"></v-textarea>
  </div>
</template>

<script>
export default {
  props: ['pocItem', 'showDetail'],
  methods: {},
  data () {
    return {
    }
  }
}
</script>
