import firebase from 'firebase/app'
import 'firebase/storage'
import { arrayUnion } from 'vuex-easy-firestore'

const pocModule = {
  firestorePath: 'officeData/{officeId}/poc',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'pocModule',
  statePropName: 'data',
  namespaced: true, // automatically added

  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {
    imgUrl: []
  },
  getters: {
    getPoc: (state) => (pocId) => {
      return state.data[pocId]
    },
    getSeqInfoOfPoc: (state) => (routeId, seqId, poc) => {
      if (poc.routeSequences) {
        const routeIndex = poc.routeSequences.map(function (x) { return x.routeId }).indexOf(routeId)
        if (routeIndex < 0) {
          return null
        } else {
          if (seqId) {
            const sequenceIndex = poc.routeSequences[routeIndex].sequences.map(function (x) { return x.sequenceId.toString() }).indexOf(seqId)
            if (sequenceIndex < 0) {
              return null
            } else {
              return poc.routeSequences[routeIndex].sequences[sequenceIndex]
            }
          } else {
            return null
          }
        }
      } else {
        return null
      }
    },
    getSeqInfoOfPocId: (state, getters) => (routeId, seqId, pocId) => {
      if (state.data[pocId]) {
        return getters.getSeqInfoOfPoc(routeId, state.data[pocId], seqId)
      } else {
        return null
      }
    },
    getOfficialSeqInfoOfPoc: (state, getters, rootState, rootGetters) => (routeId, poc) => {
      const seqId = rootGetters['routeModule/getOfficialSeqIdOfRouteId'](routeId)
      if (seqId) {
        return getters.getSeqInfoOfPoc(routeId, seqId, poc)
      } else {
        return null
      }
    },
    getOfficialSeqInfoOfPocId: (state, getters) => (routeId, pocId) => {
      return getters.getOfficialSeqInfoOfPoc(routeId, state.data[pocId])
    },
    getOfficialColorIdOfRoute: (state, getters, rootState, rootGetters) => (routeId, pocId) => {
      const seqInfo = getters.getOfficialSeqInfoOfPocId(routeId, pocId)
      if (seqInfo) {
        return seqInfo.tier
      } else {
        return 1
      }
    },
    getPlacePocArray: (state, getters) => (routeId, sequenceId, placeId) => {
      return getters.getPocOfSequence(routeId, sequenceId).filter(x => x.placeId === placeId)
    },
    getCodePocArray: (state, getters) => (routeId, sequenceId, codeId) => {
      return getters.getPocOfSequence(routeId, sequenceId).filter(x => x.codeId === codeId)
    },
    getCodePocIdArray: (state, getters) => (routeId, sequenceId, codeId) => {
      return getters.getCodePocArray(routeId, sequenceId, codeId).map(function (x) { return x.id })
    },
    getColorPocArray: (state, getters) => (routeId, sequenceId, colorId) => {
      return getters.getPocOfSequence(routeId, sequenceId).filter(x => x.color === colorId)
    },
    getColorPocIdArray: (state, getters) => (routeId, sequenceId, colorId) => {
      return getters.getColorPocArray(routeId, sequenceId, colorId).map(function (x) { return x.id })
    },
    getCodeStat: (state, getters, rootState, rootGetters) => (routeId, sequenceId, codeId) => {
      return rootGetters.getCumulStatFromPocArray(getters.getCodePocArray(routeId, sequenceId, codeId))
    },
    getColorStat: (state, getters, rootState, rootGetters) => (routeId, sequenceId, colorId) => {
      return rootGetters.getCumulStatFromPocArray(getters.getColorPocArray(routeId, sequenceId, colorId))
    },
    getRouteStat: (state, getters, rootState, rootGetters) => (routeId) => {
      return rootGetters.getCumulStatFromPocArray(getters.getPocOfRoute(routeId))
    },
    getSequenceStat: (state, getters, rootState, rootGetters) => (routeId, sequenceId) => {
      return rootGetters.getCumulStatFromPocArray(getters.getPocOfSequence(routeId, sequenceId))
    },
    getDisctinctColorOfSequence: (state, getters, rootGetters) => (routeId, sequenceId) => {
      const array = getters.getPocOfSequence(routeId, sequenceId)
      var unique = []
      var distinct = []
      for (let i = 0; i < array.length; i++) {
        if (!unique[array[i].color]) {
          distinct.push({ colorId: array[i].color })
          unique[array[i].color] = 1
        }
      }
      return distinct
    },
    getDisctinctCodeOfSequence: (state, getters, rootGetters) => (routeId, sequenceId) => {
      const array = getters.getPocOfSequence(routeId, sequenceId)
      var unique = []
      var distinct = []
      for (let i = 0; i < array.length; i++) {
        if (!unique[String(array[i].codeId)]) {
          distinct.push({ codeId: String(array[i].codeId), codeName: array[i].codeName, colorId: array[i].colorId })
          unique[String(array[i].codeId)] = 1
        }
      }
      return distinct
    },
    getDisctinctCodeOfColorOfSequence: (state, getters, rootGetters) => (routeId, sequenceId, colorId) => {
      const array = getters.getColorPocArray(routeId, sequenceId, colorId)
      var unique = []
      var distinct = []
      for (let i = 0; i < array.length; i++) {
        if (!unique[String(array[i].codeId)]) {
          distinct.push({ codeId: String(array[i].codeId), codeName: array[i].codeName, colorId: array[i].colorId })
          unique[String(array[i].codeId)] = 1
        }
      }
      return distinct
    },
    getPocDetailMarkerData_back: (state, getters, rootState, rootGetters) => (routeId, sequenceId, pocId, itemtitle) => {
      return getters.getPlaceDetailMarker(getters.getPocOfSequence(routeId, sequenceId).filter(poc => poc.id === pocId), itemtitle, routeId, sequenceId)
    },
    getPlaceDetailMarkerData_back: (state, getters, rootState, rootGetters) => (routeId, sequenceId, placeId, itemtitle) => {
      return getters.getPlaceDetailMarker(getters.getPocOfSequence(routeId, sequenceId).filter(poc => poc.placeId === placeId), itemtitle, routeId, sequenceId)
    },
    getPlaceMarker: (state, getters, rootState, rootGetters) => (routeId, sequenceId, placeId, colorId) => {
      let colorName
      let topName
      let codeId
      let allVacant = true
      let allNoPub = true
      if (colorId === 1) { colorName = 'orange' } else if (colorId === 2) { colorName = 'pink' } else { colorName = 'blue' }
      getters.getPlacePocArray(routeId, sequenceId, placeId).forEach(poc => {
        if (!codeId) { codeId = poc.codeId }
        if (poc.pub) { allNoPub = false }
        if (!poc.vacant) { allVacant = false }
      })
      if (allVacant) {
        topName = 'green'
      } else if (allNoPub) {
        topName = 'red'
      } else {
        topName = 'black'
      }
      const place = rootState.placeModule.data[placeId]
      const marker = new rootGetters.getGoogle.maps.Marker({
        position: place.homeLocation,
        label: { text: String(place.sName), fontSize: rootGetters.getMarkerLabelSize(String(place.sName)), color: 'white' },
        map: null,
        icon: {
          url: require('@/assets/Icon/MapIcon/POC/App-' + colorName + '-t' + topName + '.png'),
          size: new rootGetters.getGoogle.maps.Size(48, 48),
          //      scaledSize: new google.maps.Size(40, 40), // scaled size
          origin: new rootGetters.getGoogle.maps.Point(0, 0), // origin
          anchor: new rootGetters.getGoogle.maps.Point(24, 28), // anchor
          labelOrigin: new rootGetters.getGoogle.maps.Point(24, 32)
        }
      })
      return {
        type: 'placeMarker',
        position: place.homeLocation,
        label: { text: String(place.sName), fontSize: rootGetters.getMarkerLabelSize(String(place.sName)), color: 'white' },
        url: require('@/assets/Icon/MapIcon/POC/App-' + colorName + '-t' + topName + '.png'),
        placeId: placeId,
        routeId: routeId,
        sequenceId: sequenceId,
        codeId: codeId,
        colorId: colorId,
        marker: marker
      }

      // marker.addListener('click', function () {
      //   rootGetters.EventBus.$emit('placeClick', (placeId))
      // })
      // return marker
    },
    getPocMarker: (state, getters, rootState, rootGetters) => (poc) => {
      // { pdrListAddress: '', pdrType: '', vacant: '', pub: '', color: '', placeIdDoor: '', homeLocation: ''  }
      var styleName
      var colorName
      var topName
      const doorTemp = poc.pdrListAddress.split(' ')[0]
      const door = doorTemp.split('-').length > 1 ? doorTemp.split('-')[1] : doorTemp
      const street = poc.pdrListAddress.replace(doorTemp, '').trim()
      switch (poc.pdrType) {
        case 'Hou/Dom':
          styleName = 'Hou'
          break
        case 'Apt/App':
          styleName = 'App'
          break
        case 'Bus/com':
          styleName = 'Bus'
          break
        default:
          styleName = 'Farm'
          break
      }
      if (poc.vacant) { topName = 'green' } else if (poc.pub) { topName = 'black' } else { topName = 'red' }
      if (poc.color === 1) { colorName = 'orange' } else if (poc.color === 2) { colorName = 'pink' } else { colorName = 'blue' }
      const marker = rootGetters.getGoogleMarker({
        type: 'pocMarker',
        label: door,
        seqPosTot: poc.seqPosTot,
        map: null,
        url: require('@/assets/Icon/MapIcon/POC/' + styleName + '-' + colorName + '-t' + topName + '.png')
      })
      marker.setPosition(poc.homeLocation)

      return {
        type: 'pocMarker',
        position: poc.homeLocation,
        pocId: poc.id,
        stepId: poc.stepId !== undefined ? poc.stepId : null,
        cp: poc.pdrListPostalCode,
        street: street,
        codeId: poc.codeId,
        codeName: poc.codeName,
        colorId: poc.colorId,
        seqPosTot: poc.seqPosTot,
        marker: marker
      }

      // marker.addListener('click', function () {
      //   rootGetters.EventBus.$emit('pocClick', (poc.id))
      // })
      // return marker
    },
    getCodeMarker: (state, getters, rootState, rootGetters) => (routeId, sequenceId, codeId, codeName, colorId) => {
      const google = rootGetters.getGoogle
      var url
      if (colorId === 1) {
        url = require('@/assets/Icon/MapIcon/orange.png')
      } else if (colorId === 2) {
        url = require('@/assets/Icon/MapIcon/pink.png')
      } else {
        url = require('@/assets/Icon/MapIcon/blue.png')
      }
      const marker = new google.maps.Marker({
        position: rootGetters.getPosCenterOfPocArray(getters.getCodePocArray(routeId, sequenceId, codeId)),
        label: { text: String(codeName), fontSize: rootGetters.getMarkerLabelSize(String(codeName)), color: 'white' },
        map: null,
        title: String(codeName),
        icon: {
          url: url,
          scaledSize: new google.maps.Size(60, 60), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(30, 60), // anchor
          labelOrigin: new google.maps.Point(30, 19)
        }
      })
      marker.addListener('click', function () {
        rootGetters.EventBus.$emit('codeClick', (codeId))
      })
      return marker
    },
    getCodeMarkerInfo: (state, getters, rootState, rootGetters) => (routeId, sequenceId, codeId, codeName, colorId) => {
      var url
      if (colorId === 1) {
        url = require('@/assets/Icon/MapIcon/orange.png')
      } else if (colorId === 2) {
        url = require('@/assets/Icon/MapIcon/pink.png')
      } else {
        url = require('@/assets/Icon/MapIcon/blue.png')
      }
      const pocArray = getters.getCodePocArray(routeId, sequenceId, codeId)
      return {
        type: 'codeMarker',
        url: url,
        line: getters.getLineMarkerInfo(pocArray),
        position: rootGetters.getPosCenterOfPocArray(pocArray),
        routeId: routeId,
        sequenceId: sequenceId,
        codeId: codeId,
        codeName: String(codeName),
        label: { text: String(codeName), fontSize: rootGetters.getMarkerLabelSize(String(codeName)), color: 'white' },
        colorId: colorId
      }
    },
    getColorMarkerInfo: (state, getters, rootState, rootGetters) => (routeId, sequenceId, colorId) => {
      // const google = rootGetters.getGoogle
      const routeName = rootState.routeModule.data[routeId].name
      var routeShortName
      if (routeName.substr(routeName.length - 3, 1) === '0') {
        routeShortName = routeName.substr(routeName.length - 2)
      } else {
        routeShortName = routeName.substr(routeName.length - 3)
      }
      var url
      if (colorId === 1) {
        url = require('@/assets/Icon/MapIcon/orange.png')
      } else if (colorId === 2) {
        url = require('@/assets/Icon/MapIcon/pink.png')
      } else {
        url = require('@/assets/Icon/MapIcon/blue.png')
      }
      return {
        type: 'colorMarker',
        label: { text: routeShortName, fontSize: rootGetters.getMarkerLabelSize(routeShortName), color: 'white' },
        title: routeName,
        url: url,
        position: rootGetters.getPosAverageOfPocArray(getters.getColorPocArray(routeId, sequenceId, colorId)),
        routeId: routeId,
        sequenceId: sequenceId,
        colorId: colorId
      }
    },
    getColorMarker: (state, getters, rootState, rootGetters) => (routeId, sequenceId, colorId) => {
      const google = rootGetters.getGoogle
      const routeName = rootState.routeModule.data[routeId].name
      var routeShortName
      if (routeName.substr(routeName.length - 3, 1) === '0') {
        routeShortName = routeName.substr(routeName.length - 2)
      } else {
        routeShortName = routeName.substr(routeName.length - 3)
      }
      var url
      if (colorId === 1) {
        url = require('@/assets/Icon/MapIcon/orange.png')
      } else if (colorId === 2) {
        url = require('@/assets/Icon/MapIcon/pink.png')
      } else {
        url = require('@/assets/Icon/MapIcon/blue.png')
      }
      const marker = new rootGetters.getGoogle.maps.Marker({
        position: rootGetters.getPosAverageOfPocArray(getters.getColorPocArray(routeId, sequenceId, colorId)),
        label: { text: routeShortName, fontSize: rootGetters.getMarkerLabelSize(routeShortName), color: 'white' },
        map: null,
        title: routeName,
        icon: {
          url: url,
          scaledSize: new google.maps.Size(60, 60), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(30, 60), // anchor
          labelOrigin: new google.maps.Point(30, 19)
        }
      })
      marker.addListener('click', function () {
        rootGetters.EventBus.$emit('colorClick', (colorId))
      })
      return marker
    },
    getLineMarkerInfo: (state, getters, rootState) => (pocArray) => {
      return pocArray.sort(function (a, b) { return a.seqPosTot - b.seqPosTot }).map(poc => {
        if (poc.placeId) {
          return rootState.placeModule.data[poc.placeId].homeLocation
        } else {
          return poc.homeLocation
        }
      })
    },
    getLineMarkerData: (state, getters) => (routeId, sequenceId) => {
      function generateKey () {
        var d = new Date().getTime()
        var d2 = (performance && performance.now && (performance.now() * 1000)) || 0
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          var r = Math.random() * 16
          if (d > 0) {
            r = (d + r) % 16 | 0
            d = Math.floor(d / 16)
          } else {
            r = (d2 + r) % 16 | 0
            d2 = Math.floor(d2 / 16)
          }
          return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
        })
      }
      return getters.getDisctinctCodeOfSequence(routeId, sequenceId).map(function (code) {
        return {
          posArray: getters.getLineMarkerInfo(getters.getCodePocArray(routeId, sequenceId, code.codeId)),
          codeId: String(code.codeId),
          colorId: code.colorId,
          lineId: generateKey()
        }
      })
    },
    getPocDetailMarker: (state, getters, rootState, rootGetters) => (poc) => {
      const markerData = []
      const pocDoor = poc.pdrListAddress.split(' ')[0]
      const multiItemList = []
      let colorName
      let topName
      let styleName
      // let markerLabel
      if (poc.pdrType === 'Hou/Dom') {
        styleName = 'Hou'
      } else if (poc.pdrType === 'Apt/App') {
        styleName = 'App'
      } else if (poc.pdrType === 'Bus/com') {
        styleName = 'Bus'
      } else {
        styleName = 'Farm'
      }
      if (poc.colorId === 1) { colorName = 'orange' } else if (poc.colorId === 2) { colorName = 'pink' } else { colorName = 'blue' }
      if (poc.vacant) {
        topName = 'green'
      } else if (!poc.pub) {
        topName = 'red'
      } else {
        topName = 'black'
      }
      markerData.push({
        type: 'pocMarker',
        position: poc.homeLocation,
        label: { text: pocDoor, fontSize: rootGetters.getMarkerLabelSize(pocDoor), color: 'white' },
        url: require('@/assets/Icon/MapIcon/POC/' + styleName + '-' + colorName + '-t' + topName + '.png'),
        pocId: poc.id,
        codeId: poc.codeId,
        colorId: poc.colorId,
        isOpenInfoWindow: true
      })
      getters.getActiveMapItemsOfPoc(poc.id).forEach(pocItem => {
        if (pocItem.officeItemMultiId) {
          if (multiItemList.indexOf(pocItem.officeItemMultiId) < 0) {
            multiItemList.push(pocItem.officeItemMultiId)
          }
        } else {
          // markerLabel = pocItem.name ? pocItem.name : pocDoor
          if (pocItem.loc) {
            markerData.push({
              type: 'pocMapItemMarker',
              position: pocItem.loc,
              label: { text: pocItem.name ? pocItem.name : pocDoor, fontSize: '18px', color: 'red' },
              url: rootGetters.getMapItemIconOfType(pocItem.type),
              pocId: poc.id,
              codeId: poc.codeId,
              colorId: poc.colorId,
              itemType: pocItem.type,
              address: poc.pdrListAddress,
              itemId: pocItem.id,
              name: pocItem.name,
              desc: pocItem.desc,
              imageList: pocItem.imageList,
              isOpenInfoWindow: true
            })
          }
        }
      })
      multiItemList.forEach(multiItemId => {
        const item = rootState.officeItemMultiModule.data[multiItemId] ? rootState.officeItemMultiModule.data[multiItemId] : null
        if (item) {
          markerData.push({
            type: 'multiItemMarker',
            position: item.loc,
            desc: item.desc,
            name: item.name,
            imageList: item.imageList,
            label: { text: item.name ? item.name : pocDoor, fontSize: '18px', color: 'red' },
            url: rootGetters.getMapItemIconOfType(item.type),
            pocId: poc.id,
            multiId: multiItemId,
            itemType: item.type,
            isOpenInfoWindow: true
          })
        }
      })
      return markerData
    },
    getPocDetailMarkerData: (state, getters, rootState, rootGetters) => (routeId, pocId) => {
      const markerData = []
      const poc = state.data[pocId]
      const seqId = rootGetters['routeModule/getOfficialSeqIdOfRouteId'](routeId)
      const seqInfo = seqId ? getters.getSeqInfoOfPoc(routeId, seqId, poc) : null
      // poc.color = seqInfo ? seqInfo.tier : null
      const colorId = seqInfo ? seqInfo.tier : null
      // poc.tier = seqInfo ? seqInfo.tier : 1
      const codeId = seqInfo ? String(seqInfo.codeId) : null
      // poc.codeName = seqInfo ? String(seqInfo.codeName) : null
      // poc.seqPosTot = seqInfo ? seqInfo.seqPosTot : poc.pdrseqtotal
      const pocDoor = poc.pdrListAddress.split(' ')[0]
      const multiItemList = []
      let colorName
      let topName
      let styleName
      // let markerLabel
      if (poc.pdrType === 'Hou/Dom') {
        styleName = 'Hou'
      } else if (poc.pdrType === 'Apt/App') {
        styleName = 'App'
      } else if (poc.pdrType === 'Bus/com') {
        styleName = 'Bus'
      } else {
        styleName = 'Farm'
      }
      if (colorId === 1) { colorName = 'orange' } else if (colorId === 2) { colorName = 'pink' } else { colorName = 'blue' }
      if (poc.vacant) {
        topName = 'green'
      } else if (!poc.pub) {
        topName = 'red'
      } else {
        topName = 'black'
      }
      markerData.push({
        type: 'pocMarker',
        position: poc.homeLocation,
        // label: { text: pocDoor, fontSize: rootGetters.getMarkerLabelSize(pocDoor), color: 'white' },
        // url: require('@/assets/Icon/MapIcon/POC/' + styleName + '-' + colorName + '-t' + topName + '.png'),
        pocId: poc.id,
        codeId: codeId,
        colorId: colorId,
        isOpenInfoWindow: true,
        marker: rootGetters.getGoogleMarker({
          type: 'pocMarker',
          map: null,
          label: pocDoor,
          url: require('@/assets/Icon/MapIcon/POC/' + styleName + '-' + colorName + '-t' + topName + '.png'),
          position: poc.homeLocation
        })
      })
      getters.getActiveMapItemsOfPoc(poc.id).forEach(pocItem => {
        if (pocItem.officeItemMultiId) {
          if (multiItemList.indexOf(pocItem.officeItemMultiId) < 0) {
            multiItemList.push(pocItem.officeItemMultiId)
          }
        } else {
          // markerLabel = pocItem.name ? pocItem.name : pocDoor
          if (pocItem.loc) {
            markerData.push({
              type: 'pocMapItemMarker',
              position: pocItem.loc,
              // label: { text: pocItem.name ? pocItem.name : pocDoor, fontSize: '18px', color: 'red' },
              // url: rootGetters.getMapItemIconOfType(pocItem.type),
              pocId: poc.id,
              codeId: codeId,
              colorId: colorId,
              itemType: pocItem.type,
              address: poc.pdrListAddress,
              itemId: pocItem.id,
              name: pocItem.name,
              desc: pocItem.desc,
              imageList: pocItem.imageList,
              isOpenInfoWindow: true,
              marker: rootGetters.getGoogleMarker({
                type: 'pocMapItemMarker',
                map: null,
                label: pocItem.name ? pocItem.name : pocDoor,
                url: rootGetters.getMapItemIconOfType(pocItem.type),
                position: pocItem.loc
              })
            })
          }
        }
      })
      multiItemList.forEach(multiItemId => {
        const item = rootState.officeItemMultiModule.data[multiItemId] ? rootState.officeItemMultiModule.data[multiItemId] : null
        if (item) {
          markerData.push({
            type: 'multiItemMarker',
            position: item.loc,
            desc: item.desc,
            name: item.name,
            imageList: item.imageList,
            // label: { text: item.name ? item.name : pocDoor, fontSize: '18px', color: 'red' },
            // url: rootGetters.getMapItemIconOfType(item.type),
            pocId: poc.id,
            multiId: multiItemId,
            itemType: item.type,
            isOpenInfoWindow: true,
            siteId: item.siteId ? item.siteId : null,
            marker: rootGetters.getGoogleMarker({
              type: 'multiItemMarker',
              map: null,
              label: item.name ? item.name : ' ',
              url: rootGetters.getMapItemIconOfType(item.type),
              position: item.loc
            })
          })
        }
      })
      return markerData
    },
    getPocDetailMarkerData_temptest: (state, getters, rootState, rootGetters) => (routeId, pocId) => {
      function getCleanDoor (door) {
        if (door.split('-').length > 1) {
          return Number(door.split('-')[1].match(/\d+/g).map(Number))
        } else {
          return Number(door.match(/\d+/g).map(Number))
        }
      }
      const markerData = []
      const poc = state.data[pocId]
      const seqId = rootGetters['routeModule/getOfficialSeqIdOfRouteId'](routeId)
      const seqInfo = seqId ? getters.getSeqInfoOfPoc(routeId, seqId, poc) : null
      poc.color = seqInfo ? seqInfo.tier : null
      poc.colorId = seqInfo ? seqInfo.tier : null
      poc.tier = seqInfo ? seqInfo.tier : 1
      poc.codeId = seqInfo ? String(seqInfo.codeId) : null
      poc.codeName = seqInfo ? String(seqInfo.codeName) : null
      poc.seqPosTot = seqInfo ? seqInfo.seqPosTot : poc.pdrseqtotal
      const pocDoor = poc.pdrListAddress.split(' ')[0]
      const multiItemList = []
      let colorName
      let topName
      let styleName
      // let markerLabel
      if (poc.pdrType === 'Hou/Dom') {
        styleName = 'Hou'
      } else if (poc.pdrType === 'Apt/App') {
        styleName = 'App'
      } else if (poc.pdrType === 'Bus/com') {
        styleName = 'Bus'
      } else {
        styleName = 'Farm'
      }
      if (poc.colorId === 1) { colorName = 'orange' } else if (poc.colorId === 2) { colorName = 'pink' } else { colorName = 'blue' }
      if (poc.vacant) {
        topName = 'green'
      } else if (!poc.pub) {
        topName = 'red'
      } else {
        topName = 'black'
      }
      markerData.push({
        type: 'pocMarker',
        position: poc.homeLocation,
        label: { text: pocDoor, fontSize: rootGetters.getMarkerLabelSize(pocDoor), color: 'white' },
        url: require('@/assets/Icon/MapIcon/POC/' + styleName + '-' + colorName + '-t' + topName + '.png'),
        pocId: poc.id,
        codeId: poc.codeId,
        colorId: poc.colorId,
        isOpenInfoWindow: true,
        marker: rootGetters.getGoogleMarker({
          type: 'pocMarker',
          map: null,
          label: getCleanDoor(pocDoor),
          url: require('@/assets/Icon/MapIcon/POC/' + styleName + '-' + colorName + '-t' + topName + '.png'),
          position: poc.homeLocation
        })
      })
      getters.getActiveMapItemsOfPoc(poc.id).forEach(pocItem => {
        if (pocItem.officeItemMultiId) {
          if (multiItemList.indexOf(pocItem.officeItemMultiId) < 0) {
            multiItemList.push(pocItem.officeItemMultiId)
          }
        } else {
          // markerLabel = pocItem.name ? pocItem.name : pocDoor
          if (pocItem.loc) {
            markerData.push({
              type: 'pocMapItemMarker',
              position: pocItem.loc,
              label: { text: pocItem.name ? pocItem.name : pocDoor, fontSize: '18px', color: 'red' },
              url: rootGetters.getMapItemIconOfType(pocItem.type),
              pocId: poc.id,
              codeId: poc.codeId,
              colorId: poc.colorId,
              itemType: pocItem.type,
              address: poc.pdrListAddress,
              itemId: pocItem.id,
              name: pocItem.name,
              desc: pocItem.desc,
              imageList: pocItem.imageList,
              isOpenInfoWindow: true,
              marker: rootGetters.getGoogleMarker({
                type: 'pocMapItemMarker',
                map: null,
                label: pocItem.name ? pocItem.name : pocDoor,
                url: rootGetters.getMapItemIconOfType(pocItem.type),
                position: pocItem.loc
              })
            })
          }
        }
      })
      multiItemList.forEach(multiItemId => {
        const item = rootState.officeItemMultiModule.data[multiItemId] ? rootState.officeItemMultiModule.data[multiItemId] : null
        if (item) {
          markerData.push({
            type: 'multiItemMarker',
            position: item.loc,
            desc: item.desc,
            name: item.name,
            imageList: item.imageList,
            // label: { text: item.name ? item.name : ' ', fontSize: '18px', color: 'red' },
            // url: rootGetters.getMapItemIconOfType(item.type),
            pocId: poc.id,
            multiId: multiItemId,
            itemType: item.type,
            isOpenInfoWindow: true,
            marker: rootGetters.getGoogleMarker({
              type: 'multiItemMarker',
              map: null,
              label: item.name ? item.name : getCleanDoor(pocDoor),
              url: rootGetters.getMapItemIconOfType(item.type),
              position: item.loc
            })
          })
        }
      })
      return markerData
    },
    getPocDetailMarkerData_back2: (state, getters, rootState, rootGetters) => (routeId, sequenceId, pocId) => {
      const markerData = []
      const poc = getters.getPocOfSequence(routeId, sequenceId).filter(poc => poc.id === pocId)[0]
      const pocDoor = poc.pdrListAddress.split(' ')[0]
      const multiItemList = []
      let colorName
      let topName
      let styleName
      // let markerLabel
      if (poc.pdrType === 'Hou/Dom') {
        styleName = 'Hou'
      } else if (poc.pdrType === 'Apt/App') {
        styleName = 'App'
      } else if (poc.pdrType === 'Bus/com') {
        styleName = 'Bus'
      } else {
        styleName = 'Farm'
      }
      if (poc.colorId === 1) { colorName = 'orange' } else if (poc.colorId === 2) { colorName = 'pink' } else { colorName = 'blue' }
      if (poc.vacant) {
        topName = 'green'
      } else if (!poc.pub) {
        topName = 'red'
      } else {
        topName = 'black'
      }
      markerData.push({
        type: 'pocMarker',
        position: poc.homeLocation,
        label: { text: pocDoor, fontSize: rootGetters.getMarkerLabelSize(pocDoor), color: 'white' },
        url: require('@/assets/Icon/MapIcon/POC/' + styleName + '-' + colorName + '-t' + topName + '.png'),
        pocId: poc.id,
        codeId: poc.codeId,
        colorId: poc.colorId,
        isOpenInfoWindow: true
      })
      getters.getActiveMapItemsOfPoc(poc.id).forEach(pocItem => {
        if (pocItem.officeItemMultiId) {
          if (multiItemList.indexOf(pocItem.officeItemMultiId) < 0) {
            multiItemList.push(pocItem.officeItemMultiId)
          }
        } else {
          // markerLabel = pocItem.name ? pocItem.name : pocDoor
          if (pocItem.loc) {
            markerData.push({
              type: 'pocMapItemMarker',
              position: pocItem.loc,
              label: { text: pocItem.name ? pocItem.name : pocDoor, fontSize: '18px', color: 'red' },
              url: rootGetters.getMapItemIconOfType(pocItem.type),
              pocId: poc.id,
              codeId: poc.codeId,
              colorId: poc.colorId,
              itemType: pocItem.type,
              address: poc.pdrListAddress,
              itemId: pocItem.id,
              name: pocItem.name,
              desc: pocItem.desc,
              imageList: pocItem.imageList,
              isOpenInfoWindow: true
            })
          }
        }
      })
      multiItemList.forEach(multiItemId => {
        const item = rootState.officeItemMultiModule.data[multiItemId] ? rootState.officeItemMultiModule.data[multiItemId] : null
        if (item) {
          markerData.push({
            type: 'multiItemMarker',
            position: item.loc,
            desc: item.desc,
            name: item.name,
            imageList: item.imageList,
            // label: { text: item.name ? item.name : pocDoor, fontSize: '18px', color: 'red' },
            // url: rootGetters.getMapItemIconOfType(item.type),
            pocId: poc.id,
            multiId: multiItemId,
            itemType: item.type,
            isOpenInfoWindow: true,
            marker: rootGetters.getGoogleMarker({
              type: 'multiItemMarker',
              label: item.name ? item.name : ' ',
              url: rootGetters.getMapItemIconOfType(item.type),
              position: item.loc
            })
          })
        }
      })
      return markerData
    },
    getPlaceDetailMarkerData: (state, getters, rootState, rootGetters) => (routeId, placeId) => {
      const place = rootState.placeModule.data[placeId]
      const multiItemList = []
      let addPlaceMarker = false
      const markerData = []
      let colorName
      let colorId
      let allVacant = true
      let allNoPub = true
      function getCleanDoor (door) {
        if (door.split('-').length > 1) {
          return Number(door.split('-')[1].match(/\d+/g).map(Number)).toString()
        } else {
          return Number(door.match(/\d+/g).map(Number)).toString()
        }
      }
      const pocOfPlace = getters.getPocOfRoute(routeId).filter(poc => poc.placeId === placeId)
      pocOfPlace.forEach(poc => {
        const pocDoor = poc.pdrListAddress.split(' ')[0]
        if (poc.pub) { allNoPub = false }
        if (!colorName) {
          if (poc.colorId === 1) { colorName = 'orange' } else if (poc.colorId === 2) { colorName = 'pink' } else { colorName = 'blue' }
        }
        if (!colorId) { colorId = poc.colorId }
        if (!poc.vacant) { allVacant = false }
        if (rootGetters.distance(poc.homeLocation, place.homeLocation) > 5) {
          let styleName
          let topName
          if (poc.pdrType === 'Hou/Dom') {
            styleName = 'Hou'
          } else if (poc.pdrType === 'Apt/App') {
            styleName = 'App'
          } else if (poc.pdrType === 'Bus/com') {
            styleName = 'Bus'
          } else {
            styleName = 'Farm'
          }
          if (poc.color === 1) { colorName = 'orange' } else if (poc.color === 2) { colorName = 'pink' } else { colorName = 'blue' }
          if (poc.vacant) {
            topName = 'green'
          } else if (!poc.pub) {
            topName = 'red'
          } else {
            topName = 'black'
          }
          markerData.push({
            type: 'pocMarker',
            position: poc.homeLocation,
            label: { text: pocDoor, fontSize: rootGetters.getMarkerLabelSize(pocDoor), color: 'white' },
            url: require('@/assets/Icon/MapIcon/POC/' + styleName + '-' + colorName + '-t' + topName + '.png'),
            pocId: poc.id,
            codeId: poc.codeId,
            colorId: poc.colorId,
            isOpenInfoWindow: true,
            marker: rootGetters.getGoogleMarker({
              type: 'pocMarker',
              map: null,
              label: getCleanDoor(pocDoor),
              url: require('@/assets/Icon/MapIcon/POC/' + styleName + '-' + colorName + '-t' + topName + '.png'),
              position: poc.homeLocation
            })
          })
        } else {
          addPlaceMarker = true
        }
        // addPlaceMarker = true
        getters.getActiveMapItemsOfPoc(poc.id).forEach(pocItem => {
          if (pocItem.officeItemMultiId) {
            if (multiItemList.indexOf(pocItem.officeItemMultiId) < 0) {
              multiItemList.push(pocItem.officeItemMultiId)
            }
          } else {
            if (pocItem.loc) {
              markerData.push({
                type: 'pocMapItemMarker',
                position: pocItem.loc,
                label: { text: pocItem.name ? pocItem.name : pocDoor, fontSize: '18px', color: 'red' },
                url: rootGetters.getMapItemIconOfType(pocItem.type),
                pocId: poc.id,
                codeId: poc.codeId,
                itemId: pocItem.id,
                colorId: poc.colorId,
                itemType: pocItem.type,
                address: poc.pdrListAddress,
                name: pocItem.name,
                desc: pocItem.desc,
                imageList: pocItem.imageList,
                isOpenInfoWindow: true,
                marker: rootGetters.getGoogleMarker({
                  type: 'pocMapItemMarker',
                  map: null,
                  label: pocItem.name ? pocItem.name : pocDoor,
                  url: rootGetters.getMapItemIconOfType(pocItem.type),
                  position: pocItem.loc
                })
              })
            }
          }
        })
      })
      multiItemList.forEach(multiItemId => {
        const item = rootState.officeItemMultiModule.data[multiItemId]
        markerData.push({
          type: 'multiItemMarker',
          position: item.loc,
          desc: item.desc,
          name: item.name,
          // label: { text: item.name ? item.name : ' ', fontSize: '18px', color: 'red' },
          // url: rootGetters.getMapItemIconOfType(item.type),
          multiId: multiItemId,
          imageList: item.imageList,
          itemType: item.type,
          isOpenInfoWindow: true,
          siteId: item.siteId ? item.siteId : null,
          marker: rootGetters.getGoogleMarker({
            type: 'multiItemMarker',
            map: null,
            label: item.name ? item.name : ' ',
            url: rootGetters.getMapItemIconOfType(item.type),
            position: item.loc
          })
        })
      })
      if (addPlaceMarker) {
        let topName
        if (allVacant) {
          topName = 'green'
        } else if (allNoPub) {
          topName = 'red'
        } else {
          topName = 'black'
        }
        markerData.push({
          type: 'placeMarker',
          position: place.homeLocation,
          label: { text: String(place.sName), fontSize: rootGetters.getMarkerLabelSize(String(place.sName)), color: 'white' },
          url: require('@/assets/Icon/MapIcon/POC/App-' + colorName + '-t' + topName + '.png'),
          placeId: placeId,
          colorId: colorId,
          routeId: routeId,
          stat: rootGetters.getCumulStatFromPocArray(pocOfPlace),
          isOpenInfoWindow: true,
          marker: rootGetters.getGoogleMarker({
            type: 'placeMarker',
            map: null,
            label: String(place.sName),
            url: require('@/assets/Icon/MapIcon/POC/App-' + colorName + '-t' + topName + '.png'),
            position: place.homeLocation
          })
        })
      }
      return markerData
    },
    getPlaceDetailMarkerData_back2: (state, getters, rootState, rootGetters) => (routeId, sequenceId, placeId) => {
      const place = rootState.placeModule.data[placeId]
      const multiItemList = []
      let addPlaceMarker = false
      const markerData = []
      let colorName
      let colorId
      let allVacant = true
      let allNoPub = true
      const pocOfPlaceSequence = getters.getPocOfSequence(routeId, sequenceId).filter(poc => poc.placeId === placeId)
      pocOfPlaceSequence.forEach(poc => {
        const pocDoor = poc.pdrListAddress.split(' ')[0]
        if (poc.pub) { allNoPub = false }
        if (!colorName) {
          if (poc.colorId === 1) { colorName = 'orange' } else if (poc.colorId === 2) { colorName = 'pink' } else { colorName = 'blue' }
        }
        if (!colorId) { colorId = poc.colorId }
        if (!poc.vacant) { allVacant = false }
        if (rootGetters.distance(poc.homeLocation, place.homeLocation) > 1) {
          let styleName
          let topName
          if (poc.pdrType === 'Hou/Dom') {
            styleName = 'Hou'
          } else if (poc.pdrType === 'Apt/App') {
            styleName = 'App'
          } else if (poc.pdrType === 'Bus/com') {
            styleName = 'Bus'
          } else {
            styleName = 'Farm'
          }
          if (poc.color === 1) { colorName = 'orange' } else if (poc.color === 2) { colorName = 'pink' } else { colorName = 'blue' }
          if (poc.vacant) {
            topName = 'green'
          } else if (!poc.pub) {
            topName = 'red'
          } else {
            topName = 'black'
          }
          markerData.push({
            type: 'pocMarker',
            position: poc.homeLocation,
            label: { text: pocDoor, fontSize: rootGetters.getMarkerLabelSize(pocDoor), color: 'white' },
            url: require('@/assets/Icon/MapIcon/POC/' + styleName + '-' + colorName + '-t' + topName + '.png'),
            pocId: poc.id,
            codeId: poc.codeId,
            colorId: poc.colorId,
            isOpenInfoWindow: true
          })
        } else {
          addPlaceMarker = true
        }
        // addPlaceMarker = true
        getters.getActiveMapItemsOfPoc(poc.id).forEach(pocItem => {
          if (pocItem.officeItemMultiId) {
            if (multiItemList.indexOf(pocItem.officeItemMultiId) < 0) {
              multiItemList.push(pocItem.officeItemMultiId)
            }
          } else {
            if (pocItem.loc) {
              markerData.push({
                type: 'pocMapItemMarker',
                position: pocItem.loc,
                label: { text: pocItem.name ? pocItem.name : pocDoor, fontSize: '18px', color: 'red' },
                url: rootGetters.getMapItemIconOfType(pocItem.type),
                pocId: poc.id,
                codeId: poc.codeId,
                itemId: pocItem.id,
                colorId: poc.colorId,
                itemType: pocItem.type,
                address: poc.pdrListAddress,
                name: pocItem.name,
                desc: pocItem.desc,
                imageList: pocItem.imageList,
                isOpenInfoWindow: true
              })
            }
          }
        })
      })
      multiItemList.forEach(multiItemId => {
        const item = rootState.officeItemMultiModule.data[multiItemId]
        markerData.push({
          type: 'multiItemMarker',
          position: item.loc,
          desc: item.desc,
          name: item.name,
          label: { text: item.name ? item.name : ' ', fontSize: '18px', color: 'red' },
          url: rootGetters.getMapItemIconOfType(item.type),
          multiId: multiItemId,
          imageList: item.imageList,
          itemType: item.type,
          isOpenInfoWindow: true
        })
      })
      if (addPlaceMarker) {
        let topName
        if (allVacant) {
          topName = 'green'
        } else if (allNoPub) {
          topName = 'red'
        } else {
          topName = 'black'
        }
        markerData.push({
          type: 'placeMarker',
          position: place.homeLocation,
          label: { text: String(place.sName), fontSize: rootGetters.getMarkerLabelSize(String(place.sName)), color: 'white' },
          url: require('@/assets/Icon/MapIcon/POC/App-' + colorName + '-t' + topName + '.png'),
          placeId: placeId,
          colorId: colorId,
          routeId: routeId,
          sequenceId: sequenceId,
          stat: rootGetters.getCumulStatFromPocArray(pocOfPlaceSequence),
          isOpenInfoWindow: true
        })
      }
      return markerData
    },
    getPlaceOfRouteMarkerDataSelected: (state, getters, rootState, rootGetters) => {
      const markerList = []
      // const pocList = []
      const placeIdList = []
      getters.getPocOfSequenceSelected.forEach(poc => {
        if (poc.placeId) {
          if (placeIdList.map(x => { return x.placeId }).indexOf(poc.placeId) < 0) {
            placeIdList.push({ placeId: poc.placeId, colorId: poc.color })
          }
        } else {
          markerList.push(getters.getPocMarker(poc))
        }
      })
      placeIdList.forEach(place => {
        markerList.push(getters.getPlaceMarker(rootGetters.getSelectedRouteId, rootGetters.getSelectedSequenceId, place.placeId, place.colorId))
      })
      return markerList
    },
    getPlaceOfCodeMarkerData: (state, getters) => (routeId, sequenceId, codeId) => {
      const markerList = []
      // const pocList = []
      const placeIdList = []
      let colorId = 3
      getters.getCodePocArray(routeId, sequenceId, codeId).forEach(poc => {
        if (poc.placeId) {
          colorId = poc.color
          if (placeIdList.indexOf(poc.placeId) < 0) {
            placeIdList.push(poc.placeId)
          }
        } else {
          markerList.push(getters.getPocMarker(poc))
        }
      })
      // pocList.forEach(poc => {
      //   markerList.push(getters.getPocMarker(poc))
      // })
      placeIdList.forEach(placeId => {
        markerList.push(getters.getPlaceMarker(routeId, sequenceId, placeId, colorId))
      })
      return markerList
    },
    getPlaceOfCodeMarkerDataSelected: (state, getters, rootState, rootGetters) => {
      if (rootGetters.getUserSelection.code) {
        return getters.getPlaceOfRouteMarkerDataSelected.filter(x => x.codeId === rootGetters.getUserSelection.code)
      } else {
        return []
      }
    },
    getCodeOfColorMarkerData: (state, getters) => (routeId, sequenceId, colorId) => {
      return getters.getDisctinctCodeOfColorOfSequence(routeId, sequenceId, colorId).map(function (code) {
        return getters.getCodeMarkerInfo(routeId, sequenceId, code.codeId, code.codeName, colorId)
      })
    },
    getCodeOfColorMarkerDataSelected: (state, getters, rootState, rootGetters) => {
      if (rootGetters.getUserSelection.color) {
        return getters.getCodeOfRouteMarkerDataSelected.filter(x => String(x.colorId) === String(rootGetters.getUserSelection.color))
      } else {
        return []
      }
    },
    getCodeOfRouteMarkerData: (state, getters) => (routeId, sequenceId) => {
      return getters.getDisctinctCodeOfSequence(routeId, sequenceId).map(function (code) {
        return getters.getCodeMarkerInfo(routeId, sequenceId, code.codeId, code.codeName, code.colorId)
      })
    },
    getCodeOfRouteMarkerDataSelected: (state, getters, rootState, rootGetters) => {
      if (rootGetters.getSelectedRouteId && rootGetters.getSelectedSequenceId !== undefined) {
        return getters.getCodeOfRouteMarkerData(rootGetters.getSelectedRouteId, rootGetters.getSelectedSequenceId)
      } else {
        return []
      }
    },
    getColorOfRouteMarkerData: (state, getters) => (routeId, sequenceId) => {
      return getters.getDisctinctColorOfSequence(routeId, sequenceId).map(function (color) {
        return getters.getColorMarkerInfo(routeId, sequenceId, color.colorId)
      })
    },
    getColorOfRouteMarkerDataSelected: (state, getters, rootState, rootGetters) => {
      if (rootGetters.getSelectedRouteId && rootGetters.getSelectedSequenceId !== undefined) {
        return getters.getColorOfRouteMarkerData(rootGetters.getSelectedRouteId, rootGetters.getSelectedSequenceId)
      } else {
        return []
      }
    },
    pocExist: (state) => (address) => {
      var existId = null
      for (const pocId in state.data) {
        const poc = state.data[pocId]
        if (poc.pdrListAddress === address) {
          existId = pocId
        }
      }
      return existId
    },
    isPlaceIdExist: (state) => (placeId) => {
      let exist = false
      for (const pocId in state.data) {
        if (state.data[pocId].place_id === placeId) {
          exist = true
          break
        }
      }
      return exist
    },
    getPlaceLocation: (state, getters) => (placeId) => {
      let pLaceLocation = null
      for (const pocId in state.data) {
        const poc = state.data[pocId]
        if (poc.place_id === placeId) {
          pLaceLocation = poc.homeLocation
          break
        }
      }
      return pLaceLocation
    },
    getTpTypeArray: (state) => {
      const result = []
      for (const pocId in state.data) {
        const tpType = state.data[pocId].tpType
        if (result.indexOf(tpType) < 0) {
          result.push(tpType)
        }
      }
      return result
    },
    getActiveMapItemsOfPoc: (state, getters, rootState, rootGetters) => (pocId) => {
      return getters.getPoc(pocId).mapItems.filter(x => x.isActive).map(i => {
        if (i.officeItemMultiId) {
          const multiItem = rootState.officeItemMultiModule.data[i.officeItemMultiId]
          return {
            id: i.id,
            itemId: i.id,
            officeItemMultiId: i.officeItemMultiId,
            loc: multiItem.loc !== undefined ? multiItem.loc : null,
            name: multiItem.name !== undefined ? multiItem.name : null,
            desc: multiItem.desc !== undefined ? multiItem.desc : null,
            type: multiItem.type !== undefined ? multiItem.type : null,
            imageList: multiItem.imageList !== undefined ? multiItem.imageList : [],
            pocIdList: multiItem.pocIdList !== undefined ? multiItem.pocIdList : [pocId],
            isActive: true
          }
        } else {
          return {
            id: i.id,
            itemId: i.id,
            loc: i.loc !== undefined ? i.loc : null,
            name: i.name !== undefined ? i.name : null,
            desc: i.desc !== undefined ? i.desc : null,
            type: i.type !== undefined ? i.type : null,
            imageList: i.imageList !== undefined ? i.imageList : [],
            pocIdList: i.pocIdList !== undefined ? i.pocIdList : [pocId],
            isActive: true
          }
        }
      }).sort(function (a, b) { return a.id - b.id })
    },
    // getActiveMapItemsOfPoc_back: (state, getters, rootState, rootGetters) => (pocId) => {
    //   const poc = getters.getPoc(pocId)
    //   // // console.log('trace getActiveMapItemsOfPoc')
    //   const items = []
    //   poc.mapItems.forEach(item => {
    //     item.itemId = item.id
    //     if (item.officeItemMultiId) {
    //       // get officemulti
    //       // // console.log('trace multi')
    //       const multiItem = rootState.officeItemMultiModule.data[item.officeItemMultiId]
    //       item.loc = multiItem.loc !== undefined ? multiItem.loc : null
    //       item.name = multiItem.name !== undefined ? multiItem.name : null
    //       item.desc = multiItem.desc !== undefined ? multiItem.desc : null
    //       item.type = multiItem.type !== undefined ? multiItem.type : null
    //       item.imageList = multiItem.imageList !== undefined ? multiItem.imageList : []
    //       item.pocIdList = multiItem.pocIdList !== undefined ? multiItem.pocIdList : []
    //       item.isActive = multiItem.isActive !== undefined ? multiItem.isActive : false
    //       if (item.isActive) { items.push(item) }
    //     } else if (item.isActive) {
    //       // // console.log('trace isActive')
    //       if (item.loc === undefined) { item.loc = null }
    //       if (item.name === undefined) { item.name = null }
    //       if (item.desc === undefined) { item.desc = null }
    //       if (item.type === undefined) { item.type = null }
    //       if (item.imageList === undefined) { item.imageList = [] }
    //       if (item.pocIdList === undefined) { item.pocIdList = [] }
    //       if (rootGetters.isSoloPocMapItemType(item.type)) {
    //         items.push(item)
    //       } else {
    //         // // console.log('trace multinot set')
    //         // multiItem not set
    //         item.pocIdList = [pocId]
    //         items.push(item)
    //       }
    //     }
    //   })
    //   return items.sort(function (a, b) { return a.id - b.id })
    // },
    numberOfPoc: (state) => {
      var i = 0
      for (const pocId in state.data) {
        if (pocId) { i++ }
      }
      return i
    },
    getPocItems: (state, getters, rootState, rootGetters) => {
      const pocItemsList = []
      for (const poc of getters.getPocOfSequenceSorted(rootGetters.getSelectedRouteId, rootGetters.getSelectedSequenceId)) {
        // const poc = state.data[pocId]
        pocItemsList.push({ text: poc.pdrListAddress, value: { id: poc.id, name: poc.pdrListAddress, pub: poc.pub, type: poc.type, vacant: poc.vacant, active: false, officeId: poc.officeId, place_id: poc.place_id } })
      }
      return pocItemsList
    },
    getAllPoc: (state) => {
      return Object.values(state.data)
    },
    getPocOfRoute: (state, getters, rootState, rootGetters) => (routeId) => {
      const seqId = rootGetters['routeModule/getOfficialSeqIdOfRouteId'](routeId)
      return Object.values(state.data).filter(poc => poc.route.indexOf(routeId) > -1).map(function (poc) {
        const seqInfo = seqId ? getters.getSeqInfoOfPoc(routeId, seqId, poc) : null
        poc.color = seqInfo ? seqInfo.tier : null
        poc.colorId = seqInfo ? seqInfo.tier : null
        poc.tier = seqInfo ? seqInfo.tier : 1
        poc.codeId = seqInfo ? String(seqInfo.codeId) : null
        poc.codeName = seqInfo ? String(seqInfo.codeName) : null
        poc.seqPosTot = seqInfo ? seqInfo.seqPosTot : poc.pdrseqtotal
        return poc
      })
    },
    getPocOfPlaceOfRoute: (state, getters) => (routeId, placeId) => {
      return getters.getPocOfRoute(routeId).filter(poc => poc.placeId && poc.placeId === placeId)
    },
    // getPocMarkerTest: (state, getters, rootState, rootGetters) => (poc) => {
    //   boundsOfPlace.extend(pocItem.loc)
    //   const google = rootGetters.getGoogle
    //         const marker = new google.maps.Marker({
    //           position: pocItem.loc,
    //           label: { text: markerLabel, fontSize: '18px', color: 'red' },
    //           zIndex: 500 - placeDetailMarker.length,
    //           map: null,
    //           icon: {
    //             url: rootGetters.getMapItemIconOfType(pocItem.type),
    //             // size: new google.maps.Size(48, 48),
    //             scaledSize: new google.maps.Size(48, 48), // scaled size
    //             origin: new google.maps.Point(0, 0), // origin
    //             anchor: new google.maps.Point(24, 28), // anchor
    //             labelOrigin: new google.maps.Point(24, 54)
    //           }
    //         })
    // },
    getPocMarkerOfRoute: (state, getters, rootState, rootGetters) => (routeId) => {
      return getters.getPocOfRoute(routeId).filter(x => !x.placeId).map(function (poc) {
        return getters.getPocMarker(poc)
      })
    },
    getPocOfSequenceSelected: (state, getters, rootState, rootGetters) => {
      if (rootGetters.getSelectedRouteId && rootGetters.getSelectedSequenceId !== undefined) {
        return getters.getPocOfSequence(rootGetters.getSelectedRouteId, rootGetters.getSelectedSequenceId)
      } else {
        return []
      }
    },
    getPocOfSequenceSortedSelected: (state, getters, rootState, rootGetters) => {
      if (rootGetters.getSelectedRouteId && rootGetters.getSelectedSequenceId !== undefined) {
        return getters.getPocOfSequenceSorted(rootGetters.getSelectedRouteId, rootGetters.getSelectedSequenceId)
      } else {
        return []
      }
    },
    getPocOfSequence: (state) => (routeId, sequenceId) => {
      const pocList = []
      for (const pocId in state.data) {
        const poc = state.data[pocId]
        if (poc.routeSequences) {
          const routeIndex = poc.routeSequences.map(function (x) { return x.routeId }).indexOf(routeId)
          if (routeIndex >= 0) {
            const sequenceIndex = poc.routeSequences[routeIndex].sequences.map(function (x) { return x.sequenceId }).indexOf(sequenceId)
            if (sequenceIndex >= 0) {
              const seq = poc.routeSequences[routeIndex].sequences[sequenceIndex]
              poc.index = { sequenceIndex: sequenceIndex, routeIndex: routeIndex }
              poc.color = seq.tier
              poc.colorId = seq.tier
              poc.tier = seq.tier
              poc.codeId = String(seq.codeId)
              poc.codeName = seq.codeName
              poc.seqPosTot = seq.seqPosTot
              const door = poc.pdrListAddress.split(' ')[0]
              if (door.split('-').length > 1) {
                poc.placeIdDoor = door.split('-')[1]
              } else {
                poc.placeIdDoor = door
              }
              pocList.push(poc)
            }
          }
        }
      }
      return pocList
    },
    getPocOfSequenceSorted: (state, getters) => (routeId, sequenceId) => {
      return Object.values(getters.getPocOfSequence(routeId, sequenceId)).sort(function (a, b) {
        return a.seqPosTot - b.seqPosTot
        // return a.routeSequences[a.index.routeIndex].sequences[a.index.sequenceIndex].seqPosTot - b.routeSequences[b.index.routeIndex].sequences[b.index.sequenceIndex].seqPosTot
      }) // your sort function
    },
    getPocOfSequenceSortedByDistance: (state, getters, rootState, rootGetters) => (routeId, sequenceId) => {
      return Object.values(getters.getPocOfSequence(routeId, sequenceId)).sort(function (a, b) {
        return rootGetters.distanceOfMe(a.homeLocation) - rootGetters.distanceOfMe(b.homeLocation)
      }) // your sort function
    },
    getPocOfSequenceSortedByDistanceSelected: (state, getters, rootState, rootGetters) => {
      if (rootGetters.getSelectedRouteId && rootGetters.getSelectedSequenceId !== undefined) {
        return getters.getPocOfSequenceSortedByDistance(rootGetters.getSelectedRouteId, rootGetters.getSelectedSequenceId)
      } else {
        return []
      }
    },
    getPocDataIteratorItemsBy: (state, getters, rootState, rootGetters) => (orderBy) => {
      const pdrItemsList = []
      let pdrArray
      if (orderBy === 'dist') {
        pdrArray = getters.getPocOfSequenceSortedByDistance(rootGetters.getSelectedRouteId, rootGetters.getSelectedSequenceId)
      } else {
        pdrArray = getters.getPocOfSequenceSorted(rootGetters.getSelectedRouteId, rootGetters.getSelectedSequenceId)
      }
      for (const pdr of pdrArray) {
        pdrItemsList.push({ id: pdr.id, name: pdr.pdrListAddress, pub: pdr.pub, type: pdr.pdrType, tpType: pdr.tpType, vacant: pdr.vacant, active: false, officeId: pdr.officeId, place_id: pdr.place_id, placeId: pdr.placeId, color: pdr.color, codeId: pdr.codeId, code: pdr.codeName })
      }
      return pdrItemsList
    },
    getPocDataIteratorItemsOfPocIdArray: (state) => (pocIdArray) => {
      const result = []
      pocIdArray.forEach(pocId => {
        const pdr = state.data[pocId]
        result.push({ id: pdr.id, name: pdr.pdrListAddress, pub: pdr.pub, type: pdr.pdrType, tpType: pdr.tpType, vacant: pdr.vacant, active: false, officeId: pdr.officeId, place_id: pdr.place_id, color: pdr.color, code: pdr.codeName, placeId: pdr.placeId, codeId: pdr.codeId })
      })
      return result.length ? result : null
    },
    getPocDataIteratorItemsRangeList: (state, getters) => (pocArray) => {
      const maxRange = 4
      const posSeqList = getters.getPocOfSequenceSortedSelected
      const preSelectList = []
      let minIndex = 99999
      let maxIndex = 0

      function addSameDoorToPreSelect (pocId) {
        const pocIndex = posSeqList.map(function (x) { return x.id }).indexOf(pocId)
        const PreSelectIndex = preSelectList.map(function (x) { return x.id }).indexOf(pocId)
        if (PreSelectIndex < 0) {
          if (preSelectList.map(x => { return x.id }).indexOf(posSeqList[pocIndex].id) < 0) {
            preSelectList.push(posSeqList[pocIndex])
          }
          if (minIndex > pocIndex) { minIndex = pocIndex }
          if (maxIndex < pocIndex) { maxIndex = pocIndex }
          const cleanDoor = getCleanDoor(posSeqList[pocIndex].pdrListAddress)
          let rangeCpt = 0
          let isRange = false
          let index = pocIndex
          if (pocIndex) {
            while (rangeCpt < maxRange && index >= 0) {
              if (cleanDoor !== getCleanDoor(posSeqList[index].pdrListAddress)) { isRange = true }
              if (isRange) { rangeCpt++ } else {
                if (preSelectList.map(x => { return x.id }).indexOf(posSeqList[index].id) < 0) {
                  preSelectList.push(posSeqList[index])
                }
              }
              if (index === 0) {
                rangeCpt = maxRange
              } else {
                index--
              }
            }
            if (minIndex > index) { minIndex = index }
          }
          isRange = false
          rangeCpt = 0
          index = pocIndex + 1
          if (index < posSeqList.length) {
            while (rangeCpt < maxRange && index < posSeqList.length) {
              if (cleanDoor !== getCleanDoor(posSeqList[index].pdrListAddress)) { isRange = true }
              if (isRange) { rangeCpt++ } else {
                if (preSelectList.map(x => { return x.id }).indexOf(posSeqList[index].id) < 0) {
                  preSelectList.push(posSeqList[index])
                }
              }
              if (index < posSeqList.length - 1) {
                index++
              } else {
                rangeCpt = maxRange
              }
            }
            if (maxIndex < index) { maxIndex = index }
          }
        }
      }
      function getCleanDoor (address) {
        const door = address.split(' ')[0]
        if (door.split('-').length > 1) {
          return Number(door.split('-')[1].match(/\d+/g).map(Number))
        } else {
          return Number(door.match(/\d+/g).map(Number))
        }
      }
      if (pocArray) {
        if (pocArray.length) {
          pocArray.forEach(pocId => {
            if (pocId.id) {
              addSameDoorToPreSelect(pocId.id)
            } else {
              addSameDoorToPreSelect(pocId)
            }
          })
        } else {
          addSameDoorToPreSelect(pocArray)
        }
        return { list: posSeqList.slice(minIndex, maxIndex), preSelectList: preSelectList }
      } else {
        return { list: [], preSelectList: [] }
      }
    },
    getPlaceDetailMarker: (state, getters, rootState, rootGetters) => (pocList, itemtitle, routeId, sequenceId) => {
      const google = rootGetters.getGoogle
      const boundsOfPlace = new google.maps.LatLngBounds()
      const placeIdUsedArray = []
      const placeDetailMarker = []
      function getTitle (type) {
        switch (type) {
          case 'door':
            return itemtitle.door
          case 'tempbox':
            return itemtitle.tempbox
          case 'safeplace':
            return itemtitle.safeplace
          case 'dtd':
            return itemtitle.dtd
          case 'cmb':
            return itemtitle.cmb
          case 'lba':
            return itemtitle.lba
          case 'aptlba':
            return itemtitle.aptlba
          case 'ksk':
            return itemtitle.ksk
          case 'dflb':
            return itemtitle.dflb
          case 'rmb':
            return itemtitle.rmb
          case 'cntr':
            return itemtitle.cntr
        }
      }

      pocList.forEach(poc => {
        if (poc.placeId) {
          if (poc.homeLocation) {
            if (rootGetters.distance(poc.homeLocation, rootState.placeModule.data[poc.placeId].homeLocation) > 2) {
              const p1Marker = getters.getPocMarker(poc)
              // const infoWindow = new google.maps.InfoWindow()
              // infoWindow.setContent('<h1>' + poc.pdrListAddress + '</h1>')
              // infoWindow.setPosition(poc.homeLocation)
              p1Marker.addListener('click', () => {
                rootGetters.EventBus.$emit('createInfoWindowOfMaker', ({ type: 'pocInfoWindow', poc: poc, marker: p1Marker, statArray: [rootGetters.getCumulStatFromPocArray([poc])] }))
                // rootGetters.EventBus.$emit('createInfoWindowOfMaker', ({ infoWindow: infoWindow, marker: p1Marker, statArray: [getters.getCumulStatFromPocArray([poc])] }))
              })
              placeDetailMarker.push(p1Marker)
            }
          }
          if (placeIdUsedArray.indexOf(poc.placeId) < 0) {
            placeIdUsedArray.push(poc.placeId)
            const p2Marker = getters.getPlaceMarker(routeId, sequenceId, poc.placeId, poc.colorId)
            // const infoWindow = new google.maps.InfoWindow()
            // infoWindow.setContent('<h1>' + rootState.placeModule.data[poc.placeId].lName + '</h1>')
            // infoWindow.setPosition(rootState.placeModule.data[poc.placeId].homeLocation)
            p2Marker.addListener('click', () => {
              // rootGetters.EventBus.$emit('createInfoWindowOfMaker', ({ infoWindow: infoWindow, marker: p2Marker, statArray: [getters.getCumulStatFromPocArray(pocList.filter(x => x.placeId === poc.placeId))] }))
              rootGetters.EventBus.$emit('createInfoWindowOfMaker', ({ type: 'placeInfoWindow', placeId: poc.placeId, colorId: poc.colorId, marker: p2Marker, statArray: [rootGetters.getCumulStatFromPocArray(pocList.filter(x => x.placeId === poc.placeId))] }))
            })
            placeDetailMarker.push(p2Marker)
          }
        } else {
          const pMarker = getters.getPocMarker(poc)
          // const infoWindow = new google.maps.InfoWindow()
          // infoWindow.setContent('<h1>' + poc.pdrListAddress + '</h1>')
          // infoWindow.setPosition(poc.homeLocation)
          pMarker.addListener('click', () => {
            rootGetters.EventBus.$emit('createInfoWindowOfMaker', ({ type: 'pocInfoWindow', poc: poc, marker: pMarker, statArray: [rootGetters.getCumulStatFromPocArray([poc])] }))
            // rootGetters.EventBus.$emit('createInfoWindowOfMaker', ({ infoWindow: infoWindow, marker: pMarker, statArray: [getters.getCumulStatFromPocArray([poc])] }))
          })
          placeDetailMarker.push(pMarker)
        }
        const pocDoor = poc.pdrListAddress.split(' ')[0]
        boundsOfPlace.extend(poc.homeLocation)
        getters.getActiveMapItemsOfPoc(poc.id).forEach(pocItem => {
          let multiExist = false
          let markerLabel = pocDoor
          if (pocItem.officeItemMultiId) {
            markerLabel = pocItem.name ? pocItem.name : ' '
            if (placeDetailMarker.filter(x => x.multiId).map(function (x) { return x.multiId }).indexOf(pocItem.officeItemMultiId) > -1) {
              multiExist = true
            }
          }
          if (pocItem.loc && !multiExist) {
            boundsOfPlace.extend(pocItem.loc)
            const marker = new google.maps.Marker({
              position: pocItem.loc,
              label: { text: markerLabel, fontSize: '18px', color: 'red' },
              zIndex: 500 - placeDetailMarker.length,
              map: null,
              icon: {
                url: rootGetters.getMapItemIconOfType(pocItem.type),
                // size: new google.maps.Size(48, 48),
                scaledSize: new google.maps.Size(48, 48), // scaled size
                origin: new google.maps.Point(0, 0), // origin
                anchor: new google.maps.Point(24, 28), // anchor
                labelOrigin: new google.maps.Point(24, 54)
              }
            })
            const infoWindow = new google.maps.InfoWindow()
            let infoContent = '<h1 style="text-align: center;">'
            infoContent += pocItem.name ? pocItem.name : getTitle(pocItem.type)
            infoContent += '</h1>'
            if (pocItem.desc) {
              infoContent += '<p style="text-align: center;"><span style="color: #ff0000;">'
              infoContent += pocItem.desc
              infoContent += '</span></p>'
            }
            if (pocItem.imageList) {
              if (pocItem.imageList.length) {
                infoContent += '<p><img style="display: block; margin-left: auto; margin-right: auto;" src="'
                infoContent += pocItem.imageList.filter(image => image.isPrimary)[0].url
                infoContent += '" alt="'
                infoContent += 'image'
                infoContent += '" width="180" height="120" /></p>'
              }
              pocItem.imageList.forEach(image => {
                if (!image.isPrimary) {
                  infoContent += '<p><img style="display: block; margin-left: auto; margin-right: auto;" src="'
                  infoContent += image.url
                  infoContent += '" alt="'
                  infoContent += 'image'
                  infoContent += '" width="180" height="120" /></p>'
                }
              })
            }
            infoWindow.setContent(infoContent)
            infoWindow.setPosition(pocItem.loc)
            marker.addListener('click', () => {
              // infoWindow.open(map, marker)
              rootGetters.EventBus.$emit('openInfoBoxClick', ({ infoWindow: infoWindow, marker: marker }))
            })
            // placeDetailMarker.push({ marker: marker, multiId: pocItem.officeItemMultiId ? pocItem.officeItemMultiId : null })
            placeDetailMarker.push(marker)
          }
        })
      })
      return { markerList: placeDetailMarker, bounds: boundsOfPlace }
    },
    getPlaceMarkerOfSequence: (state, getters, rootState, rootGetters) => (sequenceId, routeId) => {
      const stat = { color: [], code: [], place: [], route: { app: 0, appP: 0, appV: 0, hou: 0, houP: 0, houV: 0, bus: 0, busP: 0, busV: 0, farm: 0, farmP: 0, farmV: 0 } }
      const placeMarker = []
      const codeDetail = []
      const codeMarker = []
      const colorMarker = []
      const colorDetail = []
      const routeName = rootState.routeModule.data[routeId].name
      const routeShortName = routeName.substr(routeName.length - 2)
      const routeDetail = { locTot: { cpt: 0, lat: 0, lng: 0 } }
      const google = rootGetters.getGoogle
      const boundsOfRoute = new google.maps.LatLngBounds()
      const EventBus = rootGetters.EventBus
      const isOfficialSeq = rootGetters['routeModule/isOfficialSequence'](sequenceId, routeId)
      getters.getPocOfSequenceSorted(routeId, sequenceId).forEach(pdr => {
        cumulStat(pdr)
        const placeIndex = placeMarker.map(function (x) { return x.id }).indexOf(pdr.place_id)
        if (placeIndex >= 0) {
          placeMarker[placeIndex].pdrList.push(pdr.id)
        } else {
          placeMarker.push({ id: pdr.place_id, name: pdr.placeIdDoor, loc: pdr.homeLocation, placeIdDoor: pdr.placeIdDoor, fAddress: pdr.formatted_address, codeId: pdr.codeId, codeName: pdr.codeName, color: pdr.color, pdrList: [pdr.id], marker: null, stat: null })
        }
      })
      let zIndex = 0
      placeMarker.forEach(place => {
        zIndex++
        boundsOfRoute.extend(place.loc)
        const codeIndex = codeDetail.map(function (x) { return x.id }).indexOf(place.codeId)
        if (codeIndex < 0) {
          codeDetail.push({ id: place.codeId, name: place.codeName, color: place.color, locTot: { cpt: 1, lat: place.loc.lat, lng: place.loc.lng }, posArray: [place.loc], bounds: new google.maps.LatLngBounds() })
          codeDetail[codeDetail.length - 1].bounds.extend(place.loc)
        } else {
          codeDetail[codeIndex].bounds.extend(place.loc)
          codeDetail[codeIndex].locTot.cpt++
          codeDetail[codeIndex].locTot.lat += place.loc.lat
          codeDetail[codeIndex].locTot.lng += place.loc.lng
          codeDetail[codeIndex].posArray.push(place.loc)
        }
        const colorIndex = colorDetail.map(function (x) { return x.id }).indexOf(place.color)
        if (colorIndex < 0) {
          colorDetail.push({ id: place.color, locTot: { cpt: 1, lat: place.loc.lat, lng: place.loc.lng }, bounds: new google.maps.LatLngBounds() })
          colorDetail[colorDetail.length - 1].bounds.extend(place.loc)
        } else {
          colorDetail[colorIndex].bounds.extend(place.loc)
          colorDetail[colorIndex].locTot.cpt++
          colorDetail[colorIndex].locTot.lat += place.loc.lat
          colorDetail[colorIndex].locTot.lng += place.loc.lng
        }
        routeDetail.locTot.cpt++
        routeDetail.locTot.lat += place.loc.lat
        routeDetail.locTot.lng += place.loc.lng
        place.stat = stat.place[stat.place.map(function (x) { return x.id }).indexOf(place.id)]

        place.marker = new google.maps.Marker({
          position: place.loc,
          label: { text: place.placeIdDoor, fontSize: getLabelSize(place.placeIdDoor), color: 'white' },
          zIndex: 1000 - zIndex,
          map: null,
          icon: {
            url: getPlaceIconUrl(place),
            size: new google.maps.Size(48, 48),
            //      scaledSize: new google.maps.Size(40, 40), // scaled size
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(24, 28), // anchor
            labelOrigin: new google.maps.Point(24, 32)
          }
        })
        place.marker.addListener('click', function () {
          EventBus.$emit('placeClick', (place.id))
        })
      })
      colorDetail.forEach(color => {
        var url
        if (color.id === 1) {
          url = require('@/assets/Icon/MapIcon/orange.png')
        } else if (color.id === 2) {
          url = require('@/assets/Icon/MapIcon/pink.png')
        } else {
          url = require('@/assets/Icon/MapIcon/blue.png')
        }
        // const lat = color.locTot.lat / color.locTot.cpt
        // const lng = color.locTot.lng / color.locTot.cpt
        const marker = new google.maps.Marker({
          position: color.bounds.getCenter(),
          label: { text: routeShortName, fontSize: '14px' },
          map: null,
          title: routeName,
          icon: {
            url: url,
            scaledSize: new google.maps.Size(60, 60), // scaled size
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(30, 60), // anchor
            labelOrigin: new google.maps.Point(30, 19)
          }
        })
        marker.addListener('click', function () {
          EventBus.$emit('colorClick', (color.id))
        })
        const indexColor = stat.color.map(function (x) { return x.id }).indexOf(color.id)
        colorMarker.push({ id: color.id, marker: marker, stat: stat.color[indexColor], route: null, bounds: color.bounds.toJSON() })
      })
      codeDetail.forEach(code => {
        let colorName
        switch (code.color) {
          case 1:
            colorName = 'orange'
            break
          case 2:
            colorName = 'pink'
            break
          case 3:
            colorName = 'blue'
            break
        }
        const codeLine = new google.maps.Polyline({
          path: code.posArray,
          geodesic: true,
          strokeColor: colorName,
          strokeOpacity: 1.0,
          strokeWeight: 6
        })
        codeLine.setMap(null)

        var url
        if (code.color === 1) {
          url = require('@/assets/Icon/MapIcon/orange.png')
        } else if (code.color === 2) {
          url = require('@/assets/Icon/MapIcon/pink.png')
        } else {
          url = require('@/assets/Icon/MapIcon/blue.png')
        }
        const posIndex = Math.floor(code.posArray.length / 2)
        // const lat = code.locTot.lat / code.locTot.cpt
        // const lng = code.locTot.lng / code.locTot.cpt
        const marker = new google.maps.Marker({
          position: code.posArray[posIndex],
          label: { text: String(code.name), fontSize: '14px' },
          map: null,
          title: String(code.name),
          icon: {
            url: url,
            scaledSize: new google.maps.Size(60, 60), // scaled size
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(30, 60), // anchor
            labelOrigin: new google.maps.Point(30, 19)
          }
        })
        marker.addListener('click', function () {
          EventBus.$emit('codeClick', (code.id))
        })
        const codeIndex = stat.code.map(function (x) { return x.id }).indexOf(code.id)
        codeMarker.push({ id: code.id, name: code.name, marker: marker, stat: stat.code[codeIndex], color: code.color, line: codeLine, bounds: code.bounds.toJSON() })
      })
      function getPlaceIconUrl (place) {
        var placeType
        var placeVacant = true
        var placePub = false
        place.pdrList.forEach(pocId => {
          const pdr = state.data[pocId]
          if (!pdr.vacant) { placeVacant = false }
          if (pdr.pub) { placePub = true }
          placeType = pdr.pdrType
        })
        var topName
        var styleName
        var colorName
        if (place.pdrList.length > 1) {
          styleName = 'App'
        } else if (placeType === 'Hou/Dom') {
          styleName = 'Hou'
        } else if (placeType === 'Apt/App') {
          styleName = 'App'
        } else if (placeType === 'Bus/com') {
          styleName = 'Bus'
        } else {
          styleName = 'Farm'
        }
        if (placeVacant) {
          topName = 'green'
        } else if (placePub) {
          topName = 'black'
        } else {
          topName = 'red'
        }
        if (place.color === 1) {
          colorName = 'orange'
        } else if (place.color === 2) {
          colorName = 'pink'
        } else {
          colorName = 'blue'
        }
        return require('@/assets/Icon/MapIcon/POC/' + styleName + '-' + colorName + '-t' + topName + '.png')
      }
      function cumulStat (pdr) {
        var codeIndex = stat.code.map(function (x) { return x.id }).indexOf(pdr.codeId)
        if (codeIndex < 0) {
          stat.code.push({ id: pdr.codeId, startCodeAddress: pdr.pdrListAddress, startPos: pdr.seqPosTot, endCodeAddress: pdr.pdrListAddress, endPos: Number(pdr.seqPosTot), app: 0, appP: 0, appV: 0, hou: 0, houP: 0, houV: 0, bus: 0, busP: 0, busV: 0, farm: 0, farmP: 0, farmV: 0 })
          codeIndex = stat.code.length - 1
        } else {
          if (stat.code[codeIndex].startPos > pdr.seqPosTot) {
            stat.code[codeIndex].startPos = pdr.seqPosTot
            stat.code[codeIndex].startCodeAddress = pdr.pdrListAddress
          }
          if (stat.code[codeIndex].endPos < Number(pdr.seqPosTot)) {
            stat.code[codeIndex].endPos = Number(pdr.seqPosTot)
            stat.code[codeIndex].endCodeAddress = pdr.pdrListAddress
          }
        }
        var colorIndex = stat.color.map(function (x) { return x.id }).indexOf(pdr.color)
        if (colorIndex < 0) {
          stat.color.push({ id: pdr.color, app: 0, appP: 0, appV: 0, hou: 0, houP: 0, houV: 0, bus: 0, busP: 0, busV: 0, farm: 0, farmP: 0, farmV: 0 })
          colorIndex = stat.color.length - 1
        }
        var placeIndex = stat.place.map(function (x) { return x.id }).indexOf(pdr.place_id)
        if (placeIndex < 0) {
          stat.place.push({ id: pdr.place_id, app: 0, appP: 0, appV: 0, hou: 0, houP: 0, houV: 0, bus: 0, busP: 0, busV: 0, farm: 0, farmP: 0, farmV: 0 })
          placeIndex = stat.place.length - 1
        }
        if (pdr.pdrType === 'Hou/Dom') {
          if (pdr.vacant) {
            stat.code[codeIndex].houV++
            stat.color[colorIndex].houV++
            stat.place[placeIndex].houV++
            stat.route.houV++
          } else {
            stat.code[codeIndex].hou++
            stat.color[colorIndex].hou++
            stat.place[placeIndex].hou++
            stat.route.hou++
            if (pdr.pub) {
              stat.code[codeIndex].houP++
              stat.color[colorIndex].houP++
              stat.place[placeIndex].houP++
              stat.route.houP++
            }
          }
        } else if (pdr.pdrType === 'Apt/App') {
          if (pdr.vacant) {
            stat.code[codeIndex].appV++
            stat.color[colorIndex].appV++
            stat.place[placeIndex].appV++
            stat.route.appV++
          } else {
            stat.code[codeIndex].app++
            stat.color[colorIndex].app++
            stat.place[placeIndex].app++
            stat.route.app++
            if (pdr.pub) {
              stat.code[codeIndex].appP++
              stat.color[colorIndex].appP++
              stat.place[placeIndex].appP++
              stat.route.appP++
            }
          }
        } else if (pdr.pdrType === 'Bus/com') {
          if (pdr.vacant) {
            stat.code[codeIndex].busV++
            stat.color[colorIndex].busV++
            stat.place[placeIndex].busV++
            stat.route.busV++
          } else {
            stat.code[codeIndex].bus++
            stat.color[colorIndex].bus++
            stat.place[placeIndex].bus++
            stat.route.bus++
            if (pdr.pub) {
              stat.code[codeIndex].busP++
              stat.color[colorIndex].busP++
              stat.place[placeIndex].busP++
              stat.route.busP++
            }
          }
        } else {
          if (pdr.vacant) {
            stat.code[codeIndex].farmV++
            stat.color[colorIndex].farmV++
            stat.place[placeIndex].farmV++
            stat.route.farmV++
          } else {
            stat.code[codeIndex].farm++
            stat.color[colorIndex].farm++
            stat.place[placeIndex].farm++
            stat.route.farm++
            if (pdr.pub) {
              stat.code[codeIndex].farmP++
              stat.color[colorIndex].farmP++
              stat.place[placeIndex].farmP++
              stat.route.farmP++
            }
          }
        }
      }
      function getLabelSize (label) {
        var labelsize
        switch (label.length) {
          case 1:
            labelsize = '18px'
            break
          case 2:
            labelsize = '16px'
            break
          case 3:
            labelsize = '14px'
            break
          case 4:
            labelsize = '12px'
            break
          case 5:
            labelsize = '10px'
            break
          case 6:
            labelsize = '8px'
            break
          default:
            labelsize = '6px'
            break
        }
        return labelsize
      }
      const routePos = { routeId: routeId, sequenceId: sequenceId, pos: { lat: routeDetail.locTot.lat / routeDetail.locTot.cpt, lng: routeDetail.locTot.lng / routeDetail.locTot.cpt } }
      // const sw = { lat: boundsOfRoute.getSouthWest().lat(), lng: boundsOfRoute.getSouthWest().lng() }
      // const ne = { lat: boundsOfRoute.getNorthEast().lat(), lng: boundsOfRoute.getNorthEast().lng() }
      return { placeMarker: placeMarker, codeMarker: codeMarker, colorMarker: colorMarker, routePos: routePos, sequenceStat: stat.route, isOfficialSequence: isOfficialSeq, bounds: boundsOfRoute.toJSON() }
    },
    getPocOfRouteNotInArray: (state) => (routeId, pocIdArray) => {
      const noMatchArray = []
      for (const pocId in state.data) {
        if (state.data[pocId].route.indexOf(routeId) >= 0) {
          if (pocIdArray.indexOf(pocId) < 0) {
            noMatchArray.push(pocId)
          }
        }
      }
      return noMatchArray
    },
    getNewPocMarker: (state) => (pdrId, google) => {
      const pdr = state.data[pdrId]
      const pos = pdr.homeLocation
      var styleName
      var colorName
      var topName
      var labelsize
      function getCleanDoor (door) {
        if (door.split('-').length > 1) {
          return Number(door.split('-')[1].match(/\d+/g).map(Number))
        } else {
          return Number(door.match(/\d+/g).map(Number))
        }
      }
      const placeIdDoor = getCleanDoor(pdr.pdrListAddress.split(' ')[0])

      switch (placeIdDoor) {
        case 1:
          labelsize = '18px'
          break
        case 2:
          labelsize = '16px'
          break
        case 3:
          labelsize = '14px'
          break
        case 4:
          labelsize = '14px'
          break
        case 5:
          labelsize = '12px'
          break
        case 6:
          labelsize = '12px'
          break
        default:
          labelsize = '10px'
          break
      }

      if (pdr.pdrType === 'Hou/Dom') {
        styleName = 'Hou'
      } else if (pdr.pdrType === 'Apt/App') {
        styleName = 'App'
      } else if (pdr.pdrType === 'Bus/com') {
        styleName = 'Bus'
      } else {
        styleName = 'Farm'
      }
      if (pdr.vacant) {
        topName = 'green'
      } else if (pdr.pub) {
        topName = 'red'
      } else {
        topName = 'yellow'
      }
      if (pdr.color === 1) {
        colorName = 'orange'
      } else if (pdr.color === 2) {
        colorName = 'pink'
      } else {
        colorName = 'blue'
      }

      const url = require('@/assets/Icon/MapIcon/POC/' + styleName + '-' + colorName + '-t' + topName + '.png')
      const marker = new google.maps.Marker({
        position: pos,
        label: { text: String(placeIdDoor), fontSize: labelsize, color: 'white' },
        map: null,
        icon: {
          url: url,
          size: new google.maps.Size(48, 48),
          //      scaledSize: new google.maps.Size(40, 40), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(24, 28), // anchor
          labelOrigin: new google.maps.Point(24, 32)
        }
      })
      return marker
    },
    getPdrReplaceImgUrl: (state) => (pocId) => {
      const poc = state.data[pocId]
      if (poc.homeImageListSelect) {
        if (poc.homeImageList) {
          return poc.homeImageList.filter(img => img.isPrimary).map(function (x) { return x.url })[0]
        } else { return null }
      } else { return null }
    },
    getPlacePictureHomeSrc: (state, getters, rootState) => (info) => {
      // info = { placeId: this.pdrId, width: '200', height: '400' }
      var pLaceLocation = null
      var placePdrId = null
      var countPdr = 0
      for (const pdrId in state.data) {
        const pdr = state.data[pdrId]
        if (pdr.place_id === info.placeId) {
          pLaceLocation = pdr.homeLocation
          placePdrId = pdr.id
          if (countPdr) { break }
          countPdr++
        }
      }
      if (countPdr) {
        return getters.getPdrPictureHomeSrc({ pdrId: placePdrId, width: info.width, height: info.height })
      } else if (pLaceLocation) {
        return 'https://maps.googleapis.com/maps/api/streetview?size=' + info.width + 'x' + info.height + '&location=' + pLaceLocation.lat + ',' + pLaceLocation.lng + '&key=' + rootState.API_KEY
      } else {
        return ''
      }
    },
    getPdrPictureHomeSrc: (state, getters, rootState) => (info) => {
      // info = { pdrId: this.pdrId, width: '200', height: '400' }
      const pdr = state.data[info.pdrId]
      const altPic = getters.getPdrReplaceImgUrl(info.pdrId)
      if (altPic && pdr.homeImageListSelect) {
        return altPic
      } else {
        return 'https://maps.googleapis.com/maps/api/streetview?size=' + info.width + 'x' + info.height + '&location=' + pdr.homeLocation.lat + ',' + pdr.homeLocation.lng + '&key=' + rootState.API_KEY
      }
    },
    getCurrentUserId: (state, getters, rootState) => {
      return rootState.userId
    }
  },
  mutations: {
    deletePocItem (state, payload) {
      const mapItems = state.data[payload.pocId].mapItems
      const itemIdx = mapItems.map(x => { return x.id }).indexOf(payload.itemId)
      if (itemIdx < 0) {
        return null
      } else {
        mapItems.splice(itemIdx, 1)
        return this.dispatch('pocModule/set', {
          id: payload.pocId,
          mapItems: mapItems
        })
      }
    },
    setSoloPocItemImageList (state, payload) {
      // payload = { itemId: , PocId: , imageList: , successMess: }
      const mapItems = state.data[payload.pocId].mapItems
      var self = this
      this.dispatch('logModule/set', { type: 'updatePicturePocItem', pocId: payload.pocId })
      const index = mapItems.map(function (x) { return x.id }).indexOf(payload.itemId)
      if (index > -1) {
        mapItems[index].imageList = payload.imageList
        this.dispatch('pocModule/set', { id: payload.pocId, mapItems: mapItems })
          .then(function () {
            if (payload.successMess) { self.dispatch('showMessage', payload.successMess) }
          }).catch(function (error) {
            self.dispatch('showAlert', error.message)
          })
      }
    },
    setSoloPocItem (state, payload) {
      const mapItems = state.data[payload.pocId].mapItems
      const item = {
        id: payload.id !== undefined ? payload.id : null,
        loc: payload.pos !== undefined ? payload.pos : null,
        name: payload.name !== undefined ? payload.name : null,
        desc: payload.desc !== undefined ? payload.desc : null,
        type: payload.type !== undefined ? payload.type : null,
        pocIdList: [],
        isActive: payload.isActive ? payload.isActive : false
      }
      if (payload.id !== undefined) {
        item.id = payload.id
        const index = mapItems.map(function (x) { return x.id }).indexOf(item.id)
        const updateLog = { type: 'updatePocItem', pocId: payload.pocId, pocItemId: item.id }
        // // console.log(updateLog)
        this.dispatch('logModule/insert', updateLog)
        if (payload.id === 0 && payload.type !== mapItems[index].type) {
          this.dispatch('logModule/set', { type: 'changePocItemPrimaryType', pocId: payload.pocId, multiId: payload.multiId, oldType: mapItems[0].type, newType: payload.type })
          this.dispatch('mailModule/sendNoticeMail', { type: 'pocItemTypeUpdate', pocId: payload.pocId, itemId: 0, oldType: mapItems[0].type, newType: payload.type })
        }
        mapItems[index] = item
      } else {
        item.created_by = payload.userId
        item.created_at = new Date()
        item.updated_by = payload.userId
        item.updated_at = new Date()
        item.id = mapItems.length
        mapItems.push(item)
      }
      this.dispatch('pocModule/set', { id: payload.pocId, mapItems: mapItems })
    },
    createPocItem (state, payload) {
      // { pocId: '', type: '', officeItemMultiId: 'optional' }
      const itemId = state.data[payload.pocId].mapItems.length
      state.data[payload.pocId].mapItems.push({
        id: itemId,
        loc: null,
        name: null,
        desc: null,
        officeItemMultiId: payload.officeItemMultiId ? payload.officeItemMultiId : null,
        type: payload.type,
        imageList: [],
        isActive: true,
        created_by: payload.userId,
        created_at: new Date(),
        updated_by: payload.userId,
        updated_at: new Date()
      })
      return this.dispatch('pocModule/set', { id: payload.pocId, mapItems: state.data[payload.pocId].mapItems })
        .then(() => { return itemId })
    },
    updatePocItem (state, payload) {
      const prom = []
      const itemIndex = state.data[payload.pocId].mapItems.map(x => { return Number(x.id) }).indexOf(Number(payload.itemId))
      const item = state.data[payload.pocId].mapItems[itemIndex]
      const trace = {}
      if (payload.pos !== undefined) { item.loc = payload.pos; trace.loc = payload.pos }
      if (payload.name !== undefined) { item.name = payload.name; trace.name = payload.name }
      if (payload.type !== undefined) { item.type = payload.type; trace.type = payload.type }
      if (payload.desc !== undefined) { item.desc = payload.desc; trace.desc = payload.desc }
      if (payload.isActive !== undefined) { item.isActive = payload.isActive; trace.isActive = payload.isActive }
      if (payload.officeItemMultiId !== undefined) { item.officeItemMultiId = payload.officeItemMultiId; trace.officeItemMultiId = payload.officeItemMultiId }
      state.data[payload.pocId].mapItems[itemIndex] = item
      prom.push(this.dispatch('pocModule/set', { id: payload.pocId, mapItems: state.data[payload.pocId].mapItems }))
      prom.push(this.dispatch('logModule/insert', { type: 'updatePocItem', pocId: payload.pocId, itemId: payload.itemId, trace: trace }))
      return Promise.all(prom)
    },
    removePocItemMultiRef (state, payload) {
      const itemIndex = state.data[payload.pocId].mapItems.mapItems.map(x => { return x.officeItemMultiId }).indexOf(payload.officeItemMultiId)
      state.data[payload.pocId].mapItems[itemIndex].isActive = false
      return this.dispatch('pocModule/set', { id: payload.pocId, mapItems: state.data[payload.pocId].mapItems })
    },
    setVacant (state, payload) {
      const poc = state.data[payload.pocId]
      poc.vacant = payload.vacant
      this.dispatch('pocModule/set', { id: payload.pocId, vacant: payload.vacant })
        .then(() => {
          this.dispatch('logModule/set', { type: 'pocVacantUpdate', pocId: payload.pocId, vacant: payload.vacant })
          this.dispatch('mailModule/sendNoticeMail', { type: 'pocVacantUpdate', pocId: payload.pocId, vacant: payload.vacant })
          this.dispatch('sendMessage', {
            type: 'vacantUpdate',
            vacant: payload.vacant,
            officeId: poc.officeId,
            date: poc.created_at,
            routeIdArray: poc.route,
            address: poc.pdrListAddress,
            postalCode: poc.pdrListPostalCode
          })
          poc.route.forEach(routeId => {
            this.dispatch('taskRouteModule/set', { type: 'pocVacantUpdate', routeId: routeId, pocId: payload.pocId, vacant: payload.vacant })
            this.dispatch('taskOfficeModule/set', { type: 'pocVacantUpdate', routeId: routeId, pocId: payload.pocId, vacant: payload.vacant })
          })
        })
    },
    setPub (state, payload) {
      const poc = state.data[payload.pocId]
      poc.pub = payload.pub
      this.dispatch('pocModule/set', { id: payload.pocId, pub: payload.pub })
        .then(() => {
          this.dispatch('logModule/set', { type: 'changePocPub', pocId: payload.pocId, pub: payload.pub })
          this.dispatch('mailModule/sendNoticeMail', { type: 'pocPubUpdate', pocId: payload.pocId, pub: payload.pub })
          poc.route.forEach(routeId => {
            this.dispatch('taskRouteModule/set', { type: 'pocPubUpdate', routeId: routeId, pocId: payload.pocId, pub: payload.pub })
            this.dispatch('taskOfficeModule/set', { type: 'pocPubUpdate', routeId: routeId, pocId: payload.pocId, pub: payload.pub })
          })
          this.dispatch('sendMessage', {
            type: 'pubUpdate',
            pub: payload.pub,
            officeId: poc.officeId,
            vacant: poc.vacant,
            routeIdArray: poc.route,
            address: poc.pdrListAddress,
            postalCode: poc.pdrListPostalCode
          })
        })
    },
    setHomeImageListSelect (state, payload) {
      state.data[payload.pocId].homeImageListSelect = payload.homeImageListSelect
      this.dispatch('pocModule/set', { id: payload.pocId, homeImageListSelect: payload.homeImageListSelect })
        .then(() => {
          this.dispatch('logModule/set', { type: 'changePocPictureSource', pocId: payload.pocId, homeImageListSelect: payload.homeImageListSelect })
        })
    },
    setHomeLocation (state, payload) {
      state.data[payload.pocId].homeLocation = payload.homeLocation
      this.dispatch('pocModule/set', { id: payload.pocId, homeLocation: payload.homeLocation })
        .then(() => {
          this.dispatch('logModule/set', { type: 'changePocHomeLocation', pocId: payload.pocId, homeLocation: payload.homeLocation })
        })
    },
    setMapItemPos (state, payload) {
      const poc = state.data[payload.pocId]
      if (payload.itemId) {
        const mapItems = poc.mapItems
        const index = mapItems.map(function (x) { return x.id }).indexOf(payload.itemId)
        mapItems[index].loc = payload.pos
        this.dispatch('pocModule/set', {
          id: payload.pocId,
          mapItems: mapItems
        })
      } else {
        this.dispatch('pocModule/set', {
          id: payload.pocId,
          tpLocation: payload.pos
        })
      }
    },
    addpictureUrlReady (state, payload) {
      state.imgUrl.push(payload)
    },
    deletePdrReplacedPictureReady (state, payload) {
      for (let i = 0; i < state.imgUrl.length; i++) {
        const pdrImg = state.imgUrl[i]
        if (pdrImg.id === payload) {
          state.imgUrl.splice(i, 1)
          state.importPictureInfo = null
          this.dispatch('pocModule/set', {
            id: payload,
            importPictureInfo: null
          })
          break
        }
      }
    },
    setPlaceId (state, payload) {
      state.data[payload.pocId].placeId = payload.placeId
    }
  },
  actions: {
    patchTemp ({ commit, state, getters, rootState, rootGetters }) {
      // rootGetters['officeSelectedModule/getOfficeGreyBoxMarker'].forEach(marker => {
      //   marker.id = null
      //   this.dispatch('officeItemModule/set', marker)
      // })
      // rootState.officeSelectedModule.data.toilet.forEach(toilet => {
      //   this.dispatch('officeItemModule/set', {
      //     type: 'toiletMarker',
      //     description: toilet.description,
      //     position: { lat: toilet.location.latitude, lng: toilet.location.longitude }
      //   })
      // })
    },
    patchTempBack ({ commit, state, getters, rootState, rootGetters }) {
      function getCleanDoor (address) {
        const door = address.split(' ')[0]
        if (door.split('-').length > 1) {
          return Number(door.split('-')[1].match(/\d+/g).map(Number))
        } else {
          return Number(door.match(/\d+/g).map(Number))
        }
      }
      function getCleanAddress (address) {
        const door = address.split(' ')[0]
        if (door.split('-').length > 1) {
          return address.replace(door, Number(door.split('-')[1].match(/\d+/g).map(Number)))
        } else {
          return address.replace(door, Number(door.match(/\d+/g).map(Number)))
        }
      }
      // let cpt = 0
      const test = false
      if (test) { getCleanDoor('test') }
      if (test) { getCleanAddress('test') }
      const prom = []
      Object.values(rootState.placeModule.data).forEach(place => {
        const patche = {
          id: place.id,
          route: [rootGetters.getSelectedRouteId]
        }
        prom.push(this.dispatch('placeModule/set', patche))
        // prom.push(this.dispatch('pocModule/delete', place.id))
      })
      /*
        // create place
        for (const pocId in state.data) {
          const poc = state.data[pocId]
          const sName = getCleanDoor(poc.pdrListAddress)
          const lName = getCleanAddress(poc.pdrListAddress)
          if (!poc.placeId) {
            if (poc.pdrType === 'Apt/App') {
              const pocList = []
              Object.values(state.data).forEach(pdr => {
                if (poc.place_id === pdr.place_id) {
                  pocList.push(pdr.id)
                }
              })
              if (pocList.length) {
                prom.push(this.dispatch('placeModule/createPlace', {
                  lName: lName,
                  sName: sName,
                  homeLocation: poc.homeLocation,
                  ggPlaceId: poc.place_id,
                  pocList: pocList,
                  imageList: []
                }))
               // // console.log('place to create = ' + poc.pdrListAddress + '  apt(' + pocList.length + ')')
              }
            }
          }
          // if (poc.report) {
          //   if (poc.report.length) {
          //     prom.push(this.dispatch('pocModule/set', { id: poc.id, report: [] }))
          //   }
          // }
        }
      */
      return Promise.all(prom)
      //   if (!poc.mapItems[0].type) {
      //   // if (poc.tpType !== poc.tpType.toLowerCase() || !poc.mapItems) {
      //     cpt++
      //     this.dispatch('pocModule/set', { id: pocId, mapItems: [{ id: 0, type: poc.tpType, loc: null, isActive: true }] })
      //     // this.dispatch('pocModule/set', { id: poc.id, tpType: poc.tpType.toLowerCase() })
      //   }
    },
    setPlaceId ({ commit }, payload) {
      return commit('setPlaceId', payload)
    },
    updateVacant ({ commit, state }, payload) {
      if (payload.vacant !== state.data[payload.pocId].vacant) {
        commit('setVacant', payload)
      }
    },
    updatePub ({ commit, state }, payload) {
      if (payload.pub !== state.data[payload.pocId].pub) {
        commit('setPub', payload)
      }
    },
    updateHomeLocation ({ commit }, payload) {
      commit('setHomeLocation', payload)
    },
    updateHomeImageListSelect ({ commit, state }, payload) {
      if (payload.homeImageListSelect !== state.data[payload.pocId].homeImageListSelect) {
        commit('setHomeImageListSelect', payload)
      }
    },
    removeMultiItemRef ({ commit, state }, payload) {
      const mapItems = state.data[payload.pocId].mapItems
      let toSave = false
      for (let i = 0; i < mapItems.length; i++) {
        const item = mapItems[i]
        if (item.officeItemMultiId === payload.multiId) {
          mapItems[i].officeItemMultiId = null
          if (item.id) {
            mapItems[i].isActive = false
          }
          toSave = true
        }
      }
      if (toSave) {
        this.dispatch('pocModule/set', { id: payload.pocId, mapItems: mapItems })
      }
    },
    addMultiItemRef ({ commit, state }, payload) {
      const mapItems = state.data[payload.pocId].mapItems
      let toSave = true
      if (payload.isPrimary) {
        mapItems[0].officeItemMultiId = payload.multiId
        if (payload.type !== mapItems[0].type) {
          this.dispatch('logModule/set', { type: 'changePocItemPrimaryType', pocId: payload.pocId, multiId: payload.multiId, oldType: mapItems[0].type, newType: payload.type })
          this.dispatch('mailModule/sendNoticeMail', { type: 'pocItemTypeUpdate', pocId: payload.pocId, itemId: 0, oldType: mapItems[0].type, newType: payload.type })
        }
      } else {
        for (let i = 0; i < mapItems.length; i++) {
          const item = mapItems[i]
          if (item.officeItemMultiId === payload.multiId) {
            toSave = false
          }
        }
        if (toSave) {
          mapItems.push({ id: mapItems.length, officeItemMultiId: payload.multiId, isActive: true })
        }
      }
      if (toSave) {
        this.dispatch('pocModule/set', { id: payload.pocId, mapItems: mapItems })
      }
    },
    updateMapItemPos ({ commit }, payload) {
      commit('setMapItemPos', payload)
    },
    saveSoloPocItemImageList ({ commit, getters, rootGetters }, payload) {
      commit('setSoloPocItemImageList', payload)
    },
    createPocItem ({ commit, getters, rootGetters }, payload) {
      payload.userId = getters.getCurrentUserId
      return commit('createPocItem', payload)
    },
    updatePocItem ({ commit, getters, rootGetters }, payload) {
      return commit('updatePocItem', payload)
    },
    removePocItemMultiRef ({ commit, getters, rootGetters }, payload) {
      return commit('removePocItemMultiRef', payload)
    },
    savePocItem ({ commit, getters, rootGetters }, payload) {
      payload.userId = getters.getCurrentUserId
      if (rootGetters.isSoloPocMapItemType(payload.type)) {
        commit('setSoloPocItem', payload)
        this.dispatch('logModule/set', { type: 'updatePocItem', pocId: payload.pocId, itemId: payload.id })
      } else {
        this.dispatch('officeItemMultiModule/savePocItem', payload)
      }
    },
    onUpdatePdr ({ commit, getters }, payload) {
      const pdr = getters.getPdr(payload.id)
      var newPdrValue
      if (payload.note) {
        if (pdr.note) {
          newPdrValue = {
            id: payload.id,
            pub: payload.pub,
            vacant: payload.vacant,
            note: arrayUnion(payload.note)
          }
        } else {
          newPdrValue = {
            id: payload.id,
            pub: payload.pub,
            vacant: payload.vacant,
            note: [payload.note]
          }
        }
      } else {
        newPdrValue = {
          id: payload.id,
          pub: payload.pub,
          vacant: payload.vacant
        }
      }
      if (payload.newPosition) {
        this.dispatch('updatePdrGeocoderFromPosition', { id: payload.id, pos: payload.newPosition, placeId: pdr.place_id })
      }
      if (payload.importPicture) {
        const storefilename = payload.id + '.jpg'
        const storepath = 'poc/' + pdr.officeId + '/'
        firebase.storage().ref(storepath).child(storefilename).putString(payload.importPicture.substring(23), 'base64', { contentType: 'image/jpg' })
          .then(fileData => {
            const imageUrl = fileData.metadata.fullPath
            this.dispatch('pocModule/set', {
              id: payload.id,
              importPictureInfo: { fileName: storefilename, path: storepath }
            })
            firebase.storage()
              .ref(imageUrl)
              .getDownloadURL()
              .then(img => {
                commit('addpictureUrlReady', { id: pdr.id, img: img })
              })
          })
          .catch((err) => {
            this.dispatch('showAlert', err.message)
          })
      }

      this.dispatch('pocModule/set', newPdrValue)
        .then((pdrId) => {
          this.dispatch('showMessage', payload.successMessage)
        })
        .catch((err) => {
          this.dispatch('showAlert', err.message)
        })
    },
    deletePdrReplacedPicture ({ commit, getters }, payload) {
      const pdr = getters.getPdr(payload)
      if (pdr.importPictureInfo) {
        firebase.storage().ref(pdr.importPictureInfo.path).child(pdr.importPictureInfo.fileName).delete()
        commit('deletePdrReplacedPictureReady', payload)
      }
    },
    deletePocItem ({ commit }, payload) {
      return commit('deletePocItem', payload)
    }
  }
}

export default pocModule
