<template fixed>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen persistent fixed transition="dialog-bottom-transition">
      <v-card class="overflow-hidden">
        <v-app-bar dark :color="flashColor" :height="$store.getters.appBarHeight">
          <v-icon :size="$store.getters.appBarIconSize - 15">{{ icon }}</v-icon>
          <v-spacer></v-spacer>
          <p class="title mt-2" align="center">{{ $t('pmtgardianeventpopup.title') }}</p>
          <v-spacer></v-spacer>
          <v-icon :size="$store.getters.appBarIconSize - 15">{{ icon }}</v-icon>
        </v-app-bar>
        <v-card
          flat
          justify="center"
          align="center"
          :width="$store.getters.screenSize.width"
          :height="$store.getters.screenSize.height - (2 * $store.getters.appBarHeight)"
        >
          <v-card
            v-if="alert"
            justify="center"
            align="center"
            max-width="444"
            class="overflow-y-auto pb-6"
            :max-height="$store.getters.screenSize.height - (2 * $store.getters.appBarHeight)">

            <v-btn class="mt-2" @click="closeAlert" x-large color="green">ok</v-btn>
            <v-container v-show="alert.note">
              <p class="title">Description :</p>
              <p class="mt-n2" v-for="(note, i) in alert.note" :key="i">{{ note }}</p>
            </v-container>
            <v-container>
              <v-carousel
                v-if="alert.imageList.length"
                :show-arrows="alert.imageList.length > 1"
                hide-delimiters
                height="200"
              >
                <v-carousel-item
                  contain
                  max-height="300"
                  v-for="(item,i) in alert.imageList"
                  :key="i"
                  :src="item.url"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                ></v-carousel-item>
              </v-carousel>
            </v-container>
            <v-container v-show="alert.owner && $store.state.pocModule.data[alert.owner]">
              <p class="title">{{ $t('common.owner') }} :</p>
              <p class="mt-n2">{{ $store.state.pocModule.data[alert.owner].pdrListAddress }}</p>
            </v-container>
            <v-container v-show="pocBlockedArray">
              <p class="title">{{ $t('createreportdog.blockpdrtitle') }} :</p>
              <p class="mt-n2" v-for="(poc, i) in pocBlockedArray" :key="i">
                {{ $store.state.pocModule.data[poc.pdrId].pdrListAddress }}
              </p>
            </v-container>
            <v-btn @click="closeAlert" x-large color="green">ok</v-btn>
          </v-card>
        </v-card>
      <v-app-bar dark :color="flashColor" bottom :height="$store.getters.appBarHeight">
        <v-icon :size="$store.getters.appBarIconSize - 15">{{ icon }}</v-icon>
        <v-spacer></v-spacer>
        <v-icon :size="$store.getters.appBarIconSize - 10">mdi-alert</v-icon>
        <v-spacer></v-spacer>
        <v-icon :size="$store.getters.appBarIconSize - 15">{{ icon }}</v-icon>
      </v-app-bar>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  props: ['title'],
  data () {
    return {
      dialog: false,
      showDogInfo: false,
      notifications: false,
      sound: true,
      icon: 'mdi-alert',
      widgets: false,
      flashColor: 'red',
      alertDone: [],
      multiPicture: false,
      pictureSrc: '',
      lastAlert: null,
      pictureList: []
    }
  },
  watch: {
    dialog (newValue) {
      if (newValue) {
        this.flashChange()
      } else {
        this.$store.dispatch('resize')
      }
    },
    // guardianActive (newValue) {
    //   if (newValue) {
    //     this.refreshPosition()
    //   }
    // },
    alertToShow (newValue) {
      if (newValue) {
        this.dialog = true
      } else {
        this.dialog = false
      }
    },
    alert (newValue) {
      if (newValue) {
        this.$store.dispatch('startVibBigAlert')
        this.showDogInfo = false
        if (newValue !== this.lastAlert) {
          this.lastAlert = newValue
          // this.pictureList = []
          this.setAlertInfo()
          // this.setPicture()
        }
      } else {
        this.$store.dispatch('stopVibBigAlert')
      }
    },
    minDist (newValue) {
      // // console.log('trace 1')
      // this.$store.getters.EventBus.$emit('minDist', newValue)
    }
  },
  computed: {
    guardianMode () {
      return this.$store.getters.getGuardianMode
    },
    guardianActive () {
      return this.$store.getters.getGuardianMode.active
    },
    dangerActive () {
      if (this.guardianActive) {
        return this.$store.getters.getGuardianMode.dangerActive
      } else {
        return false
      }
    },
    noteActive () {
      if (this.guardianActive) {
        return this.$store.getters.getGuardianMode.noteActive
      } else {
        return false
      }
    },
    alert () {
      if (this.alertToShow && this.dangerActive) {
        return this.$store.state.reportModule.data[this.alertToShow.id]
      } else {
        return null
      }
    },
    alertToShow () {
      if (this.alertActiveList) {
        if (this.alertActiveList.length > 1) {
          var urgent = null
          for (let i = 0; i < this.alertActiveList.length; i++) {
            const alert = this.alertActiveList[i]
            let show = true
            if (this.alertDone.length) {
              for (let i = 0; i < this.alertDone.length; i++) {
                if (alert.id === this.alertDone[i]) {
                  show = false
                }
              }
            }
            if (show) {
              if (!urgent) {
                urgent = alert
              } else if (alert.dist < urgent.dist) {
                urgent = alert
              }
            }
          }
          return urgent
        } else {
          const alert = this.alertActiveList[0]
          let show = true
          if (this.alertDone.length) {
            for (let i = 0; i < this.alertDone.length; i++) {
              if (alert.id === this.alertDone[i]) {
                show = false
              }
            }
          }
          if (show) {
            return this.alertActiveList[0]
          } else {
            return null
          }
        }
      } else {
        return null
      }
    },
    pocBlockedArray () {
      if (this.alert && this.alert.pocList.length) {
        return this.alert.pocList.filter(x => x.isBlocked)
      } else {
        return null
      }
    },
    myPosition () {
      return this.$store.getters.getCurrentPosition
    },
    alertActive () {
      if (this.dangerActive) {
        return this.$store.getters['reportModule/getAlert'](this.$store.getters.getCurrentPosition)
      } else {
        return []
      }
    },
    alertActiveList () {
      return this.alertActive.list
    },
    minDist () {
      return this.alertActive.minDist
    },
    nbrAlert () {
      return this.alertActiveList.length
    },
    alertPicture () {
      return null
    }
  },
  methods: {
    setAlertInfo () {
      if (this.alert.type === 'dog') {
        this.icon = 'mdi-dog-side'
        this.showDogInfo = true
      } else if (this.alert.type === 'brokenstep') {
        this.icon = 'mdi-stairs-up'
      } else if (this.alert.type === 'ice') {
        this.icon = 'mdi-snowflake-alert'
      } else {
        this.icon = 'mdi-alert'
      }
    },
    flashChange () {
      if (this.flashColor === 'red') {
        this.flashColor = 'black'
      } else {
        this.flashColor = 'red'
      }
      if (this.dialog) {
        setTimeout(function () { this.flashChange() }.bind(this), 1000)
      }
    },
    // refreshPosition () {
    //   this.$store.dispatch('refreshCurrentPosition')
    //   if (this.guardianMode.active) {
    //     var time = 5000
    //     if (this.minDist < 200) {
    //       time = 1000
    //     }
    //     setTimeout(function () { this.refreshPosition() }.bind(this), time)
    //   }
    // },
    closeAlert () {
      this.alertDone.push(this.alert.id)
    }
  },
  mounted () {
    this.flashChange()
    // this.refreshPosition()
    if (this.alert) {
      this.dialog = true
      // this.pictureList = []
      this.setAlertInfo()
      // this.setPicture()
    }
  }
}
</script>
