<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="444" fixed transition="dialog-bottom-transition">
      <v-card>
        <v-app-bar dark color="primary">
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ $t('importPicture.title') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon x-large dark @click="dialog = false">
            <v-icon color="green" x-large>mdi-check-circle</v-icon>
          </v-btn>
        </v-app-bar>
        <div id="app">
          <file-pond
            name="file"
            ref="input"
            :maxFiles="max || 1"
            :allowMultiple="allowMultiple"
            allowFileTypeValidation="true"
            allowImageCrop="true"
            imageResizeTargetWidth=450
            imageResizeTargetHeight=300
            allowImageEdit="true"
            :imageCropAspectRatio="'3:2'"
            :server="{  process, revert,  restore, load, fetch }"
            :label-idle="$t('importPicture.labelIdle')"
            v-bind:allow-multiple="true"
            :accepted-file-types="type ? type : 'image/jpeg, image/png'"
            v-bind:files="files2"
            v-on:init="handleFilePondInit"
            :fileRenameFunction="(file) => { return generateKey(file) }"
            :labelTapToCancel="$t('importPicture.labelTapToCancel')"
            :labelButtonAbortItemLoad="$t('importPicture.labelButtonAbortItemLoad')"
            :labelButtonRemoveItem="$t('importPicture.labelButtonRemoveItem')"
            :labelButtonRetryItemLoad="$t('importPicture.labelButtonRetryItemLoad')"
            :labelButtonRetryItemProcessing="$t('importPicture.labelButtonRetryItemProcessing')"
            :labelButtonUndoItemProcessing="$t('importPicture.labelButtonUndoItemProcessing')"
            :labelFileAdded="$t('importPicture.labelFileAdded')"
            :labelFileCountPlural="$t('importPicture.labelFileAdded')"
            :labelFileCountSingular="$t('importPicture.labelFileCountSingular')"
            :labelFileLoadError="$t('importPicture.labelFileLoadError')"
            :labelFileLoading="$t('importPicture.labelFileLoading')"
            :labelFileProcessing="$t('importPicture.labelFileProcessing')"
            :labelFileProcessingAborted="$t('importPicture.labelFileProcessingAborted')"
            :labelFileProcessingComplete="$t('importPicture.labelFileProcessingComplete')"
            :labelFileProcessingError="$t('importPicture.labelFileProcessingError')"
            :labelFileRemoved="$t('importPicture.labelFileRemoved')"
            :labelFileSizeNotAvailable="$t('importPicture.labelFileSizeNotAvailable')"
            :labelFileWaitingForSize="$t('importPicture.labelFileWaitingForSize')"
            :labelTapToRetry="$t('importPicture.labelTapToRetry')"
            :labelTapToUndo="$t('importPicture.labelTapToUndo')"
            :labelButtonAbortItemProcessing="$t('importPicture.labelButtonAbortItemProcessing')"
            :labelFileRemoveError="$t('importPicture.labelFileRemoveError')"
            :labelFileProcessingRevertError="$t('importPicture.labelFileProcessingRevertError')"
            :labelInvalidField="$t('importPicture.labelInvalidField')"
            />
        </div>
      </v-card>
      <picture-editor v-if="showPictureEditor" pictureUrl="pictureToEdit"></picture-editor>
    </v-dialog>
  </v-row>
</template>

<script>
// Import Vue FilePond
import firebase from 'firebase/app'
import 'firebase/storage'
import vueFilePond from 'vue-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import FilePond plugins
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css'

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageEdit from 'filepond-plugin-image-edit'
import FilePondPluginFileRename from 'filepond-plugin-file-rename'
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'
import FilePondPluginImageResize from 'filepond-plugin-image-resize'
// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginImageEdit, FilePondPluginImageExifOrientation, FilePondPluginFileRename, FilePondPluginImageCrop, FilePondPluginImageTransform, FilePondPluginImageResize)

export default {
  name: 'file-input',
  props: ['required', 'max', 'type', 'pictureBasePath', 'pictureArray'],
  data: function () {
    return {
      first: true,
      files: [], // files uploaded, input
      files2: this.pictureArray ? this.pictureArray : [], // all files
      pictureObjArray: [],
      dialog: true,
      showPictureEditor: false,
      pictureToEdit: null
    }
  },
  computed: {
    allowMultiple () {
      return this.max !== 1
    },
    rule () {
      return this.required && !this.first && this.files.length === 0
    }
  },
  methods: {
    generateKey (file) {
      var d = new Date().getTime()
      var d2 = (performance && performance.now && (performance.now() * 1000)) || 0
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16
        if (d > 0) {
          r = (d + r) % 16 | 0
          d = Math.floor(d / 16)
        } else {
          r = (d2 + r) % 16 | 0
          d2 = Math.floor(d2 / 16)
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
      }) + `${file.extension}`
    },
    process (fieldName, file, metadata, load, error, progress, abort) {
      var self = this
      try {
        progress(true, 0, 1024)
        var uploadTask = firebase.storage().ref().child(self.pictureBasePath + file.name).put(file)
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
          function (snapshot) { progress(true, snapshot.bytesTransferred, snapshot.totalBytes) },
          function (e) { self.handleError(error, e) },
          function () {
            uploadTask.snapshot.ref.getDownloadURL()
              .then((url) => {
                self.pictureObjArray.push({ url: url, fullPath: uploadTask.snapshot.ref.fullPath })
              })
            load(uploadTask.snapshot.ref.fullPath)
            self.files.push(uploadTask.snapshot.ref.fullPath)
          }
        )
        return {
          abort: () => {
            abort()
            uploadTask.cancel()
          }
        }
      } catch (e) {
        this.handleError(error, e)
        return {
          abort: () => { abort() }
        }
      }
    },
    revert (uniqueFileId, load, error) {
      var self = this
      // Create a reference to the file to delete
      var desertRef = firebase.storage().ref().child(uniqueFileId)
      desertRef.delete().then(function () {
        var index = self.files.indexOf(uniqueFileId)
        if (index > -1) {
          self.files.splice(index, 1)
        }
        var index2 = self.pictureObjArray.map(function (x) { return x.fullPath }).indexOf(uniqueFileId)
        if (index2 > -1) {
          self.pictureObjArray.splice(index2, 1)
        }
        load()
      }).catch(function (e) {
        this.handleError(error, e)
      })
    },
    // load (uniqueFileId, load, error) { error() },
    // load (source, load, error, progress, abort, headers) {
    //   var myRequest = new Request(source)
    //   fetch(myRequest).then(function (response) {
    //     response.blob().then(function (myBlob) {
    //       load(myBlob)
    //     })
    //   })
    //   // Should expose an abort method so the request can be cancelled
    //   return {
    //     abort: () => {
    //       // Let FilePond know the request has been cancelled
    //       abort()
    //     }
    //   }
    // },
    load: (source, load, error, progress, abort, headers) => {
      var myRequest = new Request(source)
      fetch(myRequest).then(function (response) {
        response.blob().then(function (myBlob) {
          load(myBlob)
        })
      })
    },
    fetch (url, load, error, progress, abort, headers) {
      error('Solo archivos locales')
    },
    restore (uniqueFileId, load, error, progress, abort, headers) {
      error()
    },
    open: (file, instructions) => {
      this.pictureToEdit = file
      this.showPictureEditor = true
    },

    // Callback set by FilePond
    // - should be called by the editor when user confirms editing
    // - should receive output object, resulting edit information
    onconfirm: (output) => {},
    // Callback set by FilePond
    // - should be called by the editor when user cancels editing
    oncancel: () => {},
    // Callback set by FilePond
    // - should be called by the editor when user closes the editor
    onclose: () => {},
    handleError (error, e) {
      switch (e.code) {
        case 'storage/canceled':
          break
        default:
          error(e.message)
          this.dispatch('showAlert', e.message)
      }
    },
    handleFilePondInit: function () {
      this.$refs.input.getFiles()
    }
  },
  watch: {
    dialog (newValue) {
      this.dialog = false
      this.$store.dispatch('resize')
      this.$emit('importPicture', this.pictureObjArray)
    },
    files: {
      handler: function (val, oldVal) {
        if (this.first) this.first = false
      },
      deep: true
    }
  },
  components: {
    FilePond
  }
}
</script>
