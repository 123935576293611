<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      :style="$store.getters.screenSize"
    >
      <v-card id="masterCard" :style="$store.getters.screenSize">
        <v-toolbar v-show="!showFullScreen" dark color="primary" :height="$store.getters.appBarHeight">
          <v-row no-gutters class="mt-2">
            <!-- <v-col two-line>
              <v-card-title>
                {{ $t('pictureManager.title')}}
              </v-card-title>
              <v-card-subtitle>{{ subTitle }}</v-card-subtitle>
            </v-col> -->
            <v-col cols="8">
              <v-list-item-title>
                {{ $t('pictureManager.title')}}
              </v-list-item-title>
              <v-list-item-subtitle>{{ subTitle }}</v-list-item-subtitle>
            </v-col>
            <v-spacer></v-spacer>
            <v-col two-line class="4">
              <p class="mt-1 text-right" v-if="imageList.length">{{ imageSelectedIndex + 1 }} / {{ imageList.length }}</p>
              <p class="mt-1 text-right" v-else>{{ imageList.length }}</p>
              <p class="mt-n5 text-right">{{ $t('common.picture') }}</p>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-card justify="center" ref="masterCard" :style="$store.getters.contentSize">
          <v-card flat height="60">
            <v-row no-gutters>
                <v-btn v-if="imageList.length" class="mt-1" icon @click="showFullScreen = !showFullScreen" x-large>
                  <v-icon x-large color="grey">{{ showFullScreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen' }}</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
              <div v-if="isUpdateAccess">
                <v-btn v-if="imageList.length ? !imageList[imageSelectedIndex].isPrimary : false" class="mt-1" icon @click="confirmChangePrimary()" x-large>
                  <v-icon x-large color="yellow">mdi-star</v-icon>
                </v-btn>
                <v-btn v-if="imageList.length" class="mt-1" icon @click="showPictureEditor = true" x-large>
                  <v-icon x-large color="success">mdi-image-edit</v-icon>
                </v-btn>
                <v-btn class="mt-1" icon @click="showImportPicture = true" x-large>
                  <v-icon x-large color="success">mdi-image-plus</v-icon>
                </v-btn>
                <v-btn v-if="imageList.length" class="mt-1" icon @click="confirmDeletePicture()" x-large>
                  <v-icon x-large color="success">mdi-image-minus</v-icon>
                </v-btn>
              </div>
              <v-spacer></v-spacer>
              <v-btn class="mt-1" icon @click="close()" x-large>
                <v-icon x-large color="red">mdi-close-circle</v-icon>
              </v-btn>
            </v-row>
          </v-card>
          <!-- <div>
          <picture-editor
            v-if="showPictureEditor"
            :pictureUrl="imageList[imageSelectedIndex].url"
            @close="showPictureEditor = false"
          ></picture-editor>
          </div> -->
          <v-card
            color="grey lighten-1"
            v-show="!showImportPicture"
            :height="$store.getters.contentHeigth - 60"
            class="overflow-y-hidden overflow-x-hidden"
          >
            <v-card-subtitle v-if="!imageList.length"> 0 {{$t('createreportdog.picture')}}</v-card-subtitle>
            <v-carousel
              v-if="imageList.length"
              v-model="imageSelectedIndex"
              hide-delimiters
              :show-arrows="imageList.length > 1"
              v-show="imageList.length"
              :height="carouselHeight"
              :max-height="carouselHeight"
            >
              <v-carousel-item
                v-for="(image, i) of imageList"
                :key="i"
                :src="image.url"
              ></v-carousel-item>
            </v-carousel>
            <v-card
              id="listCard"
              :height="listCardHeight"
              v-show="showListCard"
            >
              <v-layout row align-content-center class="mt-2">
                <v-flex
                  v-for="(image, i) of imageList"
                  :key="i"
                  px-1
                  pb-2
                  class="flex-0"
                >
                  <v-card
                    align="center"
                    justify="center"
                    class="px-1 mt-1 pt-1 pb-n1"
                    :color="i === imageSelectedIndex ? 'red' : 'white'"
                    @click="imageSelectedIndex = i"
                  >
                    <v-badge
                      v-if="image.isPrimary"
                      bordered
                      color="yellow"
                      icon="mdi-star"
                      overlap
                    >
                      <img
                        :src="image.url"
                        width="70"
                        height="70"
                        contain
                        class="mx-auto"
                      />
                    </v-badge>
                    <img
                      v-else
                      :src="image.url"
                      width="70"
                      height="70"
                      contain
                      class="mx-auto"
                    />
                  </v-card>
                </v-flex>
              </v-layout>
            </v-card>
          </v-card>
        </v-card>
      </v-card>
      <pmt-import-picture
        v-if="showImportPicture"
        :title="$t('createreportdog.addpicture')"
        @importPicture="confirmAddPicture($event)"
        @close="showImportPicture = false"
        :pictureBasePath="pictureBasePath"
        label="import"
        required="false"
        max="5"
        type="image/jpeg, image/jpg, image/png"
      ></pmt-import-picture>
      <confirm-popup
        v-if="showConfirm"
        @cancel="cancelConfirm()"
        @confirm="areConfirm()"
        :title="confirmTitle"
        :param="{ mode: 'standard', text: confirmMessText }"
      ></confirm-popup>
      <picture-editor
        v-if="showPictureEditor"
        :pictureUrl="imageList[imageSelectedIndex].url"
        @close="showPictureEditor = false"
        @toUpdatePicture="savePicture($event)"
      ></picture-editor>
      <pmt-loading-progress-local
        :loadingShow="showLoading"
        :progressValue="progress"
        :loadingTitle="loadingTitle"
      ></pmt-loading-progress-local>
    </v-dialog>
  </v-row>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/storage'
// import Exif from 'exif-js'

export default {
  props: ['title', 'subTitle', 'param'],
  data () {
    return {
      imageList: [],
      dialog: true,
      imagePreviewList: [],
      imageUrl: '',
      image: null,
      showImportPicture: false,
      imageSelectedIndex: 0,
      showFullScreen: false,
      showConfirm: false,
      confirmTitle: '',
      confirmMess: null,
      confirmMessText: '',
      addPictureList: null,
      pictureToEdit: null,
      showPictureEditor: false,
      showLoading: false,
      loadingTitle: 'Loading',
      progress: 0
    }
  },
  computed: {
    isUpdateAccess () {
      return this.$store.getters.isUpdateAccess
    },
    pictureBasePath () {
      const path = this.$store.getters.getSelectedOfficeId + '/image/'
      switch (this.param.type) {
        case 'pocItem':
          return path + 'pocItem/' + this.param.pocId + '/'
        case 'multiItem':
          return path + 'multiItem/' + this.param.multiId + '/'
        case 'officeItem':
          return path + 'officeItem/'
        case 'pocHome':
          return path + 'home/' + this.param.pocId + '/'
        case 'message':
          return path + 'message/' + this.$store.getters.userId + '/'
        case 'report':
          return path + 'report/' + this.param.reportId + '/'
        case 'place-edit':
          return path + 'placeHome/' + this.param.placeId + '/'
        case 'place-create':
          return path + 'placeHome/' + this.param.placeId + '/'
        default:
          return path
      }
    },
    systemImageList () {
      var mapItems
      var index
      var iList
      switch (this.param.type) {
        case 'pocItem':
          mapItems = this.$store.state.pocModule.data[this.param.pocId].mapItems
          index = mapItems.map(function (x) { return x.id }).indexOf(this.param.itemId)
          iList = index > -1 ? mapItems[index].imageList : []
          return iList
        case 'multiItem':
          iList = this.$store.state.officeItemMultiModule.data[this.param.multiId].imageList
          if (iList) {
            return iList
          } else {
            return []
          }
        case 'officeItem':
          return this.param.imageList
        case 'pocHome':
          iList = this.$store.state.pocModule.data[this.param.pocId].homeImageList
          if (iList) {
            return iList
          } else {
            return []
          }
        case 'message':
          return this.param.imageList
        case 'report':
          if (this.param.reportId) {
            return this.$store.state.reportModule.data[this.param.reportId].imageList
          } else {
            return this.param.imageList
          }
        case 'place-edit':
          iList = this.$store.state.placeModule.data[this.param.placeId].imageList
          if (iList) {
            return iList
          } else {
            return []
          }
        case 'place-create':
          return this.param.imageList
        default:
          return []
      }
    },
    carouselHeight () {
      if (this.showFullScreen) {
        return Math.abs(this.$store.getters.contentHeight)
      } else {
        const calcWeidth = Math.abs((this.$store.getters.contentWidth / 3) * 2)
        if (calcWeidth > 500) {
          return 500
        } else {
          return calcWeidth
        }
      }
    },
    listCardHeight () {
      return this.$store.getters.contentHeight - this.carouselHeight - 60
    },
    showListCard () {
      if (!this.showFullScreen && !this.showImportPicture) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    dialog (newValue) {
      if (!newValue) {
        this.$store.dispatch('resize')
        this.$emit('close')
      }
    }
  },
  methods: {
    close () {
      if (this.param.type === 'message' || this.param.type === 'officeItem' || this.param.type === 'report' || this.param.type === 'place-create') {
        this.$emit('return-close', this.imageList)
      } else {
        this.$emit('close')
      }
    },
    editPicture () {
      this.showPictureEditor = true
    },
    cancelConfirm () {
      this.showConfirm = false
      this.confirmMessText = ''
      this.confirmTitle = ''
      switch (this.confirmMode) {
        case 'add':
          this.deleteTempAddPicture()
          break
        default:
          break
      }
    },
    areConfirm () {
      this.showConfirm = false
      switch (this.confirmMode) {
        case 'delete':
          this.deletePicture()
          break
        case 'add':
          this.setPicture(this.addPictureList)
          break
        case 'changePrimary':
          this.setPrimary()
          break
        case 'save':
          this.saveImageList(this.$t('common.succesSave'))
          break
        default:
          break
      }
    },
    confirmChangePrimary () {
      this.confirmTitle = this.$t('common.confirmChangePrimary')
      this.confirmMessText = this.$t('common.confirmChangePrimaryText')
      this.confirmMode = 'changePrimary'
      this.showConfirm = true
    },
    confirmDeletePicture () {
      this.confirmTitle = this.$t('common.confirmdelete')
      this.confirmMessText = ''
      this.confirmMode = 'delete'
      this.showConfirm = true
    },
    confirmAddPicture (pictureList) {
      this.showImportPicture = false
      if (pictureList.length) {
        this.addPictureList = pictureList
        this.confirmTitle = this.$t('common.confirmAddPicture')
        this.confirmMessText = ''
        this.confirmMode = 'add'
        this.showConfirm = true
      }
    },
    saveImageList (successMess) {
      if (this.isUpdateAccess) {
        // successMess = this.$t('common.succesSave')
        var saveData
        var self = this
        switch (this.param.type) {
          case 'pocItem':
            saveData = { itemId: this.param.itemId, pocId: this.param.pocId, imageList: self.imageList, successMess: successMess }
            self.$store.dispatch('pocModule/saveSoloPocItemImageList', saveData)
            break
          case 'multiItem':
            self.$store.dispatch('officeItemMultiModule/set', { id: this.param.multiId, imageList: self.imageList })
              .then(function () {
                if (successMess) { self.$store.dispatch('showMessage', successMess) }
              }).catch(function (error) {
                self.$store.dispatch('showAlert', error.message)
              })
            break
          case 'pocHome':
            var temphomeImageListSelect = false
            if (this.$store.state.pocModule.data[this.param.pocId].homeImageListSelect) { temphomeImageListSelect = true }
            if (!self.imageList.length) { temphomeImageListSelect = false }
            self.$store.dispatch('pocModule/set', { id: this.param.pocId, homeImageList: self.imageList, homeImageListSelect: temphomeImageListSelect })
              .then(function () {
                if (successMess) { self.$store.dispatch('showMessage', successMess) }
              }).catch(function (error) {
                self.$store.dispatch('showAlert', error.message)
              })
            break
          case 'report':
            self.$store.dispatch('reportModule/set', { id: this.param.reportId, imageList: self.imageList })
              .then(function () {
                if (successMess) { self.$store.dispatch('showMessage', successMess) }
              }).catch(function (error) {
                self.$store.dispatch('showAlert', error.message)
              })
            break
          case 'place-edit':
            var tempPlaceImageListSelect = false
            if (this.$store.state.placeModule.data[this.param.placeId].homeImageListSelect) { tempPlaceImageListSelect = true }
            if (!self.imageList.length) { tempPlaceImageListSelect = false }
            self.$store.dispatch('placeModule/set', { id: this.param.placeId, imageList: self.imageList, homeImageListSelect: tempPlaceImageListSelect })
              .then(function () {
                if (successMess) { self.$store.dispatch('showMessage', successMess) }
              }).catch(function (error) {
                self.$store.dispatch('showAlert', error.message)
              })
            break
          default:
            break
        }
      }
    },
    savePicture (pictureBlob) {
      this.showPictureEditor = false
      const self = this
      self.loadingTitle = self.$t('common.saveinprogress')
      self.showLoading = true
      const index = this.imageSelectedIndex
      const uniqueFileId = this.imageList[index].fullPath
      const uploadTask = firebase.storage().ref().child(uniqueFileId).put(pictureBlob)
      uploadTask.on('state_changed', function (snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        self.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      }, function (error) {
        self.dispatch('showAlert', error.message)
        self.showLoading = false
        // Handle unsuccessful uploads
      }, function () {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          self.showLoading = false
          self.imageList[index].url = downloadURL
          self.saveImageList(self.$t('common.succesSave'))
        })
      })
    },
    deletePicture () {
      if (this.isUpdateAccess) {
        this.showConfirm = false
        const index = this.imageSelectedIndex
        const uniqueFileId = this.imageList[index].fullPath
        var self = this

        var desertRef = firebase.storage().ref().child(uniqueFileId)
        if (desertRef.getDownloadURL().length <= 0) {
          if (self.imageList[index].isPrimary && self.imageList.length > 1) {
            if (index) {
              self.imageList[0].isPrimary = true
            } else {
              self.imageList[1].isPrimary = true
            }
          }
          self.imageSelectedIndex = 0
          self.imageList.splice(index, 1)
          self.saveImageList(self.$t('common.succesDelete'))
        } else {
          desertRef.delete().then(function () {
            if (self.imageList[index].isPrimary && self.imageList.length > 1) {
              if (index) {
                self.imageList[0].isPrimary = true
              } else {
                self.imageList[1].isPrimary = true
              }
            }
            self.imageSelectedIndex = 0
            self.imageList.splice(index, 1)
            self.saveImageList(self.$t('common.succesDelete'))
          }).catch(function (error) {
            self.handleError(error)
          })
        }
      }
    },
    previewImage (event) {
      this.imageList.push(event.target.files[0])
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.imagePreviewList.push(fileReader.result)
      })
      fileReader.readAsDataURL(event.target.files[0])
    },
    onPickImage () {
      this.$refs.inputimage.click()
    },
    setPicture (newImageArray) {
      newImageArray.forEach(image => {
        if (!this.imageList.length) { image.isPrimary = true }
        this.imageList.push(image)
      })
      this.showImportPicture = false
      if (newImageArray.length) {
        this.saveImageList(this.$t('common.succesAddPicture'))
      }
    },
    setPrimary () {
      for (let i = 0; i < this.imageList.length; i++) {
        if (i === this.imageSelectedIndex) {
          this.imageList[i].isPrimary = true
        } else {
          this.imageList[i].isPrimary = false
        }
        this.saveImageList(null)
      }
    },
    deleteTempAddPicture () {
      if (this.addPictureList.length) {
        this.addPictureList.forEach(picture => {
          var desertRef = firebase.storage().ref().child(picture.fullPath)
          desertRef.delete()
        })
      }
    },
    handleError (err) {
      this.$store.dispatch('showAlert', err.message)
    }
  },
  mounted () {
    this.dialog = true
    this.imageList = this.systemImageList ? this.systemImageList : []
  },
  destroyed () {
    this.imageList = null
  }
}
</script>
<style scoped>
  #importCard {
    overflow-x: hidden;
    overflow-y: auto;
  }
  #listCard {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .flex-0 {
    display: flex;
  }
</style>
