<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="444" class="mx-auto" centered>
      <v-card class='mx-auto' centered>
          <v-card-title v-if="$store.getters.getMasterLoadingStatus.title" class="pt-10 pb-3">
            {{ $store.getters.getMasterLoadingStatus.title }}
          </v-card-title>
          <v-card-subtitle v-if="$store.getters.getMasterLoadingStatus.subTitle">
            {{ $store.getters.getMasterLoadingStatus.subTitle }}
          </v-card-subtitle>
          <v-card-subtitle v-if="$store.getters.getMasterLoadingStatus.progressValue">
            {{ $store.getters.getMasterLoadingStatus.progressValue }} %
          </v-card-subtitle>
          <v-card-text class="align-end">{{ $t('common.wait') }}</v-card-text>
          <v-card-text centered>
            <v-row>
              <v-progress-linear
                v-if="$store.getters.getMasterLoadingStatus.progressValue === 0 || $store.getters.getMasterLoadingStatus.progressValue"
                :value="$store.getters.getMasterLoadingStatus.progressValue"
                height="7"
                color="primary"
              ></v-progress-linear>
              <v-progress-linear
                v-else
                indeterminate
                color="primary"
              ></v-progress-linear>
            </v-row>
          </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
<script>
export default {
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    showLoading () {
      return this.$store.getters.getMasterLoadingStatus.showLoading
    }
  },
  watch: {
    showLoading (newValue) {
      this.dialog = newValue
    }
  },
  methods: {
  }
}
</script>
