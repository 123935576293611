// import Vue from 'vue'
// import VueRouter from 'vue-router'
// import Home from '@/components/HomePage/PMT_HomePage.vue'
// import Login from '@/components/user/Login.vue'
// import appHome from '@/components/user/AppHome.vue'
// import adminHome from '@/components/user/AdminHome.vue'
import AuthGuard from '@/router/auth-guard'
// import AdminLocalGuard from '@/router/adminLocal-guard'
// import byPassLogin from '@/router/byPassLogin'
// import byPassHome from '@/router/byPassHome'

// Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/PMT_HomePage')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('@/views/AdminHome'),
    base: process.env.BASE_URL,
    meta: {
      requiresAuth: true
    }
    // beforeEnter: AdminLocalGuard
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login')
    // beforeEnter: byPassLogin
  },
  {
    path: '/app',
    name: 'app',
    base: process.env.BASE_URL,
    component: () => import('@/views/AppHome'),
    // meta: { requiresAuth: true },
    beforeEnter: AuthGuard
  },
  // {
  //   path: '/app',
  //   component: { render: h => h('router-view'), name: 'Root' },
  //   children: [
  //     {
  //       path: 'about/',
  //       name: 'Test',
  //       component: () => import('@/views/About'),
  //       beforeEnter: (to, from, next) => {
  //         // console.log('trace beforeEnter')
  //         // console.log('params.parama === ' + to.params.parama)
  //         // console.log('params.param2 === ' + to.params.param2)
  //         return next()
  //       }
  //     }
  //   ]
  // },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '*',
    redirect: to => {
      return { path: '/' }
    }
  }
  // {
  //   path: '*',
  //   redirect: '/'
  // }
]

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })

// export default router
