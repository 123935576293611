<template>
<v-container>
  <v-layout row v-if="authError">
    <v-flex xs12 sm6 offset-sm3>
      <alert-cmp @dismissed="onDismissed" :err="authError"></alert-cmp>
    </v-flex>
  </v-layout>
  <v-layout row>
    <v-flex xs12 sm6 offset-sm3>
      <v-card>
        <v-toolbar
                color="primary"
                dark
                flat
              >
                <v-toolbar-title>{{ $t('createoffice.title') }}</v-toolbar-title>
                <v-spacer />
                <v-btn @click="$i18n.locale = $t('common.otherlocale')">{{ $t('common.otherlocale') }}</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <form @submit.prevent="createOffice">
              <v-layout row wrap>
                <v-select
                  v-model="selectzone"
                  :items="zonelist"
                  :label="$t('createoffice.zone')"
                  required
                ></v-select>
              </v-layout>
              <v-layout row>
                <v-flex xs12>
                  <v-text-field
                    name="name"
                    :label="$t('createoffice.name')"
                    id="name"
                    v-model="name"
                    type="text"
                    @blur="$store.dispatch('resize')"
                    required></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs12>
                  <v-text-field
                    name="address"
                    :label="$t('createoffice.address')"
                    id="address"
                    v-model="address"
                    type="text"
                    @blur="$store.dispatch('resize')"
                    required></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs12>
                  <v-text-field
                    name="city"
                    :label="$t('createoffice.city')"
                    id="city"
                    v-model="city"
                    type="text"
                    @blur="$store.dispatch('resize')"
                    required></v-text-field>
                </v-flex>
              </v-layout>
              <v-divider class="my-5"/>
              <v-layout row>
                <v-flex xs12>
                  <v-btn type="submit" color="primary">{{ $t('createoffice.create') }}</v-btn>
                </v-flex>
              </v-layout>
            </form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</v-container>
</template>

<script>

// import { searchGoogle } from '../../utils/PMTmapsUtils'
import Vue from 'vue'
import firebase from 'firebase/app'
// import PMTmapsUtils from '../../utils/PMTmapsUtils'
const EventBus = new Vue()

export default {
  methods: {
    createOffice () {
      var geocoder = new this.google.maps.Geocoder()
      geocoder.geocode({ address: 'G6V 3P0' }, function (results, status) {
        if (status === this.google.maps.GeocoderStatus.OK) {
          EventBus.$emit('searchcomplete', results)

          // this.$store.dispatch('officeModule/set', officedetail)
        } else {
          // there were no coordinates found for the address entered (or the address was not found)
          alert('Could not find your address :(')
        }
      })
    },
    onDismissed () {
      this.$store.dispatch('clearAuthError')
    }
  },
  computed: {
    authError () {
      return this.$store.getters.authError
    },
    zonelist () {
      const zlist = []
      for (const t in this.$store.state.zoneModule.data) {
        zlist.push({ text: this.$store.state.zoneModule.data[t].name, value: t })
      }
      return zlist
    }
  },
  watch: {
    officedata (newValue) {
      if (newValue) {
        this.$store.dispatch('officeModule/set', newValue)
      }
    }
  },
  async mounted () {
    // this.google = await PMTmapsUtils()
    this.google = this.$store.getters.getGoogle
    EventBus.$on('searchcomplete', (results) => {
      const officedetail = {
        name: this.name,
        address: this.address,
        city: this.city,
        zone: this.selectzone,
        gaddress: results[0].address_components,
        formatted_address: results[0].formatted_address,
        place_id: results[0].place_id,
        location: [{ desc: 'home', loc: new firebase.firestore.GeoPoint(results[0].geometry.location.lat(), results[0].geometry.location.lng()) }]
      }
      this.$store.dispatch('officeModule/set', officedetail)
    })
  },
  data () {
    return {
      name: '',
      address: '',
      city: '',
      selectzone: '',
      google: '',
      officedata: null
    }
  }
}
</script>
