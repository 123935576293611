<template id="template" fixed>
  <v-row justify="center" id="row">
    <v-dialog max-width="444" v-model="dialog" persistent fixed id="dialog" transition="dialog-bottom-transitionfab-transition">
      <v-card fixed>
        <v-data-iterator
          fixed
          ref="dataIterator"
          :hide-default-footer="showSearchParam"
          single-expand
          :page="page"
          :custom-filter="customFilter"
          :sort-by="sortBy"
          :sort-desc="isAscending"
          :custom-sort="customSort"
          :search="search"
          :items="searchItems"
          :items-per-page="30"
          :footer-props="{ 'items-per-page-options' : [15, 30, 50, 100, -1] }"
          @update:items-per-page="$store.dispatch('resize')"
        >
          <template v-slot:header>
            <v-app-bar
              dark
              color="primary"
              class="mb-1"
              :height="$store.getters.appBarHeight"
              extended
              :src="require('@/assets/Icon/MenuIcon/logo_postes_canada.png')"
              >
              <template v-slot:img="{ props }">
                <v-img
                  v-bind="props"
                  :gradient="$store.getters.getDayGradient"
                ></v-img>
              </template>
              <v-app-bar-nav-icon>
                <v-btn icon x-large @click="close">
                  <v-icon x-large>mdi-close</v-icon>
                </v-btn>
              </v-app-bar-nav-icon>
              <v-spacer></v-spacer>
              <v-app-bar-nav-icon>
              <v-btn icon x-large @click="isAscending = !isAscending"><v-icon x-large>{{ isAscending ? 'mdi-sort-alphabetical-ascending' : 'mdi-sort-alphabetical-descending' }}</v-icon></v-btn>
              </v-app-bar-nav-icon>
              <v-app-bar-nav-icon>
                <v-btn icon @click="showSearchParam=!showSearchParam">
                  <v-icon x-large :color="showSearchParam ? 'green' : 'white'" >mdi-cogs</v-icon>
                </v-btn>
              </v-app-bar-nav-icon>
              <template v-slot:extension>
                <v-spacer></v-spacer>
                <v-text-field
                  v-show="!showSearchParam"
                  v-model="search"
                  clearable
                  flat
                  solo-inverted
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  @blur="$store.dispatch('resize')"
                  :label="$t('dataIterator.searchLabel')"
                ></v-text-field>
                <p v-show="showSearchParam" class="title">{{ $t('common.searchOption') }}</p>
                <v-spacer></v-spacer>
              </template>
            </v-app-bar>
          </template>
          <template v-slot:default="{ items, isExpanded, expand }">
            <v-card :max-height="iteratorHeight" :height="iteratorHeight" flat class="overflow-y-auto">
              <v-container v-show="showSearchParam" transition="dialog-top-transition">
                <!-- <v-card-title>{{ $t('common.searchOption') }}</v-card-title> -->
                <p class="subtitle-1">{{ $t('common.searchtype') }}</p>
                <v-list>
                  <v-layout row>
                    <v-flex xs4  :class="searchMode === 'pdr' ? 'elevation-2 green lighten-3 dark' : 'elevation-1 red lighten-3 dark'">
                      <v-list-item link class="py-4">
                        <v-list-item-content @click="searchMode = 'pdr'">
                          <div>
                            <v-row no-gutters>
                              <v-spacer></v-spacer>
                              <v-badge
                                v-if="searchMode === 'pdr'"
                                overlap
                                bordered
                                color="green"
                                icon="mdi-check"
                                offset-x="10"
                                offset-y="10"
                              >
                                <v-icon class="justify-center" :size="$store.getters.contentWidth < 380 ? 35 : 50" :color="searchMode === 0 ? 'black' : 'grey'">mdi-home-group</v-icon>
                              </v-badge>
                              <v-icon v-else class="justify-center" :size="$store.getters.contentWidth < 380 ? 35 : 50" :color="searchMode === 0 ? 'black' : 'grey'">mdi-home-group</v-icon>
                              <v-spacer></v-spacer>
                            </v-row>
                          </div>
                          <p class="subtitle-2 text-center">{{ $t('common.pdr') }}</p>
                        </v-list-item-content>
                      </v-list-item>
                    </v-flex>
                    <v-flex xs4 :class="searchMode === 'toilet' ? 'elevation-2 green lighten-3 dark' : 'elevation-1 red lighten-3 dark'">
                      <v-list-item link class="py-4">
                        <v-list-item-content  @click="searchMode = 'toilet'">
                          <div>
                            <v-row>
                              <v-spacer></v-spacer>
                              <v-badge
                                v-if="searchMode === 'toilet'"
                                overlap
                                bordered
                                color="green"
                                icon="mdi-check"
                                offset-x="10"
                                offset-y="10"
                              >
                                <v-img
                                  :src="require('@/assets/Icon/MapIcon/human-male-female.png')"
                                  alt="Toilet"
                                  :max-height="$store.getters.contentWidth < 380 ? 35 : 80"
                                  contain
                                  centered
                                ></v-img>
                              </v-badge>
                              <v-img
                                v-else
                                :src="require('@/assets/Icon/MapIcon/human-male-female.png')"
                                alt="Toilet"
                                :max-height="$store.getters.contentWidth < 380 ? 35 : 50"
                                contain
                                centered
                              ></v-img>
                              <v-spacer></v-spacer>
                            </v-row>
                          </div>
                          <p class="subtitle-2 text-center">{{ $t('pmtparammapsinfo.toilet') }}</p>
                        </v-list-item-content>
                      </v-list-item>
                    </v-flex>
                    <v-flex xs4 :class="searchMode === 'greybox' ? 'elevation-2 green lighten-3 dark' : 'elevation-1 red lighten-3 dark'">
                      <v-list-item link class="py-4">
                        <v-list-item-content  @click="searchMode = 'greybox'">
                          <div>
                            <v-row>
                              <v-spacer></v-spacer>
                              <v-badge
                                v-if="searchMode === 'greybox'"
                                overlap
                                bordered
                                color="green"
                                icon="mdi-check"
                                offset-x="15"
                                offset-y="10"
                              >
                                <v-img
                                  :src="require('@/assets/Icon/MapIcon/GreyBox.png')"
                                  alt="Grey Box"
                                  :max-height="$store.getters.contentWidth < 380 ? 35 : 50"
                                  contain
                                  centered
                                ></v-img>
                              </v-badge>
                              <v-img
                                v-else
                                :src="require('@/assets/Icon/MapIcon/GreyBox.png')"
                                alt="Grey Box"
                                :max-height="$store.getters.contentWidth < 380 ? 35 : 50"
                                contain
                                centered
                              ></v-img>
                              <v-spacer></v-spacer>
                            </v-row>
                          </div>
                          <p class="subtitle-2 text-center">{{ $t('pmtparammapsinfo.greybox') }}</p>
                        </v-list-item-content>
                      </v-list-item>
                    </v-flex>
                  </v-layout>
                </v-list>
                <v-divider></v-divider>
                <p class="subtitle-1">{{ $t('common.sortby') }}</p>
                <v-radio-group v-model="sortBy" fluid>
                  <v-radio value="dist" :label="$t('common.distanceofme')"></v-radio>
                  <v-radio v-show="searchMode === 'pdr'" value="seq" class="mt-3" :label="$t('common.shipseq')"></v-radio>
                  <v-radio v-show="searchMode !== 'pdr'" value="name" class="mt-3" :label="$t('common.name')"></v-radio>
                  <v-radio v-show="searchMode === 'greybox'" value="group" class="mt-3" :label="$t('common.group')"></v-radio>
                </v-radio-group>
              </v-container>
              <v-list nav v-if="!showSearchParam">
                <v-list-item
                  v-for="item in items"
                  :key="item.id"
                  :class="isExpanded(item) ? 'elevation-2' : ''"
                  @click="(v) => expand(item, !isExpanded(item))"
                >
                  <v-list-item-content>
                    <div v-if="searchMode === 'pdr'">
                      <v-list-item-title :class="$store.getColorText(item.color)" v-text="item.pdrListAddress"></v-list-item-title>
                      <v-row no-gutters class="mb-n4">
                        <p :class="$store.getColorText(item.color) + ' subtitle-2'" v-text="item.pdrListPostalCode"></p>
                        <v-spacer></v-spacer>
                        <p v-if="sortBy === 'dist'" class="subtitle-2">{{ $store.getters.distanceOfMeText(item.homeLocation) }}</p>
                        <p v-else class="subtitle-2">Pos: {{ item.seqPosTot }}</p>
                      </v-row>
                      <v-container v-if="isExpanded(item)" transition="dialog-top-transition">
                        <v-row class="mt-2">
                          <p display-4 >{{ getTypeDesc(item.type) }}</p>
                          <v-spacer/>
                          <p>{{ $t('common.pub') }} : </p>
                          <v-icon class="mt-n4 ms-1" color='green' v-if="item.pub">mdi-check-bold</v-icon>
                          <v-icon class="mt-n4 ms-1" color='red' v-if="!item.pub">mdi-cancel</v-icon>
                          <v-spacer/>
                          <p>{{ item.vacant ? $t('pmtplacepopupinfo.vacant') : $t('pmtplacepopupinfo.occuped')  }}</p>
                        </v-row>
                        <v-row class="mt-2"><p>{{ $t('common.' + item.tpType.toLowerCase()) }}</p></v-row>
                        <v-row class="mt-3">
                          <v-btn @click="editPdr(item.id)" icon x-large><v-icon x-large :color="$store.getColorCode(item.color)">mdi-cog-outline</v-icon></v-btn>
                          <v-spacer/>
                          <v-btn @click="createReportFor(item)" icon x-large><v-icon x-large color="red">mdi-alert</v-icon></v-btn>
                          <v-spacer/>
                          <v-btn @click="selectPdr(item)" icon x-large><v-icon x-large :color="$store.getColorCode(item.color)">mdi-google-maps</v-icon></v-btn>
                        </v-row>
                      </v-container>
                    </div>
                    <div v-if="searchMode === 'greybox'" :color="item.group">
                      <p :class="item.group + ' subtitle-1 py-2 mb-n1'" v-text="item.name"></p>
                      <v-row no-gutters class="mb-n4">
                        <v-spacer></v-spacer>
                        <p v-if="sortBy === 'dist'" class="subtitle-2">{{ $store.getters.distanceOfMeText(item.position) }}</p>
                      </v-row>
                      <v-container v-if="isExpanded(item)" transition="dialog-top-transition">
                        <p class="subtitle-1">{{ item.name }}</p>
                      </v-container>
                    </div>
                    <div v-if="searchMode === 'toilet'">
                      <p v-text="item.description"></p>
                      <v-row no-gutters class="mb-n4">
                        <v-spacer></v-spacer>
                        <p v-if="sortBy === 'dist'" class="subtitle-2">{{ $store.getters.distanceOfMeText(item.position) }}</p>
                      </v-row>
                      <v-container v-if="isExpanded(item)" transition="dialog-top-transition">
                        <p class="subtitle-1">{{ item.description }}</p>
                      </v-container>
                    </div>
                  </v-list-item-content>

                </v-list-item>
              </v-list>
            </v-card>
          </template>
          <!-- <template v-slot:footer>
            <v-container background-color="primary" class="primary darken-1">
            <v-row align="center" justify="center">
              <span class="white--text">{{ $t('dataIterator.rowsPerPageText') }}</span>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    dark
                    x-large
                    class="primary ml-1"
                    v-on="on"
                  >
                    {{ itemsPerPage.text }}
                    <v-icon large>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(number, index) in itemsPerPageArray"
                    :key="index"
                    @click="updateItemsPerPage(number)"
                  >
                    <v-list-item-title>{{ number.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-row>
            <v-row class="mt-3">
              <v-btn
                fab
                dark
                x-large
                class="ms-1"
                color="primary lighten-1"
                @click="formerPage"
              >
                <v-icon x-large>mdi-chevron-left</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <span
                two-line
                class="white--text"
              >
                <v-list dark two-line class="mt-n2 text-center primary darken-1">
                    <v-list-item-title>
                      Page {{ page }} {{ $t('dataIterator.of') }} {{ numberOfPages }}
                    </v-list-item-title>
                    <v-list-item-title class="mx-auto mt-1">
                      {{ nbrItemFiltered }} {{ $t('common.pdr') }}
                    </v-list-item-title>
                </v-list>
              </span>
              <v-spacer></v-spacer>
              <v-btn
                class="me-1"
                fab
                dark
                x-large
                color="primary  lighten-1"
                @click="nextPage"
              >
                <v-icon x-large>mdi-chevron-right</v-icon>
              </v-btn>
            </v-row>
            </v-container>
          </template> -->

        </v-data-iterator>
      </v-card>
    </v-dialog>
    <pmt-edit-pdr-popup
      v-if="showEditPdr"
      :pdrId="selectedPdrId"
      @close="showEditPdr = false"
    >
    </pmt-edit-pdr-popup>
    <pmt-create-report-popup :owner="owner" @close="showCreateReport = false" v-if="showCreateReport"></pmt-create-report-popup>
  </v-row>
</template>

<script>
export default {
  data () {
    return {
      itemsPerPageArray: [{ text: '5', value: 5 }, { text: '10', value: 10 }, { text: '25', value: 25 }, { text: '50', value: 50 }, { text: this.$t('dataIterator.all'), value: -1 }],
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: { text: '5', value: 5 },
      searchMode: 'pdr',
      nbrItemFiltered: 0,
      autoComp: null,
      dialog: true,
      selectedPdrId: null,
      showEditPdr: false,
      showCreateReport: false,
      showSearchParam: false,
      notifications: false,
      isAscending: true,
      search: '',
      searchItems: this.$store.getters['pocModule/getPocOfSequenceSortedByDistanceSelected'],
      sortBy: this.$store.getters.getCurrentPosition ? 'dist' : 'seq'
    }
  },
  mounted () {
    this.nbrItemFiltered = this.searchItems.length
  },
  created () {
    // this.$store.dispatch('refreshCurrentPosition')
  },
  watch: {
    dialog (newValue) {
      if (!newValue) {
        this.$store.dispatch('resize')
        this.$emit('close')
      }
    },
    searchMode (newValue) {
      switch (newValue) {
        case 'pdr':
          this.searchItems = this.$store.getters['pocModule/getPocOfSequenceSelected']
          this.sortBy = this.$store.getters.getCurrentPosition ? 'dist' : 'seq'
          break
        case 'greybox':
          this.searchItems = this.$store.getters['officeItemModule/getGreyBoxMarker']
          this.sortBy = this.$store.getters.getCurrentPosition ? 'dist' : 'name'
          break
        case 'toilet':
          this.searchItems = this.$store.getters['officeItemModule/getToiletMarker']
          this.sortBy = this.$store.getters.getCurrentPosition ? 'dist' : 'name'
          break
        default:
          break
      }
      this.nbrItemFiltered = this.searchItems.length
    }
  },
  computed: {
    iteratorHeight () {
      return Math.round(this.$store.getters.contentHeight * 0.65)
    },
    pictureSrc () {
      return this.$store.getters.getUserSelectOfficePictureSrc ? this.$store.getters.getUserSelectOfficePictureSrc : 'https://picsum.photos/1920/1080?random'
    }
    // pdrList () {
    //   const pList = []
    //   const pdrListSorted = this.$store.getters['pdrModule/sortedPdr']
    //   for (const pdr of pdrListSorted) {
    //     pList.push({ id: pdr.id, name: pdr.csvPdrListAddress, pub: pdr.pub, type: pdr.type, vacant: pdr.vacant, active: false, officeId: pdr.officeId, place_id: pdr.place_id, color: pdr.color, code: pdr.codeName })
    //   }
    //   return pList
    // },
  },
  methods: {
    createReportFor (owner) {
      this.owner = owner
      this.showCreateReport = true
    },
    customFilter (items, search, filter) {
      const mode = this.searchMode
      // const fieldSearchArray = ['pdrListAddress', 'pdrListPostalCode']
      function newFilter (val, wSearch) {
        if (mode === 'pdr') {
          return val.pdrListAddress !== null &&
            (val.pdrListAddress.toString().toLowerCase().replace(/[^0-9a-zA-Z-]+/g, '').indexOf(wSearch) !== -1 ||
            val.pdrListPostalCode.toString().toLowerCase().replace(/[^0-9a-zA-Z]+/g, '').indexOf(wSearch) !== -1)
        } else if (mode === 'greybox') {
          return val.pdrListAddress !== null &&
            val.name.toString().toLowerCase().replace(/[^0-9a-zA-Z-]+/g, '').indexOf(wSearch) !== -1
        } else if (mode === 'toilet') {
          return val.pdrListAddress !== null &&
            val.description.toString().toLowerCase().replace(/[^0-9a-zA-Z-]+/g, '').indexOf(wSearch) !== -1
        } else {
          return false
        }
      }
      if (!search) { return items }
      const needleAry = search.toString().trim().toLowerCase().split(' ').filter(x => x.length)
      const results = items.filter(row => needleAry.every(needle => newFilter(row, needle)))
      return results
    },
    customSort (items, index, isDescending) {
      const mode = this.searchMode
      const distMe = this.$store.getters.distanceOfMe
      const sortBy = index[0]
      const isDesc = isDescending[0]
      // The following is informations as far as I researched.
      // items: 'food' items
      // index: Enabled sort headers value. (black arrow status).
      // isDescending: Whether enabled sort headers is desc
      switch (mode) {
        case 'pdr':
          switch (sortBy) {
            case 'dist':
              if (isDesc) {
                return items.sort((a, b) => {
                  return distMe(a.homeLocation) - distMe(b.homeLocation)
                })
              } else {
                return items.sort((a, b) => {
                  return distMe(b.homeLocation) - distMe(a.homeLocation)
                })
              }
            case 'seq':
              if (isDesc) {
                return items.sort((a, b) => {
                  return a.seqPosTot - b.seqPosTot
                })
              } else {
                return items.sort((a, b) => {
                  return b.seqPosTot - a.seqPosTot
                })
              }
            case 'name':
              if (isDesc) {
                return items.sort((a, b) => {
                  return a.pdrListAddress < b.pdrListAddress
                })
              } else {
                return items.sort((a, b) => {
                  return b.pdrListAddress < a.pdrListAddress
                })
              }
            default:
              return items
          }
        case 'toilet':
          switch (sortBy) {
            case 'dist':
              if (isDesc) {
                return items.sort((a, b) => {
                  return distMe(a.position) - distMe(b.position)
                })
              } else {
                return items.sort((a, b) => {
                  return distMe(b.position) - distMe(a.position)
                })
              }
            case 'name':
              if (isDesc) {
                return items.sort((a, b) => {
                  return a.description < b.description
                })
              } else {
                return items.sort((a, b) => {
                  return b.description < a.description
                })
              }
            default:
              return items
          }
        case 'greybox':
          switch (sortBy) {
            case 'dist':
              if (isDesc) {
                return items.sort((a, b) => {
                  return distMe(a.position) - distMe(b.position)
                })
              } else {
                return items.sort((a, b) => {
                  return distMe(b.position) - distMe(a.position)
                })
              }
            case 'name':
              if (isDesc) {
                return items.sort((a, b) => {
                  return a.name < b.name
                })
              } else {
                return items.sort((a, b) => {
                  return b.name < a.name
                })
              }
            case 'group':
              if (isDesc) {
                return items.sort((a, b) => {
                  return a.group < b.group
                })
              } else {
                return items.sort((a, b) => {
                  return b.group < a.group
                })
              }
            default:
              return items
          }
        default:
          return items
      }
    },
    close () {
      this.$store.getters.EventBus.$emit('sizeChange')
      this.dialog = false
      this.$emit('close')
    },
    editPdr (pdrId) {
      this.selectedPdrId = pdrId
      this.showEditPdr = true
    },
    selectPdr (pdr) {
      this.$store.dispatch('userSelectionPdrOfOffice', (pdr))
        .then(() => {
          this.close()
        })
      // this.dialog = false
      // this.close()
    },
    getTypeDesc (type) {
      let desc = ''
      switch (type) {
        case 'Hou/Dom':
          desc = this.$t('common.dom')
          break
        case 'Apt/App':
          desc = this.$t('common.app')
          break
        case 'Bus/com':
          desc = this.$t('common.bus')
          break
        default:
          desc = this.$t('common.frm')
      }
      return desc
    }
  }
}
</script>
<style>
  #attach {
    width: 50%
  }
  #dialog {
    position: fixed;
  }
</style>
