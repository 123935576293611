<template>
  <div class="home">
    <v-navigation-drawer
      v-model="drawer"
      absolute
      ref="mdrawer"
      right
      width="500"
      temporary
    >
      <v-card width="100%" :height="$store.getters.contentHeight" class="overflow-hidden">
        <v-tabs v-model="drawerTab" dark background-color="primary" grow icons-and-text>
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#roadbook" v-if="$store.getters.getSelectedTravelId">
            {{ $t('roadbook.label-mini') }}
            <v-icon>mdi-chart-timeline</v-icon>
          </v-tab>
          <v-tab href="#colis">
            Colis
            <v-img contain max-width="25" max-height="25" :src="$store.getMapItemIconOfType('colis')"/>
          </v-tab>
          <v-tab href="#param">
            Params
            <v-icon>mdi-cog</v-icon>
          </v-tab>
          <v-tabs-slider></v-tabs-slider>
        </v-tabs>

        <v-tabs-items v-model="drawerTab" class="overflow-y-auto">
          <v-tab-item width="100%" value="roadbook">
            <road-book-drawer-item :mapMode="mapMode" :isShow="drawerTab === 'roadbook' && drawer" :width="100"/>
          </v-tab-item>
          <v-tab-item value="colis">
            <colis-drawer-item/>
          </v-tab-item>
          <v-tab-item value="param">
            <param-drawer-item :mapMode="mapMode"/>
          </v-tab-item>
        </v-tabs-items>
        <!-- </v-card> -->
        <v-btn
          dark
          absolute
          icon
          bottom
          left
          x-large
          class="ms-1 mb-1 primary"
          @click="$emit('close')"
        ><v-icon x-large>mdi-arrow-expand-right</v-icon>
        </v-btn>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<script>
import ColisDrawerItem from './ColisDrawerItem.vue'
import ParamDrawerItem from './paramDrawerItem.vue'
import RoadBookDrawerItem from './RoadBookDrawerItem.vue'
export default {
  components: { RoadBookDrawerItem, ParamDrawerItem, ColisDrawerItem },
  name: '',
  props: ['isShow', 'mapMode'],
  data () {
    return {
      drawer: false,
      drawerTab: 0
    }
  },
  watch: {
    isShow (newValue) {
      this.drawer = newValue
    }
  },
  methods: {}
}
</script>
