<template>
  <div id="app">
    <v-card class="overflow-hidden" :style="$store.getters.contentStyle">
      <v-card height="90" class="grey lighten-2 pb-5">
        <v-card-title>
          <v-row no-gutters>
            <v-btn icon x-large @click="$store.getters.EventBus.$emit('selectLocalAdminComponent', ('pmt-menu-admin-local'))"><v-icon x-large>mdi-arrow-left-circle</v-icon></v-btn>
            <h3 class="mt-3">Menu</h3>
          </v-row>
        </v-card-title>
      </v-card>
      <v-sheet class="overflow-y-auto overflow-x-hidden" :height="$store.getters.contentHeight - 90" :max-height="$store.getters.contentHeight - 90">
        <input id='input' v-show="false" type="file" ref="inputUploadRoute" accept=".csv" @change="processCsvFiles()"/>
        <v-card-title>{{ $t('import-poclist.import') }}</v-card-title>
        <v-card-subtitle>{{ $t('import-route.title') }}</v-card-subtitle>

        <!-- <v-progress-circular indeterminate :width="7" color="primary" :size="50"></v-progress-circular> -->
        <v-container v-if="!file" class="justify-center mt-12">
          <v-row><v-spacer></v-spacer><v-btn dark icon size="70" color="primary" @click="$refs.inputUploadRoute.click()"><v-icon size="70">mdi-upload</v-icon></v-btn><v-spacer></v-spacer></v-row>
          <v-row centered class="mt-3"><h2 class="mx-auto">{{ $t('import-poclist.open') }}</h2></v-row>
        </v-container>
        <v-card v-if="file" id="filedetail" class="mx-auto mb-3 pt-4" centered max-width="444">
          <v-btn @click="file = null" class="float-right" x-large icon><v-icon x-large color="red">mdi-delete</v-icon></v-btn>
          <h4 class="ms-1">{{ $t('import-poclist.file') }} : </h4>
          <h5 class="ms-2">{{ fileName }}</h5>

          <h4 class="ms-1 mt-2">{{ $t('import-poclist.size') }} : </h4>
          <h5 class="ms-2">{{ Math.round(((file.size / 1024) / 1024) * 10) / 10 }} Mo</h5>
          <h4 class="ms-1 mt-2">{{ $t('import-poclist.modified') }} : </h4>
          <h5 class="ms-2">{{ new Date(file.lastModified).toLocaleString('fr-CA',) }}</h5>
          <h4 class="ms-1 mt-2">Detail : </h4>

          <v-container v-if="analyseComplete" id="fileStat">
            <!-- <v-card v-for="route in importData" :key="route.name">
              <v-row class="justify-space-around">
                <v-card-title>{{ route.name }} </v-card-title>
                <v-btn icon x-large @click="createRoute(route)">
                  <v-icon v-if="$store.getters['routeModule/getRouteId'](route.name)" x-large>mdi-refresh</v-icon>
                  <v-icon v-else x-large>mdi-import</v-icon>
                </v-btn>
              </v-row>
            </v-card> -->
            <v-expansion-panels>
              <v-expansion-panel
                v-for="(route, i) in importData"
                :key="i"
              >
                <div v-if="$store.getters['routeModule/getRouteByName'](route.name)">
                  <v-expansion-panel-header color="success"><p class="text-subtitle-1 mb-n1">{{ route.name }}</p></v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-container>
                      <v-select
                        :label="$t('common.directory')"
                        v-model="route.sequenceItemSelect"
                        outlined
                        filled
                        :items="routeSequenceItem(route.name)"
                      ></v-select>
                      <div v-if="route.sequenceItemSelect">
                        <v-text-field
                          v-if="route.sequenceItemSelect.id === -1"
                          :label="$t('common.directoryname')"
                          outlined
                          filled
                          block
                          v-model="route.sequenceItemSelect.name"
                          @blur="$store.dispatch('resize')"
                        ></v-text-field>
                      </div>
                      <div v-if="$store.getters['routeModule/getRouteByName'](route.name).type ===1">
                        <v-divider></v-divider>
                        <v-card-subtitle>{{ $t('pmtroadimport.firstpink') }}</v-card-subtitle>
                        <v-autocomplete
                          v-model="route.firstPink"
                          outlined
                          filled
                          color="pink"
                          @blur="$store.dispatch('resize')"
                          :filter="customFilter"
                          :items="route.sequence"
                        ></v-autocomplete>
                        <v-card-subtitle>{{ $t('pmtroadimport.firstblue') }}</v-card-subtitle>
                        <v-autocomplete
                          v-model="route.firstBlue"
                          outlined
                          filled
                          color="blue"
                          @blur="$store.dispatch('resize')"
                          :filter="customFilter"
                          :items="route.sequence"
                        ></v-autocomplete>
                      </div>
                      <v-divider></v-divider>
                      <v-btn :disabled="!validRouteSequence(route)" rounded class="mt-5" block @click="verifRouteSequence(route)">{{ $t('import-sequence.createdirectory')}}</v-btn>
                    </v-container>
                  </v-expansion-panel-content>
                </div>
                <div v-else dark>
                  <v-expansion-panel-header color="error" expand-icon="mdi-alert"><p class="text-subtitle-1 mb-n1">{{ route.name + ' - ' }}</p><p class="text-subtitle-2  mb-n1">{{ $t('import-sequence.no-poc') }}</p></v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p class="mt-2">{{ $t('import-sequence.no-poc-explan')}}</p>
                  </v-expansion-panel-content>
                </div>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- <th v-for="item in codeMarkerStat" :key="item.name" class="text-center white--text subtitle-2">{{ item.name }}</th> -->
          </v-container>
          <div v-if="validFile" class="mt-5">
            <v-row justify="center" ><v-btn icon size="70" @click="importToDatabase" color="green"><v-icon size="70">mdi-database-import</v-icon></v-btn></v-row>
            <v-row centered><h2 class="mx-auto mt-3 pb-10">{{ $t('import-poclist.importdata') }}</h2></v-row>
          </div>
        </v-card>
      </v-sheet>
    </v-card>
    <!-- <pmt-loading-progress-local :loadingShow="showProgress" :progressValue="progressValue" :loadingTitle="progressTitle"></pmt-loading-progress-local> -->
    <loading-popup v-if="showLoadingPopup" :title="loadingTitle" :subTitle="loadingsubTitle"></loading-popup>
    <pmt-create-routesequence-popup
      v-if="showCreateRouteSequence"
      :route="selRoute"
      @close="showCreateRouteSequence = false"
    ></pmt-create-routesequence-popup>
    <pmt-match-pocsequence-popup
      v-if="showMatchPocSequence"
      :title="$t('import-sequence.matchPocSeqTitle')"
      :routeName="selRoute.name"
      :toMatchAddress="toMatchAddressArray"
      :toMatchPocId="toMatchPocIdArray"
      :seqList="selRoute.sequence"
      @close="showMatchPocSequence = false"
      @assign-item="assignItem($event)"
      @complete="applyMatchRouteSequence($event)"
    ></pmt-match-pocsequence-popup>
  </div>
</template>

<script>
export default {
  name: 'App',
  props: ['title'],
  data () {
    return {
      file: null,
      showMatchPocSequence: false,
      toMatchPocIdArray: null,
      toMatchAddressArray: null,
      fileName: '',
      pinkItem: null,
      blueItem: null,
      pocListFiles: null,
      loadingsubTitle: '',
      selRoute: null,
      loadingTitle: this.$t('common.loading'),
      analyseComplete: false,
      validFile: false,
      showapp: false,
      showdoor: false,
      showCreateRouteSequence: false,
      importData: [],
      showLoading: false,
      showProgress: false,
      progressValue: 0,
      progressTitle: '',
      routeType: null
    }
  },
  watch: {
    file (newValue) {
      if (!newValue) {
        this.analyseComplete = false
        this.validFile = false
        this.importData = []
      }
    }
  },
  methods: {
    assignItem (choice) {
      // { type:'', item: '', refItem: ''}
      // // console.log(choice)
      // const refIndex = this.selRoute.sequence.map(x => { return x.text }).indexOf(choice.refItem.text)
      // const refSeqPos = choice.type === 'before' ? this.selRoute.sequence[refIndex].value.seqcodetotal
      this.selRoute.sequence.forEach(seqItem => {
        if (choice.type === 'before' && seqItem.value.seqcodetotal >= choice.refItem.seqcodetotal) {
          seqItem.value.seqcodetotal++
        } else if (choice.type === 'after' && seqItem.value.seqcodetotal > choice.refItem.seqcodetotal) {
          seqItem.value.seqcodetotal++
        }
      })
      this.selRoute.sequence.push({
        text: choice.item.text,
        value: {
          name: choice.item.text,
          door: choice.item.text.split(' ')[0],
          street: choice.item.text.replace(choice.item.text.split(' ')[0], '').trim(),
          seqcodetotal: choice.type === 'before' ? choice.refItem.seqcodetotal : choice.refItem.seqcodetotal + 1,
          seqcodeSys: null,
          seqcodeSysPos: null,
          info7: null,
          info8: null,
          info11: null,
          cp: this.$store.state.pocModule.data[choice.item.value].pdrListPostalCode,
          type: this.$store.state.pocModule.data[choice.item.value].tpType,
          pocId: choice.item.value,
          codeName: null,
          codeId: null,
          tier: null,
          sequenceName: null
        }
      })
    },
    applyMatchRouteSequence (matchList) {
      this.showMatchPocSequence = false
      matchList.forEach(match => {
        const matchPos = this.selRoute.sequence.map(function (x) { return x.text }).indexOf(match.address)
        if (match.choice) {
          this.selRoute.sequence[matchPos].value.pocId = match.pocId
          if (match.replace) {
            this.$store.dispatch('pocModule/set', {
              id: match.pocId,
              pdrListAddress: match.address
            })
          }
        } else {
          this.selRoute.sequence.slice(matchPos, 1)
        }
      })
      this.showLoading = false
      this.showCreateRouteSequence = true
    },
    verifRouteSequence (route) {
      this.showLoading = true
      const matchPocIdArray = []
      // const noMatchSequence = []
      // this.showCreateRouteSequence = true
      this.$store.dispatch('pocModule/closeDBChannel').then(() => {
        this.$store.dispatch('pocModule/openDBChannel', { where: [['route', 'array-contains', route.routeId]] })
          .then(() => {
            this.toMatchAddressArray = []
            var latTot = 0
            var lngTot = 0
            var cptTot = 0
            for (let i = 0; i < route.sequence.length; i++) {
              const pocId = this.$store.getters['pocModule/pocExist'](route.sequence[i].text)
              if (pocId) {
                if (this.$store.state.pocModule.data[pocId].homeLocation) {
                  cptTot++
                  latTot += this.$store.state.pocModule.data[pocId].homeLocation.lat
                  lngTot += this.$store.state.pocModule.data[pocId].homeLocation.lng
                }
                route.sequence[i].value.pocId = pocId
                matchPocIdArray.push(pocId)
              } else {
                this.toMatchAddressArray.push({ address: route.sequence[i].text, index: i, pocId: null, choice: 0, replace: false })
              }
            }
            route.sequenceItemSelect.pos = { lat: latTot / cptTot, lng: lngTot / cptTot }

            this.selRoute = route
            this.toMatchPocIdArray = this.$store.getters['pocModule/getPocOfRouteNotInArray'](route.routeId, matchPocIdArray)
            this.showLoading = false
            if (this.toMatchPocIdArray.length || this.toMatchAddressArray.length) {
              this.showMatchPocSequence = true
            } else {
              this.showCreateRouteSequence = true
            }
          })
      })
    },
    validRouteSequence (route) {
      var valid = false
      if (route.firstPink && route.firstBlue) {
        if (route.sequenceItemSelect) {
          if (route.sequenceItemSelect.id === -1) {
            if (route.sequenceItemSelect.name) {
              valid = true
            }
          } else {
            valid = true
          }
        }
      } else if (this.$store.getters['routeModule/getRouteByName'](route.name).type === 2) {
        if (route.sequenceItemSelect) {
          if (route.sequenceItemSelect.id === -1) {
            if (route.sequenceItemSelect.name) {
              valid = true
            }
          } else {
            valid = true
          }
        }
      }
      return valid
    },
    routeSequenceItem (routeName) {
      const itemArray = this.$store.getters['routeModule/getSequenceItemsOfRouteId'](this.$store.getters['routeModule/getRouteIdByName'](routeName))
      itemArray[0].text = this.$t('common.offdirectory')
      itemArray[0].value.name = this.$t('common.offdirectory')
      itemArray.push({ text: this.$t('common.new') + '...', value: { id: -1, official: false } })
      return itemArray
    },
    customFilter (item, queryText, itemText) {
      const itemTextLow = itemText.toLowerCase()
      const searchTextArray = queryText.toLowerCase().split(' ')
      var result = false
      for (let i = 0; i < searchTextArray.length; i++) {
        const search = searchTextArray[i]
        if (search.length) {
          if (itemTextLow.indexOf(search) > -1 && search) {
            result = true
          } else {
            result = false
            break
          }
        }
      }
      return result
    },
    processCsvFiles () {
      this.file = this.$refs.inputUploadRoute.files[0]
      this.fileName = this.file.name
      this.loadCommplete = true
      this.analyseComplete = false
      this.importData = []
      this.analyseCsvFile()
    },
    analyseCsvFile () {
      if (this.file) {
        this.loadingTitle = this.$t('common.analysing')
        this.showLoading = true
        const EventBus = this.$store.getters.EventBus
        const store = this.$store
        EventBus.$on('csvproccessprogress', (value) => {
          this.loadingsubTitle = value
        })
        EventBus.$on('seqcsvproccesscompleted', (result) => {
          // // console.log(result)
          this.showProgress = false
          this.showLoading = false
          this.loadingsubTitle = null
          this.importData = result
          this.analyseComplete = true
          // this.verifDataFile()
        })
        if (window.FileReader) {
          var reader = new FileReader()
          reader.readAsText(this.file)
          reader.onload = function (event) {
            let progress; let oldprogress
            var csv = event.target.result.split('\n')
            let cptligne = 0
            const routesList = []
            const countligne = csv.length
            for (const ligne of csv) {
              cptligne++
              const item = ligne.replace(/"/g, '').split(',')
              // const item = ligne.split(';')

              if (item.length > 1) {
                if (item[1].substring(1, 8) === 'Route :') {
                  // 5376- item[1] =  Route : LC0001 PMT_Import_Sequence.vue:326
                  // 5376- item[2] = LC0001 PMT_Import_Sequence.vue:326
                  // 5376- item[3] = SEQ: 10180 PMT_Import_Sequence.vue:326
                  // 5376- item[4] = G6X 1J6 PMT_Import_Sequence.vue:326
                  // 5376- item[5] = 4372 AV de l'EAU-VIVE PMT_Import_Sequence.vue:326
                  // 5376- item[6] = 20 PMT_Import_Sequence.vue:326
                  // 5376- item[7] = PMT_Import_Sequence.vue:326
                  // 5376- item[8] = O PMT_Import_Sequence.vue:326
                  // 5376- item[9] = DTD PMT_Import_Sequence.vue:326
                  // 5376- item[10] = H PMT_Import_Sequence.vue:326
                  // 5376- item[11] = R
                  const routeIndex = routesList.map(x => { return x.name }).indexOf(item[2])
                  if (routeIndex < 0) {
                    routesList.push({
                      name: item[2].trim(),
                      routeId: store.getters['routeModule/getRouteIdByName'](item[2].trim()),
                      firstPink: null,
                      firstBlue: null,
                      seqCtotal: 1,
                      sequenceItemSelect: null,
                      sequence: [{
                        text: item[5].toUpperCase().trim(),
                        value: {
                          name: item[5].toUpperCase().trim(),
                          door: item[5].toUpperCase().split(' ')[0],
                          street: item[5].toUpperCase().replace(item[5].toUpperCase().split(' ')[0], '').trim(),
                          seqcodetotal: 1,
                          seqcodeSys: Number(item[3].replace(/SEQ: /g, '').trim()),
                          seqcodeSysPos: Number(item[6]),
                          cptligne: cptligne,
                          info8: item[8],
                          info11: item[11],
                          cp: item[4],
                          type: item[9],
                          pocId: null,
                          codeName: null,
                          codeId: null,
                          tier: null,
                          // type: null,
                          sequenceName: null
                        }
                      }]
                    })
                  } else {
                    const addrs = item[5].toUpperCase().trim()
                    const addressIdx = routesList[routeIndex].sequence.map(x => { return x.text }).indexOf(addrs)
                    if (addressIdx < 0) {
                      routesList[routeIndex].sequence.push({
                        text: item[5].toUpperCase().trim(),
                        value: {
                          name: item[5].toUpperCase().trim(),
                          door: item[5].toUpperCase().split(' ')[0],
                          street: item[5].toUpperCase().replace(item[5].split(' ')[0], '').trim(),
                          seqcodetotal: routesList[routeIndex].sequence.length + 1,
                          seqcodeSys: Number(item[3].replace(/SEQ: /g, '').trim()),
                          seqcodeSysPos: Number(item[6]),
                          cptligne: cptligne,
                          info7: item[7],
                          info8: item[8],
                          info11: item[11],
                          cp: item[4],
                          type: item[9],
                          pocId: null,
                          codeName: null,
                          codeId: null,
                          tier: null,
                          // type: null,
                          sequenceName: null
                        }
                      })
                    } else {
                      const codeSys = Number(item[3].replace(/SEQ: /g, '').trim())
                      const codeSysPos = Number(item[6])
                      if (routesList[routeIndex].sequence[addressIdx].value.seqcodeSys < codeSys || routesList[routeIndex].sequence[addressIdx].value.seqcodeSysPos < codeSysPos) {
                        routesList[routeIndex].sequence[addressIdx].value.seqcodeSys = codeSys
                        routesList[routeIndex].sequence[addressIdx].value.seqcodeSysPos = codeSysPos
                      }
                    }
                  }
                }
              }
              progress = Math.round((cptligne / countligne) * 100)
              if (progress !== oldprogress) {
                oldprogress = progress
                EventBus.$emit('csvproccessprogress', (progress))
              }
            }
            routesList.forEach(route => {
              let cpt = 0
              route.sequence.sort(function (a, b) {
                if (a.value.seqcodeSys < b.value.seqcodeSys) {
                  return -1
                } else if (a.value.seqcodeSys > b.value.seqcodeSys) {
                  return 1
                } else if (a.value.seqcodeSysPos < b.value.seqcodeSysPos) {
                  return -1
                } else if (a.value.seqcodeSysPos > b.value.seqcodeSysPos) {
                  return 1
                } else {
                  return a.value.cptligne - b.value.cptligne
                }
              }).forEach(seq => {
                cpt++
                seq.value.seqcodetotal = cpt
              })
            })
            EventBus.$emit('seqcsvproccesscompleted', (routesList))
          }
        }
      }
    },
    analyseCsvFile_Back () {
      if (this.file) {
        this.loadingTitle = this.$t('common.analysing')
        this.showLoading = true
        const EventBus = this.$store.getters.EventBus
        const officeCp = this.$store.state.officeSelectedModule.data.cp
        const store = this.$store
        EventBus.$on('harmonyzeproccessprogress', (value) => {
          this.loadingsubTitle = value
        })
        EventBus.$on('seqcsvproccesscompleted', (result) => {
          this.showProgress = false
          this.showLoading = false
          this.importData = result
          this.analyseComplete = true
          // this.verifDataFile()
        })
        if (window.FileReader) {
          var reader = new FileReader()
          reader.readAsText(this.file)
          reader.onload = function (event) {
            let progress; let oldprogress
            let street
            let seqcodetotal = 0
            let seqcodestreet = 0
            var csv = event.target.result.split('\n')
            let lrname = ''
            let routeId = null
            let loffice = ''
            let lofficecp = ''
            // const pdrs = []
            // const pdrSearch = []
            let cptligne = 0
            let lastcode = ''
            let correctDoor
            // let lastseq = ''
            var route = []
            const routesList = []
            const countligne = csv.length

            for (const ligne of csv) {
              cptligne++
              const item = ligne.split(';')
              if (item.length > 1) {
                if (isNaN(item[0].replace(/ /g, ''))) {
                  if (item[0].substring(0, 38) === 'DETAILS DELIVERY POINT SEQUENCE REPORT') {
                    loffice = item[4]
                    lofficecp = item[3]
                    if (lofficecp.replace(/ /g, '') !== officeCp.replace(/ /g, '')) {
                      store.dispatch('showAlert', 'this file are not for this Office (' + loffice + ')')
                      break
                    }
                  } else if (item[0].substring(0, 5) === 'Route') {
                    if (lrname) {
                      routesList.push({ name: lrname, routeId: routeId, firstPink: null, firstBlue: null, sequence: route, sequenceItemSelect: null })
                      route = []
                      seqcodetotal = 0
                    }
                    lrname = item[1]
                    routeId = store.getters['routeModule/getRouteIdByName'](lrname.trim())
                  } else {
                    street = item[0]
                    seqcodestreet = 0
                    // lastseq = null
                    lastcode = null
                  }
                } else {
                  if (item[1]) {
                    correctDoor = item[1]
                    if (item[1].split('-').length > 1) {
                      correctDoor = item[1].split('-')[1] + '-' + item[1].split('-')[0]
                    }
                    // if (item[0] !== lastseq && correctDoor !== lastcode) {
                    if (correctDoor !== lastcode) {
                      lastcode = correctDoor
                      // lastseq = item[0]
                      seqcodestreet++
                      seqcodetotal++
                      // pdrSearch.push({ text: item[1] + ' ' + street.substring(4), value: seqcodetotal })

                      const pocSeqItem = {
                        text: correctDoor.toUpperCase().trim() + ' ' + street.toUpperCase().trim(),
                        value: {
                          name: correctDoor.toUpperCase().trim() + ' ' + street.toUpperCase().trim(),
                          door: correctDoor.toUpperCase().trim(),
                          street: street.toUpperCase().trim(),
                          seqcodetotal: seqcodetotal,
                          seqcodestreet: seqcodestreet,
                          seqcodeSys: item[0].trim(),
                          pocId: null,
                          codeName: null,
                          codeId: null,
                          tier: null,
                          // type: null,
                          sequenceName: null
                        }
                      }
                      route.push(pocSeqItem)
                    }
                  } else {
                    lastcode = ''
                    // lastseq = ''
                  }
                }
              }
              progress = Math.round((cptligne / countligne) * 100)
              if (progress !== oldprogress) {
                oldprogress = progress
                EventBus.$emit('csvproccessprogress', (progress))
              }
            }
            EventBus.$emit('seqcsvproccesscompleted', (routesList))
          }
        }
      }
    },
    verifDataFile () {
      if (this.importData) {
        if (this.importData.length) {
          this.validFile = true
        } else {
          this.validFile = false
        }
      } else {
        this.validFile = false
      }
    }
  },
  computed: {
    filteredKeys () {
      return this.keys.filter(key => key !== 'Name')
    },
    showLoadingPopup () {
      if (this.showLoading && !this.showCreateRouteSequence && !this.showMatchPocSequence) {
        return true
      } else {
        return false
      }
    }
    // routeTypeItem () {
    //   const item = []
    //   const routetype = this.$store.state.officeSelectedModule.data.routeType
    //   for (let i = 0; i < routetype.length; i++) {
    //     const type = routetype[i]
    //     item.push({ text: this.$t('routetype.' + type.typeId), value: type.typeId })
    //   }
    //   return item
    // }
  },
  mounted () {
    this.$refs.inputUploadRoute.click()
    // this.$store.dispatch('routeModule/openDBChannel', { pathVariables: { officeId: this.$store.state.userLoggedModule.data.officeselected } })
    //   .then(() => {
    //     this.loadComplete = true
    //     this.$refs.inputUploadRoute.click()
    //   })
  },
  destroyed () {
    // this.$store.dispatch('routeModule/closeDBChannel', { clearModule: true })
  }
}
</script>
