<template>
  <div class="home mx-2">
    <v-row no-gutters>
    <v-card-title>{{ $t('common.colis') }}</v-card-title>
    <v-spacer></v-spacer>
    <v-btn large icon @click="showDayColis = true"><v-icon large>mdi-plus-circle</v-icon></v-btn>
    </v-row>
    <v-card :height="$store.getters.contentHeight - 150" class="overflow-y-auto">
      <v-row no-gutters><v-spacer/><v-subheader>{{ $t(dayColisArray.length ? 'common.delivered' : 'common.clicktoAddColis') }}</v-subheader></v-row>
      <v-list dense>
        <template v-for="colis in dayColisArray">
          <v-list-item dense :key="colis.colisId + '-item'">
            <!-- <v-list-item-icon>
              <v-img contain max-width="30" max-height="30" :src="$store.getMapItemIconOfType('colis')"/>
            </v-list-item-icon> -->
            <v-list-item-content dense three-line>
              <v-list-item-title v-text="colis.pdrListAddress"></v-list-item-title>
              <v-list-item-subtitle v-text="colis.pdrListPostalCode"/>
              <v-list-item-subtitle v-text="'Code: ' + colis.codeName"/>
              <v-list-item-subtitle class="red--text" v-if="colis.canShipMulti" v-text="colis.shipToMulti ? $t('shipTo.' + colis.multiType) : $t('shipTo.atDoor')"/>
            </v-list-item-content>
            <v-list-item-action>
              <v-checkbox
                :false-value="false"
                :input-value="colis.isDone"
                @change="$store.commit('routeDayDataModule/setColisDone',{ colisId: colis.colisId, isDone: $event })"
                ></v-checkbox>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="colis.colisId + '-divider'"></v-divider>
        </template>
      </v-list>
    </v-card>
    <day-colis-popup v-if="showDayColis" @close="showDayColis = false"/>
  </div>
</template>

<script>
import DayColisPopup from '../../common/DayColisPopup.vue'
export default {
  components: { DayColisPopup },
  name: 'ColisDrawerItem',
  props: ['mapMode'],
  data () {
    return {
      ts: null,
      showDayColis: false
    }
  },
  computed: {
    dayColisArray () {
      // // console.log(this.$store.state.routeDayDataModule.data.itemDone)
      // // console.log(this.$store.getters['routeDayDataModule/getColisOfday'].map(x => {
      //   x.isDone = this.$store.getters['routeDayDataModule/isItemDone'](x.colisId)
      //   return x
      // }))
      // // console.log(this.$store.getters['routeDayDataModule/getColisOfday'])
      // if (this.$store.getters['routeDayDataModule/getColisOfday']) {
      //   return this.$store.getters['routeDayDataModule/getColisOfday'].map(x => {
      //     // console.log(x)
      //     x.item = this.$store.getters['routeDayDataModule/isItemDone'](x.colisId)
      //     return x
      //   })
      // } else {
      //   return []
      // }
      return this.$store.getters['routeDayDataModule/getColisOfday']
    },
    travelItems () {
      return this.$store.getters['officeTravelModule/getActiveTravels']
    },
    travelItemsValue () {
      return this.travelItems.map(x => { return x.id })
    },
    travelOffical () {
      return this.$store.getters['officeTravelModule/getOfficalTravel']
    },
    travelSelected () {
      if (this.$store.getters.getSelectedTravelId && this.travelItemsValue.indexOf(this.$store.getters.getSelectedTravelId) > -1) {
        return this.travelItems[this.travelItemsValue.indexOf(this.$store.getters.getSelectedTravelId)]
      } else if (this.travelOffical) {
        return this.travelOffical
      } else {
        return null
      }
    }
  },
  methods: {
    getTransportTypeName (type) {
      switch (type) {
        case 'taxi':
          return 'Taxi'
        case 'truck':
          return this.$t('common.vehicle')
        default:
          return this.$t('common.vehicle')
      }
    },
    getPartName (part) {
      switch (part) {
        case 'all':
          return this.$t('common.full')
        case '1':
          return this.$t('common.orange')
        case '2':
          return this.$t('common.pink')
        case '3':
          return this.$t('common.blue')
        default:
          return this.$t('common.full')
      }
    },
    changeTravel (travel) {
      // // console.log(travelId)
    }
  }
}
</script>
