<template>
  <div width="95%" max-width="95%%" style="overflow-hidden">
    <Draggable
      class="dragArea"
      :list="list"
      :group="{ name: 'g1' }"
      handle=".handle"
      ghost-class="ghost"
      @change="$emit('travelChange', $event)"
      :emptyInsertThreshold="100"
      v-bind="dragOptions"
      @start="drag = true"
      @end="drag = false"
    >

      <transition-group type="transition" :name="!drag ? 'flip-list' : null">
        <!-- <div :max-width="itemWidth" class="align-end list-group-item" v-for="(item, idx) in list" :key="item.id">
          <v-card class="mb-1 me-1 list-group-item" :width="itemWidth" :max-width="itemWidth" style="overflow-hidden" :color="$store.getTravelItemCardColor(item)">
            <div v-if="item.items && item.items.length" :max-width="itemWidth" :width="itemWidth" style="overflow-hidden"> -->
        <div width="100%" class="mx-1 align-end list-group-item" v-for="(item, idx) in list" :key="item.id">
          <v-card class="mb-1 me-1 list-group-item" width="100%" style="overflow-hidden" :color="$store.getTravelItemCardColor(item)">
            <div v-if="item.items && item.items.length" width="100%" style="overflow-hidden">
              <v-list-group
                v-model="item.isOpen"
                :class="item.isOpen ? 'pb-3' : ''"
                :active-class="getItemGroupActiveClass(item)"
                width="100%"
                style="overflow-hidden"
                :color="$store.getTravelItemColor(item)"
              >
                <template v-slot:activator>
                  <travel-editor-item
                    @removeItem="removeItem($event)"
                    :showAction="true"
                    :item="item"
                    :draggable="draggable"
                  ></travel-editor-item>
                </template>
                <div style="overflow-hidden">
                <travel-editor-nested :draggable="draggable" :itemsPath="getItemsPath(idx)" @travelChange="$emit('travelChange', $event)" @removeItem="removeItem($event)" class="ps-2 ps-sm-2 ps-lg-2 ps-xl-3" v-if="item.items && item.items.length" :list="item.items"/>
                <!-- <travel-editor-nested :draggable="draggable" :itemsPath="getItemsPath(idx)" :itemWidth="Math.round(itemWidth * 0.98)" @travelChange="$emit('travelChange', $event)" @removeItem="removeItem($event)" class="ps-2 ps-sm-2 ps-lg-2 ps-xl-3" v-if="item.items && item.items.length" :list="item.items"/> -->
                </div>
              </v-list-group>
            </div>
            <div v-else>
              <travel-editor-item
                @removeItem="removeItem($event)"
                showAction
                :item="item"
                :draggable="draggable"
              ></travel-editor-item>
            </div>
          </v-card>
        </div>
      </transition-group>
    </Draggable>
    <pmt-edit-pdr-popup
      v-if="showEditPdr"
      :pdrId="showEditPdr"
      @close="showEditPdr = null"
    ></pmt-edit-pdr-popup>
  </div>
</template>
<script>
import Draggable from 'vuedraggable'
export default {
  name: 'travel-editor-nested',
  props: {
    list: {
      required: true,
      type: Array
    },
    itemsPath: {
      required: true,
      type: Array
    },
    // itemWidth: {
    //   required: true,
    //   type: Number
    // },
    draggable: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Draggable
  },
  data () {
    return {
      drag: false,
      showEditPdr: null,
      tempList: this.list
    }
  },
  computed: {
    dragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  methods: {
    getItemsPath (idx) {
      const path = this.itemsPath
      path.push(idx)
      return path
    },
    removeItem (item) {
      this.$emit('removeItem', item)
    },
    getItemGroupActiveClass (item) {
      switch (item.type) {
        case 'color':
          return this.$store.getColorName(item.colorId) + ' lighten-2'
        case 'sector':
          return (Number(this.$store.getTravelItemColorId(item)) ? this.$store.getTravelItemColor(item) : 'grey') + ' lighten-4'
        case 'code':
          return this.$store.getColorName(item.colorId) + ' lighten-4'
        case 'multi':
          return 'grey lighten-2'
        default:
          return null
      }
    }
  },
  mounted () {
    // // console.log(this.list)
  }
}
</script>
<style scoped>
.dragArea {
  min-height: 50px;
}
/* .list-group-item {
  align-items: flex-end;
} */
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list__empty > div{
  min-height: 100px;
  width: 100%;
  border:dashed #ccc 3px;
}
</style>
