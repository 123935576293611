<template>
    <v-card>
        <v-navigation-drawer app v-model='ldrawer'  temporary>
            <template v-slot:prepend>
                <v-list shaped>
                    <v-list-item>
                        <v-row>

                            <v-img
                            @click="ldrawer = false"
                            alt="CanadaPost Logo"
                            contain
                            :src="require('@/assets/Icon/MenuIcon/logo_postes_canada.png')"
                            transition="scale-transition"
                            height="70"
                            width="70"
                            />
                            <v-menu>
                              <template v-slot:activator="{ on: menu }">
                                    <v-list width="70%">
                                      <v-list-item
                                        link
                                        v-on="{ ...menu }"
                                      >
                                        <v-list-item-content>
                                            <v-list-item-subtitle class="subtitle">{{ $store.state.userLoggedModule.data.empid }}</v-list-item-subtitle>
                                            <v-list-item-subtitle class="subtitle">{{ $store.state.userLoggedModule.data.lname }}, {{ $store.state.userLoggedModule.data.fname }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-icon>mdi-menu-down</v-icon>
                                        </v-list-item-action>
                                      </v-list-item>
                                    </v-list>
                              </template>
                              <v-list>
                                <v-list-item
                                  class="pt-2"
                                  @click="logout()"
                                >
                                  <v-list-item-title>{{ $t('nav_drawer.useritemlogout') }}</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                  @click="$store.dispatch('switchLocale')"
                                >
                                  <v-list-item-title>{{ $t('common.otherlocale') }}</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                        </v-row>
                    </v-list-item>

                    <v-list-item
                      round
                      link
                    >
                        <v-list-item-content>
                            <v-list-item-title class="title">{{ $store.state.officeSelectedModule.data.name }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-icon>mdi-menu-down</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </template>

            <v-divider></v-divider>

            <v-list nav shaped>
              <v-list-item
                class="text-center"
                v-for="item in items"
                :key="item.title"
                link
                @click="selectitem(item.link)"
              >

              <v-list-item-content class="mx-auto" @click="selectitem('pmt-road-import')" clearfix>
                <v-container v-if="$store.getters.contentHeight >= 660">
                  <v-icon :size="$store.getters.menuIconSize" color="primary"> {{ item.icon }}</v-icon>
                  <v-list-item-title class="subtitle text-center">{{ item.title }}</v-list-item-title>
                </v-container>
                <v-container v-if="$store.getters.contentHeight < 660 ">
                  <v-row>
                  <v-flex xs6>
                    <v-list-item-title class="subtitle text-center mt-3">{{ item.title }}</v-list-item-title>
                  </v-flex>
                  <v-flex xs6>
                    <v-icon :size="$store.getters.menuIconSize" color="primary"> {{ item.icon }}</v-icon>
                  </v-flex>
                  </v-row>
                </v-container>
              </v-list-item-content>
                <!--
                <v-list-item-content  class="mx-auto" clearfix>
                  <v-row>
                  <div height="100px" width="100px" class="mx-auto">
                    <v-img
                      :src="item.icon"
                      alt="contact"
                      height="100"
                      width="100"
                    ></v-img>
                  </div>
                  <v-list-item-title class="title">{{ item.title }}</v-list-item-title>
                  </v-row>
                </v-list-item-content>
                -->
              </v-list-item>
           </v-list>
        </v-navigation-drawer>
    </v-card>
</template>
<script>
export default {
  name: 'NavDrawer',
  props: {
    drawer: Boolean,
    username: String,
    email: String,
    avatar_path: String

  },
  methods: {
    selectitem (choice) {
      if (choice === 'app') {
        this.$router.replace('/app')
      } else {
        this.$emit('menu-item-selected', choice)
      }
      this.ldrawer = false
    },
    logout () {
      this.$router.push('/login')
      this.$store.dispatch('logout')
    }
  },
  computed: {
    items () {
      return [
        { title: this.$t('nav_drawer.menuhome'), icon: 'mdi-home', link: 'pmt-menu-admin-local' },
        { title: this.$t('nav_drawer.returnapp'), icon: 'mdi-arrow-left-circle', link: 'app' }
      ]
    }
  },
  data () {
    return {
      ldrawer: false,
      right: null
    }
  },
  watch: {
    ldrawer (newValue) {
      if (newValue !== this.drawer) {
        this.$emit('drawer-state-changed', newValue)
      }
    },
    drawer (newValue) {
      this.ldrawer = newValue
    }
  }
}
</script>
