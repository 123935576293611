<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="666" fixed persistent transition="dialog-bottom-transition">
      <v-card>
        <v-app-bar dark color="primary">
          <v-btn icon x-large dark @click="dialog = false">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ $t('createreport.' + danger.type) }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon x-large>{{ $store.getAlertMdiIcon(danger.type) }}</v-icon>
        </v-app-bar>
        <div class="overflow-y-auto">
          <v-container v-show="danger.desc" class="mb-n2">
            <p class="title">Description :</p>
            <p class="mt-n2">{{ danger.desc }}</p>
          </v-container>
          <v-container class="mt-n1">
            <v-carousel
              v-if="danger.imageList.length"
              :show-arrows="danger.imageList.length > 1"
              hide-delimiters
              height="200"
            >
              <v-carousel-item
                contain
                max-height="300"
                v-for="(item,i) in danger.imageList"
                :key="i"
                :src="item.url"
                reverse-transition="fade-transition"
                transition="fade-transition"
                @click="$store.getters.EventBus.$emit('ShowImageListFullScreen', danger.imageList)"
              ></v-carousel-item>
            </v-carousel>
          </v-container>
          <v-container v-if="danger.owner && $store.state.pocModule.data[danger.owner]">
            <p class="title">{{ $t('common.owner') }} :</p>
            <p class="mt-n2">{{ $store.state.pocModule.data[danger.owner].pdrListAddress }}</p>
          </v-container>
          <v-container v-show="pocBlockedArray">
            <p class="title">{{ $t('createreportdog.blockpdrtitle') }} :</p>
            <p class="mt-n2" v-for="(poc, i) in pocBlockedArray" :key="i">
              {{ $store.state.pocModule.data[poc.pdrId] ? $store.state.pocModule.data[poc.pdrId].pdrListAddress : '' }}
            </p>
          </v-container>
          <v-container v-show="danger.note && danger.note.length" class="mb-n2">
            <p class="title">note :</p>
            <p class="mt-n2" v-for="(note, i) in danger.note" :key="i">{{ note.desc }}</p>
          </v-container>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ['danger'],
  data () {
    return {
      dialog: true
    }
  },
  computed: {
    pocBlockedArray () {
      if (this.danger && this.danger.pocList.length) {
        return this.danger.pocList.filter(x => x.isBlocked)
      } else {
        return null
      }
    }
  },
  watch: {
    dialog (newValue) {
      this.$store.dispatch('resize')
      this.dialog = false
      this.$emit('close')
    }
  }
}
</script>
