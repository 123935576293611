<template>
  <div ref="home" class="home" :style="contentstyles.styles">
    <div v-show="false">
      <div id="OtherRouteInfoDiv">
        <v-card v-if="otherRouteDetail">
          <v-card-title>{{ otherRouteDetail.name }}</v-card-title>
          <div v-if="otherRouteDetailStat">
            <v-card-text v-for="stat in otherRouteDetailStat" :key="stat.id" class="mt-n5 mb-n3">
              {{ stat.name + ' : ' + stat.detail}}
          </v-card-text>
          </div>
          <v-card-actions>
            <v-btn x-large block @click="changeRouteClick()" >{{ $t('common.loading') }}<v-icon x-large>mdi-cloud-download</v-icon></v-btn>
          </v-card-actions>
        </v-card>
      </div>
      <div id="InfoWindowDetailPlaceDiv">
        <v-card  v-if="infoWindowDetailPlace">
          <v-card-title>{{ infoWindowDetailPlace.title }}</v-card-title>
          <!-- <div v-if="otherRouteDetailStat">
            <v-card-text v-for="stat in otherRouteDetailStat" :key="stat.id" class="mt-n5 mb-n3">
              {{ stat.name + ' : ' + stat.detail}}
            </v-card-text>
          </div> -->
          <v-card-actions>
            <v-btn x-large block @click="changeRouteClick()" >{{ $t('common.loading') }}<v-icon x-large>mdi-cloud-download</v-icon></v-btn>
          </v-card-actions>
        </v-card>
      </div>
      <div id="InfoWindowDetailPocDiv">
        <v-card>
          <v-card-title ref="test" name="test" class="test">test</v-card-title>

          <v-card-actions>
            <v-btn x-large block @click="changeRouteClick()" >{{ $t('common.loading') }}<v-icon x-large>mdi-cloud-download</v-icon></v-btn>
          </v-card-actions>
        </v-card>
      </div>
      <div id="mapGuardianControl">
        <v-btn class="ms-2" style="width:10px" @click="$store.dispatch('switchGuardianMode')">
          <v-icon size="35" color="primary">{{ $store.getters.getGuardianMode ? 'mdi-target-account' : 'mdi-target' }}</v-icon>
        </v-btn>
      </div>
      <div id="levelUpControl">
        <v-btn
          id="levelUpControlBtn"
          class="ms-2 mt-3"
          @click="clickLevelUp"
          v-show="showLevelUp"
          color="white"
          >
          <v-icon large>mdi-arrow-top-left-bold-outline</v-icon>
        </v-btn>
      </div>
      <div id="menuLeftBottomControl" v-show="!menuControlRight">
        <v-speed-dial
          v-model="menuControlLeft"
          direction="right"
          transition="'slide-x-transition'"
        >
          <template v-slot:activator>
            <v-btn
              class="ms-2"
              v-model="menuControlLeft"
              color="primary"
              large
              dark
              fab
            >
              <v-icon v-if="menuControlLeft" large>mdi-close</v-icon>
              <v-icon v-else large>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-btn
            fab
            dark
            large
            :color="$store.getters.getGuardianMode ? 'green' : 'primary'"
            @click="$store.dispatch('switchGuardianMode')"
          >
            <v-icon large>{{ $store.getters.getGuardianMode ? 'mdi-target-account' : 'mdi-target' }}</v-icon>
          </v-btn>
          <v-btn
            fab
            @click="directionsRenderer ? clearDirectionsRenderer() : showCreateMapDirection = true"
            dark
            large
            :color="directionsRenderer ? 'green' : 'primary'"
          >
            <v-icon large>mdi-directions</v-icon>
          </v-btn>
          <v-btn
            fab
            dark
            large
            color="primary"
            @click="showParam=!showParam"
          >
            <v-icon large>mdi-tune</v-icon>
          </v-btn>

        </v-speed-dial>
      </div>
      <div id="menuRightBottomControl" v-show="!menuControlLeft">
        <v-speed-dial
          class="mb-3 me-2"
          v-model="menuControlRight"
          direction="left"
          transition="'slide-x-reverse-transition'"
        >
          <template v-slot:activator>
            <v-btn
              v-model="menuControlRight"
              color="primary"
              large
              dark
              fab
            >
              <v-icon v-if="menuControlRight" large>mdi-close-box-multiple</v-icon>
              <v-icon v-else large>mdi-checkbox-multiple-blank</v-icon>
            </v-btn>
          </template>
          <v-btn
            fab
            dark
            large
            :color="showinfobox ? 'green' : 'primary'"
            @click="clickInfo"
          >
            <v-icon x-large>mdi-information-variant</v-icon>
          </v-btn>
          <v-btn
            fab
            dark
            large
            :color="showStreetView ? 'green' : 'primary'"
            @click="clickView"
          >
            <v-icon x-large>mdi-binoculars</v-icon>
          </v-btn>
        </v-speed-dial>
      </div>
    </div>
    <div ref="userMaps" class="App" id="userMaps" :style="mapstyles"/>
    <div v-show="showinfobox" class="overflow-y-auto overflow-x-hidden" align-center id="infobox" :style="'width:100%; height:' + infoboxHeight + 'px'">
      <div>
        <v-container v-show="showLevel=='directionsRenderer'" class="mx-auto" max-width="744" :style="'width:100%; height:' + infoboxHeight + 'px'">
          <div id="directionsPanel" :style="'width:100%; height:' + infoboxHeight + 'px'"></div>
        </v-container>
        <v-card v-if="showLevel=='placeDetail' && select.place" fixed max-width="744" :style="'width:100%'">
          <v-row>
            <v-card-title class="ms-3">{{ $t('pmtmapsinfo.placeinfo') }} : {{ select.place.name }}</v-card-title>
            <v-spacer></v-spacer>
            <div class="float-right me-6"><pmt-maps-popup-placeinfo class="float-right" :place='select.place' :src="streetViewPictureSrc" :contentstyles='contentstyles.styles'/></div>
          </v-row>
          <v-card-text class="subtitle-2">{{ select.place.fAddress }}</v-card-text>
          <v-card-text>
            <v-card class="mt-n4">
            <v-simple-table>
              <template v-slot:default>
                <thead class="black">
                  <tr>
                    <th v-for="item in placeMarkerStat" :key="item.id" class="text-center white--text subtitle-2">{{ item.name }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="text-center">
                    <td v-for="item in placeMarkerStat" :key="item.id" :class="$store.getColorText(select.place.color) + ' headline'">{{ item.detail }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            </v-card>
          </v-card-text>
        </v-card>
        <v-card v-if="showLevel=='placeOfCode' && select.code" class="mx-auto" max-width="744" :style="'width:100%'">
          <v-card-title>{{ $t('pmtmapsinfo.codeinfo') }} : {{ select.code.name }}</v-card-title>
          <v-card-text>
              {{ select.code.stat.startCodeAddress }}
          </v-card-text>
          <v-card-text class="mt-n8 ms-3">
              <v-icon>mdi-arrow-right</v-icon> {{ select.code.stat.endCodeAddress }}
          </v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead class="black">
                <tr>
                  <th v-for="item in codeMarkerStat" :key="item.id" class="text-center white--text subtitle-2">{{ item.name }}</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center">
                  <td v-for="item in codeMarkerStat" :key="item.id" :class="$store.getColorText(select.code.color) + ' subtitle-2'">{{ item.detail }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <v-sheet v-if="showLevel=='codeOfColor' && select.color" class="mx-auto" max-width="744" :style="'width:100%'">
          <v-card-title>
            <v-row>
            <p class="me-2">{{ $t('pmtmapsinfo.colorinfo') }} : </p>
            <p :class="$store.getColorText(select.color.id)" v-if="select.color.id == 1"> {{ $t('common.orange') }}</p>
            <p :class="$store.getColorText(select.color.id)" v-if="select.color.id == 2"> {{ $t('common.pink') }}</p>
            <p :class="$store.getColorText(select.color.id)" v-if="select.color.id == 3"> {{ $t('common.blue') }}</p>
            </v-row>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead class="black">
                <tr>
                  <th v-for="item in colorMarkerStat" :key="item.id" class="text-center white--text subtitle-2">{{ item.name }}</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center">
                  <td v-for="item in colorMarkerStat" :key="item.id" :class="$store.getColorText(select.color.id) + ' subtitle-2'">{{ item.detail }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

        </v-sheet>
        <v-sheet v-if="showLevel=='colorOfRoute' && select.route" class="mx-auto" max-width="744" :style="'width:100%'">
          <v-card-title> {{ $t('pmtmapsinfo.routeinfo') }} : {{ select.route.name }}
          </v-card-title>
          <v-sheet scrollable>
            <v-simple-table class="mt-n2 mx-auto">
              <template v-slot:default>
                <thead class="black">
                  <tr>
                    <th v-for="item in routeMarkerStat.orange" :key="item.id" class="text-center white--text subtitle-2">{{ item.name }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="text-center">
                    <td v-for="item in routeMarkerStat.orange" :key="item.id" :class="$store.getColorText(1) + ' subtitle-2'">{{ item.detail }}</td>
                  </tr>
                  <tr class="text-center">
                    <td v-for="item in routeMarkerStat.pink" :key="item.id" :class="$store.getColorText(2) + ' subtitle-2'">{{ item.detail }}</td>
                  </tr>
                  <tr class="text-center">
                    <td v-for="item in routeMarkerStat.blue" :key="item.id" :class="$store.getColorText(3) + ' subtitle-2'">{{ item.detail }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-sheet>
        </v-sheet>
        <v-card v-if="showLevel=='routeOfOffice' && select.office" class="mx-auto" max-width="744" :style="'width:100%'">
          <v-card-title> {{ $t('pmtmapsinfo.officeinfo') }} : {{ select.office.name }}
          </v-card-title>
          <v-card-text>
          </v-card-text>
        </v-card>
        <v-card v-if="showLevel=='officeOfZone' && select.zone" class="mx-auto" max-width="744" :style="'width:100%'">
          <v-card-title> {{ $t('pmtmapsinfo.zoneinfo') }} : {{ select.zone.name }}
          </v-card-title>
          <v-card-text>
          </v-card-text>
        </v-card>
        <v-card v-if="showLevel=='zone'" class="mx-auto" max-width="744" :style="'width:100%'">
          <v-card-title> {{ $t('pmtmapsinfo.globalinfo') }}
          </v-card-title>
          <v-card-text>
          </v-card-text>
        </v-card>
      </div>

    </div>
    <div v-show="showStreetView" id="streetView1" max-width="744" style="width:100%; height:250px">
      <v-img
        class="mx-auto"
        id="streetPicture"
        height="100%"
        width="100%"
        alt="no picture"
        max-width="700"
        :src="streetViewPictureSrc"
      >
      </v-img>
    </div>
    <pmt-param-maps-info @close="showParam = false" v-if="showParam"/>
    <pmt-create-map-direction-popup @return-renderer="directionsRenderer = $event" @close="showCreateMapDirection = false" v-if="showCreateMapDirection"></pmt-create-map-direction-popup>
    <loading-popup v-if="!$store.getters.dataLoadComplete" :title="showLoadingTitle"></loading-popup>
    <confirm-popup
      v-if="showConfirm"
      @cancel="showConfirm = false"
      @confirm="doConfirmedTask($event)"
      :title="showConfirmTitle"
      :item="showConfirmItem"
      :from="showConfirmFrom"
      :to="showConfirmTo"
    ></confirm-popup>
  </div>
</template>
<script>
// import Vue from 'vue'
// const EventBus = new Vue()

export default {
  props: ['contentstyles'],
  data () {
    return {
      infoWindowDetailPlace: null,
      infoWindowDetailPoc: null,
      otherRouteInfoWindow: new this.$store.getters.getGoogle.maps.InfoWindow(),
      otherRouteInfoWindowContent: '',
      otherRouteDetail: null,
      routeStat: null,
      showLoading: false,
      showLoadingTitle: this.$t('common.loading'),
      showConfirm: false,
      showConfirmTitle: '',
      showConfirmFrom: null,
      showConfirmTo: null,
      showConfirmParam: null,
      showConfirmItem: null,
      showLevelUp: false,
      showinfobox: false,
      showStreetView: false,
      directionsRenderer: null,
      showCreateMapDirection: false,
      showParam: false,
      menuControlLeft: false,
      menuControlRight: false,
      bottomNav: 7,
      show: { showDog: false, showColorLine: false, showToilet: false, showGreyBox: false },
      // select: { zone: null, office: null, route: null, color: null, code: null, place: null, pdr: null },
      showLevel: '',
      postManMarker: null,
      dogMarker: [],
      reportMarker: [],
      // greyBoxMarker: [],
      showedMarkerList: [],
      showedLineMarkerList: [],
      showedGreyBoxMarkerList: [],
      showedToiletMarkerList: [],
      showedAlertMarkerList: [],
      mapOption: { startzoom: 14, startCenter: { lat: 46.825713, lng: -71.166643 } },
      pocItemTitle: {
        door: this.$t('pocitem.door'),
        tempbox: this.$t('pocitem.tempbox'),
        safeplace: this.$t('pocitem.safeplace'),
        dtd: this.$t('pocitem.dtd'),
        cmb: this.$t('pocitem.cmb'),
        lba: this.$t('pocitem.lba'),
        aptlba: this.$t('pocitem.aptlba'),
        ksk: this.$t('pocitem.ksk'),
        dflb: this.$t('pocitem.dflb'),
        rmb: this.$t('pocitem.rmb'),
        cntr: this.$t('pocitem.cntr')
      }
    }
  },
  destroyed () {
    this.placeMarker = null
    this.codeMarker = null
  },
  watch: {
    toShowRouteItemMarkerInfo (newValue, oldValue) {
      if (oldValue) {
        if (oldValue.markerData) {
          oldValue.markerData.forEach(marker => {
            marker.setMap(null)
          })
        }
      }
      if (newValue) {
        if (newValue.level !== 'zone') {
          this.showLevelUp = true
        } else {
          this.showLevelUp = true
        }
        const bounds = new this.google.maps.LatLngBounds()
        newValue.markerData.forEach(marker => {
          marker.setMap(this.map)
          bounds.extend(marker.getPosition())
        })
        this.map.fitBounds(bounds)
      }
    },
    locale (newValue) {
      this.pocItemTitle = {
        door: this.$t('pocitem.door'),
        tempbox: this.$t('pocitem.tempbox'),
        safeplace: this.$t('pocitem.safeplace'),
        dtd: this.$t('pocitem.dtd'),
        cmb: this.$t('pocitem.cmb'),
        lba: this.$t('pocitem.lba'),
        aptlba: this.$t('pocitem.aptlba'),
        ksk: this.$t('pocitem.ksk'),
        dflb: this.$t('pocitem.dflb'),
        rmb: this.$t('pocitem.rmb'),
        cntr: this.$t('pocitem.cntr')
      }
    },
    sequenceRouteMarker (newValue) {
      if (newValue.isOfficialSequence) {
        this.$store.dispatch('routeModule/setRouteStat', { id: this.$store.getters.getSelectedRouteId, stat: newValue.sequenceStat })
      }
      // if (this.colorSelectId) {
      //   this.showMapMasterControl()
      // }
    },
    directionsRenderer (newValue) {
      if (newValue) {
        newValue.setMap(this.map)
        // newValue.setPanel(document.getElementById('directionsPanel'))
      }
      this.showMapMasterControl()
    },
    currentPosition (newValue) {
      if (this.guardianMode) {
        // this.map.setCenter(newValue)
        this.postManMarker.setPosition(newValue)
        this.postManMarker.setMap(this.map)
      } else {
        if (this.postManMarker) { this.postManMarker.setMap(null) }
      }
    },
    zoneSelectId (newValue) { this.showMapMasterControl() },
    officeSelectId (newValue) { this.showMapMasterControl() },
    routeSelectId (newValue) { this.showMapMasterControl() },
    colorSelectId (newValue) { this.showMapMasterControl() },
    codeSelectId (newValue) { this.showMapMasterControl() },
    placeSelectId (newValue) { this.showMapMasterControl() },
    isDataLoadComplete (newValue) {
      if (newValue) {
        // this.sequenceRouteMarker = this.$store.getters['pocModule/getPlaceMarkerOfSequence'](this.$store.getters.getSelectedSequenceId, this.$store.getters.getSelectedRouteId)
        // this.$store.getters['pocModule/getPlaceMarkerOfSequence'](this.$store.getters.getSelectedSequenceId, this.$store.getters.getSelectedRouteId)
        //   .then((seqRouteMarker) => {
        //     this.sequenceRouteMarker = seqRouteMarker
        //     this.showMapMasterControl()
        //   })
        this.showMapMasterControl()
      }
      // if (this.map) {
      //   this.createListener()
      // }
    },
    showColorLine (newValue) {
      this.showedLineMarkerList.forEach(showLine => {
        showLine.line.setMap(newValue ? this.map : null)
      })
    },
    showinfobox (newValue) {
      if (newValue) {
        this.showStreetView = false
      }
    },
    showStreetView (newValue) {
      if (newValue) {
        this.showinfobox = false
      }
    },
    showToilet (newValue) {
      if (newValue) {
        this.showToiletMarker()
      } else {
        this.hideToiletMarker()
      }
    },
    showGreyBox (newValue) {
      if (newValue) {
        this.showGreyBoxMarker()
      } else {
        this.hideGreyBoxMarker()
      }
    },
    showDog (newValue) {
      if (newValue) {
        this.showAlertMarker()
      } else {
        this.hideAlertMarker()
      }
    },
    showLevel (newValue) {
      // this.showedMarkerList.forEach(marker => {
      //   marker.setMap(null)
      // })
      // this.showedMarkerList = []
      // if (newValue === 'zone' || newValue === 'directionsRenderer') {
      //   this.levelUp = false
      // } else {
      //   this.levelUp = true
      // }
    }
  },
  async mounted () {
    if (this.toShowRouteItemMarkerInfo.markerData) {
      const bounds = new this.google.maps.LatLngBounds()
      this.toShowRouteItemMarkerInfo.markerData.forEach(marker => {
        marker.setMap(this.map)
        bounds.extend(marker.getPosition())
      })
      this.map.fitBounds(bounds)
      if (this.toShowRouteItemMarkerInfo !== 'zone') {
        this.showLevelUp = true
      } else {
        this.showLevelUp = true
      }
    }
    if (this.sequenceRouteMarker.routePos) { this.$store.dispatch('routeModule/replaceRouteSeqPos', this.sequenceRouteMarker.routePos) }
    this.map.controls[this.google.maps.ControlPosition.LEFT_BOTTOM].push(document.getElementById('menuLeftBottomControl'))
    this.map.controls[this.google.maps.ControlPosition.RIGHT_BOTTOM].push(document.getElementById('menuRightBottomControl'))
    this.map.controls[this.google.maps.ControlPosition.LEFT_TOP].push(document.getElementById('levelUpControl'))
    if (!this.$store.getters.getUserSelectPlacePictureSrc && this.select.place) {
      // this.$store.dispatch('updateUserSelectPlacePictureSrc')
    }

    if (this.isDataLoadComplete && this.map) {
      this.createListener()
      this.showMapMasterControl()
      this.postManMarker = new this.google.maps.Marker({
        // position: { lat: report.dangergps.latitude, lng: report.dangergps.longitude },
        map: null,
        zIndex: 999999,
        icon: { // url:mdi-toilet,
          url: require('@/assets/Icon/MapIcon/postman.png'),
          scaledSize: new this.google.maps.Size(40, 40), // scaled size
          origin: new this.google.maps.Point(0, 0), // origin
          anchor: new this.google.maps.Point(20, 20) // anchor
        }
      })
    }
  },
  methods: {
    createInfoWindowOfPlace (markerDetail) {
      const place = this.$store.state.placeModule.data[markerDetail.placeId]
      // let html = '<br><button>edit</button</br>'
      // html += '<h2>' + place.lName + '</h2>'
      // html += this.getStatTabHtml(markerDetail.statArray)
      // // console.log('trace place name = ' + place.lName)
      this.infoWindowDetailPlace = { placeId: markerDetail.placeId, title: place.lName }
      const infoWindow = new this.google.maps.InfoWindow()
      infoWindow.setContent(document.getElementById('InfoWindowDetailPlaceDiv').cloneNode(true).outerHTML)
      infoWindow.setPosition(place.homeLocation)
      return infoWindow
    },
    createInfoWindowOfPoc (markerDetail) {
      // infoContent.getElementsByTagName('v-card-title').innerText = 'test de title'
      this.infoWindowDetailPoc = markerDetail.poc
      const infoContent = document.getElementById('InfoWindowDetailPocDiv').cloneNode(true)
      const infoWindow = new this.google.maps.InfoWindow()
      infoContent.getElementsByClassName('test')[0].innerHTML = 'milk'
      infoWindow.setContent(infoContent.outerHTML)
      infoWindow.setPosition(markerDetail.poc.homeLocation)
      return infoWindow
    },
    editPlaceClick () {
      // // console.log('trace edit click')
    },
    getStatTabHtml (statArray) {
      let useApp = false
      let useHou = false
      let useBus = false
      let useFarm = false
      let cpt = 0
      statArray.forEach(stat => {
        if (stat.app || stat.appV) { useApp = true }
        if (stat.hou || stat.houV) { useHou = true }
        if (stat.bus || stat.busV) { useBus = true }
        if (stat.farm || stat.farmV) { useFarm = true }
      })
      if (useApp) { cpt++ }
      if (useHou) { cpt++ }
      if (useBus) { cpt++ }
      if (useFarm) { cpt++ }
      const pSize = cpt === 3 ? 33 : 100 / cpt
      let html = '<table style="border-collapse: collapse; width: 100%;text-align: center;" border="5"><tbody><tr>'
      if (useHou) {
        html += '<td style="width: ' + pSize + '%;">' + this.$t('common.sdom') + '</td>'
      }
      if (useApp) {
        html += '<td style="width: ' + pSize + '%;">' + this.$t('common.sapp') + '</td>'
      }
      if (useBus) {
        html += '<td style="width: ' + pSize + '%;">' + this.$t('common.sbus') + '</td>'
      }
      if (useFarm) {
        html += '<td style="width: ' + pSize + '%;">' + this.$t('common.sfrm') + '</td>'
      }
      html += '</tr>'
      statArray.forEach(stat => {
        html += '<tr>'
        if (useHou) {
          html += '<td style="width: ' + pSize + '%;">' + stat.houP + '/' + stat.hou + ' (' + stat.houV + ')' + '</td>'
        }
        if (useApp) {
          html += '<td style="width: ' + pSize + '%;">' + stat.appP + '/' + stat.app + ' (' + stat.appV + ')' + '</td>'
        }
        if (useBus) {
          html += '<td style="width: ' + pSize + '%;">' + stat.busP + '/' + stat.bus + ' (' + stat.busV + ')' + '</td>'
        }
        if (useFarm) {
          html += '<td style="width: ' + pSize + '%;">' + stat.farmP + '/' + stat.farm + ' (' + stat.farmV + ')' + '</td>'
        }
        html += '</tr>'
      })
      html += '</tbody></table>'
      return html
    },
    doConfirmedTask (param) {
      this.showConfirm = false
      this.otherRouteInfoWindow.close()
      if (param.type === 'changeOffice') {
        this.$store.dispatch('changeOffice', param.newId)
        this.$store.dispatch('userSelectionOffice', param.newId)
      } else if (param.type === 'changeRoute') {
        this.$store.dispatch('changeRoute', param.newId)
        this.$store.dispatch('userSelectionRoute', param.newId)
      } else if (param.type === 'changeSequence') {
        this.$store.dispatch('changeSequence', param.newId)
      }
    },
    changeRouteClick () {
      this.showConfirmItem = { type: 'changeRoute', newId: this.otherRouteDetail.id }
      this.showConfirmTitle = this.$t('common.changerouteconfirm')
      this.showConfirmFrom = this.$store.state.routeModule.data[this.$store.getters.getSelectedRouteId].name
      this.showConfirmTo = this.otherRouteDetail.name
      this.showConfirm = true
    },
    showOtherRouteInfoWindow (routeId) {
      this.otherRouteInfoWindow.close()
      if (!this.otherRouteInfoWindowContent) {
        this.otherRouteInfoWindowContent = document.getElementById('OtherRouteInfoDiv')
      }
      this.otherRouteDetail = this.showedMarkerList[this.showedMarkerList.map(function (x) { return x.id }).indexOf(routeId)]
      this.otherRouteInfoWindow.setPosition(this.otherRouteDetail.marker.getPosition())
      this.otherRouteInfoWindow.setContent(this.otherRouteInfoWindowContent)
      this.otherRouteInfoWindow.open(this.map)
    },
    clearDirectionsRenderer () {
      this.directionsRenderer.setMap(null)
      this.directionsRenderer.setPanel(null)
      this.directionsRenderer = null
    },
    getOfficeMarker (office) {
      let marker = null
      if (this.officeMarker) {
        for (let i = 0; i < this.officeMarker.length; i++) {
          const cMarker = this.officeMarker[i]
          if (cMarker.id === office) {
            marker = cMarker
            break
          }
        }
      }
      return marker
    },
    getRouteMarker (route) {
      let marker = null
      if (this.routeMarker) {
        for (let i = 0; i < this.routeMarker.length; i++) {
          const cMarker = this.routeMarker[i]
          if (cMarker.id === route) {
            marker = cMarker
            break
          }
        }
      }
      return marker
    },
    getZoneMarker (zone) {
      let marker = null
      if (this.zoneMarker) {
        for (let i = 0; i < this.zoneMarker.length; i++) {
          const cMarker = this.zoneMarker[i]
          if (cMarker.id === zone) {
            marker = cMarker
            break
          }
        }
      }
      return marker
    },
    getColorMarker (color) {
      let marker = null
      if (this.colorMarker) {
        for (let i = 0; i < this.colorMarker.length; i++) {
          const cMarker = this.colorMarker[i]
          if (cMarker.color === color) {
            marker = cMarker
            break
          }
        }
      }
      return marker
    },
    getCodeMarker (code) {
      let marker = null
      if (this.codeMarker) {
        for (let i = 0; i < this.codeMarker.length; i++) {
          const cMarker = this.codeMarker[i]
          if (cMarker.name === code) {
            marker = cMarker
            break
          }
        }
      }
      return marker
    },
    getPlaceMarker (place) {
      let marker = null
      if (this.placeMarker) {
        for (let i = 0; i < this.placeMarker.length; i++) {
          const cMarker = this.placeMarker[i]
          if (cMarker.id === place) {
            marker = cMarker
            break
          }
        }
      }
      return marker
    },
    showMapMasterControl () {
      /*
      var bounds = null
      this.showedMarkerList.forEach(item => {
        item.marker.setMap(null)
      })
      this.showedLineMarkerList.forEach(showLine => {
        showLine.line.setMap(null)
      })
      this.showedMarkerList = []
      this.showedLineMarkerList = []
      if (this.directionsRenderer) {
        this.directionsRenderer.setPanel(document.getElementById('directionsPanel'))
        this.hideCodeLine()
        this.showLevel = 'directionsRenderer'
      } else if (this.placeSelectId && this.select.place) {
        this.showLevel = 'placeDetail'
        // this.select.place.marker.setMap(this.map)
        // this.showedMarkerList.push(this.select.place)
       // // console.log('trace getPlaceDetailMarker')
        const placeDetailMarker = this.$store.getters['pocModule/getPlaceDetailMarker'](this.select.place.pdrList, this.map, this.pocItemTitle)
        this.showedMarkerList = [].concat([this.select.place], placeDetailMarker.markerList)
        if (placeDetailMarker.markerList.length) {
          bounds = placeDetailMarker.bounds
        } else {
          bounds = this.select.code.bounds
        }
      } else if (this.codeSelectId && this.select.code) {
        const codeId = this.codeSelectId
        this.showedMarkerList = this.placeMarker.filter(function (place) {
          return place.codeId === codeId
        })
        this.showedLineMarkerList = this.codeMarker.filter(function (code) {
          return code.id === codeId
        })
        bounds = this.select.code.bounds
        this.showLevel = 'placeOfCode'
      } else if (this.colorSelectId && this.select.color) {
        const colorId = this.colorSelectId
        this.showedMarkerList = this.codeMarker.filter(function (code) { return code.color === colorId })
        this.showedLineMarkerList = this.codeMarker.filter(function (code) { return code.color === colorId })
        this.showLevel = 'codeOfColor'
        bounds = this.select.color.bounds
        // bounds = new this.google.maps.LatLngBounds([this.select.color.boundsSwNe.sw, this.select.color.boundsSwNe.ne])
      } else if (this.routeSelectId && this.select.route) {
        this.showedMarkerList = this.colorMarker
        this.showedLineMarkerList = this.codeMarker
        this.showLevel = 'colorOfRoute'
        bounds = this.sequenceRouteMarker.bounds
      } else if (this.officeSelectId && this.select.office) {
        this.showedMarkerList = this.routeMarker
        bounds = this.routeMarkerBounds
        this.showLevel = 'routeOfOffice'
      } else if (this.zoneSelectId && this.select.zone) {
        this.showedMarkerList = this.officeMarker
        bounds = this.officeMarkerBounds
        this.showLevel = 'officeOfZone'
      } else {
        this.showedMarkerList = this.zoneMarker
        bounds = this.zoneMarkerBounds
        this.showLevel = 'zone'
      }
      const map = this.map
     // // console.log('trace nbr showitem = ' + this.showedMarkerList.length)
      this.showedMarkerList.forEach(showItem => {
       // // console.log('trace showitem')
       // // console.log(showItem)
        showItem.marker.setMap(map)
        // bounds.extend(showItem.marker.getPosition())
      })
      if (bounds) {
        this.map.fitBounds(bounds)
      }
      this.showedLineMarkerList.forEach(showLine => {
        showLine.line.setMap(this.showColorLine ? this.map : null)
      })
      */
    },
    showOfficeMarker () {
      var bounds = new this.google.maps.LatLngBounds()
      for (let i = 0; i < this.officeMarker.length; i++) {
        this.officeMarker[i].marker.setMap(this.map)
        bounds.extend(this.officeMarker[i].marker.getPosition())
      }
      for (let i = 0; i < this.zoneMarker.length; i++) {
        if (this.zoneMarker[i].id === this.select.zone) {
          bounds.extend(this.zoneMarker[i].marker.getPosition())
        }
      }
      this.map.fitBounds(bounds)
    },
    showOfficeMarkerOfZone () {
      var bounds = new this.google.maps.LatLngBounds()
      for (let i = 0; i < this.officeMarker.length; i++) {
        if (this.officeMarker[i].zone === this.zoneSelectId) {
          this.officeMarker[i].marker.setMap(this.map)
          bounds.extend(this.officeMarker[i].marker.getPosition())
        }
      }
      for (let i = 0; i < this.zoneMarker.length; i++) {
        if (this.zoneMarker[i].id === this.zoneSelectId) {
          bounds.extend(this.zoneMarker[i].marker.getPosition())
        }
      }
      this.map.fitBounds(bounds)
    },
    showZoneMarker () {
      var bounds = new this.google.maps.LatLngBounds()
      for (let i = 0; i < this.zoneMarker.length; i++) {
        this.zoneMarker[i].marker.setMap(this.map)
        bounds.extend(this.zoneMarker[i].marker.getPosition())
      }
      this.map.fitBounds(bounds)
    },
    showRouteMarkerOfOffice () {
      var bounds = new this.google.maps.LatLngBounds()
      if (this.showedMarkerList.length) {
        this.showedMarkerList.forEach(marker => {
          marker.setMap(null)
        })
      }
      this.routeMarker.forEach(route => {
        route.marker.setMap(this.map)
        this.showedMarkerList.push(route.marker)
        // const marker = this.showedMarkerList[index]
        // marker.setMap(this.map)
        bounds.extend(route.marker.getPosition())
      })
      // for (let i = 0; i < this.routeMarker.length; i++) {
      //   this.routeMarker[i].marker.setMap(this.map)
      //   bounds.extend(this.routeMarker[i].marker.getPosition())
      // }
      for (let i = 0; i < this.officeMarker.length; i++) {
        if (this.officeMarker[i].id === this.officeSelectId) {
          // bounds.extend(this.zoneMarker[i].marker.getPosition())
        }
      }
      this.map.fitBounds(bounds)
    },
    showCodeLine () {
      this.codeMarker.forEach(code => {
        code.line.setMap(this.showColorLine ? this.map : null)
      })
    },
    showCodeLineOfCode () {
      for (let i = 0; i < this.codeMarker.length; i++) {
        if (this.codeMarker[i].name === this.codeSelectId) {
          this.codeMarker[i].line.setMap(this.showColorLine ? this.map : null)
        } else {
          this.codeMarker[i].line.setMap(null)
        }
      }
    },
    showCodeLineOfColor () {
      for (let i = 0; i < this.codeMarker.length; i++) {
        if (this.codeMarker[i].color === this.colorSelectId) {
          this.codeMarker[i].line.setMap(this.showColorLine ? this.map : null)
        } else {
          this.codeMarker[i].line.setMap(null)
        }
      }
    },
    showCodeMarkerOfColor () {
      var bounds = new this.google.maps.LatLngBounds()
      for (let i = 0; i < this.codeMarker.length; i++) {
        if (this.codeMarker[i].color === this.colorSelectId) {
          bounds.extend(this.codeMarker[i].marker.getPosition())
          this.codeMarker[i].marker.setMap(this.map)
          this.codeMarker[i].line.setMap(this.showColorLine ? this.map : null)
        } else {
          this.codeMarker[i].marker.setMap(null)
          this.codeMarker[i].line.setMap(null)
        }
      }
      this.map.fitBounds(bounds)
    },
    showColorMarker () {
      var bounds = new this.google.maps.LatLngBounds()
      if (this.showedMarkerList.length) {
        this.showedMarkerList.forEach(marker => {
          marker.setMap(null)
        })
      }
      this.showedMarkerList = []
      this.colorMarker.forEach(color => {
        color.marker.setMap(this.map)
        this.showedMarkerList.push(color.marker)
        bounds.extend(color.marker.getPosition())
      })
      // for (let i = 0; i < this.colorMarker.length; i++) {
      //   bounds.extend(this.colorMarker[i].marker.getPosition())
      //   this.colorMarker[i].marker.setMap(this.map)
      // }
      this.map.fitBounds(bounds)
    },
    showColorMarkerOfRoute () {
      if (this.showedMarkerList.length) {
        this.showedMarkerList.forEach(marker => {
          marker.setMap(null)
        })
      }
      this.showedMarkerList = []
      var bounds = new this.google.maps.LatLngBounds()
      this.colorMarker.forEach(color => {
        bounds.extend(color.marker.getPosition())
        color.marker.setMap(this.map)
        this.showedMarkerList.push(color.marker)
      })
      this.map.fitBounds(bounds)
    },
    showToiletMarker () {
      if (this.toiletMarker && this.showToilet) {
        for (const marker of this.toiletMarker) {
          marker.setMap(this.map)
          this.showedToiletMarkerList.push(marker)
        }
      }
    },
    showGreyBoxMarker () {
      if (this.greyBoxMarker && this.showGreyBox) {
        for (const marker of this.greyBoxMarker) {
          marker.setMap(this.map)
          this.showedGreyBoxMarkerList.push(marker)
        }
      }
    },
    showAlertMarker () {
      if (this.AlertMarker && this.showDog) {
        for (const alert of this.AlertMarker) {
          alert.marker.setMap(this.map)
          if (alert.zone) { alert.zone.setMap(this.map) }
          this.showedAlertMarkerList.push(alert)
        }
      }
    },
    hideToiletMarker () {
      if (this.showedToiletMarkerList) {
        for (const marker of this.showedToiletMarkerList) {
          marker.setMap(null)
        }
      }
      this.showedToiletMarkerList = []
    },
    hideGreyBoxMarker () {
      if (this.showedGreyBoxMarkerList) {
        for (const marker of this.showedGreyBoxMarkerList) {
          marker.setMap(null)
        }
      }
      this.showedGreyBoxMarkerList = []
    },
    hideAlertMarker () {
      if (this.showedAlertMarkerList) {
        for (const alert of this.showedAlertMarkerList) {
          alert.marker.setMap(null)
          if (alert.zone) { alert.zone.setMap(null) }
        }
      }
      this.showedAlertMarkerList = []
    },
    hidePlaceMarker () {
      for (let i = 0; i < this.placeMarker.length; i++) {
        this.placeMarker[i].marker.setMap(null)
      }
    },
    hideCodeMarker () {
      for (let i = 0; i < this.codeMarker.length; i++) {
        this.codeMarker[i].marker.setMap(null)
      }
    },
    hideCodeLine () {
      for (let i = 0; i < this.codeMarker.length; i++) {
        this.codeMarker[i].line.setMap(null)
      }
    },
    hideColorMarker () {
      for (let i = 0; i < this.colorMarker.length; i++) {
        this.colorMarker[i].marker.setMap(null)
      }
    },
    hideRouteMarker () {
      // for (let i = 0; i < this.routeMarker.length; i++) {
      //   this.routeMarker[i].marker.setMap(null)
      // }
    },
    hideOfficeMarker () {
      for (let i = 0; i < this.officeMarker.length; i++) {
        // if (this.officeMarker[i].id !== this.$store.state.userModule.data[this.$store.state.userinfokey].officeselected) {
        this.officeMarker[i].marker.setMap(null)
        // }
      }
    },
    hideZoneMarker () {
      for (let i = 0; i < this.zoneMarker.length; i++) {
        this.zoneMarker[i].marker.setMap(null)
      }
    },
    clickLevelUp () {
      if (this.pdrSelectId) {
        this.$store.dispatch('userSelectionPdr', (null))
      } else if (this.placeSelectId) {
        this.$store.dispatch('userSelectionPlace', (null))
      } else if (this.codeSelectId) {
        this.$store.dispatch('userSelectionCode', (null))
      } else if (this.colorSelectId) {
        this.$store.dispatch('userSelectionColor', (null))
      } else if (this.routeSelectId) {
        this.$store.dispatch('userSelectionRoute', (null))
      } else if (this.officeSelectId) {
        this.$store.dispatch('userSelectionOffice', (null))
      } else if (this.zoneSelectId) {
        this.$store.dispatch('userSelectionZone', (null))
      }
      this.showMapMasterControl()
    },
    clickInfo () {
      this.bottomNav = 1
      this.showinfobox = !this.showinfobox
      this.showStreetView = false
    },
    clickView () {
      this.showStreetView = !this.showStreetView
      this.showinfobox = false
    },
    createMarker () {
      // this.placeMarker = this.sequenceRouteMarker.placeMarker
      // this.codeMarker = this.sequenceRouteMarker.codeMarker
      // this.colorMarker = this.sequenceRouteMarker.colorMarker
      // this.routeMarker = this.$store.getters['routeModule/getRouteMarker']
      // this.pdrCompileData()
      // this.createRouteMarker()
      // this.createOfficeMarker()
      // this.createZoneMarker()
      // this.showMasterControl()
      this.showMapMasterControl()
    },
    // createOfficeMarker () {
    //   const EventBus = this.$store.getters.EventBus
    //   const bounds = new this.google.maps.LatLngBounds(this.$store.getters['zoneModule/getSelectedZonePosition'])
    //   // const selofficeid = this.$store.state.userModule.data[this.$store.state.userinfokey].officeselected
    //   for (const officeId in this.$store.state.officeModule.data) {
    //     const office = this.$store.state.officeModule.data[officeId]
    //     let loc
    //     for (let x = 0; x < office.location.length; x++) {
    //       if (office.location[x].desc === 'home') {
    //         loc = office.location[x].loc
    //         break
    //       }
    //     }
    //     bounds.extend({ lat: loc.latitude, lng: loc.longitude })
    //     const offMarker = new this.google.maps.Marker({
    //       position: { lat: loc.latitude, lng: loc.longitude },
    //       label: { text: office.name, color: 'blue', fontSize: '16px' },
    //       title: office.name,
    //       icon: {
    //         url: require('@/assets/logo.png'),
    //         // size: new this.google.maps.Size(48, 48),
    //         scaledSize: new this.google.maps.Size(60, 60), // scaled size
    //         origin: new this.google.maps.Point(0, 0), // origin
    //         anchor: new this.google.maps.Point(24, 28), // anchor
    //         // labelOrigin: new this.google.maps.Point(24, 32)
    //         labelOrigin: new this.google.maps.Point(30, -5)
    //       }
    //     })
    //     // if (officeId === this.$store.state.userModule.data[this.$store.state.userinfokey].officeselected) {
    //     //   offMarker.setMap(this.map)
    //     // }
    //     // const officeInfo = { id: officeId, name: office.name, zone: office.zone }
    //     // if (this.officeSelectId === officeInfo.id) { this.select.office = officeInfo }

    //     offMarker.addListener('click', function () {
    //       EventBus.$emit('officeClick', (officeId))
    //     })
    //     this.officeMarker.push({ id: officeId, name: office.name, zone: office.zone, marker: offMarker })
    //   }
    //   this.officeMarkerBounds = bounds.toJSON()
    // },
    // createZoneMarker () {
    //   const EventBus = this.$store.getters.EventBus
    //   const bounds = new this.google.maps.LatLngBounds(this.$store.getters['officeSelectedModule/getOfficePosition'])
    //   // const selofficeid = this.$store.state.userModule.data[this.$store.state.userinfokey].officeselected
    //   for (const zoneId in this.$store.state.zoneModule.data) {
    //     const zone = this.$store.state.zoneModule.data[zoneId]
    //     bounds.extend(zone.position)
    //     const zonMarker = new this.google.maps.Marker({
    //       position: zone.position,
    //       label: { text: zone.name, color: 'black', fontSize: '16px' },
    //       title: zone.name,
    //       icon: {
    //         url: require('@/assets/logo.png'),
    //         // size: new this.google.maps.Size(48, 48),
    //         scaledSize: new this.google.maps.Size(60, 60), // scaled size
    //         origin: new this.google.maps.Point(0, 0), // origin
    //         anchor: new this.google.maps.Point(24, 28), // anchor
    //         // labelOrigin: new this.google.maps.Point(24, 32)
    //         labelOrigin: new this.google.maps.Point(30, -5)
    //       }
    //     })
    //     // const zoneInfo = { id: zoneId, name: zone.name }
    //     // if (this.zoneSelectId === zoneInfo.id) { this.select.zone = zoneInfo }

    //     zonMarker.addListener('click', function () {
    //       EventBus.$emit('zoneClick', (zoneId))
    //     })
    //     this.zoneMarker.push({ id: zoneId, name: zone.name, marker: zonMarker })
    //   }
    //   this.zoneMarkerBounds = bounds.toJSON()
    // },
    createListener () {
      const EventBus = this.$store.getters.EventBus
      // EventBus.$on('replacePicture', (src) => {
      //   this.streetViewPictureSrc = src
      // })
      EventBus.$on('mapclick', () => {
        if (this.otherRouteInfoWindow) {
          this.otherRouteInfoWindow.close()
        }
        if (this.showinfobox) {
          this.showinfobox = false
          this.bottomNav = 7
          //         if(this.usermap){this.usermap.setStreetView(null)}
        }
      })
      EventBus.$on('colorClick', (colorId) => {
        // // console.log('trace colorClick')
        // // console.log(colorId)
        this.$store.dispatch('userSelectionColor', (colorId))
      })
      EventBus.$on('codeClick', (codeId) => {
        this.$store.dispatch('userSelectionCode', (codeId))
      })
      EventBus.$on('routeClick', (routeId) => {
        if (this.$store.getters.getSelectedRouteId === routeId) {
          this.otherRouteInfoWindow.close()
          this.$store.dispatch('userSelectionRoute', (routeId))
        } else {
          this.showOtherRouteInfoWindow(routeId)
        }
      })
      EventBus.$on('officeClick', (officeId) => {
        this.$store.dispatch('userSelectionOffice', (officeId))
      })
      EventBus.$on('zoneClick', (zoneId) => {
        this.$store.dispatch('userSelectionZone', (zoneId))
      })
      EventBus.$on('placeClick', (placeId) => {
        this.$store.dispatch('userSelectionPlace', (placeId))
      })
      EventBus.$on('pocClick', (pocId) => {
        this.$store.dispatch('userSelectionPdr', (pocId))
      })
      EventBus.$on('openInfoBoxClick', (markerDetail) => {
        markerDetail.infoWindow.open(this.map, markerDetail.marker)
      })
      EventBus.$on('createInfoWindowOfMaker', (markerDetail) => {
        // markerDetail.infoWindow.setContent(markerDetail.infoWindow.getContent() + this.getStatTabHtml(markerDetail.statArray))
        // markerDetail.infoWindow.open(this.map, markerDetail.marker)
        switch (markerDetail.type) {
          case 'placeInfoWindow':
            this.createInfoWindowOfPlace(markerDetail).open(this.map, markerDetail.marker)
            break
          case 'pocInfoWindow':
            this.createInfoWindowOfPoc(markerDetail).open(this.map, markerDetail.marker)
            break
          default:
            break
        }
        // this.createInfoWindowOfPlace()
      })
      EventBus.$on('map_zoom_changed', () => {
        // const zoomLevel = this.map.getZoom()
        /*
        if (zoomLevel === 17) {
          // showpdrmarker()
        } else if (zoomLevel < 17) {
          for (let i = 0; i < this.colorMarker.length; i++) {
            this.colorMarker[i].marker.setMap(this.map)
          }
          for (let i = 0; i < this.codeMarker.length; i++) {
            this.codeMarker[i].marker.setMap(null)
            this.codeMarker[i].marker.setMap(this.map)
          }
          for (let i = 0; i < this.pdrMarker.length; i++) {
            this.pdrMarker[i].marker.setMap(null)
          }
        }
        */
      })

      this.map.addListener('click', function () { EventBus.$emit('mapclick') })
      this.map.addListener('zoom_changed', function () { EventBus.$emit('map_zoom_changed') })

      document.addEventListener('selectpdr', function (e) {
        // this.selectedpdr = e.detail;
        EventBus.$emit('markerclick', (e.detail))
      })
    }
  },
  computed: {
    toShowRouteItemMarkerInfo () {
      if (this.pdrSelectId) {
        return { level: 'pocDetail', markerData: this.$store.getters['pocModule/getPocDetailMarkerData'](this.routeSelectId, this.$store.getters.getSelectedSequenceId, this.pdrSelectId, this.pocItemTitle).markerList }
      } else if (this.placeSelectId) {
        return { level: 'placeDetail', markerData: this.$store.getters['pocModule/getPlaceDetailMarkerData'](this.routeSelectId, this.$store.getters.getSelectedSequenceId, this.placeSelectId, this.pocItemTitle).markerList }
      } else if (this.codeSelectId) {
        return { level: 'placeOfCode', markerData: this.$store.getters['pocModule/getPlaceOfCodeMarkerData'](this.routeSelectId, this.$store.getters.getSelectedSequenceId, this.codeSelectId) }
      } else if (this.colorSelectId) {
        return { level: 'codeOfColor', markerData: this.$store.getters['pocModule/getCodeOfColorMarkerData'](this.routeSelectId, this.$store.getters.getSelectedSequenceId, this.colorSelectId) }
      } else if (this.routeSelectId) {
        return { level: 'colorOfRoute', markerData: this.$store.getters['pocModule/getColorOfRouteMarkerData'](this.routeSelectId, this.$store.getters.getSelectedSequenceId) }
      } else if (this.officeSelectId) {
        return { level: 'routeOfOffice', markerData: this.$store.getters['routeModule/getRouteMarker'].marker }
      } else if (this.zoneSelectId) {
        return { level: 'officeOfZone', markerData: this.$store.getters['officeModule/getOfficeMarker'].marker }
      } else {
        return { level: 'zone', markerData: this.$store.getters['zoneModule/getZoneMarker'].marker }
      }
    },
    locale () {
      return this.$store.getLocale
    },
    otherRouteDetailStat () {
      if (this.otherRouteDetail) {
        if (this.$store.state.routeModule.data[this.otherRouteDetail.id].stat) {
          const stat = this.$store.state.routeModule.data[this.otherRouteDetail.id].stat
          const statArray = []
          if (stat.hou || stat.houV) {
            statArray.push({ id: statArray.length, name: this.$t('common.dom'), detail: stat.houP + '/' + stat.hou + '   (' + stat.houV + ')' })
          }
          if (stat.app || stat.appV) {
            statArray.push({ id: statArray.length, name: this.$t('common.app'), detail: stat.appP + '/' + stat.app + '   (' + stat.appV + ')' })
          }
          if (stat.bus || stat.busV) {
            statArray.push({ id: statArray.length, name: this.$t('common.bus'), detail: stat.busP + '/' + stat.bus + '   (' + stat.busV + ')' })
          }
          if (stat.farm || stat.farmV) {
            statArray.push({ id: statArray.length, name: this.$t('common.frm'), detail: stat.farmP + '/' + stat.farm + '   (' + stat.farmV + ')' })
          }
          return statArray
        } else {
          return null
        }
      } else {
        return null
      }
    },
    select () {
      return {
        zone: this.zoneSelectId ? this.zoneMarker[this.zoneMarker.map(function (x) { return x.id }).indexOf(this.zoneSelectId)] : null,
        office: this.officeSelectId ? this.officeMarker[this.officeMarker.map(function (x) { return x.id }).indexOf(this.officeSelectId)] : null,
        route: this.routeSelectId ? this.routeMarker[this.routeMarker.map(function (x) { return x.id }).indexOf(this.routeSelectId)] : null,
        color: this.colorSelectId ? this.colorMarker[this.colorMarker.map(function (x) { return x.id }).indexOf(this.colorSelectId)] : null,
        code: this.codeSelectId ? this.codeMarker[this.codeMarker.map(function (x) { return x.id }).indexOf(this.codeSelectId)] : null,
        place: this.placeSelectId ? this.placeMarker[this.placeMarker.map(function (x) { return x.id }).indexOf(this.placeSelectId)] : null,
        pdr: null
      }
    },
    sequenceRouteMarker () { return this.$store.getters['pocModule/getPlaceMarkerOfSequence'](this.$store.getters.getSelectedSequenceId, this.$store.getters.getSelectedRouteId) },
    codeMarker () { return this.sequenceRouteMarker.codeMarker },
    colorMarker () { return this.sequenceRouteMarker.colorMarker },
    placeMarker () { return this.sequenceRouteMarker.placeMarker },
    routeMarker () { return this.$store.getters['routeModule/getRouteMarker'].marker },
    routeMarkerBounds () { return this.$store.getters['routeModule/getRouteMarker'].bounds },
    zoneMarker () { return this.$store.getters['zoneModule/getZoneMarker'].marker },
    zoneMarkerBounds () { return this.$store.getters['zoneModule/getZoneMarker'].bounds },
    officeMarker () { return this.$store.getters['officeModule/getOfficeMarker'].marker },
    officeMarkerBounds () { return this.$store.getters['officeModule/getOfficeMarker'].bounds },
    // routeStat () {
    //   if (this.sequenceRouteMarker.isOfficialSequence) {
    //     return this.sequenceRouteMarker.sequenceStat
    //   } else {
    //     return null
    //   }
    // },
    google () {
      return this.$store.getters.getGoogle
    },
    map () {
      if (this.google) {
        return new this.google.maps.Map(document.getElementById('userMaps'), {
          center: this.mapOption.startCenter,
          zoom: this.mapOption.startzoom,
          styles: [
            {
              featureType: 'administrative',
              elementType: 'geometry',
              stylers: [
                {
                  visibility: 'off'
                }
              ]
            },
            {
              featureType: 'poi',
              stylers: [
                {
                  visibility: 'off'
                }
              ]
            },
            {
              featureType: 'road',
              elementType: 'labels.icon',
              stylers: [
                {
                  visibility: 'off'
                }
              ]
            },
            {
              featureType: 'transit',
              stylers: [
                {
                  visibility: 'off'
                }
              ]
            }
          ],

          zoomControlOptions: {
            position: this.google.maps.ControlPosition.RIGHT_CENTER
          },
          streetViewControlOptions: {
            position: this.google.maps.ControlPosition.TOP_RIGHT
          },
          fullscreenControl: false,
          mapTypeControl: true,
          mapTypeControlOptions: {
            style: this.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
            mapTypeIds: ['roadmap', 'satellite'],
            position: this.google.maps.ControlPosition.TOP_CENTER
          },
          rotateControlOptions: {
            position: this.google.maps.ControlPosition.LEFT_CENTER
          }
        })
      } else {
        return null
      }
    },
    guardianMode () {
      return this.$store.getters.getGuardianMode
    },
    currentPosition () {
      return this.$store.getters.getCurrentPosition
    },
    AlertMarker () {
      if (this.officeSelectId) {
        return this.$store.getters.getAlertZoneMarker
      } else {
        return null
      }
    },
    // sequenceRouteMarker () {
    //   return this.$store.getters['pocModule/getPlaceMarkerOfSequence'](this.$store.getters.getSelectedSequenceId, this.$store.getters.getSelectedRouteId)
    // },
    toiletMarker () {
      if (this.officeSelectId) {
        return this.$store.getters['officeModule/getOfficeToiletMarker'](this.officeSelectId, this.google)
      } else {
        return null
      }
    },
    greyBoxMarker () {
      if (this.officeSelectId) {
        return this.$store.getters['officeModule/getOfficeGreyBoxMarker'](this.officeSelectId, this.google, this.map)
      } else {
        return null
      }
    },
    streetViewPictureSrc () {
      if (this.$store.getters.getUserSelectPlacePictureSrc) {
        // const info = { placeId: this.placeSelectId, width: window.innerWidth, height: '270' }
        // return this.$store.getters['pdrModule/getPlacePictureHomeSrc'](info)
      // info = { placeId: this.pdrId, width: '200', height: '400' }
        return this.$store.getters.getUserSelectPlacePictureSrc
      } else if (this.officeSelectId && this.$store.getters.getUserSelectOfficePictureSrc) {
        return this.$store.getters.getUserSelectOfficePictureSrc
      } else {
        // return 'https://picsum.photos/1920/1080?random'
        return require('@/assets/postes-logo.jpg')
      }
    },
    mapInfo () {
      return this.$store.getters.getUserParam.mapInfo
    },
    infoboxHeight () {
      if (this.$store.getters.contentHeight < 580) {
        return 200
      } else {
        return 300
      }
    },
    mapstyles () {
      if (this.showinfobox || this.showStreetView) {
        // return 'width:' + this.contentstyles.width + 'px; height:' + (this.contentstyles.height - 325) + 'px'
        return 'width:' + this.contentstyles.width + 'px; height:' + (this.$store.getters.contentHeight - this.infoboxHeight) + 'px'
      } else {
        return 'width:' + this.contentstyles.width + 'px; height:' + (this.$store.getters.contentHeight) + 'px'
      }
      // return 'width:' + this.contentstyles.width + 'px; height:' + (this.contentstyles.height - 150) + 'px'
    },
    showColorLine () { return this.mapInfo.showColorLine },
    showDog () { return this.mapInfo.showDog },
    showToilet () { return this.mapInfo.showToilet },
    showGreyBox () { return this.mapInfo.showGreyBox },
    zoneSelectId () {
      return this.$store.getters.getUserSelection.zone
    },
    officeSelectId () {
      return this.$store.getters.getUserSelection.office
    },
    routeSelectId () {
      return this.$store.getters.getUserSelection.route
    },
    colorSelectId () {
      return this.$store.getters.getUserSelection.color
    },
    codeSelectId () {
      return this.$store.getters.getUserSelection.code
    },
    placeSelectId () {
      return this.$store.getters.getUserSelection.place
    },
    pdrSelectId () {
      return this.$store.getters.getUserSelection.pdr
    },
    placeMarkerStat () {
      const statArray = []
      if (this.select.place) {
        const stat = this.select.place.stat
        if (stat.hou || stat.houV) {
          statArray.push({ id: statArray.length, name: this.$t('common.dom'), detail: stat.houP + '/' + stat.hou + '   (' + stat.houV + ')' })
        }
        if (stat.app || stat.appV) {
          statArray.push({ id: statArray.length, name: this.$t('common.app'), detail: stat.appP + '/' + stat.app + '   (' + stat.appV + ')' })
        }
        if (stat.bus || stat.busV) {
          statArray.push({ id: statArray.length, name: this.$t('common.bus'), detail: stat.busP + '/' + stat.bus + '   (' + stat.busV + ')' })
        }
        if (stat.farm || stat.farmV) {
          statArray.push({ id: statArray.length, name: this.$t('common.frm'), detail: stat.farmP + '/' + stat.farm + '   (' + stat.farmV + ')' })
        }
      }
      return statArray
    },
    codeMarkerStat () {
      const statArray = []
      if (this.select.code) {
        const stat = this.select.code.stat
        if (stat.hou || stat.houV) {
          statArray.push({ id: statArray.length, name: this.$t('common.dom'), detail: stat.houP + '/' + stat.hou + '   (' + stat.houV + ')' })
        }
        if (stat.app || stat.appV) {
          statArray.push({ id: statArray.length, name: this.$t('common.app'), detail: stat.appP + '/' + stat.app + '   (' + stat.appV + ')' })
        }
        if (stat.bus || stat.busV) {
          statArray.push({ id: statArray.length, name: this.$t('common.bus'), detail: stat.busP + '/' + stat.bus + '   (' + stat.busV + ')' })
        }
        if (stat.farm || stat.farmV) {
          statArray.push({ id: statArray.length, name: this.$t('common.frm'), detail: stat.farmP + '/' + stat.farm + '   (' + stat.farmV + ')' })
        }
      }
      return statArray
    },
    colorMarkerStat () {
      const statArray = []
      if (this.select.color) {
        const stat = this.select.color.stat
        if (stat.hou || stat.houV) {
          statArray.push({ id: statArray.length, name: this.$t('common.dom'), detail: stat.houP + '/' + stat.hou + '   (' + stat.houV + ')' })
        }
        if (stat.app || stat.appV) {
          statArray.push({ id: statArray.length, name: this.$t('common.app'), detail: stat.appP + '/' + stat.app + '   (' + stat.appV + ')' })
        }
        if (stat.bus || stat.busV) {
          statArray.push({ id: statArray.length, name: this.$t('common.bus'), detail: stat.busP + '/' + stat.bus + '   (' + stat.busV + ')' })
        }
        if (stat.farm || stat.farmV) {
          statArray.push({ id: statArray.length, name: this.$t('common.frm'), detail: stat.farmP + '/' + stat.farm + '   (' + stat.farmV + ')' })
        }
      }
      return statArray
    },
    routeMarkerStat () {
      let statArrayOrange = []
      let statArrayPink = []
      let statArrayBlue = []
      for (const color of this.colorMarker) {
        const stat = color.stat
        const statArray = []
        if (stat.hou || stat.houV) {
          statArray.push({ id: statArray.length, name: this.$t('common.dom'), detail: stat.houP + '/' + stat.hou + '   (' + stat.houV + ')' })
        }
        if (stat.app || stat.appV) {
          statArray.push({ id: statArray.length, name: this.$t('common.app'), detail: stat.appP + '/' + stat.app + '   (' + stat.appV + ')' })
        }
        if (stat.bus || stat.busV) {
          statArray.push({ id: statArray.length, name: this.$t('common.bus'), detail: stat.busP + '/' + stat.bus + '   (' + stat.busV + ')' })
        }
        if (stat.farm || stat.farmV) {
          statArray.push({ id: statArray.length, name: this.$t('common.frm'), detail: stat.farmP + '/' + stat.farm + '   (' + stat.farmV + ')' })
        }
        if (color.id === 1) { statArrayOrange = statArray }
        if (color.id === 2) { statArrayPink = statArray }
        if (color.id === 3) { statArrayBlue = statArray }
      }
      return { orange: statArrayOrange, pink: statArrayPink, blue: statArrayBlue }
    },
    isDataLoadComplete () {
      return this.$store.getters.postmanDataLoadCompleteReady
    }
  }
}
</script>
<style>
      #directionsPanel {
        font-family: 'Roboto','sans-serif';
        line-height: 30px;
        padding-left: 10px;
      }

      #directionsPanel select, #directionsPanel input {
        font-size: 15px;
      }

      #directionsPanel select {
        width: 100%;
      }

      #directionsPanel i {
        font-size: 12px;
      }
      #directionsPanel {
        height: 100%;
        float: right;
        width: 390px;
        overflow: auto;
      }
      #infobox {
        overflow: auto;
      }
      #levelUpControlBtn {
        color: 'rgb(25,25,25)';
        fontFamily: 'Roboto,Arial,sans-serif';
        fontSize: '16px';
        lineHeight: '38px';
        paddingLeft: '5px';
        paddingRight: '5px';
      }

</style>
