<template id="template" fixed>
  <v-row justify="center" id="row">
    <v-dialog max-width="444" v-model="dialog" id="dialog" transition="dialog-bottom-transition">
      <v-app-bar
        :color="colorId ? $store.getColorCode(colorId) : 'primary'"
        dark
        extended
        :src="$store.getters['placeModule/getPlacePictureHomeSrc']({ placeId: placeId, width: '450', height: '300' })"
      >
        <template v-slot:img="{ props }">
          <v-img
            v-bind="props"
            :gradient="$store.getGradientByColorId(colorId, $store.getters['userLoggedModule/isDarkThemes'])"
          ></v-img>
        </template>
        <v-app-bar-nav-icon>
          <v-btn icon @click="dialog = false">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
        </v-app-bar-nav-icon>

        <v-spacer></v-spacer>
        <v-btn x-large icon>
          <v-icon x-large @click="showFilter = !showFilter">{{ pubFilter !== 'all' || vacantFilter !== 'all'? 'mdi-filter-menu' : 'mdi-filter-menu-outline' }}</v-icon>
        </v-btn>
        <v-btn x-large icon>
          <v-icon x-large @click="showSearch = !showSearch">mdi-magnify</v-icon>
        </v-btn>

        <template v-slot:extension>
          <v-spacer></v-spacer>
          <p class="subtitle-1">{{ place.lName }}</p>
          <v-spacer></v-spacer>
        </template>

      </v-app-bar>
      <v-card v-if="showFilter" max-height="150" height="150" flat color="grey lighten-4">
        <v-card-title class="pt-n2">{{ $t('common.filter') }} :</v-card-title>
        <v-row no-gutters>
          <v-col cols="6">
            <v-select
              class="mx-1"
              :label="$t('common.pub')"
              outlined
              :items="pubItems"
              v-model="pubFilter"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              class="mx-1"
              :label="$t('common.vacant')"
              outlined
              :items="vacantItems"
              v-model="vacantFilter"
            ></v-select>
          </v-col>
        </v-row>
      </v-card>
      <v-card v-if="showSearch" max-height="150" height="150" flat color="grey lighten-4">
        <v-card-title>{{ $t('common.search') }} :</v-card-title>
        <v-text-field
          class="mx-2"
          :label="$t('common.search')"
          outlined
          v-model="search"
          @blur="$store.dispatch('resize')"
          clearable
        ></v-text-field>
      </v-card>
      <v-card :max-height="iteratorHeight" flat class="overflow-y-auto">
        <v-data-iterator
          ref="dataIterator"
          single-expand
          :custom-filter="customFilter"
          :search="search"
          :items="pocFilteredList"
          :items-per-page="30"
          :footer-props="{ 'items-per-page-options' : [15, 30, 50, 100, -1] }"
          @update:items-per-page="$store.dispatch('resize')"
          >
          <template v-slot:default="{ items, isExpanded, expand }">
            <div overflow-y-auto>
              <v-list nav>
                <v-list-item
                  v-for="item in items"
                  :key="item.id"
                  :class="isExpanded(item) ? 'elevation-2' : ''"
                  @click="(v) => expand(item, !isExpanded(item))"
                >
                  <v-list-item-content>
                    <v-list-item-title :class="$store.getColorText(item.color)" v-text="item.pdrListAddress"></v-list-item-title>
                    <v-list-item-subtitle :class="$store.getColorText(item.color)">
                      <v-row no-gutters>
                        {{  item.pdrListPostalCode }}
                        <v-spacer></v-spacer>
                        {{ getTypeDesc(item.pdrType) }}
                      </v-row>
                      </v-list-item-subtitle>
                    <v-container v-if="isExpanded(item)" transition="dialog-top-transition">
                      <v-row no-gutters>
                        <v-col width="60" align="center" justify="center">
                          <v-img contain max-width="45" :src="item.pub ? $store.getters.getAppIcon.pub : $store.getters.getAppIcon.noPub"></v-img>
                          <p class="subtitle-2">{{ item.pub ? $t('common.pub') : $t('common.nopub') }}</p>
                        </v-col>
                        <v-col class="mx-1" width="60" align="center" justify="center">
                          <v-img contain max-width="45" :src="item.vacant ? $store.getters.getAppIcon.vacant : $store.getters.getAppIcon.noVacant"></v-img>
                          <p class="subtitle-2">{{ item.vacant ? $t('common.vacant') : $t('common.novacant') }}</p>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col v-show="item.report && item.report.length && $store.getters.myAccess.employe" class="me-1" width="60" align="center" justify="center">
                          <v-img contain max-width="45" :src="item.vacant ? $store.getters.getAppIcon.vacant : $store.getters.getAppIcon.noVacant"></v-img>
                          <p class="subtitle-2">{{ item.vacant ? $t('common.vacant') : $t('common.novacant') }}</p>
                        </v-col>
                        <v-col v-show="item.note && item.note.length && $store.getters.myAccess.employe" class="me-1" width="60" align="center" justify="center">
                          <v-img contain max-width="45" :src="item.vacant ? $store.getters.getAppIcon.vacant : $store.getters.getAppIcon.noVacant"></v-img>
                          <p class="subtitle-2">{{ item.vacant ? $t('common.vacant') : $t('common.novacant') }}</p>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-btn v-if="$store.getters.myAccess.employe" @click="editPdr(item.id)" icon x-large><v-icon x-large :color="$store.getColorCode(item.color)">mdi-cog-outline</v-icon></v-btn>
                      </v-row>
                    </v-container>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </template>
        </v-data-iterator>
        <!-- <v-sheet
          id="scrolling-techniques-2"
          class="overflow-y-auto"
          max-height="100%"
        >
          <v-container v-if="showSearch">
            <v-text-field
              class="ms-5 me-5"
              v-model="search"
              append-icon="mdi-magnify"
              :label="this.$t('pmtplacepopupinfo.search')"
              single-line
              hide-details
              clearable
              autofocus
              @blur="$store.getters.EventBus.$emit('sizeChange')"
            ></v-text-field>
          </v-container>
          <v-container>
            <v-list>
              <div id="pdrListDiv" class="mt-3">
                <v-list-group
                  v-for="item in pocSearchList"
                  :key="item.id"
                  v-model="item.pocOfPlaceActive"
                  no-action
                >
                  <template v-slot:activator>
                    <v-list-item-content >
                      <v-list-item-title :class="item.color ? $store.getColorText(item.color) : ''" v-text="item.pdrListAddress"></v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-card :color="$store.getColorCode(item.color)" class="lighten-4">
                    <v-list-item-content>
                      <v-row class="mx-5">
                        <p display-4 >{{ getTypeDesc(item.type) }}</p>
                        <v-spacer/>
                        <p class="me-9">{{ item.vacant ? $t('pmtplacepopupinfo.vacant') : $t('pmtplacepopupinfo.occuped')  }}</p>
                      </v-row>
                      <v-row class="mx-5 mt-n2">
                        <p class="mt-4 me-3">{{ $t('common.pub') }} : </p>
                        <v-icon color='green' v-if="item.pub">mdi-check-bold</v-icon>
                        <v-icon color='red' v-if="!item.pub">mdi-cancel</v-icon>
                        <v-spacer/>
                        <v-btn class="me-7" @click="editPdr(item.id)" icon x-large><v-icon x-large :color="$store.getColorCode(item.color)">mdi-cog-outline</v-icon></v-btn>
                      </v-row>
                    </v-list-item-content>
                  </v-card>
                </v-list-group>
              </div>
            </v-list>

          </v-container>
        </v-sheet> -->
      </v-card>
    </v-dialog>
    <pmt-edit-pdr-popup
      v-if="showEditPdr && $store.getters.myAccess.employe"
      :pdrId="selectedPdrId"
      @close="showEditPdr = false"
    >
    </pmt-edit-pdr-popup>
  </v-row>
</template>

<script>
export default {
  props: ['placeId', 'routeId', 'sequenceId', 'codeId', 'colorId'],
  data () {
    return {
      showSearch: false,
      showFilter: false,
      showEditPdr: false,
      dialog: true,
      selectedPdrId: null,
      notifications: false,
      search: '',
      vacantFilter: 'all',
      pubFilter: 'all'
    }
  },
  watch: {
    dialog (newValue) {
      this.$emit('close')
    },
    showSearch (newValue) {
      if (newValue && this.showFilter) {
        this.showFilter = false
      }
    },
    showFilter (newValue) {
      if (newValue && this.showSearch) {
        this.showSearch = false
      }
    }
  },
  computed: {
    iteratorHeight () {
      if (this.showSearch) {
        return (this.$store.getters.contentHeight - 150) * 0.65
      } else if (this.showFilter) {
        return (this.$store.getters.contentHeight - 150) * 0.65
      } else {
        return this.$store.getters.contentHeight * 0.70
      }
    },
    place () {
      if (this.placeId) {
        if (this.$store.state.placeModule.data[this.placeId]) {
          return this.$store.state.placeModule.data[this.placeId]
        } else {
          this.$store.dispatch('placeModule/fetchById', this.placeId)
          return null
        }
      } else {
        return null
      }
    },
    pocList () {
      const self = this
      if (this.codeId) {
        return this.$store.getters['pocModule/getPlacePocArray'](this.routeId, this.sequenceId, this.placeId)
          .filter(poc => poc.codeId === this.codeId)
          .sort(function (a, b) { return a.seqPosTot - b.seqPosTot })
      } else if (this.routeId && this.sequenceId !== undefined && this.placeId) {
        return this.$store.getters['pocModule/getPlacePocArray'](this.routeId, this.sequenceId, this.placeId)
          .sort(function (a, b) { return a.seqPosTot - b.seqPosTot })
      } else if (this.placeId) {
        if (this.$store.state.placeModule.data[this.placeId]) {
          return this.$store.state.placeModule.data[this.placeId].pocList.map(function (pocId) {
            return self.$store.state.pocModule.data[pocId]
          }).sort(function (a, b) { return a.pdrListAddress < b.pdrListAddress })
        } else {
          this.$store.dispatch('placeModule/fetchById', this.placeId)
          return []
        }
      } else {
        // // console.log('trace 4')
        return []
      }
    },
    pocFilteredList () {
      const self = this
      if (self.pubFilter !== 'all' || self.vacantFilter !== 'all') {
        return self.pocList.filter(function (poc) {
          if (self.pubFilter !== 'all') {
            if (poc.pub && self.pubFilter !== 'pub') { return false }
            if (!poc.pub && self.pubFilter === 'pub') { return false }
          }
          if (self.vacantFilter !== 'all') {
            if (poc.vacant && self.vacantFilter !== 'vacant') { return false }
            if (!poc.vacant && self.vacantFilter === 'vacant') { return false }
          }
          return true
        })
      } else {
        return self.pocList
      }
    },
    pubItems () {
      return [{
        text: this.$t('common.all'),
        value: 'all'
      }, {
        text: this.$t('common.pub'),
        value: 'pub'
      }, {
        text: this.$t('common.nopub'),
        value: 'nopub'
      }]
    },
    vacantItems () {
      return [{
        text: this.$t('common.all'),
        value: 'all'
      }, {
        text: this.$t('common.vacant'),
        value: 'vacant'
      }, {
        text: this.$t('common.novacant'),
        value: 'novacant'
      }]
    }
  },
  methods: {
    customFilter (items, search, filter) {
      // const fieldSearchArray = ['pdrListAddress', 'pdrListPostalCode']
      function newFilter (val, wSearch) {
        return val.pdrListAddress !== null &&
          (val.pdrListAddress.toString().toLowerCase().replace(/[^0-9a-zA-Z-]+/g, '').indexOf(wSearch) !== -1 ||
          val.pdrListPostalCode.toString().toLowerCase().replace(/[^0-9a-zA-Z]+/g, '').indexOf(wSearch) !== -1)
      }
      if (!search) { return items }
      const needleAry = search.toString().trim().toLowerCase().split(' ').filter(x => x.length)
      const results = items.filter(row => needleAry.every(needle => newFilter(row, needle)))
      return results
    },
    getTypeDesc (type) {
      let desc = ''
      switch (type) {
        case 'Hou/Dom':
          desc = this.$t('common.dom')
          break
        case 'Apt/App':
          desc = this.$t('common.app')
          break
        case 'Bus/com':
          desc = this.$t('common.bus')
          break
        default:
          desc = this.$t('common.frm')
      }
      return desc
    },
    editPdr (pdrId) {
      // // console.log('trace edit pdrId =' + pdrId)
      this.selectedPdrId = pdrId
      this.showEditPdr = true
    }

  }
}
</script>

<style>
/*
#dialogCard {
    max-width: 100% !important;
    width: inherit !important;
    max-height: 100% !important;
    overflow: hidden !important;
    position: sticky !important;
  }
  #toolBar {
    height: 110px !important;
    position: fixed !important;
  }
  #pdrListDiv {
    top: 110px !important;
    overflow: hidden !important;
    z-index: -999 !important;
    position: relative !important;
  }
  #searchRow {
    position: fixed !important;
    top: 85px !important;
    width: 80% !important;
    padding-left: 14px !important;
  }
  #pdrList {
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
  ----------------------------------
  #pdrListDiv {
    overflow: hidden !important;
    width: 100% !important;
    max-width: 100% !important;
    margin-top: 100px !important;
    z-index: -999 !important;
    top: 100px !important;
  }

  #template {
    width: 100%! important;
  }
  #dialog {
    width: 100%! important;
    max-width: 100% !important;
    top: 100px !important;
  }

  #headerCard {
    position: sticky !important;
    width: 100%! important;
    top: 20px !important;
  }
  html, body {
    max-width: 100% !important;
    overflow-x: hidden !important;
  }
  */
</style>
