import gmapsInit from './gmaps'
// import MarkerClusterer from 'markerclustererplus-node'

let showtoilet = false
let mData = []; let mapInit = false
let showMarkerList = []; const showClusterList = []; let showmode = ''
const roadMarkerList = []; const colorMarkerlist = []; const pdrMarkerlist = []; const dogMarkerlist = []; const toiletMarkerlist = []
var selectedPdr = ''
let google; let map
var vibrateInterval

export default async function init (mapData, mapOption, element) {
  return initmap(mapData, mapOption, element)
}

// Démarre une vibration avec une durée
function startVibrate (duration) {
  navigator.vibrate(duration)
}

// Arrête la vibration
export function stopVibrate () {
  // Vide l'intervalle et annule les vibrations persistantes
  if (vibrateInterval) clearInterval(vibrateInterval)
  navigator.vibrate(0)
}

// Démarre la vibration persistante avec un intervalle et une durée donnée
export function startPeristentVibrate (duration, interval) {
  vibrateInterval = setInterval(function () {
    startVibrate(duration)
  }, interval)
}
export function getselectedpdr () {
  return selectedPdr
}
export function getusergoogle () {
  return google
}
export function showroadmarker () {
  if (showmode !== 'road') {
    showmode = 'road'
    clearshowmarker()
    showMarkerList = []
    for (let i = 0; i < roadMarkerList.length; i++) {
      roadMarkerList[i].setMap(map)
      showMarkerList.push(roadMarkerList[i])
    }
  }
}
export function showpdrmarker () {
  if (showmode !== 'pdr') {
    showmode = 'pdr'
    clearshowmarker()
    showMarkerList = []
    for (let i = 0; i < pdrMarkerlist.length; i++) {
      pdrMarkerlist[i].setMap(map)
      showMarkerList.push(pdrMarkerlist[i])
    }
    showdogmarker()
  }
}
export function showcolormarker () {
  if (showmode !== 'color') {
    showmode = 'color'
    clearshowmarker()
    showMarkerList = []
    for (let i = 0; i < colorMarkerlist.length; i++) {
      colorMarkerlist[i].setMap(map)
      showMarkerList.push(colorMarkerlist[i])
    }
  }
}
export function showtoiletmarkerlist () {
  if (showtoilet) {
  // let lmap = choice ? map |null;

    for (let i = 0; i < toiletMarkerlist.length; i++) {
      toiletMarkerlist[i].setMap(map)
    }
  }
}
export function showtoiletmarker (choice) {
  if (showtoilet !== choice) {
    showtoilet = choice
    // let lmap = choice ? map |null;
    for (let i = 0; i < toiletMarkerlist.length; i++) {
      toiletMarkerlist[i].setAnimation(google.maps.Animation.DROP)
      toiletMarkerlist[i].setMap(choice ? map : null)
    }
  }
  return showtoilet
}
function showdogmarker () {
  for (let i = 0; i < dogMarkerlist.length; i++) {
    dogMarkerlist[i].setMap(map)
  }
}

async function initmap (mapData, mapOption, element) {
  if (!mapInit) {
    mData = mapData
    // mOption = mapOption
    try {
      google = await gmapsInit()

      // var infowindow, marker
      // const geocoder = new google.maps.Geocoder();

      // map = new google.maps.Map(element, {
      map = new google.maps.Map(document.getElementById('userMaps'), {
        center: mapOption.startCenter,
        zoom: mapOption.startzoom,
        styles: [
          {
            featureType: 'administrative',
            elementType: 'geometry',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'poi',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'road',
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'transit',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          }
        ]
      })
      map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(document.getElementById('mapOption'))
      setMapData()
      //        map.setOptions({styles: styles['hide']});
      showroadmarker()
      showtoiletmarkerlist()
      mapInit = true
    } catch (error) {
    }
  }

  // Vue.dispatchEvent('test')
  return map
}
function setMapData () {
  let roadlist = []; const colorbluelist = []; const colorpinklist = []
  const colororangelist = []; const codelist = []; let lmarker
  for (const region of mData) {
    for (const zone of region.zone) {
      for (const office of zone.office) {
        for (const road of office.road) {
          for (const pdr of road.pdr) {
            lmarker = createpdrmarker(pdr)
            roadlist.push(lmarker)
            codelist.push(lmarker)
            pdrMarkerlist.push(lmarker)
            if (pdr.tier === 'pink') {
              colorpinklist.push(lmarker)
            } else if (pdr.tier === 'blue') {
              colorbluelist.push(lmarker)
            } else {
              colororangelist.push(lmarker)
            }
            if (pdr.dog) {
              dogMarkerlist.push(createdogmarker(pdr))
            }
            /* for (let code of pdr.codes) {
                codelist[code.id].push(lmarker)
                let iconstyle = [{url: '../assets/code.png',height:74,width:74}];
                codeclusterlist.push(this.createcluster(codelist,iconstyle,17,"code d'attache",mymap));
                codelist = [];
              } */
          }
          //  let iconstyle = [{url: '../assets/tier.png',height:74,width:74}];
          // blueclusterlist.push({road_id: road.id,'cluster':createcluster(colorbluelist,iconstyle,15,"tier bleu")});
          // pinkclusterlist.push({road_id: road.id,'cluster':createcluster(colorpinklist,iconstyle,15,"tier Rose")});
          // orangeclusterlist.push({road_id: road.id,'cluster':createcluster(colororangelist,iconstyle,15,"tier Orange")});
          colorMarkerlist.push(creatercolormarker('blue', colorbluelist, road.roadName))
          colorMarkerlist.push(creatercolormarker('pink', colorpinklist, road.roadName))
          colorMarkerlist.push(creatercolormarker('orange', colororangelist, road.roadName))
          roadMarkerList.push(createroadmarker(road, roadlist))
          roadlist = []
        } // end for road
        createtoiletmarkerlist(office.toilet)
        // officeclusterlist.push({office_id:office.id})
      } // end for office
    } // end for zone
  } // end for region

  map.addListener('zoom_changed', function () {
    const zoomLevel = map.getZoom()

    if (zoomLevel === 16) {
      showpdrmarker()
    } else if (zoomLevel === 15) {
      showcolormarker()
    } else if (zoomLevel < 14) {
      showroadmarker()
    }
  })
}
// temp export
export function userlocation () {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function (position) {
      var pos = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }
      /*
          infoWindow.setPosition(pos);
          infoWindow.setContent('Location found.');
          infoWindow.open(map); */
      map.setCenter(pos)
    }, function () {
      // handleLocationError(true, infoWindow, map.getCenter());
    })
  } else {
    // Browser doesn't support Geolocation
    // handleLocationError(false, infoWindow, map.getCenter());
  }
}
/*
function createcluster (markers, iconstyle, maxzom, title) {
  var markerCluster = new MarkerClusterer(map, markers,
    {
      styles: iconstyle,
      averageCenter: true,
      minimumClusterSize: markers.length,
      gridSize: 500,
      maxZoom: maxzom,
      title: title
    })

  showClusterList.push(markerCluster)
  return markerCluster
}
*/
function seticonlabelsize (labellength) {
  let labelsize
  switch (labellength) {
    case 1:
      labelsize = '18px'
      break
    case 2:
      labelsize = '16px'
      break
    case 3:
      labelsize = '14px'
      break
    case 4:
      labelsize = '12px'
      break
    case 5:
      labelsize = '10px'
      break
    case 6:
      labelsize = '8px'
      break
    default:
      labelsize = '6px'
      break
  }
  return labelsize
}
function createroadmarker (road, roadlist) {
  const url = 'http://maps.google.com/mapfiles/ms/icons/green.png'
  const labelsize = seticonlabelsize(road.roadName.length)

  let lattotal = 0; let lngtotal = 0
  for (let i = 0; i < roadlist.length; i++) {
    lattotal = lattotal + roadlist[i].position.lat()
    lngtotal = lngtotal + roadlist[i].position.lng()
  }

  lattotal = lattotal / roadlist.length
  lngtotal = lngtotal / roadlist.length

  const marker = new google.maps.Marker({
    position: { lat: lattotal, lng: lngtotal },
    label: { text: road.roadName, fontSize: labelsize },
    map: map,
    title: road.roadName,
    icon: {
      url: url,
      scaledSize: new google.maps.Size(60, 70), // scaled size
      origin: new google.maps.Point(0, -15), // origin
      anchor: new google.maps.Point(30, 60) // anchor
    }
  })
  marker.addListener('click', function () {
    const center = marker.getPosition()

    // map.setZoom(map.getZoom() + 1)
    map.setZoom(15)
    map.setCenter(center)
  })
  showMarkerList.push(marker)
  return marker
}
function createdogmarker (pdr) {
  var dogCircle = new google.maps.Circle({
    strokeColor: '#FF0000',
    strokeOpacity: pdr.dog / 10,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    map: map,
    center: pdr.homeposition,
    radius: 30
  })
  return dogCircle
}
function createtoiletmarkerlist (toiletlist) {
  for (let i = 0; i < toiletlist.length; i++) {
    const toilet = toiletlist[i]
    const marker = new google.maps.Marker({
      position: toilet.toiletposition,
      animation: google.maps.Animation.DROP,
      map: map,
      icon: { // url:mdi-toilet,
        url: require('@/assets/Icon/MapIcon/human-male-female.png'),
        scaledSize: new google.maps.Size(30, 30), // scaled size
        origin: new google.maps.Point(0, 0), // origin
        anchor: new google.maps.Point(15, 15) // anchor
      }
    })
    marker.setMap(null)
    toiletMarkerlist.push(marker)
  }
}
function creatercolormarker (color, markerlist, roadname) {
  let url = 'http://maps.google.com/mapfiles/ms/icons/'
  const labelsize = seticonlabelsize(roadname.length)
  url += color + '.png'
  let lattotal = 0; let lngtotal = 0
  for (let i = 0; i < markerlist.length; i++) {
    lattotal = lattotal + markerlist[i].position.lat()
    lngtotal = lngtotal + markerlist[i].position.lng()
  }

  lattotal = lattotal / markerlist.length
  lngtotal = lngtotal / markerlist.length

  const marker = new google.maps.Marker({
    position: { lat: lattotal, lng: lngtotal },
    label: { text: roadname, fontSize: labelsize },
    map: map,
    title: roadname,
    icon: {
      url: url,
      scaledSize: new google.maps.Size(60, 70), // scaled size
      origin: new google.maps.Point(0, -15), // origin
      anchor: new google.maps.Point(30, 60) // anchor
    }
  })
  marker.addListener('click', function () {
    const center = marker.getPosition()

    // map.setZoom(map.getZoom() + 1)
    map.setZoom(16)
    map.setCenter(center)
  })
  showMarkerList.push(marker)
  return marker
}

function createpdrmarker (pdr) {
  let url = ''
  const labelsize = seticonlabelsize(pdr.door.length)

  if (pdr.type === 'App') {
    url = require('@/assets/Icon/MapIcon/' + pdr.tier + '48_app.png')
  }
  if (pdr.type === 'Dom') {
    url = require('@/assets/Icon/MapIcon/' + pdr.tier + '48_home.png')
    // url = '../assets/'+pdr.tier+'48_home.png'
  }
  if (pdr.type === 'Com') {
    url = require('@/assets/Icon/MapIcon/' + pdr.tier + '48_store.png')
  }
  /*
  let infowindow = new google.maps.InfoWindow({
    content: document.getElementById('Info')
  });
*/

  // fin
  const marker = new google.maps.Marker({
    position: pdr.homeposition,
    label: { text: pdr.door, fontSize: labelsize, color: 'black' },
    shape: {
      type: 'rect',
      coords: [0, 0, 2, 2]
    },
    map: map,
    animation: google.maps.Animation.DROP,
    title: pdr.door + ' ' + pdr.street,
    icon: {
      url: url,
      size: new google.maps.Size(48, 48),
      //      scaledSize: new google.maps.Size(40, 40), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(24, 28), // anchor
      labelOrigin: new google.maps.Point(24, 32)
    }
  })

  marker.addListener('click', function () {
    // ************************* test debut  ***************************
    var event = new CustomEvent('selectpdr', { detail: pdr })
    document.dispatchEvent(event)

    map.setCenter(pdr.homeposition)
    /*    var ediv = document.getElementById("pano");
    ediv.style.display = 'block'
    var mdiv = document.getElementById("Maps");
    mdiv.style.height = "60%"
    if(pdr.picture){
        var elem = document.createElement("IMG");
        elem.setAttribute("src", pdr.picture);
  //      elem.setAttribute("height", "200");
  //      elem.setAttribute("width", "400");
        elem.setAttribute("alt", "Shipping point");

        ediv.innerHTML = '';
        ediv.appendChild(elem);
    }else{

        var panorama = new google.maps.StreetViewPanorama(
            ediv, {
                position: pdr.homeposition,
                pov: {
                heading: 34,
                pitch: 10
                }
            });
        map.setStreetView(panorama);
    }

// ************************* test fin  ***************************
    let component = Vue.component('infowindowscomponent')
    Vue.use(Vuetify);
    var InfoWindow = Vue.extend(component);

    var instance = new InfoWindow({
        propsData: {
            selpdr: pdr,
            google: google
        }
    });
    instance.$mount();
    if (openInfowindows){
        openInfowindows.close();
    }
    openInfowindows = new google.maps.InfoWindow({
        content: instance.$el,
    });

    openInfowindows.open(map, marker);
    map.addListener('click',function(){
            openInfowindows.close();
            ediv.style.display = 'none'
            mdiv.style.height = "100%"
            map.setStreetView(null)
        })
    //selectedPdr = pdr;
    /*
    openInfowindows.addListener('close',function (){
        ediv.style.display = 'none'
        mdiv.style.height = "100%"
    })
    */
  })
  showMarkerList.push(marker)
  return marker
}
function clearshowmarker () {
  for (let i = 0; i < showClusterList.length; i++) {
    showClusterList[i].setMap(null)
  }
  for (let i = 0; i < showMarkerList.length; i++) {
    showMarkerList[i].setMap(null)
  }
  for (let i = 0; i < dogMarkerlist.length; i++) {
    dogMarkerlist[i].setMap(null)
  }
  // showMarkerList = [];
}
