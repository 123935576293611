<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="444" class="mx-auto" centered>
      <v-card class='mx-auto' centered>
          <p v-if="title" class="pt-10 pb-3 title mx-1 text-center">
            {{ title }}
          </p>
          <!-- <p v-if="from" class="pt-3">
            <v-row no-gutters>
              <v-spacer></v-spacer>
              {{ from }}
              <v-icon class="ms-2 me-2" color="green">mdi-arrow-right-bold</v-icon>
              {{ to }}
              <v-spacer></v-spacer>
             </v-row>
          </p> -->
          <div v-if="from" class="pt-3">
            <p class="mx-5 yellow lighten-4 title" align="center">{{ $t('common.before') }}</p>
            <p class="mx-5 mt-n4 grey lighten-4" align="center">{{ from }}</p>
          </div>
          <div v-if="from && to">
            <v-row no-gutters>
              <v-spacer></v-spacer>
              <v-icon class="ms-2 me-2" x-large color="green">mdi-arrow-down-box</v-icon>
              <v-spacer></v-spacer>
            </v-row>
          </div>
          <div v-if="to" class="pt-4">
            <p v-if="from" class="mx-5 yellow lighten-4 title" align="center">{{ $t('common.after') }}</p>
            <p class="mx-5 mt-n4 grey lighten-4" align="center">{{ to }}</p>
          </div>
          <div v-if="param" class="pt-3">
            <v-container v-if="createRouteMode">
              <v-select
                v-model="routeType"
                label="Type"
                outlined
                filled
                :items="routeTypeItem"
              ></v-select>
            </v-container>
            <v-container v-if="modifyPocIdArrayMode" justify="center">
              <div v-if="item.addList.length">
                <p class="text-center title">{{ $t('common.add') }} :</p>
                <p v-for="(pocId, index) in item.addList" :key="index" class="text-center">
                  {{ $store.state.pocModule.data[pocId].pdrListAddress }}
                </p>
                <v-divider></v-divider>
              </div>
              <div v-if="item.remList.length">
                <p class="text-center title">{{ $t('common.remove') }} :</p>
                <p v-for="(pocId, index) in item.remList" :key="index" class="text-center">
                  {{ $store.state.pocModule.data[pocId].pdrListAddress }}
                </p>
              </div>
            </v-container>
          </div>
          <div v-if="refreshRouteMode" class="mx-4">
            <v-checkbox
              v-model="refreshPub"
              :label="$t('common.pub')"
            ></v-checkbox>
            <v-checkbox
              v-model="refreshVacant"
              :label="$t('common.vacant')"
            ></v-checkbox>
          </div>
          <div v-if="param && param.mode === 'standard-explain'">
            <v-card-text>
              <v-textarea
                v-model="explain"
                outlined
                filled
                :label="$t('common.explain')"
                class="mt-5"
              ></v-textarea>
            </v-card-text>
          </div>
          <div v-if="confirmStandardMode">
            <p v-if="param.text" class="text-center mx-2">
            * {{ param.text }}
            </p>
          </div>
          <v-card-text centered>
            <v-row class="pt-10 mb-5">
              <v-spacer></v-spacer>
              <v-btn @click="$emit('cancel', item)">{{ confirmYesNoMode ? $t('common.no') : $t('common.cancel') }}</v-btn>
              <v-spacer></v-spacer>
              <v-btn v-if="validToConfirm" color="success" @click="confirm()">{{ confirmYesNoMode ? $t('common.yes') : $t('common.confirm') }}</v-btn>
              <v-spacer></v-spacer>
            </v-row>
          </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
<script>
export default {
  props: ['title', 'from', 'to', 'item', 'param'],
  data () {
    return {
      dialog: true,
      routeType: null,
      refreshVacant: true,
      refreshPub: true,
      explain: null
    }
  },
  methods: {
    confirm () {
      if (this.createRouteMode) {
        this.$emit('confirm', ({ routeName: this.item, routeType: this.routeType }))
      } else if (this.refreshRouteMode) {
        this.$emit('confirm', ({ routeName: this.item, pub: this.refreshPub, vacant: this.refreshVacant, routeId: this.$store.getters['routeModule/getRouteIdByName'](this.item) }))
      } else if (this.param && this.param.mode === 'standard-explain') {
        this.item.explain = this.explain
        this.$emit('confirm', (this.item))
      } else {
        this.$emit('confirm', (this.item))
      }
    }
  },
  computed: {
    validToConfirm () {
      if (this.createRouteMode) {
        if (this.routeType) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    createRouteMode () {
      if (this.param) {
        if (this.param.mode === 'importRoute') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    modifyPocIdArrayMode () {
      if (this.param) {
        if (this.param.mode === 'modifyPocIdArray') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    refreshRouteMode () {
      if (this.param) {
        if (this.param.mode === 'refreshRoute') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    confirmStandardMode () {
      if (this.param) {
        if (this.param.mode === 'standard' || this.param.mode === 'standard-explain') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    confirmYesNoMode () {
      if (this.param) {
        if (this.param.mode === 'yesno') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    routeTypeItem () {
      const item = []
      const routetype = this.$store.state.officeSelectedModule.data.routeType
      for (let i = 0; i < routetype.length; i++) {
        const type = routetype[i]
        item.push({ text: this.$t('routetype.' + type.typeId), value: type.typeId })
      }
      return item
    }
  }
}
</script>
