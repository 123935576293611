<template>
  <v-app>
    <router-view/>
    <alert-cmp/>
    <loadingMain/>
  </v-app>
  <!-- <v-app>
    <b-navbar toggleable="lg" type="dark" variant="info">
      <b-navbar-brand to="/">Photo Editor</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item to="/" :active="path  == '/'">Home</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view/>
    <alert-cmp/>
  </v-app> -->
</template>

<script>
import loadingMain from './components/common/PMT_LoadingMain_Poppup.vue'
// import colors from 'vuetify/lib/util/colors'
export default {
  components: { loadingMain },
  name: 'App',
  computed: {
    dayColor () {
      return this.$store.getters['paramModule/dayColor'].color
    },
    toDay () {
      return this.$store.getters.today
    },
    isDarkTheme () {
      return this.$store.getters['userLoggedModule/isDarkThemes']
    },
    themesMode () {
      return this.$store.getters['userLoggedModule/getThemesMode']
    },
    currentThemes () {
      return this.$store.getters.getCurrentThemes
    }
    // userIsAuthenticated () {
    //   if (this.$store.getters.user !== null && this.$store.getters.user !== undefined) {
    //     return true
    //   } else {
    //     return false
    //   }
    // },
    // dataloadComplete () {
    //   if (this.$store.getters.loadfile) {
    //     return true
    //   } else {
    //     return false
    //   }
    // },
    // user_job_type_id () {
    //   if (this.userIsAuthenticated) {
    //     if (this.$store.getters.userLoadPartialReady) {
    //       return this.$store.state.userModule.data[this.$store.state.userinfokey].job_type_id
    //     } else { return 0 }
    //   } else { return 0 }
    // }
  },
  watch: {
    dayColor (newValue) {
      this.$store.dispatch('assignCurrentThemes')
      // this.$vuetify.theme.themes.light.primary = newValue
      // this.$vuetify.theme.themes.light.accent = colors.amber.accent3
    },
    toDay (newValue) {
      this.$store.dispatch('assignCurrentThemes')
      // this.$vuetify.theme.themes.light.primary = this.$store.getters['paramModule/getColorOfDay'](new Date()).color
      // this.$vuetify.theme.themes.light.accent = colors.amber.accent3
    },
    isDarkTheme (newValue) {
      this.setDarkTheme(newValue)
    },
    themesMode (newValue) {
      this.$store.dispatch('assignCurrentThemes')
    },
    currentThemes (newValue) {
      if (newValue) {
        this.setThemes(newValue)
      } else {
        this.setThemes(this.$store.getThemesByName('noColor'))
      }
    },
    dataloadComplete (newValue) {
      if (newValue) {
        this.showapp = true
      } else {
        this.showapp = false
      }
    },
    showlogin (newValue) {
      if (newValue) {
        this.showloading = false
      } else {
        if (!this.showapp) {
          this.showloading = true
        }
      }
    },
    showapp (newValue) {
      if (newValue) {
        this.showloading = false
      } else {
        if (!this.showlogin) {
          this.showloading = true
        }
      }
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    const options = {
      enableHighAccuracy: true,
      timeout: 0,
      maximumAge: 0
    }
    const self = this
    function success (pos) {
      self.cptPosErr = 0
      if (!self.isWaitForUpdate && self.$store.getters['userLoggedModule/getMapMode'] === 'track-me' && pos.coords) {
        if (self.$store.getters.getCurrentPosition.lat !== pos.coords.latitude && self.$store.getters.getCurrentPosition.lng !== pos.coords.longitude) {
          self.isWaitForUpdate = true
          self.$store.dispatch('updateCurrentPosition', { lat: pos.coords.latitude, lng: pos.coords.longitude })
            .then(() => { self.isWaitForUpdate = false })
        }
      }
    }
    function error (err) {
      self.cptPosErr++
      if (self.cptPosErr === 5) {
        self.$store.dispatch('showAlert', ('ERROR(' + err.code + '): ' + err.message))
      }
    }
    this.watchId = navigator.geolocation.watchPosition(success, error, options)
    // document.addEventListener('touchstart', handleTouchstart, supportsPassiveOption ? { passive : false } : false)
    // document.documentElement.style.overflow = 'hidden'
  },
  created () {
    this.$store.dispatch('refreshCurrentPosition')
    window.addEventListener('resize', this.myEventHandler)
    // document.addEventListener('resize', this.myEventHandler)
    this.$on('message', (message) => {
      this.message = message
    })
    this.$store.getters.EventBus.$on('sizeChange', () => {
      this.myEventHandler()
    })
    document.addEventListener('gesturestart', function (e) {
      e.preventDefault()
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = 0.99
    })

    document.addEventListener('gesturechange', function (e) {
      e.preventDefault()
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = 0.99
    })

    document.addEventListener('gestureend', function (e) {
      e.preventDefault()
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = 0.99
    })
    this.$store.getters.EventBus.$on('setLocale', (locale) => {
      this.$i18n.locale = locale
      this.$vuetify.lang.current = locale
    })
    this.$store.getters.EventBus.$on('setVuetifyThemes', (themes) => {
      // this.setThemes(themes)
    })
    // this.setThemes(this.pToolThemes.noColor)
    this.$store.dispatch('assignCurrentThemes')
    // this.$store.commit('setCurrentThemes', this.$store.getThemesByName('noColor'))
    // this.setThemes(this.currentThemes ? this.currentThemes : this.$setCurrentThemes)
    this.setDarkTheme(this.isDarkTheme)
    this.$store.dispatch('initApp')
  },
  destroyed () {
    if (this.watchId) { navigator.geolocation.clearWatch(this.watchId) }
    window.removeEventListener('resize', this.myEventHandler)
  },
  data () {
    return {
      watchId: null,
      calendarLoaded: false,
      showloading: true,
      showlogin: false,
      showapp: false,
      fullscreen: false,
      message: false,
      isWaitForUpdate: false,
      cptPosErr: 0
      // pToolThemes: {
      //   blue: {
      //     light: {
      //       primary: colors.blue.darken2,
      //       secondary: colors.shades.black,
      //       accent: colors.amber.accent3,
      //       error: colors.red.accent2,
      //       info: colors.blue,
      //       success: colors.green,
      //       warning: colors.amber
      //     },
      //     dark: {
      //       primary: colors.blue.darken2,
      //       secondary: colors.shades.black,
      //       accent: colors.amber.accent3,
      //       error: colors.red.accent2,
      //       info: colors.blue,
      //       success: colors.green,
      //       warning: colors.amber
      //     }
      //   },
      //   orange: {
      //     light: {
      //       primary: colors.orange,
      //       secondary: colors.shades.black,
      //       accent: colors.amber.accent3,
      //       error: colors.red.accent2,
      //       info: colors.blue,
      //       success: colors.green,
      //       warning: colors.amber
      //     },
      //     dark: {
      //       primary: colors.orange,
      //       secondary: colors.shades.black,
      //       accent: colors.amber.accent3,
      //       error: colors.red.accent2,
      //       info: colors.blue,
      //       success: colors.green,
      //       warning: colors.amber
      //     }
      //   },
      //   pink: {
      //     light: {
      //       primary: colors.pink,
      //       secondary: colors.shades.black,
      //       accent: colors.amber.accent3,
      //       error: colors.red.accent2,
      //       info: colors.blue,
      //       success: colors.green,
      //       warning: colors.amber
      //     },
      //     dark: {
      //       primary: colors.pink,
      //       secondary: colors.shades.black,
      //       accent: colors.amber.accent3,
      //       error: colors.red.accent2,
      //       info: colors.blue,
      //       success: colors.green,
      //       warning: colors.amber
      //     }
      //   },
      //   noColor: {
      //     light: {
      //       primary: colors.grey.darken3,
      //       secondary: colors.shades.black,
      //       accent: colors.amber.accent3,
      //       error: colors.red.accent2,
      //       info: colors.blue,
      //       success: colors.green,
      //       warning: colors.amber
      //     },
      //     dark: {
      //       primary: colors.grey.lighten2,
      //       secondary: colors.shades.black,
      //       accent: colors.amber.accent3,
      //       error: colors.red.accent2,
      //       info: colors.blue,
      //       success: colors.green,
      //       warning: colors.amber
      //     }
      //   }
      // }
    }
    //
  },
  methods: {
    setThemes (themes) {
      this.$vuetify.theme.themes.light.primary = themes.light.primary
      this.$vuetify.theme.themes.light.secondary = themes.light.secondary
      this.$vuetify.theme.themes.light.accent = themes.light.accent
      this.$vuetify.theme.themes.light.error = themes.light.error
      this.$vuetify.theme.themes.light.info = themes.light.info
      this.$vuetify.theme.themes.light.success = themes.light.success
      this.$vuetify.theme.themes.light.warning = themes.light.warning

      this.$vuetify.theme.themes.dark.primary = themes.dark.primary
      this.$vuetify.theme.themes.dark.secondary = themes.dark.secondary
      this.$vuetify.theme.themes.dark.accent = themes.dark.accent
      this.$vuetify.theme.themes.dark.error = themes.dark.error
      this.$vuetify.theme.themes.dark.info = themes.dark.info
      this.$vuetify.theme.themes.dark.success = themes.dark.success
      this.$vuetify.theme.themes.dark.warning = themes.dark.warning
    },
    setDarkTheme (isDark) {
      this.$vuetify.theme.dark = isDark
    },
    myEventHandler (e) {
      // window.scrollTo(0, 0)
      // document.documentElement.style.overflow = 'hidden'
      window.scrollTo(0, 0)
      this.$store.dispatch('resize')
      // this.$store.getters.EventBus.$emit('sizeChange')
    }
  }
}
</script>

<style lang="scss">
// .page {
//   padding: 20px;
// }
// button,
// .btn.btn-primary {
//   margin-right: 10px !important;
// }
// .button-toolbar {
//   margin-bottom: 10px;
// }
</style>
