import Vue from 'vue'
import App from './App.vue'
import { routes } from './router'

// import * as firebase from 'firebase'
// import firebase from 'firebase/app'
import store from './store'
import vuetify from './plugins/vuetify'
import { VIcon, VBtn } from 'vuetify/lib'
// import 'vuetify/src/stylus/app.styl'
// import vuetify from 'vuetify'
import i18n from './i18n'
import VImageInput from 'vuetify-image-input'
// import ImageUploader from 'vue-image-upload-resize'
import VueRouter from 'vue-router'
import * as VueGoogleMaps from 'vue2-google-maps'
// import BootstrapVue from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// import 'tui-image-editor/dist/tui-image-editor.css'
import VueZoomer from 'vue-zoomer'
import firebase from 'firebase/app'
import 'firebase/auth'
// drage & drop  <v-draggable-treeview
// import VuetifyDraggableTreeview from 'vuetify-draggable-treeview'

// drage & drop  <vue-nestable
// import VueNestable from 'vue-nestable'

// in component import draggable from 'vuedraggable'

// Vue.use(BootstrapVue)
Vue.use(VueZoomer)
// Vue.use(VuetifyDraggableTreeview)
// Vue.use(VueNestable)

// const firebase = require('firebase/app')
// import ''

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBCYzXrnUG2oYl9LaUoMURILgcFtag0TOI',
    region: 'CA',
    language: 'fr'
    // libraries: 'places' // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
    // If you want to set the version, you can do so:
    // v: '3.26',
  },

  // If you intend to programmatically custom event listener code
  // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  // you might need to turn this on.
  autobindAllEvents: false,

  // If you want to manually install components, e.g.
  // import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  // Vue.component('GmapMarker', GmapMarker)
  // then disable the following:
  installComponents: true
})
// Vue.use(ImageUploader)
// Vue.component('mail', require('@/components/mail/mail.vue').default)
// Display :

Vue.component('create-message', require('@/components/supervisor/PMT_CreateMessage_popup.vue').default)

Vue.component('display-stats', require('@/components/display/Display_Stats.vue').default)
Vue.component('display-pocitem', require('@/components/display/PMT_DisplayInfo_PocItem.vue').default)
Vue.component('display-picture-fullscreen', require('@/components/display/Display_Picture_FullScreen.vue').default)
Vue.component('display-udl', require('@/components/display/Display_UDLInfo.vue').default)
Vue.component('display-bpcom', require('@/components/display/Display_BpComInfo.vue').default)
Vue.component('display-travel-timeline', require('@/components/display/Display_TravelTimeLine.vue').default)

Vue.component('pmt-guardian-event-popup', require('@/components/Guardian/PMT_Guardian_Event_Popup.vue').default)
Vue.component('guardian-danger-bottomsheet', require('@/components/Guardian/PMT_Guardian_Danger_BottomSheet.vue').default)
Vue.component('dangerInfo-popup', require('@/components/Guardian/PMT_DisplayDangerInfo_popup.vue').default)

Vue.component('stats-popup', require('@/components/common/PMT_Stats_popup.vue').default)
Vue.component('select-item-popup', require('@/components/common/PMT_SelectItem_popup.vue').default)
Vue.component('loading-popup', require('@/components/common/PMT_Loading_Poppup.vue').default)
Vue.component('confirm-popup', require('@/components/common/PMT_Confirm_Poppup.vue').default)
Vue.component('pmt-showList-popup', require('@/components/common/PMT_ShowList_Popup.vue').default)
Vue.component('menu-csocial', require('@/components/common/PMT_Menu_CSocial.vue').default)
Vue.component('menu-mixcomite', require('@/components/common/PMT_Menu_MixComite.vue').default)
Vue.component('menu-formation', require('@/components/common/PMT_Menu_Formation.vue').default)
Vue.component('contact-popup', require('@/components/common/PMT_Contact_PoPup.vue').default)
// Vue.component('login', require('@/components/user/Login.vue').default)
// Vue.component('homePage', require('@/components/share/PMT_HomePage.vue').default)
Vue.component('alert-cmp', require('@/components/share/Alert.vue').default)

Vue.component('create-user', require('@/components/admin/CreateUser.vue').default)
Vue.component('create-office', require('@/components/admin/CreateOffice.vue').default)
Vue.component('create-zone', require('@/components/admin/CreateZone.vue').default)
Vue.component('manage-contact-office', require('@/components/admin/ManageContactOffice.vue').default)
Vue.component('manage-contact-zone', require('@/components/admin/ManageContactZone.vue').default)
Vue.component('create-edit-contact', require('@/components/admin/CreateEditContact.vue').default)

Vue.component('pmt-match-pocsequence-popup', require('@/components/localAdmin/PMT_MatchPocSequence_Popup.vue').default)
Vue.component('pmt-create-routesequence-popup', require('@/components/localAdmin/PMT_CreateRouteSequence_PopUp.vue').default)
Vue.component('pmt-import-poclist', require('@/components/localAdmin/PMT_Import_PocList.vue').default)
// Vue.component('pmt-import-oldAddress', require('@/components/localAdmin/PMT_Import_OldAddressList.vue').default)
Vue.component('pmt-import-harmonize', require('@/components/localAdmin/PMT_Import_Harmonize.vue').default)
Vue.component('pmt-import-bpcom', require('@/components/localAdmin/PMT_Import_BpCom.vue').default)
Vue.component('pmt-import-bpcomdetail', require('@/components/localAdmin/PMT_Import_BpComDetail_Popup.vue').default)

Vue.component('pmt-import-sequence', require('@/components/localAdmin/PMT_Import_Sequence.vue').default)
Vue.component('pmt-menu-import-csv', require('@/components/localAdmin/PMT_MenuImportCsv.vue').default)
Vue.component('pmt-localadmin-home', require('@/components/HomePage/PMT_LocalAdmin_Home.vue').default)
Vue.component('pmt-menu-admin-local', require('@/components/localAdmin/PMT_MenuLocalAdmin.vue').default)
// Vue.component('pmt-la-app-bar', require('@/components/localAdmin/PMT_LA_App_Bar.vue').default)
Vue.component('pmt-la-nav-drawer', require('@/components/localAdmin/PMT_LA_Nav_Drawer.vue').default)
Vue.component('pmt-correct-pdr', require('@/components/localAdmin/PMT_CorrectPdr.vue').default)
// Vue.component('pmt-edit-route', require('@/components/localAdmin/PMT_EditRoute.vue').default)
Vue.component('pmt-edit-rule', require('@/components/localAdmin/PMT_EditRule.vue').default)
// Vue.component('pmt-route-manager', require('@/components/localAdmin/PMT_RouteManager.vue').default)

Vue.component('pmt-note-editor', require('@/components/editor/PMT_NoteEditor.vue').default)
Vue.component('pmt-report-editor', require('@/components/editor/PMT_ReportEditor.vue').default)
Vue.component('pmt-route-editor', require('@/components/editor/PMT_RouteEditor.vue').default)
Vue.component('pmt-travel-editor', require('@/components/editor/PMT_TravelEditor.vue').default)
Vue.component('travel-editor-item', require('@/components/editor/PMT_TravelEditorItem.vue').default)
Vue.component('pmt-travel-new-editor', require('@/components/editor/PMT_TravelNewEditor.vue').default)
Vue.component('pmt-officeitem-editor', require('@/components/editor/PMT_OfficeItemEditor.vue').default)

Vue.component('pmt-travel-manager', require('@/components/manager/PMT_TravelManager.vue').default)
Vue.component('pmt-report-manager', require('@/components/manager/PMT_ReportManager.vue').default)
Vue.component('pmt-route-manager', require('@/components/manager/PMT_RouteManager.vue').default)
Vue.component('pmt-officeitem-manager', require('@/components/manager/PMT_OfficeItemManager.vue').default)

// Vue.component('pmt-menu-create-report', require('@/components/share/PMT_MenuCreateReport.vue').default)
Vue.component('pmt-menu-create-mapItem', require('@/components/share/PMT_Create_MapItem_popup.vue').default)
Vue.component('pmt-createedit-pocItem', require('@/components/share/PMT_CreateEdit_PocItem_popup.vue').default)
Vue.component('pmt-edit-pocItem', require('@/components/share/PMT_Edit_PocItem_popup.vue').default)

Vue.component('pmt-edit-greybox', require('@/components/share/PMT_Edit_GreyBox.vue').default)

// Vue.component('create-report-dog', require('@/components/share/CreateReportDog.vue').default)
// Vue.component('create-report-brokenstep', require('@/components/share/CreateReportBrokenStep.vue').default)
// Vue.component('create-report-fullbox', require('@/components/share/CreateReportFullBox.vue').default)
// Vue.component('create-report-pub', require('@/components/share/CreateReportPub.vue').default)
// Vue.component('create-report-ice', require('@/components/share/CreateReportIce.vue').default)
// Vue.component('create-report-other', require('@/components/share/CreateReportOther.vue').default)

Vue.component('pmt-import-picture', require('@/components/common/PMT_PictureImport.vue').default)
Vue.component('picture-manager', require('@/components/common/PMT_Picture_Manager.vue').default)
Vue.component('picture-editor', require('@/components/common/PMT_Picture_Editor.vue').default)
Vue.component('pmt-search-popup', require('@/components/common/PMT_Search_popup.vue').default)
Vue.component('pmt-select-popup', require('@/components/common/PMT_Select_popup.vue').default)
// Vue.component('pmt-user-param', require('@/components/common/PMT_User_Param.vue').default)
Vue.component('user-param-popup', require('@/components/common/PMT_UserParam_popup.vue').default)

Vue.component('pmt-road-import', require('@/components/share/PMT_Roadimport.vue').default)
Vue.component('pmt-position-map-set', require('@/components/share/PMT_PositionMapset.vue').default)
Vue.component('pmt-loading', require('@/components/share/PMT_Loading.vue').default)
Vue.component('pmt-loading-progress', require('@/components/share/PMT_LoadingProgress.vue').default)
Vue.component('pmt-loading-progress-local', require('@/components/share/PMT_LoadingProgressLocal.vue').default)
Vue.component('pmt-loading-local', require('@/components/share/PMT_LoadingLocal.vue').default)
Vue.component('pmt-select-pdr', require('@/components/share/PMT_SelectPdr_popup.vue').default)
Vue.component('pmt-select-greybox', require('@/components/share/PMT_SelectGreyBox_popup.vue').default)

Vue.component('VImageInput', VImageInput)

// Vue.component('search-google', require('@/components/admin/Google.vue').default)
Vue.component('pmt-postman-home', require('@/components/HomePage/PMT_Postman_Home.vue').default)

Vue.component('pmt-pm-tool', require('@/components/postman/PMT_PM_tool.vue').default)
Vue.component('pmt-pm-attache', require('@/components/postman/PMT_PM_Attache.vue').default)
Vue.component('pmt-pm-babillard', require('@/components/postman/PMT_PM_Babillard.vue').default)
// Vue.component('pmt-pm-app-bar', require('@/components/postman/PMT_PM_App_Bar.vue').default)
Vue.component('pmt-pm-nav-drawer', require('@/components/postman/PMT_PM_Nav_Drawer.vue').default)

Vue.component('maps', require('@/components/maps/Maps.vue').default)
Vue.component('maps-postman', require('@/components/maps/Maps_Postman.vue').default)
Vue.component('maps-infowindow', require('@/components/maps/Maps_InfoWindow.vue').default)
Vue.component('geocoder-search', require('@/components/maps/GMaps_GeocoderSearch.vue').default)

Vue.component('pmt-maps', require('@/components/share/PMT_maps.vue').default)
Vue.component('pmt-maps-info', require('@/components/maps/PMT_Maps_Info.vue').default)
Vue.component('pmt-param-maps-info', require('@/components/maps/PMT_Param_Maps_Info.vue').default)
Vue.component('pmt-maps-popup-placeinfo', require('@/components/maps/PMT_Place_Popup_Info.vue').default)
Vue.component('pmt-create-map-direction-popup', require('@/components/maps/PMT_CreateMapDirection_Popup.vue').default)
Vue.component('maps-user', require('@/components/maps/GMaps_User.vue').default)

Vue.component('pmt-pocofplace-popup', require('@/components/share/PMT_PocOfPlace_Popup.vue').default)
Vue.component('pmt-edit-place-popup', require('@/components/share/PMT_Place_Edit_Popup.vue').default)
Vue.component('pmt-edit-pdr-popup', require('@/components/share/PMT_Pdr_Edit_PoPup.vue').default)
// Vue.component('pmt-edit-route', require('@/components/share/PMT_EditRoute.vue').default)
Vue.component('pmt-create-report-popup', require('@/components/share/PMT_Create_Report_popup.vue').default)
Vue.component('pmt-admin-greybox', require('@/components/share/PMT_Admin_GreyBox.vue').default)
Vue.component('pmt-picker-date-popup', require('@/components/share/PMT_Picker_Date_Popup.vue').default)
Vue.component('pmt-picker-time-popup', require('@/components/share/PMT_Picker_Time_Popup.vue').default)

// Vue.component('pmt-select-pdr-input', require('@/components/share/PMT_Select_Pdr.vue').default)
Vue.config.productionTip = false

Vue.use(vuetify)
// Vue.use(VIcon)
/*
service firebase.storage {
  match /b/{bucket}/o {
    match /{allPaths=**} {
      allow read, write: if request.auth != null;
    }
  }
}
*/
Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  routes
})
router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (requiresAuth && !currentUser) next({ name: 'login' })
  // else if (!requiresAuth && currentUser) next('home')
  else next()
})
// new Vue({
//   el: '#app',
//   router,
//   store,
//   vuetify,
//   i18n,
//   VIcon,
//   VBtn,
//   render: h => h(App),
//   created () {
//     // this.$store.dispatch('checkSignIn')
//   }
// }).$mount('#app')
let app = ''
firebase.auth().onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      el: '#app',
      router,
      store,
      vuetify,
      i18n,
      VIcon,
      VBtn,
      render: h => h(App),
      created () {
        // this.$store.dispatch('checkSignIn')
      }
    }).$mount('#app')
  }
})
