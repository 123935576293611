<template>
  <div>
    <v-dialog v-model="$store.getters.loadingProgress" persistent max-width="310" class="mx-auto" centered>
      <v-card class='mx-auto' centered>
          <v-card-title>
            {{ $store.getters.loadingProgressTitle }}
          </v-card-title>
          <v-card-text centered>
            <v-row>
              <v-progress-linear
                indeterminate
                color="primary"
              ></v-progress-linear>
            </v-row>
          </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
<script>
export default {
  data () {
    return {
      progressValue: 0,
      dialog: false
    }
  },
  created () {
    this.$store.state.EventBus.$on('proccessprogress', (value) => {
      this.progressValue = value
    })
  },
  methods: {
  }
}
</script>
