const routeModule = {
  firestorePath: 'officeData/{officeId}/route/',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'routeModule',
  statePropName: 'data',
  namespaced: true, // automatically added

  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {
  },
  getters: {
    sortedRoute: (state, getters, rootState, rootGetters) => {
      function compare (a, b) {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      }
      if (rootGetters.showSttpRouteAccess && rootGetters.showFfrsRouteAccess) {
        // All Route
        return Object.values(state.data).filter(route => route.isActive).sort(compare)
      } else if (rootGetters.showSttpRouteAccess) {
        // STTP Route
        return Object.values(state.data).filter(route => route.type === 1 && route.isActive).sort(compare)
      } else if (rootGetters.showFfrsRouteAccess) {
        // FFrs Route
        return Object.values(state.data).filter(route => route.type === 2 && route.isActive).sort(compare)
      } else {
        return []
      }
    },
    getOfficialSeqIdOfRouteId: (state) => (routeId) => {
      const route = state.data[routeId]
      if (route.sequenceList) {
        return route.sequenceList.filter(x => x.official)[0].id.toString()
      } else {
        return null
      }
    },
    getActiveRoute: (state) => {
      return Object.values(state.data).filter(route => route.isActive)
    },
    getAllRoute: (state) => {
      return Object.values(state.data).sort(function (a, b) {
        return a.name > b.name
      }) // your sort function
    },
    sortedRouteAllType: (state, getters) => {
      return Object.values(state.data).filter(route => route.isActive).sort(function (a, b) {
        const aname = a.name.toUpperCase()
        const bname = b.name.toUpperCase()
        let comparison = 0
        if (aname > bname) {
          comparison = 1
        } else if (aname < bname) {
          comparison = -1
        }
        return comparison
      })
    },
    isOfficialSequence: (state) => (sequenceId, routeId) => {
      const index = state.data[routeId].sequenceList.map(function (x) { return x.id }).indexOf(sequenceId)
      if (index < 0) {
        return false
      } else {
        return (state.data[routeId].sequenceList[index].official)
      }
    },
    getRouteMakerData: (state, getters, rootState, rootGetters) => {
      const google = rootGetters.getGoogle
      // const EventBus = rootGetters.EventBus
      const bounds = new google.maps.LatLngBounds(rootGetters['officeSelectedModule/getOfficePosition'])
      const routeMarker = []
      getters.getActiveRoute.forEach(route => {
        var tempRName
        if (route.name.substr(route.name.length - 3, 1) === '0') {
          tempRName = route.name.substr(route.name.length - 2)
        } else {
          tempRName = route.name.substr(route.name.length - 3)
        }
        bounds.extend(route.position)
        const isNotLoadedRoute = rootGetters.getSelectedRouteId !== route.id
        const shortName = tempRName
        routeMarker.push({
          type: 'routeMarker',
          routeId: route.id,
          title: route.name,
          isOpenInfoWindow: isNotLoadedRoute,
          stat: route.stat,
          marker: rootGetters.getGoogleMarker({
            type: 'routeMarker',
            label: shortName,
            routeId: route.id,
            position: route.position,
            pos: Number(shortName)
          })
        })
      })
      return { markerList: routeMarker, bounds: bounds.toJSON() }
    },
    getRouteMarker: (state, getters, rootState, rootGetters) => {
      const google = rootGetters.getGoogle
      // const EventBus = rootGetters.EventBus
      const bounds = new google.maps.LatLngBounds(rootGetters['officeSelectedModule/getOfficePosition'])
      const routeMarker = []
      getters.sortedRoute.forEach(route => {
        var tempRName
        if (route.name.substr(route.name.length - 3, 1) === '0') {
          tempRName = route.name.substr(route.name.length - 2)
        } else {
          tempRName = route.name.substr(route.name.length - 3)
        }
        const shortName = tempRName
        route.sequenceList.forEach(seq => {
          if (seq.official) {
            bounds.extend(seq.pos)
            const isNotLoadedRoute = rootGetters.getSelectedRouteId !== route.id
            const routeMarkerInfo = {
              type: 'routeMarker',
              label: { text: shortName, fontSize: '18px', color: isNotLoadedRoute ? 'red' : 'green' },
              title: route.name,
              url: require('@/assets/Icon/MapIcon/route.png'),
              position: seq.pos,
              routeId: route.id,
              isOpenInfoWindow: isNotLoadedRoute,
              stat: route.stat
            }

            // const rMarker = new google.maps.Marker({
            //   position: seq.pos,
            //   label: { text: shortName, fontSize: '18px' },
            //   title: route.name,
            //   icon: {
            //     url: require('@/assets/Icon/MapIcon/route.png'),
            //     scaledSize: new google.maps.Size(50, 50), // scaled size
            //     origin: new google.maps.Point(0, 0), // origin
            //     anchor: new google.maps.Point(24, 28), // anchor
            //     // labelOrigin: new google.maps.Point(24, 32)
            //     labelOrigin: new google.maps.Point(25, 25)
            //   }
            // })
            // const routeInfo = { id: route.id, name: route.name, shortname: shortName, marker: rMarker }

            // rMarker.addListener('click', function () {
            //   EventBus.$emit('routeClick', (route.id))
            // })
            // if (this.routeSelectId === route.name) { this.select.route = routeInfo }
            // routeMarker.push(routeInfo)
            routeMarker.push(routeMarkerInfo)
          }
        })
      })
      return { marker: routeMarker, bounds: bounds.toJSON() }
    },
    getRouteIdItems: (state, getters) => {
      return getters.sortedRoute.map(x => {
        return { text: x.name, value: x.id }
      })
    },
    getRouteItems: (state, getters) => {
      const routeItemsList = []
      var noMatchPocCpt = 0
      var pocCpt = 0
      for (const route of getters.sortedRoute) {
        noMatchPocCpt = 0
        pocCpt = 0
        // for (const seq of route.sequence) {
        //   pocCpt++
        //   if (!seq.pocId) {
        //     noMatchPocCpt++
        //   }
        // }
        routeItemsList.push({ text: route.name, value: { id: route.id, name: route.name, valide: route.isValide, type: route.type, sequence: route.sequence, pocCpt: pocCpt, noMatchPocCpt: noMatchPocCpt } })
      }
      const itemsSorted = routeItemsList.sort((a, b) => {
        return a.text < b.text
      })
      // console.log(itemsSorted)
      return itemsSorted
    },
    getRouteItemOfRouteId: (state) => (routeId) => {
      const route = state.data[routeId]
      if (route) {
        return { text: route.name, value: { id: route.id, name: route.name, valide: route.isValide, type: route.type, sequence: route.sequence, pocCpt: 0, noMatchPocCpt: 0 } }
      } else {
        return null
      }
    },
    getSequenceItemsOfRouteId: (state) => (routeId) => {
      const items = []
      // const sequenceList = state.data[routeId].sequenceList
      if (state.data[routeId].sequenceList) {
        var addOfficial = true
        state.data[routeId].sequenceList.forEach((seq) => {
          if (seq.official) { addOfficial = false }
          items.push({ text: seq.name, value: { id: seq.id, name: seq.name, official: seq.official, pos: seq.pos } })
        })
        if (addOfficial) {
          items.push({ text: 'Directory officel', value: { id: state.data[routeId].sequenceList.length, name: '', official: true, pos: null } })
        }
        return items
      } else {
        return [{ text: 'Directory officel', value: { id: 0, name: null, official: true, pos: null } }]
      }
    },
    getRouteIdByName: (state, getters) => (name) => {
      if (getters.getRouteByName(name)) {
        return getters.getRouteByName(name).id
      } else {
        return null
      }
    },
    getRouteByName: (state) => (name) => {
      const result = Object.values(state.data).filter(route => route.name === name)
      if (result.length) {
        return result[0]
      } else {
        return null
      }
    }
  },
  mutations: {
    setRouteSeqPos (state, payload) {
      const sequenceList = state.data[payload.routeId].sequenceList
      const seqIndex = sequenceList.map(function (x) { return x.id }).indexOf(payload.sequenceId)
      if (sequenceList[seqIndex].pos) {
        if (sequenceList[seqIndex].pos.lat !== payload.pos.lat || sequenceList[seqIndex].pos.lng !== payload.pos.lng) {
          sequenceList[seqIndex].pos = payload.pos
          this.dispatch('routeModule/set', { id: payload.routeId, sequenceList: sequenceList })
        }
      } else {
        sequenceList[seqIndex].pos = payload.pos
        this.dispatch('routeModule/set', { id: payload.routeId, sequenceList: sequenceList })
      }
    }
  },
  actions: {
    replaceRouteSeqPos ({ commit, state }, payload) {
      commit('setRouteSeqPos', payload)
    },
    setRouteStat ({ commit, state }, payload) {
      var toUpdate = false
      const route = state.data[payload.id]
      if (!route.stat) {
        toUpdate = true
        // { app: 0, appP: 0, appV: 0, hou: 0, houP: 0, houV: 0, bus: 0, busP: 0, busV: 0, farm: 0, farmP: 0, farmV: 0 }
      } else if (route.stat.app !== payload.stat.app || route.stat.appP !== payload.stat.appP || route.stat.appV !== payload.stat.appV) {
        toUpdate = true
      } else if (route.stat.hou !== payload.stat.hou || route.stat.houP !== payload.stat.houP || route.stat.houV !== payload.stat.houV) {
        toUpdate = true
      } else if (route.stat.hou !== payload.stat.hou || route.stat.houP !== payload.stat.houP || route.stat.houV !== payload.stat.houV) {
        toUpdate = true
      } else if (route.stat.bus !== payload.stat.bus || route.stat.busP !== payload.stat.busP || route.stat.busV !== payload.stat.busV) {
        toUpdate = true
      }
      if (toUpdate) {
        this.dispatch('routeModule/set', { id: payload.id, stat: payload.stat })
      }
    }
  }
}

export default routeModule
