<template id="template" fixed>
  <v-row justify="center" id="row">
    <v-dialog max-width="444" v-model="dialog" id="dialog" transition="dialog-bottom-transition">
      <template v-slot:activator="{ on }">
        <v-btn :color="$store.getColorCode(place.color)" v-on="on" x-large icon class="mt-1 float-right"><v-icon x-large>mdi-information</v-icon></v-btn>
      </template>
      <v-card id="dialogCard" overflow-hidden>
        <v-app-bar
          absolute
          :color="$store.getColorCode(place.color)"
          dark
          shrink-on-scroll
          :src="src"
          scroll-target="#scrolling-techniques-2"
        >
          <template v-slot:img="{ props }">
            <v-img
              v-bind="props"
              :gradient="$store.getGradientByColorId(place.color, $store.getters['userLoggedModule/isDarkThemes'])"
            ></v-img>
          </template>

          <v-app-bar-nav-icon>
            <v-btn icon @click="dialog = false">
              <v-icon x-large>mdi-close</v-icon>
            </v-btn></v-app-bar-nav-icon>

          <v-toolbar-title>{{ place.fAddress }}</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn x-large icon v-if="!soloPdr">
            <v-icon x-large @click="showSearch = !showSearch">mdi-magnify</v-icon>
          </v-btn>

        </v-app-bar>
        <v-sheet
          id="scrolling-techniques-2"
          class="overflow-y-auto"
          max-height="600"
        >
          <v-container class="mt-12"></v-container>
          <v-container class="mt-6"></v-container>
          <v-container v-if="showSearch">
            <v-text-field
              class="ms-5 me-5"
              v-model="search"
              append-icon="mdi-magnify"
              :label="this.$t('pmtplacepopupinfo.search')"
              single-line
              hide-details
              clearable
              autofocus
              @blur="$store.getters.EventBus.$emit('sizeChange')"
            ></v-text-field>
          </v-container>
          <v-container v-if="soloPdr">
            <v-card class="mt-5">
              <v-row class="mx-5 text--center">
                  <p :class="$store.getColorText(pdrSearchList[0].color) + ' mt-6'" title>{{ pdrSearchList[0].name }}</p>
              </v-row>
              <v-row class="mx-5">
                <p display-4 >{{ getTypeDesc(pdrSearchList[0].type) }}</p>
                <v-spacer/>
                <p class="me-9">{{ pdrSearchList[0].vacant ? $t('pmtplacepopupinfo.vacant') : $t('pmtplacepopupinfo.occuped')  }}</p>
              </v-row>
              <v-row class="mx-5 mt-n2">
                <p class="mt-4 me-3 mb-5">{{ $t('common.pub') }} : </p>
                <v-icon color='green' v-if="pdrSearchList[0].pub">mdi-check-bold</v-icon>
                <v-icon color='red' v-if="!pdrSearchList[0].pub">mdi-cancel</v-icon>
                <v-spacer/>
                <v-btn class="me-7" @click="editPdr(pdrSearchList[0].id)" icon x-large><v-icon x-large :color="$store.getColorCode(pdrSearchList[0].color)">mdi-cog-outline</v-icon></v-btn>
              </v-row>
            </v-card>
          </v-container>
          <v-container v-if="!soloPdr">
            <v-list>
              <div id="pdrListDiv" class="mt-3">
                <v-list-group
                  v-for="item in pdrSearchList"
                  :key="item.id"
                  v-model="item.active"
                  no-action
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title :class="$store.getColorText(item.color)" v-text="item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-card>
                    <v-list-item-content>
                      <v-row class="mx-5">
                        <p display-4 >{{ getTypeDesc(item.type) }}</p>
                        <v-spacer/>
                        <p class="me-9">{{ item.vacant ? $t('pmtplacepopupinfo.vacant') : $t('pmtplacepopupinfo.occuped')  }}</p>
                      </v-row>
                      <v-row class="mx-5 mt-n2">
                        <p class="mt-4 me-3">{{ $t('common.pub') }} : </p>
                        <v-icon color='green' v-if="item.pub">mdi-check-bold</v-icon>
                        <v-icon color='red' v-if="!item.pub">mdi-cancel</v-icon>
                        <v-spacer/>
                        <v-btn class="me-7" @click="editPdr(item.id)" icon x-large><v-icon x-large :color="$store.getColorCode(item.color)">mdi-cog-outline</v-icon></v-btn>
                      </v-row>
                    </v-list-item-content>
                  </v-card>
                </v-list-group>
              </div>
            </v-list>

          </v-container>
        </v-sheet>
      </v-card>
    </v-dialog>
      <pmt-edit-pdr-popup
        v-if="showEditPdr"
        :pdrId="selectedPdrId"
        @close="showEditPdr = false"
      >
      </pmt-edit-pdr-popup>
  </v-row>
</template>

<script>
export default {
  props: ['place', 'src', 'contentstyles'],
  data () {
    return {
      showSearch: false,
      showEditPdr: false,
      soloPdr: false,
      dialog: false,
      notifications: false,
      search: ''
    }
  },
  mounted () {
    if (this.pdrList.length === 1) {
      this.soloPdr = true
    }
  },
  computed: {
    pdrList () {
      const pList = []
      for (let i = 0; i < this.place.pdrList.length; i++) {
        const pdrId = this.place.pdrList[i]
        const pdr = this.$store.state.pocModule.data[pdrId]
        pList.push({ id: pdr.id, name: pdr.pdrListAddress, pub: pdr.pub, type: pdr.type, vacant: pdr.vacant, active: false, action: 'mdi-cancel', color: pdr.color })
      }
      return pList
    },
    pdrSearchList () {
      let sList = []
      if (this.search) {
        for (let i = 0; i < this.pdrList.length; i++) {
          const pdr = this.pdrList[i]
          if (pdr.name.search(this.search) >= 0) {
            sList.push(pdr)
          } else {
            pdr.active = false
          }
        }
      } else {
        sList = this.pdrList
      }
      return sList
    }
  },
  methods: {
    getTypeDesc (type) {
      let desc = ''
      switch (type) {
        case 'Hou/Dom':
          desc = this.$t('common.dom')
          break
        case 'Apt/App':
          desc = this.$t('common.app')
          break
        case 'Bus/com':
          desc = this.$t('common.bus')
          break
        default:
          desc = this.$t('common.frm')
      }
      return desc
    },
    editPdr (pdrId) {
      this.selectedPdrId = pdrId
      this.showEditPdr = true
    }

  }
}
</script>

<style>
/*
#dialogCard {
    max-width: 100% !important;
    width: inherit !important;
    max-height: 100% !important;
    overflow: hidden !important;
    position: sticky !important;
  }
  #toolBar {
    height: 110px !important;
    position: fixed !important;
  }
  #pdrListDiv {
    top: 110px !important;
    overflow: hidden !important;
    z-index: -999 !important;
    position: relative !important;
  }
  #searchRow {
    position: fixed !important;
    top: 85px !important;
    width: 80% !important;
    padding-left: 14px !important;
  }
  #pdrList {
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
  ----------------------------------
  #pdrListDiv {
    overflow: hidden !important;
    width: 100% !important;
    max-width: 100% !important;
    margin-top: 100px !important;
    z-index: -999 !important;
    top: 100px !important;
  }

  #template {
    width: 100%! important;
  }
  #dialog {
    width: 100%! important;
    max-width: 100% !important;
    top: 100px !important;
  }

  #headerCard {
    position: sticky !important;
    width: 100%! important;
    top: 20px !important;
  }
  html, body {
    max-width: 100% !important;
    overflow-x: hidden !important;
  }
  */
</style>
