<template>
<v-container>
  <v-layout row>
    <v-flex xs12 sm6 offset-sm3>
      <v-card>
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <v-toolbar-title>{{ $t('createzone.title') }}</v-toolbar-title>
          <v-spacer />
          <v-btn @click="$i18n.locale = $t('common.otherlocale')">{{ $t('common.otherlocale') }}</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <form @submit.prevent="createzone">
              <v-layout row>
                <v-flex xs12>
                  <v-text-field
                    name="name"
                    :label="$t('createzone.name')"
                    id="name"
                    v-model="name"
                    type="text"
                    @blur="$store.dispatch('resize')"
                    required></v-text-field>
                </v-flex>
              </v-layout>
              <v-divider class="my-5"/>
              <v-layout row>
                <v-flex xs12>
                  <v-btn type="submit" color="primary">{{ $t('createzone.create') }}</v-btn>
                </v-flex>
              </v-layout>
            </form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</v-container>
</template>

<script>
export default {
  methods: {
    createzone () {
      const zonedetail = {
        name: this.name
      }
      this.$store.dispatch('zoneModule/set', zonedetail)
    }
  },
  data () {
    return {
      name: ''
    }
  }
}
</script>
