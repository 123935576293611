
<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent fixed max-width="444" transition="dialog-bottom-transition">
      <v-card class="overflow-hidden">
        <v-app-bar dark color="primary">
          <v-btn icon x-large dark @click="dialog = false">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ route.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-app-bar>
        <v-card class="overflow-y-auto overflow-x-hidden" :max-height="Math.round($store.getters.contentHeight * 0.80)">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                {{ $t('common.pdr') }} : {{ statSum.p + ' / ' + statSum.a + ' (' + statSum.v + ')' }}
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mx-n2">
                <v-card v-if="routeStat" color="yellow lighten-4 pt-5 pb-2">
                  <p align="center" class="subtitle-2 mt-n2" v-if="routeStat.hou || routeStat.houV">{{ $t('common.dom') }} : {{ routeStat.houP + '/' + routeStat.hou + '   (' + routeStat.houV + ')' }}</p>
                  <p align="center" class="subtitle-2 mt-n2" v-if="routeStat.app || routeStat.appV">{{ $t('common.app') }} : {{ routeStat.appP + '/' + routeStat.app + '   (' + routeStat.appV + ')' }}</p>
                  <p align="center" class="subtitle-2 mt-n2" v-if="routeStat.bus || routeStat.busV">{{ $t('common.bus') }} : {{ routeStat.busP + '/' + routeStat.bus + '   (' + routeStat.busV + ')' }}</p>
                  <p align="center" class="subtitle-2 mt-n2" v-if="routeStat.farm || routeStat.farmV">{{ $t('common.frm') }} : {{ routeStat.farmP + '/' + routeStat.farm + '   (' + routeStat.farmV + ')' }}</p>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                {{ $t('common.shipmethod') }} : {{ shipTypeArray.length }}
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mx-n2">
                <v-card v-if="routeStat" color="yellow lighten-4 pt-5 pb-2">
                  <p v-for="stype in shipTypeArray" :key="stype.type" align="center" class="subtitle-2 mt-n2">
                    {{ $t('common.' + stype.type) }} : {{ stype.pocIdArray.length }}
                  </p>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-card flat>
            <v-list>
              <v-list-item>
                <!-- SEQUENCE -->
                <v-list-item-avatar>
                  <v-icon :color="sequenceArray.length ? 'green' : 'red'">
                    {{ sequenceArray.length ? 'mdi-check-bold' : 'mdi-close-circle' }}
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title> Sequence : {{ sequenceArray.length }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <!-- PLACE -->
                <v-list-item-avatar>
                  <v-icon :color="placeArray.length ? 'green' : 'yellow'">
                    {{ placeArray.length ? 'mdi-check-bold' : 'mdi-close-circle' }}
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title> Place : {{ placeArray.length }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn  color="primary" @click="generatePlace" icon :loading="btnCreatePlaceLoading"><v-icon large>mdi-auto-fix</v-icon></v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <!-- POC Location -->
                <v-list-item-avatar>
                  <v-icon :color="pocNotFoundArray.length ? 'red' : 'green'">
                    {{ pocNotFoundArray.length ? 'mdi-close-circle' : 'mdi-check-bold' }}
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title> {{ $t('common.pdrnolocation') }} : {{ pocNotFoundArray.length }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action v-if="pocNotFoundArray.length">
                  <v-btn  color="primary" @click="retryServerLocattion" icon :loading="btnCorrectPocLocationLoading"><v-icon large>mdi-auto-fix</v-icon></v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>

            <v-switch
              class="ms-3"
              v-model="active"
              :disabled="!canBeActive"
              :label="active ? $t('common.active') : $t('common.inactive')"
            ></v-switch>
            <v-row no-gutters class="mb-5 mx-2">
              <v-btn v-if="$store.getters.isSupportAdmin" @click="routeDelete()">delete <v-icon>mdi-delete</v-icon></v-btn>
              <v-spacer></v-spacer>
              <v-btn v-if="$store.getters.isSupportAdmin" @click="correctPocPlace" :loading="btnCorrectPlaceLoading">correct<v-icon>mdi-pencil</v-icon></v-btn>
            </v-row>
          </v-card>
        </v-card>
      </v-card>
    </v-dialog>
    <loading-popup v-if="showLoading" :title="loadingTitle" :subTitle="loadingsubTitle"></loading-popup>
  </v-row>
</template>

<script>
export default {
  props: ['routeId'],
  data () {
    return {
      dialog: true,
      loadingTitle: this.$t('common.loading'),
      loadingsubTitle: this.$t('edit-route.loadingDesc'),
      showLoading: false,
      btnCreatePlaceLoading: false,
      btnCorrectPlaceLoading: false,
      btnCorrectPocLocationLoading: false,
      active: false
    }
  },
  computed: {
    canBeActive () {
      if (!this.route.stat) {
        return false
      } else if (!this.route.sequenceList || !this.route.sequenceList.length) {
        return false
      } else if (this.pocNotFoundArray.length) {
        return false
      } else {
        return true
      }
    },
    route () {
      return this.$store.state.routeModule.data[this.routeId]
    },
    sequenceArray () {
      if (this.route.sequenceList && this.route.sequenceList.length) {
        return this.route.sequenceList
      } else {
        return []
      }
    },
    placeArray () {
      return this.$store.getters['placeModule/getPlaceOfRoute'](this.routeId)
    },
    pocArray () {
      return this.$store.getters['pocModule/getPocOfRoute'](this.routeId)
    },
    routeStat () {
      return this.$store.getCumulStatFromPocArray(this.pocArray)
    },
    statSum () {
      return {
        v: (this.routeStat.houV + this.routeStat.appV + this.routeStat.busV + this.routeStat.farmV),
        p: (this.routeStat.houP + this.routeStat.appP + this.routeStat.busP + this.routeStat.farmP),
        a: (this.routeStat.hou + this.routeStat.app + this.routeStat.bus + this.routeStat.farm)
      }
    },
    pocNotFoundArray () {
      return this.pocArray.filter(x => !x.homeLocation)
    },
    shipTypeArray () {
      const type = []
      this.pocArray.forEach(poc => {
        const typeIndex = type.map(x => { return x.type }).indexOf(poc.tpType)
        if (typeIndex < 0) {
          type.push({ type: poc.tpType, pocIdArray: [poc.id] })
        } else {
          type[typeIndex].pocIdArray.push(poc.id)
        }
      })
      return type
    }
  },
  methods: {
    retryServerLocattion () {
      this.btnCorrectPocLocationLoading = true
      const prom = []
      this.pocNotFoundArray.forEach(poc => {
        if (!poc.homeLocation) {
          prom.push(this.$store.dispatch('pocModule/set', {
            id: poc.id,
            googleFound: false,
            googleSearch: true
          }))
        }
      })
      Promise.all(prom).then(() => {
        this.$store.dispatch('adminModule/set', {
          type: 'searchGooglePosition',
          officeId: this.$store.getters.getSelectedOfficeId
        }).then(() => {
          this.btnCorrectPocLocationLoading = false
        })
      })
    },
    positionIndexOf (myArray, pos) {
      for (var i = 0, len = myArray.length; i < len; i++) {
        if (myArray[i].position.lat === pos.lat && myArray[i].position.lng === pos.lng) return i
      }
      return -1
    },
    routeDelete () {
      this.$emit('close-delete', { pocArray: this.pocArray, placeArray: this.placeArray, routeId: this.routeId })
    },
    generatePlace () {
      const posArray = []
      this.btnCreatePlaceLoading = true
      const prom = []
      // let cptNewPlace = 0
      // let cptUpdPlace = 0

      this.pocArray.forEach(poc => {
        if (poc.homeLocation) {
          const posIndex = this.positionIndexOf(posArray, poc.homeLocation)
          if (posIndex < 0) {
            posArray.push({ position: poc.homeLocation, pocArray: [poc] })
          } else {
            posArray[posIndex].pocArray.push(poc)
          }
        }
      })
      posArray.forEach(pos => {
        if (pos.pocArray.length > 1) {
          let placeId = null
          let cptNoPlace = 0
          // let cptPlace = 0
          pos.pocArray.forEach(poc => {
            if (poc.placeId) {
              if (placeId && placeId !== poc.placeId) {
                this.$store.dispatch('addToLog', 'error many differente place pour meme position pocId => ' + poc.id)
              } else {
                placeId = poc.placeId
              }
            } else {
              cptNoPlace++
            }
          })
          if (cptNoPlace) {
            if (placeId) {
              // cptUpdPlace++
              prom.push(this.$store.dispatch('placeModule/addPocToPlace', {
                placeId: placeId,
                pocIdArray: pos.pocArray.map(x => { return x.id })
              }))
            } else {
              // cptNewPlace++
              const pdr = pos.pocArray[0]
              prom.push(this.$store.dispatch('placeModule/createPlace', {
                sName: this.getCleanDoor(pdr.pdrListAddress),
                lName: pdr.pdrListAddress,
                homeLocation: pdr.homeLocation,
                ggPlaceId: pdr.place_id,
                pocList: pos.pocArray.map(x => { return x.id }),
                imageList: pdr.imageList ? pdr.imageList : []
              }))
            }
          }
        }
      })
      Promise.all(prom).then(() => {
        this.btnCreatePlaceLoading = false
      })
      // // console.log('new place = ' + cptNewPlace)
      // // console.log('update place' + cptUpdPlace)
    },
    getCleanDoor (address) {
      const door = address.split(' ')[0]
      if (door.split('-').length > 1) {
        return Number(door.split('-')[1].match(/\d+/g).map(Number))
      } else {
        return Number(door.match(/\d+/g).map(Number))
      }
    },
    correctPocPlace () {
      this.btnCorrectPlaceLoading = true
      const prom = []
      this.placeArray.forEach(place => {
        place.pocList.forEach(pocId => {
          const poc = this.$store.state.pocModule.data[pocId]
          if (poc && !poc.placeId) {
            prom.push(this.$store.dispatch('pocModule/set', {
              id: pocId,
              placeId: place.id
            }))
          }
        })
      })
      Promise.all(prom).then(() => {
        this.btnCorrectPlaceLoading = false
        // // console.log('trace correct finish')
      })
    }
  },
  watch: {
    dialog (newValue) {
      this.dialog = false
      this.$store.dispatch('resize')
      this.$emit('close')
    },
    active (newValue) {
      if (newValue !== this.route.isActive) {
        this.$store.dispatch('routeModule/set', {
          id: this.routeId,
          position: this.$store.getPosAverageOfPocArray(this.$store.getters['pocModule/getPocOfRoute'](this.routeId)),
          isActive: newValue
        })
      }
    }
  },
  created () {
    this.showLoading = true
    const prom = []
    prom.push(this.$store.dispatch('placeModule/openDBChannel', { pathVariables: { officeId: this.$store.getters.getSelectedOfficeId }, clauses: { where: [['route', 'array-contains', this.routeId]] } }))
    prom.push(this.$store.dispatch('pocModule/openDBChannel', { pathVariables: { officeId: this.$store.getters.getSelectedOfficeId }, clauses: { where: [['route', 'array-contains', this.routeId]] } }))
    Promise.all(prom).then(() => {
      this.$store.dispatch('routeModule/setRouteStat', { id: this.route.id, stat: this.routeStat })
      if (this.route.sequenceList) {
        this.route.sequenceList.forEach(seq => {
          if (!seq.pos || !seq.pos.lat || !seq.pos.lng) {
            const pocSeqArray = this.$store.getters['pocModule/getPocOfSequence'](this.routeId, seq.id)
            const routePos = this.$store.getPosAverageOfPocArray(pocSeqArray)
            this.$store.dispatch('routeModule/replaceRouteSeqPos', { routeId: this.routeId, sequenceId: seq.id, pos: routePos })
          }
        })
      }
      this.active = this.route.isActive
      this.showLoading = false
    })
  },
  destroyed () {
    this.$store.dispatch('pocModule/closeDBChannel')
  }
}
</script>
<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
