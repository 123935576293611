<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="444" fixed transition="fab-transition">
      <v-card>
        <v-container
          max-height="500"
          class="overflow-x-hidden overflow-y-auto"
        >
          <v-data-iterator
            ref="dataIterator"
            :expanded.sync="selectedList"
            hide-default-footer
            :single-expand="solo ? true : false"
            :items="list"
          >
            <template v-slot:header>
              <v-app-bar dark color="primary">
                <v-spacer></v-spacer>
                <v-toolbar-title>{{ $t('select-popup.title-' + mode) }}</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-app-bar>
              <v-row no-gutters>
                <v-spacer></v-spacer>
                <v-btn v-show="selectedList.length" @click="selectedList = []">{{ $t('common.none') }}</v-btn>
                <v-btn @click="selectedList = list">{{ $t('common.all') }}</v-btn>
              </v-row>
            </template>
            <template v-slot:default="{ items, isExpanded, expand }">
              <v-list>
                <!-- <v-row v-if="!solo">
                  <v-card-subtitle> {{ pdrSelectedList.length }} {{ $t('common.selected') }}</v-card-subtitle>
                </v-row> -->
                <v-list-item
                  v-for="item in items"
                  :key="item.id"
                  :class="isExpanded(item) ? 'elevation-1' : ''"
                  @click="(v) => expand(item, !isExpanded(item))"
                >
                  <v-list-item-content v-if="mode === 'group'">
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content v-else-if="mode === 'perso'" two-line>
                    <v-list-item-title v-text="$store.state.userModule.data[item.userId].empid"></v-list-item-title>
                    <v-list-item-title v-text="$store.state.userModule.data[item.userId].lname + ', ' + $store.state.userModule.data[item.userId].fname"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content v-else-if="mode === 'route'" two-line>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                    <v-list-item-subtitle v-text="$t('routetype.' + item.type)"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action><v-checkbox :input-value="isExpanded(item)"></v-checkbox></v-list-item-action>
                </v-list-item>
              </v-list>
            </template>
            <template v-slot:footer>
              <v-app-bar bottom dark color="primary">
                <v-spacer></v-spacer>
                <v-btn icon x-large dark @click="close()">
                  <v-icon color="green" x-large>mdi-check</v-icon>
                </v-btn>
              </v-app-bar>
            </template>
          </v-data-iterator>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ['list', 'preselect', 'solo', 'mode'],
  data () {
    return {
      dialog: true,
      selectedList: this.preselect ? this.preselect : []
    }
  },
  methods: {
    close () {
      this.dialog = false
      this.$emit('return-select', this.selectedList)
    }
  }
}
</script>
