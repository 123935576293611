<template>
  <v-row justify-center>
    <v-dialog v-model="dialog" persistent fixed max-width="444" transition="scale-transition">
      <v-card >
        <v-app-bar dark color="primary">
          <v-btn icon dark @click="$emit('close')" x-large class="lighten-4">
            <v-icon x-large class="lighten-4">mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ $t('pmtmenuadminlocal.submenuimporttitle') }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-app-bar>
        <v-container
          fixedclass="overflow-y-auto overflow-x-hidden"
        >
          <v-list nav>
            <v-row>
              <v-col cols="6">
                <v-list-item link>
                <v-list-item-content class="mx-auto" @click="selectitemTemp('pmt-import-poclist')" clearfix>
                    <v-icon :size="$store.getters.iconSize" color="primary"> mdi-file-import
                    </v-icon>
                    <p two-line class="subtitle text-center">{{ $t('pmtmenuadminlocal.importpoclist') }}</p>
                </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="6">
                <v-list-item link>
                <v-list-item-content class="mx-auto" @click="selectitemTemp('pmt-import-sequence')" clearfix>
                    <v-icon :size="$store.getters.iconSize" color="primary"> mdi-file-import
                    </v-icon>
                    <p two-line class="subtitle text-center">{{ $t('pmtmenuadminlocal.importsequence') }}</p>
                </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="6">
                <v-list-item link>
                <v-list-item-content class="mx-auto" @click="selectitemTemp('pmt-import-harmonize')" clearfix>
                    <v-icon :size="$store.getters.iconSize" color="primary"> mdi-file-import
                    </v-icon>
                    <p two-line class="subtitle text-center">{{ $t('pmtmenuadminlocal.importharmonize') }}</p>
                </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="6">
                <v-list-item link>
                <v-list-item-content class="mx-auto" @click="selectitemTemp('pmt-import-bpcom')" clearfix>
                    <v-icon :size="$store.getters.iconSize" color="primary"> mdi-file-import
                    </v-icon>
                    <p two-line class="subtitle text-center">{{ $t('pmtmenuadminlocal.importbpcom') }}</p>
                </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-list>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ['contentstyles'],
  watch: {
    // dialog (newValue) {
    //   if (!newValue) {
    //     this.$store.getters.EventBus.$emit('selectLocalAdminComponent', ('pmt-menu-admin-local'))
    //   }
    // }
  },
  methods: {
    selectitem (choice) {
      this.$emit('close')
      this.$store.getters.EventBus.$emit('selectLocalAdminComponent', (choice))
    },
    selectitemTemp (choice) {
      this.$emit('close')
      this.$store.getters.EventBus.$emit('selectLocalAdminComponentTemp', (choice))
    }
  },
  data () {
    return {
      dialog: true
    }
  },
  mounted () {
    this.dialog = true
  }
}
</script>
