<template fixed>
  <div class="home">
    <gmaps-controler
      :mapMode="mapMode"
      :markerData="markerData"
      :officeItemMarker="officeItemMarker"
      :lineMarkerData="lineMarkerData"
      :zoneMarkerData="colorZoneMarkerData"
      :markerClustererData="markerClustererData"
      :params="params"
      @doTask="doTask($event)"
    ></gmaps-controler>
    <confirm-popup
      v-if="showConfirmRouteChange"
      @cancel="changeRouteCancel()"
      @confirm="changeRoute($event)"
      :title="confirmTitle"
      :from="confirmFrom"
      :to="confirmTo"
      :item="confirmNewId"
    ></confirm-popup>
    <!-- <pmt-loading-local :loadingTitle="showLoadingTitle" :loadingShow="dataReady"></pmt-loading-local> -->
  </div>
</template>

<script>
import gmapsControler from './GMaps_Controler.vue'
export default {
  components: { gmapsControler },
  props: [],
  data () {
    return {
      temp: false,
      filterStreet: null,
      filterCodeId: null,
      filterColorId: 1,
      filterCp: null,
      postManMarker: {
        type: 'postManMarker',
        isOpenInfoWindow: true,
        marker: this.$store.getGoogleMarker(this.$store.getters.getGoogle, {
          type: 'postManMarker',
          label: ' ',
          position: this.$store.getters.getCurrentPosition
        })
      },
      showConfirmRouteChange: false,
      confirmTitle: null,
      confirmFrom: null,
      confirmTo: null,
      confirmNewId: null,
      // showLoading: false,
      showLoadingTitle: null
    }
  },
  methods: {
    doTask (task) {
    //  // console.log('trace dotask = ' + task.taskType)
      switch (task.taskType) {
        case 'loadRoute':
          this.confirmTitle = this.$t('common.changerouteconfirm')
          this.confirmFrom = this.$store.getters.getSelectedRouteId ? this.$store.state.routeModule.data[this.$store.getters.getSelectedRouteId].name : null
          this.confirmTo = task.routeName
          this.confirmNewId = task.routeId
          this.showConfirmRouteChange = true
          // // console.log('trace loadRoute id = ' + task.routeId)
          // this.loadRoute(task)
          break
        case 'trackMeClicked':
          this.$store.dispatch('userLoggedModule/updateMapMode', 'track-me')
          this.$store.dispatch('refreshCurrentPosition')
          break
        case 'directionClicked':
          this.$store.dispatch('userLoggedModule/updateMapMode', 'direction')
          break
        case 'roadBookClicked':
        //  // console.log('trace set mode road-book')
          this.$store.dispatch('userLoggedModule/updateMapMode', 'road-book')
          break
        case 'routeInfoClicked':
          this.$store.dispatch('userLoggedModule/updateMapMode', 'route-info')
          break
        case 'levelUpClicked':
          this.levelUpClicked()
          break
        case 'markerClick':
          this.markerClick(task.markerData)
          break
        default:
          // // console.log('task a faire')
          // // console.log(task)
          break
      }
    },
    markerClick (marker) {
      switch (this.mapMode) {
        case 'route-info':
          switch (marker.type) {
            case 'pocMarker':
              this.$store.dispatch('userLoggedModule/updateSelectionPdr', marker.pocId)
              break
            case 'placeMarker':
              this.$store.dispatch('userLoggedModule/updateSelectionPlace', marker.placeId)
              break
            case 'cpMarker':
              this.$store.dispatch('userLoggedModule/updateSelectionCp', marker.cp)
              break
            case 'codeMarker':
              this.$store.dispatch('userLoggedModule/updateSelectionCode', marker.codeId)
              break
            case 'colorMarker':
              this.$store.dispatch('userLoggedModule/updateSelectionColor', marker.colorId)
              break
            case 'streetMarker':
              this.$store.dispatch('userLoggedModule/updateSelectionStreet', marker.street)
              break
            case 'routeMarker':
              this.$store.dispatch('userLoggedModule/updateSelectionRoute', marker.routeId)
              break
            case 'officeMarker':
              this.$store.dispatch('userLoggedModule/updateSelectionOffice', marker.officeId)
              break
            case 'zoneMarker':
              this.$store.dispatch('userLoggedModule/updateSelectionZone', marker.zoneId)
              break
            default:
              // // console.log('trace a gerer')
              // // console.log(marker)
              break
          }
          break
        case 'track-me':
          switch (marker.type) {
            case 'pocMarker':
              // // console.log('trace pocMarker clicked')
              break
            case 'placeMarker':
              // // console.log('trace placeMarker clicked')
              break
            default:
              break
          }
          break
        case 'direction':
          switch (marker.type) {
            case 'pocMarker':
              this.$store.commit('userLoggedModule/setTravelSelectedNavInfo', {
                type: 'pocDetail',
                pocId: marker.pocId
              })
              break
            case 'wpMarker':
              this.$store.commit('userLoggedModule/setTravelSelectedNavInfo', {
                type: 'wpDetail',
                wpId: marker.wpId,
                wpType: marker.wpType
              })
              break
            case 'loopMarker':
              this.$store.commit('userLoggedModule/setTravelSelectedNavInfo', {
                type: 'loopDetail',
                stepId: marker.stepId
              })
              break
            case 'placeMarker':
              // // console.log('trace placeMarker clicked')
              break
            default:
              break
          }
          break
        case 'road-book':
          switch (marker.type) {
            case 'pocMarker':
              this.$store.commit('userLoggedModule/setTravelSelectedNavInfo', {
                type: 'pocDetail',
                pocId: marker.pocId
              })
              break
            case 'placeMarker':
              this.$store.commit('userLoggedModule/setTravelSelectedNavInfo', {
                type: 'placeDetail',
                placeId: marker.placeId
              })
              break
            case 'wpMarker':
              this.$store.commit('userLoggedModule/setTravelSelectedNavInfo', {
                type: 'wpDetail',
                wpId: marker.wpId,
                wpType: marker.wpType
              })
              break
            case 'loopMarker':
              this.$store.commit('userLoggedModule/setTravelSelectedNavInfo', {
                type: 'loopDetail',
                stepId: marker.stepId
              })
              break
            default:
            //  // console.log(marker)
              break
          }
          break
        default:
          break
      }
    },
    getGroupBy () {
      if (this.routeSelectId) {
        if (this.$store.state.routeModule.data[this.routeSelectId].type === 1) {
          return this.$store.getters['userLoggedModule/getRouteInfoSttpGroupBy']
        } else {
          return this.$store.getters['userLoggedModule/getRouteInfoFfrsGroupBy']
        }
      } else {
        return ''
      }
    },
    levelUpClicked () {
      const groupBy = this.getGroupBy()
      switch (this.mapMode) {
        case 'route-info':
          if (this.pdrSelectId) {
            this.$store.dispatch('userLoggedModule/updateSelectionPdr', (null))
          } else if (this.placeSelectId) {
            this.$store.dispatch('userLoggedModule/updateSelectionPlace', (null))
          } else if (this.cpSelectId && groupBy.search(/cp/i) > -1) {
            this.$store.dispatch('userLoggedModule/updateSelectionCp', (null))
          } else if (this.streetSelectId && groupBy.search(/street/i) > -1) {
            this.$store.dispatch('userLoggedModule/updateSelectionStreet', (null))
          } else if (this.codeSelectId && groupBy.search(/code/i) > -1) {
            this.$store.dispatch('userLoggedModule/updateSelectionCode', (null))
          } else if (this.colorSelectId && groupBy.search(/color/i) > -1) {
            this.$store.dispatch('userLoggedModule/updateSelectionColor', (null))
          } else if (this.routeSelectId) {
            this.$store.dispatch('userLoggedModule/updateSelectionRoute', (null))
          } else if (this.officeSelectId) {
            this.$store.dispatch('userLoggedModule/updateSelectionOffice', (null))
          } else if (this.zoneSelectId) {
            this.$store.dispatch('userLoggedModule/updateSelectionZone', (null))
          }
          break
        case 'direction':
          switch (this.navInfoType) {
            case 'pocDetail':
              this.$store.commit('userLoggedModule/setTravelSelectedNavInfo', {
                type: 'loopDetail',
                stepId: this.navInfo.stepId
              })
              break
            case 'placeDetail':
              this.$store.commit('userLoggedModule/setTravelSelectedNavInfo', {
                type: 'loopDetail',
                stepId: this.navInfo.stepId
              })
              break
            case 'loopDetail':
              this.$store.commit('userLoggedModule/setTravelSelectedNavInfo', null)
              break
            default:
              this.$store.commit('userLoggedModule/setTravelSelectedNavInfo', null)
              break
          }
          break
        case 'road-book':
          switch (this.navInfoType) {
            case 'pocDetail':
              this.$store.commit('userLoggedModule/setTravelSelectedNavInfo', {
                type: 'loopDetail',
                stepId: this.navInfo.stepId
              })
              break
            case 'placeDetail':
              this.$store.commit('userLoggedModule/setTravelSelectedNavInfo', {
                type: 'loopDetail',
                stepId: this.navInfo.stepId
              })
              break
            case 'loopDetail':
              this.$store.commit('userLoggedModule/setTravelSelectedNavInfo', null)
              break
            default:
              this.$store.commit('userLoggedModule/setTravelSelectedNavInfo', null)
              break
          }
          break
        default:
          break
      }
    },
    setGroupMarker () {
      // const cpArray = []
      // const codeArray = []
      // const colorArray = []
    },
    changeRouteCancel () {
      this.showConfirmRouteChange = false
    },
    changeRoute (id) {
      this.$store.commit('userLoggedModule/setTravelSelected', null)
      this.showConfirmRouteChange = false
      this.showLoadingTitle = this.$t('common.loading')
      // this.showLoading = true
      this.$store.dispatch('postmanChangeRoute', id).then(() => {
        const userSelection = {
          zone: this.$store.getters.getSelectedZoneId,
          office: this.officeSelectedId,
          route: id,
          color: null,
          code: null,
          street: null,
          cp: null,
          place: null,
          pdr: null,
          travelselected: null
        }
        // this.$store.dispatch('userLoggedModule/updateSelectionField', userSelection).then(() => {
        //   this.showLoading = false
        // })
        this.$store.dispatch('userLoggedModule/updateSelectionField', userSelection)
      })
    },
    getMarkerShow () {
      const markerShow = this.$store.getters['userLoggedModule/getMarkerShow']
      switch (markerShow.type) {
        case 'toilet':
          return this.$store.getters['officeItemModule/getToiletMarker'].filter(x => x.id === markerShow.id)
        default:
          return []
      }
    }
  },
  mounted () {
    // // console.log(this.$store.getters['officeTravelModule/getTravelMapDetailSelected'])
    // // console.log(this.$store.getters.getMarkerOfRoute(this.$store.getters.getUserSelection.route))
  },
  computed: {
    roadBook () {
      if (this.dataReady) {
        return this.$store.getters['officeTravelModule/getRoadBook']
      } else {
        return []
      }
    },
    dataReady () {
      return this.$store.getters.postmanDataLoadCompleteReady
    },
    params () {
      switch (this.mapMode) {
        case 'road-book':
          if (this.dataReady) {
            return {
              pocItems: this.travelStatusNextStepItems.filter(x => x.type === 'poc'),
              stepId: this.travelStatusNextStepId,
              stepType: this.travelStatusNextStep ? this.travelStatusNextStep.type : null,
              steps: this.travelDetail.steps
            }
          } else {
            return null
          }
        default:
          return null
      }
    },
    travelStatusNextStepPos () {
      // console.log(this.$store.getters['routeDayDataModule/getMyTravelSelectedNextStep'])
      if (this.dataReady) {
        return this.$store.getters['routeDayDataModule/getMyTravelSelectedNextStep']
      } else {
        return null
      }
    },
    travelStatusNextStepIdx () {
      if (this.dataReady && this.roadBook.length && this.travelStatusNextStepPos) {
        return this.roadBook.map(x => { return x.roadBookPos }).indexOf(this.travelStatusNextStepPos)
      } else {
        return -1
      }
    },
    travelStatusNextStepId () {
      if (this.dataReady && this.travelStatusNextStepIdx > -1 && this.roadBook[this.travelStatusNextStepIdx] && this.roadBook[this.travelStatusNextStepIdx].stepId) {
        return this.roadBook[this.travelStatusNextStepIdx].stepId
      } else {
        return null
      }
    },
    travelStatusNextStep () {
      if (this.dataReady && this.travelStatusNextStepIdx > -1) {
        return this.roadBook[this.travelStatusNextStepIdx]
      } else {
        return null
      }
    },
    travelStatusNextStepItems () {
      if (this.dataReady && this.travelDetail && this.travelDetail.items && this.travelStatusNextStepId) {
        return this.travelDetail.items.filter(x => x.stepId === this.travelStatusNextStepId)
      } else {
        return []
      }
    },
    travelSelectedStepDone () {
      return this.$store.getters['routeDayDataModule/getMyTravelSelectedStepDone']
    },
    travelDetail () {
      if (this.$store.getters['officeTravelModule/getTravelMapDetailSelected']) {
        return this.$store.getters['officeTravelModule/getTravelMapDetailSelected']
      } else {
        return {}
      }
    },
    mapInfoParam () { return this.$store.getters['userLoggedModule/getMapInfoParam'] },
    currentPosition () { return this.$store.getters.getCurrentPosition },
    showBpCom () { return this.mapInfoParam.showBpCom },
    showCodeLine () { return this.mapInfoParam.showCodeLine },
    showColorZone () { return this.mapInfoParam.showColorZone },
    showDog () { return this.mapInfoParam.showDog },
    showGreyBox () { return this.mapInfoParam.showGreyBox },
    showMe () { return this.mapInfoParam.showMe },
    showParking () { return this.mapInfoParam.showParking },
    showPocMarker () { return this.mapInfoParam.showPocMarker },
    showRedBox () { return this.mapInfoParam.showRedBox },
    showParcelLocker () { return this.mapInfoParam.showParcelLocker },
    showToilet () { return this.mapInfoParam.showToilet },
    navInfo () { return this.$store.getters['userLoggedModule/getTravelSelectedNavInfo'] },
    navInfoType () { return this.$store.getters['userLoggedModule/getTravelSelectedNavInfoType'] },
    markerData () {
      var step
      var mArray
      if (!this.dataReady) { return { markerList: [] } }
      switch (this.mapMode) {
        case 'route-info':
          if (this.showPocMarker && this.dataReady) {
            // // console.log('trace 1')
            // // console.log(this.routeInfoMarkerData)
            return this.routeInfoMarkerData
          } else {
            return { markerList: [] }
          }
        case 'track-me':
          // if (this.routeSelectId) {
          //   return { markerList: this.markerOfRoute.placeMarker, bounds: this.markerOfRoute.routeBounds }
          // } else {
          //   return { markerList: [] }
          // }
          return { markerList: [] }
        case 'direction':
          if (this.dataReady) {
            switch (this.navInfoType) {
              case 'wpDetail':
                return { markerList: this.travelDetail.items ? this.travelDetail.items.filter(x => x.wpId === this.navInfo.wpId).map(x => { return x.markerInfo }) : [] }
              case 'pocDetail':
                return { markerList: this.$store.getters['pocModule/getPocDetailMarkerData'](this.routeSelectId, this.navInfo.pocId), bounds: null }
              case 'loopDetail':
                if (this.travelDetail.steps) {
                  step = this.travelDetail.steps[this.travelDetail.steps.map(x => { return x.stepId }).indexOf(this.navInfo.stepId)]
                  mArray = this.travelDetail.items.filter(x => x.stepId === this.navInfo.stepId).map(x => { return x.markerInfo })
                  return { markerList: [step.stepMarker].concat(mArray), bounds: step.stepMarker.bounds }
                } else {
                  return { markerList: [] }
                }
              case null:
                return { markerList: this.travelDetail.steps ? this.travelDetail.steps.map(x => { return x.stepMarker }) : [] }
              default:
                return { markerList: [] }
            }
          } else {
            return { markerList: [] }
          }
        case 'road-book':
          // console.log('trace 1 ->' + this.travelStatusNextStep)
          if (this.dataReady && this.travelStatusNextStep) {
            // console.log('trace 2')
            switch (this.navInfoType) {
              case 'pocDetail':
                return { markerList: this.$store.getters['pocModule/getPocDetailMarkerData'](this.routeSelectId, this.navInfo.pocId), bounds: null }
              case 'placeDetail':
                return { markerList: this.$store.getters['pocModule/getPlaceDetailMarkerData'](this.routeSelectId, this.navInfo.placeId), bounds: null }
              case 'wpDetail':
                return { markerList: this.travelDetail.items ? this.travelDetail.items.filter(x => x.wpId === this.navInfo.wpId).map(x => { return x.markerInfo }) : [] }
              case 'loopDetail':
                // if (this.travelDetail.steps) {
                //   step = this.travelDetail.steps[this.travelDetail.steps.map(x => { return x.stepId }).indexOf(this.navInfo.stepId)]
                //   mArray = this.travelDetail.items.filter(x => x.stepId === this.navInfo.stepId).map(x => { return x.markerInfo })
                //   return { markerList: [step.stepMarker].concat(mArray), bounds: step.stepMarker.bounds }
                // } else {
                //   return { markerList: [] }
                // }
                // case 'placeOfCode':
                return {
                  markerList: this.markerOfRoute.placeMarker.filter(x => {
                    return x.stepId === this.navInfo.stepId || (x.stepArray && x.stepArray.indexOf(this.navInfo.stepId) > -1)
                  }),
                  bounds: null
                }
              case null:
                // console.log(this.travelStatusNextStep)
                if (this.travelStatusNextStep.stepMarker) {
                  if (this.travelStatusNextStep.stepMarker.markerArray) {
                    // console.log(this.travelStatusNextStep.stepMarker.markerArray)
                    return { markerList: this.travelStatusNextStep.stepMarker.markerArray, bounds: this.travelStatusNextStep.stepMarker.bounds }
                  } else {
                    return { markerList: [this.travelStatusNextStep.stepMarker], bounds: this.travelStatusNextStep.stepMarker.bounds }
                  }
                } else {
                  return { markerList: [] }
                }
                // return { markerList: this.travelDetail.steps ? this.travelDetail.steps.map(x => { return x.stepMarker }) : [] }
              default:
                return { markerList: [] }
            }
          } else {
            return { markerList: [] }
          }
        case 'marker-show':
          return { markerList: this.getMarkerShow }
        default:
          return { markerList: [] }
      }
    },
    travelStepFromToMarker () {
      const ftArray = []
      var stepIdx
      // // console.log(this.navInfoType)
      // // console.log(this.mapMode)
      if (!this.dataReady) { return [] }
      switch (this.mapMode) {
        case 'road-book':
          switch (this.navInfoType) {
            case 'wpDetail':
              return []
            default:
              if (this.travelDetail && this.travelDetail.steps && this.travelStatusNextStepId) {
                ftArray.push(this.travelDetail.steps[this.travelStatusNextStepIdx].fromItemId)
                ftArray.push(this.travelDetail.steps[this.travelStatusNextStepIdx].toItemId)
              }
              return this.travelDetail.items ? this.travelDetail.items.filter(x => ftArray.indexOf(x.id) > -1).map(x => { return x.markerInfo }) : []
          }
        case 'direction':
          switch (this.navInfoType) {
            case 'wpDetail':
              return { markerList: this.travelDetail.items ? this.travelDetail.items.filter(x => x.wpId === this.navInfo.wpId).map(x => { return x.markerInfo }) : [] }
            case 'loopDetail':
              if (this.travelDetail && this.travelDetail.steps) {
                stepIdx = this.travelDetail.steps.map(x => { return x.stepId }).indexOf(this.navInfo.stepId)
                // // console.log('index = ' + stepIdx)
                if (stepIdx > -1) {
                  ftArray.push(this.travelDetail.steps[stepIdx].fromItemId)
                  ftArray.push(this.travelDetail.steps[stepIdx].toItemId)
                }
              }
              // // console.log(ftArray)
              return this.travelDetail.items ? this.travelDetail.items.filter(x => ftArray.indexOf(x.id) > -1).map(x => { return x.markerInfo }) : []
            case null:
              if (this.travelDetail && this.travelDetail.steps) {
                this.travelDetail.steps.forEach(step => {
                  if (ftArray.indexOf(step.fromItemId) < 0) { ftArray.push(step.fromItemId) }
                  if (ftArray.indexOf(step.toItemId) < 0) { ftArray.push(step.toItemId) }
                })
              }
              return this.travelDetail.items ? this.travelDetail.items.filter(x => ftArray.indexOf(x.id) > -1).map(x => { return x.markerInfo }) : []
            default:
              return []
          }
        default:
          return []
      }
    },
    markerClustererData () {
      switch (this.mapMode) {
        case 'track-me':
          if (this.routeSelectId && this.dataReady) {
            return {
              // placeMarker: this.markerOfRoute.placeMarker.map(x => {
              //   x.marker.setVisible(true)
              //   return x.marker
              // }),
              placeMarker: this.markerOfRoute.placeMarker,
              redBoxMarker: this.redBoxMarker
            }
          } else {
            return null
          }
        case 'direction':
          return null
        case 'road-book':
          return null
        case 'marker-show':
          return null
        default:
          return null
      }
    },
    meMarkerData () {
      if (this.showMe && this.postManMarker.marker) {
        if (this.showMe || this.mapMode === 'track-me') {
          return [this.postManMarker]
        } else { return [] }
      } else { return [] }
    },
    colisMarkerData () {
      // console.log(this.$store.getters['routeDayDataModule/getColisOfday'])
      return this.$store.getters['routeDayDataModule/getColisOfday']
    },
    routeInfoMarkerData () {
      switch (this.infoShowLevel) {
        case 'pocDetail':
          return { markerList: this.$store.getters['pocModule/getPocDetailMarkerData'](this.routeSelectId, this.pdrSelectId), bounds: null }
        case 'placeDetail':
          return { markerList: this.$store.getters['pocModule/getPlaceDetailMarkerData'](this.routeSelectId, this.placeSelectId), bounds: null }
        case 'placeOfStreet':
          return {
            markerList: this.markerOfRoute.placeMarker.filter(x => {
              return x.street === this.streetSelectId || (x.streetArray && x.streetArray.indexOf(this.streetSelectId) > -1)
            }),
            bounds: null
          }
        case 'placeOfColor':
          return {
            markerList: this.markerOfRoute.placeMarker.filter(x => {
              return x.colorId === this.colorSelectId || (x.colorArray && x.colorArray.indexOf(this.colorSelectId) > -1)
            }),
            bounds: null
          }
        case 'placeOfCode':
          return {
            markerList: this.markerOfRoute.placeMarker.filter(x => {
              return x.codeId === this.codeSelectId || (x.codeArray && x.codeArray.map(x => { return x.codeId }).indexOf(this.codeSelectId) > -1)
            }),
            bounds: null
          }
        case 'placeOfCp':
          return {
            markerList: this.markerOfRoute.placeMarker.filter(x => {
              return x.cp === this.cpSelectId || (x.cpArray && x.cpArray.indexOf(this.cpSelectId) > -1)
            }),
            bounds: null
          }
        case 'placeOfCodeOfColor':
          return {
            markerList: this.markerOfRoute.placeMarker.filter(x => {
              return x.colorId === this.colorSelectId || (x.colorArray && x.colorArray.indexOf(this.colorSelectId) > -1)
            }).filter(x => {
              return x.codeId === this.codeSelectId || (x.codeArray && x.codeArray.map(x => { return x.codeId }).indexOf(this.codeSelectId) > -1)
            }),
            bounds: null
          }
        case 'placeOfStreetOfColor':
          return {
            markerList: this.markerOfRoute.placeMarker.filter(x => {
              return x.colorId === this.colorSelectId || (x.colorArray && x.colorArray.indexOf(this.colorSelectId) > -1)
            }).filter(x => {
              return x.street === this.streetSelectId || (x.streetArray && x.streetArray.indexOf(this.streetSelectId) > -1)
            }),
            bounds: null
          }
        case 'placeOfCpOfColor':
          return {
            markerList: this.markerOfRoute.placeMarker.filter(x => {
              return x.colorId === this.colorSelectId || (x.colorArray && x.colorArray.indexOf(this.colorSelectId) > -1)
            }).filter(x => {
              return x.cp === this.cpSelectId || (x.cpArray && x.cpArray.indexOf(this.cpSelectId) > -1)
            }),
            bounds: null
          }
        case 'placeOfCpOfStreet':
          return {
            markerList: this.markerOfRoute.placeMarker.filter(x => {
              return x.street === this.streetSelectId || (x.streetArray && x.streetArray.indexOf(this.streetSelectId) > -1)
            }).filter(x => {
              return x.cp === this.cpSelectId || (x.cpArray && x.cpArray.indexOf(this.cpSelectId) > -1)
            }),
            bounds: null
          }
        case 'placeOfRoute':
          return { markerList: this.markerOfRoute.placeMarker, bounds: this.markerOfRoute.routeBounds }
        case 'colorOfRoute':
          return { markerList: this.markerOfRoute.colorMarker, bounds: this.markerOfRoute.routeBounds }
        case 'codeOfRoute':
          return { markerList: this.markerOfRoute.codeMarker, bounds: this.markerOfRoute.routeBounds }
        case 'codeOfColor':
          return {
            markerList: this.markerOfRoute.codeMarker.filter(x => {
              return x.colorId === this.colorSelectId || (x.colorArray && x.colorArray.indexOf(this.colorSelectId) > -1)
            }),
            bounds: null
          }
        case 'streetOfRoute':
          return { markerList: this.markerOfRoute.streetMarker, bounds: this.markerOfRoute.routeBounds }
        case 'streetOfColor':
          return {
            markerList: this.markerOfRoute.streetMarker.filter(x => {
              return x.colorId === this.colorSelectId || (x.colorArray && x.colorArray.indexOf(this.colorSelectId) > -1)
            }),
            bounds: null
          }
        case 'cpOfStreet':
          // return {
          //   markerList: this.markerOfRoute.cpMarker.filter(x => x.streetArray.indexOf(this.streetSelectId) > -1),
          //   bounds: null
          // }
          return {
            markerList: this.markerOfRoute.cpMarker,
            bounds: null
          }
        case 'cpOfRoute':
          return { markerList: this.markerOfRoute.cpMarker, bounds: this.markerOfRoute.routeBounds }
        case 'cpOfColor':
          return {
            markerList: this.markerOfRoute.cpMarker.filter(x => {
              return x.colorId === this.colorSelectId || (x.colorArray && x.colorArray.indexOf(this.colorSelectId) > -1)
            }),
            bounds: null
          }
        case 'officeOfZone':
          return this.officeMarkerData
        case 'zone':
          return this.zoneMarkerData
        case 'routeOfOffice':
          return this.routeMarkerData
        default:
          return { markerList: [], bounds: null }
      }
    },
    lineMarkerData () {
      if (!this.dataReady) { return [] }
      if (this.mapMode === 'route-info' && this.routeSelectId && this.showCodeLine) {
        switch (this.infoShowLevel) {
          case 'placeOfCode':
            return this.markerOfRoute.codeLineMarker.filter(x => x.codeId === this.codeSelectId)
          case 'codeOfColor':
            return this.markerOfRoute.codeLineMarker.filter(x => x.colorId === this.colorSelectId)
          default:
            return this.markerOfRoute.codeLineMarker
        }
      } else if (this.mapMode === 'direction') {
        let lineArray = []
        switch (this.navInfoType) {
          case 'wpDetail':
            return []
          case 'loopDetail':
            return this.travelDetail.steps ? this.travelDetail.steps[this.travelDetail.steps.map(x => { return x.stepId }).indexOf(this.navInfo.stepId)].stepMarker.lineMarker : []
          case null:
            if (this.travelDetail.steps && this.travelDetail.steps.length) {
              for (let i = 0; i < this.travelDetail.steps.length; i++) {
                lineArray = lineArray.concat(this.travelDetail.steps[i].stepMarker.lineMarker)
              }
            }
            return lineArray
          default:
            return []
        }
      } else if (this.mapMode === 'road-book') {
        if (this.travelStatusNextStep) {
          switch (this.navInfoType) {
            case 'wpDetail':
              return []
            case 'loopDetail':
            case null:
              if (this.travelStatusNextStep.stepMarker && this.travelStatusNextStep.stepMarker.lineMarker) {
                return this.travelStatusNextStep.stepMarker.lineMarker
              } else {
                return []
              }
            default:
              return []
          }
        } else {
          return []
        }
      } else {
        return []
      }
    },
    colorZoneMarkerData () {
      if (this.mapMode === 'route-info' && this.routeSelectId && this.showColorZone) {
        return this.markerOfRoute.colorMarker
      } else {
        return []
      }
    },
    codeMarkerData () {
      if (this.filterColorId) {
        const codeMarkerFiltered = this.markerOfRoute.codeMarker.filter(x => x.colorId === this.filterColorId)
        const bounds = new this.$store.getters.getGoogle.maps.LatLngBounds()
        codeMarkerFiltered.forEach(m => {
          bounds.extend(m.position)
        })
        return {
          markerList: codeMarkerFiltered,
          bounds: bounds
        }
      } else {
        return {
          markerList: this.markerOfRoute.codeMarker,
          bounds: this.markerOfRoute.routeBounds
        }
      }
    },
    markerOfRoute () {
      return this.$store.getters.getSelectedRouteInfoMarker
    },
    routeMarkerData () {
      return this.$store.getters['routeModule/getRouteMakerData']
    },
    officeMarkerData () {
      return this.$store.getters['officeModule/getOfficeMarkerData']
    },
    zoneMarkerData () {
      return this.$store.getters['zoneModule/getZoneMarker']
    },
    zoneSelectId () {
      return this.$store.getters['userLoggedModule/getSelectionZone']
    },
    officeSelectId () {
      return this.$store.getters['userLoggedModule/getSelectionOffice']
    },
    routeSelectId () {
      return this.$store.getters['userLoggedModule/getSelectionRoute']
    },
    colorSelectId () {
      return this.$store.getters['userLoggedModule/getSelectionColor']
    },
    codeSelectId () {
      return this.$store.getters['userLoggedModule/getSelectionCode']
    },
    streetSelectId () {
      return this.$store.getters['userLoggedModule/getSelectionStreet']
    },
    cpSelectId () {
      return this.$store.getters['userLoggedModule/getSelectionCp']
    },
    placeSelectId () {
      return this.$store.getters['userLoggedModule/getSelectionPlace']
    },
    pdrSelectId () {
      return this.$store.getters['userLoggedModule/getSelectionPdr']
    },
    mapMode () {
      return this.$store.getters['userLoggedModule/getMapMode']
    },
    infoShowLevel () {
      return this.$store.getters['userLoggedModule/getMapinfoShowLevel']
    },
    travelShowLevel () {
      return this.$store.getters['userLoggedModule/getMapTravelShowLevel']
    },
    officeItemMarker () {
      const routeId = this.routeSelectId
      function rFilter (marker) {
        if (routeId && marker.route) {
          return marker.route.indexOf(routeId) > -1
        } else {
          return true
        }
      }
      switch (this.mapMode) {
        case 'route-info':
          if (this.infoShowLevel === 'zone' || this.infoShowLevel === 'officeOfZone' || this.infoShowLevel === 'routeOfOffice' || this.infoShowLevel === 'pocDetail' || this.infoShowLevel === 'pocDetail') {
            return []
          } else {
            // // console.log('trace bpCom of route')
            // // console.log(this.bpComMarker.filter(rFilter))
            return [].concat(this.parcelLockerMarker, this.toiletMarker, this.parkingMarker.filter(rFilter), this.bpComMarker.filter(rFilter), this.greyBoxMarker.filter(rFilter), this.redBoxMarker.filter(rFilter), this.meMarkerData, this.alertMarker.filter(rFilter), this.colisMarkerData)
          }
        case 'track-me':
          return [].concat(this.parcelLockerMarker, this.toiletMarker, this.greyBoxMarker, this.redBoxMarker, this.meMarkerData, this.alertMarker)
        case 'direction':
          return [].concat(this.travelStepFromToMarker, this.meMarkerData, this.alertMarker.filter(rFilter))
        case 'road-book':
          return [].concat(this.travelStepFromToMarker, this.meMarkerData, this.alertMarker.filter(rFilter))
        default:
          return []
      }
    },
    bpComMarker () {
      if (this.showBpCom) {
        // // console.log('trace bpCom')
        // // console.log(this.$store.getters['officeItemMultiModule/getBpComMarker'])
        return this.$store.getters['officeItemMultiModule/getBpComMarker']
      } else {
        return []
      }
    },
    parcelLockerMarker () {
      if (this.showParcelLocker) {
        // // console.log('trace ParcelLockerMarker')
        // // console.log(this.$store.getters['officeItemModule/getParcelLockerMarker'])
        return this.$store.getters['officeItemModule/getParcelLockerMarker']
      } else {
        // // console.log('trace ParcelLockerMarker aucun')
        return []
      }
    },
    parkingMarker () {
      if (this.showParking) {
        // // console.log('trace parking')
        // // console.log(this.$store.getters['officeItemMultiModule/getParkingMarker'])
        return this.$store.getters['officeItemMultiModule/getParkingMarker']
      } else {
        return []
      }
    },
    toiletMarker () {
      if (this.showToilet) {
        return this.$store.getters['officeItemModule/getToiletMarker']
      } else {
        return []
      }
    },
    greyBoxMarker () {
      if (this.showGreyBox) {
        return this.$store.getters['officeItemModule/getGreyBoxMarker']
      } else {
        return []
      }
    },
    redBoxMarker () {
      if (this.showRedBox) {
        return this.$store.getters['officeItemModule/getRedBoxMarker']
      } else {
        return []
      }
    },
    alertMarker () {
      if (this.showDog) {
        return this.$store.getters['reportModule/getAlertZoneMarkerData']
      } else {
        return []
      }
    }
  },
  watch: {
    currentPosition (newValue) {
      this.postManMarker.marker.setPosition(newValue)
      this.postManMarker.position = newValue
    },
    travelStatusNextStepPos (newValue) {
      if (this.dataReady && newValue) {
        // console.log('trace newStepPos = ' + newValue)
        switch (this.mapMode) {
          case 'road-book':
            if (this.navInfoType) {
              this.$store.commit('userLoggedModule/setTravelSelectedNavInfo', null)
            }
            break
          case 'direction':
            if (this.navInfoType) {
              // this.$store.commit('userLoggedModule/setTravelSelectedNavInfo', {
              //   type: 'loopDetail',
              //   stepId: newValue
              // })
            }
            break
          default:
            break
        }
      }
    }
    // travelDetail (newValue) {
    //   // // console.log(newValue)
    // },
    // markerData (newValue) {
    //   // // console.log(newValue)
    // },
    // travelStepFromToMarker (newValue) {
    // //  // console.log(newValue)
    // }
  }
}
</script>
