<template app>
  <v-row justify="center">
    <v-dialog v-model="dialog" fixed persistent max-width="644" transition="dialog-bottom-transition">
      <v-card>
        <v-app-bar dark color="primary">
          <v-btn icon x-large dark @click="dialog = false">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ bpComDetail.route }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-app-bar>
        <v-card ref="maincard" :max-height="$store.getters.contentHeight * 0.85" class="overflow-y-auto overflow-x-hidden">
          <v-card
            elevation="3"
            class="my-2 mx-2"
            v-for="site in bp.site"
            :key="site.siteKey"
          >
            <v-card-title>Site: {{ site.siteTempName }} - {{ site.siteId }}</v-card-title>
            <v-card-subtitle>{{ site.bpComDesc }}</v-card-subtitle>
            <v-card-subtitle v-if="site.fileName === fileName" class="red--text">{{$t('common.alreadyImported')}}</v-card-subtitle>

            <v-card-text>
              <v-card v-if="site.noMatchArray" class="mb-8 red lighten-4">
                <v-card-title><v-icon color="red" class="me-2">mdi-alert</v-icon>{{ $t('import-bpcom.noMatch') }}</v-card-title>
                <v-card-subtitle>{{ $t('import-bpcom.noMatchDesc') }}</v-card-subtitle>
                <v-card-subtitle>{{ $t('common.pdr') }}:</v-card-subtitle>
                <v-card-text
                  v-for="(noMatch, i) in site.noMatchArray"
                  :key="i"
                >
                  {{ noMatch.address + ' ( ' + $t('common.udl') + ': ' + noMatch.udlId + ' , ' + $t('common.rta') + ': ' +noMatch.rtaId + ' )' }}
                </v-card-text>
              </v-card>
              <v-text-field
                v-model="site.siteId"
                label="Site no"
                outlined
                filled
                block
              ></v-text-field>
              <v-expansion-panels>
                <v-expansion-panel
                  v-for="(udl, x) in site.udl"
                  :key="x"
                >
                  <v-expansion-panel-header>
                    <v-row no-gutters>
                      <v-col cols="2">
                        <v-list-item-content two-line align="center" class="ms-n4 my-n2">
                          <v-list-item-subtitle>{{ $t('common.udl') }}</v-list-item-subtitle>
                          <v-list-item-title>{{ udl.udlId }}</v-list-item-title>
                        </v-list-item-content>
                      </v-col>
                      <v-col cols="10" class="overflow-hidden">
                        <v-list-item-content two-line class="my-n2">
                          <h5><span class="red--text">{{ $t('common.from') }} :</span> {{ getUdlFromAddress(udl) }}</h5>
                          <h5><span class="red--text">{{ $t('common.to') }} :</span> {{ getUdlToAddress(udl) }}</h5>
                        </v-list-item-content>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list nav>
                      <v-list-item
                        v-for="rta in udl.rta"
                        :key="rta.rtaId"
                        elevation-5
                      >
                        <v-list-item-avatar>
                            {{ $t('common.rta') }} : {{ rta.rtaId }}
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <p :class="rta.isMailSlot ? 'subtitle-2 mt-1 red--text' : 'subtitle-2 mt-1'">
                            {{ rta.civic && rta.street ? rta.civic + ' ' + rta.street : rta.isMailSlot ? $t('common.mailSlot') : $t('common.empty') }}
                          </p>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <v-select
                      label="Deplacer Udl vers..."
                      filled
                      block
                      :id="`switchselectudl-${site.siteTempName}`"
                      @focus="autoScrollTo(`#switchselectudl-${site.siteTempName}`)"
                      attach="attach"
                      :items="getSwitchItemsOfUdl(site)"
                      @change="switchUdlTo({ siteKey: site.siteKey, udlKey: udl.udlKey, to: $event })"
                    >
                    <template v-slot:append-outer>
                      <div id="attach"></div>
                    </template>
                    </v-select>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <p class="mt-5">Config:</p>
              <v-row no-gutters>
                <p>{{ site.miniPark }}</p>
                <v-spacer></v-spacer>
                <p>{{ site.siteConfiguration }}</p>
              </v-row>
              <v-text-field class="mt-2" v-model="site.bpComDesc" block outlined filled label="Desc"></v-text-field>

              <v-autocomplete
                v-model="site.proximPocId"
                :ref="`autocomplete-${site.siteTempName}`"
                :id="`autocomplete-${site.siteTempName}`"
                outlined
                :scroll-target="`autocompletetarget-${site.siteTempName}`"
                filled
                :filter="customFilter"
                @focus="autoScrollTo(`#autocomplete-${site.siteTempName}`)"
                :label="$t('common.proximityOf')"
                color="primary"
                :items="pocItems"
                attach="attach"
              ></v-autocomplete>
                <template v-slot:append-outer>
                  <div id="attach"></div>
                </template>
              <div :id="`autoselector-${site.siteTempName}`"></div>
              <v-row no-gutters>
                <v-col cols="6" align="center">
                  <v-btn icon x-large @click="showGetPosition = site"><v-icon x-large :color="site.position ? 'success' : 'error'">mdi-map-marker</v-icon></v-btn>
                  <p align="center">{{ $t('createreportdog.gps') }}</p>
                </v-col>
                <v-col cols="6" align="center">
                  <v-btn icon :disabled="!site.position || (site.noMatchArray && site.noMatchArray.length > 0)" x-large @click="importSite(site)"><v-icon x-large :color="site.multiId ? 'yellow' : 'green'">mdi-content-save</v-icon></v-btn>
                  <p align="center">{{ site.multiId ? $t('common.update') : $t('common.save') }}</p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card>
      </v-card>
      <loading-popup v-if="showLoading" :title="loadingTitle" :subTitle="loadingsubTitle"></loading-popup>
    </v-dialog>
    <pmt-position-map-set
      v-if="showGetPosition"
      :startCenterPdrId="showGetPosition.proximPocId ? showGetPosition.proximPocId : null"
      :param="{ icon: $store.getMapItemIconOfType('cmb'), markerStartPosition: showGetPosition.position ? showGetPosition.position : null, centerPostition: showGetPosition.position ? showGetPosition.position : null, getDistance: false, title: showGetPosition.siteId + ' - ' + showGetPosition.bpComDesc }"
      @return-close="showGetPosition = null"
      @return-check="setPosition($event)"
    />
  </v-row>
</template>

<script>
import * as easings from 'vuetify/es5/services/goto/easing-patterns'
export default {
  props: ['bpComDetail', 'fileName'],
  data () {
    return {
      bp: this.bpComDetail,
      routeId: this.$store.getters['routeModule/getRouteIdByName'](this.bpComDetail.route),
      dialog: false,
      showLoading: true,
      loadingTitle: this.$t('edit-route.loadingDesc'),
      loadingsubTitle: 'Route: ' + this.bpComDetail.route,
      showGetPosition: null,
      duration: 300,
      offset: 0,
      easing: 'easeInOutCubic',
      easings: Object.keys(easings)
    }
  },
  methods: {
    isSiteDisabled (site) {
      return !site.proximPocId && !site.position
    },
    importSite (site) {
      this.loadingTitle = this.$t('common.saveinprogress')
      this.showLoading = true
      const self = this
      site.fileName = this.fileName
      const item = {
        type: 'cmb',
        desc: site.bpComDesc,
        imageList: [],
        pocIdList: this.getPocIdList(site),
        pos: site.position,
        udl: site.udl,
        siteId: site.siteId,
        siteConfiguration: site.siteConfiguration,
        miniPark: site.miniPark,
        proximPocId: site.proximPocId,
        fileName: this.fileName
      }
      if (site.multiId) {
        item.pos = site.position
        item.multiId = site.multiId
        this.$store.dispatch('officeItemMultiModule/updateMultiItem', item)
          .then(() => {
            this.showLoading = false
          })
      } else {
        item.isActive = true
        item.name = null
        item.loc = site.position
        item.route = [this.routeId]
        this.$store.dispatch('officeItemMultiModule/set', item)
          .then((multiId) => {
            const prom = []
            site.multiId = multiId
            item.pocIdList.forEach(pocId => {
              prom.push(self.$store.dispatch('pocModule/updatePocItem', {
                pocId: pocId,
                itemId: 0,
                officeItemMultiId: multiId
              }))
            })
            Promise.all(prom).then(() => {
              self.showLoading = false
            })
          })
      }
    },
    getPocIdList (site) {
      const pocIdList = []
      site.udl.forEach(udl => {
        udl.rta.forEach(rta => {
          if (rta.pocId && pocIdList.indexOf(rta.pocId) < 0) {
            pocIdList.push(rta.pocId)
          }
        })
      })
      return pocIdList
    },
    setPosition (posDist) {
      const siteIdx = this.bp.site.map(x => { return x.siteKey }).indexOf(this.showGetPosition.siteKey)
      if (siteIdx > -1) {
        this.bp.site[siteIdx].position = posDist.position
      }
      this.showGetPosition = null
    },
    matchBpComToPoc (pocArray) {
      if (pocArray && pocArray.length) {
        this.bp.site.forEach(site => {
          site.noMatchArray = null
          const siteMultiId = this.isSiteExistList(site)
          if (siteMultiId) {
            const multi = this.$store.state.officeItemMultiModule.data[siteMultiId]
            site.position = multi.loc
            site.siteId = multi.siteId
            site.fileName = multi.fileName ? multi.fileName : null
            site.multiId = siteMultiId
            site.proximPocId = multi.proximPocId ? multi.proximPocId : null
          }
          site.udl.forEach(udl => {
            udl.rta.forEach(rta => {
              if (rta.civic && rta.street) {
                if (!rta.pocId) {
                  const address = rta.civic.toUpperCase() + ' ' + rta.street.toUpperCase()
                  const corIndex = address.split('�')
                  let isCleanText = true
                  if (corIndex.length > 1) {
                    isCleanText = false
                  }
                  let match = false
                  pocArray.forEach(poc => {
                    if (!match) {
                      if (isCleanText) {
                        if (poc.pdrListAddress === address) {
                          rta.pocId = poc.id
                          match = true
                        }
                      } else {
                        let strCpt = 0
                        match = true
                        for (let i = 0; i < corIndex.length; i++) {
                          const adr = corIndex[i].toUpperCase()
                          if (adr.length && poc.pdrListAddress.substring(strCpt, strCpt + adr.length) !== adr) {
                            match = false
                            break
                          }
                          strCpt += adr.length + 1
                        }
                        if (match) {
                          rta.street = poc.pdrListAddress.replace(rta.civic, '').trim()
                          rta.pocId = poc.id
                        }
                      }
                    }
                  })
                  if (match) {
                    if (!site.proximPocId) {
                      site.proximPocId = this.searchProximityPoc(rta.pocId, site.bpComDesc)
                    }
                  } else {
                    const noMatch = { address: address, udlId: udl.udlId, rtaId: rta.rtaId }
                    if (site.noMatchArray) {
                      site.noMatchArray.push(noMatch)
                    } else {
                      site.noMatchArray = [noMatch]
                    }
                  }
                }
              }
            })
          })
        })
        this.showLoading = false
      }
      this.$store.commit('setShowMasterLoading', false)
    },
    searchProximityPoc (pocId, desc) {
      const pocAdr = this.$store.state.pocModule.data[pocId].pdrListAddress
      if (pocAdr.length > desc.length) {
        return null
      } else if (desc.indexOf(pocAdr) > -1) {
        return pocId
      } else if (desc.split('�').length) {
        const sdesc = desc.substring(desc.length - pocAdr.length)
        const descIndex = sdesc.split('�')
        let match = true
        let strCpt = 0
        for (let i = 0; i < descIndex.length; i++) {
          const adr = descIndex[i].toUpperCase()
          if (adr.length && pocAdr.substring(strCpt, strCpt + adr.length) !== adr) {
            match = false
            break
          }
          strCpt += adr.length + 1
        }
        if (match) { return pocId }
        return null
      } else {
        return null
      }
    },
    isSiteExistList (site) {
      let multiId = null
      if (site.multiId) { return site.multiId }
      const bpComArray = this.$store.getters['officeItemMultiModule/getBpCom'].filter(x => x.siteId === site.siteId)
      bpComArray.forEach(result => {
        multiId = result.id
        return result.id
      })
      if (site.udl && site.udl.length) {
        site.udl.forEach(udl => {
          udl.rta.forEach(rta => {
            if (rta.pocId) {
              if (this.$store.state.pocModule.data[rta.pocId].mapItems[0].officeItemMultiId) {
                multiId = this.$store.state.pocModule.data[rta.pocId].mapItems[0].officeItemMultiId
                const multi = this.$store.state.officeItemMultiModule.data[multiId]
                site.position = multi.loc
                site.siteId = multi.siteId
                site.multiId = multiId
                return multiId
              }
            }
          })
        })
      }
      return multiId
    },
    getUdlFromAddress (udl) {
      let fromAddress = ''
      let min = 99
      udl.rta.forEach(rta => {
        if (rta.civic && rta.street && Number(rta.rtaId) < min) {
          min = Number(rta.rtaId)
          fromAddress = rta.civic + ' ' + rta.street
        }
      })
      return fromAddress
    },
    getUdlToAddress (udl) {
      let toAddress = ''
      let max = 0
      udl.rta.forEach(rta => {
        if (rta.civic && rta.street && Number(rta.rtaId) > max) {
          max = Number(rta.rtaId)
          toAddress = rta.civic + ' ' + rta.street
        }
      })
      return toAddress
    },
    switchUdlTo (sInfo) {
      const siteIdx = this.bp.site.map(x => { return x.siteKey }).indexOf(sInfo.siteKey)
      const udlIdx = this.bp.site[siteIdx].udl.map(x => { return x.udlKey }).indexOf(sInfo.udlKey)
      const fromSite = this.bp.site[siteIdx]
      if (sInfo.to === 'new') {
        const siteName = this.bp.site.length + 1
        this.bp.site.push({
          siteId: fromSite.siteId,
          siteKey: this.$store.getUniqueKey(),
          siteTempName: siteName,
          siteConfiguration: fromSite.siteConfiguration,
          miniPark: fromSite.miniPark,
          bpComDesc: fromSite.bpComDesc,
          udl: [this.bp.site[siteIdx].udl[udlIdx]]
        })
      } else {
        this.bp.site[this.bp.site.map(x => { return x.siteKey }).indexOf(sInfo.to)].udl.push(this.bp.site[siteIdx].udl[udlIdx])
      }
      this.bp.site[siteIdx].udl.splice(udlIdx, 1)
    },
    getSwitchItemsOfUdl (site) {
      const items = [{ text: this.$t('common.newSite'), value: 'new' }]
      this.bp.site.forEach(s => {
        if (s.siteKey !== site.siteKey) {
          items.push({ text: 'Site - ' + s.siteTempName, value: s.siteKey })
        }
      })
      return items
    },
    customFilter (item, queryText, itemText) {
      const itemTextLow = itemText.toLowerCase()
      const searchTextArray = queryText.toLowerCase().split(' ')
      var result = false
      for (let i = 0; i < searchTextArray.length; i++) {
        const search = searchTextArray[i]
        if (search.length) {
          if (itemTextLow.indexOf(search) > -1 && search) {
            result = true
          } else {
            result = false
            break
          }
        }
      }
      return result
    },
    autoScrollTo (idTarget) {
      this.$vuetify.goTo(idTarget, { container: this.$refs.maincard })
    }
  },
  computed: {
    pocItems () {
      return this.pocOfRoute.map(x => { return { text: x.pdrListAddress, value: x.id } })
    },
    pocOfRoute () {
      return this.$store.getters['pocModule/getPocOfRoute'](this.routeId).sort(function (a, b) {
        return a.seqPosTot - b.seqPosTot
      })
    },
    options () {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing
      }
    }
  },
  watch: {
    bpComDetail (newValue) {
      if (newValue) {
        this.dialog = true
      } else {
        this.dialog = false
      }
    },
    dialog (newValue) {
      if (!newValue) {
        this.dialog = false
        this.$store.dispatch('resize')
        this.$emit('close')
      }
    },
    showLoading (newValue) {
      if (!newValue) {
        this.dialog = true
      }
    },
    pocOfRoute (newValue) {
      this.$store.commit('setMasterLoadingTitle', this.$t('loading-mess.analyze-data'))
      this.matchBpComToPoc(newValue)
    }
  },
  created () {
    this.$store.commit('setMasterLoadingStatus', {
      showLoading: true,
      title: this.$t('loading-mess.load-route-data'),
      subTitle: this.bpComDetail.route
    })
    const clauses = [['route', 'array-contains', this.routeId]]
    const prom = []
    prom.push(this.$store.dispatch('pocModule/openDBChannel', { pathVariables: { officeId: this.$store.getters.getSelectedOfficeId }, clauses: { where: clauses } }))
    prom.push(this.$store.dispatch('officeItemMultiModule/openDBChannel'), { pathVariables: { officeId: this.$store.getters.getSelectedOfficeId } })
    Promise.all(prom).then(() => {
      this.$store.commit('setMasterLoadingTitle', this.$t('loading-mess.analyze-data'))
      this.matchBpComToPoc(self.pocOfRoute)
    })
  },
  destroyed () {
    this.$store.dispatch('pocModule/closeDBChannel')
    this.$store.dispatch('officeItemMultiModule/closeDBChannel')
  }
}
</script>
