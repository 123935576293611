<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="583"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          gestion calendrier
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Gestion du calendrier
        </v-card-title>
        <v-row no-gutters>
          <v-card width="290">
            <!-- <v-date-picker
              v-model="newDate"
              color="green lighten-1"
            ></v-date-picker> -->
            <v-card-text>
              <v-text-field
                filled
                outlined
                v-model="newDate"
                :rules="[ validDate ]"
                label="Date de reference"
              >
                <template v-slot:append>
                  <datePicker
                    :presetDate="newDate"
                    @newDate="newDate = $event"
                  ></datePicker>
                </template>
              </v-text-field>
              <v-select
                filled
                outlined
                v-model="dayColor"
                :items="dayColorItems"
                label="Couleur de reference"
              ></v-select>
              <v-btn block @click="generateCalendar()">Generer</v-btn>
            </v-card-text>
          </v-card>
          <v-card width="290">
            <v-select
            :items="dayColorItems"
          ></v-select>
          </v-card>
        </v-row>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import datePicker from '../common/PMT_DatePicker_popup.vue'
export default {
  components: { datePicker },
  name: '',
  props: [],
  data () {
    return {
      dialog: false,
      newDate: new Date().toLocaleDateString('fr-CA'),
      dayColor: null,
      notWDayArray: this.$store.getters['paramModule/getCalNotWorkingDayArray'],
      dayColorItems: [
        { text: 'Orange', value: '1' },
        { text: 'Rose', value: '2' },
        { text: 'Bleu', value: '3' }
      ]
    }
  },
  methods: {
    validDate (txtDate) {
      if (txtDate && this.$store.isValidDate(txtDate)) {
        return true
      } else {
        return 'date non valid'
      }
    },
    generateCalendar () {
      const calendar = this.$store.getters['paramModule/getCalCalendar']
      const refDate = new Date(this.newDate)
      refDate.setDate(refDate.getDate() + 1)
      let color = Number(this.dayColor)

      for (let cpt = 1; cpt < 400; cpt++) {
        if (refDate.getDay() && refDate.getDay() !== 6) {
          let workDay = true

          for (let i = 0; i < this.notWDayArray.length; i++) {
            const notWDay = this.notWDayArray[i]
            if (notWDay.year === refDate.getFullYear() && notWDay.month === refDate.getMonth() && notWDay.day === refDate.getDate()) {
              workDay = false
            }
          }
          if (workDay) {
            let dayExist = false
            for (let i = 0; i < calendar.length; i++) {
              const calDay = calendar[i]
              if (calDay.year === refDate.getFullYear() && calDay.month === refDate.getMonth() && calDay.day === refDate.getDate()) {
                dayExist = true
                calDay.color = color
              }
            }
            if (!dayExist) {
              calendar.push({ year: refDate.getFullYear(), month: refDate.getMonth(), day: refDate.getDate(), color: color })
            }
            if (color === 3) {
              color = 1
            } else { color++ }
          }
        }
        refDate.setDate(refDate.getDate() + 1)
      }
      const id = this.$store.getters['paramModule/getCalId']
      this.$store.dispatch('paramModule/set', { id, calendar: calendar })
    }
  },
  watch: {
    newDate (newValue) {
      // // console.log(newValue)
    }
  }
}
</script>
