<template>
  <div>
  <v-card
    elevation='24'
    max-width='444'
    class='mx-auto'
  >
    <v-system-bar>Attache</v-system-bar>
    <v-carousel
      :continuous='false'
      :cycle="cycle"

      :show-arrows="true"
      :hide-delimiters="true"
      height="500"
      light
    >
      <v-carousel-item
        v-for="(code, i) in codes"
        :key="i"
      >
        <v-sheet
          :color="daycolor"
          height="100%"
          tile

        >
      <v-card
        class="d-flex mx-3 mt-3 justify-space-between"
        :flat=true
        :color="daycolor"
      >
        <v-card
          class="mt-3 align-center"
        >

        {{ code.relais }}
        </v-card>
        <v-card
          class="mt-3"
          :flat=true
          :color="daycolor"
        >
          Sequence :

        </v-card>
        <v-card
          class="mt-3 justify-space-between"
        >
          system
        </v-card>
      </v-card>

      <v-card
        class="d-flex mx-3 mt-3 justify-space-between"
        :flat=true
        :color="daycolor"
      >
        <v-card
          class="justify-space-between"
        >
          item 4
        </v-card>
        <v-card
          class="mt-n2 px-1 justify-space-between title"
          elevation="5"
        >
        <!-- 1/200-->
            {{i +1 }}/{{codes.length}}
        </v-card>
        <v-card
          class="justify-space-between"
        >
          item 6
        </v-card>
      </v-card>
      <!-- Rue -->
      <v-card

        class="d-flex justify-center mt-3 display-1"
        :flat=true
        :color="daycolor"
      >
        {{ code.roadname }}
      </v-card>
      <!-- type en U == 1 -->
      <v-card
        v-if="code.type===1"
        class="d-flex justify-center mt-7 mb-8"
        :flat=true
        :color="daycolor"
      >

        <v-card
          class=""
          :color="daycolor"
          :flat=true
        >
          <v-list two-line

            :color="daycolor"
          >
            <v-list-item>
              <v-list-item-content>

                <v-list-item-title
                  class="d-flex justify-end display-1"
                >
                  {{code.start}}
                </v-list-item-title>
                <v-list-item-subtitle
                  class="d-flex justify-end title"
                >
                  {{code.startband}}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

        </v-card>
        <v-card
          class="mt-7"
          :color="daycolor"
          :flat=true
        >
          <v-img
              :src="require('@/assets/Icon/AppIcon/arrow-right.png')"
              alt="contact"
              height="60"
              width="60"
          >
          </v-img>
        </v-card>
        <v-card
          class=""
          :flat=true
        >
        <v-list two-line

          :color="daycolor"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="d-flex justify-start display-1">{{code.end}}</v-list-item-title>
                <v-list-item-subtitle class="d-flex justify-start title">2-4-2</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>

      </v-card>
      <!-- type en X == 2 -->
      <v-card
        v-if="code.type===2"
        class="d-flex mt-6 justify-center"
        :flat=true
        :color="daycolor"
      >
        <v-card
          class="ml-5"
          :color="daycolor"
          :flat=true
        >
          <v-list two-line :color="daycolor">
            <v-list-item class="mt-n5">
              <v-list-item-content>
                <v-list-item-title class="d-flex justify-end display-1">{{code.startpair}}</v-list-item-title>
                <v-list-item-subtitle class="d-flex justify-end title mt-n3">2-4-5</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="mt-n5">
              <v-list-item-content>
                <v-list-item-title class="d-flex justify-end display-1">{{code.startimpair}}</v-list-item-title>
                <v-list-item-subtitle class="d-flex justify-end title mt-n3">2-4-5</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
        <v-card
          class="justify-space-between mt-9"
          :color="daycolor"
          :flat=true
        >
          <v-img
              :src="require('@/assets/Icon/AppIcon/arrow-expand-all.png')"
              alt="contact"
              height="60"
              width="60"
          >
          </v-img>

        </v-card>
        <v-card
          class="justify-space-between mr-3"
          :flat=true
        >
        <v-list two-line :color="daycolor">
            <v-list-item class="mt-n5">
              <v-list-item-content>
                <v-list-item-title class="d-flex justify-start display-1">{{code.endpair}}</v-list-item-title>
                <v-list-item-subtitle class="d-flex justify-start title mt-n3">2-4-5</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item  class="mt-n5">
              <v-list-item-content>
                <v-list-item-title class="d-flex justify-start display-1">{{code.endimpair}}</v-list-item-title>
                <v-list-item-subtitle class="d-flex justify-start title mt-n3">2-4-5</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>

      </v-card>
      <!-- Circulaire de la sequence -->

      <v-card>
        <v-simple-table dense width="80%">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Circulaire</th>
                <th class="text-center">Dom</th>
                <th class="text-center">app</th>
                <th class="text-center">com</th>
                <th class="text-center">ferme</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="circulaire in code.circulaires" :key="circulaire.color">
                <td class="text-left"> {{ circulaire.color}} </td>
                <td class="text-center"> {{ circulaire.dom}} </td>
                <td class="text-center"> {{ circulaire.app}} </td>
                <td class="text-center"> {{ circulaire.com}} </td>
                <td class="text-center"> {{ circulaire.ferme}} </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <!--10 11 12 -->
      <v-card
        class="d-flex mx-3 mt-12 justify-space-between"
        :flat=true
        :color="daycolor"
      >
        <v-card
          class="justify-space-between"
        >
          <timelineSequence
            :sequences= code.shipsequences
            :title= code.roadname>
          </timelineSequence>
        </v-card>
        <v-card
          class="justify-space-between"
        >
          detail
        </v-card>
        <v-card
          class="justify-space-between"
        >
          <SeqMap title='Carte de sequence'
          :sequences= code.shipsequences
          :centerposition = code.centerposition></SeqMap>
        </v-card>

      </v-card>

        </v-sheet>
      </v-carousel-item>
    </v-carousel>
    <!-- Circulaire Total de route-->
    <v-simple-table dense>
          <template v-slot:default>
            <thead dark>
              <tr>
                <th class="text-center mx-n3">Circulaire</th>
                <th class="text-center mx-n3">Dom</th>
                <th class="text-center mx-n3">App</th>
                <th class="text-center mx-n3">Com</th>
                <th class="text-center mx-n3">Ferme</th>
                <th class="primary text-center mx-n3">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr class="orange--text">
                <td class="text-center mx-n3"> {{ roadcircu.orange.name }} </td>
                <td class="text-center mx-n3"> {{ roadcircu.orange.dom }} </td>
                <td class="text-center mx-n3"> {{ roadcircu.orange.app }} </td>
                <td class="text-center mx-n3"> {{ roadcircu.orange.com }} </td>
                <td class="text-center mx-n3"> {{ roadcircu.orange.ferme }} </td>
                <td class="grey text-center mx-n3"> {{ roadcircu.orange.total }} </td>
              </tr>
              <tr class="pink--text">
                <td class="text-center mx-n3"> {{ roadcircu.pink.name }} </td>
                <td class="text-center  mx-n3"> {{ roadcircu.pink.dom }} </td>
                <td class="text-center mx-n3"> {{ roadcircu.pink.app }} </td>
                <td class="text-center mx-n3"> {{ roadcircu.pink.com }} </td>
                <td class="text-center mx-n3"> {{ roadcircu.pink.ferme }} </td>
                <td class="grey text-center mx-n3"> {{ roadcircu.pink.total }} </td>
              </tr>
              <tr class="blue--text">
                <td class="text-center mx-n3"> {{ roadcircu.blue.name }} </td>
                <td class="text-center mx-n3"> {{ roadcircu.blue.dom }} </td>
                <td class="text-center mx-n3"> {{ roadcircu.blue.app }} </td>
                <td class="text-center mx-n3"> {{ roadcircu.blue.com }} </td>
                <td class="text-center mx-n3"> {{ roadcircu.blue.ferme }} </td>
                <td class="grey text-center mx-n3"> {{ roadcircu.blue.total }} </td>
              </tr>
              <tr class="black mx-n2">
                <td class="grey text-center mx-n3"> {{ roadcircu.total.name }} </td>
                <td class="grey text-center mx-n3"> {{ roadcircu.total.dom }} </td>
                <td class="grey text-center mx-n3"> {{ roadcircu.total.app}} </td>
                <td class="grey text-center mx-n3"> {{ roadcircu.total.com}} </td>
                <td class="grey text-center mx-n3"> {{ roadcircu.total.ferme}} </td>
                <td class="grey text-center mx-n3"> {{ roadcircu.total.total}} </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
  </v-card>
  </div>
</template>

<script>

export default {
  props: ['contentstyles'],
  mounted () {

  },
  methods: {
    relais () {
      return 'nom du relais'
    }
  },
  data () {
    return {
      daycolor: 'orange',
      cycle: false,
      codes: [{
        sequence: 1,
        relais: '92 Philipe Boucher',
        type: 1,
        roadname: 'Mgr Bourget',
        start: '39',
        startband: '3-3-3',
        end: '33',
        endband: '4-4-4',
        centerposition: { lat: 46.823193, lng: -71.162751 },
        shipsequences: [{ door: '39', homeposition: { lat: 46.823193, lng: -71.162751 }, tier: 'pink', type: 'App', app: [{ name: '1' }, { name: '2' }, { name: '3' }, { name: '4' }], pair: false }, { door: '35', homeposition: { lat: 46.823638, lng: -71.162917 }, tier: 'pink', type: 'App', app: [{ name: '1' }, { name: '2' }, { name: '3' }, { name: '4' }, { name: '5' }, { name: '6' }, { name: '7' }, { name: '8' }, { name: '9' }, { name: '10' }], pair: false }, { door: '33', homeposition: { lat: 46.823505, lng: -71.163222 }, tier: 'pink', type: 'Dom', pair: false }],
        circulaires: [{ color: 'rose', colorcode: 'pink--text', app: '14/14', dom: '1/1', com: '0/0', ferme: '0/0' }]
      }, {
        sequence: 2,
        type: 1,
        roadname: 'Mgr Bourget',
        start: '36',
        end: '36',
        startband: '3-3-3',
        endband: '3-3-3',
        shipsequences: [{ door: '36', pair: true, tier: 'pink', type: 'Dom' }],
        circulaires: [{ color: 'rose', colorcode: 'orange--text', app: '0/0', dom: '1/1', com: '0', ferme: '0' }]
      }, {
        sequence: 3,
        type: 2,
        roadname: "De L'Eau Fraiche",
        startpair: '48',
        endpair: '20',
        startimpair: '55',
        endimpair: '43',
        shipsequences: [{ door: '55', pos: 'right', tier: 'pink', type: 'Dom', pair: false }, { door: '48', pos: 'left', tier: 'pink', type: 'Dom', pair: true }, { door: '49', tier: 'pink', type: 'Dom', pair: false }, { door: '43', tier: 'pink', type: 'Dom', pair: false }, { door: '44', tier: 'pink', type: 'Dom', pair: true }, { door: '40', tier: 'pink', type: 'Dom', pair: true }, { door: '34', tier: 'pink', type: 'Dom', pair: true }, { door: '28', tier: 'pink', type: 'Dom', pair: true }, { door: '20', tier: 'pink', type: 'App', app: [{ name: '1' }, { name: '2' }, { name: '3' }, { name: '4' }], pair: true }],
        circulaires: [{ color: 'rose', colorcode: 'pink', app: '4/4', dom: '7/8', com: '0', ferme: '0' }]
      }
      ],
      codeCount: 18,
      roadcircu: { orange: { name: 'Orange', app: '46/52', dom: '302/323', com: '12/14', ferme: '0/0', total: '361/393' }, pink: { name: 'Rose', app: '200/205', dom: '198/222', com: '23/23', ferme: '0/0', total: '422/444' }, blue: { name: 'Bleue', app: '72/74', dom: '272/293', com: '2/2', ferme: '0/0', total: '362/375' }, total: { name: 'Total', app: '323/343', dom: '754/823', com: '32/44', ferme: '0/0', total: '1077/1234' } },
      sticky: false,
      singleLine: true,
      icon: 'mdi-plus',
      color: undefined,
      iconColor: undefined,
      elevation: 4
    }
  }
}

</script>
