<template id="template" fixed>
  <v-row justify="center" id="row">
    <v-dialog max-width="444" v-model="dialog" persistent fixed id="dialog" transition="dialog-bottom-transition">
      <v-card>
        <v-data-iterator
          ref="dataIterator"
          :expanded.sync="greyBoxSelectedList"
          :single-expand="solo ? true : false"
          :page="page"
          :custom-filter="customFilter"
          :search="search"
          :items="$store.getters['officeItemModule/getGreyBoxSorted']"
          :items-per-page="30"
          :footer-props="{ 'items-per-page-options' : [15, 30, 50, 100, -1] }"
          @update:items-per-page="$store.dispatch('resize')"
          >
          <template v-slot:header>
            <v-toolbar
              dark
              color="primary"
              class="mb-1"
              :height="$store.getters.appBarHeight"
              >
              <v-app-bar-nav-icon class="me-1">
                <v-btn icon x-large @click="close">
                  <v-icon x-large>mdi-close</v-icon>
                </v-btn>
              </v-app-bar-nav-icon>
              <v-text-field
                v-model="search"
                clearable
                flat
                solo-inverted
                hide-details
                prepend-inner-icon="mdi-magnify"
                :label="$t('dataIterator.searchLabel')"
                @blur="$store.dispatch('resize')"
              ></v-text-field>
              <v-app-bar-nav-icon color="green darken-1" class="ms-1">
                <v-btn icon x-large @click="returnSelectedPdr()" color="green darken-1">
                  <v-icon x-large>mdi-check-circle</v-icon>
                </v-btn>
              </v-app-bar-nav-icon>
              <template v-slot:extension v-if="greyBoxSelectedList.length && !solo">
                <v-card-subtitle class="white--text"> {{ greyBoxSelectedList.length }} {{ $t('common.selected') }}</v-card-subtitle>
              </template>
            </v-toolbar>
          </template>
          <template v-slot:default="{ items, isExpanded, expand }">
            <div overflow-y-auto>
              <v-container v-show="showSearchParam" transition="dialog-top-transition">
                <v-card-title> Option de recherche</v-card-title>
                <v-btn large block>
                  type de recherche
                </v-btn>
                <v-btn large block>
                  type de recherche
                </v-btn>
                <v-btn large block>
                  type de recherche
                </v-btn>
              </v-container>
              <v-list nav v-if="!showSearchParam">
                <v-list-item
                  v-for="item in items"
                  :key="item.id"
                  :background-color="item.goup"
                  :color="item.goup"
                  :class="isExpanded(item) ? 'elevation-2 ' + item.group : item.group"
                  @click="(v) => expand(item, !isExpanded(item))"
                >
                  <v-list-item-content>
                    <v-list-item-title  v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action><v-checkbox color="primary" :input-value="isExpanded(item)"></v-checkbox></v-list-item-action>
                </v-list-item>
              </v-list>
            </div>
          </template>
          <!-- <template v-slot:footer>
            <v-container background-color="primary" class="primary darken-1">
            <v-row align="center" justify="center">
              <span class="white--text">{{ $t('dataIterator.rowsPerPageText') }}</span>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    dark
                    x-large
                    class="primary ml-1"
                    v-on="on"
                  >
                    {{ itemsPerPage.text }}
                    <v-icon large>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(number, index) in itemsPerPageArray"
                    :key="index"
                    @click="updateItemsPerPage(number)"
                  >
                    <v-list-item-title>{{ number.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-row>
            <v-row class="mt-3">
              <v-btn
                fab
                dark
                x-large
                class="ms-1"
                color="primary lighten-1"
                @click="formerPage"
              >
                <v-icon x-large>mdi-chevron-left</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <span
                two-line
                class="white--text"
              >
                <v-list dark two-line class="mt-n2 text-center primary darken-1">
                    <v-list-item-title>
                      Page {{ page }} {{ $t('dataIterator.of') }} {{ numberOfPages }}
                    </v-list-item-title>
                    <v-list-item-title class="mx-auto mt-1">
                      {{ nbrItemFiltered }} {{ $t('common.pdr') }}
                    </v-list-item-title>
                </v-list>
              </span>
              <v-spacer></v-spacer>
              <v-btn
                class="me-1"
                fab
                dark
                x-large
                color="primary  lighten-1"
                @click="nextPage"
              >
                <v-icon x-large>mdi-chevron-right</v-icon>
              </v-btn>
            </v-row>
            </v-container>
          </template> -->

        </v-data-iterator>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ['selItem', 'solo'],
  data () {
    return {
      itemsPerPageArray: [{ text: '5', value: 5 }, { text: '10', value: 10 }, { text: '25', value: 25 }, { text: '50', value: 50 }, { text: this.$t('dataIterator.all'), value: -1 }],
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: { text: '5', value: 5 },
      searchMode: 0,
      nbrItemFiltered: this.$store.getters['officeModule/getGreyBoxActiveList'](this.$store.getters.getSelectedOfficeId).length,
      greyBoxSelectedList: [],
      dialog: true,
      showSearchParam: false,
      notifications: false,
      search: ''
    }
  },
  mounted () {
    if (this.selItem) {
      this.greyBoxSelectedList = this.selItem
    }
  },
  watch: {
    search (newValue) {
      this.page = 1
    },
    dialog (newValue) {
      if (!newValue) {
        this.$store.dispatch('resize')
        this.$emit('close')
      }
    }
  },
  computed: {
    numberOfPages () {
      if (this.itemsPerPage.value > 0) {
        return Math.ceil(this.nbrItemFiltered / this.itemsPerPage.value)
      } else {
        return 1
      }
    },
    filteredKeys () {
      return this.keys.filter(key => key !== 'Name')
    },
    pictureSrc () {
      return this.$store.getters.getUserSelectOfficePictureSrc ? this.$store.getters.getUserSelectOfficePictureSrc : 'https://picsum.photos/1920/1080?random'
    },
    codeSearchList () {
      return null
    }
  },
  methods: {
    // itemSelect (item) {
    //   if (item.value) {
    //     if (this.solo) {
    //       this.greyBoxSelectedList = [item.item]
    //     } else {
    //       this.greyBoxSelectedList.push(item.item)
    //     }
    //   } else {
    //     if (this.solo) {
    //       this.greyBoxSelectedList = []
    //     } else {
    //       this.greyBoxSelectedList.splice(this.greyBoxSelectedList.indexOf(item.item.id), 1)
    //     }
    //   }
    // },
    returnSelectedPdr () {
      this.$emit('select', this.greyBoxSelectedList)
      this.close()
    },
    createReportFor (owner) {
      this.owner = owner
      this.showCreateReport = true
    },
    nextPage () {
      if (this.page + 1 <= this.numberOfPages) {
        this.page += 1
      }
    },
    formerPage () {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage (number) {
      this.page = 1
      this.itemsPerPage = number
    },
    customFilter (items, search, filter) {
      // this custom filter will do a multi-match separated by a space.
      // e.g
      // this.page = 1

      if (!search) {
        this.nbrItemFiltered = items.length
        return items
      } // if the search is empty just return all the items

      function newFilter (val, search) {
        return val !== null &&
          ['undefined', 'boolean'].indexOf(typeof val) === -1 &&
          val.toString().toLowerCase().replace(/[^0-9a-zA-Z-]+/g, '').indexOf(search) !== -1
      }

      const needleAry = search.toString().toLowerCase().split(' ').filter(x => x)
      // whenever we encounter a space in our search we will filter for both the first and second word (or third word)

      const result = items.filter(row => needleAry.every(needle => Object.keys(row).some(key => newFilter(row.name, needle))))
      if (result.length) {
        this.nbrItemFiltered = result.length
      } else {
        this.nbrItemFiltered = 1
      }

      return result
      // return items.filter(row => needleAry.every(needle => Object.keys(row).some(key => newFilter(row[key], needle))))
      // foreach needle in our array cycle through the data (row[key]) in the current row looking for a match.
      //  .some will return true and exit the loop if it finds one it will return false if it doesnt
      //  .every will exit the loop if we dont find a match but will return true if all needles match
      //  .filter the rows on each match
    },
    close () {
      this.$store.getters.EventBus.$emit('sizeChange')
      this.dialog = false
      this.$emit('close')
    }
  }
}
</script>
<style>
  #attach {
    width: 50%
  }
</style>
