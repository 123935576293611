import { arrayRemove, arrayUnion } from 'vuex-easy-firestore'

const messUserTypeModule = {
  firestorePath: 'officeData/{officeId}/messUserType/{userType}/mess',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'messUserTypeModule',
  statePropName: 'data',
  namespaced: true, // automatically added
  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    getActiveMessageSorted: (state, getters) => {
      return Object.values(state.data).filter(mess => mess.isActive && !getters.isDeleteByMe(mess.id))
        .sort(function (a, b) {
          return b.created_at - a.created_at
        }) // your sort function
    },
    getItems: (state, getters) => {
      const items = getters.getActiveMessageSorted
      items.forEach(mess => {
        mess.isRead = getters.isReadByMe(mess.id)
        mess.module = 'messUserTypeModule'
      })
      return items
    },
    isReadByMe: (state, getters, rootGetters) => (messId) => {
      if (state.data[messId].readBy) {
        if (state.data[messId].readBy.indexOf(rootGetters.userId) < 0) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },
    isDeleteByMe: (state, getters, rootGetters) => (messId) => {
      if (state.data[messId].deleteBy) {
        if (state.data[messId].deleteBy.indexOf(rootGetters.userId) < 0) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    }
  },
  mutations: {
    setReadByMe (state, payload) {
      if (state.data[payload.messId].readBy) {
        this.dispatch('messUserTypeModule/set', {
          id: payload.messId,
          readBy: arrayUnion(payload.userId)
        })
      } else {
        this.dispatch('messUserTypeModule/set', {
          id: payload.messId,
          readBy: [payload.userId]
        })
      }
    },
    setDeleteByMe (state, payload) {
      if (state.data[payload.messId].readBy) {
        this.dispatch('messUserTypeModule/set', {
          id: payload.messId,
          deleteBy: arrayUnion(payload.userId)
        })
      } else {
        this.dispatch('messUserTypeModule/set', {
          id: payload.messId,
          deleteBy: [payload.userId]
        })
      }
    },
    setUndeleteByMe (state, payload) {
      this.dispatch('messUserTypeModule/set', {
        id: payload.messId,
        deleteBy: arrayRemove(payload.userId)
      })
    }
  },
  actions: {
    asReadMessage ({ commit, getters, rootGetters }, payload) {
      if (!getters.isReadByMe(payload)) {
        commit('setReadByMe', {
          messId: payload,
          userId: rootGetters.userId
        })
      }
    },
    asDeleteMessage ({ commit, getters, rootGetters }, payload) {
      if (!getters.isDeleteByMe(payload)) {
        commit('setDeleteByMe', {
          messId: payload,
          userId: rootGetters.userId
        })
      }
    },
    asUndeleteMessage ({ commit, getters, rootGetters }, payload) {
      if (!getters.isDeleteByMe(payload)) {
        commit('setUndeleteByMe', {
          messId: payload,
          userId: rootGetters.userId
        })
      }
    }
  }
}

export default messUserTypeModule
