<template fixed>
  <div class="home">
    <v-simple-table>
      <template v-slot:default>
        <thead class="black" v-if="showHeader">
          <tr :width="colWidth">
            <th v-for="item in statsShowArray" :key="item.id" class="text-center white--text subtitle-2">{{ item.name }}</th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-center" :width="colWidth">
            <td v-for="item in statsShowArray" :key="item.id" :class="$store.getColorText(textColorId) + ' button'">{{ item.detail }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  // stat: { app: 0, appP: 0, appV: 0, hou: 0, houP: 0, houV: 0, bus: 0, busP: 0, busV: 0, farm: 0, farmP: 0, farmV: 0 }
  props: ['stats', 'textColorId', 'showHeader', 'presetColsArray'],
  data () {
    return {}
  },
  computed: {
    showedCols () {
      if (this.presetColsArray && this.presetColsArray.length) {
        return {
          hou: this.presetColsArray.indexOf('Hou/Dom') > -1,
          app: this.presetColsArray.indexOf('Apt/App') > -1,
          bus: this.presetColsArray.indexOf('Bus/com') > -1,
          farm: this.presetColsArray.indexOf('Frm') > -1,
          tot: this.presetColsArray.indexOf('tot') > -1
        }
      } else {
        return {
          hou: this.stat.hou || this.stat.houV,
          app: this.stat.app || this.stat.appV,
          bus: this.stat.bus || this.stat.busV,
          farm: this.stat.farm || this.stat.farmV,
          tot: false
        }
      }
    },
    colWidth () {
      switch (this.statsShowArray.length) {
        case 1:
          return '100%'
        case 2:
          return '50%'
        case 3:
          return '33%'
        case 4:
          return '25%'
        default:
          return '100%'
      }
    },
    statsShowArray () {
      const statArray = []
      const stat = this.stats
      if (this.showedCols.hou) {
        statArray.push({ id: statArray.length, name: this.$t('common.sdom'), detail: stat.houP + '/' + stat.hou + '   (' + stat.houV + ')' })
      }
      if (this.showedCols.app) {
        statArray.push({ id: statArray.length, name: this.$t('common.sapp'), detail: stat.appP + '/' + stat.app + '   (' + stat.appV + ')' })
      }
      if (this.showedCols.bus) {
        statArray.push({ id: statArray.length, name: this.$t('common.sbus'), detail: stat.busP + '/' + stat.bus + '   (' + stat.busV + ')' })
      }
      if (this.showedCols.farm) {
        statArray.push({ id: statArray.length, name: this.$t('common.sfrm'), detail: stat.farmP + '/' + stat.farm + '   (' + stat.farmV + ')' })
      }
      if (this.showedCols.tot) {
        statArray.push({ id: statArray.length, name: this.$t('common.pdr'), detail: stat.totP + '/' + stat.tot + '   (' + stat.totV + ')' })
      }
      return statArray
    }
  }
}
</script>
