<template fixed>
  <div>
    <v-card-title class="grey my-n3">{{ $t('roadbook.label') }}</v-card-title>
    <!-- <v-card-text> -->
    <v-stepper
      v-model="travelModel"
      ref="stepper"
      vertical
      non-linear
      dense
      id="card-scroll"
      :height="$store.getters.contentHeight - 115"
      width="100%"
      class="overflow-y-auto"
    >
      <div ref="stepperdiv">
        <v-stepper-items v-for="(cStep, idx) in roadBook" :key="cStep.stepId + '-step'">
          <v-stepper-step
            dense
            :class="travelSelectedStepDone[cStep.stepId] ? 'green lighten-5' : 'grey lighten-3'"
            :step="String(cStep.roadBookPos)"
            :id="cStep.stepId"

            :color="travelSelectedStepDone[cStep.stepId] ? 'green': 'primary'"
            :ref="'step' + String(cStep.roadBookPos)"
            editable
          >
            <v-list-item dense>
              <v-list-item-action>
                <v-list-item-icon class="me-n5 ms-n2 mt-n4"><v-img contain :src="$store.getMapItemIconOfType(cStep.type)" width="50" height="50"/></v-list-item-icon>
              </v-list-item-action>
              <v-list-item-content three-line>
                <v-list-item-title v-text="getStepTitle1(cStep)"/>
                <v-list-item-title v-if="isStepTitle2(cStep)" v-text="getStepTitle2(cStep)"/>
                <v-list-item-title v-if="isStepTitle3(cStep)" v-text="getStepTitle3(cStep)"/>
              </v-list-item-content>
              <v-list-item-action v-if="travelSelectedStepDone[cStep.stepId]">
                <v-icon large color="green">mdi-check</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-content
            :step="String(cStep.roadBookPos)"
            width="100%"
            class="ms-n3 me-n10"
          >
            <stats-popup v-if="isShowStat(cStep)" class="mt-3 mb-3" block :pocItems="getStepItems(cStep)" showInfoButton></stats-popup>
            <v-card
              flat
              width="100%"
              ref="stepCard"
              color="grey lighten-3"
              class="overflow-y-auto overflow-x-hidden"
              :max-height="$store.getters.contentHeight - 430"
            >
              <travel-editor-nested :itemsPath="[]" :list="cStep.stepTree"></travel-editor-nested>
            </v-card>
            <v-btn
              v-if="travelStatusNextStep !== cStep.roadBookPos"
              color="primary"
              class="my-4"
              large
              dark
              block
              @click="setStep(cStep)"
            >
              <v-icon left>{{ travelSelectedStepDone[cStep.stepId] ? 'mdi-arrow-u-right-bottom' : 'mdi-arrow-down-bold-outline' }}</v-icon>
              {{ $t('common.' + (travelSelectedStepDone[cStep.stepId] ? 'redo' : 'do')) }}
            </v-btn>
            <div v-if="!travelSelectedStepDone[cStep.stepId]">
              <v-btn
                v-if="idx < travelSteps.length - 1"
                color="primary"
                class="my-4"
                large
                dark
                block
                @click="setStepComplete(cStep)"
              >
                <v-icon left>mdi-check-bold</v-icon>
                {{ $t('common.finished') }}
              </v-btn>
              <v-btn
                v-else
                color="primary"
                class="my-4"
                large
                block
                @click="resetSteps()"
              >
                {{ $t('common.restart') }}
              </v-btn>
            </div>
            <!-- <v-card-title class="mb-12" v-else>fin</v-card-title> -->
          </v-stepper-content>
        </v-stepper-items>
      </div>
    </v-stepper>
    <!-- </v-card-text> -->
  </div>
</template>

<script>
import travelEditorNested from '../../editor/PMT_TravelEditorNested.vue'
import ResizeObserver from 'resize-observer-polyfill'

export default {
  name: 'RoadBookDrawerItem',
  components: { travelEditorNested },
  props: ['mapMode', 'width', 'isShow'],
  data () {
    return {
      travelModel: null,
      ro: null
    }
  },
  computed: {
    travelStatusNextStep () {
      return this.$store.getters['routeDayDataModule/getMyTravelSelectedNextStep']
    },
    travelSelectedStepDone () {
      return this.$store.getters['routeDayDataModule/getMyTravelSelectedStepDone']
    },
    stepDetailWidth () {
      if (this.$refs.stepCard && this.$refs.stepCard.clientWidth) {
        return this.$refs.stepCard.clientWidth
      } else {
        return 300
      }
    },
    travelStatus () {
      return this.$store.getters['routeDayDataModule/getMyTravelSelectedStatus']
    },
    travelSteps () {
      if (this.roadBook.length) {
        return this.roadBook.map(x => {
          x.stepTree = this.generateStepTree(x)
          return x
        }).sort(this.$store.sortByPos)
      } else {
        return []
      }
    },
    roadBook () {
      return this.$store.getters['officeTravelModule/getRoadBook']
    },
    travelMapDetail () {
      // return this.$store.getters['officeTravelModule/getRoadBook']
      return this.$store.getters['officeTravelModule/getTravelMapDetailSelected']
    }
  },
  methods: {
    onResize () {
      // // console.log('trace resize')
      this.myScrollTo(this.travelModel)
    },
    resetSteps () {
      var colAr = []
      this.roadBook.forEach(step => {
        colAr = colAr.concat(step.colisArray.map(x => { return x.colisId }))
      })
      this.$store.dispatch('routeDayDataModule/resetMyTravelStepsStatus', { colisIdArray: colAr })
      // this.$store.dispatch('routeDayDataModule/updateMyTravelStatus', { isDone: {}, nextStep: this.getNextStepNotComplete({}) })
    },
    isShowStat (step) {
      switch (step.type) {
        case 'loop':
        case 'cmb-direct':
          return true
        default:
          return false
      }
    },
    getNextStepNotComplete (isDone) {
      let pos
      // // console.log(this.travelSteps)
      this.travelSteps.sort(this.$store.sortByRoadBookPos).forEach(step => {
        // // console.log('step ' + step.pos + ' = ' + String(this.$store.getters['routeDayDataModule/isItemDone'](step.stepId)))
        if (!pos && !isDone[step.stepId]) {
          pos = step.roadBookPos ? step.roadBookPos : step.pos
        }
      })
      if (pos) {
        return pos
      } else {
        return 1
      }
    },
    getStepAvatar (step) {
      switch (step.type) {
        case 'loop':
          return this.$store.getMapItemIconOfType('loop')
        case 'park-loop':
          return this.$store.getMapItemIconOfType('park-loop')
        case 'parcelLocker':
          return this.$store.getMapItemIconOfType('parcellocker')
        case 'greyBox':
          return this.$store.getMapItemIconOfType('greyBox')
        case 'cmb-direct':
          return this.$store.getMapItemIconOfType('cmb')
        default:
          return this.$store.getMapItemIconOfType('park-loop')
      }
    },
    getStepTitle1 (step) {
      switch (step.type) {
        case 'loop':
          return this.$t('common.deliveryLoop')
        case 'parcelLocker':
          return 'parcelLocker'
        case 'greyBox':
          return 'greyBox'
        case 'cmb-direct':
          // // console.log(step)
          var wpIdx = this.travelMapDetail.wp.map(x => { return x.wpId }).indexOf(step.fromWpId)
          var wp = this.travelMapDetail.wp[wpIdx]
          if (wp && wp.multiId) {
            return this.$t('pocitem.' + this.$store.state.officeItemMultiModule.data[wp.multiId].type)
          } else {
            return this.$t('wp.' + wp.type + '-title')
          }
        case 'colis-zone':
          return this.$t('travelItem.colis-zone')
        case 'colis-list':
          return this.$t('commmon.colis')
        case 'poc-list':
          return this.$t('commmon.poc-long')
        default:
          // // console.log(step)
          return 'title ' + step.type
      }
    },
    isStepTitle2 (step) {
      switch (step.type) {
        case 'loop':
        case 'parcelLocker':
        case 'greyBox':
        case 'colis-zone':
        case 'cmb-direct':
          return true
        default:
          return false
      }
    },
    getStepTitle2 (step) {
      switch (step.type) {
        case 'loop':
          return this.$t('common.from') + ': ' + this.getFromStep(step)
        case 'parcelLocker':
          return 'parcelLocker'
        case 'greyBox':
          return 'greyBox'
        case 'cmb-direct':
          var wpIdx = this.travelMapDetail.wp.map(x => { return x.wpId }).indexOf(step.fromWpId)
          var wp = this.travelMapDetail.wp[wpIdx]
          if (wp && wp.multiId) {
            if (this.$store.state.officeItemMultiModule.data[wp.multiId].name) {
              return this.$store.state.officeItemMultiModule.data[wp.multiId].name
            } else if (this.$store.state.officeItemMultiModule.data[wp.multiId].desc) {
              return this.$store.state.officeItemMultiModule.data[wp.multiId].desc
            } else {
              if (wp.name) {
                return wp.name
              } else {
                return null
              }
            }
          } else {
            if (wp.name) {
              return wp.name
            } else {
              return null
            }
          }
        case 'colis-zone':
          return step.colisArray.length + ' ' + this.$t('common.colis')
        default:
          return 'title2 ' + step.type
      }
    },
    isStepTitle3 (step) {
      switch (step.type) {
        case 'loop':
        case 'parcelLocker':
        case 'greyBox':
          return true
        case 'cmb-direct':
          return true
        default:
          return false
      }
    },
    getStepTitle3 (step) {
      switch (step.type) {
        case 'loop':
          return this.$t('common.to') + ': ' + this.getToStep(step)
        case 'parcelLocker':
          return 'parcelLocker'
        case 'greyBox':
          return 'greyBox'
        case 'cmb-direct':
          if (step.colisArray && step.colisArray.length) {
            return this.getStepItems(step).length + ' ' + this.$t('common.poc-long') + ', ' + step.colisArray.length + ' ' + this.$t('common.colis')
          } else {
            return this.getStepItems(step).length + ' ' + this.$t('common.poc-long')
          }
        default:
          return 'title ' + step.type
      }
    },
    setStep (step) {
      var tStatus = this.$store.getters['routeDayDataModule/getMyTravelSelectedStatus']
      tStatus.isDone[step.stepId] = false
      step.colisArray.forEach(colis => {
        // colis.isDone = false
        this.$store.commit('routeDayDataModule/setColisDone', { colisId: colis.colisId, isDone: false })
      })
      tStatus.nextStep = step.roadBookPos
      this.$store.dispatch('routeDayDataModule/updateMyTravelStatus', tStatus)
    },
    setStepComplete (step) {
      var tStatus = this.$store.getters['routeDayDataModule/getMyTravelSelectedStatus']
      // var isDone = this.travelSelectedStepDone
      tStatus.isDone[step.stepId] = true
      tStatus.nextStep = this.getNextStepNotComplete(tStatus.isDone)
      step.colisArray.forEach(colis => {
        // colis.isDone = true
        this.$store.commit('routeDayDataModule/setColisDone', { colisId: colis.colisId, isDone: true })
      })
      this.$store.dispatch('routeDayDataModule/updateMyTravelStatus', tStatus)
      this.travelModel = tStatus.nextStep
      // step.isDone = true
      // this.travelModel = String(Number(this.travelModel) + 1)
    },
    getFromStep (step) {
      const fItem = this.travelMapDetail.items.filter(x => x.stepId === step.stepId).sort(this.$store.sortByPos)[0]
      // const fItem = this.travelMapDetail.items.filter(x => x.id === step.fromItemId)[0]
      switch (fItem.type) {
        case 'poc':
          return this.$store.state.pocModule.data[fItem.id].pdrListAddress
        case 'greyBox':
          return this.$store.state.officeItemModule.data[fItem.greyBoxId].name
        default:
          return 'type a faire ' + fItem.type
      }
    },
    getToStep (step) {
      const fItem = this.travelMapDetail.items.filter(x => x.stepId === step.stepId).sort(this.$store.sortByPosDesc)[0]
      // const fItem = this.travelMapDetail.items.filter(x => x.id === step.fromItemId)[0]
      switch (fItem.type) {
        case 'poc':
          return this.$store.state.pocModule.data[fItem.id].pdrListAddress
        case 'greyBox':
          return this.$store.state.officeItemModule.data[fItem.id].name
        default:
          return 'type a faire ' + fItem.type
      }
    },
    getStepItemWidth () {
      // // console.log(this.$refs.stepCard.$el.clientWidth)
      return this.$refs.stepCard.clientWidth
    },
    getStepItems (step) {
      switch (step.type) {
        case 'cmb-direct':
          return this.travelMapDetail.items.filter(x => x.type === 'poc' && x.wpId === step.fromWpId)
        default:
          return this.travelMapDetail.items.filter(x => x.type === 'poc' && x.stepId === step.stepId)
      }
    },
    generateStepTree (step) {
      var stepItems
      // const tree = []
      switch (step.type) {
        case 'loop':
          stepItems = this.travelMapDetail.items.filter(x => x.isUse && x.stepId === step.stepId)
          return this.$store.generatePocItemTree(stepItems, ['code', 'multi'])
        case 'cmb-direct':
          stepItems = this.travelMapDetail.items.filter(x => x.wpId === step.fromWpId)

          if (step.colisArray && step.colisArray.length) {
            // console.log(step.colisArray)
            return [{
              type: 'colis-list',
              id: this.$store.getUniqueKey(),
              items: step.colisArray
            }].concat(this.$store.generatePocItemTree(stepItems, ['code']))
          } else { return this.$store.generatePocItemTree(stepItems, ['code']) }
          // return [{
          //   type: 'poc-list',
          //   id: this.$store.getUniqueKey(),
          //   items: this.$store.generatePocItemTree(stepItems, ['code'])
          // }, {
          //   type: 'colis-list',
          //   id: this.$store.getUniqueKey(),
          //   items: step.colisArray
          // }]
        case 'colis-zone':
          // return [{
          //   type: 'colis-list',
          //   id: this.$store.getUniqueKey(),
          //   items: step.colisArray
          // }]
          // // console.log(step)
          return step.colisArray
        default:
          return []
      }
    },
    myScrollTo (stepPos) {
      // const stepIdx = this.travelSteps.map(x => { return x.pos }).indexOf(Number(stepPos))
      // // console.log('goto -> ' + stepPos)
      if (this.isShow && this.$refs['step' + String(stepPos)]) {
        const target = this.$refs['step' + String(stepPos)][0]
        const options = { container: '#card-scroll', offset: 10 }
        if (target) {
          this.$nextTick(() => {
            this.$vuetify.goTo(target, options)
          })
        }
      }
    }
  },
  watch: {
    isShow (newValue) {
      // console.log('trace isShow = ' + newValue)
      if (newValue) {
        if (this.travelModel !== String(this.travelStatusNextStep)) {
          this.travelModel = String(this.travelStatusNextStep)
        } else {
          this.myScrollTo(this.travelModel)
        }
      }
    },
    travelStatusNextStep (newValue) {
      // // console.log('new user nextStep = ' + newValue)
      if (newValue && this.isShow) {
        this.travelModel = String(newValue)
      }
    },
    travelModel (newValue) {
      // this.myScrollTo(newValue)
      // // console.log('travelStatusNextStep = ' + this.travelStatusNextStep)
      // // console.log('travelModel newValue = ' + newValue)
    },
    travelStatus (newValue) {
      // // console.log(newValue)
    },
    travelSteps (newValue) {
      // // console.log(newValue)
    },
    roadBook (newValue) {
      // console.log(newValue)
    },
    travelSelectedStepDone (newValue) {
      // // console.log('new user isDone = ' + newValue)
      // // console.log(newValue)
    }
  },
  mounted () {
    if (ResizeObserver) {
      this.ro = new ResizeObserver(this.onResize).observe(this.$refs.stepperdiv)
    }
    if (this.travelModel !== String(this.travelStatusNextStep)) {
      this.travelModel = String(this.travelStatusNextStep)
    } else {
      this.myScrollTo(this.travelModel)
    }
    // console.log(this.travelSteps)
    // console.log(this.roadBook)
    // // console.log(this.$store.getters['routeDayDataModule/getMyTravelSelectedStatus'])
  },
  beforeDestroy () {
    if (this.ro) {
      this.ro.unobserve(this.$refs.tLine)
    }
  }
}
</script>
