<template>
  <div class="home overflow-hidden" :style="$store.getters.contentStyle">
    <v-card>
      <v-sheet class="overflow-y-auto overflow-x-hidden" :height="$store.getters.contentHeight" :max-height="$store.getters.contentHeight">
        <v-data-iterator
          fixed
          :hide-default-footer="showSearchParam"
          single-expand
          :custom-filter="customFilter"
          :sort-by="sortBy"
          :sort-desc="isAscending"
          :custom-sort="customSort"
          :search="routeSelected"
          :items="items"
          :items-per-page="30"
          :footer-props="{ 'items-per-page-options' : [15, 30, 50, 100, -1] }"
          @update:items-per-page="$store.dispatch('resize')"
        >
          <template v-slot:header>
            <v-app-bar
              dark
              color="primary"
              class="mb-1"
              :height="$store.getters.appBarHeight"
              extended
              :src="imgBar"
              >
              <template v-slot:img="{ props }">
                <v-img
                  v-bind="props"
                  contain
                  :gradient="$store.getters.getDayGradient"
                ></v-img>
              </template>
              <!-- <v-app-bar-nav-icon>
                <v-btn icon x-large @click="close">
                  <v-icon x-large>mdi-close</v-icon>
                </v-btn>
              </v-app-bar-nav-icon> -->
              <v-row no-gutters>
                <v-btn icon x-large @click="$emit('menu-item-selected', 'pmt-pm-tool')"><v-icon x-large>mdi-arrow-left-circle</v-icon></v-btn>
                <h3 class="mt-3">Menu</h3>
              </v-row>
              <v-spacer></v-spacer>
              <v-app-bar-nav-icon>
              <v-btn icon x-large @click="isAscending = !isAscending"><v-icon x-large>{{ isAscending ? 'mdi-sort-alphabetical-ascending' : 'mdi-sort-alphabetical-descending' }}</v-icon></v-btn>
              </v-app-bar-nav-icon>
              <!-- <v-app-bar-nav-icon>
                <v-btn icon @click="showSearchParam=!showSearchParam">
                  <v-icon x-large :color="showSearchParam ? 'green' : 'white'" >mdi-cogs</v-icon>
                </v-btn>
              </v-app-bar-nav-icon> -->
              <v-app-bar-nav-icon>
                <v-btn icon @click="showCreateReport = true">
                  <v-icon x-large>mdi-alert-plus</v-icon>
                </v-btn>
              </v-app-bar-nav-icon>

              <template v-slot:extension>
                <v-col cols="3" class="mb-n7">
                  <v-select
                    class="mt-7"
                    label="Type"
                    v-model="itemTypeSelected"
                    :items="itemList"
                  ></v-select>
                </v-col>
                <v-col cols="3" class="mb-n7">
                  <v-select
                    class="mt-7"
                    label="Status"
                    v-model="statusSelected"
                    :items="statusList"
                  ></v-select>
                </v-col>
                <v-col cols="3" class="mb-n6">
                  <v-select
                    class="mt-7"
                    label="Route"
                    v-model="routeSelected"
                    :items="routeList"
                  ></v-select>
                </v-col>
                <v-col cols="3" class="mb-n6">
                  <v-select
                    class="mt-7"
                    :label="$t('common.active')"
                    v-model="activeSelected"
                    :items="activeList"
                  ></v-select>
                </v-col>
              </template>
            </v-app-bar>
          </template>
          <template v-slot:default="{ items }">
            <v-card :max-height="iteratorHeight" :height="iteratorHeight" flat class="overflow-y-auto">
              <v-list>
                <v-list-item
                  v-for="item in items"
                  :key="item.id"
                  background-color="grey"
                  :class="item.active ? '' : 'red lighten-4'"
                  @click="editReportId = item.id"
                >
                  <v-list-item-avatar>
                    <!-- <v-avatar
                      size="32px"
                      tile
                    >
                      <img
                        :src="$store.getAlertPngIcon(item.type)"
                        :alt="$store.getAlertPngIcon()"
                      >
                    </v-avatar> -->
                    <v-icon :color="item.active ? 'red' : 'grey'">{{ $store.getAlertMdiIcon(item.type) }}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content two-line>
                    <v-list-item-title>
                      {{ getReportName(item) }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-row no-gutters>
                      {{ getRouteNameString(item.routeIdArray) }}
                      <v-spacer></v-spacer>
                      {{ $t('report-Status.' + item.status) }}
                      </v-row>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon class="ms-3" :color="item.proximityAlert && item.active ? 'success' : 'error'" v-text="item.active ? item.proximityAlert ? 'mdi-leak' : 'mdi-leak-off' : 'mdi-close-thick'"></v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
          </template>
        </v-data-iterator>
      </v-sheet>
    </v-card>
    <loading-popup v-if="showLoading" :title="loadingTitle" :subTitle="loadingsubTitle"></loading-popup>
    <pmt-create-report-popup @close="closeCreateReport" v-if="showCreateReport"></pmt-create-report-popup>
    <pmt-report-editor
      v-if="editReportId"
      :reportId="editReportId"
      @close="editReportId = null"
    ></pmt-report-editor>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isDataLoaded: true,
      showLoading: false,
      loadingTitle: this.$t('common.loading'),
      loadingsubTitle: '',
      selectPocId: null,
      showSearchParam: false,
      isAscending: true,
      type: null,
      searchMode: 'pdr',
      search: '',
      searchItems: this.$store.getters['pocModule/getPocOfSequenceSortedByDistanceSelected'],
      sortBy: this.$store.getters.getCurrentPosition ? 'dist' : 'name',
      editReportId: null,
      itemTypeSelected: 'all',
      routeSelected: 'all',
      activeSelected: 'all',
      statusSelected: 'all',
      showCreateReport: false
    }
  },
  methods: {
    customFilter (items, search) {
      if (search !== 'all') {
        return items.filter(val => {
          if (val.routeIdArray && val.routeIdArray.length) {
            return val.routeIdArray.indexOf(search) > -1
          } else {
            return true
          }
        })
      } else {
        return items
      }
    },
    customSort (items, index, isDescending) {
      // const mode = this.searchMode
      // const distMe = this.$store.getters.distanceOfMe
      // const sortBy = index[0]
      const isDesc = isDescending[0]

      switch (this.itemTypeSelected) {
        case 'all':
          return items.sort(function (a, b) {
            if (a.type === b.type) {
              if (isDesc) { return b.created_at - a.created_at } else { return a.created_at - b.created_at }
            } else {
              if (isDesc) { return b.type < a.type } else { return a.type < b.type }
            }
          })
        default:
          return items.sort(function (a, b) {
            if (a.type === b.type) {
              if (isDesc) { return b.created_at - a.created_at } else { return a.created_at - b.created_at }
            } else {
              if (isDesc) { return b.type < a.type } else { return a.type < b.type }
            }
          })
      }
    },
    customGroup (items, groupBy, groupDesc) {
      // // console.log('trace test')
      // // console.log(groupBy)
      // // console.log(groupDesc)
    },
    deleteRoute (data) {
      this.editReportId = null
      if (data.pocArray.length) {
        this.$store.dispatch('pocModule/deleteBatch', data.pocArray.map(x => { return x.id }))
      }
      if (data.pocArray.length) {
        this.$store.dispatch('placeModule/deleteBatch', data.placeArray.map(x => { return x.id }))
      }
      if (data.routeId) {
        this.$store.dispatch('routeModule/delete', data.routeId)
      }
    },
    closeCreateReport () {
      this.showCreateReport = false
      this.$store.getters.EventBus.$emit('sizeChange')
    },
    searchPosPlaceId (pos) {
      const google = this.$store.getters.getGoogle
      const geocoder = new google.maps.Geocoder()
      const store = this.$store
      const pocId = this.selectPocId
      geocoder.geocode({ location: pos.position }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          const pocUpdate = {
            id: pocId,
            place_id: results[0].place_id,
            formatted_address: results[0].formatted_address,
            homeLocation: pos.position,
            googleFound: true,
            isActive: true
          }
          store.dispatch('pocModule/set', pocUpdate)
        } else {
          // there were no coordinates found for the address entered (or the address was not found)
          alert('Could not find your address ')
        }
      })
    },
    getRouteNameString (routeIdArray) {
      return routeIdArray.map(x => { return this.$store.state.routeModule.data[x].name }).sort(function (a, b) { return a - b }).toString()
    },
    getReportName (report) {
      const ownerIdx = report.pocList.map(x => { return x.isOwner }).indexOf(true)
      if (ownerIdx < 0) {
        return this.$t('pmtcreatereportpopup.title' + report.type)
      } else {
        if (report.pocList[ownerIdx].name) {
          return report.pocList[ownerIdx].name
        } else if (report.pocList[ownerIdx].address) {
          return report.pocList[ownerIdx].address
        } else {
          return this.$t('pmtcreatereportpopup.title' + report.type)
        }
      }
    }
  },
  computed: {
    iteratorHeight () {
      return Math.round(this.$store.getters.contentHeight - (149 + this.$store.getters.appBarHeight))
    },
    reportItem () {
      if (this.$store.getters.isMixComite) {
        return this.$store.getters['reportModule/getAllReport']
      } else {
        return this.$store.getters['reportModule/getAllReport'].filter(x => x.created_by === this.$store.getters.userId)
      }
    },
    itemActivefiltered () {
      switch (this.activeSelected) {
        case 'all':
          return this.reportItem
        case 'active':
          return this.reportItem.filter(x => x.active)
        case 'inactive':
          return this.reportItem.filter(x => !x.active)
        default:
          return this.reportItem
      }
    },
    statusItems () {
      switch (this.statusSelected) {
        case 'all':
          return this.itemActivefiltered
        default:
          return this.itemActivefiltered.filter(x => x.status === this.statusSelected)
      }
    },
    items () {
      switch (this.itemTypeSelected) {
        case 'all':
          return this.statusItems
        default:
          return this.statusItems.filter(x => x.type === this.itemTypeSelected)
      }
    },
    itemList () {
      return [
        { text: this.$t('common.all'), value: 'all' },
        { text: this.$t('createreport.dog'), value: 'dog' },
        { text: this.$t('createreport.ice'), value: 'ice' },
        { text: this.$t('createreport.fullbox'), value: 'fullbox' },
        { text: this.$t('createreport.brokenstep'), value: 'brokenstep' },
        { text: this.$t('createreport.other'), value: 'other' }
      ]
    },
    routeList () {
      return [].concat(
        [{ text: this.$t('common.all'), value: 'all' }],
        this.$store.getters['routeModule/getRouteIdItems']
      )
    },
    statusList () {
      return [
        { text: this.$t('common.all'), value: 'all' },
        { text: this.$t('report-Status.1'), value: 1 },
        { text: this.$t('report-Status.2'), value: 2 },
        { text: this.$t('report-Status.3'), value: 3 },
        { text: this.$t('report-Status.4'), value: 4 },
        { text: this.$t('report-Status.5'), value: 5 }
      ]
    },
    activeList () {
      return [
        { text: this.$t('common.all'), value: 'all' },
        { text: this.$t('common.active'), value: 'active' },
        { text: this.$t('common.inactive'), value: 'inactive' }
      ]
    },
    imgBar () {
      return this.$store.getAlertPngIcon(this.itemTypeSelected)
    }
  },
  mounted () {
    this.isDataLoaded = true
  },
  destroyed () {
    // this.$store.dispatch('routeModule/closeDBChannel', { clearModule: true })
    // this.$store.dispatch('routeModule/setPathVars', { officeId: this.$store.state.userLoggedModule.data.officeselected })
  }
}
</script>
