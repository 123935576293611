const taskRouteModule = {
  firestorePath: 'officeData/{officeId}/taskRoute',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'taskRouteModule',
  statePropName: 'data',
  namespaced: true, // automatically added

  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    getRouteTask: (state) => {
      return Object.values(state.data).filter(task => task.isActive)
    },
    getMyRouteTask: (state, getters, rootState, rootGetters) => {
      return Object.values(state.data).filter(task => task.isActive)
    }
  },
  mutations: {},
  actions: {}
}

export default taskRouteModule
