<template>
  <div class="home overflow-hidden" :style="$store.getters.contentStyle">
    <v-card>
      <v-sheet class="overflow-y-auto overflow-x-hidden" :height="$store.getters.contentHeight" :max-height="$store.getters.contentHeight">
        <v-data-iterator
          fixed
          :hide-default-footer="showSearchParam"
          single-expand
          :custom-filter="customFilter"
          :sort-by="sortBy"
          :sort-desc="isAscending"
          :custom-sort="customSort"
          :search="routeSelected"
          :items="items"
          :items-per-page="30"
          :footer-props="{ 'items-per-page-options' : [15, 30, 50, 100, -1] }"
          @update:items-per-page="$store.dispatch('resize')"
        >
          <template v-slot:header>
            <v-app-bar
              dark
              color="primary"
              class="mb-1"
              :height="$store.getters.appBarHeight"
              extended
              :src="imgBar"
              >
              <template v-slot:img="{ props }">
                <v-img
                  v-bind="props"
                  contain
                  :gradient="$store.getters.getDayGradient"
                ></v-img>
              </template>
              <!-- <v-app-bar-nav-icon>
                <v-btn icon x-large @click="close">
                  <v-icon x-large>mdi-close</v-icon>
                </v-btn>
              </v-app-bar-nav-icon> -->
              <v-row no-gutters>
                <v-btn icon x-large @click="$emit('menu-item-selected', 'pmt-pm-tool')"><v-icon x-large>mdi-arrow-left-circle</v-icon></v-btn>
                <h3 class="mt-3">Menu</h3>
              </v-row>
              <v-spacer></v-spacer>
              <v-app-bar-nav-icon>
              <v-btn icon x-large @click="isAscending = !isAscending"><v-icon x-large>{{ isAscending ? 'mdi-sort-alphabetical-ascending' : 'mdi-sort-alphabetical-descending' }}</v-icon></v-btn>
              </v-app-bar-nav-icon>
              <!-- <v-app-bar-nav-icon>
                <v-btn icon @click="showSearchParam=!showSearchParam">
                  <v-icon x-large :color="showSearchParam ? 'green' : 'white'" >mdi-cogs</v-icon>
                </v-btn>
              </v-app-bar-nav-icon> -->
              <v-app-bar-nav-icon>
                <v-btn icon @click="createTravel = true">
                  <v-icon x-large :color="showSearchParam ? 'green' : 'white'" >mdi-plus</v-icon>
                </v-btn>
              </v-app-bar-nav-icon>
              <template v-slot:extension>
                <v-spacer></v-spacer>
                <v-col cols="6">
                  <v-select
                    v-model="itemTypeSelected"
                    :items="itemList"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="routeSelected"
                    :items="routeList"
                  ></v-select>
                </v-col>
                <!-- <v-text-field
                  v-show="!showSearchParam"
                  v-model="search"
                  clearable
                  flat
                  solo-inverted
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  @blur="$store.dispatch('resize')"
                  :label="$t('dataIterator.searchLabel')"
                ></v-text-field> -->
                <p v-show="showSearchParam" class="title">{{ $t('common.searchOption') }}</p>
                <v-spacer></v-spacer>
              </template>
            </v-app-bar>
          </template>
          <template v-slot:default="{ items }">
            <v-card :max-height="iteratorHeight" :height="iteratorHeight" flat class="overflow-y-auto">
              <v-list nav>
                <v-list-item
                  v-for="item in items"
                  :key="item.id"
                  class="grey lighten-3"
                >
                  <v-list-item-avatar>
                    <v-avatar
                      size="32px"
                      tile
                    >
                      <img
                        :src="$store.getMarkerIconSpec($store.getters.getGoogle, { type: item.transportType }).url"
                        alt="item"
                      >
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-icon v-if="item.isOfficial" color="yellow" class="ms-n3">mdi-star</v-icon>
                  <v-list-item-content align="center" three-line>
                    <v-list-item-title class="font-weight-bold">{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle class="font-weight-medium">{{ $store.state.routeModule.data[item.routeId].name }} - {{ getPartName(item.part) }}</v-list-item-subtitle>
                    <v-list-item-subtitle class="font-weight-medium">{{ getTransportTypeName(item.transportType) + (item.isOfficial ? ' (Officiel)' : '') }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="canDeleteItem(item)">
                    <v-row no-gutters>
                    <v-btn icon large @click="editTravelId = item.id" v-if="canEditItem(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                    <v-btn icon large @click="deleteItem(item)" v-if="canDeleteItem(item)"><v-icon color="red">mdi-delete</v-icon></v-btn>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
          </template>
        </v-data-iterator>
      </v-sheet>
    </v-card>
    <loading-popup v-if="showLoading" :title="loadingTitle" :subTitle="loadingsubTitle"></loading-popup>
    <pmt-position-map-set
      v-if="showGetPosition"
      :param="{ title: $store.state.pocModule.data[selectPocId].pdrListAddress, icon: $store.getAlertPngIcon('house'), getDistance: false, isCreateMode: true}"
      @return-close="showGetPosition = false"
      @return-check="setPosition($event)"
    />
    <pmt-travel-new-editor
      v-if="createTravel"
      @close="createTravel = false"
      @create-close="createTravel = null; editTravelId = $event"
    ></pmt-travel-new-editor>
    <pmt-travel-editor
      v-if="editTravelId"
      :travelId="editTravelId"
      @close="editTravelId = null"
    ></pmt-travel-editor>
    <confirm-popup
      v-if="showConfirm"
      @cancel="cancelConfirm($event)"
      @confirm="areConfirm($event)"
      :title="showConfirm.title"
      :from="showConfirm.from"
      :to="showConfirm.to"
      :item="showConfirm.item"
      :param="{ mode: showConfirm.mode, text: showConfirm.subtitle }"
    ></confirm-popup>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isDataLoaded: true,
      showLoading: false,
      showConfirm: null,
      showGetPosition: false,
      loadingTitle: this.$t('common.loading'),
      loadingsubTitle: '',
      selectPocId: null,
      showSearchParam: false,
      isAscending: true,
      type: null,
      searchMode: 'pdr',
      search: '',
      searchItems: this.$store.getters['pocModule/getPocOfSequenceSortedByDistanceSelected'],
      sortBy: this.$store.getters.getCurrentPosition ? 'dist' : 'name',
      editTravelId: null,
      createTravel: false,
      itemTypeSelected: 'all',
      routeSelected: this.$store.getters.getSelectedRouteId ? this.$store.getters.getSelectedRouteId : 'all'
    }
  },
  methods: {
    areConfirm (item) {
      this.showConfirm = null
      switch (item.confirmType) {
        case 'deleteItem':
          this.$store.dispatch('officeTravelModule/delete', item.item.id)
          break
        default:
          break
      }
    },
    cancelConfirm (item) {
      switch (item.confirmType) {
        case 'deleteItem':
          this.showConfirm = null
          break
        default:
          break
      }
    },
    deleteItem (item) {
      this.showConfirm = {
        title: this.$t('confirm-mess.deleteTravel'),
        subtitle: this.$t('confirm-mess.deleteTravelSubTitle'),
        from: null,
        to: null,
        item: {
          confirmType: 'deleteItem',
          item: item
        },
        mode: 'standard'
      }
    },
    canEditItem (item) {
      if (item.created_by === this.$store.getters.userId) {
        return true
      } else if (this.$store.isLocalAdmin) {
        return true
      } else if (this.$store.isSupervisorAccess) {
        return true
      } else {
        return false
      }
    },
    canDeleteItem (item) {
      if (item.created_by === this.$store.getters.userId) {
        return true
      } else if (this.$store.isLocalAdmin) {
        return true
      } else {
        return false
      }
    },
    getTransportTypeName (type) {
      switch (type) {
        case 'taxi':
          return 'Taxi'
        case 'truck':
          return this.$t('common.vehicle')
        default:
          return this.$t('common.vehicle')
      }
    },
    getPartName (part) {
      switch (part) {
        case 'all':
          return this.$t('common.full')
        case '1':
          return this.$t('common.orange')
        case '2':
          return this.$t('common.pink')
        case '3':
          return this.$t('common.blue')
        default:
          return this.$t('common.full')
      }
    },
    customFilter (items, search) {
      if (search !== 'all') {
        return items.filter(val => val.routeId === search)
      } else {
        return items
      }
    },
    customSort (items, index, isDescending) {
      // const mode = this.searchMode
      // const distMe = this.$store.getters.distanceOfMe
      // const sortBy = index[0]
      const isDesc = isDescending[0]

      switch (this.itemTypeSelected) {
        case 'all':
          return items.sort(function (a, b) {
            if (a.type === b.type) {
              if (a.group === b.group) {
                if (a.name === b.name) {
                  if (isDesc) { return b.created_at - a.created_at } else { return a.created_at - b.created_at }
                } else {
                  if (isDesc) { return b.name < a.name } else { return a.name < b.name }
                }
              } else {
                if (isDesc) { return b.group < a.group } else { return a.group < b.group }
              }
            } else {
              if (isDesc) { return b.type < a.type } else { return a.type < b.type }
              // return a.type < b.type
            }
          })
        case 'redBoxMarker':
          return items.sort(function (a, b) {
            if (isDesc) { return b.created_at - a.created_at } else { return a.created_at - b.created_at }
          })
        case 'greyBoxMarker':
          return items.sort(function (a, b) {
            if (a.group === b.group) {
              if (isDesc) { return b.name < a.name } else { return a.name < b.name }
              // return a.name < b.name
            } else {
              if (isDesc) { return b.group < a.group } else { return a.group < b.group }
              // return a.group < b.group
            }
          })
        case 'toiletMarker':
          return items.sort(function (a, b) {
            if (isDesc) { return b.created_at - a.created_at } else { return a.created_at - b.created_at }
          })
        case 'parkingMarker':
          return items.sort(function (a, b) {
            if (isDesc) { return b.created_at - a.created_at } else { return a.created_at - b.created_at }
            // return a.created_at - b.created_at
          })
        default:
          return items.sort(function (a, b) {
            if (a.type === b.type) {
              if (isDesc) { return b.created_at - a.created_at } else { return a.created_at - b.created_at }
            } else {
              if (isDesc) { return b.type < a.type } else { return a.type < b.type }
            }
          })
      }
    },
    deleteRoute (data) {
      this.editTravelId = null
      if (data.pocArray.length) {
        this.$store.dispatch('pocModule/deleteBatch', data.pocArray.map(x => { return x.id }))
      }
      if (data.pocArray.length) {
        this.$store.dispatch('placeModule/deleteBatch', data.placeArray.map(x => { return x.id }))
      }
      if (data.routeId) {
        this.$store.dispatch('routeModule/delete', data.routeId)
      }
    },
    searchPosPlaceId (pos) {
      const google = this.$store.getters.getGoogle
      const geocoder = new google.maps.Geocoder()
      const store = this.$store
      const pocId = this.selectPocId
      geocoder.geocode({ location: pos.position }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          const pocUpdate = {
            id: pocId,
            place_id: results[0].place_id,
            formatted_address: results[0].formatted_address,
            homeLocation: pos.position,
            googleFound: true,
            isActive: true
          }
          store.dispatch('pocModule/set', pocUpdate)
        } else {
          // there were no coordinates found for the address entered (or the address was not found)
          alert('Could not find your address ')
        }
      })
    },
    selected (item) {
      this.selectPocId = item.value.id
      this.showGetPosition = true
    },
    setPosition (pos) {
      this.showGetPosition = false
      this.searchPosPlaceId(pos)
    }
  },
  computed: {
    refSelectedRouteId () {
      return this.$store.getters.getSelectedRouteId
    },
    iteratorHeight () {
      return Math.round(this.$store.getters.contentHeight - (149 + this.$store.getters.appBarHeight))
    },
    items () {
      const travel = this.$store.getters['officeTravelModule/getAllTravel']
      if (this.itemTypeSelected === 'all') {
        return travel
      } else {
        return travel.filter(x => x.transportType === this.itemTypeSelected)
      }
    },
    itemList () {
      return [
        { text: this.$t('common.all'), value: 'all' },
        { text: 'Taxi', value: 'taxi' },
        { text: this.$t('common.vehicle'), value: 'truck' }
      ]
    },
    routeList () {
      return [].concat(
        [{ text: this.$t('common.all'), value: 'all' }],
        this.$store.getters['routeModule/getRouteIdItems']
      )
    },
    imgBar () {
      if (this.itemTypeSelected !== 'all') {
        return this.$store.getMarkerIconSpec(this.$store.getters.getGoogle, { type: this.itemTypeSelected }).url
      } else {
        return require('@/assets/Icon/MenuIcon/logo_postes_canada.png')
      }
    }
  },
  watch: {
    refSelectedRouteId (newValue) {
      if (newValue) {
        this.routeSelected = newValue
      } else {
        this.routeSelected = 'all'
      }
    }
  },
  mounted () {
    this.isDataLoaded = true
  },
  destroyed () {
    // this.$store.dispatch('routeModule/closeDBChannel', { clearModule: true })
    // this.$store.dispatch('routeModule/setPathVars', { officeId: this.$store.state.userLoggedModule.data.officeselected })
  }
}
</script>
