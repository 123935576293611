const travelItemPlugin = store => {
  store.getTravelItemColorId = function (item) {
    const itemColorArray = []
    function findItemsColor (i) {
      if (i.items && i.items.length) {
        for (let x = 0; x < i.items.length; x++) {
          findItemsColor(i.items[x])
        }
      } else {
        if (i.colorId && itemColorArray.indexOf(i.colorId) < 0) {
          itemColorArray.push(i.colorId)
        }
      }
    }
    findItemsColor(item)
    if (itemColorArray.length !== 1) {
      return 0
    } else {
      return itemColorArray[0]
    }
  }
  store.getTravelItemColor = function (item) {
    return this.getColorName(store.getTravelItemColorId(item))
  }
  store.getTravelStepItemsCodeLine = function (stepItems) {
    const line = []
    let curLine = {
      colorId: null,
      codeId: null,
      posArray: []
    }
    stepItems.forEach(item => {
      if (item.codeId && item.codeId !== curLine.codeId) {
        if (curLine.codeId) {
          line.push(curLine)
          curLine = {
            colorId: item.colorId ? item.colorId : null,
            codeId: item.codeId,
            posArray: []
          }
        } else {
          curLine.codeId = item.codeId
        }
      }
      if (!curLine.colorId && item.colorId) { curLine.colorId = item.colorId }
      curLine.posArray.push(item.markerInfo.position)
    })
    return line
  }
  store.getTravelStepItemsColorLine = function (stepItems) {
    const line = []
    let curLine = {
      colorId: null,
      posArray: []
    }
    stepItems.forEach(item => {
      if (item.colorId && item.colorId !== curLine.colorId) {
        if (curLine.colorId) {
          line.push(curLine)
          line.push({
            colorId: 0,
            posArray: [curLine.posArray[curLine.posArray.length - 1], item.markerInfo.position]
          })
          curLine = {
            colorId: item.colorId ? item.colorId : null,
            posArray: []
          }
        } else {
          curLine.colorId = item.colorId
        }
      }
      // if (!curLine.colorId && item.colorId) { curLine.colorId = item.colorId }
      curLine.posArray.push(item.markerInfo.position)
    })
    line.push(curLine)
    return line
  }
  store.getTravelItemCardColor = function (item) {
    switch (item.type) {
      case 'color':
        return item.isOpen ? store.getColorName(item.colorId) + ' lighten-2' : store.getColorName(item.colorId)
      case 'cmb-direct':
      case 'colis-zone':
      case 'sector':
        return (Number(store.getTravelItemColorId(item)) ? this.getTravelItemColor(item) : 'grey') + ' lighten-4'
      case 'street':
      case 'wp':
        return (Number(store.getTravelItemColorId(item)) > 0 ? this.getTravelItemColor(item) : 'grey') + ' lighten-3'
      case 'code':
        // return store.getColorName(item.colorId) + ' lighten-4'
        return item.isOpen ? this.getTravelItemColor(item) + ' lighten-4' : ''
      case 'greyBox':
        return 'grey lighten-3'
      case 'redBox':
        return 'red lighten-3'
      case 'parcelLocker':
        return 'grey lighten-3'
      case 'taxi-start':
      case 'taxi-finish':
      case 'taxi-direct':
        return 'green lighten-4'
      case 'multi':
        return item.isOpen ? this.getTravelItemColor(item) + ' lighten-2' : null
      default:
        return null
    }
  }
  store.generateStepsFromTaxiTravelItems = function (items) {
    const steps = []
    var stepPos = 1
    var lastSector
    const itemsSorted = items.sort(store.sortByPos)
    for (let i = 0; i < itemsSorted.length; i++) {
      const item = itemsSorted[i]
      if (item.type === 'poc') {
        if (lastSector !== item.sectorId) {
          steps.push({
            stepType: 'loop',
            stepId: store.getUniqueKey(),
            stepPos: stepPos,
            complete: false,
            sectorId: item.sectorId
          })
          lastSector = item.sectorId
          stepPos++
        }
      } else if (item.type === 'greyBox') {
        steps.push({
          stepType: 'greyBox',
          stepId: store.getUniqueKey(),
          stepPos: stepPos,
          complete: false,
          itemId: item.id
        })
        stepPos++
      } else if (item.type === 'parcelLocker') {
        steps.push({
          stepType: 'parcelLocker',
          stepId: store.getUniqueKey(),
          stepPos: stepPos,
          complete: false,
          itemId: item.id
        })
        stepPos++
      }
    }
    return steps
  }
  store.generateStepsFromTruckTravelItems = function (items) {
    const steps = []
    // var stepPos = 1
    // var stepIdx = 0
    // var lastSector
    // const itemsSorted = items.sort(store.sortByPos)
    // for (let i = 0; i < itemsSorted.length; i++) {
    //   const item = itemsSorted[i]
    //   if (item.type === 'poc') {
    //     if (lastSector !== item.sectorId) {
    //       steps.push({
    //         stepType: 'loop',
    //         stepId: store.getUniqueKey(),
    //         stepPos: stepPos,
    //         sectorId: item.sectorId,
    //         fromItem: i > 0 ? itemsSorted[i - 1].id : null,
    //         toItem: i < itemsSorted.length ? itemsSorted[i + 1].id : null
    //       })
    //       lastSector = item.sectorId
    //       stepPos++
    //       stepIdx = steps.length - 1
    //     } else {
    //       steps[stepIdx].toItem = (i < itemsSorted.length ? itemsSorted[i + 1].id : null)
    //     }
    //   } else if (item.type === 'greyBox') {
    //     steps.push({
    //       stepType: 'charge',
    //       stepId: store.getUniqueKey(),
    //       stepPos: stepPos,
    //       itemId: item.id
    //     })
    //     stepPos++
    //   } else if (item.type === 'parcelLocker') {
    //     steps.push({
    //       stepType: 'lever',
    //       stepId: store.getUniqueKey(),
    //       stepPos: stepPos,
    //       itemId: item.id
    //     })
    //     stepPos++
    //   }
    // }
    return steps
  }
  store.generateStepsTravelItems = function (travelDetail) {
    if (travelDetail.transportType === 'taxi') {
      return store.generateStepsFromTaxiTravelItems(travelDetail.items)
    } else if (travelDetail.transportType === 'truck') {
      return store.generateStepsFromTruckTravelItems(travelDetail.items)
    } else {
      return []
    }
  }
  store.generateRoadBook = function (td) {
    const rb = []
    return rb
  }
  store.generateTravelTree = function (travelInfo, group) {
    const tree = []
    var stepPath
    var wpIdx
    const travelSteps = travelInfo.steps.sort(store.sortByPos)
    const tpTypeArray = []
    let currentCodeId = null
    // let currentWpId = travelInfo.travelWayPoint.length ? travelInfo.travelWayPoint[0].wpId : null
    let currentCp = null
    // const wpUsedArray = []
    let currentStreet = null
    let currentMulti = null
    // let wpIdx = null
    let cpIdx = null
    let codeIdx = null
    let streetIdx = null
    let multiIdx = null
    const showCodeGroup = true
    const showMultiGroup = true
    const showStreetGroup = false
    const showCpGroup = false
    function initCurrent () {
      currentStreet = null
      currentCodeId = null
      currentCp = null
      currentMulti = null
      cpIdx = null
      codeIdx = null
      streetIdx = null
      multiIdx = null
    }
    // console.log(travelSteps)
    for (let i = 0; i < travelSteps.length; i++) {
      initCurrent()
      const step = travelSteps[i]
      const stepItems = travelInfo.items.filter(x => x.stepId === step.stepId).sort(store.sortByPos)
      // // console.log('type -> ' + step.type)
      switch (step.type) {
        case 'loop':
          // console.log(step)
          if (step.fromItemId && ((tree.length && tree[tree.length - 1].id !== step.fromItemId) || !tree.length)) {
            var fromIdx = travelInfo.items.map(x => { return x.id }).indexOf(step.fromItemId)
            if (fromIdx > -1) { tree.push(travelInfo.items[fromIdx]) }
          }
          if (step.fromWpId) {
            wpIdx = travelInfo.travelWayPoint.map(x => { return x.wpId }).indexOf(step.fromWpId)
            tree.push({
              type: 'wp',
              id: store.getUniqueKey(),
              pos: step.pos,
              wpId: step.fromWpId,
              wp: travelInfo.travelWayPoint[wpIdx],
              stepId: step.stepId,
              items: []
            })
          } else {
            tree.push({
              type: 'step',
              id: store.getUniqueKey(),
              pos: step.pos,
              stepId: step.stepId,
              items: []
            })
          }
          stepPath = tree[tree.length - 1].items
          for (let y = 0; y < stepItems.length; y++) {
            const item = stepItems[y]
            if (item.type === 'poc') {
              const poc = store.state.pocModule.data[item.id]
              // item.stepId = stepId
              if (tpTypeArray.indexOf(poc.tpType) < 0) { tpTypeArray.push(poc.tpType) }
              let pocPath = stepPath

              // if (!item.wpId) { item.wpId = currentWpId }
              // if (String(currentWpId) !== String(item.wpId) || wpIdx === null) {
              //   const wId = item.wpId ? item.wpId : currentWpId
              //   wpUsedArray.push(wId)
              //   pocPath.push({
              //     type: 'wp',
              //     id: store.getUniqueKey(),
              //     wpId: wId,
              //     wp: travelInfo.travelWayPoint[travelInfo.travelWayPoint.map(x => { return x.wpId }).indexOf(wId)],
              //     items: []
              //   })
              //   currentWpId = wId
              //   currentCodeId = null
              //   currentCp = null
              //   currentStreet = null
              //   currentMulti = null
              //   wpIdx = pocPath.length - 1
              // }
              // // console.log(item)
              // pocPath = pocPath[wpIdx].items
              if (showCodeGroup && item.codeId) {
                if (currentCodeId !== item.codeId) {
                  pocPath.push({
                    type: 'code',
                    id: store.getUniqueKey(),
                    codeId: item.codeId,
                    codeName: item.codeName,
                    colorId: item.colorId,
                    items: []
                  })
                  currentCodeId = item.codeId
                  currentCp = null
                  currentStreet = null
                  currentMulti = null
                  codeIdx = pocPath.length - 1
                }
                pocPath = pocPath[codeIdx].items
              }
              if (showStreetGroup) {
                const street = poc.pdrListAddress.replace(poc.pdrListAddress.split(' ')[0], '').trim()
                if (currentStreet !== street) {
                  pocPath.push({
                    type: 'street',
                    id: store.getUniqueKey(),
                    street: street,
                    items: []
                  })
                  currentStreet = street
                  currentCp = null
                  currentMulti = null
                  streetIdx = pocPath.length - 1
                }
                pocPath = pocPath[streetIdx].items
              }
              if (showCpGroup) {
                if (currentCp !== poc.pdrListPostalCode) {
                  pocPath.push({
                    type: 'cp',
                    id: store.getUniqueKey(),
                    cp: poc.pdrListPostalCode,
                    colorId: item.colorId,
                    items: []
                  })
                  currentCp = poc.pdrListPostalCode
                  currentMulti = null
                  cpIdx = pocPath.length - 1
                }
                pocPath = pocPath[cpIdx].items
              }
              if (showMultiGroup) {
                const multiId = poc.mapItems[0].officeItemMultiId ? poc.mapItems[0].officeItemMultiId : null
                if (multiId) {
                  if (currentMulti !== multiId) {
                    pocPath.push({
                      type: 'multi',
                      multiType: poc.mapItems[0].type,
                      id: store.getUniqueKey(),
                      multiId: multiId,
                      colorId: item.colorId,
                      items: []
                    })
                    currentMulti = multiId
                    multiIdx = pocPath.length - 1
                  }
                  pocPath = pocPath[multiIdx].items
                } else {
                  if (currentMulti) {
                    // currentSectorId = null
                    // currentCodeId = null
                    // currentCp = null
                    // currentStreet = null
                  }
                  currentMulti = null
                }
              }
              pocPath.push(item)
            } else {
              if (item.type === 'greyBox') {
                tree.push(item)
                // currentColorId = null
                currentCodeId = null
              } else if (item.type === 'parcelLocker') {
                tree.push(item)
                // currentColorId = null
                currentCodeId = null
              } else {
                // console.log('trace a traiter type = ' + item.type)
              }
            }
          }
          if (step.toItemId) {
            var toIdx = travelInfo.items.map(x => { return x.id }).indexOf(step.toItemId)
            if (toIdx > -1) { tree.push(travelInfo.items[toIdx]) }
          }
          break
        case 'cmb-direct':
          // // console.log(step)
          if (step.fromWpId) {
            wpIdx = travelInfo.travelWayPoint.map(x => { return x.wpId }).indexOf(step.fromWpId)
            if (wpIdx > -1) {
              tree.push({
                type: 'cmb-direct',
                id: store.getUniqueKey(),
                wpId: step.fromWpId,
                pos: step.pos,
                wp: travelInfo.travelWayPoint[wpIdx],
                items: []
              })
              stepPath = tree[tree.length - 1].items
              var wpItems = travelInfo.items.filter(x => x.wpId === step.fromWpId).sort(store.sortByPos)
              for (let y = 0; y < wpItems.length; y++) {
                const item = wpItems[y]
                item.moveLock = true
                if (item.type === 'poc') {
                  const poc = store.state.pocModule.data[item.id]
                  if (tpTypeArray.indexOf(poc.tpType) < 0) { tpTypeArray.push(poc.tpType) }
                  let pocPath = stepPath
                  // if (showStreetGroup) {
                  const street = poc.pdrListAddress.replace(poc.pdrListAddress.split(' ')[0], '').trim()
                  if (currentStreet !== street) {
                    pocPath.push({
                      type: 'street',
                      id: store.getUniqueKey(),
                      street: street,
                      moveLock: true,
                      items: []
                    })
                    currentStreet = street
                    currentCp = null
                    currentMulti = null
                    streetIdx = pocPath.length - 1
                  }
                  pocPath = pocPath[streetIdx].items
                  // }
                  if (showCpGroup) {
                    if (currentCp !== poc.pdrListPostalCode) {
                      pocPath.push({
                        type: 'cp',
                        id: store.getUniqueKey(),
                        cp: poc.pdrListPostalCode,
                        colorId: item.colorId,
                        items: []
                      })
                      currentCp = poc.pdrListPostalCode
                      currentMulti = null
                      cpIdx = pocPath.length - 1
                    }
                    pocPath = pocPath[cpIdx].items
                  }

                  pocPath.push(item)
                } else {
                  // console.log('trace a traiter type = ' + item.type)
                }
              }
            }
          }
          break
        case 'colis-zone':
          // console.log(step)
          tree.push({
            type: 'colis-zone',
            id: store.getUniqueKey(),
            pos: step.pos,
            stepId: step.stepId,
            items: []
          })
          stepPath = tree[tree.length - 1].items
          for (let y = 0; y < stepItems.length; y++) {
            const item = stepItems[y]
            if (item.type === 'poc') {
              const poc = store.state.pocModule.data[item.id]
              if (tpTypeArray.indexOf(poc.tpType) < 0) { tpTypeArray.push(poc.tpType) }
              let pocPath = stepPath
              // if (showStreetGroup) {
              const street = poc.pdrListAddress.replace(poc.pdrListAddress.split(' ')[0], '').trim()
              // console.log('street = ' + street)
              if (currentStreet !== street) {
                pocPath.push({
                  type: 'street',
                  id: store.getUniqueKey(),
                  street: street,
                  items: []
                })
                currentStreet = street
                currentCp = null
                currentMulti = null
                streetIdx = pocPath.length - 1
              }
              if (pocPath[streetIdx]) {
                pocPath = pocPath[streetIdx].items
              } else {
                // console.log('streetIdx = ' + streetIdx)
                // console.log('streetItem = ' + pocPath[streetIdx])
              }
              // }
              if (showCpGroup) {
                if (currentCp !== poc.pdrListPostalCode) {
                  pocPath.push({
                    type: 'cp',
                    id: store.getUniqueKey(),
                    cp: poc.pdrListPostalCode,
                    colorId: item.colorId,
                    items: []
                  })
                  currentCp = poc.pdrListPostalCode
                  currentMulti = null
                  cpIdx = pocPath.length - 1
                }
                pocPath = pocPath[cpIdx].items
              }

              pocPath.push(item)
            } else {
              // console.log('trace a traiter type = ' + item.type)
            }
          }
          break
        default:
          // console.log('step a faire -> ' + step.type)
          break
      }
    }
    // console.log(tree)
    return tree
  }
  store.generatePocItemTree = function (items, group) {
    const tree = []
    const showSectorGroup = group.indexOf('sector') > -1
    const showColorGroup = group.indexOf('color') > -1
    const showCodeGroup = group.indexOf('code') > -1
    const showCpGroup = group.indexOf('cp') > -1
    const showStreetGroup = group.indexOf('street') > -1
    const showMultiGroup = group.indexOf('multi') > -1
    let currentSectorId = null
    let currentColorId = null
    let currentCodeId = null
    let currentCp = null
    let currentStreet = null
    let currentMulti = null
    let sectorIdx = null
    let colorIdx = null
    let codeIdx = null
    let cpIdx = null
    let streetIdx = null
    let multiIdx = null
    const travelItemSorted = items.sort(function (a, b) {
      return a.pos - b.pos
    })
    for (let itemIdx = 0; itemIdx < travelItemSorted.length; itemIdx++) {
      const item = travelItemSorted[itemIdx]
      if (item.type === 'poc') {
        const poc = store.state.pocModule.data[item.id]
        let pocPath = tree
        if (showSectorGroup) {
          if (currentSectorId !== item.sectorId) {
            pocPath.push({
              type: 'sector',
              id: store.getUniqueKey(),
              sectorId: item.sectorId,
              colorId: item.colorId,
              items: []
            })
            currentSectorId = item.sectorId
            currentColorId = null
            currentCodeId = null
            currentCp = null
            currentStreet = null
            currentMulti = null
            sectorIdx = pocPath.length - 1
          }
          pocPath = pocPath[sectorIdx].items
        }
        if (showColorGroup) {
          if (currentColorId !== item.colorId) {
            pocPath.push({
              type: 'color',
              id: store.getUniqueKey(),
              colorId: item.colorId,
              items: []
            })
            currentColorId = item.colorId
            currentCodeId = null
            currentCp = null
            currentStreet = null
            currentMulti = null
            colorIdx = pocPath.length - 1
          }
          pocPath = pocPath[colorIdx].items
        }
        if (showCodeGroup) {
          if (currentCodeId !== item.codeId) {
            pocPath.push({
              type: 'code',
              id: store.getUniqueKey(),
              codeId: item.codeId,
              codeName: item.codeName,
              colorId: item.colorId,
              items: []
            })
            currentCodeId = item.codeId
            currentCp = null
            currentStreet = null
            currentMulti = null
            codeIdx = pocPath.length - 1
          }
          pocPath = pocPath[codeIdx].items
        }
        if (showStreetGroup) {
          const street = poc.pdrListAddress.replace(poc.pdrListAddress.split(' ')[0], '').trim()
          if (currentStreet !== street) {
            pocPath.push({
              type: 'street',
              id: store.getUniqueKey(),
              street: street,
              items: []
            })
            currentStreet = street
            currentCp = null
            currentMulti = null
            streetIdx = pocPath.length - 1
          }
          pocPath = pocPath[streetIdx].items
        }
        if (showCpGroup) {
          if (currentCp !== poc.pdrListPostalCode) {
            pocPath.push({
              type: 'cp',
              id: store.getUniqueKey(),
              cp: poc.pdrListPostalCode,
              colorId: item.colorId,
              items: []
            })
            currentCp = poc.pdrListPostalCode
            currentMulti = null
            cpIdx = pocPath.length - 1
          }
          pocPath = pocPath[cpIdx].items
        }
        if (showMultiGroup) {
          const multiId = poc.mapItems[0].officeItemMultiId ? poc.mapItems[0].officeItemMultiId : null
          if (multiId) {
            if (currentMulti !== multiId) {
              pocPath.push({
                type: 'multi',
                multiType: poc.mapItems[0].type,
                id: store.getUniqueKey(),
                multiId: multiId,
                colorId: item.colorId,
                items: []
              })
              currentMulti = multiId
              multiIdx = pocPath.length - 1
            }
            pocPath = pocPath[multiIdx].items
          } else {
            currentMulti = null
          }
        }
        pocPath.push(item)
        // lastPath = pocPath
      } else {
        if (item.type === 'greyBox') {
          tree.push(item)
          currentColorId = null
          currentCodeId = null
        } else if (item.type === 'parcelLocker') {
          tree.push(item)
          currentSectorId = null
          currentColorId = null
          currentCodeId = null
        } else if (item.type === 'redBox') {
          // lastPath.push(item)
          tree.push(item)
          currentSectorId = null
          currentColorId = null
          currentCodeId = null
        } else if (item.type === 'wp') {
          tree.push(item)
          currentSectorId = null
          currentColorId = null
          currentCodeId = null
        } else {
          tree.push(item)
        }
      }
    }
    return tree
  }
  store.updateTravelInfoFromTree = function () {
    return null
  }
}

export default travelItemPlugin
