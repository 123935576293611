<template>
  <div class="home mx-2">
    <v-card-title>{{ $t('common.parameters') }}</v-card-title>
    <v-select
      solo
      :value="travelSelected"
      @change="changeTravel($event)"
      :items="travelItems"
      :item-value="travelItemsValue"
    >
      <template slot='selection' slot-scope='{ item }'>
        <v-list-item-avatar>
          <v-avatar
            size="32px"
            tile
          >
            <img
              :src="$store.getMarkerIconSpec($store.getters.getGoogle, { type: item.transportType }).url"
              alt="item"
            >
          </v-avatar>
        </v-list-item-avatar>
        <v-icon v-if="item.isOfficial" color="yellow" class="ms-n3">mdi-star</v-icon>
        <v-list-item-content align="center" three-line>
          <v-list-item-title class="font-weight-bold">{{ item.name }}</v-list-item-title>
          <v-list-item-subtitle class="font-weight-medium">{{ $store.state.routeModule.data[item.routeId].name }} - {{ getPartName(item.part) }}</v-list-item-subtitle>
          <v-list-item-subtitle class="font-weight-medium">{{ getTransportTypeName(item.transportType) + (item.isOfficial ? ' (Officiel)' : '') }}</v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <template slot='item' slot-scope='{ item }'>
        <v-list-item-avatar>
          <v-avatar
            size="32px"
            tile
          >
            <img
              :src="$store.getMarkerIconSpec($store.getters.getGoogle, { type: item.transportType }).url"
              alt="item"
            >
          </v-avatar>
        </v-list-item-avatar>
        <v-icon v-if="item.isOfficial" color="yellow" class="ms-n3">mdi-star</v-icon>
        <v-list-item-content align="center" three-line>
          <v-list-item-title class="font-weight-bold">{{ item.name }}</v-list-item-title>
          <v-list-item-subtitle class="font-weight-medium">{{ $store.state.routeModule.data[item.routeId].name }} - {{ getPartName(item.part) }}</v-list-item-subtitle>
          <v-list-item-subtitle class="font-weight-medium">{{ getTransportTypeName(item.transportType) + (item.isOfficial ? ' (Officiel)' : '') }}</v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-select>
    <v-btn x-large block color="primary">Colis du jour (0)</v-btn>
  </div>
</template>

<script>
export default {
  name: 'ParamDrawerItem',
  props: ['routeId', 'mapMode'],
  data () {
    return {
      ts: null
    }
  },
  computed: {
    travelItems () {
      return this.$store.getters['officeTravelModule/getActiveTravels']
    },
    travelItemsValue () {
      return this.travelItems.map(x => { return x.id })
    },
    travelOffical () {
      return this.$store.getters['officeTravelModule/getOfficalTravel']
    },
    travelSelected () {
      if (this.$store.getters.getSelectedTravelId && this.travelItemsValue.indexOf(this.$store.getters.getSelectedTravelId) > -1) {
        return this.travelItems[this.travelItemsValue.indexOf(this.$store.getters.getSelectedTravelId)]
      } else if (this.travelOffical) {
        return this.travelOffical
      } else {
        return null
      }
    }
  },
  methods: {
    getTransportTypeName (type) {
      switch (type) {
        case 'taxi':
          return 'Taxi'
        case 'truck':
          return this.$t('common.vehicle')
        default:
          return this.$t('common.vehicle')
      }
    },
    getPartName (part) {
      switch (part) {
        case 'all':
          return this.$t('common.full')
        case '1':
          return this.$t('common.orange')
        case '2':
          return this.$t('common.pink')
        case '3':
          return this.$t('common.blue')
        default:
          return this.$t('common.full')
      }
    },
    changeTravel (travel) {
      // // console.log(travelId)
    }
  }
}
</script>
