import gmapsInit from './gmaps'
let google
var vibrateInterval

export default async function init () {
  google = await gmapsInit()
  return google
}
/**
 * Calculates the distance, in kilometers, between two locations, via the
 * Haversine formula. Note that this is approximate due to the fact that
 * the Earth's radius varies between 6356.752 km and 6378.137 km.
 *
 * @param {Object} location1 The first location given as .latitude and .longitude
 * @param {Object} location2 The second location given as .latitude and .longitude
 * @return {number} The distance, in kilometers, between the inputted locations.
 */
export function distance (location1, location2) {
  const radius = 6371 // Earth's radius in kilometers
  const latDelta = degreesToRadians(location2.latitude - location1.latitude)
  const lonDelta = degreesToRadians(location2.longitude - location1.longitude)

  const a = (Math.sin(latDelta / 2) * Math.sin(latDelta / 2)) +
          (Math.cos(degreesToRadians(location1.latitude)) * Math.cos(degreesToRadians(location2.latitude)) *
          Math.sin(lonDelta / 2) * Math.sin(lonDelta / 2))

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return radius * c
}
function startVibrate (duration) {
  navigator.vibrate(duration)
}
function degreesToRadians (degrees) {
  var pi = Math.PI
  return degrees * (pi / 180)
}
// Arrête la vibration
export function stopVibrate () {
  // Vide l'intervalle et annule les vibrations persistantes
  if (vibrateInterval) clearInterval(vibrateInterval)
  navigator.vibrate(0)
}

// Démarre la vibration persistante avec un intervalle et une durée donnée
export function startPeristentVibrate (duration, interval) {
  vibrateInterval = setInterval(function () {
    startVibrate(duration)
  }, interval)
}
export async function searchGoogle (schadress) {
  if (!google) { google = await gmapsInit() }
  const test = await getgeocoderresult(schadress)
  return test
  // let schadress = "20 River Terrace, New York, NY, United States'";
  // let schadress = this.selectedpdr.door + " " + this.selectedpdr.street + ', Levis, QC, Canada';
}

async function getgeocoderresult (schadress) {
  const geocoder = new google.maps.Geocoder()
  // then we call the geocode function with the address we want to use as parameter
  // const searchResults = await geocoder.geocode({ address: schadress })
  return geocoder.geocode({ address: schadress }, function (results, status) {
    if (status === google.maps.GeocoderStatus.OK) {
      return results[0]
    } else {
      // there were no coordinates found for the address entered (or the address was not found)
      alert('Could not find your address :(')
      return null
    }
  })
}
