<template >
  <v-row justify="center">
    <v-dialog v-model="showGetType" persistent fixed max-width="444" transition="scale-transition">
      <v-card >
        <v-app-bar dark color="primary">
          <v-btn icon dark @click="dialog = false" x-large class="lighten-4">
            <v-icon x-large class="lighten-4">mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ $t('pmtcreatereportpopup.title') }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-app-bar>
        <v-container
          fixedclass="overflow-y-auto overflow-x-hidden"
        >
          <v-list nav>
            <v-row>
              <v-col cols="6">
                <v-list-item link>
                <v-list-item-content class="mx-auto" @click="type = 'dog'" clearfix>
                      <v-icon :size="$store.getters.iconSize" color="primary">mdi-dog-side</v-icon>
                    <v-list-item-title class="subtitle text-center">{{ $t('createreport.dog') }}</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="6">
                <v-list-item link>
                <v-list-item-content class="mx-auto" @click="type = 'ice'" clearfix>
                      <v-icon :size="$store.getters.iconSize" color="primary">mdi-snowflake-alert</v-icon>
                    <v-list-item-title class="subtitle text-center">{{ $t('createreport.ice') }}</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="6">
                <v-list-item link>
                <v-list-item-content class="mx-auto" @click="type = 'brokenstep'" clearfix>
                      <v-icon :size="$store.getters.iconSize" color="primary">mdi-stairs-up</v-icon>
                    <v-list-item-title class="subtitle text-center">{{ $t('createreport.brokenstep') }}</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="6">
                <v-list-item link>
                <v-list-item-content class="mx-auto" @click="type = 'other'" clearfix>
                      <v-icon :size="$store.getters.iconSize" color="primary">mdi-alert</v-icon>
                    <v-list-item-title class="subtitle text-center">{{ $t('createreport.other') }}</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="6">
                <v-list-item link>
                <v-list-item-content class="mx-auto" @click="type='fullbox'" clearfix>
                      <v-icon :size="$store.getters.iconSize" color="primary">mdi-mailbox-up</v-icon>
                    <v-list-item-title class="subtitle text-center">{{ $t('createreport.fullbox') }}</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-list>
        </v-container>

      </v-card>
    </v-dialog>
    <v-dialog v-model="showCreateReport" persistent fixed max-width="444" transition="scale-transition">
        <v-app-bar dark color="primary">
          <v-btn icon dark @click="dialog = false" x-large>
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ $t('pmtcreatereportpopup.title' + type) }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-app-bar>
        <v-card :max-height="$store.getters.contentHeight * 0.75" flat class="overflow-y-auto">
          <v-card-text>
            <v-row class="mt-5" no-gutters>
              <p class="title"> {{ $t('common.owner') }} : </p>
              <v-spacer></v-spacer>
              <v-btn :color="reportOwner ? 'success' : 'warning'" @click="showSelectOwner = true"> {{ reportOwner ? 'Ok' : $t('common.notselected') }} </v-btn>
            </v-row>
            <p v-if="reportOwner" class="title green--text" align="center">{{ reportOwner.name }}</p>
            <v-divider/>
            <div v-if="showGetBlock">
              <p class="title mt-5"> {{ $t('createreportdog.blockpdrtitle') }} ?</p>
              <v-row no-gutters>
                <v-switch
                  class="mt-n1"
                  v-model="pdrBlock"
                  :label="pdrBlock ? $t('common.yes') : $t('common.no') "
                ></v-switch>
                <v-spacer></v-spacer>
                <v-btn class="mt-n1" v-if="pdrBlock" @click="showSelectPdr = true" :color="selectPdrList.length ? 'success' : 'error'">
                  {{ selectPdrList.length }} {{ $t('common.pdr') }} <div class="d-none d-sm-flex"> {{ $t('common.selected') }} </div>
                </v-btn>
              </v-row>
              <v-expansion-panels v-if="selectPdrList.length && pdrBlock" class="mb-5">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <p class="subtitle-1 my-n2">Detail</p>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content color="grey lighten-3">
                    <p
                      class="subtitle-1 red--text mt-2"
                      align="center"
                      v-for="(pdr, i) in selectPdrList"
                      :key="i"
                    >
                      {{ pdr.pdrListAddress }}
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <v-divider/>
            <p class="title mt-5">{{ $t('pmtcreatereportpopup.titlestep2') }} :</p>
            <v-textarea
              v-model="desc"
              class="mb-5"
              background-color="grey lighten-3"
              filled
              solo
            ></v-textarea>
            <v-divider/>
            <p class="title mt-5">{{ $t('common.proximityAlert') }} :</p>
            <v-row no-gutters class="mt-n1">
              <v-spacer></v-spacer>
              <v-switch
                v-model="proximityAlert"
                :label="$t(proximityAlert ? 'common.active' : 'common.inactive')"
              ></v-switch>
              <v-icon large class="ms-3" :color="proximityAlert ? 'success' : 'error'" v-text="proximityAlert ? 'mdi-leak' : 'mdi-leak-off'"></v-icon>
              <v-spacer></v-spacer>
            </v-row>
            <v-divider/>
            <v-list nav>
              <v-layout row>
                <v-flex xs4 sm4 offset-sm1 align-center>
                  <v-list-item link v-if="showGetReportPosition">
                  <v-list-item-content class="mx-auto" @click="showGetPosition = true" clearfix>
                      <v-icon x-large :color="position ? 'success' : 'error'">mdi-map-marker</v-icon>
                      <v-list-item-title class="subtitle text-center">{{ $t('createreportdog.gps') }}</v-list-item-title>
                  </v-list-item-content>
                  </v-list-item>
                </v-flex>
                <v-flex xs4 sm4 offset-sm1 align-center>
                  <v-list-item link>
                    <v-list-item-content align="center" class="mx-auto" @click="showManagePicture = true" clearfix>
                      <v-badge
                        v-if="imageList.length"
                        :content="imageList.length"
                        color="green"
                        offset-x="20"
                        offset-y="20"
                        bottom
                      >
                        <v-icon x-large color="success">mdi-folder-multiple-image</v-icon>
                      </v-badge>
                      <v-icon v-else x-large color="warning">mdi-folder-multiple-image</v-icon>

                      <v-list-item-title class="subtitle text-center">{{ $t('common.picture') }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-flex>
              </v-layout>
            </v-list>

          </v-card-text>
        </v-card>
        <v-app-bar dark color="primary" bottom>
          <v-spacer></v-spacer>
          <v-toolbar-title v-show="valideReport" @click="onCreateReport">{{ $t('createreportdog.createreport') }}</v-toolbar-title>
          <v-app-bar-nav-icon v-show="valideReport" @click="onCreateReport">
            <v-btn icon dark x-large>
              <v-icon color="success" x-large>mdi-content-save</v-icon>
            </v-btn>
          </v-app-bar-nav-icon>
        </v-app-bar>
        <pmt-loading-local
          :loadingShow="showSaving"
          :loadingTitle="$t('common.saveinprogress')"
        ></pmt-loading-local>
    </v-dialog>
    <pmt-select-pdr
      v-if="showSelectPdr"
      :title="$t('createreportdog.selectpdrpopuptitle')"
      :selItem="selectPdrList.length ? selectPdrList : null"
      @close="showSelectPdr = false"
      @select="selectPdrList = $event"
    ></pmt-select-pdr>
    <pmt-select-pdr
      v-if="showSelectOwner"
      :title="$t('common.owner')"
      :selItem="reportOwner"
      solo=true
      @close="showSelectOwner = false"
      @select="reportOwner = $event[0]"
    ></pmt-select-pdr>
    <!-- <pmt-import-picture
      v-if="showImportPicture"
      :title="$t('createreportdog.addpicture')"
      @selectpicture="addpicture($event)"
      @close="showImportPicture = false"
    ></pmt-import-picture> -->
    <pmt-position-map-set
      v-if="showGetPosition"
      :startCenterPdrId="reportOwnerId"
      :param="{ icon: $store.getAlertPngIcon(type),markerStartPosition: position , getDistance: true }"
      @return-close="showGetPosition = false"
      @return-check="setPosition($event)"
    />
    <picture-manager
      v-if="showManagePicture"
      :subTitle="$t('pmtcreatereportpopup.title' + type)"
      :param="{ type: 'message', imageList: imageList }"
      @close="showManagePicture = false"
      @return-close="returnImage($event)"
    ></picture-manager>
  </v-row>
</template>

<script>
import firebase from 'firebase/app'
export default {
  props: ['reportType', 'owner'],
  data () {
    return {
      dialog: true,
      showGetType: false,
      showCreateReport: false,
      showFullBoxReport: false,
      showSelectOwner: false,
      showSelectPdr: false,
      showManagePicture: false,
      showGetBlock: false,
      showGetReportPosition: false,
      pdrBlock: false,
      type: '',
      reportOwner: null,
      reportOwnerName: '',
      reportOwnerId: null,
      selectPdrList: [],
      desc: '',
      showSaving: false,
      distance: Number,
      pocOwner: null,
      position: null,
      showGetPosition: false,
      imageList: [],
      ownerName: '',
      proximityAlert: false
    }
  },
  computed: {
    valideReport () {
      switch (this.type) {
        case 'fullbox':
          if (this.reportOwner) {
            return true
          } else {
            return false
          }
        case '':
          return false
        default:
          if (!this.position) {
            return false
          } else if (!this.selectPdrList.length && this.pdrBlock) {
            return false
          } else {
            return true
          }
      }
    },
    selectPdrIdList () {
      const idList = []
      for (const pdr of this.selectPdrList) {
        idList.push(pdr.id)
      }
      return idList
    }
  },
  watch: {
    dialog (newValue) {
      this.dialog = false
      this.$store.dispatch('resize')
      this.$emit('close')
    },
    type (newValue) {
      if (newValue) {
        this.showGetType = false
        this.showCreateReport = true
        // if (newValue === 'fullbox') {
        //   this.showFullBoxReport = true
        // } else {
        //   this.showCreateReport = true
        // }
      }
      switch (newValue) {
        case 'fullbox':
          this.showGetBlock = false
          this.showGetReportPosition = false
          break
        case '':
          this.showGetBlock = false
          this.showGetReportPosition = false
          break
        default:
          this.showGetBlock = true
          this.showGetReportPosition = true
          break
      }
    },
    reportOwner (newValue) {
      if (newValue) {
        if (newValue.name) {
          this.reportOwnerName = newValue.name
          this.reportOwnerId = newValue.id
        } else if (newValue.pdrListAddress) {
          this.reportOwnerName = newValue.pdrListAddress
          this.reportOwnerId = newValue.id
        } else {
          this.reportOwnerName = ''
          this.reportOwnerId = null
        }
      } else {
        this.reportOwnerName = ''
        this.reportOwnerId = null
      }
    }
  },
  methods: {
    returnImage (images) {
      this.showManagePicture = false
      this.imageList = images
    },
    customFilter (item, queryText, itemText) {
      const itemTextLow = itemText.toLowerCase()
      const searchTextArray = queryText.toLowerCase().split(' ')
      var result = false
      for (let i = 0; i < searchTextArray.length; i++) {
        const search = searchTextArray[i]
        if (search.length) {
          if (itemTextLow.indexOf(search) > -1 && search) {
            result = true
          } else {
            result = false
            break
          }
        }
      }
      return result
    },
    initData () {
      this.distance = 35
      this.position = null
      this.pocOwner = null
      this.selectPdrList = []
      this.imageList = []
      this.dogDanger = false
      this.pdrBlock = false
      this.pocBlockedList = []
      this.desc = ''
    },
    setPosition (posDist) {
      // posDist.position {lat:lng} posDist.distance (num)
      this.distance = posDist.distance
      this.position = posDist.position
      this.pocOwner = posDist.pocOwner
      this.showGetPosition = false
    },
    onCreateReport () {
      this.showSaving = true
      var ownerId = ''
      var ownerName = ''
      var ownerAddress = ''
      var includeOwner = false
      var pocList = []
      const routeIdArray = [this.$store.getters.getSelectedRouteId]
      const self = this
      if (this.reportOwner) {
        ownerId = this.reportOwner.id
        this.$store.state.pocModule.data[ownerId].route.forEach(routeId => {
          if (routeIdArray.indexOf(routeId) < 0) {
            routeIdArray.push(routeId)
          }
        })
      }
      for (let i = 0; i < this.selectPdrIdList.length; i++) {
        const pdrId = this.selectPdrIdList[i]
        const poc = this.$store.state.pocModule.data[pdrId]
        var isOwner = false
        if (pdrId === ownerId) {
          includeOwner = true
          isOwner = true
          ownerName = poc.name ? poc.name : ''
          ownerAddress = poc.pdrListAddress
        } else {
          poc.route.forEach(routeId => {
            if (routeIdArray.indexOf(routeId) < 0) {
              routeIdArray.push(routeId)
            }
          })
        }
        pocList.push({ pdrId: pdrId, name: poc.name ? poc.name : null, address: poc.pdrListAddress, isBlocked: true, isOwner: isOwner })
      }
      if (!includeOwner && ownerId) { pocList.push({ pdrId: ownerId, name: ownerName, address: ownerAddress, isBlocked: false, isOwner: true }) }
      const report = {
        type: this.type,
        status: 1,
        pocList: pocList,
        owner: ownerId,
        active: true,
        proximityAlert: this.proximityAlert,
        desc: this.desc,
        note: [],
        hist: [{
          status: 1,
          userId: this.$store.getters.userId,
          name: this.$store.state.userLoggedModule.data.lname + ', ' + this.$store.state.userLoggedModule.data.fname,
          empId: this.$store.state.userLoggedModule.data.empid,
          at: firebase.firestore.Timestamp.fromDate(new Date())
        }],
        routeIdArray: routeIdArray,
        imageList: this.imageList ? this.imageList : [],
        reportByName: this.$store.state.userLoggedModule.data.lname + ', ' + this.$store.state.userLoggedModule.data.fname
      }
      if (this.type !== 'fullbox') {
        report.dangergps = new firebase.firestore.GeoPoint(this.position.lat, this.position.lng)
        report.securedistance = this.distance
      }
      this.$store.dispatch('reportModule/onCreateReport', report)
        .then(() => {
          this.showSaving = false
          this.initData()
          this.$emit('close')
          this.$store.dispatch('showMessage', this.$t('common.succesCreateReport'))
        })
        .catch(function (error) {
          self.showSaving = false
          self.$store.dispatch('showAlert', error.message)
        })
    }
  },
  mounted () {
    if (this.owner) {
      this.reportOwner = this.owner
      this.reportOwnerName = this.owner.name
    }
    if (this.reportType) {
      this.type = this.reportType
    } else {
      this.showGetType = true
    }
  },
  destroyed () {
    if (this.imageList.length) {
      this.imageList.forEach(picture => {
        var desertRef = firebase.storage().ref().child(picture.fullPath)
        desertRef.delete()
      })
    }
  }
}
</script>
