import Vue from 'vue'
import Vuex from 'vuex'
import VuexEasyFirestore from 'vuex-easy-firestore'
import gmapsInit, { getPictureSrc } from '@/utils/gmaps'
// import { gmapApi } from 'vue2-google-maps'
import { startPeristentVibrate, stopVibrate } from '@/utils/PMTvibrate'
// import { ConvexHullGrahamScan } from '@/utils/graham_scan'
// import GrahamScan from '@lucio/graham-scan'
import { Firebase, initFirebase } from './config/firebase.js'
// import firebase from 'firebase/app'
// import 'firebase/storage'
// import from step 3 (below)
import authStore from './modules/authStore'
import paramModule from './modules/paramModule.js'
// import calendarModule from './modules/calendarModule.js'

import userModule from './modules/userModule.js'
import userLoggedModule from './modules/userLoggedModule.js'
import officeModule from './modules/officeModule.js'
import officeItemModule from './modules/officeItemModule.js'
import officeTravelModule from './modules/officeTravelModule.js'
import officeSeletedModule from './modules/officeSelectedModule.js'
import officeRulesModule from './modules/officeRulesModule.js'
import pdrModule from './modules/pdrModule.js'
import zoneModule from './modules/zoneModule.js'
import officeItemMultiModule from './modules/officeItemMultiModule.js'
import routeModule from './modules/routeModule.js'
import routeDayDataModule from './modules/routeDayDataModule.js'
import routeSelectedModule from './modules/routeSelectedModule.js'
import reportModule from './modules/reportModule.js'
import pocModule from './modules/pocModule.js'
import harmonizeModule from './modules/harmonizeModule.js'
import mailModule from './modules/mailModule.js'
import messCSocialModule from './modules/messCSocialModule.js'
import messMixComiteModule from './modules/messMixComiteModule.js'

import messUserModule from './modules/messUserModule.js'
import messUserTypeModule from './modules/messUserTypeModule.js'
import messRouteModule from './modules/messRouteModule.js'
import officeMailNoticeModule from './modules/officeMailNoticeModule.js'
import logModule from './modules/logModule.js'
import taskOfficeModule from './modules/taskOfficeModule.js'
import taskRouteModule from './modules/taskRouteModule.js'
import adminModule from './modules/adminModule.js'
import placeModule from './modules/placeModule.js'
// plugin
import commonFunctionPlugin from './plugin/commonFunctionPlugin.js'
import travelItemPlugin from './plugin/travelItemPlugin.js'
import mapPlugin from './plugin/mapPlugin.js'
import themesPlugin from './plugin/themesPlugin.js'
Vue.use(Vuex)

const easyFirestore = VuexEasyFirestore(
  [userModule, userLoggedModule, officeModule, officeSeletedModule, officeRulesModule, officeTravelModule, officeItemModule,
    pdrModule, zoneModule, officeItemMultiModule, routeModule, routeSelectedModule, reportModule, paramModule, harmonizeModule,
    pocModule, mailModule, messCSocialModule, messUserModule, messUserTypeModule, messRouteModule, messMixComiteModule, officeMailNoticeModule,
    logModule, taskRouteModule, taskOfficeModule, adminModule, placeModule, routeDayDataModule],
  { logging: true, FirebaseDependency: Firebase }
)
const store = new Vuex.Store({
  plugins: [easyFirestore, commonFunctionPlugin, mapPlugin, travelItemPlugin, themesPlugin],
  state: {
    API_KEY: 'AIzaSyBCYzXrnUG2oYl9LaUoMURILgcFtag0TOI',
    primaryPocMapItemTypeArray: ['dtd', 'cmb', 'lba', 'aptlba', 'ksk', 'dflb', 'rmb', 'cntr'],
    optionalPocMapItemTypeArray: ['door', 'safeplace', 'tempbox'],
    userId: null,
    userEmail: null,
    // access: { visitor: true, postmanFfrs: false, postmanSttp: false, employe: false, supervisor: false, admin: false, cSocial: false },
    path: '/',
    contentSize: { width: window.innerWidth, height: window.innerHeight, style: 'width:' + window.innerWidth + 'px; height:' + window.innerHeight + 'px' },
    screenSize: { width: window.innerWidth, height: window.innerHeight, style: 'width:' + window.innerWidth + 'px; height:' + window.innerHeight + 'px' },
    google: null,
    appBarExtendHeight: 0,
    currentPosition: null,
    colorCode: { orange: '#FF9800', pink: '#E91E63', blue: '#1976D2' },
    userSelection: { zone: null, office: null, route: null, color: null, code: null, place: null, pdr: null },
    userParam: { locale: 'en', guardianMode: { active: true, noteActive: true, dangerActive: true }, mapInfo: { showDog: false, showPocMarker: false, showColorLine: false, showToilet: false, showGreyBox: false, showParking: false } },
    userSelectOfficePictureSrc: null,
    userSelectPlacePictureSrc: null,
    dayColor: '#424242',
    EventBus: new Vue(),
    user: null,
    officeModuleLoadComplete: false,
    officeRulesModuleLoadComplete: false,
    officeMailNoticeModuleLoadComplete: false,
    officeItemMultiModuleLoadComplete: false,
    officeItemModuleLoadComplete: false,
    officeTravelModuleLoadComplete: false,
    officeSelectedModuleLoadComplete: false,
    userModuleLoadComplete: false,
    userLoggedModuleLoadComplete: false,
    // pdrModuleLoadComplete: false,
    pocModuleLoadComplete: false,
    placeModuleLoadComplete: false,
    zoneModuleLoadComplete: false,
    routeModuleLoadComplete: false,
    routeDayDataModuleLoadComplete: false,
    reportModuleLoadComplete: false,
    paramModuleLoadComplete: false,
    taskRouteModuleLoadComplete: false,
    messCSocialModuleLoadComplete: false,
    messMixComiteModuleLoadComplete: false,
    messUserModuleLoadComplete: false,
    messUserTypeModuleLoadComplete: false,
    messRouteModuleLoadComplete: false,
    userLoadComplete: false,
    userloading: false,
    authError: null,
    waitlogin: true,
    loadfilecpt: 0,
    loadfilecompleted: true,
    loadfile: false,
    usercreatemode: false,
    userinfokey: null,
    systemMessage: '',
    systemAlert: '',
    systemInfo: '',
    dashBoardMenuSelect: 'messageboard',
    masterLoadingStatus: { showLoading: false },
    loadingProgress: false,
    loadingProgressValue: 0,
    loadingProgressTitle: '',
    alertDistance: 0,
    sessionMessageLog: [],
    currentThemes: null,
    today: null
  },
  getters: {
    getSortByPos: (state) => {
      return store.sortByPos
    },
    getFilterOnlyUnique: (state) => {
      return store.filterOnlyUnique
    },
    getPocMarker: (state) => (markerInfo) => {
      // markerInfo = { pdrListAddress: '', pdrType: '', vacant: '', pub: '', colorId: '', codeId: '', codeName: '', pdrListPostalCode: '', homeLocation: '', seqPosTot: '' }
      return store.getPocMarker(markerInfo)
    },
    getItemMarker: (state) => (item) => {
      return store.getItemMarker(item)
    },
    getStepMarker: (state) => (tpType, step, travelItems, tWp) => {
      return store.getStepMarker(tpType, step, travelItems, tWp)
    },
    getTravelStepsItems: (state) => (travelDetail) => {
      if (travelDetail.transportType === 'taxi') {
        return this.generateStepsFromTaxiTravelItems(this.travelDetail.items)
      } else if (travelDetail.transportType === 'truck') {
        return this.generateStepsFromTruckTravelItems(this.travelDetail.items)
      } else {
        return []
      }
    },
    today: (state) => {
      return state.today
    },
    getCurrentThemes: (state) => {
      return state.currentThemes
      // return state.currentThemes
    },
    showMasterLoading: (state) => {
      if (state.masterLoadingStatus.showLoading) {
        return true
      } else {
        return false
      }
    },
    getMasterLoadingStatus: (state) => {
      return state.masterLoadingStatus
    },
    distance: (state, getters) => (pos1, pos2) => {
      return store.distance(pos1, pos2)
    },
    getPosCenterOfPocArray: (state, getters) => (pocArray) => {
      return store.getPosCenterOfPocArray(pocArray)
    },
    getPosAverageOfPocArray: (state, getters) => (pocArray) => {
      return store.getPosAverageOfPocArray(pocArray)
    },
    getCumulTotalStatFromPocIdArray: (state, getters) => (pocIdArray) => {
      return store.getCumulTotalStatFromPocArray(pocIdArray.map(x => {
        return state.pocModule.data[x]
      }))
    },
    getCumulStatFromPocIdArray: (state, getters) => (pocIdArray) => {
      return store.getCumulStatFromPocArray(pocIdArray.map(x => {
        return state.pocModule.data[x]
      }))
    },
    getCumulStatFromPocArray: (state, getters) => (pocArray) => {
      return store.getCumulStatFromPocArray(pocArray)
    },
    getCodeLineMarker: (state, getters) => (lineInfo) => {
      const lineSymbol = {
        path: getters.getGoogle.maps.SymbolPath.FORWARD_CLOSED_ARROW
      }

      // Create the polyline and add the symbol via the 'icons' property.
      const positionSorted = lineInfo.posArray.sort(function (a, b) { return a.seqPosTot - b.seqPosTot }).map(x => { return x.position })
      return {
        line: new getters.getGoogle.maps.Polyline({
          path: positionSorted,
          geodesic: true,
          strokeColor: store.getColorName(lineInfo.colorId),
          strokeOpacity: 1.0,
          strokeWeight: 6
        }),
        lineArrow: new getters.getGoogle.maps.Polyline({
          path: positionSorted,
          geodesic: true,
          strokeColor: store.getColorName(lineInfo.colorId),
          strokeOpacity: 1.0,
          strokeWeight: 6,
          icons: [
            {
              icon: lineSymbol,
              offset: '100%'
            }
          ]
        })
      }
    },
    getGoogleCircle: (state, getters) => (pos, color, radius) => {
      // 'brown' },
      // 'blue' },
      // 'yellow' },
      // 'green' },
      // 'red' }strokeColor: getters.getColorCode(colorId),
      return new getters.getGoogle.maps.Circle({
        strokeColor: color,
        strokeOpacity: 0.2,
        strokeWeight: 0.3,
        fillColor: color,
        fillOpacity: 0.1,
        map: null,
        zIndex: 10,
        center: pos,
        radius: radius
      })
    },
    getColorIconUrl: (state) => (colorId) => {
      switch (colorId) {
        case 2:
          return require('@/assets/Icon/MapIcon/pink.png')
        case 3:
          return require('@/assets/Icon/MapIcon/blue.png')
        default:
          return require('@/assets/Icon/MapIcon/orange.png')
      }
    },
    getSelectedRouteInfoMarker: (state, getters) => {
      return getters.getMarkerOfRoute(getters['userLoggedModule/getSelectionRoute'])
    },
    getGoogleMarker: (state, getters) => (markerInfo) => {
      // markerInfo.isDone = getters[]
      return store.getGoogleMarker(getters.getGoogle, markerInfo)
    },
    getTravelWayPointMarker: (state, getters) => (twp) => {
      return store.getTravelWayPointMarker(twp)
    },
    getAlertPngIcon: (state) => (type) => {
      return store.getAlertPngIcon(type)
    },
    getMapItemIconOfType: (state) => (type) => {
      return store.getMapItemIconOfType(type)
    },
    getMarkerOfRoute: (state, getters) => (routeId) => {
      const pocArray = getters['pocModule/getPocOfRoute'](routeId)
      const travelItems = getters['officeTravelModule/getSeletedTravel'] ? getters['officeTravelModule/getSeletedTravel'].travelInfo.items : []
      const placeMarker = []
      const placeArray = []
      const cpArray = []
      const streetArray = []
      const codeArray = []
      const colorArray = []
      let pos = 0
      let colorName
      let topName
      const routeBounds = new getters.getGoogle.maps.LatLngBounds()

      pocArray.forEach(poc => {
        const street = poc.pdrListAddress.replace(poc.pdrListAddress.split(' ')[0], '').trim()
        const travelItemIdx = travelItems.map(x => { return x.id }).indexOf(poc.id)
        const stepId = travelItemIdx > -1 ? travelItems[travelItemIdx].stepId : null
        if (poc.placeId) {
          // Place
          const place = state.placeModule.data[poc.placeId]
          const placeIndex = placeArray.map(x => { return x.placeId }).indexOf(poc.placeId)
          if (placeIndex < 0) {
            placeArray.push({
              placeId: poc.placeId,
              stepArray: stepId > -1 ? [stepId] : [],
              position: place.homeLocation,
              colorArray: poc.colorId ? [poc.colorId] : [],
              codeArray: poc.codeId ? [{ codeId: poc.codeId, codeName: poc.codeName }] : [],
              streetArray: [street],
              cpArray: [poc.pdrListPostalCode],
              pocIdArray: [poc.id],
              allPub: poc.pub,
              allVacant: poc.vacant,
              seqPosTot: poc.seqPosTot,
              sName: place.sName
            })
          } else {
            if (stepId !== null && placeArray[placeIndex].stepArray.indexOf(stepId) < 0) {
              placeArray[placeIndex].stepArray.push(stepId)
            }
            if (poc.colorId && !placeArray[placeIndex].colorId) {
              placeArray[placeIndex].colorId = poc.colorId
            }
            placeArray[placeIndex].pocIdArray.push(poc.id)
            if (!poc.pub && placeArray[placeIndex].allPub) {
              placeArray[placeIndex].allPub = poc.pub
            }
            if (!poc.vacant && placeArray[placeIndex].allVacant) {
              placeArray[placeIndex].allVacant = poc.vacant
            }
            if (poc.colorId !== undefined && placeArray[placeIndex].colorArray.indexOf(poc.colorId) < 0) {
              placeArray[placeIndex].colorArray.push(poc.colorId)
            }
            if (poc.codeId !== undefined && placeArray[placeIndex].codeArray.map(x => { return x.codeId }).indexOf(poc.codeId) < 0) {
              placeArray[placeIndex].codeArray.push({ codeId: poc.codeId, codeName: poc.codeName })
            }
            if (placeArray[placeIndex].streetArray.indexOf(street) < 0) {
              placeArray[placeIndex].streetArray.push(street)
            }
            if (placeArray[placeIndex].cpArray.indexOf(poc.pdrListPostalCode) < 0) {
              placeArray[placeIndex].cpArray.push(poc.pdrListPostalCode)
            }
          }
        } else {
          // POC
          const m = getters['pocModule/getPocMarker'](poc)
          m.stepId = stepId
          placeMarker.push(m)

          const cpIndex = cpArray.map(x => { return x.cp }).indexOf(poc.pdrListPostalCode)
          if (cpIndex < 0) {
            cpArray.push({
              posArray: [poc.homeLocation],
              cp: poc.pdrListPostalCode,
              streetArray: [street]
            })
          } else {
            cpArray[cpIndex].posArray.push(poc.homeLocation)
            if (cpArray[cpIndex].streetArray.indexOf(street) < 0) { cpArray[cpIndex].streetArray.push(street) }
          }
          const streetIndex = streetArray.map(x => { return x.street }).indexOf(street)
          if (streetIndex < 0) {
            streetArray.push({
              posArray: [poc.homeLocation],
              street: street
            })
          } else {
            streetArray[streetIndex].posArray.push(poc.homeLocation)
          }
        }
      })
      placeArray.forEach(placeInfo => {
        if (placeInfo.colorId === 3) { colorName = 'blue' } else if (placeInfo.colorId === 2) { colorName = 'pink' } else { colorName = 'orange' }
        if (placeInfo.allVacant) {
          topName = 'green'
        } else if (!placeInfo.allPub) {
          topName = 'red'
        } else {
          topName = 'black'
        }
        placeMarker.push({
          type: 'placeMarker',
          position: placeInfo.position,
          placeId: placeInfo.placeId,
          routeId: routeId,
          codeId: placeInfo.codeId,
          colorId: placeInfo.colorId,
          codeArray: placeInfo.codeArray,
          colorArray: placeInfo.colorArray,
          stepArray: placeInfo.stepArray,
          cpArray: placeInfo.cpArray,
          streetArray: placeInfo.streetArray,
          seqPosTot: placeInfo.seqPosTot,
          marker: store.getGoogleMarker(getters.getGoogle, {
            type: 'placeMarker',
            map: null,
            label: String(placeInfo.sName),
            url: require('@/assets/Icon/MapIcon/POC/App-' + colorName + '-t' + topName + '.png'),
            position: placeInfo.position,
            seqPosTot: placeInfo.seqPosTot
          })
        })
        placeInfo.cpArray.forEach(cp => {
          const cpIndex = cpArray.map(x => { return x.cp }).indexOf(cp)
          if (cpIndex < 0) {
            cpArray.push({
              posArray: [placeInfo.position],
              cp: cp
            })
          } else {
            cpArray[cpIndex].posArray.push(placeInfo.position)
          }
        })
        placeInfo.streetArray.forEach(street => {
          const streetIndex = streetArray.map(x => { return x.street }).indexOf(street)
          if (streetIndex < 0) {
            streetArray.push({
              posArray: [placeInfo.position],
              street: street
            })
          } else {
            streetArray[streetIndex].posArray.push(placeInfo.position)
          }
        })
      })

      // placeMarker

      placeMarker.forEach(marker => {
        if (marker.position) {
          routeBounds.extend(marker.position)
        }
        if (marker.codeId !== null && marker.codeId !== undefined) {
          const codeIndex = codeArray.map(x => { return x.codeId }).indexOf(marker.codeId)
          if (codeIndex < 0) {
            codeArray.push({
              posArray: [{ position: marker.position, seqPosTot: marker.seqPosTot }],
              codeId: marker.codeId,
              codeName: marker.codeName,
              colorId: marker.colorId
            })
          } else {
            codeArray[codeIndex].posArray.push({ position: marker.position, seqPosTot: marker.seqPosTot })
          }
        }
        if (marker.codeArray !== undefined && marker.codeArray.length) {
          marker.codeArray.forEach(codeInfo => {
            if (codeInfo.codeId !== null && codeInfo.codeId !== undefined) {
              const codeIndex = codeArray.map(x => { return x.codeId }).indexOf(codeInfo.codeId)
              if (codeIndex < 0) {
                codeArray.push({
                  posArray: [{ position: marker.position, seqPosTot: marker.seqPosTot }],
                  codeId: codeInfo.codeId,
                  colorId: marker.colorId,
                  codeName: codeInfo.codeName
                })
              } else {
                codeArray[codeIndex].posArray.push({ position: marker.position, seqPosTot: marker.seqPosTot })
              }
            }
          })
        }
        if (marker.colorId !== null && marker.colorId !== undefined) {
          const colorIndex = colorArray.map(x => { return x.colorId }).indexOf(marker.colorId)
          if (colorIndex < 0) {
            colorArray.push({
              posArray: [marker.position],
              colorId: marker.colorId
            })
          } else {
            colorArray[colorIndex].posArray.push(marker.position)
          }
        }
        if (marker.colorArray !== undefined && marker.colorArray.length) {
          marker.colorArray.forEach(colorId => {
            if (colorId !== null) {
              const colorIndex = colorArray.map(x => { return x.colorId }).indexOf(colorId)
              if (colorIndex < 0) {
                colorArray.push({
                  posArray: [marker.position],
                  colorId: colorId
                })
              } else {
                colorArray[colorIndex].posArray.push(marker.position)
              }
            }
          })
        }
      })
      pos = 0
      const codeMarker = codeArray.length < 2 ? [] : codeArray.map(x => {
        const position = store.getPosCenterOfPosArray(x.posArray)
        return {
          type: 'codeMarker',
          title: x.codeName,
          position: position,
          routeId: routeId,
          colorId: x.colorId,
          codeId: x.codeId,
          codeName: x.codeName,
          marker: store.getGoogleMarker(getters.getGoogle, {
            type: 'codeMarker',
            map: null,
            label: x.codeName,
            pos: pos++,
            url: getters.getColorIconUrl(x.colorId),
            position: position
          })
        }
      })
      pos = 0
      const cpMarker = cpArray.map(x => {
        return {
          type: 'cpMarker',
          cp: x.cp,
          streetArray: x.streetArray,
          marker: store.getGoogleMarker(getters.getGoogle, {
            type: 'cpMarker',
            label: x.cp,
            pos: pos++,
            map: null,
            position: store.getPosAverageOnFirtsPosOfPosArray(x.posArray)
          })
        }
      })
      pos = 0
      const streetMarker = streetArray.map(x => {
        return {
          type: 'streetMarker',
          street: x.street,
          marker: store.getGoogleMarker(getters.getGoogle, {
            type: 'streetMarker',
            label: x.street,
            pos: pos++,
            map: null,
            position: store.getPosAverageOnFirtsPosOfPosArray(x.posArray)
          })
        }
      })
      const codeLineMarker = codeArray.length < 2 ? [] : codeArray.map(x => {
        return {
          codeId: x.codeId,
          codeName: x.codeName,
          colorId: x.colorId,
          marker: getters.getCodeLineMarker(x)
        }
      })
      pos = 0
      const colorMarker = colorArray.length < 2 ? [] : colorArray.map(x => {
        const position = store.getPosAverageOfPosArray(x.posArray)
        return {
          type: 'colorMarker',
          title: '',
          position: position,
          routeId: routeId,
          colorId: x.colorId,
          marker: store.getGoogleMarker(getters.getGoogle, {
            type: 'colorMarker',
            map: null,
            pos: pos++,
            label: x.colorId ? x.colorId.toString() : ' ',
            url: getters.getColorIconUrl(x.colorId),
            position: position
          }),
          polygon: store.getGooglePolygon(getters.getGoogle, x.posArray, x.colorId),
          circleArray: x.posArray.map(pos => { return getters.getGoogleCircle(pos, x.colorId, 17) })
        }
      })
      return {
        placeMarker: placeMarker,
        cpMarker: cpMarker,
        streetMarker: streetMarker,
        colorMarker: colorMarker,
        codeMarker: codeMarker,
        codeLineMarker: codeLineMarker,
        routeBounds: routeBounds.toJSON()
      }
    },
    getOptionalPocMapItemTypeArray: (state) => {
      return state.optionalPocMapItemTypeArray
    },
    getPrimaryPocMapItemTypeArray: (state) => {
      return state.primaryPocMapItemTypeArray
    },
    isPrimaryPocItem: (state, getters) => (type) => {
      if (getters.getPrimaryPocMapItemTypeArray.indexOf(type) < 0) {
        return false
      } else {
        return true
      }
    },
    userType: (state) => {
      if (state.userLoggedModule) {
        return state.userLoggedModule.data.userType
      } else {
        return 0
      }
    },
    getCurrentUser: (state) => {
      return state.user
    },
    isSoloPocMapItemType: (state) => (type) => {
      // if (['cmb', 'lba', 'dflb'].indexOf(type) < 0) {
      //   return true
      // } else {
      //   return false
      // }
      return (['cmb', 'lba', 'aptlba', 'dflb'].indexOf(type) < 0)
    },
    myAccess: (state, getters) => {
      if (state.officeRulesModuleLoadComplete) {
        return getters['officeRulesModule/getMyAccess']
      } else {
        return { visitor: false, employe: false, admin: false, cSocial: false, isMixComite: false }
      }
    },
    isEmploye: (state, getters) => {
      return getters.myAccess.employe
    },
    isSupportAdmin: (state, getters) => {
      if (getters.userId === 'n7Z61DqH4pMxP0p0Jm3WzHXBVW72' || getters.userId === '9oZnyRmArDSyhtZ4MpvMIVZIhFu2') {
        return true
      } else {
        return false
      }
    },
    isUpdateAccess: (state, getters) => {
      if (getters.isAdminAcces || getters.myAccess.employe) {
        return true
      } else {
        return false
      }
    },
    userIsSignIn: (state) => {
      if (Firebase.auth().currentUser) {
        return true
      } else {
        return false
      }
    },
    // { visitor: false, postmanFfrs: false, postmanSttp: false, employe: false, supervisor: false, admin: false, cSocial: false }
    isPostman: (state, getters) => {
      if (getters.isPostmanFfrs || getters.isPostmanSttp) {
        return true
      } else {
        return false
      }
    },
    isPostmanSttp: (state, getters) => {
      if (state.userLoggedModule) {
        if (state.userLoggedModule.data.userType === 1) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    isPostmanFfrs: (state, getters) => {
      if (state.userLoggedModule) {
        if (state.userLoggedModule.data.userType === 2) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    isSupervisor: (state, getters) => {
      if (state.userLoggedModule) {
        if (state.userLoggedModule.data.userType === 3) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    isPostmanSttpAccess: (state, getters) => {
      if (getters.isPostmanSttp && getters.myAccess.employe) {
        return true
      } else {
        return false
      }
    },
    isPostmanFfrsAccess: (state, getters) => {
      if (getters.ispostmanFfrs && getters.myAccess.employe) {
        return true
      } else {
        return false
      }
    },
    isSupervisorAccess: (state, getters) => {
      if (getters.isSupervisor && getters.myAccess.employe) {
        return true
      } else {
        return false
      }
    },
    isLocalAdmin: (state, getters) => {
      return getters.isAdminAcces
    },
    isAdminAcces: (state, getters) => {
      return getters.myAccess.admin
    },
    isCSocial: (state, getters) => {
      return getters.myAccess.cSocial
    },
    isMixComite: (state, getters) => {
      return getters.myAccess.isMixComite
    },
    showFfrsRouteAccess: (state, getters) => {
      if (getters.isPostmanFfrs) {
        return true
      } else if (getters.isSupervisor) {
        return true
      } else if (getters.isMixComite) {
        return true
      } else if (getters.isAdminAcces) {
        return true
      } else {
        return false
      }
    },
    updateFfrsRouteAccess: (state, getters) => {
      if (getters.showFFrsRouteAccess && getters.isEmploye) {
        return true
      } else {
        return false
      }
    },
    showSttpRouteAccess: (state, getters) => {
      if (getters.isPostmanSttp) {
        return true
      } else if (getters.isSupervisor) {
        return true
      } else if (getters.isMixComite) {
        return true
      } else if (getters.isAdminAcces) {
        return true
      } else {
        return false
      }
    },
    updateSttpRouteAccess: (state, getters) => {
      if (getters.showSttpRouteAccess && getters.isEmploye) {
        return true
      } else {
        return false
      }
    },
    pocRef: (state) => {
      return Firebase.firestore().collection('/officeData/' + state.userLoggedModule.data.officeselected + '/poc/')
    },
    routeRef: (state) => {
      return Firebase.firestore().collection('/officeData/' + state.userLoggedModule.data.officeselected + '/route/')
    },
    userId: (state) => {
      return state.userId
    },
    user: (state, getters) => {
      if (state.userLoggedModule.data) {
        return state.userLoggedModule.data
      } else {
        return null
      }
    },
    currentPath: (state) => {
      return state.currentPath
    },
    getSelectedOffice: (state, getters) => {
      return getters['officeModule/getOffice'](getters.getSelectedOfficeId)
    },
    getSelectedOfficeId: (state) => {
      return state.userLoggedModule.data.officeselected
    },
    getSelectedRouteId: (state) => {
      if (state.userLoggedModule) {
        return state.userLoggedModule.data.routeselected
      } else {
        return null
      }
    },
    getSelectedTravelId: (state, getters) => {
      if (getters['officeTravelModule/getOfficalTravel']) {
        if (state.userLoggedModule && state.userLoggedModule.data && state.userLoggedModule.data.travelselected && state.userLoggedModule.data.travelselected.id) {
          // && state.officeTravelModule.data[state.userLoggedModule.data.travelselected.id] && state.officeTravelModule.data[state.userLoggedModule.data.travelselected.id].routeId === getters.getSelectedTravelId) {
          const travel = getters['officeTravelModule/getTravel'](state.userLoggedModule.data.travelselected.id)
          if (travel && travel.routeId === getters.getSelectedRouteId) {
            return state.userLoggedModule.data.travelselected.id
          } else {
            return getters['officeTravelModule/getOfficalTravel'].id
          }
          // return state.userLoggedModule.data.travelselected.id
        } else {
          // console.log('trace 2')
          return getters['officeTravelModule/getOfficalTravel'].id
        }
      } else {
        // console.log('trace 3 null')
        return null
      }
    },
    getSelectedSequenceId: (state) => {
      if (state.userLoggedModule) {
        return state.userLoggedModule.data.sequenceselected
      } else {
        return null
      }
    },
    getSelectedZoneId: (state) => {
      return state.userLoggedModule.data.zoneselected
    },
    getMessageLog: (state) => {
      return state.sessionMessageLog
    },
    isReportModuleLoadComplete: (state) => {
      return state.reportModuleLoadComplete
    },
    isPocModuleLoadComplete: (state) => {
      return state.pocModuleLoadComplete
    },
    isMessCSocialModuleLoadComplete: (state) => {
      return state.messCSocialModuleLoadComplete
    },
    isMessMixComiteModuleLoadComplete: (state) => {
      return state.messMixComiteModuleLoadComplete
    },
    isMessUserModuleLoadComplete: (state) => {
      return state.messUserModuleLoadComplete
    },
    isMessUserTypeModuleLoadComplete: (state) => {
      return state.messUserTypeModuleLoadComplete
    },
    isMessRouteModuleLoadComplete: (state) => {
      return state.messRouteModuleLoadComplete
    },
    dashBoardMenuSelect: (state) => {
      return state.dashBoardMenuSelect
    },
    contentSize: (state) => {
      return state.contentSize
    },
    contentStyle: (state, getters) => {
      return getters.contentSize.style
    },
    screenSize: (state) => {
      return state.screenSize
    },
    screenStyle: (state, getters) => {
      return getters.screenSize.style
    },
    contentWidth: (state) => {
      return state.contentSize.width
    },
    contentHeight: (state) => {
      return state.contentSize.height
    },
    menuIconSize: (state) => {
      if (window.innerHeight < 600) {
        return 50
      } else {
        return 80
      }
    },
    appBarIconSize: (state) => {
      if (window.innerHeight < 600) {
        return 60
      } else {
        return 80
      }
    },
    appBarHeight: (state) => {
      if (window.innerHeight < 600) {
        return 60
      } else {
        return 80
      }
    },
    appBarExtendHeight: (state) => {
      return state.appBarExtendHeight
    },
    iconSize: (state) => {
      if (state.contentSize.width < 350) {
        return 60
      } else {
        return 80
      }
    },
    distanceOfMeText: (state, getters) => (pos) => {
      const dist = getters.distanceOfMe(pos)
      if (dist > 1000) {
        return (Math.round(dist / 100) / 10) + ' Km'
      } else {
        return (Math.round(dist * 10) / 10) + ' M '
      }
    },
    distanceOfMe: (state, getters) => (pos) => {
      return store.distance(pos, getters.getCurrentPosition)
    },
    getCurrentPosition (state) {
      return state.currentPosition
    },
    getGoogle (state) {
      return state.google
      // return gmapApi
    },
    getDisplayDate: (state, getters) => (date) => {
      var moment = require('moment') // require
      moment.locale(state.userParam.locale)
      return moment(date.seconds * 1000, 'x').fromNow()
    },
    getLocale (state) {
      return state.userParam.locale
    },
    getFullLocale (state) {
      return state.userParam.locale + '-CA'
    },
    getAppIcon () {
      return {
        pub: require('@/assets/Icon/AppIcon/pub.png'),
        noPub: require('@/assets/Icon/AppIcon/noPub.png'),
        vacant: require('@/assets/Icon/AppIcon/vacant.png'),
        noVacant: require('@/assets/Icon/AppIcon/noVacant.png'),
        report: require('@/assets/Icon/AppIcon/noVacant.png')
      }
    },
    getAlertZoneMarker (state, getters) {
      const alertZone = []

      if (state.reportModuleLoadComplete && state.officeModuleLoadComplete && getters.getSelectedOfficeId) {
        if (state.officeModule.data[getters.getSelectedOfficeId].report) {
          for (const reportId of state.officeModule.data[getters.getSelectedOfficeId].report) {
            let zone = null
            let marker = null
            // let icon = ''
            const report = state.reportModule.data[reportId]
            if (report.type === 'dog' || report.type === 'ice' || report.type === 'brokenstep' || report.type === 'other') {
              // if (report.type === 'dog') {
              //   icon = require('@/assets/Icon/MapIcon/dog-side.png')
              // } else if (report.type === 'ice') {
              //   icon = require('@/assets/Icon/MapIcon/snowflake-alert.png')
              // } else if (report.type === 'brokenstep') {
              //   icon = require('@/assets/Icon/MapIcon/stairs-up.png')
              // } else if (report.type === 'other') {
              //   icon = require('@/assets/Icon/MapIcon/alert.png')
              // }
              marker = new state.google.maps.Marker({
                position: { lat: report.dangergps.latitude, lng: report.dangergps.longitude },
                map: null,
                icon: { // url:mdi-toilet,
                  url: store.getAlertPngIcon(report.type),
                  scaledSize: new state.google.maps.Size(30, 30), // scaled size
                  origin: new state.google.maps.Point(0, 0), // origin
                  anchor: new state.google.maps.Point(15, 15) // anchor
                }
              })
              if (report.securedistance) {
                zone = new state.google.maps.Circle({
                  strokeColor: '#FF0000',
                  strokeOpacity: 0.7,
                  strokeWeight: 2,
                  fillColor: '#FF0000',
                  fillOpacity: 0.35,
                  map: null,
                  center: { lat: report.dangergps.latitude, lng: report.dangergps.longitude },
                  radius: report.securedistance
                })
              }
              alertZone.push({ marker: marker, zone: zone, type: report.type, picture: report.picture })
            }
          }
        }
      }
      return alertZone
    },
    // getUserSelectPlacePictureSrc (state) {
    //   return state.userSelectPlacePictureSrc
    // },
    getUserSelectOfficePictureSrc (state) {
      return state.userSelectOfficePictureSrc
    },
    getUserModuleLoadComplete (state) {
      return state.userModuleLoadComplete
    },
    getUserParam (state) {
      return state.userParam
    },
    getGuardianMode (state) {
      if (typeof state.userParam.guardianMode === 'boolean') {
        return {
          active: false,
          noteActive: false,
          dangerActive: false
        }
      } else {
        return state.userParam.guardianMode
      }
    },
    EventBus (state) {
      return state.EventBus
    },
    getUserSelection (state, getters) {
      return getters['userLoggedModule/getSelection']
      // return state.userSelection
    },
    getDayGradient (state, getters) {
      // return getGradientByColorId(getters['paramModule/dayColor'].id)
      if (state.currentThemes) {
        if (getters['userLoggedModule/isDarkThemes']) {
          return state.currentThemes.dark.gradient
        } else {
          return state.currentThemes.light.gradient
        }
      } else {
        return null
      }
    },
    getMarkerLabelSize: (state) => (label) => {
      return store.getMarkerLabelSize(label)
    },
    getDayColorCode (state, getters) {
      if (getters['paramModule/dayColor']) {
        return getters['paramModule/dayColor'].id
      } else {
        return '0'
      }
    },
    getColorCode: (state) => (code) => {
      let cCode = null
      switch (code) {
        case 0:
          cCode = '#424242'
          break
        case 1:
          cCode = state.colorCode.orange
          break
        case '1':
          cCode = state.colorCode.orange
          break
        case 2:
          cCode = state.colorCode.pink
          break
        case '2':
          cCode = state.colorCode.pink
          break
        case 3:
          cCode = state.colorCode.blue
          break
        case '3':
          cCode = state.colorCode.blue
          break
        default:
          cCode = '#424242'
      }
      return cCode
    },
    isUserLoggedModuleLoadComplete (state) {
      return state.userLoggedModuleLoadComplete
    },
    userLoadPartialReady (state) {
      if (!state.paramModuleLoadComplete) {
        return false
      } else if (!state.userLoggedModuleLoadComplete) {
        return false
      } else if (!state.officeSelectedModuleLoadComplete) {
        return false
      } else if (!state.zoneModuleLoadComplete) {
        return false
      } else if (!state.routeModuleLoadComplete) {
        return false
      } else {
        return true
      }
    },
    dataLoadComplete (state) {
      if (!state.pocModuleLoadComplete) {
        return false
      } else if (!state.paramModuleLoadComplete) {
        return false
      } else if (!state.userLoggedModuleLoadComplete) {
        return false
      } else if (!state.officeSelectedModuleLoadComplete) {
        return false
      } else if (!state.zoneModuleLoadComplete) {
        return false
      } else if (!state.reportModuleLoadComplete) {
        return false
      } else {
        return true
      }
    },
    postmanDataLoadPartialReady (state) {
      if (!state.paramModuleLoadComplete) {
        return false
      } else if (!state.userLoggedModuleLoadComplete) {
        return false
      } else if (!state.officeSelectedModuleLoadComplete) {
        return false
      } else if (!state.zoneModuleLoadComplete) {
        return false
      } else if (!state.routeModuleLoadComplete) {
        return false
      } else {
        return true
      }
    },
    postmanDataLoadCompleteReady (state, getters) {
      if (!getters.postmanDataLoadPartialReady) {
        return false
      } else if (!state.pocModuleLoadComplete) {
        return false
      } else if (!state.reportModuleLoadComplete) {
        return false
      } else if (!state.officeModuleLoadComplete) {
        return false
      } else if (!state.placeModuleLoadComplete) {
        return false
      } else if (!state.routeDayDataModuleLoadComplete) {
        return false
      } else {
        return true
      }
    },
    userLoadCompleteReady (state) {
      if (!state.pocModuleLoadComplete) {
        return false
      } else if (!state.paramModuleLoadComplete) {
        return false
      } else if (!state.userLoggedModuleLoadComplete) {
        return false
      } else if (!state.officeModuleLoadComplete) {
        return false
      } else if (!state.zoneModuleLoadComplete) {
        return false
      } else if (!state.reportModuleLoadComplete) {
        return false
      } else {
        return true
      }
    },
    // user (state) {
    //   return state.user
    // },
    message (state) {
      return state.systemMessage
    },
    info (state) {
      return state.systemInfo
    },
    alert (state) {
      return state.systemAlert
    },
    userinfokey (state) {
      return state.userinfokey
    },
    userloading (state) {
      return state.userloading
    },
    authError (state) {
      return state.authError
    },
    waitlogin (state) {
      return state.waitlogin
    },
    loadfile (state) {
      return state.loadfile
    },
    loadingProgress (state) {
      return state.loadingProgress
    },
    loadingProgressValue (state) {
      return state.loadingProgressValue
    },
    loadingProgressTitle (state) {
      return state.loadingProgressTitle
    }
  },
  mutations: {
    setToday (state, payload) {
      state.today = payload
      // this.dispatch('routeDayDataModule/initDay', state.today)
    },
    setCurrentThemes (state, payload) {
      state.currentThemes = payload
    },
    setMasterLoadingStatus (state, payload) {
      if (payload) {
        state.masterLoadingStatus = payload
      } else {
        state.masterLoadingStatus = { showLoading: false }
      }
    },
    setShowMasterLoading (state, payload) {
      if (payload) {
        state.masterLoadingStatus.showLoading = payload
      } else {
        state.masterLoadingStatus = { showLoading: false }
      }
    },
    setMasterLoadingTitle (state, payload) {
      state.masterLoadingStatus.title = payload
    },
    setMasterLoadingSubtitle (state, payload) {
      state.masterLoadingStatus.subTitle = payload
    },
    setMasterLoadingProgessValue (state, payload) {
      state.masterLoadingStatus.progressValue = payload
    },
    setUserId (state, payload) {
      if (state.userId !== payload) {
        if (payload) {
          if (!state.userId) {
            this.dispatch('userLoggedModule/openDBChannel', { pathVariables: { userId: payload } })
              .then(() => {
                state.userLoggedModuleLoadComplete = true
                this.dispatch('loadUserInfo')
                this.dispatch('openUserDataCollection')
              })
          } else {
            this.dispatch('userLoggedModule/closeDBChannel', { clearModule: true })
              .then(() => {
                state.userLoggedModuleLoadComplete = false
                state.userModuleLoadComplete = false
                this.dispatch('userLoggedModule/openDBChannel', { pathVariables: { userId: payload } })
                  .then(() => {
                    state.userLoggedModuleLoadComplete = true
                    state.userModuleLoadComplete = true
                    this.dispatch('openUserDataCollection')
                  })
              })
          }
        } else {
          this.dispatch('userLoggedModule/closeDBChannel', { clearModule: true })
            .then(() => {
              state.userLoggedModuleLoadComplete = false
              state.userModuleLoadComplete = false
            })
        }
        state.userId = payload
      }
    },
    setUserEmail (state, payload) {
      state.userEmail = payload
    },
    setCurrentPath (state, payload) {
      if (state.path !== payload.fullPath) {
        state.path = payload.fullPath
      }
    },
    setDashBoardMenuSelect (state, payload) {
      state.dashBoardMenuSelect = payload
    },
    addMessageLog (state, payload) {
      state.sessionMessageLog.push(payload)
    },
    setAppBarExtendHeight (state, payload) {
      state.appBarExtendHeight = payload
    },
    setScreenSize (state) {
      state.screenSize.height = window.innerHeight
      state.screenSize.width = window.innerWidth
      state.screenSize.style = 'width:' + window.innerWidth + 'px; height:' + window.innerHeight + 'px'
    },
    setContentSize (state) {
      state.contentSize.height = window.innerHeight - (this.getters.appBarHeight + state.appBarExtendHeight)
      state.contentSize.width = window.innerWidth
      state.contentSize.style = 'width:' + window.innerWidth + 'px; height:' + state.contentSize.height + 'px'
    },
    setGoogle (state, payload) {
      state.google = payload
    },
    setCurrentPosition (state, payload) {
      state.currentPosition = payload
    },
    setUser (state, payload) {
      // if (payload) {
      //   state.waitlogin = false
      // } else {
      //   state.waitlogin = true
      //   state.userinfokey = null
      // }
      state.user = payload
    },
    setGuardianMode (state, payload) {
      if (typeof state.userParam.guardianMode === 'boolean') {
        state.userParam.guardianMode = {
          active: false,
          noteActive: false,
          dangerActive: false
        }
      }
      let toSave = false
      if (payload.active !== undefined && state.userParam.guardianMode.active !== payload.active) {
        state.userParam.guardianMode.active = payload.active
        toSave = true
      }
      if (payload.noteActive !== undefined && state.userParam.guardianMode.noteActive !== payload.noteActive) {
        state.userParam.guardianMode.noteActive = payload.noteActive
        toSave = true
      }
      if (payload.dangerActive !== undefined && state.userParam.guardianMode.dangerActive !== payload.dangerActive) {
        state.userParam.guardianMode.dangerActive = payload.dangerActive
        toSave = true
      }
      if (toSave) {
        return this.dispatch('saveUserParam')
      } else {
        return null
      }
    },
    setAlertDistance (state, payload) {
      state.alertDistance = payload
    },
    setUserParamLocale (state, payload) {
      state.userParam.locale = payload
      state.EventBus.$emit('setLocale', payload)
      this.dispatch('saveUserParam')
    },
    setUserParamMapInfo (state, payload) {
      state.userParam.mapInfo = payload
      this.dispatch('saveUserParam')
    },
    setUserParamMapInfoShowColorLine (state, payload) {
      state.userParam.mapInfo.showColorLine = payload
      this.dispatch('saveUserParam')
    },
    setUserParamMapInfoShowMe (state, payload) {
      state.userParam.mapInfo.showMe = payload
      this.dispatch('saveUserParam')
    },
    setUserParamMapInfoTrackMe (state, payload) {
      state.userParam.mapInfo.trackMe = payload
      this.dispatch('saveUserParam')
    },
    setUserParamMapInfoShowPocMarker (state, payload) {
      state.userParam.mapInfo.showPocMarker = payload
      this.dispatch('saveUserParam')
    },
    setUserParamMapInfoShowDog (state, payload) {
      state.userParam.mapInfo.showDog = payload
      this.dispatch('saveUserParam')
    },
    setUserParamMapInfoShowToilet (state, payload) {
      state.userParam.mapInfo.showToilet = payload
      this.dispatch('saveUserParam')
    },
    setUserParamMapInfoShowGreyBox (state, payload) {
      state.userParam.mapInfo.showGreyBox = payload
      this.dispatch('saveUserParam')
    },
    setUserParamMapInfoShowParking (state, payload) {
      state.userParam.mapInfo.showParking = payload
      this.dispatch('saveUserParam')
    },
    setUserSelectOfficePictureSrc (state) {
      const officeLocation = state.officeSelectedModule.data.location[0]
      getPictureSrc(officeLocation.loc.latitude, officeLocation.loc.longitude, window.innerWidth, 270)
        .then((src) => {
          state.userSelectOfficePictureSrc = src
        })
    },
    // setUserSelectPlacePictureSrc (state) {
    //   if (state.userSelection.place && state.pocModuleLoadComplete) {
    //     const place = this.getters['pocModule/getPlaceLocation'](state.userSelection.place)
    //     getPictureSrc(place.lat, place.lng, window.innerWidth, 270)
    //       .then((src) => {
    //         state.userSelectPlacePictureSrc = src
    //       })
    //   } else {
    //     state.userSelectPlacePictureSrc = null
    //   }
    // },
    setUserSelectionGlobal (state, payload) {
      this.dispatch('setUserSelection', (payload))
      if (payload.office) {
        const officeLocation = state.officeSelectedModule.data.location[0]
        getPictureSrc(officeLocation.loc.latitude, officeLocation.loc.longitude, window.innerWidth, 270)
          .then((src) => {
            state.userSelectOfficePictureSrc = src
          })
      } else {
        state.userSelectOfficePictureSrc = null
      }
      // this.dispatch('updateUserSelectPlacePictureSrc')
      // if (payload.place) {
      //   const place = this.getters['pdrModule/getPlaceLocation'](payload.place)
      //   getPictureSrc(place.loc.latitude, place.loc.longitude, window.innerWidth, 270)
      //     .then((src) => {
      //       state.userSelectPlacePictureSrc = src
      //     })
      // } else {
      //   state.userSelectPlacePictureSrc = null
      // }
      this.dispatch('saveUserSelection')
    },
    setUserSelectionPdrOfOffice (state, payload) {
      state.userSelection.color = payload.color
      state.userSelection.code = payload.codeId
      state.userSelection.place = payload.placeId ? payload.placeId : null
      state.userSelection.pdr = payload.id
      this.dispatch('saveUserSelection')
      this.dispatch('userLoggedModule/updateMapMode', 'route-info')
      state.EventBus.$emit('showMap')
    },
    setDayColor (state, payload) {
      if (state.dayColor !== payload.id) {
        state.dayColor = payload.color
        state.EventBus.$emit('setVuetifyThemes', payload.id)
      }
    },
    setParamModuleLoadComplete (state, payload) {
      state.paramModuleLoadComplete = payload
    },
    setofficeModuleLoadComplete (state, payload) {
      state.officeModuleLoadComplete = payload
    },
    setofficeItemModuleLoadComplete (state, payload) {
      state.officeItemModuleLoadComplete = payload
    },
    setofficeTravelModuleLoadComplete (state, payload) {
      state.officeTravelModuleLoadComplete = payload
    },
    setofficeSelectedModuleLoadComplete (state, payload) {
      state.officeSelectedModuleLoadComplete = payload
    },
    setofficeRulesModuleLoadComplete (state, payload) {
      state.officeRulesModuleLoadComplete = payload
    },
    setofficeMailNoticeModuleLoadComplete (state, payload) {
      state.officeMailNoticeModuleLoadComplete = payload
    },
    setuserModuleLoadComplete (state, payload) {
      state.userModuleLoadComplete = payload
    },
    setpdrModuleLoadComplete (state, payload) {
      state.pdrModuleLoadComplete = payload
      // onsole.log('state pdrModuleloadComplete =' + payload)
    },
    setpocModuleLoadComplete (state, payload) {
      state.pocModuleLoadComplete = payload
    },
    setplaceModuleLoadComplete (state, payload) {
      state.placeModuleLoadComplete = payload
    },
    setzoneModuleLoadComplete (state, payload) {
      state.zoneModuleLoadComplete = payload
    },
    setrouteModuleLoadComplete (state, payload) {
      state.routeModuleLoadComplete = payload
    },
    setrouteDayDataModuleLoadComplete (state, payload) {
      state.routeDayDataModuleLoadComplete = payload
      if (payload && state.today) {
        this.dispatch('routeDayDataModule/initDay', state.today)
      }
    },
    setMessUserModuleLoadComplete (state, payload) {
      state.messUserModuleLoadComplete = payload
    },
    setMessCSocialModuleLoadComplete (state, payload) {
      state.messCSocialModuleLoadComplete = payload
    },
    setMessMixComiteModuleLoadComplete (state, payload) {
      state.messMixComiteModuleLoadComplete = payload
    },
    setMessUserTypeModuleLoadComplete (state, payload) {
      state.messUserTypeModuleLoadComplete = payload
    },
    setMessRouteModuleLoadComplete (state, payload) {
      state.messRouteModuleLoadComplete = payload
    },
    setofficeItemMultiModuleLoadComplete (state, payload) {
      state.officeItemMultiModuleLoadComplete = payload
    },
    setTaskRouteModuleLoadComplete (state, payload) {
      state.taskRouteModuleLoadComplete = payload
    },
    setreportModuleLoadComplete (state, payload) {
      state.reportModuleLoadComplete = payload
      // onsole.log('state reportModuleloadComplete =' + payload)
    },
    setUserinfo (state) {
      if (state.userLoggedModule.data.selection) {
        state.userSelection = state.userLoggedModule.data.selection
        // this.dispatch('updateUserSelectPlacePictureSrc')
        // this.dispatch('setUserSelection', (state.userLoggedModule.data.selection))
        // if (state.userSelection.office) {
        //   this.dispatch('openPostmanDataCollection', (state.userSelection.office))
        // }
        // this.dispatch('setUserSelection', (this.state.userModule.data[state.userinfokey].selection))
      } else {
        this.dispatch('saveUserSelection')
      }
      if (state.userLoggedModule.data.param) {
        state.userParam = state.userLoggedModule.data.param
      } else {
        this.dispatch('saveUserParam')
      }
      state.EventBus.$emit('setLocale', state.userParam.locale)
      // state.userModuleLoadComplete = true
      // this.commit('setuserModuleLoadComplete', true)
      // this.dispatch('loadUserData')
    },
    setWaitlogin (state, payload) {
      state.waitlogin = payload
    },
    setUserLoading (state, payload) {
      state.userloading = payload
    },
    setAuthError (state, payload) {
      state.authError = payload
    },
    clearAuthError (state) {
      state.authError = null
    },
    addfinishloadfile (state) {
      state.loadfilecpt++
      if (state.loadfilecpt === 6) {
        state.loadfilecompleted = true
        state.userLoadComplete = true
        state.userLoadCompleteReady = true
        // onsole.log('userLoadComplete = ' + state.userLoadComplete)
      }
    },
    setloadfilestart (state) {
      state.loadfilecompleted = false
      state.loadfile = true
      state.loadfilecpt = 0
    },
    setloadfile (state) {
      state.loadfile = false
    },
    setSystemMessage (state, payload) {
      state.systemMessage = payload
    },
    setSystemInfo (state, payload) {
      state.systemInfo = payload
    },
    setSystemAlert (state, payload) {
      state.systemAlert = payload
    },
    setLoadingProgress (state, payload) {
      state.loadingProgress = payload
    },
    setLoadingProgressValue (state, payload) {
      // onsole.log('trace progress dans state = ' + payload)
      // state.loadingProgressValue = 50
      // state.loadingProgressValue = payload
      if (payload === 50) {
        state.loadingProgressValue = 25
      } else {
        state.loadingProgressValue = 50
      }
      state.loadingProgressValue = payload
    },
    setLoadingProgressTitle (state, payload) {
      state.loadingProgressTitle = payload
    }
  },
  actions: {
    doBatch ({ commit }, payload) {
      function doBatch (doList) {
        var batchList
        var toDoList = null
        if (doList.length > 500) {
          batchList = doList.slice(0, 500)
          toDoList = doList.slice(500)
        } else {
          batchList = doList
        }
        return Promise.all(batchList).then(() => {
          if (toDoList) {
            return doBatch(toDoList)
          } else {
            return true
          }
        })
          .catch((err) => {
            return this.dispatch('showAlert', err.message)
          })
      }
      return doBatch(payload)
    },
    setGoogle ({ commit }, payload) {
      return commit('setGoogle', payload)
    },
    assignCurrentThemes ({ commit, getters, state }, payload) {
      switch (getters['userLoggedModule/getThemesMode']) {
        case 'auto':
          return commit('setCurrentThemes', this.getThemesByColorId(getters.getDayColorCode))
        default:
          return commit('setCurrentThemes', this.getThemesByName(getters['userLoggedModule/getThemesMode']))
      }
    },
    createTask ({ commit, getters, state }, payload) {
      // var ts = Firebase.firestore.Timestamp.fromDate(new Date())
      const prom = []
      switch (payload.type) {
        case 'createReport':
          break
        default:
          break
      }
      return Promise.all(prom)
    },
    sendMessage ({ commit, getters, state }, payload) {
      var ts = Firebase.firestore.Timestamp.fromDate(new Date())
      const prom = []
      switch (payload.type) {
        case 'pubUpdate':
          payload.routeIdArray.forEach(routeId => {
            const mess = {
              type: payload.type,
              pub: payload.pub,
              address: payload.address,
              isActive: true,
              byName: state.userLoggedModule.data.fname + ' ' + state.userLoggedModule.data.lname,
              byEmpId: state.userLoggedModule.data.empid,
              byDate: ts,
              readBy: [],
              deleteBy: []
            }
            if (getters.getSelectedRouteId === routeId) {
              prom.push(this.dispatch('messRouteModule/set', mess))
            } else {
              mess.created_at = ts
              mess.created_by = getters.userId
              prom.push(Firebase.firestore().collection('officeData/' + payload.officeId + '/messRoute/' + routeId + '/mess').add(mess))
            }
          })
          break
        case 'vacantUpdate':
          payload.routeIdArray.forEach(routeId => {
            const mess = {
              type: payload.type,
              vacant: payload.vacant,
              address: payload.address,
              isActive: true,
              created_at: ts,
              created_by: getters.userId,
              byDate: ts,
              byName: state.userLoggedModule.data.fname + ' ' + state.userLoggedModule.data.lname,
              byEmpId: state.userLoggedModule.data.empid,
              readBy: [],
              deleteBy: []
            }
            if (getters.getSelectedRouteId === routeId) {
              prom.push(this.dispatch('messRouteModule/set', mess))
            } else {
              prom.push(Firebase.firestore().collection('officeData/' + payload.officeId + '/messRoute/' + routeId + '/mess').add(mess))
            }
          })
          break
        case 'userMess':
          payload.dest.forEach(userId => {
            const mess = {
              type: 'message',
              messageType: payload.type,
              title: payload.title,
              message: payload.message,
              imageList: payload.imageList,
              from: payload.from,
              created_at: ts,
              created_by: getters.userId,
              isActive: true,
              isRead: false,
              isDelete: false,
              byDate: ts,
              byName: state.userLoggedModule.data.fname + ' ' + state.userLoggedModule.data.lname,
              byEmpId: state.userLoggedModule.data.empid
            }
            if (userId !== getters.userId) {
              prom.push(Firebase.firestore().collection('userData/' + userId + '/messUser').add(mess))
            } else {
              prom.push(this.dispatch('messUserModule/set', mess))
            }
          })
          break
        case 'route':
          payload.dest.forEach(routeId => {
            const mess = {
              type: 'message',
              messageType: payload.type,
              title: payload.title,
              message: payload.message,
              imageList: payload.imageList,
              from: payload.from,
              created_at: ts,
              created_by: getters.userId,
              isActive: true,
              byDate: ts,
              byName: state.userLoggedModule.data.fname + ' ' + state.userLoggedModule.data.lname,
              byEmpId: state.userLoggedModule.data.empid,
              readBy: [],
              deleteBy: []
            }
            if (routeId !== getters.getSelectedRouteId) {
              prom.push(Firebase.firestore().collection('officeData/' + getters.getSelectedOfficeId + '/messRoute/' + routeId + '/mess').add(mess))
            } else {
              prom.push(this.dispatch('messRouteModule/set', mess))
            }
          })
          break
        case 'group':
          payload.dest.forEach(groupId => {
            const mess = {
              type: 'message',
              messageType: payload.type,
              title: payload.title,
              message: payload.message,
              imageList: payload.imageList,
              from: payload.from,
              created_at: ts,
              created_by: getters.userId,
              isActive: true,
              byDate: ts,
              byName: state.userLoggedModule.data.fname + ' ' + state.userLoggedModule.data.lname,
              byEmpId: state.userLoggedModule.data.empid,
              readBy: [],
              deleteBy: []
            }
            switch (groupId) {
              case 'supervisor':
                if (getters.isSupervisorAccess) {
                  prom.push(this.dispatch('messUserTypeModule/set', mess))
                } else {
                  prom.push(Firebase.firestore().collection('officeData/' + getters.getSelectedOfficeId + '/messUserType/3/mess').add(mess))
                }
                break
              case 'postmansttp':
                if (getters.isPostmanSttpAccess) {
                  prom.push(this.dispatch('messUserTypeModule/set', mess))
                } else {
                  prom.push(Firebase.firestore().collection('officeData/' + getters.getSelectedOfficeId + '/messUserType/1/mess').add(mess))
                }
                break
              case 'postmanffrs':
                if (getters.isPostmanFfrsAccess) {
                  prom.push(this.dispatch('messUserTypeModule/set', mess))
                } else {
                  prom.push(Firebase.firestore().collection('officeData/' + getters.getSelectedOfficeId + '/messUserType/2/mess').add(mess))
                }
                break
              case 'csocial':
                if (getters.isCSocial) {
                  prom.push(this.dispatch('messCSocialModule/set', mess))
                } else {
                  prom.push(Firebase.firestore().collection('officeData/' + getters.getSelectedOfficeId + '/messUserType/csocial/mess').add(mess))
                }
                break
              case 'mixcomite':
                if (getters.isCSocial) {
                  prom.push(this.dispatch('messMixComiteModule/set', mess))
                } else {
                  prom.push(Firebase.firestore().collection('officeData/' + getters.getSelectedOfficeId + '/messUserType/mixcomite/mess').add(mess))
                }
                break
              default:
                break
            }
          })
          break
        case 'createReport':
          prom.push(this.dispatch('messMixComiteModule/set', {
            type: 'createReport',
            reportId: payload.reportId,
            byDate: ts,
            isActive: true,
            byName: state.userLoggedModule.data.fname + ' ' + state.userLoggedModule.data.lname,
            byEmpId: state.userLoggedModule.data.empid,
            readBy: [],
            deleteBy: []
          }))
          state.reportModule.data[payload.reportId].routeIdArray.forEach(routeId => {
            if (routeId !== getters.getSelectedRouteId) {
              prom.push(Firebase.firestore().collection('officeData/' + getters.getSelectedOfficeId + '/messRoute/' + routeId + '/mess').add({
                type: 'newRouteReport',
                reportId: payload.reportId,
                byDate: ts,
                isActive: true,
                byName: state.userLoggedModule.data.fname + ' ' + state.userLoggedModule.data.lname,
                byEmpId: state.userLoggedModule.data.empid,
                readBy: [],
                deleteBy: []
              }))
            } else {
              prom.push(this.dispatch('messRouteModule/set', {
                type: 'newRouteReport',
                reportId: payload.reportId,
                byDate: ts,
                isActive: true,
                byName: state.userLoggedModule.data.fname + ' ' + state.userLoggedModule.data.lname,
                byEmpId: state.userLoggedModule.data.empid,
                readBy: [],
                deleteBy: []
              }))
            }
          })
          break
        default:
          break
      }
      return Promise.all(prom)
    },
    // updateUserSelectPlacePictureSrc ({ commit }) {
    //   commit('setUserSelectPlacePictureSrc')
    // },
    loadUserInfo ({ commit }) {
      commit('setUserinfo')
    },
    changeOffice ({ commit, getters, state }, payload) {
      this.dispatch('userLoggedModule/set', { officeselected: payload }).then(() => {
        // const userSelection = {
        //   zone: state.userSelection.zone,
        //   office: payload,
        //   route: null,
        //   color: null,
        //   code: null,
        //   place: null
        // }
        // this.dispatch('setUserSelection', userSelection)
        this.dispatch('changeOfficePostmanDataCollection')
      })
    },
    postmanChangeRoute ({ commit, getters, state }, payload) {
      return this.dispatch('doChangeRoutePostmanDataCollection', payload).then(() => {
        return commit('userLoggedModule/setRouteSelected', {
          routeId: payload,
          travelId: getters['officeTravelModule/getOfficialTravelOfRoute'](payload) ? getters['officeTravelModule/getOfficialTravelOfRoute'](payload).id : null
        })
      })
      // this.dispatch('userLoggedModule/set', { routeselected: payload, sequenceselected: 0 }).then(() => {
      //   const userSelection = {
      //     zone: state.userSelection.zone,
      //     office: state.userSelection.office,
      //     route: null,
      //     color: null,
      //     code: null,
      //     place: null
      //   }
      //   this.dispatch('setUserSelection', userSelection)
      //   this.dispatch('changeRoutePostmanDataCollection', payload)
      // })
    },
    changeSequence ({ commit, getters, state }, payload) {
      this.dispatch('userLoggedModule/set', { sequenceselected: payload })
      const userSelection = {
        zone: state.userSelection.zone,
        office: state.userSelection.office,
        route: state.userSelection.route,
        color: null,
        code: null,
        place: null
      }
      this.dispatch('setUserSelection', userSelection)
    },
    onRouterpathChanged ({ commit }, payload) {
      commit('setCurrentPath', (payload))
    },
    openUserDataCollection ({ commit, getters, state }) {
      this.dispatch('openCommonDataCollection')
      this.dispatch('messUserModule/openDBChannel', { pathVariables: { userId: state.userId } })
        .then(() => {
          commit('setMessUserModuleLoadComplete', true)
        })
        .catch(error => {
          this.dispatch('showAlert', error.message)
        })
      this.dispatch('logModule/setPathVars', { officeId: getters.getSelectedOfficeId })
    },
    openCommonDataCollection ({ commit, getters, state }) {
      this.dispatch('officeModule/openDBChannel', { where: [['zone', '==', getters.getSelectedZoneId]] })
        .then(() => {
          this.dispatch('addToLog', ' officeModule openend')
          commit('setofficeModuleLoadComplete', true)
        })
        .catch(error => {
          this.dispatch('addToLog', ' erreur load OfficeModule')
          this.dispatch('showAlert', error.message)
          // Same as the other `catch` block above
        })
      this.dispatch('zoneModule/openDBChannel')
        .then(() => {
          this.dispatch('addToLog', ' zoneModule openned')
          commit('setzoneModuleLoadComplete', true)
        })
        .catch(error => {
          this.dispatch('showAlert', error.message)
        })
      this.dispatch('officeSelectedModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId } })
        .then(() => {
          commit('setofficeSelectedModuleLoadComplete', true)
          commit('setUserSelectOfficePictureSrc')
        })
        .catch((error) => {
          this.dispatch('showAlert', error.message)
        })
      this.dispatch('officeRulesModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId } })
        .then(() => {
          commit('setofficeRulesModuleLoadComplete', true)
          if (getters.isCSocial) {
            this.dispatch('messCSocialModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId } })
              .then(() => {
                commit('setMessCSocialModuleLoadComplete', true)
              })
              .catch(error => {
                this.dispatch('showAlert', error.message)
              })
          } else {
            this.dispatch('messCSocialModule/setPathVars', { officeId: getters.getSelectedOfficeId })
          }
          if (getters.isMixComite) {
            this.dispatch('messMixComiteModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId } })
              .then(() => {
                commit('setMessMixComiteModuleLoadComplete', true)
              })
              .catch(error => {
                this.dispatch('showAlert', error.message)
              })
          } else {
            this.dispatch('messMixComiteModule/setPathVars', { officeId: getters.getSelectedOfficeId })
          }
        })
        .catch((error) => {
          this.dispatch('showAlert', error.message)
        })
      this.dispatch('officeMailNoticeModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId } })
        .then(() => {
          commit('setofficeMailNoticeModuleLoadComplete', true)
        })
        .catch((error) => {
          this.dispatch('showAlert', error.message)
        })
      this.dispatch('officeItemMultiModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId } })
        .then(() => {
          commit('setofficeItemMultiModuleLoadComplete', true)
        })
      this.dispatch('officeItemModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId } })
        .then(() => {
          commit('setofficeItemModuleLoadComplete', true)
        })
      this.dispatch('officeTravelModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId } })
        .then(() => {
          commit('setofficeTravelModuleLoadComplete', true)
        })
      this.dispatch('messUserTypeModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId, userType: getters.userType } })
        .then(() => {
          commit('setMessUserTypeModuleLoadComplete', true)
        })
        .catch(error => {
          this.dispatch('showAlert', error.message)
        })
    },
    openAdminLocalDataCollection ({ commit, getters, state }, payload) {
      this.dispatch('routeModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId } })
        .then(() => {
          commit('setrouteModuleLoadComplete', true)
        })
      this.dispatch('pocModule/setPathVars', { officeId: state.userLoggedModule.data.officeselected })
      this.dispatch('harmonizeModule/setPathVars', { officeId: state.userLoggedModule.data.officeselected })

      // this.dispatch('officeSelectedModule/openDBChannel', { pathVariables: { officeId: state.userLoggedModule.data.officeselected } })
      //   .then(() => {
      //   })
      // this.dispatch('harmonizeModule/openDBChannel', { pathVariables: { officeId: payload } })
      //   .then(() => {
      //   })
    },
    openSupervisorDataCollection ({ commit, getters, state }) {
      this.dispatch('taskOfficeModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId } })
    },
    openPostmanDataCollection ({ commit, getters, state }) {
      this.dispatch('taskOfficeModule/setPathVars', { officeId: getters.getSelectedOfficeId })

      // this.dispatch('routeModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId }, clauses: { where: [['type', '==', getters.isPostmanSttp ? 1 : 2]] } })
      this.dispatch('routeModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId } })
        .then(() => {
          commit('setrouteModuleLoadComplete', true)
        })
      if (getters.getSelectedRouteId) {
        if (getters.isPostman) {
          this.dispatch('taskRouteModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId }, clauses: { where: [['routeId', '==', getters.getSelectedRouteId]] } })
            .then(() => {
              commit('setTaskRouteModuleLoadComplete', true)
            })
          this.dispatch('messRouteModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId, routeId: getters.getSelectedRouteId } })
            .then(() => {
              commit('setMessRouteModuleLoadComplete', true)
            })
        }
        this.dispatch('pocModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId }, clauses: { where: [['route', 'array-contains', getters.getSelectedRouteId]] } })
          .then(() => {
            commit('setpocModuleLoadComplete', true)
          })
        this.dispatch('placeModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId }, clauses: { where: [['route', 'array-contains', getters.getSelectedRouteId]] } })
          .then(() => {
            commit('setplaceModuleLoadComplete', true)
          })
        this.dispatch('routeDayDataModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId, routeId: getters.getSelectedRouteId } })
          .then(() => {
            commit('setrouteDayDataModuleLoadComplete', true)
          })
      } else {
        this.dispatch('pocModule/setPathVars', { officeId: getters.getSelectedOfficeId })
        this.dispatch('placeModule/setPathVars', { officeId: getters.getSelectedOfficeId })
      }
      this.dispatch('reportModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId } })
        .then(() => {
          commit('setreportModuleLoadComplete', true)
        })
        .catch(error => {
          this.dispatch('showAlert', error.message)
        })
      // this.dispatch('pocModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId } })
    },
    /*
    changeRoutePostmanDataCollection ({ commit, getters }, payload) {
      commit('setpocModuleLoadComplete', false)
      this.dispatch('pocModule/closeDBChannel', { clearModule: true }).then(() => {
        if (payload) {
          this.dispatch('pocModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId }, clauses: { where: [['route', 'array-contains', getters.getSelectedRouteId]] } })
            .then(() => {
              // commit('setUserSelectionRoute', (payload))
              commit('setpocModuleLoadComplete', true)
            })
        }
      })
      commit('setplaceModuleLoadComplete', false)
      this.dispatch('placeModule/closeDBChannel', { clearModule: true }).then(() => {
        if (payload) {
          this.dispatch('placeModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId }, clauses: { where: [['route', 'array-contains', getters.getSelectedRouteId]] } })
            .then(() => {
              commit('setplaceModuleLoadComplete', true)
            })
        }
      })
      if (getters.isPostman) {
        commit('setTaskRouteModuleLoadComplete', false)
        this.dispatch('taskRouteModule/closeDBChannel', { clearModule: true }).then(() => {
          this.dispatch('taskRouteModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId }, clauses: { where: [['routeId', '==', payload]] } })
            .then(() => {
              commit('setTaskRouteModuleLoadComplete', true)
            })
        })
        commit('setMessRouteModuleLoadComplete', false)
        this.dispatch('messRouteModule/closeDBChannel', { clearModule: true }).then(() => {
          this.dispatch('messRouteModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId, routeId: payload } })
            .then(() => {
              commit('setMessRouteModuleLoadComplete', true)
            })
        })
      }
    },
    */
    doChangeRoutePostmanDataCollection ({ commit, getters }, payload) {
      const prom = []
      commit('setpocModuleLoadComplete', false)
      prom.push(this.dispatch('pocModule/closeDBChannel', { clearModule: true }).then(() => {
        if (payload) {
          return this.dispatch('pocModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId }, clauses: { where: [['route', 'array-contains', payload]] } })
            .then(() => {
              // commit('setUserSelectionRoute', (payload))
              return commit('setpocModuleLoadComplete', true)
            })
        } else { return null }
      }))
      commit('setrouteDayDataModuleLoadComplete', false)
      prom.push(this.dispatch('routeDayDataModule/closeDBChannel', { clearModule: true }).then(() => {
        if (payload) {
          return this.dispatch('routeDayDataModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId, routeId: payload } })
            .then(() => {
              commit('setrouteDayDataModuleLoadComplete', true)
            })
        } else { return null }
      }))
      commit('setplaceModuleLoadComplete', false)
      prom.push(this.dispatch('placeModule/closeDBChannel', { clearModule: true }).then(() => {
        if (payload) {
          return this.dispatch('placeModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId }, clauses: { where: [['route', 'array-contains', payload]] } })
            .then(() => {
              return commit('setplaceModuleLoadComplete', true)
            })
        } else { return null }
      }))
      if (getters.isPostman) {
        commit('setTaskRouteModuleLoadComplete', false)
        prom.push(this.dispatch('taskRouteModule/closeDBChannel', { clearModule: true }).then(() => {
          return this.dispatch('taskRouteModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId }, clauses: { where: [['routeId', '==', payload]] } })
            .then(() => {
              return commit('setTaskRouteModuleLoadComplete', true)
            })
        }))
        commit('setMessRouteModuleLoadComplete', false)
        prom.push(this.dispatch('messRouteModule/closeDBChannel', { clearModule: true }).then(() => {
          return this.dispatch('messRouteModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId, routeId: payload } })
            .then(() => {
              return commit('setMessRouteModuleLoadComplete', true)
            })
        }))
      }
      return Promise.all(prom)
    },
    changeOfficePostmanDataCollection ({ commit, getters, state }) {
      commit('setofficeSelectedModuleLoadComplete', false)
      this.dispatch('officeSelectedModule/closeDBChannel', { clearModule: true }).then(() => {
        this.dispatch('officeSelectedModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId } })
          .then(() => {
            commit('setofficeSelectedModuleLoadComplete', true)
            commit('setUserSelectOfficePictureSrc')
          })
          .catch((error) => {
            this.dispatch('showAlert', error.message)
          })
      })
      commit('setofficeRulesModuleLoadComplete', false)
      this.dispatch('officeRulesModule/closeDBChannel', { clearModule: true }).then(() => {
        this.dispatch('officeRulesModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId } })
          .then(() => {
            commit('setofficeRulesModuleLoadComplete', true)
            // commit('setUserRules', (getters['officeRulesModule/getEmpAcces'](state.userLoggedModule.data.empid)))
          })
          .catch((error) => {
            this.dispatch('showAlert', error.message)
          })
      })
      commit('setofficeMailNoticeModuleLoadComplete', false)
      this.dispatch('officeMailNoticeModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId } })
        .then(() => {
          commit('setofficeMailNoticeModuleLoadComplete', true)
          // commit('setUserRules', (getters['officeRulesModule/getEmpAcces'](state.userLoggedModule.data.empid)))
        })
        .catch((error) => {
          this.dispatch('showAlert', error.message)
        })
      commit('setpocModuleLoadComplete', false)
      this.dispatch('pocModule/closeDBChannel', { clearModule: true }).then(() => {
        this.dispatch('pocModule/setPathVars', { officeId: getters.getSelectedOfficeId })
          .then(() => {
            if (getters.getSelectedRouteId) {
              this.dispatch('pocModule/openDBChannel', { where: [['route', 'array-contains', getters.getSelectedRouteId]] })
                .then(() => {
                  commit('setpocModuleLoadComplete', true)
                })
            }
          })
      })
      commit('setplaceModuleLoadComplete', false)
      this.dispatch('placeModule/closeDBChannel', { clearModule: true }).then(() => {
        if (getters.getSelectedRouteId) {
          this.dispatch('placeModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId }, clauses: { where: [['route', 'array-contains', getters.getSelectedRouteId]] } })
            .then(() => {
              commit('setplaceModuleLoadComplete', true)
            })
        } else {
          this.dispatch('placeModule/setPathVars', { officeId: getters.getSelectedOfficeId })
        }
      })
      commit('setrouteModuleLoadComplete', false)
      this.dispatch('routeModule/closeDBChannel', { clearModule: true }).then(() => {
        this.dispatch('routeModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId } })
          .then(() => {
            commit('setrouteModuleLoadComplete', true)
          })
      })
      commit('setreportModuleLoadComplete', false)
      this.dispatch('reportModule/closeDBChannel', { clearModule: true }).then(() => {
        this.dispatch('reportModule/openDBChannel', { pathVariables: { officeId: getters.getSelectedOfficeId } })
          .then(() => {
            this.dispatch('addToLog', ' reportModule openned')
            commit('setreportModuleLoadComplete', true)
          })
          .catch(error => {
            this.dispatch('showAlert', error.message)
            // Same as the other `catch` block above
          })
      })
    },
    closeCommonDataCollection ({ commit, getters, state }) {
      this.dispatch('officeModule/closeDBChannel')
      this.dispatch('zoneModule/closeDBChannel')
      this.dispatch('officeSelectedModule/closeDBChannel')
      this.dispatch('officeRulesModule/closeDBChannel')
      this.dispatch('officeMailNoticeModule/closeDBChannel')
      this.dispatch('officeItemMultiModule/closeDBChannel')
    },
    closePostmanDataCollection ({ commit }) {
      this.dispatch('pocModule/closeDBChannel')
      this.dispatch('routeModule/closeDBChannel')
      this.dispatch('taskRouteModule/closeDBChannel')
      this.dispatch('reportModule/closeDBChannel')

      // this.dispatch('harmonizeModule/RESET_VUEX_EASY_FIRESTORE_STATE')
    },
    setUserSelection ({ commit }, payload) {
      commit('setUserSelectionZone', (payload.zone))
      commit('setUserSelectionOffice', (payload.office))
      commit('setUserSelectionRoute', (payload.route))
      commit('setUserSelectionColor', (payload.color))
      commit('setUserSelectionCode', (payload.code))
      commit('setUserSelectionPlace', (payload.place))
      commit('setUserSelectionPdr', (payload.pdr))
      // this.dispatch('updateUserSelectPlacePictureSrc')
    },
    dashBoardMenuSelect ({ commit }, payload) {
      commit('setDashBoardMenuSelect', (payload))
    },
    addToLog ({ commit }, payload) {
      commit('addMessageLog', (payload))
    },
    resize ({ commit }) {
      window.scrollTo(0, 0)
      commit('setContentSize')
      commit('setScreenSize')
    },
    showExtendAppBar ({ commit }, payload) {
      commit('setAppBarExtendHeight', (payload))
      commit('setContentSize')
      commit('setScreenSize')
    },
    initGoogle  ({ commit }) {
      this.dispatch('addToLog', ' init...')
      this.dispatch('resize')
      try {
        gmapsInit(this.state.userParam.locale)
          .then((google) => {
            commit('setGoogle', (google))
          })
      } catch (error) {
        this.dispatch('showAlert', (error))
      }
    },
    initApp ({ commit }) {
      this.dispatch('initGoogle')
      Firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          this.dispatch('loadParam')
          commit('setUserId', (user.uid))
        } else {
          commit('setUserId', (null))
        }
      })
    },
    loadParam ({ commit, state }) {
      this.dispatch('paramModule/openDBChannel')
        .then(() => {
          // this.dispatch('paramModule/generateCalendar')
          commit('setDayColor', this.getters['paramModule/dayColor'])
          commit('setParamModuleLoadComplete', true)
          this.dispatch('assignCurrentThemes')
          this.dispatch('addToLog', ' paramModule openend')
        })
        .catch(error => {
          this.dispatch('addToLog', ' erreur load paramModule' + error)
          this.dispatch('showAlert', error.message)
          // Same as the other `catch` block above
        })
    },
    startVibBigAlert () {
      startPeristentVibrate(500, 500)
    },
    stopVibBigAlert () {
      stopVibrate()
    },
    updatePdrGeocoderFromPosition ({ commit, getters }, payload) {
      const geocoder = new this.state.google.maps.Geocoder()
      getters.EventBus.$on('updatePdr', (pdrData) => {
        this.dispatch('pocModule/set', pdrData)
          .then(() => {
            if (!getters['pocModule/isPlaceIdExist'](payload.placeId)) {
              commit('setUserSelectionPlace', null)
            }
          })
      })
      geocoder.geocode({ location: payload.pos.position }, function (results, status) {
        if (status === this.google.maps.GeocoderStatus.OK) {
          const pdrDetail = {
            id: payload.id,
            gaddress: results[0].address_components,
            formatted_address: results[0].formatted_address,
            place_id: results[0].place_id,
            // location: [{ desc: 'home', loc: new Firebase.firestore.GeoPoint(payload.pos.position.lat, payload.pos.position.lng) }]
            homeLocation: payload.pos.position
          }
          getters.EventBus.$emit('updatePdr', pdrDetail)
          //
        } else {
          // there were no coordinates found for the address entered (or the address was not found)
          alert('Could not find your address ')
          // onsole.log(status)
        }
      })
    },
    switchLocale ({ commit }) {
      if (this.state.userParam.locale === 'fr') {
        commit('setUserParamLocale', 'en')
      } else {
        commit('setUserParamLocale', 'fr')
      }
    },
    updateLocale ({ commit }, payload) {
      return commit('setUserParamLocale', payload)
    },
    updateGuardianMode ({ commit }, payload) {
      return commit('setGuardianMode', payload)
    },
    switchGuardianMode ({ commit }) {
      if (this.state.userParam.guardianMode.active) {
        this.dispatch('showMessage', 'Mode Gardien desactiver')
        commit('setGuardianMode', { active: false })
      } else {
        this.dispatch('showMessage', 'Mode Gardien activer')
        commit('setGuardianMode', { active: true })
      }
      this.dispatch('saveUserParam')
    },
    alertDistance ({ commit }, payload) {
      commit('setAlertDistance', payload)
    },
    updateCurrentPosition ({ commit }, payload) {
      return commit('setCurrentPosition', payload)
    },
    refreshCurrentPosition ({ commit }) {
      navigator.geolocation.getCurrentPosition(pos => {
        if (pos.coords) {
          commit('setCurrentPosition', { lat: pos.coords.latitude, lng: pos.coords.longitude })
        } else if (this.state.currentPosition) {
          // keep last position
        } else if (this.getters.getUserSelection.office) {
          const officeLoc = this.state.officeSelectedModule.data.location[0].loc
          commit('setCurrentPosition', { lat: officeLoc.latitude, lng: officeLoc.longitude })
        } else {
          this.dispatch('showAlert', 'erreur de position')
          commit('setCurrentPosition', { lat: 46, lng: 71 })
        }
      }, err => {
        this.dispatch('showAlert', (err.message))
      })
    },
    saveUserSelection ({ commit }) {
      this.dispatch('userLoggedModule/set', { selection: this.state.userSelection })
    },
    saveUserParam ({ commit, state }) {
      return this.dispatch('userLoggedModule/set', { param: state.userParam })
    },
    userParamMapInfoShowColorLine ({ commit }, payload) {
      if (this.state.userParam.mapInfo.showColorLine !== payload) {
        commit('setUserParamMapInfoShowColorLine', payload)
      }
    },
    userParamMapInfoShowPocMarker ({ commit }, payload) {
      if (this.state.userParam.mapInfo.showPocMarker !== payload) {
        commit('setUserParamMapInfoShowPocMarker', payload)
      }
    },
    userParamMapInfoShowMe ({ commit }, payload) {
      if (this.state.userParam.mapInfo.showMe !== payload) {
        commit('setUserParamMapInfoShowMe', payload)
      }
    },
    userParamMapInfoTrackMe ({ commit }, payload) {
      if (this.state.userParam.mapInfo.TrackMe !== payload) {
        commit('setUserParamMapInfoTrackMe', payload)
      }
    },
    userParamMapInfoShowDog ({ commit }, payload) {
      if (this.state.userParam.mapInfo.showDog !== payload) {
        commit('setUserParamMapInfoShowDog', payload)
      }
    },
    userParamMapInfoShowToilet ({ commit }, payload) {
      if (this.state.userParam.mapInfo.showToilet !== payload) {
        commit('setUserParamMapInfoShowToilet', payload)
      }
    },
    userParamMapInfoShowGreyBox ({ commit }, payload) {
      if (this.state.userParam.mapInfo.showGreyBox !== payload) {
        commit('setUserParamMapInfoShowGreyBox', payload)
      }
    },
    userParamMapInfoShowParking ({ commit }, payload) {
      if (this.state.userParam.mapInfo.showParking !== payload) {
        commit('setUserParamMapInfoShowParking', payload)
      }
    },
    userSelectionZone ({ commit }, payload) {
      return this.dispatch('userLoggedModule/updateSelectionZone', payload)
      // if (this.state.userSelection.zone !== payload) {
      //   commit('setUserSelectionZone', payload)
      // }
    },
    userSelectionOffice ({ commit }, payload) {
      return this.dispatch('userLoggedModule/updateSelectionOffice', payload)
      // if (this.state.userSelection.office !== payload) {
      //   commit('setUserSelectionOffice', payload)
      // }
    },
    userSelectionRoute ({ commit }, payload) {
      return this.dispatch('userLoggedModule/updateSelectionRoute', payload)
      // if (this.state.userSelection.route !== payload) {
      //   commit('setUserSelectionRoute', payload)
      // }
    },
    userSelectionColor ({ commit }, payload) {
      return this.dispatch('userLoggedModule/updateSelectionColor', payload)
      // if (this.state.userSelection.color !== payload) {
      //   commit('setUserSelectionColor', payload)
      // }
    },
    userSelectionCode ({ commit }, payload) {
      return this.dispatch('userLoggedModule/updateSelectionCode', payload)
      // if (this.state.userSelection.code !== payload) {
      //   commit('setUserSelectionCode', payload)
      // }
    },
    userSelectionPlace ({ commit }, payload) {
      return this.dispatch('userLoggedModule/updateSelectionPlace', payload)
      // if (this.state.userSelection.place !== payload) {
      //   commit('setUserSelectionPlace', payload)
      // }
    },
    userSelectionPdr ({ commit }, payload) {
      return this.dispatch('userLoggedModule/updateSelectionPdr', payload)
      // if (this.state.userSelection.pdr !== payload) {
      //   commit('setUserSelectionPdr', payload)
      // }
    },
    userSelectionPdrOfOffice ({ commit }, payload) {
      if (this.state.userSelection.pdr !== payload.pdr ||
        (payload.placeId && this.state.userSelection.place !== payload.placeId)) {
        return commit('setUserSelectionPdrOfOffice', payload)
      } else {
        return null
      }
    },
    startLoadingProgress ({ commit }, payload) {
      commit('setLoadingProgress', true)
      commit('setLoadingProgressTitle', payload)
      commit('setLoadingProgressValue', 0)
    },
    completeLoadingProgress ({ commit }) {
      if (this.state.loadingProgress) {
        commit('setLoadingProgressTitle', '')
        commit('setLoadingProgressValue', 100)
        commit('setLoadingProgress', false)
      }
    },
    progressLoadingProgressValue ({ commit }, payload) {
      this.state.loadingProgressValue = payload
      // if (this.state.loadingProgress) {
      commit('setLoadingProgressValue', payload)
      // }
    },
    showMessage ({ commit }, payload) {
      commit('setSystemMessage', payload)
    },
    showInfo ({ commit }, payload) {
      commit('setSystemInfo', payload)
    },
    showAlert ({ commit }, payload) {
      commit('setSystemAlert', payload)
    },
    // signUserUp ({ commit }, payload) {
    //   commit('setUserLoading', true)
    //   commit('clearAuthError')
    //   this.state.usercreatemode = true
    //   Firebase.auth().createUserWithEmailAndPassword(payload.email, payload.password)
    //     .then(
    //       user => {
    //         // payload.userid = user.uid
    //         this.state.usercreatemode = false
    //         commit('setUserLoading', false)
    //         payload.userinfo.gid = user.uid
    //         this.dispatch('userModule/set', payload.userinfo)
    //       }
    //     )
    //     .catch(
    //       error => {
    //         commit('setUserLoading', false)
    //         commit('setAuthError', error)
    //       }
    //     )
    // },
    signUserIn ({ commit }, payload) {
      commit('setUserLoading', true)
      Firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
        .then(
          user => {
            commit('setloadfilestart')
            commit('setUserLoading', false)
            commit('setUserId', (user.uid))
          }
        )
        .catch(
          error => {
            commit('setUserLoading', false)
            commit('setAuthError', error.message)
            this.dispatch('showAlert', (error.message))
          }
        )
    },
    clearAuthError ({ commit }) {
      commit('clearAuthError')
    },
    checkSignIn ({ commit }) {
      // this.dispatch('logout')
      // Firebase.auth().onAuthStateChanged((user) => {
      //   if (!this.state.usercreatemode) {
      //     if (user) {
      //       commit('setUserId', (user.uid))
      //       this.dispatch('autoSignIn', user)
      //     } else {
      //       commit('setUserId', (null))
      //       this.dispatch('notloginuser')
      //     }
      //   }
      // })
    },
    autoSignIn ({ commit }, payload) {
      // commit('setUser', payload)
      // // this.dispatch('connectdata')
    },
    notloginuser ({ commit }) {
      // commit('setWaitlogin', false)
    },
    logout ({ commit }) {
      commit('setUserId', null)
      commit('setUserEmail', (null))
      commit('setuserModuleLoadComplete', false)
      commit('setzoneModuleLoadComplete', false)
      commit('setofficeModuleLoadComplete', false)
      commit('setofficeItemModuleLoadComplete', false)
      commit('setofficeTravelModuleLoadComplete', false)
      commit('setofficeSelectedModuleLoadComplete', false)
      commit('setreportModuleLoadComplete', false)
      commit('setrouteModuleLoadComplete', false)
      commit('setMessUserModuleLoadComplete', false)
      commit('setofficeItemMultiModuleLoadComplete', false)
      commit('setTaskRouteModuleLoadComplete', false)
      commit('setpdrModuleLoadComplete', false)
      commit('setpocModuleLoadComplete', false)
      commit('setParamModuleLoadComplete', false)

      this.dispatch('userLoggedModule/closeDBChannel', { clearModule: true })
      this.dispatch('zoneModule/closeDBChannel', { clearModule: true })
      this.dispatch('userModule/closeDBChannel', { clearModule: true })
      this.dispatch('pdrModule/closeDBChannel', { clearModule: true })
      this.dispatch('pocModule/closeDBChannel', { clearModule: true })
      this.dispatch('officeItemMultiModule/closeDBChannel', { clearModule: true })
      this.dispatch('routeModule/closeDBChannel', { clearModule: true })
      this.dispatch('routeSelectedModule/closeDBChannel', { clearModule: true })
      this.dispatch('officeModule/closeDBChannel', { clearModule: true })
      this.dispatch('reportModule/closeDBChannel', { clearModule: true })
      // this.dispatch('paramModule/closeDBChannel', { clearModule: true })
      commit('setloadfile', false)
      Firebase.auth().signOut()
    },
    // connectdata ({ commit }) {
    //   commit('setloadfilestart')
    //   this.dispatch('loadParam')
    //   this.dispatch('userModule/openDBChannel', { where: [['gid', '==', '{userId}']] })
    //     .then(() => {
    //       this.dispatch('addToLog', ' userModule openend')
    //       commit('addfinishloadfile')
    //       commit('setuserModuleLoadComplete', true)
    //       commit('setUserinfo')
    //       // commit('setDayColor', 1)
    //     })
    // },
    loadUserData ({ commit }) {
      this.dispatch('officeModule/openDBChannel')
        .then(() => {
          this.dispatch('addToLog', ' officeModule openend')
          commit('setofficeModuleLoadComplete', true)
          // onsole.log('officeid = ' + this.state.userModule.data[this.state.userinfokey].officeselected)
          commit('addfinishloadfile')

          commit('setUserSelectOfficePictureSrc')
          const where = [['officeId', '==', this.state.userModule.data[this.state.userinfokey].officeselected]]
          const orderby = ['csvSeqPosition']
          this.dispatch('pdrModule/openDBChannel', { clause: { where, orderby } })
            .then(() => {
              this.dispatch('addToLog', ' pdrModule openend')
              commit('setpdrModuleLoadComplete', true)
              // commit('setUserSelectPlacePictureSrc')
              this.dispatch('pdrModule/setPdrImgUrl')
              commit('addfinishloadfile')
            })
            .catch(error => {
              this.dispatch('showAlert', error.message)
            })
        })
        .catch(error => {
          this.dispatch('addToLog', ' erreur load OfficeModule')
          this.dispatch('showAlert', error.message)
          // Same as the other `catch` block above
        })
      this.dispatch('zoneModule/openDBChannel')
        .then(() => {
          this.dispatch('addToLog', ' zoneModule openned')
          commit('setzoneModuleLoadComplete', true)
          commit('addfinishloadfile')
        })
        .catch(error => {
          this.dispatch('showAlert', error.message)
          // Same as the other `catch` block above
        })
      this.dispatch('reportModule/openDBChannel')
        .then(() => {
          this.dispatch('addToLog', ' reportModule openned')
          commit('setreportModuleLoadComplete', true)
          commit('addfinishloadfile')
        })
        .catch(error => {
          this.dispatch('showAlert', error.message)
          // Same as the other `catch` block above
        })
    }
  },
  modules: {
    authStore
  }
})
// initFirebase
initFirebase()
  // .then(() => {
  //   $store.dispatch('addToLog', ' initFirebase completed Success !!!')
  // })
  .catch(error => {
    // $store.dispatch('addToLog', ' initFirebase error !!!')
    this.$emit('error', error)
    // take user to a page stating an error occurred
    // (might be a connection error, or the app is open in another tab)
  })
export default store
