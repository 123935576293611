const harmonizeModule = {
  firestorePath: 'officeData/{officeId}/harmonize',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'harmonizeModule',
  statePropName: 'data',
  namespaced: true, // automatically added

  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    nbrHarmonize: (state) => {
      var nbr = 0
      for (const harmonizeId in state.data) {
        if (harmonizeId) {
          nbr++
        }
      }
      return nbr
    },
    searchHarmonize: (state) => (addressObj) => {
      var harmonizeAddress
      for (const harmonizeId in state.data) {
        if (harmonizeId) {
          const harmonize = state.data[harmonizeId]
          if (addressObj.pdrListPostalCode.replace(/ /g, '').toUpperCase() === harmonize.newAddress.postalCode.replace(/ /g, '').toUpperCase()) {
            if (harmonize.newAddress.suit && harmonize.newAddress.suffix) {
              harmonizeAddress = harmonize.newAddress.suit + '-' + harmonize.newAddress.civic + harmonize.newAddress.suffix + ' ' + harmonize.newAddress.street
            } else if (harmonize.newAddress.suit) {
              harmonizeAddress = harmonize.newAddress.suit + '-' + harmonize.newAddress.civic + ' ' + harmonize.newAddress.street
            } else if (harmonize.newAddress.suffix) {
              harmonizeAddress = harmonize.newAddress.civic + harmonize.newAddress.suffix + ' ' + harmonize.newAddress.street
            } else {
              harmonizeAddress = harmonize.newAddress.civic + ' ' + harmonize.newAddress.street
            }
            if (addressObj.pdrListAddress.replace(/ /g, '').toUpperCase() === harmonizeAddress.replace(/ /g, '').toUpperCase()) {
              addressObj.oldAddress = harmonize.oldAddress
              addressObj.harmonizeFound = true
              addressObj.harmonizeId = harmonizeId
            }
          }
          if (addressObj.pdrListPostalCode.replace(/ /g, '').toUpperCase() === harmonize.oldAddress.postalCode.replace(/ /g, '').toUpperCase()) {
            if (harmonize.oldAddress.suit && harmonize.oldAddress.suffix) {
              harmonizeAddress = harmonize.oldAddress.suit + '-' + harmonize.oldAddress.civic + harmonize.oldAddress.suffix + ' ' + harmonize.oldAddress.street
            } else if (harmonize.oldAddress.suit) {
              harmonizeAddress = harmonize.oldAddress.suit + '-' + harmonize.oldAddress.civic + ' ' + harmonize.oldAddress.street
            } else if (harmonize.oldAddress.suffix) {
              harmonizeAddress = harmonize.oldAddress.civic + harmonize.oldAddress.suffix + ' ' + harmonize.oldAddress.street
            } else {
              harmonizeAddress = harmonize.oldAddress.civic + ' ' + harmonize.oldAddress.street
            }
            if (addressObj.pdrListAddress.replace(/ /g, '').toUpperCase() === harmonizeAddress.replace(/ /g, '').toUpperCase()) {
              addressObj.newAddress = harmonize.newAddress
              addressObj.harmonizeFound = true
              addressObj.harmonizeId = harmonizeId
            }
          }
        }
      }
      if (!addressObj.harmonizeFound) { addressObj.harmonizeFound = false }
      return addressObj
    }
  },
  mutations: {},
  actions: {}
}

export default harmonizeModule
