<template>
  <div class="home">
    <maps
      :contentstyles="contentstyles"
      :markersInfo="pocInfoMarker"
      :officeItemMarker="officeItemMarker"
      :directionsRenderer="directionsRenderer ? directionsRenderer.dr : null"
      @markerClicked="asClicked($event)"
      @levelUpClicked="levelUpClicked()"
      @doTask="doMapTask($event)"
      :params="{
        mode: 'routeInfoMode',
        showColorLine: showColorLine,
      }"
    ></maps>
    <!-- <loading-popup v-if="!$store.getters.dataLoadComplete" :title="showLoadingTitle"></loading-popup> -->
    <pmt-loading-local :loadingTitle="showLoadingTitle" :loadingShow="showLoading"></pmt-loading-local>
    <pmt-create-map-direction-popup
      @return-renderer="directionsRenderer = $event"
      @close="showCreateMapDirection = false"
      v-if="showCreateMapDirection"
    ></pmt-create-map-direction-popup>
    <confirm-popup
      v-if="showConfirm"
      @cancel="showConfirm = false"
      @confirm="doConfirmedTask($event)"
      :title="showConfirmTitle"
      :item="showConfirmItem"
      :from="showConfirmFrom"
      :to="showConfirmTo"
    ></confirm-popup>
  </div>
</template>

<script>
export default {
  props: ['contentstyles'],
  data () {
    return {
      showLoading: false,
      showLoadingTitle: this.$t('common.loading'),
      showConfirm: false,
      showConfirmTitle: '',
      showConfirmFrom: null,
      showConfirmTo: null,
      showConfirmParam: null,
      showConfirmItem: null,
      showCreateMapDirection: false,
      directionsRenderer: null
    }
  },
  methods: {
    doMapTask (task) {
      switch (task.taskType) {
        case 'loadRoute':
          // // console.log('trace loadRoute id = ' + task.routeId)
          this.loadRoute(task)
          break
        case 'directionClicked':
          // // console.log('trace direction clicked')
          if (this.directionsRenderer) {
            this.directionsRenderer = null
          } else {
            this.showCreateMapDirection = true
          }
          break
        default:
          break
      }
    },
    doConfirmedTask (param) {
      this.showConfirm = false
      if (param.type === 'changeOffice') {
        this.$store.dispatch('changeOffice', param.newId)
        this.$store.dispatch('userSelectionOffice', param.newId)
      } else if (param.type === 'changeRoute') {
        this.showLoading = true
        this.$store.dispatch('postmanChangeRoute', param.newId).then(() => {
          this.$store.dispatch('userSelectionRoute', param.newId).then(() => {
            this.showLoading = false
          })
        })
      } else if (param.type === 'changeSequence') {
        this.$store.dispatch('changeSequence', param.newId)
      }
    },
    loadRoute (route) {
      this.showConfirmItem = { type: 'changeRoute', newId: route.routeId }
      this.showConfirmTitle = this.$t('common.changerouteconfirm')
      this.showConfirmFrom = this.$store.state.routeModule.data[this.$store.getters.getSelectedRouteId].name
      this.showConfirmTo = route.routeName
      this.showConfirm = true
    },
    asClicked (marker) {
      switch (marker.type) {
        case 'pocMarker':
          this.$store.dispatch('userSelectionPdr', (marker.pocId))
          break
        case 'placeMarker':
          this.$store.dispatch('userSelectionPlace', (marker.placeId))
          break
        case 'codeMarker':
          this.$store.dispatch('userSelectionCode', (marker.codeId))
          break
        case 'colorMarker':
          this.$store.dispatch('userSelectionColor', (marker.colorId))
          break
        case 'routeMarker':
          this.$store.dispatch('userSelectionRoute', (marker.routeId))
          break
        case 'officeMarker':
          this.$store.dispatch('userSelectionOffice', (marker.officeId))
          break
        case 'zoneMarker':
          this.$store.dispatch('userSelectionZone', (marker.zoneId))
          break
        default:
          // // console.log('trace asClicked sans type !!!! ERROR')
          break
      }
    },
    levelUpClicked () {
      if (this.pdrSelectId) {
        this.$store.dispatch('userSelectionPdr', (null))
      } else if (this.placeSelectId) {
        this.$store.dispatch('userSelectionPlace', (null))
      } else if (this.codeSelectId) {
        this.$store.dispatch('userSelectionCode', (null))
      } else if (this.colorSelectId) {
        this.$store.dispatch('userSelectionColor', (null))
      } else if (this.routeSelectId) {
        this.$store.dispatch('userSelectionRoute', (null))
      } else if (this.officeSelectId) {
        this.$store.dispatch('userSelectionOffice', (null))
      } else if (this.zoneSelectId) {
        this.$store.dispatch('userSelectionZone', (null))
      }
    }
  },
  computed: {
    officeItemMarker () {
      if (this.directionsRenderer && this.officeSelectId) {
        return []
      } else {
        return [].concat(this.toiletMarker, this.greyBoxMarker)
      }
    },
    toiletMarker () {
      if (this.showToilet) {
        return this.$store.getters['officeItemModule/getToiletMarker']
      } else {
        return []
      }
    },
    greyBoxMarker () {
      if (this.showGreyBox) {
        return this.$store.getters['officeItemModule/getGreyBoxMarker']
      } else {
        return []
      }
    },
    pocInfoMarker () {
      if (this.directionsRenderer) {
        return { level: 'pocDetail', markerData: this.directionsRenderer.marker, line: null }
      } else if (this.pdrSelectId) {
        return { level: 'pocDetail', markerData: this.$store.getters['pocModule/getPocDetailMarkerData'](this.routeSelectId, this.$store.getters.getSelectedSequenceId, this.pdrSelectId, this.pocItemTitle), line: null }
      } else if (this.placeSelectId) {
        return { level: 'placeDetail', markerData: this.$store.getters['pocModule/getPlaceDetailMarkerData'](this.routeSelectId, this.$store.getters.getSelectedSequenceId, this.placeSelectId, this.pocItemTitle), line: null }
      } else if (this.codeSelectId) {
        return { level: 'placeOfCode', markerData: this.$store.getters['pocModule/getPlaceOfCodeMarkerDataSelected'], line: this.lineMarkerInfo.filter(x => String(x.codeId) === String(this.codeSelectId)) }
      } else if (this.colorSelectId) {
        return { level: 'codeOfColor', markerData: this.$store.getters['pocModule/getCodeOfColorMarkerDataSelected'], line: this.lineMarkerInfo.filter(x => String(x.colorId) === String(this.colorSelectId)) }
      } else if (this.routeSelectId) {
        return { level: 'colorOfRoute', markerData: this.$store.getters['pocModule/getColorOfRouteMarkerDataSelected'], line: this.lineMarkerInfo }
      } else if (this.officeSelectId) {
        return { level: 'routeOfOffice', markerData: this.$store.getters['routeModule/getRouteMarker'].marker, line: null }
      } else if (this.zoneSelectId) {
        return { level: 'officeOfZone', markerData: this.$store.getters['officeModule/getOfficeMarker'].marker, line: null }
      } else {
        return { level: 'zone', markerData: this.$store.getters['zoneModule/getZoneMarker'].marker, line: null }
      }
    },
    lineMarkerInfo () {
      if (this.routeSelectId) {
        return this.$store.getters['pocModule/getLineMarkerData'](this.routeSelectId, this.$store.getters.getSelectedSequenceId)
      } else { return [] }
    },
    zoneSelectId () {
      return this.$store.getters.getUserSelection.zone
    },
    officeSelectId () {
      return this.$store.getters.getUserSelection.office
    },
    routeSelectId () {
      return this.$store.getters.getUserSelection.route
    },
    colorSelectId () {
      return this.$store.getters.getUserSelection.color
    },
    codeSelectId () {
      return this.$store.getters.getUserSelection.code
    },
    placeSelectId () {
      return this.$store.getters.getUserSelection.place
    },
    pdrSelectId () {
      return this.$store.getters.getUserSelection.pdr
    },
    mapInfo () {
      return this.$store.getters.getUserParam.mapInfo
    },
    showColorLine () { return this.mapInfo.showColorLine },
    showDog () { return this.mapInfo.showDog },
    showToilet () { return this.mapInfo.showToilet },
    showGreyBox () { return this.mapInfo.showGreyBox }
  },
  created () {
    // // console.log(this.$store.getters.getUserSelection)
  }
}
</script>
