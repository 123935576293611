<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fixed max-width="444" transition="dialog-bottom-transition">
      <v-card>
        <v-app-bar dark :color="color ? color : 'primary'">
          <v-btn icon x-large dark @click="dialog = false">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon x-large v-if="canBeSave" dark @click="saveItem()" color="success">
            <v-icon x-large>mdi-content-save</v-icon>
          </v-btn>
        </v-app-bar>
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="4" align="center">
              <v-img
                v-if="type"
                :src="$store.getMapItemIconOfType(type)"
                alt="Item"
                max-height="50"
                contain
              ></v-img>
            </v-col>
            <v-col cols="8" align="start">
              <v-select outlined :items="itemType" :color="color" :item-color="color" v-model="type" label="Type"></v-select>
            </v-col>
          </v-row>
          <v-btn @click="showSelectPocOfItem = true" class="mb-2" block v-if="!isSolo">{{ pocIdList.length }} select poc</v-btn>
          <v-text-field v-if="!isPrimaryMailBox" @blur="$store.dispatch('resize')" outlined block :label="$t('pocitem.name')" v-model="name" :color="color"></v-text-field>
          <v-textarea outlined @blur="modifyDesc()" :label="$t('pocitem.desc')" v-model="desc" name="test" :color="color"></v-textarea>
          <v-row class="mt-n3">
            <v-col cols="4" align="center">
              <v-btn icon x-large @click="showManagePicture = true">
                <v-badge
                  v-if="imageList.length"
                  :content="imageList.length"
                  color="green"
                  overlap
                  bottom
                >
                  <v-icon x-large color="success">mdi-folder-multiple-image</v-icon>
                </v-badge>
                <v-icon v-else x-large color="error">mdi-folder-multiple-image</v-icon>
              </v-btn>
              <p align="center">{{ $t('createreportdog.picture') }}</p>
            </v-col>

            <v-col cols="4" align="center">
              <v-btn icon x-large @click="showGetItemPosition = true"><v-icon x-large :color="pos ? 'success' : 'error'">mdi-map-marker</v-icon></v-btn>
              <p align="center">{{ $t('createreportdog.gps') }}</p>
            </v-col>

            <v-col cols="4" align="center" v-if="!isPrimaryMailBox">
                <v-btn icon x-large @click="deleteItem(item)"><v-icon x-large color="primary">mdi-delete</v-icon></v-btn>
                <p align="center">{{ $t('common.delete') }}</p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <pmt-position-map-set
        v-if="showGetItemPosition"
        :startCenterPdrId="pocId"
        :param="{ icon: $store.getMapItemIconOfType(type), markerStartPosition: pos, getDistance: false }"
        @return-close="showGetItemPosition = false"
        @return-check="modifyPos($event)"
      />
      <pmt-select-pdr
        v-if="showSelectPocOfItem"
        @close="showSelectPocOfItem = false"
        @select="setPocIdList($event)"
        :selItem="$store.getters['pocModule/getPocDataIteratorItemsOfPocIdArray'](pocIdList)"
        :pocIdToRangeList="pocId"
      ></pmt-select-pdr>
      <picture-manager
        v-if="showManagePicture"
        :subTitle="name ? name : $t('pocitem.' + type)"
        :param="pictureManagerParam()"
        @close="showManagePicture = false"
      ></picture-manager>
      <confirm-popup
        v-if="showConfirmModify"
        @cancel="cancelmodifyConfirm()"
        @confirm="modifyConfirmed($event)"
        :title="confirmTitle"
        :from="confirmModifyFrom"
        :to="confirmModifyTo"
        :item="confirmItem"
        :param="{ mode: 'standard', text: null }"
      ></confirm-popup>
      <pmt-loading-local :loadingTitle="showLoadingTitle" :loadingShow="showLoading"></pmt-loading-local>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ['pocId', 'itemId', 'color', 'pocItem'],
  data () {
    return {
      dialog: true,
      notifications: false,
      type: null,
      name: null,
      desc: null,
      imageList: [],
      pocIdList: [],
      showGetItemPosition: false,
      pos: null,
      title: this.itemId !== undefined ? this.$t('pocitem.edittitle') : this.$t('pocitem.createtitle'),
      isActive: true,
      showSelectPocOfItem: false,
      showManagePicture: false,
      itemType: [],
      showLoading: false,
      showLoadingTitle: '',
      showConfirm: false,
      showConfirmModify: false,
      showConfirmQuit: false,
      confirmItem: null,
      confirmTitle: '',
      confirmModifyFrom: '',
      confirmModifyTo: ''
    }
  },
  computed: {
    refPocItem () {
      if (this.itemId !== undefined) {
        return this.$store.getters['pocModule/getActiveMapItemsOfPoc'](this.pocId).filter(x => x.id === this.itemId)[0]
      } else {
        return null
      }
    },
    isNew () {
      if (this.itemId !== undefined) {
        return false
      } else {
        return true
      }
    },
    isPrimaryMailBox () {
      if (this.itemId === 0) {
        return true
      } else { return false }
    },
    canBeSave () {
      if (this.isNew) {
        if (this.type) {
          return true
        } else { return false }
      } else { return false }
    },
    isModify () {
      if (this.isNew) {
        return false
      } else {
        if (this.desc !== this.refItem.desc) {
          return true
        } else {
          return false
        }
      }
    },
    isSolo () {
      return this.$store.getters.isSoloPocMapItemType(this.type)
    }
  },
  methods: {
    resetItem () {
      this.type = this.refPocItem ? this.refPocItem.type : null
      this.name = this.refPocItem ? this.refPocItem.name : null
      this.desc = this.refPocItem ? this.refPocItem.desc : null
      this.imageList = this.refPocItem ? this.refPocItem.imageList : []
      this.pocIdList = this.refPocItem ? this.refPocItem.pocIdList : []
      this.pos = this.refPocItem ? this.refPocItem.loc : null
    },
    modifyDesc () {
      if (!this.isNew && (this.refPocItem.desc !== this.desc)) {
        this.confirmTitle = this.$t('pocItem-edit.confirm-modify-desc')
        this.confirmModifyFrom = this.refPocItem.desc
        this.confirmModifyTo = this.desc
        this.confirmItem = { desc: this.desc }
        this.showConfirmModify = true
      }
    },
    modifyPos (newPos) {
      this.pos = newPos.position
      if (!this.isNew && (this.refPocItem.loc !== this.pos)) {
        this.showGetItemPosition = false
        this.confirmTitle = this.$t('pocItem-edit.confirm-modify-position')
        this.confirmModifyFrom = null
        this.confirmModifyTo = null
        this.confirmItem = { pos: newPos.position }
        this.showConfirmModify = true
      }
    },
    cancelmodifyConfirm () {
      this.resetItem()
      this.showConfirmModify = false
    },
    modifyConfirmed (change) {
      // // console.log('trace change =')
      // // console.log(change)
      this.showConfirmModify = false
      this.showLoading = true
      this.showLoadingTitle = this.$t('common.saveinprogress')
      if (this.isSolo) {
        change.pocId = this.pocId
        change.itemId = this.itemId
        this.$store.dispatch('pocModule/updatePocItem', change).then(() => {
          this.showLoading = false
        })
      } else {
        // // console.log('trace not solo')
      }
    },
    pictureManagerParam () {
      if (this.refPocItem.officeItemMultiId !== undefined) {
        return {
          type: 'multiItem',
          multiId: this.refPocItem.officeItemMultiId
        }
      } else {
        return {
          type: 'pocItem',
          itemId: this.itemId,
          pocId: this.pocId
        }
      }
    },
    setPicture (pictureArray) {
      this.imageList = pictureArray
      this.showManagePicture = false
    },
    setPocIdList (items) {
      this.pocIdList = items.map(function (x) { return x.id })
    },
    saveItem () {
      const item = {
        type: this.type,
        name: this.name,
        desc: this.desc,
        imageList: this.imageList,
        pocIdList: this.pocIdList,
        pos: this.pos,
        pocId: this.pocId,
        isActive: this.isActive
      }
      if (this.pocItem) {
        if (this.pocItem.officeItemMultiId !== undefined) { item.officeItemMultiId = this.pocItem.officeItemMultiId }
        item.id = this.pocItem.id
      }
      this.$store.dispatch('pocModule/savePocItem', item)
      this.$emit('return-close')
    }
  },
  mounted () {
    this.resetItem()
    this.$store.getters['pocModule/get' + (this.isPrimaryMailBox ? 'Primary' : 'Optional') + 'PocMapItemTypeArray'].forEach(type => {
      this.itemType.push({ text: this.$t('pocitem.' + type), value: type })
    })
  },
  watch: {
    type (newValue) {
      if (!this.$store.getters.isSoloPocMapItemType(newValue)) {
        if (!this.pocIdList.length) {
          this.pocIdList.push(this.pocId)
        }
      }
    },
    dialog (newValue) {
      this.$store.dispatch('resize')
      this.dialog = false
      this.$emit('return-close')
    }
  }
}
</script>
