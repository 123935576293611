<template>
  <div class="home">
    <div v-show="false">
      <div id="levelUpControl">
        <v-btn
          id="levelUpControlBtn"
          class="ms-2 mt-3"
          @click="clickLevelUp"
          v-show="showLevelUp"
          color="white"
          >
          <v-icon large>mdi-arrow-top-left-bold-outline</v-icon>
        </v-btn>
      </div>
      <div id="menuLeftBottomControl" v-show="!menuControlRight">
        <v-speed-dial
          v-model="menuControlLeft"
          direction="right"
          transition="'slide-x-transition'"
        >
          <template v-slot:activator>
            <v-btn
              class="ms-2"
              v-model="menuControlLeft"
              color="primary"
              large
              dark
              fab
            >
              <v-icon v-if="menuControlLeft" large>mdi-close</v-icon>
              <v-icon v-else large>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-btn
            fab
            dark
            large
            :color="trackMe ? 'green' : 'primary'"
            @click="$store.dispatch('userParamMapInfoTrackMe', !trackMe)"
          >
            <v-icon large>{{ trackMe ? 'mdi-target-account' : 'mdi-target' }}</v-icon>
          </v-btn>
          <v-btn
            fab
            @click="$emit('doTask', { taskType: 'directionClicked' })"
            dark
            large
            :color="directionsRenderer ? 'green' : 'primary'"
          >
            <v-icon large>mdi-directions</v-icon>
          </v-btn>
          <v-btn
            fab
            dark
            large
            color="primary"
            @click="showParam=!showParam"
          >
            <v-icon large>mdi-tune</v-icon>
          </v-btn>

        </v-speed-dial>
      </div>
      <!-- <div id="menuRightBottomControl" v-show="!menuControlLeft"> -->
      <div id="menuRightBottomControl" v-show="false">
        <v-speed-dial
          class="mb-3 me-2"
          v-model="menuControlRight"
          direction="left"
          transition="'slide-x-reverse-transition'"
        >
          <template v-slot:activator>
            <v-btn
              v-model="menuControlRight"
              color="primary"
              large
              dark
              fab
            >
              <v-icon v-if="menuControlRight" large>mdi-close-box-multiple</v-icon>
              <v-icon v-else large>mdi-checkbox-multiple-blank</v-icon>
            </v-btn>
          </template>
          <v-btn
            fab
            dark
            large
            :color="showinfobox ? 'green' : 'primary'"
            @click="clickInfo"
          >
            <v-icon x-large>mdi-information-variant</v-icon>
          </v-btn>
          <v-btn
            fab
            dark
            large
            :color="showStreetView ? 'green' : 'primary'"
            @click="clickView"
          >
            <v-icon x-large>mdi-binoculars</v-icon>
          </v-btn>
        </v-speed-dial>
      </div>
    </div>
    <GmapMap
      ref="mapRef"
      :center="mapStartPosition"
      :zoom="7"
      map-type-id="roadmap"
      :style="mapStyle"
      :options="mapOptions"
    >
      <GmapMarker
        ref="officeItemMarker"
        :key="m.id"
        v-for="(m, index) in officeItemMarker"
        :position="m.position"
        :clickable="false"
        :draggable="false"
        @click="clickMakersInfo(m, index)"
        :icon="getIconSpec(m)"
        :option="MarkerOptions"
      />
      <GmapMarker
        ref="pocMarker"
        :key="index"
        v-for="(m, index) in pocMarkerInfo"
        :position="m.position"
        :clickable="true"
        :draggable="false"
        :label="m.label ? m.label : null"
        @click="clickMakersInfo(m, index)"
        :icon="getIconSpec(m)"
        :option="MarkerOptions"
      />
      <GmapPolyline
        :key="l.lineId"
        v-for="l in lineMarker"
        :path="l.posArray"
        :options="getPolylineOptions(l)"
      ></GmapPolyline>
      <GmapInfoWindow
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen=false"
      >
        <div v-if="infoWindowMarker">
          <maps-infowindow
            :m="infoWindowMarker"
            @doTask="infoWindowDoTask($event)"
            @close="infoWinOpen = false"/>
        </div>
      </GmapInfoWindow>
    </GmapMap>
    <div v-show="directionsRenderer" class="mx-auto overflow-y-auto overflow-x-hidden" :max-height="infoboxHeight"  max-width="744" :style="infoboxStyle">
      <div id="directionsPanel" :style="infoboxStyle"></div>
    </div>
    <pmt-param-maps-info @close="showParam = false" v-if="showParam"/>
  </div>
</template>

<script>
export default {
  props: ['contentstyles', 'markersInfo', 'lineInfo', 'officeItemMarker', 'params', 'directionsRenderer'],
  data () {
    return {
      showParam: false,
      mapStartPosition: this.$store.getters.getCurrentPosition ? this.$store.getters.getCurrentPosition : { lat: 10, lng: 10 },
      menuControlRight: false,
      menuControlLeft: false,
      postManMarker: null,
      showinfobox: false,
      showStreetView: false,
      mapOptions: {
        styles: [
          {
            featureType: 'administrative',
            elementType: 'geometry',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'poi',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'road',
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'transit',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          }
        ],

        zoomControlOptions: {
          position: this.$store.getters.getGoogle.maps.ControlPosition.RIGHT_CENTER
        },
        streetViewControlOptions: {
          position: this.$store.getters.getGoogle.maps.ControlPosition.TOP_RIGHT
        },
        fullscreenControl: false,
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: this.$store.getters.getGoogle.maps.MapTypeControlStyle.DROPDOWN_MENU,
          mapTypeIds: ['roadmap', 'satellite'],
          position: this.$store.getters.getGoogle.maps.ControlPosition.TOP_CENTER
        },
        rotateControlOptions: {
          position: this.$store.getters.getGoogle.maps.ControlPosition.LEFT_CENTER
        }
      },
      map: null,
      infoContent: '',
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      infoWindowMarker: null,
      infoWinOpen: false,
      boxInfoWinOpen: null,
      currentMidx: null,
      fullscreenControl: false,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        },
        maxWidth: 250,
        minWidth: 250
      },
      MarkerOptions: {
        zIndex: 999999,
        opacity: 0.2

      }
    }
  },
  watch: {
    infoboxHeight (newValue) {
      this.directionsRenderer.setPanel(document.getElementById('directionsPanel'))
    },
    markersInfo (newValue) {
      this.infoWinOpen = false
      if (newValue.markerData.length && this.map) {
        this.setMapBoundFromPositionArray(newValue.markerData)
      }
    },
    showToilet (newValue) {
      if (this.map) {
        // this.$store.getters['officeSelectedModule/getOfficeToiletMarker'].forEach(marker => {
        //   marker.setMap(newValue ? this.map : null)
        // })
      }
    },
    showGreyBox (newValue) {
      if (this.map) {
        // this.$store.getters['officeSelectedModule/getOfficeGreyBoxMarker'].forEach(box => {
        //   box.marker.setMap(newValue ? this.map : null)
        // })
      }
    },
    showDog (newValue) {
      if (this.map) {
        this.$store.getters['reportModule/getAlertZoneMarker'].forEach(alert => {
          alert.marker.setMap(newValue ? this.map : null)
        })
      }
    },
    myPos (newValue) {
      if (this.trackMe) {
        this.map.setCenter(newValue)
      }
      if (this.showMe && newValue && this.postManMarker) {
        this.postManMarker.setPosition(this.myPos)
      }
    },
    directionsRenderer (newValue, oldValue) {
      if (oldValue) {
        oldValue.setMap(null)
        oldValue.setPanel(null)
      }
      if (newValue) {
        newValue.setMap(this.map)
        newValue.setPanel(document.getElementById('directionsPanel'))
      }
    }
  },
  methods: {
    clickMakersInfo (m, index) {
      // // console.log(m)
      if (m.isOpenInfoWindow) {
        this.toggleInfoWindow(m, index)
      } else {
        this.$emit('markerClicked', m)
      }
    },
    infoWindowLoadClick () {
      // // console.log('click load => ' + this.infoWindowMarker.title)
    },
    toggleInfoWindow: function (marker, idx) {
      this.infoWindowPos = marker.position
      this.infoWindowMarker = marker
      this.infoContent = this.getInfoWindowContent(marker)

      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen
      } else {
        this.infoWinOpen = true
        this.currentMidx = idx
      }
    },
    getInfoWindowContent (m) {
      let infoContent
      switch (m.type) {
        case 'pocItemMarker':
          infoContent = '<h1 style="text-align: center;">'
          infoContent += m.pocItem.name
          infoContent += '</h1>'
          if (m.pocItem.desc) {
            infoContent += '<p style="text-align: center;"><span style="color: #ff0000;">'
            infoContent += m.pocItem.desc
            infoContent += '</span></p>'
          }
          if (m.pocItem.imageList) {
            if (m.pocItem.imageList.length) {
              infoContent += '<p><img style="display: block; margin-left: auto; margin-right: auto;" src="'
              infoContent += m.pocItem.imageList.filter(image => image.isPrimary)[0].url
              infoContent += '" alt="'
              infoContent += 'image'
              infoContent += '" width="180" height="120" /></p>'
            }
            m.pocItem.imageList.forEach(image => {
              if (!image.isPrimary) {
                infoContent += '<p><img style="display: block; margin-left: auto; margin-right: auto;" src="'
                infoContent += image.url
                infoContent += '" alt="'
                infoContent += 'image'
                infoContent += '" width="180" height="120" /></p>'
              }
            })
          }
          break
        case 'routeMarker':
          infoContent = '<h1 style="text-align: center;">'
          infoContent += m.title
          infoContent += '</h1>'
          infoContent += this.getStatTabHtml([m.stat])
          break
        default:
          break
      }
      return infoContent
    },
    getStatTabHtml (statArray) {
      let useApp = false
      let useHou = false
      let useBus = false
      let useFarm = false
      let cpt = 0
      statArray.forEach(stat => {
        if (stat.app || stat.appV) { useApp = true }
        if (stat.hou || stat.houV) { useHou = true }
        if (stat.bus || stat.busV) { useBus = true }
        if (stat.farm || stat.farmV) { useFarm = true }
      })
      if (useApp) { cpt++ }
      if (useHou) { cpt++ }
      if (useBus) { cpt++ }
      if (useFarm) { cpt++ }
      const pSize = cpt === 3 ? 33 : 100 / cpt
      let html = '<table style="border-collapse: collapse; width: 100%;text-align: center;" border="5"><tbody><tr>'
      if (useHou) {
        html += '<td style="width: ' + pSize + '%;">' + this.$t('common.sdom') + '</td>'
      }
      if (useApp) {
        html += '<td style="width: ' + pSize + '%;">' + this.$t('common.sapp') + '</td>'
      }
      if (useBus) {
        html += '<td style="width: ' + pSize + '%;">' + this.$t('common.sbus') + '</td>'
      }
      if (useFarm) {
        html += '<td style="width: ' + pSize + '%;">' + this.$t('common.sfrm') + '</td>'
      }
      html += '</tr>'
      statArray.forEach(stat => {
        html += '<tr>'
        if (useHou) {
          html += '<td style="width: ' + pSize + '%;">' + stat.houP + '/' + stat.hou + ' (' + stat.houV + ')' + '</td>'
        }
        if (useApp) {
          html += '<td style="width: ' + pSize + '%;">' + stat.appP + '/' + stat.app + ' (' + stat.appV + ')' + '</td>'
        }
        if (useBus) {
          html += '<td style="width: ' + pSize + '%;">' + stat.busP + '/' + stat.bus + ' (' + stat.busV + ')' + '</td>'
        }
        if (useFarm) {
          html += '<td style="width: ' + pSize + '%;">' + stat.farmP + '/' + stat.farm + ' (' + stat.farmV + ')' + '</td>'
        }
        html += '</tr>'
      })
      html += '</tbody></table>'
      return html
    },
    getColorName (colorId) {
      switch (String(colorId)) {
        case '1':
          return 'orange'
        case '2':
          return 'pink'
        default:
          return 'blue'
      }
    },
    getPolylineOptions (line) {
      return {
        geodesic: true,
        strokeColor: this.getColorName(line.colorId),
        strokeOpacity: 1.0,
        strokeWeight: 6
      }
    },
    setMapBoundFromPositionArray (markerInfoArray) {
      const bounds = new this.$store.getters.getGoogle.maps.LatLngBounds()
      let cptPos = 0
      let lastPos = null
      markerInfoArray.forEach(marker => {
        if (marker.position) {
          if (marker.position.lat && marker.position.lng) {
            bounds.extend(marker.position)
            cptPos += 1
            lastPos = marker.position
          }
        } else {
          // // console.log('trace no position')
          // // console.log(marker)
        }
      })
      this.lineMarker.forEach(line => {
        line.posArray.forEach(pos => {
          if (pos && pos.lat && pos.lng) {
            bounds.extend(pos)
          }
        })
      })
      if (cptPos > 1) {
        this.map.fitBounds(bounds)
      } else {
        this.map.setCenter(lastPos)
        this.map.setZoom(20)
      }
    },
    setMapBoundFromBounds (markerInfoArray) {
      const bounds = new this.$store.getters.getGoogle.maps.LatLngBounds()
      markerInfoArray.forEach(marker => {
        bounds.extend(marker.position)
      })
      this.map.fitBounds(bounds)
    },
    getIconSpec (markerInfo) {
      const google = this.$store.getters.getGoogle
      switch (markerInfo.type) {
        case 'postManMarker':
          return { // url:mdi-toilet,
            url: require('@/assets/Icon/MapIcon/postman.png'),
            scaledSize: new this.google.maps.Size(40, 40), // scaled size
            origin: new this.google.maps.Point(0, 0), // origin
            anchor: new this.google.maps.Point(20, 20) // anchor
          }
        case 'pocMarker':
          return {
            url: markerInfo.url,
            // size: new google.maps.Size(48, 48),
            scaledSize: new google.maps.Size(60, 60), // scaled size
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(24, 28), // anchor
            labelOrigin: new google.maps.Point(30, 43)
          }
        case 'placeMarker':
          return {
            url: markerInfo.url,
            // size: new google.maps.Size(48, 48),
            // scaledSize: new google.maps.Size(48, 48), // scaled size
            scaledSize: new google.maps.Size(60, 60), // scaled size
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(24, 28), // anchor
            // labelOrigin: new google.maps.Point(24, 32)
            labelOrigin: new google.maps.Point(30, 43)
          }
        case 'codeMarker':
          return {
            url: markerInfo.url,
            scaledSize: new google.maps.Size(60, 60), // scaled size
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(30, 60), // anchor
            labelOrigin: new google.maps.Point(30, 19)
          }
        case 'colorMarker':
          return {
            url: markerInfo.url,
            scaledSize: new google.maps.Size(60, 60), // scaled size
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(30, 60), // anchor
            labelOrigin: new google.maps.Point(30, 19)
          }
        case 'routeMarker':
          return {
            url: markerInfo.url,
            scaledSize: new google.maps.Size(50, 50), // scaled size
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(24, 28), // anchor
            labelOrigin: new google.maps.Point(25, 25)
          }
        case 'officeMarker':
          return {
            url: markerInfo.url,
            scaledSize: new google.maps.Size(60, 60), // scaled size
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(24, 28), // anchor
            labelOrigin: new google.maps.Point(30, -5)
          }
        case 'zoneMarker':
          return {
            url: markerInfo.url,
            scaledSize: new google.maps.Size(60, 60), // scaled size
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(24, 28), // anchor
            labelOrigin: new google.maps.Point(30, -5)
          }
        case 'multiItemMarker':
          return {
            url: markerInfo.url,
            scaledSize: new google.maps.Size(48, 48), // scaled size
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(24, 28), // anchor
            labelOrigin: new google.maps.Point(24, 54)
          }
        case 'pocMapItemMarker':
          return {
            url: markerInfo.url,
            scaledSize: new google.maps.Size(48, 48), // scaled size
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(24, 28), // anchor
            labelOrigin: new google.maps.Point(24, 54)
          }
        case 'toiletMarker':
          return {
            url: require('@/assets/Icon/MapIcon/human-male-female.png'),
            scaledSize: new google.maps.Size(48, 48), // scaled size
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(24, 24) // anchor
          }
        case 'greyBoxMarker':
          return {
            url: require('@/assets/Icon/MapIcon/GreyBox.png'),
            scaledSize: new google.maps.Size(48, 48), // scaled size
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(24, 24) // anchor
          }
        case 'alertMarker':
          return {
            url: markerInfo.url,
            scaledSize: new google.maps.Size(48, 48), // scaled size
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(24, 28), // anchor
            labelOrigin: new google.maps.Point(24, 54)
          }
        default:
          return { url: markerInfo.url }
      }
    },
    clickLevelUp () {
      this.$emit('levelUpClicked')
    },
    infoWindowDoTask (task) {
      switch (task.taskType) {
        case 'rootTask':
          this.infoWinOpen = false
          this.$emit('doTask', task.taskDetail)
          break
        default:
          break
      }
    },
    clickView () {},
    clickInfo () {}
  },
  computed: {
    mapStyle () {
      if (this.directionsRenderer) {
        return 'width:' + this.$store.getters.contentWidth + 'px; height:' + (this.$store.getters.contentHeight - this.infoboxHeight) + 'px'
      } else {
        return this.$store.getters.contentStyle
      }
    },
    infoboxStyle () {
      return 'width:' + this.$store.getters.contentWidth + 'px; height:' + this.infoboxHeight + 'px'
    },
    infoboxHeight () {
      return Math.round(this.$store.getters.contentHeight * 0.35)
    },
    pocMarkerInfo () {
      if (this.showPocMarker) {
        return this.markersInfo.markerData
      } else {
        return []
      }
    },
    showColorLine () { return this.$store.getters.getUserParam.mapInfo.showColorLine },
    showDog () { return this.$store.getters.getUserParam.mapInfo.showDog },
    showToilet () { return this.$store.getters.getUserParam.mapInfo.showToilet },
    showGreyBox () { return this.$store.getters.getUserParam.mapInfo.showGreyBox },
    showPocMarker () { return this.$store.getters.getUserParam.mapInfo.showPocMarker },
    showMe () { return this.$store.getters.getUserParam.mapInfo.showMe },
    trackMe () { return this.$store.getters.getUserParam.mapInfo.trackMe },
    showLevelUp () { return this.showPocMarker },
    lineMarker () {
      if (this.markersInfo.line && this.showColorLine) {
        return this.markersInfo.line
      } else {
        return []
      }
    },
    myPos () {
      return this.$store.getters.getCurrentPosition
    }
  },
  mounted () {
    // // console.log(this.markersInfo)
    this.$refs.mapRef.$mapPromise.then((map) => {
      this.map = map
      map.controls[this.$store.getters.getGoogle.maps.ControlPosition.LEFT_BOTTOM].push(document.getElementById('menuLeftBottomControl'))
      map.controls[this.$store.getters.getGoogle.maps.ControlPosition.RIGHT_BOTTOM].push(document.getElementById('menuRightBottomControl'))
      map.controls[this.$store.getters.getGoogle.maps.ControlPosition.LEFT_TOP].push(document.getElementById('levelUpControl'))
      this.setMapBoundFromPositionArray(this.markersInfo.markerData)
      this.postManMarker = new this.$store.getters.getGoogle.maps.Marker({
        position: this.myPos,
        map: this.showMe ? map : null,
        zIndex: 999999,
        icon: { // url:mdi-toilet,
          url: require('@/assets/Icon/MapIcon/postman.png'),
          scaledSize: new this.$store.getters.getGoogle.maps.Size(40, 40), // scaled size
          origin: new this.$store.getters.getGoogle.maps.Point(0, 0), // origin
          anchor: new this.$store.getters.getGoogle.maps.Point(20, 20) // anchor
        }
      })
      // if (this.showToilet) {
      //   this.$store.getters['officeSelectedModule/getOfficeToiletMarker'].forEach(marker => {
      //     marker.setMap(map)
      //   })
      // }

      // this.$store.getters['officeSelectedModule/getOfficeGreyBoxMarker'].forEach(box => {
      //   box.marker.setMap(this.showGreyBox ? map : null)
      //   const infowindow = new this.$store.getters.getGoogle.maps.InfoWindow({
      //     content: '<h2>' + box.name + ' </h2'
      //   })
      //   box.marker.addListener('click', function () {
      //     if (this.boxInfoWinOpen) {
      //       infowindow.close()
      //       this.boxInfoWinOpen = null
      //     } else {
      //       this.boxInfoWinOpen = infowindow
      //       infowindow.open(map, box.marker)
      //     }
      //   })
      // })

      this.$store.getters['reportModule/getAlertZoneMarker'].forEach(alert => {
        alert.marker.setMap(this.showDog ? map : null)
      })
    })
    // this.$refs.infoWindow.info.styles.display = 'none'
  }
}
</script>
<style>
  button.gm-ui-hover-effect img {
    /* width: 30px !important;
    height: 30px !important; */
    /* right: 30px !important; */
  }

  button.gm-ui-hover-effect {
    right: 5px !important;
    /* visibility: hidden; */
  }
</style>
