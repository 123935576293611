<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="444" fixed persistent transition="dialog-bottom-transition">
      <v-card>
        <v-app-bar dark color="primary">
          <v-btn icon x-large dark @click="dialog = false">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-app-bar>
        <v-card-title>{{ subTitle }}</v-card-title>
        <v-autocomplete
          v-model="selectItem"
          class="mt-2 mb-5"
          outlined
          filled
          label="Reference"
          :filter="customFilter"
          :items="items"
        ></v-autocomplete>

      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ['title', 'items', 'subTitle'],
  data () {
    return {
      dialog: true,
      selectItem: null
    }
  },
  methods: {
    customFilter (item, queryText, itemText) {
      const itemTextLow = itemText.toLowerCase()
      const searchTextArray = queryText.toLowerCase().split(' ')
      var result = false
      for (let i = 0; i < searchTextArray.length; i++) {
        const search = searchTextArray[i]
        if (search.length) {
          if (itemTextLow.indexOf(search) > -1 && search) {
            result = true
          } else {
            result = false
            break
          }
        }
      }
      return result
    }
  },
  watch: {
    selectItem (newValue) {
      this.$emit('select-item', newValue)
    },
    dialog (newValue) {
      this.dialog = false
      this.$store.dispatch('resize')
      this.$emit('close')
    }
  }
}
</script>
