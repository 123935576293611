<template>
  <div class="home">
    <v-dialog v-model="dialog" max-width="444" class="mx-auto overflow-hidden" centered>
      <v-card class="px-2 overflow-hidden" max-width="444">
          <v-row no-gutters>
            <v-btn @click="dialog = false" icon x-large><v-icon x-large>mdi-close</v-icon></v-btn>
            <v-spacer></v-spacer>
            <p class="title mt-3" align="center">{{ $t('common.parameters') }}</p>
            <v-spacer></v-spacer>
          </v-row>
          <v-card>
            <v-card-title>{{ $t('common.regrouping') }}</v-card-title>
            <v-select
              v-if="$store.getters.showSttpRouteAccess"
              class="mx-2"
              v-model="sttpGroupBy"
              outlined
              block
              :items="sttpGroupByItems"
              :label="$t('pmtparammapsinfo.sttpparam')"
              required
            ></v-select>
            <v-select
              v-if="$store.getters.showFfrsRouteAccess"
              class="mt-4 mx-2"
              v-model="ffrsGroupBy"
              outlined
              block
              :items="ffrsGroupByItems"
              :label="$t('pmtparammapsinfo.ffrsparam')"
              required
            ></v-select>
          </v-card>
          <v-layout row>
            <!-- showPocMarker -->
            <v-col cols="4" justify="center" align="center">
              <v-btn height="100" width="100%" :loading="showPocMarkerLoading" :color="showPocMarker ? 'green lighten-2' : 'red lighten-2'" @click="showPocMarker = !showPocMarker" block>
                <v-badge
                  overlap
                  bordered
                  :color="showPocMarker ? 'green' : 'red'"
                  :icon="showPocMarker ? 'mdi-check' : 'mdi-cancel'"
                  offset-x="15"
                  offset-y="10"
                >
                  <v-icon class="justify-center" :size="$store.getters.contentWidth < 380 ? 35 : 50" :color="showPocMarker ? 'black' : 'grey'">mdi-home-group</v-icon>
                </v-badge>
              </v-btn>
              <p class="subtitle text-center mt-1 mb-n2">{{ $t('common.pdr') }}</p>
            </v-col>
            <!-- showMe -->
            <v-col cols="4" justify="center" align="center">
              <v-btn height="100" width="100%" :loading="showMeLoading" :color="showMe ? 'green lighten-2' : 'red lighten-2'" @click="showMe = !showMe" block>
                <v-badge
                  overlap
                  bordered
                  :color="showMe ? 'green' : 'red'"
                  :icon="showMe ? 'mdi-check' : 'mdi-cancel'"
                  offset-x="10"
                  offset-y="10"
                >
                  <v-icon class="justify-center" :size="$store.getters.contentWidth < 380 ? 35 : 50" :color="showMe ? 'black' : 'grey'">mdi-human-child</v-icon>
                </v-badge>
              </v-btn>
              <p class="subtitle text-center mt-1 mb-n2">{{ $t('common.me') }}</p>
            </v-col>
            <!-- showDog -->
            <v-col cols="4" justify="center" align="center">
              <v-btn height="100" width="100%" :loading="showDogLoading" :color="showDog ? 'green lighten-2' : 'red lighten-2'" @click="showDog = !showDog" block>
                <v-badge
                  overlap
                  bordered
                  :color="showDog ? 'green' : 'red'"
                  :icon="showDog ? 'mdi-check' : 'mdi-cancel'"
                  offset-x="10"
                  offset-y="10"
                >
                  <v-icon class="justify-center" :size="$store.getters.contentWidth < 380 ? 35 : 50" :color="showDog ? 'red' : 'grey'">mdi-alert</v-icon>
                </v-badge>
              </v-btn>
              <p class="subtitle text-center mt-1 mb-n2">{{ $t('pmtparammapsinfo.dangerzone') }}</p>
            </v-col>
          </v-layout>
          <v-layout row>
            <!-- showToilet -->
            <v-col cols="4" justify="center" align="center">
              <v-btn height="100" width="100%" :loading="showToiletLoading" :color="showToilet ? 'green lighten-2' : 'red lighten-2'" @click="showToilet = !showToilet" block>
                <v-badge
                  overlap
                  bordered
                  :color="showToilet ? 'green' : 'red'"
                  :icon="showToilet ? 'mdi-check' : 'mdi-cancel'"
                  offset-x="10"
                  offset-y="10"
                >
                  <v-icon class="justify-center" :size="$store.getters.contentWidth < 380 ? 35 : 50" :color="showToilet ? 'black' : 'grey'">mdi-human-male-female</v-icon>
                </v-badge>
              </v-btn>
              <p class="subtitle text-center mt-1 mb-n2">{{ $t('pmtparammapsinfo.toilet') }}</p>
            </v-col>
            <!-- showParking -->
            <v-col cols="4" justify="center" align="center">
              <v-btn height="100" width="100%" :loading="showParkingLoading" :color="showParking ? 'green lighten-2' : 'red lighten-2'" @click="showParking = !showParking" block>
                <v-badge
                  overlap
                  bordered
                  :color="showParking ? 'green' : 'red'"
                  :icon="showParking ? 'mdi-check' : 'mdi-cancel'"
                  offset-x="10"
                  offset-y="10"
                >
                  <v-icon class="justify-center" :size="$store.getters.contentWidth < 380 ? 35 : 50" :color="showParking ? 'black' : 'grey'">mdi-parking</v-icon>
                </v-badge>
              </v-btn>
              <p class="subtitle text-center mt-1 mb-n2">{{ $t('pmtparammapsinfo.parking') }}</p>
            </v-col>
            <!-- showRedBox -->
            <v-col cols="4" justify="center" align="center">
              <v-btn height="100" width="100%" :loading="showRedBoxLoading" @click="showRedBox = !showRedBox" :color="showRedBox ? 'green lighten-2' : 'red lighten-2'" block>
                <v-badge
                  overlap
                  bordered
                  :color="showRedBox ? 'green' : 'red'"
                  :icon="showRedBox ? 'mdi-check' : 'mdi-cancel'"
                  offset-x="10"
                  offset-y="10"
                >
                  <v-img
                    :src="require('@/assets/Icon/MapIcon/redbox.png')"
                    alt="Grey Box"
                    :max-height="$store.getters.contentWidth < 380 ? 35 : 50"
                    width="50"
                    contain
                    centered
                  ></v-img>
                </v-badge>
              </v-btn>
              <p class="subtitle text-center mt-1 mb-n2">{{ $t('pmtparammapsinfo.redbox') }}</p>
            </v-col>
          </v-layout>
          <v-layout row>
            <!-- showBpCom -->
            <v-col cols="4" justify="center" align="center" v-if="$store.getters.showSttpRouteAccess">
              <v-btn height="100" width="100%" :loading="showBpComLoading" @click="showBpCom = !showBpCom" :color="showBpCom ? 'green lighten-2' : 'red lighten-2'" block>
                <v-badge
                  overlap
                  bordered
                  :color="showBpCom ? 'green' : 'red'"
                  :icon="showBpCom ? 'mdi-check' : 'mdi-cancel'"
                  offset-x="10"
                  offset-y="10"
                >
                  <v-img
                    :src="require('@/assets/Icon/MapIcon/Item/bpCom.png')"
                    alt="Bp COM"
                    :max-height="$store.getters.contentWidth < 380 ? 35 : 50"
                    width="50"
                    contain
                    centered
                  ></v-img>
                </v-badge>
              </v-btn>
              <p class="subtitle text-center mt-1 mb-n2">{{ $t('pmtparammapsinfo.bpcom') }}</p>
            </v-col>
            <!-- showCodeLine -->
            <v-col cols="4" justify="center" align="center" v-if="$store.getters.showSttpRouteAccess">
              <v-btn height="100" width="100%" :loading="showCodeLineLoading" :color="showCodeLine ? 'green lighten-2' : 'red lighten-2'" @click="showCodeLine = !showCodeLine" block>
                <v-badge
                  overlap
                  bordered
                  :color="showCodeLine ? 'green' : 'red'"
                  :icon="showCodeLine ? 'mdi-check' : 'mdi-cancel'"
                  offset-x="10"
                  offset-y="10"
                >
                  <v-icon class="justify-center" :size="$store.getters.contentWidth < 380 ? 35 : 50" :color="showCodeLine ? 'black' : 'grey'">mdi-subdirectory-arrow-right</v-icon>
                </v-badge>
              </v-btn>
              <p class="subtitle text-center mt-1 mb-n2">{{ $t('pmtparammapsinfo.codeline') }}</p>
            </v-col>
            <!-- showColorZone -->
            <v-col cols="4" justify="center" align="center">
              <v-btn height="100" width="100%" :loading="showColorZoneLoading" :color="showColorZone ? 'green lighten-2' : 'red lighten-2'" @click="showColorZone = !showColorZone" block>
                <v-badge
                  overlap
                  bordered
                  :color="showColorZone ? 'green' : 'red'"
                  :icon="showColorZone ? 'mdi-check' : 'mdi-cancel'"
                  offset-x="10"
                  offset-y="10"
                >
                  <v-icon class="justify-center" :size="$store.getters.contentWidth < 380 ? 35 : 50" :color="showColorZone ? 'black' : 'grey'">mdi-texture-box</v-icon>
                </v-badge>
              </v-btn>
              <p class="subtitle text-center mt-1 mb-n2">{{ $t('pmtparammapsinfo.colorZone') }}</p>
            </v-col>
          </v-layout>
          <v-layout row>
            <!-- showGreyBox -->
            <v-col cols="4" justify="center" align="center" v-if="$store.getters.showSttpRouteAccess">
              <v-btn height="100" width="100%" :loading="showGreyBoxLoading" @click="showGreyBox = !showGreyBox" :color="showGreyBox ? 'green lighten-2' : 'red lighten-2'" block>
                <v-badge
                  overlap
                  bordered
                  :color="showGreyBox ? 'green' : 'red'"
                  :icon="showGreyBox ? 'mdi-check' : 'mdi-cancel'"
                  offset-x="10"
                  offset-y="10"
                >
                  <v-img
                    :src="require('@/assets/Icon/MapIcon/GreyBox.png')"
                    alt="Grey Box"
                    :max-height="$store.getters.contentWidth < 380 ? 35 : 50"
                    width="50"
                    contain
                    centered
                  ></v-img>
                </v-badge>
              </v-btn>
              <p class="subtitle text-center mt-1 mb-n2">{{ $t('pmtparammapsinfo.greybox') }}</p>
            </v-col>
            <!-- showParcelLocker -->
            <v-col cols="4" justify="center" align="center">
              <v-btn height="100" width="100%" :loading="showParcelLockerLoading" @click="showParcelLocker = !showParcelLocker" :color="showParcelLocker ? 'green lighten-2' : 'red lighten-2'" block>
                <v-badge
                  overlap
                  bordered
                  :color="showParcelLocker ? 'green' : 'red'"
                  :icon="showParcelLocker ? 'mdi-check' : 'mdi-cancel'"
                  offset-x="10"
                  offset-y="10"
                >
                  <v-img
                    :src="require('@/assets/Icon/MapIcon/parcelLocker.png')"
                    alt="Grey Box"
                    :max-height="$store.getters.contentWidth < 380 ? 35 : 50"
                    width="50"
                    contain
                    centered
                  ></v-img>
                </v-badge>
              </v-btn>
              <p class="subtitle text-center mt-1 mb-n2">{{ $t('pmtparammapsinfo.parcellocker') }}</p>
            </v-col>
          </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialog: true,
      showBpCom: this.$store.getters['userLoggedModule/getMapInfoParam'].showBpCom,
      showRedBox: this.$store.getters['userLoggedModule/getMapInfoParam'].showRedBox,
      showParcelLocker: this.$store.getters['userLoggedModule/getMapInfoParam'].showParcelLocker,
      showGreyBox: this.$store.getters['userLoggedModule/getMapInfoParam'].showGreyBox,
      showParking: this.$store.getters['userLoggedModule/getMapInfoParam'].showParking,
      showToilet: this.$store.getters['userLoggedModule/getMapInfoParam'].showToilet,
      showMe: this.$store.getters['userLoggedModule/getMapInfoParam'].showMe,
      showDog: this.$store.getters['userLoggedModule/getMapInfoParam'].showDog,
      showCodeLine: this.$store.getters['userLoggedModule/getMapInfoParam'].showCodeLine,
      showColorZone: this.$store.getters['userLoggedModule/getMapInfoParam'].showColorZone,
      showPocMarker: this.$store.getters['userLoggedModule/getMapInfoParam'].showPocMarker,
      showGreyBoxLoading: false,
      showRedBoxLoading: false,
      showParcelLockerLoading: false,
      showBpComLoading: false,
      showParkingLoading: false,
      showToiletLoading: false,
      showMeLoading: false,
      showDogLoading: false,
      showCodeLineLoading: false,
      showColorZoneLoading: false,
      showPocMarkerLoading: false,
      refreshVacant: false,
      sttpGroupBy: this.$store.getters['userLoggedModule/getRouteInfoSttpGroupBy'],
      ffrsGroupBy: this.$store.getters['userLoggedModule/getRouteInfoFfrsGroupBy']
    }
  },
  watch: {
    dialog (newValue) {
      if (!newValue) {
        this.$emit('close')
      }
    },
    sttpGroupBy (newValue) {
      this.$store.dispatch('userLoggedModule/updateRouteInfoSttpGroupBy', newValue)
    },
    ffrsGroupBy (newValue) {
      this.$store.dispatch('userLoggedModule/updateRouteInfoFfrsGroupBy', newValue)
    },
    showGreyBox (newValue) {
      // this.$store.dispatch('userParamMapInfoShowGreyBox', newValue)
      this.showGreyBoxLoading = true
      this.$store.dispatch('userLoggedModule/updateParamMapInfoShowGreyBox', newValue)
        .then(() => { this.showGreyBoxLoading = false })
    },
    showRedBox (newValue) {
      // this.$store.dispatch('userParamMapInfoShowRedBox', newValue)
      this.showRedBoxLoading = true
      this.$store.dispatch('userLoggedModule/updateParamMapInfoShowRedBox', newValue)
        .then(() => { this.showRedBoxLoading = false })
    },
    showParcelLocker (newValue) {
      this.showParcelLockerLoading = true
      this.$store.dispatch('userLoggedModule/updateParamMapInfoShowParcelLocker', newValue)
        .then(() => { this.showParcelLockerLoading = false })
    },
    showBpCom (newValue) {
      // this.$store.dispatch('userParamMapInfoShowBpCom', newValue)
      this.showBpComLoading = true
      this.$store.dispatch('userLoggedModule/updateParamMapInfoShowBpCom', newValue)
        .then(() => { this.showBpComLoading = false })
    },
    showParking (newValue) {
      this.showParkingLoading = true
      this.$store.dispatch('userLoggedModule/updateParamMapInfoShowParking', newValue)
        .then(() => { this.showParkingLoading = false })
    },
    showToilet (newValue) {
      this.showToiletLoading = true
      this.$store.dispatch('userLoggedModule/updateParamMapInfoShowToilet', newValue)
        .then(() => { this.showToiletLoading = false })
    },
    showMe (newValue) {
      this.showMeLoading = true
      this.$store.dispatch('userLoggedModule/updateParamMapInfoShowMe', newValue)
        .then(() => { this.showMeLoading = false })
    },
    showDog (newValue) {
      this.showDogLoading = true
      this.$store.dispatch('userLoggedModule/updateParamMapInfoShowDog', newValue)
        .then(() => { this.showDogLoading = false })
    },
    showCodeLine (newValue) {
      this.showCodeLineLoading = true
      this.$store.dispatch('userLoggedModule/updateParamMapInfoShowCodeLine', newValue)
        .then(() => { this.showCodeLineLoading = false })
    },
    showColorZone (newValue) {
      this.showColorZoneLoading = true
      this.$store.dispatch('userLoggedModule/updateParamMapInfoShowColorZone', newValue)
        .then(() => { this.showColorZoneLoading = false })
    },
    showPocMarker (newValue) {
      this.showPocMarkerLoading = true
      this.$store.dispatch('userLoggedModule/updateParamMapInfoShowPocMarker', newValue)
        .then(() => { this.showPocMarkerLoading = false })
    }
  },
  methods: {},
  computed: {
    mapInfoParam () {
      return this.$store.getters['userLoggedModule/getMapInfoParam']
    },
    sttpGroupByItems () {
      return [
        { text: this.$t('group-poc-by.color'), value: 'color' },
        { text: this.$t('group-poc-by.code'), value: 'code' },
        { text: this.$t('group-poc-by.street'), value: 'street' },
        { text: this.$t('group-poc-by.cp'), value: 'cp' },
        { text: this.$t('group-poc-by.color-code'), value: 'color-code' }
        // { text: this.$t('group-poc-by.color-street'), value: 'color-street' },
        // { text: this.$t('group-poc-by.color-cp'), value: 'color-cp' },
        // { text: this.$t('group-poc-by.street-cp'), value: 'street-cp' }
      ]
    },
    ffrsGroupByItems () {
      return [
        { text: this.$t('group-poc-by.code'), value: 'code' },
        { text: this.$t('group-poc-by.street'), value: 'street' },
        { text: this.$t('group-poc-by.cp'), value: 'cp' }
        // { text: this.$t('group-poc-by.street-cp'), value: 'street-cp' }
      ]
    }
  }
}
</script>
