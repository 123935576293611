<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" id="dialog" fixed fullscreen transition="dialog-bottom-transition">
      <v-card id="masterCard">
        <v-app-bar dark height="60" color="primary">
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ $t('pictureEditor.title') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon x-large dark @click="quit()">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>
        <v-toolbar height="60">
          <v-btn icon x-large @click="doUndo()">
            <v-icon x-large>mdi-undo</v-icon>
          </v-btn>
          <v-btn icon x-large @click="doRedo()">
            <v-icon x-large>mdi-redo</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn icon x-large @click="setDrawMode('select')" class="mx-n1">
            <v-icon :color="drawMode == 'select' ? 'green' : 'grey'" x-large>mdi-cursor-default-click</v-icon>
          </v-btn>
          <v-btn icon x-large @click="setDrawMode('circle')" class="mx-n1">
            <v-icon :color="drawMode == 'circle' ? 'green' : 'grey'" x-large>mdi-checkbox-blank-circle-outline</v-icon>
          </v-btn>
          <v-btn icon x-large @click="setDrawMode('arrow')" class="mx-n1">
            <v-icon :color="drawMode == 'arrow' ? 'green' : 'grey'" x-large>mdi-arrow-bottom-left-thick</v-icon>
          </v-btn>
          <v-btn icon x-large @click="setDrawMode('text')" class="ms-n1">
            <v-icon :color="drawMode == 'text' ? 'green' : 'grey'" x-large>mdi-format-text</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card height="120">
            <v-card-title class="mt-n1">{{ $t('pictureEditor.subtitle-' + drawMode) }}</v-card-title>
          <v-row v-if="drawMode == 'text'" class="mt-n2" no-gutters>
            <v-col cols="8">
              <v-text-field
                v-model="text"
                autofocus
                outlined
                @change="setDrawMode('text')"
                :label="$t('common.text')"
                @blur="$store.dispatch('resize')"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select
                :items="textSizeItems"
                v-model="textSize"
                :label="$t('common.size')"
                outlined
              ></v-select>
            </v-col>
          </v-row>
        </v-card>
        <div align="center" justify="center" :width="$store.getters.screenSize.width" class="overflow-y-auto">
          <Editor
            :canvasWidth="canvasWidth"
            :canvasHeight="canvasHeight"
            ref="editor"
          />
        </div>
        <v-app-bar bottom absolute v-if="!showSelectColor">
          <v-btn icon x-large @click="showSelectColor = true">
            <v-icon :color="color" x-large>mdi-checkbox-blank-circle</v-icon>
          </v-btn>
        </v-app-bar>
        <v-app-bar bottom absolute v-if="showSelectColor" class="grey">
          <v-btn icon x-large @click="setColor('black')">
            <v-icon color="black" x-large>mdi-checkbox-blank-circle</v-icon>
          </v-btn>
          <v-btn icon x-large @click="setColor('white')">
            <v-icon color="white" large>mdi-checkbox-blank-circle</v-icon>
          </v-btn>
          <v-btn icon x-large @click="setColor('red')">
            <v-icon color="red" large>mdi-checkbox-blank-circle</v-icon>
          </v-btn>
          <v-btn icon x-large @click="setColor('blue')">
            <v-icon color="blue" x-large>mdi-checkbox-blank-circle</v-icon>
          </v-btn>
          <v-btn icon x-large @click="setColor('yellow')">
            <v-icon color="yellow" x-large>mdi-checkbox-blank-circle</v-icon>
          </v-btn>
          <v-btn icon x-large @click="setColor('purple')">
            <v-icon color="purple" x-large>mdi-checkbox-blank-circle</v-icon>
          </v-btn>
        </v-app-bar>
      </v-card>
      <confirm-popup
        v-if="showConfirm"
        @cancel="justQuit()"
        @confirm="saveAndQuit()"
        :title="$t('common.confirmKeepChange')"
        :param="{ mode: 'yesno' }"
      ></confirm-popup>
    </v-dialog>
  </v-row>
</template>

<script>
import Editor from 'vue-image-markup'

export default {
  props: ['pictureUrl'],
  data () {
    return {
      dialog: true,
      canvas: null,
      text: '',
      color: 'black',
      strokeWidth: 6,
      textSize: 32,
      textSizeItems: [
        { text: this.$t('common.small'), value: 16 },
        { text: this.$t('common.medium'), value: 32 },
        { text: this.$t('common.large'), value: 64 }
      ],
      resultPicture: null,
      refEditor: this.$refs.editor,
      EventBus: this.$store.getters.EventBus,
      drawMode: 'select',
      showSelectColor: false,
      showConfirm: false,
      toUpdate: false
    }
  },
  computed: {
    canvasWidth () {
      if (this.$store.getters.screenSize.width < this.$store.getters.screenSize.height) {
        return this.$store.getters.screenSize.width
      } else {
        return this.$store.getters.screenSize.height
      }
    },
    canvasHeight () {
      return (this.canvasWidth / 3) * 2
    }
  },
  created () {
  },
  mounted () {
    this.$refs.editor.setBackgroundImage(this.pictureUrl)
    this.setDrawMode(this.drawMode)
  },
  methods: {
    setColor (color) {
      this.showSelectColor = false
      this.color = color
      this.setDrawMode(this.drawMode)
    },
    applyChange () {
      this.resultPicture = this.$refs.editor.saveImage()
    },
    getPictureBlob () {
      return new Blob(this.$refs.editor.saveImage(), { type: 'image/jpeg' })
    },
    getPictureBlob2 () {
      const dataURI = this.$refs.editor.saveImage()
      // convert base64 to raw binary data held in a string
      // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
      var byteString = atob(dataURI.split(',')[1])

      // separate out the mime component
      // var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

      // write the bytes of the string to an ArrayBuffer
      var ab = new ArrayBuffer(byteString.length)
      var ia = new Uint8Array(ab)
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }

      // write the ArrayBuffer to a blob, and you're done
      var bb = new Blob([ab])
      return bb
    },
    doUndo () {
      this.$refs.editor.undo()
      this.setDrawMode(this.drawMode)
    },
    doRedo () {
      this.$refs.editor.redo()
      this.setDrawMode(this.drawMode)
    },
    setDrawMode (mode) {
      if (mode !== 'select') { this.toUpdate = true }
      switch (mode) {
        case 'circle':
          this.drawMode = 'circle'
          this.$refs.editor.set('circle', { stroke: this.color, strokeWidth: this.strokeWidth })
          break
        case 'arrow':
          this.drawMode = 'arrow'
          this.$refs.editor.set('arrow', { stroke: this.color, strokeWidth: this.strokeWidth })
          break
        case 'text':
          this.drawMode = 'text'
          this.$refs.editor.set('text', {
            id: 'title',
            fill: this.color,
            fontFamily: 'Verdana',
            fontStyle: 'normal',
            fontWeigth: 'bold',
            fontSize: this.textSize,
            placeholder: this.text
          })
          break
        case 'select':
          this.drawMode = 'select'
          this.$refs.editor.set('selectMode')
          break
        default:
          break
      }
    },
    saveAndQuit () {
      this.showConfirm = false
      this.$emit('toUpdatePicture', this.getPictureBlob2())
    },
    justQuit () {
      this.showConfirm = false
      this.$emit('close')
    },
    quit () {
      if (this.toUpdate) {
        this.showConfirm = true
      } else {
        this.$emit('close')
      }
    }
  },
  watch: {
    text () {
      this.setDrawMode('text')
    },
    textSize () {
      this.setDrawMode('text')
    }
  },
  components: {
    Editor
  }
}
</script>
