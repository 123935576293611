<template>
  <div>
    <v-snackbar
        v-model="message"
        multi-line
        top
        color="success"
        :timeout='3000'
    >
    {{ messagetxt }}
    </v-snackbar>
    <v-snackbar
        v-model="alert"
        multi-line
        top
        color="error"
        :timeout='3000'
    >
    <v-icon color="white">mdi-alert</v-icon>
    <p>{{ alerttxt }}</p>
    </v-snackbar>
    <v-snackbar
        v-model="info"
        multi-line
        top
        color="info"
        :timeout='6000'
    >
    {{ infotxt }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
      message: false,
      messagetxt: '',
      info: false,
      infotxt: '',
      alert: false,
      alerttxt: ''
    }
    //
  },
  computed: {
    sysmessage () {
      return this.$store.getters.message
    },
    sysalert () {
      return this.$store.getters.alert
    },
    sysinfo () {
      return this.$store.getters.info
    }
  },
  watch: {
    sysmessage (newValue) {
      if (newValue) {
        this.message = true
        this.messagetxt = newValue
        this.$store.dispatch('showMessage', null)
      }
    },
    sysalert (newValue) {
      if (newValue) {
        this.alert = true
        this.alerttxt = newValue
        this.$store.dispatch('showAlert', null)
      }
    },
    sysinfo (newValue) {
      if (newValue) {
        this.info = true
        this.infotxt = newValue
        this.$store.dispatch('showInfo', null)
      }
    }
  },
  methods: {}
}
</script>
