<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="444" fixed transition="dialog-bottom-transition">
      <v-card>
        <v-app-bar dark color="primary" :src="contactType === 'office' ? $store.getters.getUserSelectOfficePictureSrc : require('@/assets/postes-logo.jpg')">
          <v-btn icon x-large dark @click="dialog = false">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>Contact</v-toolbar-title>
          <v-spacer></v-spacer>
          <template v-slot:extension>
            <v-tabs align-with-title grow show-arrows v-model="contactType">
              <v-tab href="#office">{{ $t('common.office') }}</v-tab>
              <v-tab href="#global">Global</v-tab>
            </v-tabs>
          </template>
          <template v-slot:img="{ props }">
            <v-img
              v-bind="props"
              :gradient="$store.getters.getDayGradient"
            ></v-img>
          </template>
        </v-app-bar>

        <v-card  class="overflow-y-auto" :max-height="Math.round($store.getters.screenSize.height * 0.7)">
          <v-card-title v-if="this.contactType === 'global'">Global</v-card-title>
          <v-card-title v-else>{{ $t('common.office') }} : {{ ' ' + $store.state.officeSelectedModule.data.name }}</v-card-title>
          <v-list>
            <v-list-item
              v-for="contact in contactList"
              :key="contact.id"
            >
              <v-list-item-content two-line>
                <!-- <v-list-item-icon><v-icon color="primary" x-large>mdi-phone</v-icon></v-list-item-icon> -->
                <v-list-item-title>{{ contact.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ contact.detail }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-row no-gutters>
                  <v-btn :disabled="!contact.mail" :href="`mailto:+${ contact.mail }`" icon large>
                    <v-icon color="primary" large>mdi-mail</v-icon>
                  </v-btn>
                  <v-btn v-if="contact.phoneExt" :disabled="!contact.phone" class="ms-2" :href="`tel:${ contact.phone + ',' + contact.phoneExt }`" icon large>
                    <v-icon color="primary" large>mdi-phone</v-icon>
                  </v-btn>
                  <v-btn v-else :disabled="!contact.phone" class="ms-2" :href="`tel:${ contact.phone }`" icon large>
                    <v-icon color="primary" large>mdi-phone</v-icon>
                  </v-btn>
                </v-row>
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <!-- <v-list v-if="contactType === 'global'">
            <v-subheader>Global</v-subheader>
            <v-list-item
              v-for="(contact, i) in contactList"
              :key="i"
            >
              <v-list-item-content>
                <v-row no-gutters>
                  <p class="title">{{ contact.name }}</p>
                  <v-spacer></v-spacer>
                  <v-btn :disabled="!contact.mail" :href="`mailto:+${ contact.mail }`" icon x-large>
                    <v-icon color="primary" x-large>mdi-mail</v-icon>
                  </v-btn>
                  <v-btn v-if="contact.phoneExt" :disabled="!contact.phone" class="ms-2" :href="`tel:${ contact.phone + ',' + contact.phoneExt }`" icon x-large>
                    <v-icon color="primary" x-large>mdi-phone</v-icon>
                  </v-btn>
                  <v-btn v-else :disabled="!contact.phone" class="ms-2" :href="`tel:${ contact.phone }`" icon x-large>
                    <v-icon color="primary" x-large>mdi-phone</v-icon>
                  </v-btn>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list v-else class="mt-2">
            <v-subheader>{{ $t('common.office') }}</v-subheader>
            <v-list-item
              v-for="(contact, i) in contactList"
              :key="i"
            >
              <v-list-item-content>
                <v-row no-gutters>
                  <p class="title">{{ contact.name }}</p>
                  <v-spacer></v-spacer>
                  <v-btn :disabled="!contact.mail" :href="`mailto:+${ contact.mail }`" icon x-large>
                    <v-icon color="primary" x-large>mdi-mail</v-icon>
                  </v-btn>
                  <v-btn v-if="contact.phoneExt" :disabled="!contact.phone" class="ms-2" :href="`tel:${ contact.phone + ',' + contact.phoneExt }`" icon x-large>
                    <v-icon color="primary" x-large>mdi-phone</v-icon>
                  </v-btn>
                  <v-btn v-else :disabled="!contact.phone" class="ms-2" :href="`tel:${ contact.phone }`" icon x-large>
                    <v-icon color="primary" x-large>mdi-phone</v-icon>
                  </v-btn>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list> -->
         </v-card>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ['title'],
  data () {
    return {
      dialog: true,
      officeContactList: [],
      zoneContactList: [],
      contactType: 'office'
    }
  },
  computed: {
    contactList () {
      if (this.contactType === 'global') {
        return this.$store.getters['zoneModule/getContactList']
      } else {
        return this.$store.getters['officeSelectedModule/getContactList']
      }
    }
  },
  watch: {
    dialog (newValue) {
      this.$store.dispatch('resize')
      this.dialog = false
      this.$emit('close')
    }
  }
}
</script>
