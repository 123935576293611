const zoneModule = {
  firestorePath: 'zone',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'zoneModule',
  statePropName: 'data',
  namespaced: true, // automatically added

  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    getContactList: (state, getters, rootState, rootGetters) => {
      const zone = state.data[rootGetters.getSelectedZoneId]
      return zone.contactList ? zone.contactList : []
    },
    zoneList (state) {
      return state.data
    },
    zonecount (state) {
      return state.data.length
    },
    getSelectedZonePosition (state, getters, rootState, rootGetters) {
      return state.data[rootGetters.getSelectedZoneId].position
    },
    getZoneMarker (state, getters, rootState, rootGetters) {
      const google = rootGetters.getGoogle
      // const EventBus = rootGetters.EventBus
      const bounds = new google.maps.LatLngBounds(rootGetters['officeSelectedModule/getOfficePosition'])
      const zoneMarker = []
      for (const zoneId in state.data) {
        const zone = state.data[zoneId]
        bounds.extend(zone.position)
        const zonMarker = new google.maps.Marker({
          position: zone.position,
          label: { text: zone.name, color: 'black', fontSize: '16px' },
          title: zone.name,
          icon: {
            url: require('@/assets/logo.png'),
            // size: new this.google.maps.Size(48, 48),
            scaledSize: new google.maps.Size(60, 60), // scaled size
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(24, 28), // anchor
            // labelOrigin: new this.google.maps.Point(24, 32)
            labelOrigin: new google.maps.Point(30, -5)
          }
        })
        // const zoneInfo = { id: zoneId, name: zone.name }
        // if (this.zoneSelectId === zoneInfo.id) { this.select.zone = zoneInfo }

        // zonMarker.addListener('click', function () {
        //   EventBus.$emit('zoneClick', (zoneId))
        // })
        // zoneMarker.push({ id: zoneId, name: zone.name, marker: zonMarker })
        // zoneMarker.push(zonMarker)
        const isNotLoadedZone = rootGetters.getSelectedZoneId !== zoneId
        zoneMarker.push({
          type: 'zoneMarker',
          label: { text: zone.name, fontSize: '16px', color: isNotLoadedZone ? 'red' : 'green' },
          title: zone.name,
          url: require('@/assets/logo.png'),
          position: zone.position,
          zoneId: zoneId,
          isOpenInfoWindow: isNotLoadedZone,
          marker: zonMarker
        })
      }
      // this.zoneMarkerBounds = bounds.toJSON()
      return { markerList: zoneMarker, bounds: bounds.toJSON() }
    }
  },
  mutations: {
    setContact (state, payload) {
      const contactList = state.data[payload.zoneId].contactList ? state.data[payload.zoneId].contactList : []
      const index = contactList.map(x => { return x.id }).indexOf(payload.contact.id)
      if (index < 0) {
        contactList.push(payload.contact)
      } else {
        contactList[index] = payload.contact
      }
      state.data[payload.zoneId].contactList = contactList
      return this.dispatch('zoneModule/set', { id: payload.zoneId, contactList: contactList })
    },
    deleteContact (state, payload) {
      const contactList = state.data[payload.zoneId].contactList
      const index = contactList.map(x => { return x.id }).indexOf(payload.contactId)
      if (index < 0) {
        return null
      } else {
        contactList.splice(index, 1)
        return this.dispatch('zoneModule/set', { id: payload.zoneId, contactList: contactList })
      }
    }
  },
  actions: {
    saveContact ({ commit, state, getters, rootState, rootGetters }, payload) {
      if (!payload.id) { payload.id = this.getUniqueKey() }
      return commit('setContact', { zoneId: rootGetters.getSelectedZoneId, contact: payload })
    },
    deleteContact ({ commit, state, getters, rootState, rootGetters }, payload) {
      return commit('deleteContact', { zoneId: rootGetters.getSelectedZoneId, contactId: payload })
    }
  }
}

export default zoneModule
