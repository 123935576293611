import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'
import fr from 'vuetify/es5/locale/fr'
import en from 'vuetify/es5/locale/en'
// import myIconSvg from '@/assets/Icon/Svg/test5.svg'
Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { fr, en },
    current: 'en'
  },
  icons: {
    iconfont: 'mdiSvg'
  },
  // secondary: '#FF0000'
  themes: {
    light: {
      primary: '#1f9dd5',
      secondary: colors.shades.black,
      accent: colors.amber.accent3,
      error: colors.red.accent2,
      info: colors.blue,
      success: '#4CAF50',
      warning: '#FFC107'
    },
    dark: {
      primary: '#1f9dd5',
      secondary: colors.shades.black,
      accent: colors.amber.accent3,
      error: '#FF5252',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FFC107'
    }
    // light: {
    //   primary: '#1f9dd5',
    //   secondary: '#DAA520',
    //   accent: '#82B1FF',
    //   error: '#FF5252',
    //   info: '#2196F3',
    //   success: '#4CAF50',
    //   warning: '#FFC107'
    // }
  }
})
