
<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent fixed max-width="444" transition="dialog-bottom-transition">
      <v-card class="overflow-hidden">
        <v-app-bar dark color="primary">
          <v-btn icon x-large dark @click="dialog = false">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ $t('common.deliveryroute') }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-app-bar>
        <v-card class="overflow-y-auto overflow-x-hidden" :max-height="Math.round($store.getters.contentHeight * 0.80)">
          <v-card-title>{{ $store.state.routeModule.data[$store.getters.getSelectedRouteId].name }}</v-card-title>
          <v-card-text>
            <form @submit.prevent="createTravel">
              <v-text-field required v-model="travelName" :label="$t('common.name')" outlined block></v-text-field>
              <v-select required outlined v-model="travelTransportType" :items="transportTypeItems" :label="$t('common.transporttype')" block></v-select>
              <v-select required outlined v-model="travelPart" :items="partItems" :label="$t('common.part')" block></v-select>
              <v-checkbox v-model="fromDirectory" :label="$t('travel.generateFromDir')"></v-checkbox>
              <!-- <v-checkbox v-if="$store.getters.isSupervisorAccess || $store.getters.isLocalAdmin" :disabled="isTravelOfficialOfRouteExist" v-model="isOfficial" :label="$t('travel.isOfficial')"></v-checkbox> -->
              <v-btn class="mt-3" :loading="showLoading" rounded :disabled="!readyToCreate" block x-large type="submit">{{ $t('common.next') }}</v-btn>
            </form>
          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>
    <!-- <loading-popup v-if="showLoading" :title="loadingTitle" :subTitle="loadingsubTitle"></loading-popup> -->
  </v-row>
</template>

<script>
export default {
  props: ['travelId'],
  data () {
    return {
      dialog: true,
      fromDirectory: false,
      loadingTitle: this.$t('common.loading'),
      loadingsubTitle: this.$t('edit-route.loadingDesc'),
      showLoading: false,
      travelStep: [],
      travelRouteId: this.$store.getters.selectedRouteId,
      travelName: null,
      travelTransportType: null,
      travelType: null,
      travelPart: null,
      transportTypeItems: [{
        text: 'Taxi',
        value: 'taxi'
      }, {
        text: this.$t('common.vehicle'),
        value: 'truck'
      }],
      partItems: [{
        text: this.$t('common.full'),
        value: 'all'
      }, {
        text: this.$t('common.orange'),
        value: '1'
      }, {
        text: this.$t('common.pink'),
        value: '2'
      }, {
        text: this.$t('common.blue'),
        value: '3'
      }],
      stepGroup: [],
      step: []
    }
  },
  computed: {
    isTravelOfficialOfRouteExist () {
      return false
    },
    readyToCreate () {
      if (!this.travelTransportType) {
        return false
      } else {
        return true
      }
    },
    routeName () {
      return this.$store.state.routeModule.data[this.routeSelectId].name
    },
    routeSelectId () {
      return this.$store.getters.getSelectedRouteId
    },
    pocOfTravel () {
      if (this.travelPart === 'all') {
        return this.pocOfRoute
      } else {
        return this.pocOfRoute.filter(x => String(x.colorId) === String(this.travelPart))
      }
    },
    pocOfRoute () {
      return this.$store.getters['pocModule/getPocOfRoute'](this.routeSelectId).sort(function (a, b) {
        return a.seqPosTot - b.seqPosTot
      })
    }
  },
  methods: {
    generateTravelBaseEmpty () {
      return this.pocOfTravel.filter(x => x.codeId !== undefined && x.colorId !== undefined).map(x => {
        return {
          pocId: x.id,
          codeId: x.codeId,
          colorId: x.colorId,
          stepId: null,
          stepPos: null,
          dirPos: x.seqPosTot
        }
      })
    },
    generateTravelInfoTaxiFromDir () {
      const travelBase = []
      // const newSgId = this.$store.getUniqueKey
      const groupId = this.$store.getUniqueKey()
      let stepId = this.$store.getUniqueKey()
      let stepPosInGroup = 1
      let posInStep = 1
      const step = []
      const stepGroup = [{
        groupId: groupId,
        gsPosition: 1,
        groupItems: [],
        type: 'start'
      }]
      const groupIdx = 0
      this.pocOfTravel.filter(x => x.codeId !== undefined && x.codeId !== null && x.colorId !== undefined).forEach(poc => {
        // Creer depart
        if (!step.length) {
          posInStep = 1
          step.push({
            stepId: stepId,
            groupId: groupId,
            type: 'start-taxi',
            colorId: poc.colorId,
            pocId: poc.id,
            position: poc.homeLocation,
            address: poc.pdrListAddress,
            stepPosInGroup: stepPosInGroup++
          })
          stepGroup[groupIdx].groupItems.push({
            type: 'step',
            id: stepId
          })
        }
        const codeStepIdx = step.filter(x => x.type === 'code').map(x => { return x.codeId }).indexOf(poc.codeId)
        // const colorStepIdx = colorStep.map(x => { return x.colorId }).indexOf(poc.colorId)
        if (codeStepIdx < 0) {
          stepId = this.$store.getUniqueKey()
          posInStep = 1
          step.push({
            stepId: stepId,
            groupId: groupId,
            type: 'code',
            codeId: poc.codeId,
            codeName: poc.codeName,
            colorId: poc.colorId,
            stepPosInGroup: stepPosInGroup++
          })
          stepGroup[groupIdx].groupItems.push({
            type: 'step',
            id: stepId
          })
        }
        travelBase.push({
          pocId: poc.id,
          codeId: poc.codeId,
          colorId: poc.colorId,
          stepId: stepId,
          posInStep: posInStep++,
          dirPos: poc.seqPosTot
        })
      })
      return { step: step, stepGroup: stepGroup, travelBase: travelBase }
    },
    generateTravelInfo () {
      const itemsUnAssigned = []
      this.pocOfTravel.forEach(poc => {
        itemsUnAssigned.push({
          type: 'poc',
          id: poc.id,
          codeId: poc.codeId,
          codeName: poc.codeName,
          colorId: poc.colorId,
          isUse: false
        })
      })
      return { items: itemsUnAssigned, group: [], travelWayPoint: [] }
    },
    generateTravelInfoFromDir_back () {
      let pos = 1
      const items = []
      const group = ['sector']
      const colorGroup = []
      const codeGroup = []
      const cpGroup = []
      const travelWayPoint = []
      let cWpId = null
      let cWp = { type: 'vide' }
      // const streetGroup = []
      if (this.transportType === 'taxi') {
        cWpId = this.$store.getUniqueKey()
        cWp = {
          type: 'taxi-start',
          loc: this.pocOfTravel[0].homeLocation,
          wpId: cWpId
        }
        travelWayPoint.push(cWp)
      }
      // "dtd" : "Porte à porte",
      // "cmb" : "Boîte Postale Communautaire",
      // "lba" : "Batterie de boîte aux lettres",
      // "aptlba" : "Batterie de boîte aux lettres (apt)",
      // "ksk" : "Kiosque",
      // "dflb" : "Boîte postale dans un édifice de livraison",
      // "rmb" : "Boîte aux lettre rurale",
      // "cntr" : "Comptoir",
      for (let i = 0; i < this.pocOfTravel.length; i++) {
        const poc = this.pocOfTravel[i]
        if (this.transportType !== 'taxi') {
          const multiId = poc.mapItems[0].officeItemMultiId ? poc.mapItems[0].officeItemMultiId : null
          switch (poc.tpType) {
            case 'dtd':
              if (cWp.type !== 'park-loop') {
                cWpId = this.$store.getUniqueKey()
                cWp = {
                  type: 'park-loop',
                  loc: poc.homeLocation,
                  wpId: cWpId
                }
                travelWayPoint.push(cWp)
              }
              break
            case 'cmb':
              if (!multiId) {
                // // console.log('erreur bpcom non importer')
                break
              }
              if (cWp.type !== 'cmb' || (cWp.type === 'cmb' && cWp.multiId !== multiId)) {
                cWpId = this.$store.getUniqueKey()
                cWp = {
                  type: 'cmb',
                  multiId: multiId,
                  loc: this.$store.state.officeItemMultiModule.data[multiId].loc,
                  wpId: cWpId
                }
                travelWayPoint.push(cWp)
              }
              break
            case 'lba':
              if ((cWp.type !== 'lba' && cWp.type !== 'park-loop') || (cWp.type === 'lba' && cWp.multiId !== multiId)) {
                cWpId = this.$store.getUniqueKey()
                cWp = {
                  type: 'lba',
                  multiId: multiId,
                  loc: multiId ? this.$store.state.officeItemMultiModule.data[multiId].loc : poc.homeLocation,
                  wpId: cWpId
                }
                travelWayPoint.push(cWp)
              }
              break
            case 'aptlba':
              if ((cWp.type !== 'aptlba' && cWp.type !== 'park-loop') || (cWp.type === 'aptlba' && cWp.multiId !== multiId)) {
                cWpId = this.$store.getUniqueKey()
                cWp = {
                  type: 'aptlba',
                  multiId: multiId,
                  loc: multiId ? this.$store.state.officeItemMultiModule.data[multiId].loc : poc.homeLocation,
                  wpId: cWpId
                }
                travelWayPoint.push(cWp)
              }
              break
            case 'ksk':
              cWpId = this.$store.getUniqueKey()
              cWp = {
                type: 'ksk',
                loc: poc.homeLocation,
                wpId: cWpId
              }
              travelWayPoint.push(cWp)
              break
            case 'dflb':
              if (cWp.type !== 'dflb' || (cWp.type === 'dflb' && cWp.multiId !== multiId)) {
                cWpId = this.$store.getUniqueKey()
                cWp = {
                  type: 'dflb',
                  multiId: multiId,
                  loc: multiId ? this.$store.state.officeItemMultiModule.data[multiId].loc : poc.homeLocation,
                  wpId: cWpId
                }
                travelWayPoint.push(cWp)
              }
              break
            case 'rmb':
              cWpId = this.$store.getUniqueKey()
              cWp = {
                type: 'rmb',
                loc: poc.homeLocation,
                wpId: cWpId
              }
              travelWayPoint.push(cWp)
              break
            case 'cntr':
              if (cWp.type !== 'park-loop') {
                cWpId = this.$store.getUniqueKey()
                cWp = {
                  type: 'cntr',
                  loc: poc.homeLocation,
                  wpId: cWpId
                }
                travelWayPoint.push(cWp)
              }
              break
            default:
              // // console.log('erreur type non pris en charge ' + poc.tpType)
              break
          }
        }
        if (poc.codeId !== undefined && poc.codeId !== null) {
          const codeIdx = codeGroup.indexOf(poc.codeId)
          if (codeIdx < 0) {
            codeGroup.push(poc.CodeId)
          }
        }
        if (poc.colorId !== undefined && poc.colorId !== null) {
          const colorIdx = colorGroup.indexOf(poc.colorId)
          if (colorIdx < 0) {
            colorGroup.push(poc.ColorId)
          }
        }
        const cp = poc.pdrListPostalCode.substring(0, 3)
        const cpIdx = cpGroup.indexOf(cp)
        if (cpIdx < 0) {
          colorGroup.push(cp)
        }
        const item = {
          type: 'poc',
          id: poc.id,
          codeId: poc.codeId,
          codeName: poc.codeName,
          colorId: poc.colorId,
          group: null,
          wpId: cWpId,
          pos: pos++,
          isUse: true
        }
        if (poc.placeId) { item.placeId = poc.placeId }
        if (poc.mapItems[0].officeItemMultiId) {
          item.multiId = poc.mapItems[0].officeItemMultiId
        }
        items.push(item)
      }
      // if (cpGroup.length > 1) { group.push('cp') }
      if (colorGroup.length > 1) { group.push('color') }
      if (codeGroup.length > 1) { group.push('code') }
      group.push('cp')
      group.push('street')
      group.push('multi')
      return { items: items, group: group, travelWayPoint: travelWayPoint }
    },
    generateTravelInfoFromDir () {
      let pos = 1
      const items = []
      const group = ['sector']
      const colorGroup = []
      const codeGroup = []
      const cpGroup = []
      const travelWayPoint = []
      const isTaxi = this.transportType === 'taxi'
      const steps = []
      let cStepId = null
      let cStep = { type: 'vide' }
      let cWpId = null
      let cWp = { type: 'vide' }
      // const streetGroup = []
      if (isTaxi) {
        cWpId = this.$store.getUniqueKey()
        cWp = {
          type: 'taxi-start',
          loc: this.pocOfTravel[0].homeLocation,
          wpId: cWpId
        }
        travelWayPoint.push(cWp)
      }
      // "dtd" : "Porte à porte",
      // "cmb" : "Boîte Postale Communautaire",
      // "lba" : "Batterie de boîte aux lettres",
      // "aptlba" : "Batterie de boîte aux lettres (apt)",
      // "ksk" : "Kiosque",
      // "dflb" : "Boîte postale dans un édifice de livraison",
      // "rmb" : "Boîte aux lettre rurale",
      // "cntr" : "Comptoir",
      for (let i = 0; i < this.pocOfTravel.length; i++) {
        const poc = this.pocOfTravel[i]
        if (!isTaxi) {
          const multiId = poc.mapItems[0].officeItemMultiId ? poc.mapItems[0].officeItemMultiId : null
          switch (poc.tpType) {
            case 'dtd':
              if (cWp.type !== 'park-loop') {
                cWpId = this.$store.getUniqueKey()
                cWp = {
                  type: 'park-loop',
                  loc: poc.homeLocation,
                  wpId: cWpId
                }
                travelWayPoint.push(cWp)
              }
              break
            case 'cmb':
              if (!multiId) {
                // // console.log('erreur bpcom non importer')
                break
              }
              if (cWp.type !== 'cmb' || (cWp.type === 'cmb' && cWp.multiId !== multiId)) {
                cWpId = this.$store.getUniqueKey()
                cWp = {
                  type: 'cmb',
                  multiId: multiId,
                  loc: this.$store.state.officeItemMultiModule.data[multiId].loc,
                  wpId: cWpId
                }
                travelWayPoint.push(cWp)
              }
              break
            case 'lba':
              if ((cWp.type !== 'lba' && cWp.type !== 'park-loop') || (cWp.type === 'lba' && cWp.multiId !== multiId)) {
                cWpId = this.$store.getUniqueKey()
                cWp = {
                  type: 'lba',
                  multiId: multiId,
                  loc: multiId ? this.$store.state.officeItemMultiModule.data[multiId].loc : poc.homeLocation,
                  wpId: cWpId
                }
                travelWayPoint.push(cWp)
              }
              break
            case 'aptlba':
              if ((cWp.type !== 'aptlba' && cWp.type !== 'park-loop') || (cWp.type === 'aptlba' && cWp.multiId !== multiId)) {
                cWpId = this.$store.getUniqueKey()
                cWp = {
                  type: 'aptlba',
                  multiId: multiId,
                  loc: multiId ? this.$store.state.officeItemMultiModule.data[multiId].loc : poc.homeLocation,
                  wpId: cWpId
                }
                travelWayPoint.push(cWp)
              }
              break
            case 'ksk':
              cWpId = this.$store.getUniqueKey()
              cWp = {
                type: 'ksk',
                loc: poc.homeLocation,
                wpId: cWpId
              }
              travelWayPoint.push(cWp)
              break
            case 'dflb':
              if (cWp.type !== 'dflb' || (cWp.type === 'dflb' && cWp.multiId !== multiId)) {
                cWpId = this.$store.getUniqueKey()
                cWp = {
                  type: 'dflb',
                  multiId: multiId,
                  loc: multiId ? this.$store.state.officeItemMultiModule.data[multiId].loc : poc.homeLocation,
                  wpId: cWpId
                }
                travelWayPoint.push(cWp)
              }
              break
            case 'rmb':
              cWpId = this.$store.getUniqueKey()
              cWp = {
                type: 'rmb',
                loc: poc.homeLocation,
                wpId: cWpId
              }
              travelWayPoint.push(cWp)
              break
            case 'cntr':
              if (cWp.type !== 'park-loop') {
                cWpId = this.$store.getUniqueKey()
                cWp = {
                  type: 'cntr',
                  loc: poc.homeLocation,
                  wpId: cWpId
                }
                travelWayPoint.push(cWp)
              }
              break
            default:
              // // console.log('erreur type non pris en charge ' + poc.tpType)
              break
          }
        }
        switch (poc.tpType) {
          case 'lba':
          case 'aptlba':
          case 'ksk':
          case 'dflb':
          case 'rmb':
          case 'cntr':
          case 'dtd':
            if (cStep.type !== 'loop' || cStep.fromWpId !== cWpId) {
              cStepId = this.$store.getUniqueKey()
              cStep = {
                type: 'loop',
                stepId: cStepId,
                pos: steps.length + 1,
                fromItemId: null,
                toItemId: null,
                fromWpId: cWpId,
                toWpId: null
              }
              steps.push(cStep)
            }
            break
          case 'cmb':
            if (cStep.type !== 'colis-zone' || cStep.fromWpId !== cWpId) {
              const cmbStepId = this.$store.getUniqueKey()
              cStep = {
                type: 'cmb-direct',
                stepId: cmbStepId,
                pos: steps.length + 1,
                fromItemId: null,
                toItemId: null,
                fromWpId: cWpId,
                toWpId: null
              }
              steps.push(cStep)
              cStepId = this.$store.getUniqueKey()
              cStep = {
                type: 'colis-zone',
                stepId: cStepId,
                cmbStepId: cmbStepId,
                pos: steps.length + 1,
                fromItemId: null,
                toItemId: null,
                fromWpId: cWpId,
                toWpId: null
              }
              steps.push(cStep)
            }
            break
          default:
            // console.log(poc.tpType)
            break
        }
        if (poc.codeId !== undefined && poc.codeId !== null) {
          const codeIdx = codeGroup.indexOf(poc.codeId)
          if (codeIdx < 0) {
            codeGroup.push(poc.CodeId)
          }
        }
        if (poc.colorId !== undefined && poc.colorId !== null) {
          const colorIdx = colorGroup.indexOf(poc.colorId)
          if (colorIdx < 0) {
            colorGroup.push(poc.ColorId)
          }
        }
        const cp = poc.pdrListPostalCode.substring(0, 3)
        const cpIdx = cpGroup.indexOf(cp)
        if (cpIdx < 0) {
          colorGroup.push(cp)
        }
        const item = {
          type: 'poc',
          id: poc.id,
          codeId: poc.codeId,
          codeName: poc.codeName,
          colorId: poc.colorId,
          group: null,
          wpId: cWpId,
          stepId: cStepId,
          pos: pos++,
          isUse: true
        }
        if (poc.placeId) { item.placeId = poc.placeId }
        if (poc.mapItems[0].officeItemMultiId) {
          item.multiId = poc.mapItems[0].officeItemMultiId
        }
        items.push(item)
      }
      // if (cpGroup.length > 1) { group.push('cp') }
      if (colorGroup.length > 1) { group.push('color') }
      if (codeGroup.length > 1) { group.push('code') }
      group.push('cp')
      group.push('street')
      group.push('multi')
      // console.log(steps)
      return { items: items, group: group, travelWayPoint: travelWayPoint, steps: steps }
    },
    createTravel () {
      // // console.log('generate travel')
      // const tbInfo = this.generateTravelInfoTaxiFromDir()
      this.showLoading = true
      this.$store.dispatch('officeTravelModule/set', {
        name: this.travelName,
        transportType: this.travelTransportType,
        type: this.travelType,
        part: this.travelPart,
        routeId: this.routeSelectId,
        isOfficial: false,
        isActive: false,
        share: false,
        customGroup: [],
        // travelDetail: this.fromDirectory ? this.generateTravelDetailFromDir() : this.generateTravelDetail(),
        travelInfo: this.fromDirectory ? this.generateTravelInfoFromDir() : this.generateTravelInfo()
        // travelInfo: this.generateTravel()
        // step: tbInfo.step,
        // stepGroup: tbInfo.stepGroup,
        // base: tbInfo.travelBase
      }).then((travelId) => {
        this.showLoading = false
        this.$emit('create-close', travelId)
      })
    }
  },
  watch: {
    dialog (newValue) {
      this.dialog = false
      this.$store.dispatch('resize')
      this.$emit('close')
    }
  }
}
</script>
<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
