<template>
  <div class="home text-center">
    <pmt-loading-progress-local
      :loadingTitle="loadingTitle"
      :progressValue="loadingValue"
      :loadingShow="loadingShow"
    ></pmt-loading-progress-local>
    <h1 class="mt-3">{{ $t('pmtroadimport.title') }}</h1>
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1">{{ $t('pmtroadimport.step1menutitle') }}</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2">{{ $t('pmtroadimport.step2menutitle') }}</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3">{{ $t('pmtroadimport.step3menutitle') }}</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card
            class="mb-12"
            color="grey lighten-1"
          >
            <v-flex xs12 sm6 offset-sm3>
              <v-card
                height="400px"
                color="primary"
                class="mt-5"
                dark
              >
                <v-card-title>{{ $t('pmtroadimport.step1title') }}</v-card-title>
                <v-card-text>
                <v-file-input class="mt-3 mx-5" outlined accept=".csv" @change="processSeqFile" :label="$t('pmtroadimport.sequencelabel')" v-model="seqfiles"></v-file-input>

                <h3 v-show="step1complete" class="mt-5" title> {{ $t('pmtroadimport.filedetail') }}</h3>
                <p v-show="step1complete" class="mt-2" subheader>{{ $t('common.office') }} : {{ officeName }}</p>
                <p v-show="step1complete" class="mt-n5" subheader>{{ $t('common.route') }} : {{ roadName }}</p>
                <p v-show="step1complete" class="mt-n5" subheader>{{ $t('common.pdr') }} : {{ seqCodeTab.length }}</p>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-card>

          <v-btn
            text
            @click="cancel1"
          >{{ $t('pmtroadimport.cancelbutton') }}</v-btn>
          <v-btn
            color="primary"
            @click="e1 = 2"
            :disabled="!step1complete"
          >
            {{ $t('pmtroadimport.nextbutton') }}
          </v-btn>
          <v-btn
            text
            @click="verifPdrGoogleInfo"
          >Verif</v-btn>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card
            class="mb-12"
            color="grey lighten-1"
            height="400px"
            dark
          >
            <v-flex xs12 sm6 offset-sm3>
              <v-card
                height="400px"
                color="primary"
                class="mt-5"
              >
                <v-card-title>{{ $t('pmtroadimport.step2title') }}</v-card-title>
                <v-card-text>
                  <v-combobox
                    class="mt-5"
                    v-model="fpinkpdr"
                    :items="pdrSearch"
                    color="pink"
                    outlined
                    auto-select-first
                    :no-data-text="$t('pmtroadimport.nodatafind')"
                    :label="$t('pmtroadimport.firstpink')"
                    >
                    </v-combobox>
                    <v-combobox
                    v-model="fbluepdr"
                    :items="pdrSearch"
                    color="blue"
                    outlined
                    auto-select-first
                    :no-data-text="$t('pmtroadimport.nodatafind')"
                    :label="$t('pmtroadimport.firstblue')"
                  >
                  </v-combobox>
                  <h4 class="mt-n1" color="orange" subheader>{{ $t('pmtroadimport.orangepoc') }} : {{ cptorangecode }}</h4>
                  <h4 class="mt-n1" color="pink" subheader>{{ $t('pmtroadimport.pinkpoc') }} : {{ cptpinkcode }}</h4>
                  <h4 class="mt-n1" color="blue" subheader>{{ $t('pmtroadimport.bluepoc') }} : {{ cptbluecode }}</h4>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-card>

          <v-btn
            text
            @click="cancel2"
          >{{ $t('pmtroadimport.cancelbutton') }}</v-btn>
          <v-btn
            color="primary"
            @click="e1 = 3"
            :disabled="!step2complete"
          >
            {{ $t('pmtroadimport.nextbutton') }}
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card
            class="mb-12"
            color="grey lighten-1"
            height="500px"
            dark
          >
          <v-flex xs12 sm6 offset-sm3>
             <v-card
                height="500px"
                color="primary"
              >
                <v-card-title>{{ $t('pmtroadimport.step3title') }}</v-card-title>
                <v-card-text>
                  <v-file-input accept=".csv" @change="processPdrListFile" :label="$t('pmtroadimport.pdrlabel')" v-model="pdrlistfiles"></v-file-input>
                  <v-simple-table v-if="stats" dense width="80%">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">{{ roadName }}</th>
                          <th class="text-center">{{ $t('common.dom') }}</th>
                          <th class="text-center">{{ $t('common.app') }}</th>
                          <th class="text-center">{{ $t('common.bus') }}</th>
                          <th class="text-center">{{ $t('common.frm') }}</th>
                          <th class="text-center">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="orange--text">
                          <td class="text-left"> {{ $t('common.orange') }} </td>
                          <td class="text-center"> {{ stats.cptOrangeDomPub }} / {{ stats.cptOrangeDom }}  ({{ stats.cptOrangeDomVacant }})</td>
                          <td class="text-center"> {{ stats.cptOrangeAppPub }} / {{ stats.cptOrangeApp }}  ({{ stats.cptOrangeAppVacant }}) </td>
                          <td class="text-center"> {{ stats.cptOrangeBusPub }} / {{ stats.cptOrangeBus }}  ({{ stats.cptOrangeBusVacant }}) </td>
                          <td class="text-center"> {{ stats.cptOrangeFrmPub }} / {{ stats.cptOrangeFrm }}  ({{ stats.cptOrangeFrmVacant }}) </td>
                          <td class="text-center"> {{ stats.cptOrangeDomPub + stats.cptOrangeAppPub + stats.cptOrangeBusPub + stats.cptOrangeFrmPub }} / {{ stats.cptOrangeDom + stats.cptOrangeApp + stats.cptOrangeBus + stats.cptOrangeFrm }}  ({{ stats.cptOrangeDomVacant + stats.cptOrangeAppVacant + stats.cptOrangeBusVacant + stats.cptOrangeFrmVacant }}) </td>
                        </tr>
                        <tr class="pink--text">
                          <td class="text-left"> {{ $t('common.pink') }} </td>
                          <td class="text-center"> {{ stats.cptPinkDomPub }} / {{ stats.cptPinkDom }}  ({{ stats.cptPinkDomVacant }})</td>
                          <td class="text-center"> {{ stats.cptPinkAppPub }} / {{ stats.cptPinkApp }}  ({{ stats.cptPinkAppVacant }}) </td>
                          <td class="text-center"> {{ stats.cptPinkBusPub }} / {{ stats.cptPinkBus }}  ({{ stats.cptPinkBusVacant }}) </td>
                          <td class="text-center"> {{ stats.cptPinkFrmPub }} / {{ stats.cptPinkFrm }}  ({{ stats.cptPinkFrmVacant }}) </td>
                          <td class="text-center"> {{ stats.cptPinkDomPub + stats.cptPinkAppPub + stats.cptPinkBusPub + stats.cptPinkFrmPub }} / {{ stats.cptPinkDom + stats.cptPinkApp + stats.cptPinkBus + stats.cptPinkFrm }}  ({{ stats.cptPinkDomVacant + stats.cptPinkAppVacant + stats.cptPinkBusVacant + stats.cptPinkFrmVacant }}) </td>
                        </tr>
                        <tr class="blue--text">
                          <td class="text-left"> {{ $t('common.blue') }} </td>
                          <td class="text-center"> {{ stats.cptBlueDomPub }} / {{ stats.cptBlueDom }}  ({{ stats.cptBlueDomVacant }})</td>
                          <td class="text-center"> {{ stats.cptBlueAppPub }} / {{ stats.cptBlueApp }}  ({{ stats.cptBlueAppVacant }}) </td>
                          <td class="text-center"> {{ stats.cptBlueBusPub }} / {{ stats.cptBlueBus }}  ({{ stats.cptBlueBusVacant }}) </td>
                          <td class="text-center"> {{ stats.cptBlueFrmPub }} / {{ stats.cptBlueFrm }}  ({{ stats.cptBlueFrmVacant }}) </td>
                          <td class="text-center"> {{ stats.cptBlueDomPub + stats.cptBlueAppPub + stats.cptBlueBusPub + stats.cptBlueFrmPub }} / {{ stats.cptBlueDom + stats.cptBlueApp + stats.cptBlueBus + stats.cptBlueFrm }}  ({{ stats.cptBlueDomVacant + stats.cptBlueAppVacant + stats.cptBlueBusVacant + stats.cptBlueFrmVacant }}) </td>
                        </tr>
                        <tr>
                          <td class="text-left"> Total </td>
                          <td class="text-center"> {{
                              stats.cptOrangeDomPub +
                              stats.cptPinkDomPub +
                              stats.cptBlueDomPub
                            }} / {{
                              stats.cptOrangeDom +
                              stats.cptPinkDom +
                              stats.cptBlueDom
                            }}  ({{
                              stats.cptOrangeDomVacant +
                              stats.cptPinkDomVacant +
                              stats.cptBlueDomVacant
                            }})
                          </td>
                          <td class="text-center"> {{
                              stats.cptOrangeAppPub +
                              stats.cptPinkAppPub +
                              stats.cptBlueAppPub
                            }} / {{
                              stats.cptOrangeApp +
                              stats.cptPinkApp +
                              stats.cptBlueApp
                            }}  ({{
                              stats.cptOrangeAppVacant +
                              stats.cptPinkAppVacant +
                              stats.cptBlueAppVacant
                            }})
                          </td>
                          <td class="text-center"> {{
                              stats.cptOrangeBusPub +
                              stats.cptPinkBusPub +
                              stats.cptBlueBusPub
                            }} / {{
                              stats.cptOrangeBus +
                              stats.cptPinkBus +
                              stats.cptBlueBus
                            }}  ({{
                              stats.cptOrangeBusVacant +
                              stats.cptPinkBusVacant +
                              stats.cptBlueBusVacant
                            }})
                          </td>
                          <td class="text-center"> {{
                              stats.cptOrangeFrmPub +
                              stats.cptPinkFrmPub +
                              stats.cptBlueFrmPub
                            }} / {{
                              stats.cptOrangeFrm +
                              stats.cptPinkFrm +
                              stats.cptBlueFrm
                            }}  ({{
                              stats.cptOrangeFrmVacant +
                              stats.cptPinkFrmVacant +
                              stats.cptBlueFrmVacant
                            }})
                          </td>
                          <td class="text-center"> {{
                              stats.cptOrangeDomPub + stats.cptOrangeAppPub + stats.cptOrangeBusPub + stats.cptOrangeFrmPub +
                              stats.cptPinkDomPub + stats.cptPinkAppPub + stats.cptPinkBusPub + stats.cptPinkFrmPub +
                              stats.cptBlueDomPub + stats.cptBlueAppPub + stats.cptBlueBusPub + stats.cptBlueFrmPub
                            }} / {{
                              stats.cptOrangeDom + stats.cptOrangeApp + stats.cptOrangeBus + stats.cptOrangeFrm +
                              stats.cptPinkDom + stats.cptPinkApp + stats.cptPinkBus + stats.cptPinkFrm +
                              stats.cptBlueDom + stats.cptBlueApp + stats.cptBlueBus + stats.cptBlueFrm
                            }}  ({{
                              stats.cptOrangeDomVacant + stats.cptOrangeAppVacant + stats.cptOrangeBusVacant + stats.cptOrangeFrmVacant +
                              stats.cptPinkDomVacant + stats.cptPinkAppVacant + stats.cptPinkBusVacant + stats.cptPinkFrmVacant +
                              stats.cptBlueDomVacant + stats.cptBlueAppVacant + stats.cptBlueBusVacant + stats.cptBlueFrmVacant
                            }})
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
          </v-flex>

          </v-card>
          <v-btn
            text
            @click="cancel3"
          >{{ $t('pmtroadimport.cancelbutton') }}</v-btn>
          <v-btn
            color="primary"
            @click="importData"
            :disabled="!step3complete"
          >
            {{ $t('pmtroadimport.importbutton') }}
          </v-btn>

        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import PMTmapsUtils from '../../utils/PMTmapsUtils'
import { arrayUnion } from 'vuex-easy-firestore'
import firebase from 'firebase/app'
import Vue from 'vue'
const EventBus = new Vue()
export default {
  props: [],
  watch: {
    fpinkpdr (newValue) {
      if (newValue) {
        if (newValue.value) {
          this.pinkcode = newValue.value
          if (this.bluecode) {
            this.cptpinkcode = 0
            this.cptorangecode = 0
            this.cptbluecode = 0
            for (const code of this.seqCodeTab) {
              if (code.seqcodetotal < this.pinkcode) {
                code.color = 1
                this.cptorangecode++
              } else if (code.seqcodetotal < this.bluecode) {
                code.color = 2
                this.cptpinkcode++
              } else {
                code.color = 3
                this.cptbluecode++
              }
            }
            // onsole.log(this.seqCodeTab)
            this.step2complete = true
          }
        } else {
          this.pinkcode = null
          this.step2complete = false
        }
      } else {
        this.pinkcode = null
        this.step2complete = false
      }
    },
    fbluepdr (newValue) {
      if (newValue) {
        if (newValue.value) {
          this.bluecode = newValue.value
          if (this.pinkcode) {
            this.cptpinkcode = 0
            this.cptorangecode = 0
            this.cptbluecode = 0
            for (const code of this.seqCodeTab) {
              if (code.seqcodetotal < this.pinkcode) {
                code.color = 1
                this.cptorangecode++
              } else if (code.seqcodetotal < this.bluecode) {
                code.color = 2
                this.cptpinkcode++
              } else {
                // // onsole.log('code = ' + code.seqcodetotal + ' bluecode = ' + this.bluecode)
                code.color = 3
                this.cptbluecode++
              }
            }
            // onsole.log(this.seqCodeTab)
            this.step2complete = true
          }
        } else {
          this.bluecode = null
          this.step2complete = false
        }
      } else {
        this.bluecode = null
        this.step2complete = false
      }
    }
  },
  methods: {
    startloading (title) {
      this.loadingTitle = title
      this.loadingShow = true
      this.loadingValue = 0
    },
    processSeqFile () {
      this.seqfilesload = true
      this.startloading(this.$t('pmtroadimport.checkfile'))
      // this.$store.dispatch('startLoadingProgress', this.$t('pmtroadimport.checkfile'))
      if (this.seqfiles) {
        if (window.FileReader) {
          var reader = new FileReader()
          reader.readAsText(this.seqfiles)
          reader.onload = function (event) {
            let progress; let oldprogress
            let street
            let seqcodetotal = 0
            let seqcodestreet = 0
            var csv = event.target.result.split('\n')
            let lrname = ''
            let loffice = ''
            let lofficecp = ''
            const pdrs = []
            const pdrSearch = []
            let cptligne = 0
            let lastcode = ''
            let lastseq = ''
            const countligne = csv.length

            for (const ligne of csv) {
              const item = ligne.split(';')
              if (isNaN(item[0].replace(/ /g, ''))) {
                if (item[0].substring(0, 22) === 'DETAILS DELIVERY POINT') {
                  loffice = item[4]
                  lofficecp = item[3]
                } else if (item[0].substring(0, 5) === 'Route') {
                  if (!lrname) { lrname = item[1] }
                } else {
                  street = item[0]
                  seqcodestreet = 0
                }
              } else {
                if (item[1]) {
                  if (item[0] !== lastseq && item[1] !== lastcode) {
                    lastcode = item[1]
                    lastseq = item[0]
                    seqcodestreet++
                    seqcodetotal++
                    pdrSearch.push({ text: item[1] + ' ' + street.substring(4), value: seqcodetotal })

                    const pdr = {
                      door: item[1],
                      seqcodetotal: seqcodetotal,
                      seqcodestreet: seqcodestreet,
                      street: street
                    }
                    pdrs.push(pdr)
                  }
                } else {
                  lastcode = ''
                  lastseq = ''
                }
              }
              cptligne++
              progress = Math.round((cptligne / countligne) * 100)
              if (progress !== oldprogress) {
                oldprogress = progress
                EventBus.$emit('csvproccessprogress', (progress))
              }
            }

            const result = { route: lrname, officecp: lofficecp, office: loffice, pdrlist: pdrs, pdrSearch: pdrSearch }
            EventBus.$emit('seqcsvproccesscompleted', (result))

            // if (this.seqCodeTab) { this.step1complete = true }
          }
        }
      }
    },
    cancel1 () {
      this.e1 = 1
      this.seqfiles = null
      this.step1complete = false
    },
    cancel2 () {
      this.e1 = 1
      this.fpinkpdr = null
      this.fbluepdr = null
      this.step2complete = false
    },
    cancel3 () {
      this.e1 = 2
      this.stats = null
      this.pdr = null
      this.pdrlistfiles = null
      this.step3complete = false
    },
    processPdrListFile () {
      this.pdrlistfilesload = true
      this.startloading(this.$t('pmtroadimport.checkfile'))
      // this.$store.dispatch('startLoadingProgress', this.$t('pmtroadimport.checkfile'))
      if (this.pdrlistfiles) {
        if (window.FileReader) {
          var reader = new FileReader()
          reader.readAsText(this.pdrlistfiles)
          reader.onload = function (event) {
            let lrname; let loffice; let progress; let oldprogress
            // let street; let lastcomplex = ''
            const pdrs = []

            var csv = event.target.result.split('\n')
            // // onsole.log('csv = ' + csv.length)
            // // onsole.log('ligne = ' + csv[2])

            // // onsole.log('rue = ->'+test[0].substring(0,4)+'<-')
            let cptligne = 0
            let seqcode = ''
            let oldcode = ''
            let tempcode = ''

            const countligne = csv.length
            for (const ligne of csv) {
              cptligne++
              const item = ligne.replace(/"/g, '').split(',')
              /*
              if (cptligne === 91) {
                // onsole.log('item = 91 ++++++++++++++++++++++++')
                // onsole.log('item[0] = ' + item[0])
                // onsole.log('item[1] = ' + item[1])
                // onsole.log('item[2] = ' + item[2])
                // onsole.log('item[3] = ' + item[3])
                // onsole.log('item[4] = ' + item[4])
                // onsole.log('item[5] = ' + item[5])
                // onsole.log('item[6] = ' + item[6])
                // onsole.log('item[7] = ' + item[7])
                // onsole.log('item[8] = ' + item[8])
                // onsole.log('item[9] = ' + item[9])
                // onsole.log('item[10] = ' + item[10])
                // onsole.log('item[11] = ' + item[11])
                // onsole.log('item[12] = ' + item[12])
                // onsole.log('item[13] = ' + item[13])
                // onsole.log('item[14] = ' + item[14])
                // onsole.log('item[15] = ' + item[15])
                // onsole.log('item[16] = ' + item[16])
                // onsole.log('item[17] = ' + item[17])
                // onsole.log('item = ++++++++++++++++++++++++')
              }
              */
              // const item = itembrut.replace(/"/g, '')
              if (item[1] === 'POINT OF CALLS LIST PER INSTALLATION' && item[6]) {
                tempcode = item[6]
                if (!lrname) {
                  lrname = item[4]
                }
                if (tempcode !== oldcode) {
                  oldcode = tempcode
                  seqcode = tempcode
                }
              }
              if (item[1] === 'Address / Adresse') {
                let vacant
                if (item[5] === '0') {
                  vacant = true
                } else {
                  vacant = false
                }
                let pub
                if (item[6] === '0') {
                  pub = false
                } else {
                  pub = true
                }

                const totseqpdr = item[11].split(':')
                const pdr = {
                  pdrListAddress: item[2],
                  pdrListPostalCode: item[3],
                  seqcode: seqcode,
                  codeBF: item[4],
                  vacant: vacant,
                  pub: pub,
                  occuparyStatus: item[7],
                  tpType: item[8],
                  pdrType: item[9],
                  pdrZone: item[10],
                  pdrseqtotal: Number(totseqpdr[1].replace(/ /g, ''))
                }
                pdrs.push(pdr)
              }
              progress = Math.round((cptligne / countligne) * 100)
              if (progress !== oldprogress) {
                oldprogress = progress
                EventBus.$emit('csvproccessprogress', (progress))
              }
            }
            const ldata = {
              roadname: lrname,
              officename: loffice,
              pdrlist: pdrs
            }
            // // onsole.log('cpt code = ' + cptcode)
            // // onsole.log('cpttempPOC =' + cpttempPOC)
            // // onsole.log('cpttempADR =' + cpttempADR)
            // // onsole.log(seqcode)
            EventBus.$emit('pdrlistcsvproccesscompleted', (ldata))
          }
        }
      }
    },
    mergeCsvFiles (pdrlists) {
      this.startloading(this.$t('pmtroadimport.mergefile'))
      // this.$store.dispatch('startLoadingProgress', this.$t('pmtroadimport.mergefile'))
      let progress; let oldprogress = 0
      let cptverifcode = 0
      const stats = {
        cptOrangeBus: 0,
        cptOrangeBusPub: 0,
        cptOrangeBusVacant: 0,
        cptPinkBus: 0,
        cptPinkBusPub: 0,
        cptPinkBusVacant: 0,
        cptBlueBus: 0,
        cptBlueBusPub: 0,
        cptBlueBusVacant: 0,
        cptOrangeApp: 0,
        cptOrangeAppPub: 0,
        cptOrangeAppVacant: 0,
        cptPinkApp: 0,
        cptPinkAppPub: 0,
        cptPinkAppVacant: 0,
        cptBlueApp: 0,
        cptBlueAppPub: 0,
        cptBlueAppVacant: 0,
        cptOrangeFrm: 0,
        cptOrangeFrmPub: 0,
        cptOrangeFrmVacant: 0,
        cptPinkFrm: 0,
        cptPinkFrmPub: 0,
        cptPinkFrmVacant: 0,
        cptBlueFrm: 0,
        cptBlueFrmPub: 0,
        cptBlueFrmVacant: 0,
        cptOrangeDom: 0,
        cptOrangeDomPub: 0,
        cptOrangeDomVacant: 0,
        cptPinkDom: 0,
        cptPinkDomPub: 0,
        cptPinkDomVacant: 0,
        cptBlueDom: 0,
        cptBlueDomPub: 0,
        cptBlueDomVacant: 0
      }
      const pdrMerged = []
      const cpttotvericode = this.seqCodeTab.length
      let match = false
      for (const pdrseq of this.seqCodeTab) {
        cptverifcode++
        const adrss = pdrseq.door + ' ' + pdrseq.street
        const isapp = pdrseq.door.split('-')
        let revadrss
        if (isapp.length > 1) {
          revadrss = isapp[1] + '-' + isapp[0] + ' ' + pdrseq.street
        }
        match = false
        for (const pdrlist of pdrlists) {
          if (!match) {
            if (adrss.replace(/ /g, '') === pdrlist.pdrListAddress.replace(/ /g, '')) {
              // // onsole.log('match = ' + adrss)
              pdrMerged.push({
                vacant: pdrlist.vacant,
                pub: pdrlist.pub,
                occuparyStatus: pdrlist.occuparyStatus,
                tpType: pdrlist.tpType,
                color: pdrseq.color,
                type: pdrlist.pdrType,
                zone: pdrlist.pdrZone,
                csvPdrListAddress: pdrlist.pdrListAddress,
                csvPdrListcp: pdrlist.pdrListPostalCode,
                csvPdrListcodeBF: pdrlist.codeBF,
                csvPdrListseqcode: pdrlist.seqcode,
                csvPdrListNbrPdrInseqtotal: pdrlist.pdrseqtotal,
                csvSeqPosition: pdrseq.seqcodetotal,
                csvSeqPositionInStreet: pdrseq.seqcodestreet,
                officeId: this.$store.state.userModule.data[this.$store.state.userinfokey].officeselected,
                routeName: this.roadName
              })

              if (pdrseq.color === 1) {
                if (pdrlist.pdrType === 'Hou/Dom') {
                  if (pdrlist.vacant) {
                    stats.cptOrangeDomVacant++
                  } else {
                    stats.cptOrangeDom++
                  }
                  if (pdrlist.pub) { stats.cptOrangeDomPub++ }
                } else if (pdrlist.pdrType === 'Apt/App') {
                  if (pdrlist.vacant) {
                    stats.cptOrangeAppVacant++
                  } else {
                    stats.cptOrangeApp++
                  }
                  if (pdrlist.pub) { stats.cptOrangeAppPub++ }
                } else if (pdrlist.pdrType === 'Bus/com') {
                  if (pdrlist.vacant) {
                    stats.cptOrangeBusVacant++
                  } else {
                    stats.cptOrangeBus++
                  }
                  if (pdrlist.pub) { stats.cptOrangeBusPub++ }
                } else if (pdrlist.pdrType === 'Frm/Frm') {
                  if (pdrlist.vacant) {
                    stats.cptOrangeFrmVacant++
                  } else {
                    stats.cptOrangeFrm++
                  }
                  if (pdrlist.pub) { stats.cptOrangeFrmPub++ }
                } else {
                  // onsole.log('error pdr = ' + pdrlist.csvPdrListAddress + ' type = ' + pdrlist.pdrType)
                }
              } else if (pdrseq.color === 2) {
                if (pdrlist.pdrType === 'Hou/Dom') {
                  if (pdrlist.vacant) {
                    stats.cptPinkDomVacant++
                  } else {
                    stats.cptPinkDom++
                  }
                  if (pdrlist.pub) { stats.cptPinkDomPub++ }
                }
                if (pdrlist.pdrType === 'Apt/App') {
                  if (pdrlist.vacant) {
                    stats.cptPinkAppVacant++
                  } else {
                    stats.cptPinkApp++
                  }
                  if (pdrlist.pub) { stats.cptPinkAppPub++ }
                }
                if (pdrlist.pdrType === 'Bus/com') {
                  if (pdrlist.vacant) {
                    stats.cptPinkBusVacant++
                  } else {
                    stats.cptPinkBus++
                  }
                  if (pdrlist.pub) { stats.cptPinkBusPub++ }
                }
                if (pdrlist.pdrType === 'Frm/Frm') {
                  if (pdrlist.vacant) {
                    stats.cptPinkFrmVacant++
                  } else {
                    stats.cptPinkFrm++
                  }
                  if (pdrlist.pub) { stats.cptPinkFrmPub++ }
                }
              } else if (pdrseq.color === 3) {
                if (pdrlist.pdrType === 'Hou/Dom') {
                  if (pdrlist.vacant) {
                    stats.cptBlueDomVacant++
                  } else {
                    stats.cptBlueDom++
                  }
                  if (pdrlist.pub) { stats.cptBlueDomPub++ }
                }
                if (pdrlist.pdrType === 'Apt/App') {
                  if (pdrlist.vacant) {
                    stats.cptBlueAppVacant++
                  } else {
                    stats.cptBlueApp++
                  }
                  if (pdrlist.pub) { stats.cptBlueAppPub++ }
                }
                if (pdrlist.pdrType === 'Bus/com') {
                  if (pdrlist.vacant) {
                    stats.cptBlueBusVacant++
                  } else {
                    stats.cptBlueBus++
                  }
                  if (pdrlist.pub) { stats.cptBlueBusPub++ }
                }
                if (pdrlist.pdrType === 'Frm/Frm') {
                  if (pdrlist.vacant) {
                    stats.cptBlueFrmVacant++
                  } else {
                    stats.cptBlueFrm++
                  }
                  if (pdrlist.pub) { stats.cptBlueFrmPub++ }
                }
              } else {
                // onsole.log('error color pour pdr = ' + pdrseq.street)
              }
              match = true
            } else {
              if (revadrss) {
                if (revadrss.replace(/ /g, '') === pdrlist.pdrListAddress.replace(/ /g, '')) {
                  // // onsole.log('match rev = ' + revadrss)
                  pdrMerged.push({
                    vacant: pdrlist.vacant,
                    pub: pdrlist.pub,
                    color: pdrseq.color,
                    occuparyStatus: pdrlist.occuparyStatus,
                    tpType: pdrlist.tpType,
                    type: pdrlist.pdrType,
                    zone: pdrlist.pdrZone,
                    csvPdrListAddress: pdrlist.pdrListAddress,
                    csvPdrListcp: pdrlist.pdrListPostalCode,
                    csvPdrListcodeBF: pdrlist.codeBF,
                    csvPdrListseqcode: pdrlist.seqcode,
                    csvPdrListNbrPdrInseqtotal: pdrlist.pdrseqtotal,
                    csvSeqPosition: pdrseq.seqcodetotal,
                    csvSeqPositionInStreet: pdrseq.seqcodestreet,
                    officeId: this.$store.state.userModule.data[this.$store.state.userinfokey].officeselected,
                    routeName: this.roadName
                  })
                  if (pdrseq.color === 1) {
                    if (pdrlist.pdrType === 'Hou/Dom') {
                      if (pdrlist.vacant) {
                        stats.cptOrangeDomVacant++
                      } else {
                        stats.cptOrangeDom++
                      }
                      if (pdrlist.pub) { stats.cptOrangeDomPub++ }
                    } else if (pdrlist.pdrType === 'Apt/App') {
                      if (pdrlist.vacant) {
                        stats.cptOrangeAppVacant++
                      } else {
                        stats.cptOrangeApp++
                      }
                      if (pdrlist.pub) { stats.cptOrangeAppPub++ }
                    } else if (pdrlist.pdrType === 'Bus/com') {
                      if (pdrlist.vacant) {
                        stats.cptOrangeBusVacant++
                      } else {
                        stats.cptOrangeBus++
                      }
                      if (pdrlist.pub) { stats.cptOrangeBusPub++ }
                    } else if (pdrlist.pdrType === 'Frm/Frm') {
                      if (pdrlist.vacant) {
                        stats.cptOrangeFrmVacant++
                      } else {
                        stats.cptOrangeFrm++
                      }
                      if (pdrlist.pub) { stats.cptOrangeFrmPub++ }
                    } else {
                      // onsole.log('error pdr = ' + pdrlist.csvPdrListAddress + ' type = ' + pdrlist.pdrType)
                    }
                  } else if (pdrseq.color === 2) {
                    if (pdrlist.pdrType === 'Hou/Dom') {
                      if (pdrlist.vacant) {
                        stats.cptPinkDomVacant++
                      } else {
                        stats.cptPinkDom++
                      }
                      if (pdrlist.pub) { stats.cptPinkDomPub++ }
                    }
                    if (pdrlist.pdrType === 'Apt/App') {
                      if (pdrlist.vacant) {
                        stats.cptPinkAppVacant++
                      } else {
                        stats.cptPinkApp++
                      }
                      if (pdrlist.pub) { stats.cptPinkAppPub++ }
                    }
                    if (pdrlist.pdrType === 'Bus/com') {
                      if (pdrlist.vacant) {
                        stats.cptPinkBusVacant++
                      } else {
                        stats.cptPinkBus++
                      }
                      if (pdrlist.pub) { stats.cptPinkBusPub++ }
                    }
                    if (pdrlist.pdrType === 'Bus/com') {
                      if (pdrlist.vacant) {
                        stats.cptPinkBusVacant++
                      } else {
                        stats.cptPinkBus++
                      }
                      if (pdrlist.pub) { stats.cptPinkBusPub++ }
                    }
                    if (pdrlist.pdrType === 'Frm/Frm') {
                      if (pdrlist.vacant) {
                        stats.cptPinkFrmVacant++
                      } else {
                        stats.cptPinkFrm++
                      }
                      if (pdrlist.pub) { stats.cptPinkFrmPub++ }
                    }
                  } else if (pdrseq.color === 3) {
                    if (pdrlist.pdrType === 'Hou/Dom') {
                      if (pdrlist.vacant) {
                        stats.cptBlueDomVacant++
                      } else {
                        stats.cptBlueDom++
                      }
                      if (pdrlist.pub) { stats.cptBlueDomPub++ }
                    }
                    if (pdrlist.pdrType === 'Apt/App') {
                      if (pdrlist.vacant) {
                        stats.cptBlueAppVacant++
                      } else {
                        stats.cptBlueApp++
                      }
                      if (pdrlist.pub) { stats.cptBlueAppPub++ }
                    }
                    if (pdrlist.pdrType === 'Bus/com') {
                      if (pdrlist.vacant) {
                        stats.cptBlueBusVacant++
                      } else {
                        stats.cptBlueBus++
                      }
                      if (pdrlist.pub) { stats.cptBlueBusPub++ }
                    }
                    if (pdrlist.pdrType === 'Bus/com') {
                      if (pdrlist.vacant) {
                        stats.cptBlueBusVacant++
                      } else {
                        stats.cptBlueBus++
                      }
                      if (pdrlist.pub) { stats.cptBlueBusPub++ }
                    }
                    if (pdrlist.pdrType === 'Frm/Frm') {
                      if (pdrlist.vacant) {
                        stats.cptBlueFrmVacant++
                      } else {
                        stats.cptBlueFrm++
                      }
                      if (pdrlist.pub) { stats.cptBlueFrmPub++ }
                    }
                  } else {
                    // onsole.log('error color pour pdr = ' + pdrseq.street)
                  }
                  match = true
                }
              }
            }
          }
        }
        // if (!match) { // onsole.log('pdr not found = ' + adrss) }
        if (match) { match = false }

        progress = Math.round((cptverifcode / cpttotvericode) * 100)
        if (progress !== oldprogress) {
          oldprogress = progress
          this.loadingValue = progress
          // this.$store.state.EventBus.$emit('proccessprogress', (progress))
          // this.$store.state.loadingProgressValue = progress
        }
      }
      // onsole.log(pdrMerged)
      // onsole.log('stats')
      // onsole.log(stats)
      this.stats = stats
      this.pdr = pdrMerged
    },
    async importData () {
      this.startloading(this.$t('pmtroadimport.importfile'))
      // this.$store.dispatch('startLoadingProgress', this.$t('pmtroadimport.importfile'))
      // onsole.log('importdata')

      const resultId = await this.$store.dispatch('pdrModule/insertBatch', this.pdr)
      this.idspdr = resultId
      const route = { name: this.roadName, pdr: this.idspdr }
      const selofficeid = this.$store.state.userModule.data[this.$store.state.userinfokey].officeselected
      // const $store.state.officeModule.data[$store.state.userModule.data[$store.state.userinfokey].officeselected].report.push(report)
      this.$store.dispatch('officeModule/patch', {
        id: selofficeid,
        route: arrayUnion(route)
      })
      this.startGoogleSearch(this.$t('pmtroadimport.geosearch'))
      // this.$store.dispatch('completeLoadingProgress')
    },
    /*
    patchPdrCodeName () {
      const pdrListSorted = this.$store.getters['pdrModule/sortedPdr']
      let bluecodeCpt = 0
      let codeCpt = 0
      let tempCodeName
      let oldCodeName
      let oldColor
      let firstAddress
      // let lastAddress
      // let lastCodeName
      // let cptAddInCode = 0
      let oldstreet

      for (const pdr of pdrListSorted) {
        const streetArray = pdr.csvPdrListAddress.split(' ')
        // streetArray.splice(streetArray.length - 1, 1)
        // const street = streetArray.toString()
        const street = streetArray.splice(streetArray.length - 1, 1).toString()
        // // onsole.log(street)
        if (pdr.csvPdrListseqcode !== oldCodeName || oldColor !== pdr.color || street !== oldstreet) {
          oldColor = pdr.color

          if (pdr.color < 3) {
            codeCpt++
            tempCodeName = String(codeCpt)
          } else {
            bluecodeCpt++
            const bcode = bluecodeCpt + 64
            if (bluecodeCpt < 27) {
              tempCodeName = String.fromCharCode(bcode)
            } else if ((bluecodeCpt < 53)) {
              tempCodeName = String.fromCharCode(bcode - 26) + String.fromCharCode(bcode - 26)
            } else {
              tempCodeName = String.fromCharCode(bcode - 52) + String.fromCharCode(bcode - 52) + String.fromCharCode(bcode - 52)
            }
          }
          if (firstAddress) {
            // // onsole.log(street)
            // onsole.log('NewCODE[' + oldCodeName + '](' + cptAddInCode + ') = ' + lastCodeName + '  address = ' + firstAddress + ' last = ' + lastAddress)
          }
          oldCodeName = pdr.csvPdrListseqcode
          lastCodeName = tempCodeName
          firstAddress = pdr.csvPdrListAddress
          lastAddress = pdr.csvPdrListAddress
          cptAddInCode = 1
        } else {
          cptAddInCode++
        }
        const id = pdr.id
        if (!pdr.codeName) { this.$store.dispatch('pdrModule/set', { id, codeName: tempCodeName }) }
        // listToPatch.push({ pdrId: pdr.id, codeName: tempCodeName })
        lastAddress = pdr.csvPdrListAddress
        oldstreet = street
      }
      // onsole.log('NewCODE[' + oldCodeName + '](' + cptAddInCode + ') = ' + lastCodeName + '  address = ' + firstAddress + ' last = ' + lastAddress)
    },
    */
    verifPdrGoogleInfo () {
      this.idspdr = []
      // // onsole.log('verif pdr tot = ' + this.$store.state.pdrModule.data.length)
      const pdrListSorted = this.$store.getters['pdrModule/sortedPdr']
      for (const pdr of pdrListSorted) {
      // for (const pdrId in this.$store.state.pdrModule.data) {

        // // onsole.log(pdrId)
        if (!pdr.place_id) {
          // const door = this.$store.state.pdrModule.data[pdrId].csvPdrListAddress.split(' ', 1)
          // // onsole.log(door)
          // const appDoor = door[0].split('-')
          // // onsole.log('seqNbr = ' + this.$store.state.pdrModule.data[pdrId].csvSeqPosition + '  door = ' + door + '  appDoor = ' + appDoor[1])
          // if (door[0].split('-').length > 1) {
          // // onsole.log('split sur : ' + this.$store.state.pdrModule.data[pdrId].csvPdrListAddress)
          // }
          this.idspdr.push(pdr.id)
          // // onsole.log('Google not Found = ' + this.$store.state.pdrModule.data[pdrId].csvPdrListAddress)
        } else {
          // // onsole.log('else ====')
        }
      }
      // // onsole.log('pdr tot = ' + this.$store.state.pdrModule.data.length)
      // this.$store.dispatch('pdrModule/patchBatch', { doc: { place_id: null }, ids: this.idspdr })

      if (this.idspdr.length) {
        // onsole.log('Nbr a taiter = ' + this.idspdr.length + ' / ' + pdrListSorted.length)
        this.startGoogleSearch(this.$t('pmtroadimport.geosearch'))
      } else {
        this.$store.dispatch('showMessage', this.$t('pmtroadimport.noSearch'))
      }
    },
    startGoogleSearch (title) {
      this.cptpdrSearch = 0
      this.loadingTitle = title
      this.loadingShow = true
      this.pdrGoogleSearch()
    },
    pdrGoogleSearch () {
      const tempAddress = this.$store.state.pdrModule.data[this.idspdr[this.cptpdrSearch]].csvPdrListAddress + ', Lévis, QC ' + this.$store.state.pdrModule.data[this.idspdr[this.cptpdrSearch]].csvPdrListcp
      let searchAddress
      const door = tempAddress.split(' ', 1)
      // // onsole.log(door)
      const appDoor = door[0].split('-')

      if (appDoor[1]) {
        searchAddress = tempAddress.replace(door[0], appDoor[1])
        // onsole.log('+++++ Replace : ' + door[0] + '  -> ' + searchAddress)
      } else {
        searchAddress = tempAddress
      }
      /*
      const address = this.$store.state.pdrModule.data[this.idspdr[this.cptpdrSearch]].csvPdrListAddress
      const addressArray = address.split(' ')
      const doorArray = addressArray[0].split('-')
      let searchAddress
      if (doorArray.length > 1) {
        searchAddress = addressArray[0] = doorArray[1]
      } else {
        searchAddress = this.$store.state.pdrModule.data[this.idspdr[this.cptpdrSearch]].csvPdrListAddress + ', Lévis, QC ' + this.$store.state.pdrModule.data[this.idspdr[this.cptpdrSearch]].csvPdrListcp
      }
      */
      // const searchAddress = this.$store.state.pdrModule.data[this.idspdr[this.cptpdrSearch]].csvPdrListAddress + ', Lévis, QC ' + this.$store.state.pdrModule.data[this.idspdr[this.cptpdrSearch]].csvPdrListcp
      const pdrId = this.idspdr[this.cptpdrSearch]
      // const cpt = this.cptpdrSearch
      this.geocoder.geocode({ address: searchAddress }, function (results, status) {
        if (status === this.google.maps.GeocoderStatus.OK) {
          const data = { pdrId: pdrId, results: results }
          EventBus.$emit('pdrsearchcomplete', data)

          // this.$store.dispatch('officeModule/set', officedetail)
        } else if (status === 'OVER_QUERY_LIMIT') {
          // onsole.log('over limits cpt = ' + cpt)
          setTimeout(function () {
            EventBus.$emit('pdrsearchretry')
          }, 2000)
        } else {
          // there were no coordinates found for the address entered (or the address was not found)
          alert('Could not find your address ( ' + searchAddress + ' )')
          // onsole.log(status)
        }
      })
    }
  },
  data () {
    return {
      fpinkpdr: null,
      pinkcode: null,
      fbluepdr: null,
      bluecode: null,
      cptpinkcode: 0,
      cptorangecode: 0,
      cptbluecode: 0,
      e1: 1,
      idspdr: [],
      pdrSearch: [],
      cptpdrSearch: 0,
      stats: null,
      pdrlistfiles: null,
      seqfiles: null,
      seqfilesload: false,
      pdrlistfilesload: false,
      roadName: '',
      officeName: '',
      importstatut: 'start',
      progressvalue: 0,
      step1complete: false,
      step2complete: false,
      step3complete: false,
      seqCodeTab: [],
      google: null,
      geocoder: null,
      loadingShow: false,
      loadingTitle: '',
      loadingValue: 0,
      pdr: []
    }
  },
  async mounted () {
    this.google = await PMTmapsUtils()
    this.geocoder = new this.google.maps.Geocoder()
    EventBus.$on('pdrsearchcomplete', (data) => {
      const id = data.pdrId
      let placeIdDoor = ''
      for (let i = 0; i < data.results[0].address_components.length; i++) {
        if (!data.results[0].address_components[i].types.length) {
          // onsole.log('i = ' + i + '  longueur array = ' + data.results[0].address_components.length)
        }
        for (let x = 0; x < data.results[0].address_components[i].types.length; x++) {
          if (data.results[0].address_components[i].types[x] === 'street_number') {
            placeIdDoor = data.results[0].address_components[i].short_name
            break
          }
        }
        if (placeIdDoor) {
          break
        }
      }
      if (!placeIdDoor) {
        // onsole.log('placeIdDoor not found id = ' + id)
        // onsole.log(data.results[0].address_components)
      }
      const pdrDetail = {
        id,
        gaddress: data.results[0].address_components,
        formatted_address: data.results[0].formatted_address,
        placeIdDoor: placeIdDoor,
        place_id: data.results[0].place_id,
        location: [{ desc: 'home', loc: new firebase.firestore.GeoPoint(data.results[0].geometry.location.lat(), data.results[0].geometry.location.lng()) }]
      }
      this.$store.dispatch('pdrModule/set', pdrDetail)
      this.cptpdrSearch++
      this.loadingValue = Math.round((this.cptpdrSearch / this.idspdr.length) * 100)
      if (this.cptpdrSearch < this.idspdr.length) {
        this.pdrGoogleSearch()
      }
    })
    EventBus.$on('pdrsearchretry', () => {
      this.pdrGoogleSearch()
    })

    EventBus.$on('seqcsvproccesscompleted', (result) => {
      // // onsole.log('event recu ===' + result.office)
      this.roadName = result.route
      this.officeName = result.office
      if (result.pdrlist.length && result.office) {
        this.step1complete = true
        this.seqCodeTab = result.pdrlist
        this.pdrSearch = result.pdrSearch
        this.cptorangecode = result.pdrlist.length
      } else {
        this.$store.dispatch('showAlert', this.$t('pmtroadimport.errorfile'))
        this.seqfiles = null
        this.step1complete = false
      }
      this.loadingShow = false
    })
    EventBus.$on('pdrlistcsvproccesscompleted', (result) => {
      // // onsole.log('event recu ===' + result.roadname)

      if (result.pdrlist.length) {
        // // onsole.log(result.pdrlist)
        this.step3complete = true
        this.mergeCsvFiles(result.pdrlist)
      } else {
        this.$store.dispatch('showAlert', 'mauvais tye de fichier')
        this.pdrlistfiles = null
      }
      // this.$store.dispatch('completeLoadingProgress')
      this.loadingShow = false
    })
    EventBus.$on('csvproccessprogress', (value) => {
      this.loadingValue = value
      // this.$store.dispatch('progressLoadingProgressValue', value)
      // this.$store.dispatch('progressLoadingProgressValue', value)
      // this.$store.state.loadingProgressValue = value
      // this.$store.state.EventBus.$emit('proccessprogress', (value))
    })
  },
  computed: {
    blueCodeName (blueCode) {
      if (blueCode < 27) {
        return String.fromCharCode(blueCode)
      } else if ((blueCode < 53)) {
        return String.fromCharCode(blueCode - 26) + String.fromCharCode(blueCode - 26)
      } else {
        return String.fromCharCode(blueCode - 52) + String.fromCharCode(blueCode - 52) + String.fromCharCode(blueCode - 52)
      }
    }
  }

}

</script>
