<template>
  <div class="home" :style="$store.getters.contentStyle">
    <v-container justify-center align-center>
      <v-row no-gutters>
        <v-btn icon x-large @click="$store.getters.EventBus.$emit('selectComponent', ('pmt-pm-tool'))"><v-icon x-large>mdi-arrow-left-circle</v-icon></v-btn>
        <h3 class="mt-3">Menu</h3>
        <v-spacer></v-spacer>
        <h1>{{ $t('tool.clubsocial')}}</h1>
        <v-spacer></v-spacer>
      </v-row>
    </v-container>
    <v-card :height="$store.getters.contentSize.height - 74" :width="$store.getters.contentSize.width" class="overflow-y-auto overflow-x-hidden">
      <v-list nav>
        <v-row>
          <v-col cols="4">
            <v-list-item link class='mt-n2'>
            <v-list-item-content class="mx-auto" clearfix @click="showCreateMessage = true">
                <v-icon :size="$store.getters.iconSize" color="primary">mdi-message-draw</v-icon>
                <p class="subtitle text-center">{{ $t('commonmenu.createmessage') }}</p>
            </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-list>
    </v-card>
    <create-message
      from="csocial"
      @close="closeCreateMessage"
      v-if="showCreateMessage"
    ></create-message>
    </div>
</template>

<script>
export default {
  props: ['contentstyles'],
  methods: {
    selectitem (choice) {
      this.$emit('menu-item-selected', choice)
      this.ldrawer = false
    },
    closeCreateMessage () {
      this.showCreateMessage = false
      this.$store.getters.EventBus.$emit('sizeChange')
    }
  },
  data () {
    return {
      showCreateMessage: false
    }
  }
}
</script>
