<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable max-width="444" fixed persistent transition="dialog-top-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="secondary"
          :icon="!showInfoButton"
          :large="showInfoButton"
          :block="block"
          v-bind="attrs"
          v-on="on"
        >
          <p class="mt-4 me-2" v-if="showInfoButton">{{ $t('common.pdr') }} : {{ statsSubtitle }}</p>
          <v-icon>mdi-home-analytics</v-icon>
        </v-btn>
      </template>
      <v-card class="px-n2">
        <v-app-bar dark color="secondary" height="80">
          <v-spacer></v-spacer>
          <v-col class="text-center" cols="10">
            <v-toolbar-title class="font-weight-black">{{ statsTitle }}</v-toolbar-title>
            <v-toolbar-title>{{ statsSubtitle }}</v-toolbar-title>
          </v-col>
          <v-spacer></v-spacer>
          <v-btn icon x-large dark @click="dialog = false">
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>
        <v-expansion-panels v-if="asManyGroupColor || asManyGroupCp || asManyGroupPdrType">
          <v-expansion-panel class="yellow lighten-3">
            <v-expansion-panel-header>
              <v-row no-gutters>
              <v-spacer></v-spacer>
              {{ $t('common.regrouping') }}
              <v-spacer></v-spacer>
              <v-icon>mdi-home-group</v-icon>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider></v-divider>
              <v-checkbox
                v-if="asManyGroupColor"
                v-model="showColorGroup"
              >
                <template v-slot:label>
                  <v-list-item-content two-line>
                    <v-list-item-title v-text="$t('stats.colorGroup')"></v-list-item-title>
                    <v-list-item-subtitle v-text="$t('stats.colorGroupDesc')"></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-checkbox>
              <!-- <v-checkbox
                v-model="showStreetGroup"
                :label="$t('stats.streetGroup')"
              ></v-checkbox> -->
              <v-checkbox v-if="asManyGroupCp" v-model="showCpGroup">
                <template v-slot:label>
                  <v-list-item-content two-line>
                    <v-list-item-title v-text="$t('stats.cpGroup')"></v-list-item-title>
                    <v-list-item-subtitle v-text="$t('stats.cpGroupDesc')"></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-checkbox>
              <v-checkbox v-if="asManyGroupPdrType" v-model="showPdrTypeGroup">
                <template v-slot:label>
                  <v-list-item-content two-line>
                    <v-list-item-title v-text="$t('stats.pocTypeGroup')"></v-list-item-title>
                    <v-list-item-subtitle v-text="$t('stats.pocTypeGroupDesc')"></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-checkbox>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- 'stats', 'colorId', 'showHeader', 'presetColsArray' -->
        <!-- <display-stats :showHeader="true" :stats="stats"></display-stats> -->
        <v-card-text class="mt-5">
          <v-card
            v-for="(statsInfo, i) in statsInfoArray"
            :key="i"
          >
            <v-card v-if="statsInfo.type === 'cpGroup'" class="mt-4 mb-n4 grey ligthen-4 text-center">
              <p class="text-h5 text-center font-weight-bold">{{ statsInfo.cp }}</p>
            </v-card>
            <display-stats
              v-else-if="statsInfo.type === 'stats'"
              :presetColsArray="presetColsArray"
              :showHeader="statsInfo.showHeader"
              :stats="statsInfo.stats"
              :textColorId="statsInfo.colorId"
            ></display-stats>
          </v-card>
        </v-card-text>
        <v-app-bar dark color="secondary" bottom>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ $t('stats.stats-info') }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-app-bar>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  // pocItems = { id: required ,colorId: required }
  // props: ['pocIdArray', 'pocItems', 'pocArray', 'title', 'showInfoButton'],
  props: {
    pocIdArray: {
      type: Array
    },
    pocItems: {
      type: Array
    },
    pocArray: {
      type: Array
    },
    title: {
      type: String
    },
    showInfoButton: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialog: false,
      possibleGroup: [],
      showColorGroup: false,
      showCpGroup: false,
      showStreetGroup: false,
      showPdrTypeGroup: true
    }
  },
  computed: {
    stats () {
      return this.$store.getters.getCumulStatFromPocIdArray(this.pocItems.map(x => { return x.id }))
    },
    statsTitle () {
      return this.title ? this.title : this.$t('stats.statsTitle')
    },
    presetColsArray () {
      // ,="showPdrTypeGroup ? pdrTypeGroup : ['tot']"
      if (this.showPdrTypeGroup) {
        return this.pdrTypeGroup
      } else {
        return ['tot']
      }
    },
    statsSubtitle () {
      const stat = this.$store.getCumulTotalStatFromPocArray(this.statsItems)
      return stat.pub + ' / ' + stat.pocActive + ' (' + stat.vacant + ')'
    },
    statsMode () {
      if (this.pocItems) {
        return 'pocItems'
      } else if (this.pocIdArray) {
        return 'pocIdArray'
      } else {
        return 'pocArray'
      }
    },
    colorGroup () {
      const gColor = []
      this.statsItems.forEach(item => {
        if (item.colorId !== undefined && gColor.indexOf(item.colorId) < 0) { gColor.push(item.colorId) }
      })
      return gColor
    },
    cpGroup () {
      const gCp = []
      this.statsItems.forEach(item => {
        if (item.cp !== undefined && gCp.indexOf(item.cp) < 0) { gCp.push(item.cp) }
      })
      return gCp
    },
    pdrTypeGroup () {
      const gpdrType = []
      this.statsItems.forEach(item => {
        if (item.pdrType !== undefined && gpdrType.indexOf(item.pdrType) < 0) { gpdrType.push(item.pdrType) }
      })
      return gpdrType
    },
    statsItems () {
      if (this.pocItems) {
        return this.pocItems.map(x => {
          const poc = this.$store.state.pocModule.data[x.id]
          return {
            id: x.id,
            colorId: x.colorId,
            pdrType: poc.pdrType,
            vacant: poc.vacant,
            pub: poc.pub,
            cp: poc.pdrListPostalCode.substring(0, 3)
          }
        })
      } else if (this.pocArray) {
        return this.pocArray.map(x => {
          x.cp = x.pdrListPostalCode.substring(0, 3)
          return x
        })
      } else if (this.pocIdArray) {
        return this.pocIdArray.map(pocId => {
          const poc = this.$store.state.pocModule.data[pocId]
          poc.cp = poc.pdrListPostalCode.substring(0, 3)
          return poc
        })
      } else {
        return []
      }
    },
    statsInfoArray () {
      const sArray = []
      let isHeaderShowed = false
      if (this.showCpGroup) {
        this.cpGroup.forEach(cp => {
          sArray.push({
            type: 'cpGroup',
            cp: cp
          })
          isHeaderShowed = false
          if (this.showColorGroup) {
            this.colorGroup.forEach(colorId => {
              sArray.push({
                type: 'stats',
                colorId: colorId,
                showHeader: !isHeaderShowed,
                stats: this.$store.getCumulStatFromPocArray(this.statsItems.filter(x => x.colorId === colorId && x.cp === cp))
              })
              isHeaderShowed = true
            })
          } else {
            sArray.push({
              type: 'stats',
              showHeader: !isHeaderShowed,
              colorId: this.asManyGroupColor ? 0 : this.colorGroup.length ? this.colorGroup[0] : 0,
              stats: this.$store.getCumulStatFromPocArray(this.statsItems.filter(x => x.cp === cp))
            })
            isHeaderShowed = true
          }
        })
      } else {
        sArray.push({
          type: 'cpGroup',
          cp: this.cpGroup.toString()
        })
        if (this.showColorGroup) {
          this.colorGroup.forEach(colorId => {
            sArray.push({
              type: 'stats',
              colorId: colorId,
              showHeader: !isHeaderShowed,
              stats: this.$store.getCumulStatFromPocArray(this.statsItems.filter(x => x.colorId === colorId))
            })
            isHeaderShowed = true
          })
        } else {
          sArray.push({
            type: 'stats',
            colorId: this.asManyGroupColor ? 0 : this.colorGroup.length ? this.colorGroup[0] : 0,
            showHeader: !isHeaderShowed,
            stats: this.$store.getCumulStatFromPocArray(this.statsItems)
          })
          isHeaderShowed = true
        }
      }
      return sArray
    },
    asManyGroupColor () { return this.colorGroup.length > 1 },
    asManyGroupCp () { return this.cpGroup.length > 1 },
    asManyGroupPdrType () { return this.pdrTypeGroup.length > 1 }
  },
  methods: {
    getSumStats (stats) {

    },
    generate () {
      // this.
    }
  },
  mounted () {
    this.showColorGroup = this.asManyGroupColor
    this.showCpGroup = this.asManyGroupCp
    this.showPdrTypeGroup = true
    // // console.log(this.colorGroup)
    // // console.log(this.cpGroup)
  }
}
</script>
