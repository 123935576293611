<template>
  <div class="home">
    <div class="home" id="userGMaps" :style="mapStyle"/>
    <!-- <pmt-maps-drawer :isShow="showMapDrawer" @close="showMapDrawer = false"/> -->
    <maps-drawer :mapMode="mapMode" :isShow="showMapDrawer" @close="showMapDrawer = false"/>
    <div v-show="false">
      <div>
        <div id="infoWindowsDiv" ref="infoWindowRef">
          <maps-info-window
            v-if="infoWindowsData"
            id="infoWindowsDiv2"
            :m="infoWindowsData"
            :mapMode="mapMode"
            @doTask="doTask($event)"
          ></maps-info-window>
        </div>
      </div>
      <div id="levelUpControl">
        <v-btn
          id="levelUpControlBtn"
          class="ms-2 mt-3"
          @click="doTask({ taskType: 'levelUpClicked' })"
          v-show="showControl.showLevelUp"
          color="white"
          >
          <v-icon large>mdi-arrow-top-left-bold-outline</v-icon>
        </v-btn>
      </div>
      <div id="menuLeftBottomControl" v-show="!menuControlRight">
        <v-speed-dial
          v-model="menuControlLeft"
          direction="right"
          transition="'slide-x-transition'"
        >
          <template v-slot:activator>
            <v-btn
              class="ms-1"
              v-model="menuControlLeft"
              color="primary"
              :large="$store.getters.contentWidth > 370"
              dark
              fab
            >
              <v-icon v-if="menuControlLeft" large>mdi-close</v-icon>
              <!-- <v-icon v-else large>mdi-menu</v-icon> -->
              <v-icon v-else large>{{ $store.getMapModeIcon(mapMode) }}</v-icon>
            </v-btn>
          </template>
          <v-btn
            v-show="mapMode !== 'track-me'"
            fab
            dark
            :large="$store.getters.contentWidth > 370"
            color="primary"
            @click="doTask({ taskType: 'trackMeClicked' })"
          >
            <v-icon large v-text="$store.getMapModeIcon('track-me-off')"/>
          </v-btn>
          <v-btn
            v-show="mapMode !== 'route-info'"
            fab
            dark
            :large="$store.getters.contentWidth > 370"
            color="primary"
            @click="doTask({ taskType: 'routeInfoClicked' })"
          >
            <v-icon large v-text="$store.getMapModeIcon('route-info')"/>
          </v-btn>
          <v-btn
            v-show="mapMode !== 'direction'"
            fab
            @click="doTask({ taskType: 'directionClicked' })"
            dark
            :large="$store.getters.contentWidth > 370"
            color="primary"
          >
            <v-icon large v-text="$store.getMapModeIcon('direction')"/>
          </v-btn>
          <v-btn
            v-show="mapMode !== 'road-book' && $store.getters.getSelectedTravelId"
            fab
            @click="doTask({ taskType: 'roadBookClicked' })"
            dark
            :large="$store.getters.contentWidth > 370"
            color="primary"
          >
            <v-icon large v-text="$store.getMapModeIcon('road-book')"/>
          </v-btn>
          <v-btn
            v-show="mapMode !== 'direction'"
            fab
            dark
            :large="$store.getters.contentWidth > 370"
            color="primary"
            @click="$emit('showParam')"
          >
            <v-icon large>mdi-tune</v-icon>
          </v-btn>

        </v-speed-dial>
      </div>
      <div id="menuRightBottomControl" v-show="!menuControlLeft">
        <v-btn
          class="mb-3 me-1"
          color="primary"
          :large="$store.getters.contentWidth > 370"
          dark
          fab
          @click="showMapDrawer = !showMapDrawer"
        ><v-icon large>mdi-arrow-expand-left</v-icon></v-btn>
      </div>
      <div id="infoControl" v-show="!menuControlLeft && !menuControlRight">
        <map-info-control v-show="!menuControlLeft && !menuControlRight" :params="params" :mapMode="mapMode"/>
      </div>
      <div id="infoStreetViewControl">
        <street-view-info-control :mapMode="mapMode"/>
      </div>
      <div id="closeStreetViewControl">
        <v-btn
          icon
          fab
          dark
          @click="map.getStreetView().setVisible(false)"
          color="primary"
        >
          <v-icon x-large>mdi-backspace</v-icon>
        </v-btn>
      </div>
      <!-- <div id="menuRightBottomControl" v-show="!menuControlLeft"> -->
      <!-- <div id="menuRightBottomControl" v-show="false">
        <v-speed-dial
          class="mb-3 me-2"
          v-model="menuControlRight"
          direction="left"
          transition="'slide-x-reverse-transition'"
        >
          <template v-slot:activator>
            <v-btn
              v-model="menuControlRight"
              color="primary"
              large
              dark
              fab
            >
              <v-icon v-if="menuControlRight" large>mdi-close-box-multiple</v-icon>
              <v-icon v-else large>mdi-checkbox-multiple-blank</v-icon>
            </v-btn>
          </template>
          <v-btn
            fab
            dark
            large
            :color="showinfobox ? 'green' : 'primary'"
            @click="clickInfo"
          >
            <v-icon x-large>mdi-information-variant</v-icon>
          </v-btn>
          <v-btn
            fab
            dark
            large
            :color="showStreetView ? 'green' : 'primary'"
            @click="clickView"
          >
            <v-icon x-large>mdi-binoculars</v-icon>
          </v-btn>
        </v-speed-dial>
      </div> -->
    </div>
  </div>
</template>

<script>
import mapsInfoWindow from './Maps_InfoWindow.vue'
import MarkerClusterer from 'markerclustererplus-node'
import StreetViewInfoControl from './control/streetViewInfoControl.vue'
import MapInfoControl from './control/mapInfoControl.vue'
import MapsDrawer from './drawer/MapsDrawer.vue'
export default {
  components: { mapsInfoWindow, StreetViewInfoControl, MapInfoControl, MapsDrawer },
  props: ['mapMode', 'mapStyle', 'mapOption', 'showControl', 'renderer', 'markerData1', 'markerData2', 'markerClustererData', 'lineMarkerData', 'zoneMarkerData', 'params'],
  data () {
    return {
      map: null,
      infoWindowsData: null,
      infoControlTitle: 'detail des info',
      infoControlActive: null,
      infoWindow: null,
      showMapDrawer: false,
      menuControlLeft: false,
      menuControlRight: false,
      markerData1ListenerArray: [],
      markerData2ListenerArray: [],
      clusterListenerArray: [],
      placeCluster: null,
      greyBoxCluster: null,
      redBoxCluster: null
    }
  },
  computed: {
    currentPosition () { return this.$store.getters.getCurrentPosition },
    bounds () {
      if (this.mapOption.adjustMode === 'bounds') {
        return this.mapOption.bounds
      } else {
        return null
      }
    },
    centerZoom () {
      if (this.mapOption.adjustMode === 'center') {
        return this.mapOption
      } else {
        return null
      }
    }
  },
  methods: {
    doTask (task) {
      task.mapCenterZoom = { zoom: this.map.getZoom(), center: this.map.getCenter() }
      switch (task.taskType) {
        case 'markerClick':
          if (task.markerData.isOpenInfoWindow || this.mapMode === 'track-me') {
            this.toggleInfoWindow(task.markerData)
          } else {
            window.setTimeout(() => {
              task.markerData.marker.setAnimation(null)
            }, 400)
            task.markerData.marker.setAnimation(this.$store.getters.getGoogle.maps.Animation.BOUNCE)
            this.$emit('doTask', task)
          }
          break
        case 'zoomChanged':
          // // console.log('zoomLevel = ' + task.zoomLevel)
          this.setLineMarker(task.zoomLevel)
          break
        case 'mapClick':
          this.toggleInfoWindow()
          this.$store.getters.EventBus.$emit('closeMapInfoControl')
          this.$emit('doTask', task)
          break
        case 'loadRoute':
          this.toggleInfoWindow()
          this.$emit('doTask', task)
          break
        default:
          this.$emit('doTask', task)
          break
      }
    },
    getAnimation (markerType) {
      const google = this.$store.getters.getGoogle
      switch (markerType) {
        case 'cpMarker':
          return google.maps.Animation.DROP
        case 'streetMarker':
          return google.maps.Animation.DROP
        case 'pocMarker':
          return google.maps.Animation.DROP
        case 'placeMarker':
          return google.maps.Animation.DROP
        case 'toiletMarker':
          return google.maps.Animation.DROP
        case 'codeMarker':
          return google.maps.Animation.DROP
        case 'colorMarker':
          return google.maps.Animation.DROP
        default:
          return null
      }
    },
    toggleInfoWindow (markerData) {
      if (this.infoWindow) {
        this.infoWindow.close()
        this.infoWindow.setMap(null)
        this.infoWindow = null
        this.infoWindowsData = null
        // if (markerData.type === 'alertMarker' && markerData.zone) { markerData.zone.setVisible(false) }
      } else if (markerData) {
        // if (markerData.type === 'alertMarker' && markerData.zone) {
        //   markerData.zone.setMap(this.map)
        //   markerData.zone.setVisible(true)
        // }
        this.infoWindow = this.createinfoWindow(markerData)
        this.infoWindow.open(this.map, markerData.marker)
      }
    },
    createinfoWindow (m) {
      this.infoWindowsData = m
      const self = this
      const infoWindow = new this.$store.getters.getGoogle.maps.InfoWindow({
        // content: document.getElementById('infoWindowsDiv')
        content: self.$refs.infoWindowRef,
        pixelOffset: {
          width: 0,
          height: -3
        },
        maxWidth: 250,
        minWidth: 250
      })
      this.$store.getters.getGoogle.maps.event.addListener(infoWindow, 'closeclick', function () {
        infoWindow.setMap(null)
        self.infoWindow = null
      })
      return infoWindow
    },
    setLineMarker (zoomLevel) {
      if (this.lineMarkerData && this.lineMarkerData.length) {
        const showArray = zoomLevel > 16
        // // console.log(this.lineMarkerData)
        this.lineMarkerData.forEach(m => {
          if (!m.marker.line.getMap()) {
            m.marker.line.setMap(this.map)
          }
          if (!m.marker.lineArrow.getMap()) {
            m.marker.lineArrow.setMap(this.map)
          }
          m.marker.lineArrow.setVisible(showArray)
          m.marker.line.setVisible(!showArray)
        })
      }
    },
    setPlaceMarkerCluster (placeMarkerArray) {
      const self = this
      if (placeMarkerArray && placeMarkerArray.length) {
        placeMarkerArray.forEach(md => {
          if (!md.isClickListerSet) {
            md.isClickListerSet = true
            md.marker.setVisible(true)
            this.clusterListenerArray.push(md.marker.addListener('click', function () {
              // self.$emit('markerClick', (md))
              self.doTask({ taskType: 'markerClick', markerData: md })
            }))
          }
        })
      }
      if (this.placeCluster) {
        this.placeCluster.clearMarkers()
        if (placeMarkerArray && placeMarkerArray.length) {
          this.placeCluster.addMarkers(placeMarkerArray.map(x => { return x.marker }))
        }
      } else if (placeMarkerArray && placeMarkerArray.length) {
        this.placeCluster = new MarkerClusterer(this.map, placeMarkerArray.map(x => { return x.marker }), {
          imagePath: 'https://www.ptool.ca/Cluster/placeX',
          // IMAGE_PATH: '@/assets/Icon/MapIcon/Cluster/place',
          // imageExtension: 'png',
          // imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
          // style: [
          //   {
          //     textColor: 'red',
          //     url: 'https://www.ptool.ca/Cluster/placeX1.png',
          //     height: 50,
          //     width: 50
          //   },
          //   {
          //     textColor: 'white',
          //     url: 'https://www.ptool.ca/Cluster/placeX2.png',
          //     height: 50,
          //     width: 50
          //   },
          //   {
          //     textColor: 'white',
          //     url: 'https://www.ptool.ca/Cluster/placeX3.png',
          //     height: 50,
          //     width: 50
          //   }
          // ],
          maxZoom: 17,
          zIndex: 900,
          minimuplaceClusterSize: 2
        })
      }
    },
    setGreyBoxMarkerCluster (greyBoxMarkerArray) {
      if (this.greyBoxCluster) {
        this.greyBoxCluster.clearMarkers()
        if (greyBoxMarkerArray && greyBoxMarkerArray.length) {
          this.greyBoxCluster.addMarkers(greyBoxMarkerArray)
        }
      } else if (greyBoxMarkerArray && greyBoxMarkerArray.length) {
        this.greyBoxCluster = new MarkerClusterer(this.map, greyBoxMarkerArray, {
          imagePath: 'https://www.ptool.ca/Cluster/m',
          maxZoom: 17,
          zIndex: 800,
          minimuplaceClusterSize: 2
        })
      }
    },
    setRedBoxMarkerCluster (redBoxMarkerArray) {
      if (this.redBoxCluster) {
        this.redBoxCluster.clearMarkers()
        if (redBoxMarkerArray && redBoxMarkerArray.length) {
          this.redBoxCluster.addMarkers(redBoxMarkerArray)
        }
      } else if (redBoxMarkerArray && redBoxMarkerArray.length) {
        this.redBoxCluster = new MarkerClusterer(this.map, redBoxMarkerArray, {
          imagePath: 'https://www.ptool.ca/Cluster/m',
          maxZoom: 17,
          zIndex: 800,
          minimuplaceClusterSize: 2
        })
      }
    }
  },
  watch: {
    currentPosition (newValue) {
      if (this.mapMode === 'track-me' && newValue && !this.infoWindow) {
        if (!this.map.getBounds().contains(newValue)) {
          this.map.setCenter(newValue)
        }
      }
    },
    mapMode (newValue) {
      // console.log('new mapMode = ' + newValue)
      switch (newValue) {
        case 'track-me':
          this.map.setCenter(this.currentPosition)
          break
        default:
          break
      }
    },
    renderer (newValue, oldValue) {
      if (oldValue) {
        oldValue.setMap(null)
        oldValue.setPanel(null)
      }
      if (newValue) {
        newValue.setMap(this.map)
        newValue.setPanel(document.getElementById('directionsPanel'))
      }
    },
    bounds (newValue) {
      if (newValue && this.map) {
        this.map.fitBounds(newValue)
      }
    },
    centerZoom (newValue) {
      if (newValue && this.map) {
        this.map.setCenter(newValue.startCenter)
        this.map.setZoom(newValue.startzoom)
      }
    },
    markerClustererData (newValue, oldValue) {
      if (newValue && newValue.placeMarker) {
        this.setPlaceMarkerCluster(newValue.placeMarker)
      } else {
        this.setPlaceMarkerCluster([])
      }
    },
    markerData1 (newValue, oldValue) {
      // // console.log(newValue)
      const self = this
      this.markerData1ListenerArray.forEach(listener => {
        this.$store.getters.getGoogle.maps.event.removeListener(listener)
      })
      this.markerData1ListenerArray = []
      if (oldValue) {
        oldValue.markerList.forEach(markerData => {
          // markerData.marker.setMap(null)
          if (markerData.marker) { markerData.marker.setVisible(false) }
        })
      }
      if (newValue) {
        newValue.markerList.forEach(markerData => {
          // markerData.marker.setAnimation(this.getAnimation(markerData.type))
          // console.log(markerData)
          if (markerData.marker) {
            // console.log('trace 1')
            if (!markerData.marker.getMap()) {
              // console.log('trace 2 setMap')
              markerData.marker.setMap(this.map)
            }
            markerData.marker.setVisible(true)
            this.markerData1ListenerArray.push(markerData.marker.addListener('click', function () {
              // self.markerClick(markerData)
              self.doTask({ taskType: 'markerClick', markerData: markerData })
            }))
          }
        })
      }
    },
    markerData2 (newValue, oldValue) {
      const self = this
      this.markerData2ListenerArray.forEach(listener => {
        this.$store.getters.getGoogle.maps.event.removeListener(listener)
      })
      this.markerData2ListenerArray = []
      if (oldValue) {
        oldValue.forEach(markerData => {
          // markerData.marker.setMap(null)
          if (markerData.marker) { markerData.marker.setVisible(false) }
          // if (markerData.type === 'greyBoxMarker') {
          //   markerData.circle.setVisible(false)
          // }
        })
      }
      if (newValue) {
        // // console.log(newValue)
        newValue.forEach(markerData => {
          // markerData.marker.setAnimation(this.getAnimation(markerData.type))
          if (markerData.marker) {
            if (!markerData.marker.getMap()) {
              markerData.marker.setMap(this.map)
            }
            markerData.marker.setVisible(true)
            this.markerData2ListenerArray.push(markerData.marker.addListener('click', function () {
              // self.markerClick(markerData)
              self.doTask({ taskType: 'markerClick', markerData: markerData })
            }))
          }
        })
      }
    },
    lineMarkerData (newValue, oldValue) {
      const showArray = this.map.getZoom() > 17
      if (oldValue && oldValue.length) {
        oldValue.forEach(m => {
          m.marker.line.setVisible(false)
          m.marker.lineArrow.setVisible(false)
        })
      }
      if (newValue && newValue.length) {
        newValue.forEach(m => {
          if (!m.marker.line.getMap()) {
            m.marker.line.setMap(this.map)
          }
          if (!m.marker.lineArrow.getMap()) {
            m.marker.lineArrow.setMap(this.map)
          }
          m.marker.lineArrow.setVisible(showArray)
          m.marker.line.setVisible(!showArray)
        })
      }
    },
    zoneMarkerData (newValue, oldValue) {
      if (oldValue && oldValue.length) {
        oldValue.forEach(m => {
          m.polygon.setVisible(false)
        })
      }
      if (newValue && newValue.length) {
        newValue.forEach(m => {
          m.polygon.setMap(this.map)
          if (!m.polygon.getMap()) {
            m.polygon.setMap(this.map)
          }
          m.polygon.setVisible(true)
        })
      }
    }
  },
  mounted () {
    const google = this.$store.getters.getGoogle
    const self = this
    this.map = new google.maps.Map(document.getElementById('userGMaps'), {
      // center: this.mapOption.startCenter,
      // zoom: this.mapOption.startzoom,
      // mapId: '6fc3572086494d57',
      styles: [
        {
          featureType: 'administrative',
          elementType: 'geometry',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'transit',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        }
      ],

      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER
      },
      streetViewControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT
      },
      fullscreenControl: false,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        mapTypeIds: ['roadmap', 'satellite'],
        position: google.maps.ControlPosition.TOP_CENTER
      },
      rotateControlOptions: {
        position: google.maps.ControlPosition.LEFT_CENTER
      }
    })
    var thePanorama = this.map.getStreetView()
    thePanorama.setOptions({
      linksControl: false,
      panControl: false,
      enableCloseButton: false,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER
      },
      disableDefaultUI: true
    })
    thePanorama.controls[google.maps.ControlPosition.LEFT_TOP].push(document.getElementById('closeStreetViewControl'))
    thePanorama.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(document.getElementById('infoStreetViewControl'))
    google.maps.event.addListener(thePanorama, 'visible_changed', function () {
      if (thePanorama.getVisible()) {
        // Display your street view visible UI
        // // console.log('trace streetview open')
      } else {
        // Display your original UI
        // // console.log('trace streetview close')
      }
    })
    this.map.addListener('zoom_changed', () => {
      self.doTask({ taskType: 'zoomChanged', zoomLevel: self.map.getZoom() })
    })
    self.doTask({ taskType: 'mapLoaded' })
    this.map.addListener('click', function (mapsMouseEvent) {
      self.doTask({ taskType: 'mapClick', position: mapsMouseEvent.latLng.toJSON() })
    })
    if (this.mapOption.adjustMode === 'bounds') {
      this.map.fitBounds(this.mapOption.bounds)
    } else if (this.mapOption.adjustMode === 'center') {
      this.map.setCenter(this.mapOption.startCenter)
      this.map.setZoom(this.mapOption.startzoom)
    } else if (this.mapOption.adjustMode === 'current') {
      this.map.setCenter(this.mapOption.startCenter)
      this.map.setZoom(this.mapOption.startzoom)
    } else {
      // // console.log('trace no zoom start')
    }
    this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(document.getElementById('menuLeftBottomControl'))
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(document.getElementById('menuRightBottomControl'))
    this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(document.getElementById('infoControl'))
    this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(document.getElementById('levelUpControl'))
    if (this.markerData1) {
      this.markerData1.markerList.forEach(md => {
        // md.marker.setAnimation(this.getAnimation(md.type))
        if (!md.marker.getMap()) {
          md.marker.setMap(this.map)
        }
        md.marker.setVisible(true)
        // md.marker.setMap(this.map)
        this.markerData1ListenerArray.push(md.marker.addListener('click', function () {
          // self.$emit('markerClick', (md))
          self.doTask({ taskType: 'markerClick', markerData: md })
        }))
      })
    }
    if (this.markerData2) {
      this.markerData2.forEach(md => {
        // // console.log(md)
        // md.marker.setAnimation(this.getAnimation(md.type))
        if (!md.marker.getMap()) {
          md.marker.setMap(this.map)
        }
        // if (md.type === 'greyBoxMarker') {
        //   // // console.log('trace greybox')
        //   // // console.log(md)
        //   if (!md.circle.getMap()) {
        //     md.circle.setMap(this.map)
        //   }
        //   md.circle.setVisible(true)
        // }
        md.marker.setVisible(true)
        // md.marker.setMap(this.map)
        this.markerData2ListenerArray.push(md.marker.addListener('click', function () {
          // self.$emit('markerClick', (md))
          self.doTask({ taskType: 'markerClick', markerData: md })
        }))
      })
    }
    if (this.markerClustererData && this.markerClustererData.placeMarker) {
      this.setPlaceMarkerCluster(this.markerClustererData.placeMarker)
    }
    this.setLineMarker(this.map.getZoom())
    if (this.zoneMarkerData && this.zoneMarkerData.length) {
      this.zoneMarkerData.forEach(m => {
        m.polygon.setMap(this.map)
        if (!m.polygon.getMap()) {
          m.polygon.setMap(this.map)
        }
        m.polygon.setVisible(true)
      })
    }
    // const test = this.$store.getters.getMarkerOfRoute(this.$store.getters.getUserSelection.route)
    // test.colorMarker.forEach(m => {
    // m.polygon.setMap(this.map)
    // m.circleArray.forEach(circle => {
    //   circle.setMap(this.map)
    // })
    // m.marker.setMap(this.map)
    // })
    // test.codeLineMarker.forEach(m => {
    //   m.marker.line.setMap(this.map)
    // })
  },
  destroyed () {
    this.map = null
    if (this.markerData1ListenerArray && this.markerData1ListenerArray.length) {
      this.markerData1ListenerArray.forEach(listener => {
        this.$store.getters.getGoogle.maps.event.removeListener(listener)
      })
    }
    if (this.markerData2ListenerArray && this.markerData2ListenerArray.length) {
      this.markerData2ListenerArray.forEach(listener => {
        this.$store.getters.getGoogle.maps.event.removeListener(listener)
      })
    }
    if (this.clusterListenerArray && this.clusterListenerArray.length) {
      this.clusterListenerArray.forEach(listener => {
        this.$store.getters.getGoogle.maps.event.removeListener(listener)
      })
    }
  }
}
</script>
<style>
  button.gm-ui-hover-effect img {
    width: 30px !important;
    height: 30px !important;
    right: 30px !important;
  }
  button.gm-ui-hover-effect {
    right: 10px !important;
    /* visibility: hidden; */
  }
  /*
  .gm-style-iw {
    width: 350px !important;
    top: 0 !important;
    left: 0 !important;
    background-color: #fff;
    box-shadow: 0 1px 6px rgba(178, 178, 178, 0.6);
    border: 1px solid rgba(72, 181, 233, 0.6);
    border-radius: 2px 2px 0 0;
  }
  */
</style>
